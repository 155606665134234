import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { useData, useLoginForm } from '../hooks';
import { useNavLinks, useAuth } from '../contexts';
import { Table } from 'semantic-ui-react';
import { EuiPanel, EuiBadge, EuiToolTip } from '@elastic/eui';
import { EuiIcon } from '@elastic/eui';

const PricingPlan = () => {
  const [isSwitchChecked, setIsSwitchChecked] = useState(false);
  const [data, setData] = useState({});

  const loginForm = useLoginForm();
  const { homeLinks } = useNavLinks();
  const [animation, setAnimation] = React.useState('');
  const { checkForUser, token } = useAuth();
  const { getChargeCatalogAll, getCodCharge,getChargeCatalog } = useData();

  React.useEffect(() => {
    setData(getChargeCatalogAll());
    console.log(getChargeCatalogAll())
  }, [token]);

  React.useEffect(() => {
    checkForUser();
    homeLinks();
    setAnimation('on-mount 0.4s ease-out forwards');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container fluid>
      <Container fluid>
        <h4 className='fw-bold fs-4 mb-4'>Pricing Plan</h4>
        <EuiPanel hasShadow={false} hasBorder={false} className='mb-4 p-0'>
          <Table celled structured color='red' className='rounded-1'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  rowSpan='2'
                  verticalAlign='middle'
                  textAlign='center'
                >
                  Zone
                </Table.HeaderCell>
                <Table.HeaderCell
                  rowSpan='2'
                  verticalAlign='middle'
                  textAlign='center'
                >
                  Delivery Time
                </Table.HeaderCell>
                <Table.HeaderCell
                  colSpan='3'
                  verticalAlign='middle'
                  textAlign='center'
                >
                  Delivery Charge
                </Table.HeaderCell>
                <Table.HeaderCell
                  rowSpan='2'
                  verticalAlign='middle'
                  textAlign='center'
                >
                  COD Charge
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell positive>Upto 1kg</Table.HeaderCell>
                <Table.HeaderCell warning>1kg to 2kg</Table.HeaderCell>
                <Table.HeaderCell negative>After 2kg</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell textAlign='center' positive={true}>
                  <EuiBadge
                    color='success'
                    style={{ textTransform: 'capitalize' }}
                  >
                    Same City
                  </EuiBadge>
                </Table.Cell>
                <Table.Cell>{data?.['sameCity']?.time}</Table.Cell>
                <Table.Cell>{data?.['sameCity']?.charge} Tk</Table.Cell>
                <Table.Cell>
                  {data?.['sameCity']?.charge +
                    data?.['sameCity']?.next}{' '}
                  Tk
                </Table.Cell>
                <Table.Cell>{data?.['sameCity']?.next} Tk/Kg</Table.Cell>
                <Table.Cell
                 
                  verticalAlign='middle'
                  textAlign='center'
                >
                  {getCodCharge('sameCity')}%
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell textAlign='center' warning>
                  <EuiBadge color='warning'>Dhaka Suburbs</EuiBadge>
                </Table.Cell>
                <Table.Cell>{data?.['dhakaSuburbs']?.time}</Table.Cell>
                <Table.Cell>{data?.['dhakaSuburbs']?.charge} Tk</Table.Cell>
                <Table.Cell>
                  {data?.['dhakaSuburbs']?.charge +
                    data?.['dhakaSuburbs']?.next}{' '}
                  Tk
                </Table.Cell>
                <Table.Cell>{data?.['dhakaSuburbs']?.next} Tk/Kg</Table.Cell>

                <Table.Cell
                 
                  verticalAlign='middle'
                  textAlign='center'
                >
                  {getCodCharge('dhakaSuburbs')}%
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell textAlign='center' negative>
                  <EuiBadge color='danger'>Outside City</EuiBadge>
                </Table.Cell>
                <Table.Cell>{data?.['outsideCity']?.time}</Table.Cell>
                <Table.Cell>{data?.['outsideCity']?.charge} Tk</Table.Cell>
                <Table.Cell>
                  {data?.['outsideCity']?.charge +
                    data?.['outsideCity']?.next}{' '}
                  Tk
                </Table.Cell>
                <Table.Cell>{data?.['outsideCity']?.next} Tk/Kg</Table.Cell>
                <Table.Cell
                 
                  verticalAlign='middle'
                  textAlign='center'
                >
                  {getCodCharge('outsideCity')}%
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell textAlign='center' positive>
                  <EuiBadge color='green' className='text-white'>
                    Same Day Delivery
                  </EuiBadge>
                </Table.Cell>
                <Table.Cell>
                  {data?.['sameDayDelivery']?.time}
                  <EuiToolTip
                    position='right'
                    content='Cut off time is 2:00pm. That means after 2:00pm same day delivery option will be gone. And same day delivery will be only applicable for deliveries inside dhaka.'
                  >
                    <EuiIcon type='questionInCircle' color='subdued' />
                  </EuiToolTip>
                </Table.Cell>
                <Table.Cell>
                  {data?.['sameDayDelivery']?.charge} Tk
                </Table.Cell>
                <Table.Cell>
                  {data?.['sameDayDelivery']?.charge +
                    data?.['sameDayDelivery']?.next}{' '}
                  Tk
                </Table.Cell>
                <Table.Cell>
                  {data?.['sameDayDelivery']?.next} Tk/Kg
                </Table.Cell>
                <Table.Cell
                 
                  verticalAlign='middle'
                  textAlign='center'
                >
                  {getCodCharge('sameDayDelivery')}%
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </EuiPanel>
        <div className='p-4'>
          <ol style={{ listStyleType: 'disc' }} className='fw-bold text-dark'>
            {/* <li className='mb-3'>
              {getCodCharge()}% Cash On Delivery (COD) fee may apply
            </li> */}
            <li className='mb-3'>
              Same City {data?.['sameCity']?.next} tk Per Kg will apply
              after 2Kg
            </li>

            <li className='mb-3'>
              Dhaka Suburbs {data?.['dhakaSuburbs']?.next} tk Per Kg will apply
              after 2Kg
            </li>

            <li className='mb-3'>
              Outside City {data?.['outsideCity']?.next} tk Per Kg will apply
              after 2Kg
            </li>

            <li className='mb-3'>
              Same Day Delivery {data?.['sameDayDelivery']?.next} tk Per Kg
              will apply after 2Kg
            </li>

            <li className='mb-3'>
              Suburbs are Gazipur, Keraniganj, Narayanganj, Savar
            </li>
          </ol>
        </div>
      </Container>
    </Container>
  );
};

export default PricingPlan;
