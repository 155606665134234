import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useData, useLoginForm } from '../hooks';
import { useNavLinks, useAuth } from '../contexts';
import { Grid, Image } from 'semantic-ui-react';
import { Navbar as BNavbar } from 'react-bootstrap';
import { brandLogoFull as logo, signupback } from '../assets/index';
import { EuiHeaderLink, useGeneratedHtmlId, EuiPanel } from '@elastic/eui';
import { PrimaryRegForm } from '../components';

const Login = () => {
  const [isSwitchChecked, setIsSwitchChecked] = useState(false);

  const loginForm = useLoginForm();
  const { homeLinks } = useNavLinks();
  const [animation, setAnimation] = React.useState('');
  const { checkForUser } = useAuth();
  const { getSiteInfos } = useData();
  const data = getSiteInfos();

  React.useEffect(() => {
    checkForUser();
    homeLinks();
    setAnimation('on-mount 0.4s ease-out forwards');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const basicCheckboxId = useGeneratedHtmlId({ prefix: 'basicCheckbox' });

  return (
    <div className='login'>
      <Grid celled marginTop={0} style={{ margin: 0, minHeight: '100vh' }}>
        <Grid.Row>
          <Grid.Column
            style={{ padding: 0 }}
            mobile={16}
            tablet={6}
            computer={6}
            className='d-none d-md-block'
          >
            <Image
              src={signupback}
              style={{
                height: '100%',
                objectFit: 'cover',
                objectPosition: '-150px center',
              }}
            />
          </Grid.Column>

          <Grid.Column
            style={{ padding: 0 }}
            mobile={16}
            tablet={10}
            computer={10}
          >
            <Container
              fluid
              className='h-100'
              // className=' p-4'
              style={{
                // marginTop: '90px',
                // marginBottom: '90px',
                animation: animation,
              }}
            >
              <div className='d-flex align-items-center justify-content-between py-4'>
                <BNavbar.Brand className='p-0'>
                  <Link to='/#' className='h5 m-0 text-bolder p-0 text-primary'>
                    <img src={logo} height='40px' alt='Piickme Express' />
                  </Link>
                </BNavbar.Brand>
                <EuiHeaderLink color='primary' iconType='mobile'>
                  {data?.contactNumber}
                </EuiHeaderLink>
              </div>
              <div
                className='py-2 py-lg-5 d-flex align-items-center w-100'
                style={{ height: 'calc(100% - 85.5px)' }}
              >
                <EuiPanel
                  // hasBorder
                  hasShadow
                  className='bg-white p-4 p-md-5 shadow-sm mb-3 mb-lg-4 mx-auto'
                  style={{ maxWidth: 650 }}
                >
                  <PrimaryRegForm />
                </EuiPanel>
              </div>
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default Login;
