export const locations = [
  {
    district: 'Dhaka',
    zones: [
      {
        name: 'Khilkhet',
        areas: [
          'Namapara',
          'International convention city Bashundhara',
          'Khilkhet Graveyard',
          'Progoti saroni 300 feet, Neela market',
          'Pink City Model Town',
          'Dumini',
          'Bombay sweets & CO',
          'Ismaili Jamatkhana and certre',
          'Khilkhet Bazar',
          'ModdhoPara',
          'Uttar Para',
          'Bhuiya Bari',
          'Member Bari',
          'Bottola',
          'Amtola-Khilkhet',
          'Khilkhet Bashtola',
          'Taltola',
          'talertek',
          'Botghat',
          'Lakecity',
          'Boura',
          'LonjoniPara',
          'Sodesh',
          'Mastul',
          'Patira',
          'Purbachal 300 FT',
          'Lake City Concord',
          'Banorupa Housing',
          '300 Feet (khilkhet to purbachal)',
          'Tanpara',
          'Army Golf Kurmitola Golf',
        ],
      },
      {
        name: 'Banani',
        areas: [
          'Road 2',
          'Road 3',
          'Road 4',
          'Road 5',
          'Fakhruddin restaurant',
          'Road 7',
          'Road 8',
          'Road 9',
          'Road 10',
          'Road 11',
          'Road 12',
          'Road 13',
          'Road 14',
          'Road 15',
          'Road 16',
          'Road 17',
          'Road 18',
          'Road 19',
          'Road 20',
          'Road 21',
          'Road 22',
          'Road 23',
          'Road 24',
          'Road 25',
          'Road 26',
          'Road 27',
          'Ashalota',
          'Bonolota',
          'Bangladesh Army stadium',
          'Bangladesh Navy headquarters',
          'Banani graveyard',
          'Banani thana',
          'Banani sub post office',
          'Banani club',
          'Dhaka International university campus 01',
          'South point school & College',
          'Bangladesh Road transport Authority',
          'Banani Model Town',
          'Banani Old DOHS',
          'Masranga Television',
          'Neval Headquarters',
        ],
      },
      {
        name: 'Banani DOHS',
        areas: [
          'Road 2',
          'Road 3',
          'Road 4',
          'Road 5',
          'Road 6',
          'Western Road',
          'Masjid Road',
        ],
      },
      {
        name: 'Baridhara Diplomatic Zone',
        areas: [
          'Road 2',
          'Road 3',
          'Road 4',
          'Road 5',
          'Road 6',
          'Road 7',
          'Road 8',
          'Road 9',
          'Road 10',
          'Road 11',
          'Road 12',
          'UN Road',
          'Park Road',
          'Sharawardi Avenue',
        ],
      },
      {
        name: 'Baridhara DOHS',
        areas: [
          'Road 2 Baridhara DOHS',
          'Road 3 Baridhara DOHS',
          'Road 4 Baridhara DOHS',
          'Road 5 Baridhara DOHS',
          'Road 6 Baridhara DOHS',
          'Road 7 Baridhara DOHS',
          'Road 8 Baridhara DOHS',
          'Road 9 Baridhara DOHS',
          'Road 10 Baridhara DOHS',
          'Road 11 Baridhara DOHS',
          'Road 12 Baridhara DOHS',
          'Shewra Baridhara DOHS',
        ],
      },
      {
        name: 'Baridhara J Block',
        areas: [
          'Road 2 Baridhara J Block',
          'Road 3 Baridhara J Block',
          'Road 4 Baridhara J Block',
          'Road 5 Baridhara J Block',
          'Road 6 Baridhara J Block',
          'Road 7 Baridhara J Block',
          'Road 8 Baridhara J Block',
          'Road 9 Baridhara J Block',
          'Road 10 Baridhara J Block',
          'Road 11 Baridhara J Block',
          'Road 12 Baridhara J Block',
          'Road 13 Baridhara J Block',
          'Road 14 Baridhara J Block',
          'Road 15 Baridhara J Block',
          'Road 1A',
          'Road 2A',
          'Road 2B',
          'Road 2C',
          'Road 2D',
          'Road 2E',
          'Ma O Shishu Clinic',
        ],
      },
      {
        name: 'Bashundhara',
        areas: [
          'Block B Bashundhara',
          'Block C Bashundhara',
          'Block D Bashundhara',
          'Block E Bashundhara',
          'Block F Bashundhara',
          'Block G Bashundhara',
          'Block H Bashundhara',
          'Block I Bashundhara',
          'Block J Bashundhara',
          'Block K Bashundhara',
          'Block L Bashundhara',
          'Block M Bashundhara',
          'Block N Bashundhara',
          'WALTON Corporate office',
          'North south university',
          'GP House',
          'Bashundhara industrial headquarter-2',
        ],
      },
      {
        name: 'Gulshan 1',
        areas: [
          'Road 2',
          'Road 3',
          'Road 4',
          'Road 5',
          'Road 6',
          'Road 7',
          'Road 8',
          'Road 9',
          'Road 10',
          'Road 11',
          'Road 12',
          'Road 13',
          'Road 14',
          'Road 15',
          'Road 16',
          'Road 17',
          'Road 18',
          'Road 19',
          'Road 20',
          'Road 21',
          'Road 22',
          'Road 23',
          'Road 24',
          'Road 25',
          'Road 26',
          'Road 27',
          'Road 28',
          'Road 29',
          'Road 30',
          'Road 31',
          'Road 32',
          'Road 33',
          'Road 116',
          'Road 117',
          'Road 118',
          'Road 119',
          'Road 120',
          'Road 121',
          'Road 122',
          'Road 123',
          'Road 124',
          'Road 125',
          'Road 126',
          'Road 127',
          'Road 128',
          'Road 129',
          'Road 130',
          'Road 131',
          'Road 132',
          'Road 133',
          'Road 134',
          'Road 135',
          'Road 136',
          'Road 137',
          'Road 138',
          'Road 139',
          'Road 140',
          'Road 141',
          'Road 142',
          'Police Plaza concord shopping mall',
          'Gulshan shooting club',
          'KFC',
          'Pizza hut',
          'Spectra covention centre',
          'Cadet college club limited',
          'DCC Market',
          'Zaara convention centre',
          'The Glass house',
          'Jabbar Tower',
          'Gulshan-1 circle',
          'DESCO',
          'Navana tower',
          'Fakhruddin restaurant',
          'Gulshan 1 Aarong',
        ],
      },
      {
        name: 'Gulshan 2',
        areas: [
          'Road 35',
          'Road 36',
          'United commercial bank limited head office',
          'Road 37',
          'Road 38',
          'Road 39',
          'Road 40',
          'Road 41',
          'Road 42',
          'Road 43',
          'Road 44',
          'Road 45',
          'Road 46',
          'Road 47',
          'Road 48',
          'Road 49',
          'Road 50',
          'Road 51',
          'Road 52',
          'Road 53',
          'Road 54',
          'Road 55',
          'Cadet college club limited',
          'Road 57',
          'Road 58',
          'Road 59',
          'Road 60',
          'Road 61',
          'Road 62',
          'Road 63',
          'Road 64',
          'Road 65',
          'Road 66',
          'Road 67',
          'Road 71',
          'Road 72',
          'Road 73',
          'Road 74',
          'Road 75',
          'Road 76',
          'Road 77',
          'Road 78',
          'Road 79',
          'Road 80',
          'Road 81',
          'Road 82',
          'Road 83',
          'Road 84',
          'Road 85',
          'Road 86',
          'Road 87',
          'Road 88',
          'Road 89',
          'Road 90',
          'Road 91',
          'Road 92',
          'Road 93',
          'Road 94',
          'Road 95',
          'Road 96',
          'Road 97',
          'Road 98',
          'Road 99',
          'Road 100',
          'Road 101',
          'Road 102',
          'Road 103',
          'Road 104',
          'Road 105',
          'Road 106',
          'Road 107',
          'Road 108',
          'Road 109',
          'Road 110',
          'Road 111',
          'Road 112',
          'Road 113',
          'Gulshan central Masjid (Azad mosjid)',
          'The Westin Dhaka',
          'Banglalink customer care center',
          'Gulshan lake park',
          'United hospital limited',
          'Justices Shahabuddin park',
          'Gulshan DCC and super Market',
          'Gulshan Pink City Shopping complex',
        ],
      },
      {
        name: 'Nadda',
        areas: [
          'Joar Shahara',
          'Olipara',
          'Lichubagan',
          'Kalachadpur',
          'Muralbazar',
          'Sharkarbari',
          'Nadda',
        ],
      },
      {
        name: 'Kuril',
        areas: [
          'Haji shahabuddin baitul falah jame masjid',
          'Power Tech',
          'American internation University Bangladesh',
          'Aga Khan Academy',
          'Joshim uddin institute',
          'Kuratoli Bazar',
          'Kuril kuratoli adarshaw high school',
          'Kuril flyover',
          'Pubali Bank limited',
          'First security Islami Bank Limited',
          'Kuril Primary School',
          'Jamuna future park',
          'Jagannathpur',
          'Harez Sharak',
          'Sahid Abdul Aziz Sharak',
          'Pitha Ghor',
          'Saudi Masjid',
          'Walton Plaza',
        ],
      },
      {
        name: 'Mohakhali',
        areas: [
          'BATB Playground',
          'Raowa Convention Hall',
          'Mohakhali Flyover',
          'SKS Tower',
          'Mohakhali bus terminal',
          'ICDDRB Dhaka Hospital',
          'Mohakhali TB Gate',
          'Government Titumir College',
          'Dhaka Bank limited',
          'Brac University',
          'Partex Group',
          'Brac Centre',
          'TB gate Road',
          'National Institute of Disease of the chest',
          'Infectious Disease Hospital',
          'Institute of Pubic Health',
        ],
      },
      {
        name: 'Mohakhali DOHS',
        areas: [
          'Mohakhali DOHS Road 2',
          'Mohakhali DOHS Road 3',
          'Mohakhali DOHS Road 4',
          'Mohakhali DOHS Road 5',
          'Mohakhali DOHS Road 6',
          'Mohakhali DOHS Road 7',
          'Mohakhali DOHS Road 8',
          'Mohakhali DOHS Road 9',
          'Mohakhali DOHS Road 10',
          'Mohakhali DOHS Road 11',
          'Mohakhali DOHS Road 12',
          'Mohakhali DOHS Road 13',
          'Mohakhali DOHS Road 14',
          'Mohakhali DOHS Road 15',
          'Mohakhali DOHS Road 16',
          'Mohakhali DOHS Road 17',
          'Mohakhali DOHS Road 18',
          'Mohakhali DOHS Road 19',
          'Mohakhali DOHS Road 20',
          'Mohakhali DOHS Road 21',
          'Mohakhali DOHS Road 22',
          'Mohakhali DOHS Road 23',
          'Mohakhali DOHS Road 24',
          'Mohakhali DOHS Road 25',
          'Mohakhali DOHS Road 26',
          'Mohakhali DOHS Road 27',
          'Mohakhali DOHS Road 28',
          'Mohakhali DOHS Road 29',
          'Mohakhali DOHS Road 30',
          'Mohakhali DOHS Road 31',
          'Mohakhali DOHS Road 32',
          'Mohakhali DOHS Road 33',
          'Mohakhali DOHS Road 34',
          'Mohakhali DOHS Road 35',
        ],
      },
      {
        name: 'Nakhalpara',
        areas: [
          'Arjotapara',
          'Universal Medical College & Hospital Ltd',
          'Falcon Tower',
          "The Prime Minister's office",
          'Nakhalpara',
          'Nakhalapara government primary school',
          'Rahimafrooz Battery Headquarter',
          'Sony Rangs Showroom',
          'MP hostel Road, Nakhalpara',
          'Nakhalpara sub post office',
          'Bijoy Swaroni-tejgaon flyover',
          'Jahangir Gate',
          'Universal medical college & hospital',
          'Mohakhali Flyover',
          'Mohakhali Railgate',
          'Mohakhali Plaza',
        ],
      },
      {
        name: 'Niketan',
        areas: [
          'Asian television',
          "Brac University Girl's Hostel",
          'Pran-RFL Group Production House',
          'Niketon Central Jame Masjid',
          'Niketon Gate - 2',
          'Niketon Road 1',
          'Niketon Road 2',
          'Niketon Road 3',
          'Niketon Road 4',
          'Niketon Road 5',
          'Niketon Road 6',
          'Niketon Road 7',
          'Niketon Road 8',
          'Niketon Road 9',
          'Niketon Road 10',
        ],
      },
      {
        name: 'Nikunja 1',
        areas: [
          'Nikunja 1 Road 02',
          'Nikunja 1 Road 03',
          'Nikunja 1 Road 04',
          'Nikunja 1 Road 05',
          'Nikunja 1 Road 06',
          'Nikunja 1 Road 07',
          'Nikunja 1 Road 08',
          'Nikunja 1 Road 09',
        ],
      },
      {
        name: 'Nikunja 2',
        areas: [
          'Nikunja 2 Road 02',
          'Nikunja 2 Road 03',
          'Nikunja 2 Road 04',
          'Nikunja 2 Road 05',
          'Nikunja 2 Road 06',
          'Nikunja 2 Road 07',
          'Nikunja 2 Road 08',
          'Nikunja 2 Road 09',
          'Nikunja 2 Road 10',
          'Nikunja 2 Road 11',
          'Nikunja 2 Road 12',
          'Nikunja 2 Road 13',
          'Nikunja 2 Road 14',
          'Nikunja 2 Road 15',
          'Nikunja 2 Road 16',
          'Nikunja 2 Road 17',
          'Nikunja 2 Road 18',
          'Nikunja 2 Road 19',
          'Nikunja 2 Road 20',
          'Khilkhet Nikunja 2 jame mosjid',
          'Dhaka Regency Hotel & Resort',
          'Khilkhet police station',
          'BRTA Joar Shahara Branch',
        ],
      },
      {
        name: 'Tejgaon',
        areas: [
          'Nabisco Biscuit & Bread factory Ltd',
          'South East University',
          'Shanta Westen tower',
          'Hashim Tower',
          'Nava Tower',
          'Akij house',
          'Tejgaon industrial area',
          'Begunbari,tejgaon',
          'Film development Corporation',
          'Dhaka Polytechnic institute',
          'Brac Bank head office',
          'Bashundhara City',
          'BG Press Quarter',
          'BSTI',
          'Channel 24',
          'Essential Drugs Company',
          'Polar',
          'Satrasta',
          'Shahin Bag',
          'Shantiniketon',
          'Shatrasta',
          'Shomorita Medical College',
          'Tejgaon Ceramic Institute',
          'Tejgaon Hokers Market',
          'Tejgaon Land Office',
          'Tejgaon Link Road',
          'Tejgaon Rail Station',
          'Tejgaon Sonali Bank',
          'Tejgaon Thana',
          'Tibbot',
          'Lucas er Mor',
          'Karwanbazar Truck Stand',
          'Elenbari-Tejgaon',
          'BG press',
          'Rangs Tower',
        ],
      },
      {
        name: 'Vatara',
        areas: [
          'Wazuddin Road',
          'Khandokar Barir Mor',
          'Nayanagar',
          'Cocacola',
          'Apollo Hospital',
          'Jomoz road',
          'Kuratuly',
          'Notun Bazar-Vatara',
          'North Badda',
        ],
      },
      {
        name: 'Banasree',
        areas: [
          'Block B',
          'Block C',
          'Block D',
          'Block E',
          'Block F',
          'Block G',
          'Block H',
          'Block J',
          'Shanta Vantage',
          'Ideal School Goli',
          'Farazi Hospital Road',
          'Meradia Bazar',
        ],
      },
      {
        name: 'South Banasree',
        areas: [
          'Block B South Banasree',
          'Block C South Banasree',
          'Block D South Banasree',
          'Block E South Banasree',
          'Block M South Banasree',
          'Block F South Banasree',
          'Block G South Banasree',
          'Block H South Banasree',
          'Block J South Banasree',
          'Block K South Banasree',
          'Block L South Banasree',
          'Block N South Banasree',
          'Dosh Tala Market',
          'Falguny Check',
          'Protik Ruposree Residential Project',
          'Society Vaban',
          'Trimohoni',
          'Trimohoni Bazar',
          'Titas Road, Uttara Bank',
        ],
      },
      {
        name: 'Boro Moghbazar',
        areas: [
          'Red Cresent',
          'Gabtola',
          'Batar Goli',
          'Ad-Din Hospital',
          'Ramna Century Avenue',
          'Kazi Office Goli',
          'Ramna Officers Quarter',
          'High court Staff quarter',
          "Officer's Club",
          'Eskaton Garden',
          'New Eskaton Road',
          'Mogbazar Rail Crossing',
          'Mogbazar Out Circular Road',
        ],
      },
      {
        name: 'Mogbazar',
        areas: [
          'Doctor Goli',
          'Green Way',
          'Bepari Goli',
          'Kakon Goli',
          'Chairman Goli',
          'East Nayatola',
          'West Nayatola',
          'Ideal Point',
          'Central Point',
          'Sher-E-Bangla School Road',
          'Hazi Tower Goli',
          'Modhubag',
          'Mirbag',
          'Ambagan, Hatirjheel',
          'Shawpno Goli',
          'Bakery Goli',
          'Grand Plaza',
          'Telephone Bhaban',
          'Aarong',
          'Agora',
          'Holifamily Hospital Mogbazar',
        ],
      },
      {
        name: 'Eskaton Road',
        areas: [
          'Jolpai Resturant',
          'Eskaton Gallery',
          'Queens Garden Home',
          '129, Rangs Tower',
          '2 Hajar Goli',
          'Agrani Ayasha, Eskaton',
          'Navana Sattar Garden',
          'Rupayan Trade Centre',
          'Officers Quarter',
          'Inter Continental Hotel',
          'Borak Uniqe Heights Tower',
          'Holy Family Hospital',
          'Minto Road',
          'DB Office, Minto Road',
          'Insaf Barakah Kidney and General Hospital',
          'Shanti Kunja Goli',
          'Dilu Road',
          'Gaush Nagar',
          'BM Goli',
          '46 Rupayan Gellaxy',
          '41 Navana Tower',
          '37 Rangs Tower',
          '31 Rasid View',
          'BM School',
          'Shanta Tower, Banglamotor',
          '51 Eastern Tower',
          '52/1 Hasan Holding',
          '53 ABC Crescent',
          '54 Property Homes',
          '57 Togor Bhaban',
          'MHK Tower',
          'MTB Tower',
          'Concord Tower',
        ],
      },
      {
        name: 'Goran',
        areas: [
          'Chapra Masjid',
          'Ali Ahmed School Road',
          'Adharsha School Road',
          'Hawai Goli',
          'Fire Service',
          'Shantipur Masjid',
          'Comilla Hotel Goli',
          'Tilpa Para',
          'Goran Road 7',
          'Goran Road 8',
          'Goran Road 9',
          'Goran Road 10',
          'West Goran',
          'South Goran',
          'North Goran',
          'Shipahi Bag',
          'Nababer Mor',
        ],
      },
      {
        name: 'Khilgaon',
        areas: [
          'Purabarir Mor',
          'Modhuban Hotel Mor',
          'Bhuyanpara Kachabazar',
          'Bhuyanpara Minara Masjid',
          'Block A, Tilpapara',
          'Block B, Chowdhury Para',
          'Block C, Chowdhury Para',
          'Chowdhury Para',
          'Riazbag',
          'Taltola',
          'Biddyut Office',
          'Khilgaon Matir Masjid',
          'Natunbag',
        ],
      },
      {
        name: 'Shahjadpur',
        areas: [
          'Nurerchala',
          'Satarkul',
          'Khilbarirtek',
          'Shahajadpur',
          'Sayed Nagar, 100 Feet Road',
          'Bashtola',
          'US Embassy',
          'Indian Embassy',
          'Canadian Embassy',
          'Nepal Embassy',
          'Confidence Center',
          'Suvastu Nazar Valley',
          'Suvastu Tower',
          'Cambrian College',
          'High way Homes',
          'BTI Premier Plaza',
          'GMG Tower',
          'Morium Tower 1',
          'Morium Tower 2',
        ],
      },
      {
        name: 'Badda',
        areas: [
          'Manama Tower',
          'MF Tower, Link Road',
          'Tropical Molla Tower',
          'Middle Badda',
          'Post Office Goli, Middle Badda',
          'Beparipara Goli, Middle Badda',
          'Bazar Road, Middle Badda',
          'Adarsha Nagar, Middle Badda',
          'Moinarbag, Uttar Badda',
          'Shadhinota Sharani',
          'Tetul Tola, Uttar Badda',
          'Alir Mor, Uttar Badda',
          'Abdullabag, Uttar Badda',
          'Thana Road, Uttar Badda',
          'Hazi Para, Uttar badda',
          'Satarkul, Uttar Badda',
          'Gupipara, Uttar Badda',
          'Sabjigoli, Uttar Badda',
          'Badda Genaral Hospital',
          'Khanbag Masjid, Uttar Badda',
          'Gudaraghat',
          'Badda High School Goli',
          'Baishakhi Sarani',
          'South Badda',
          'Gulshan Link Road',
          'Badda Link Road',
          'Facilities Tower',
          'Cumilla Para',
          'Kuwaity Masjid',
          'Jahurul Haque City',
        ],
      },
      {
        name: 'Shajahanpur',
        areas: [
          'Shantibag',
          'Pabna Colony',
          'Gulbag',
          'Razarbag',
          'Momenbag',
          'Amtola Masjid Road',
          'Uttara Bank Goli',
          'Officers Colony',
          'Railway Colony',
          'Kobi Benajir Bagan Bari',
          'Navana Tower',
          'Rajarbag Gate-3',
          'Rajarbag Mor',
          'Rajarbag Gate-2',
        ],
      },
      {
        name: 'Malibag',
        areas: [
          'Malibag 1st Lane',
          'Property Plaza',
          'West Malibag',
          'Hosaf Tower',
          'CID Office',
          'Fortune Shopping Mall',
          'Sirajul Islam Medical College',
          'Rajuk Officers Quater',
          'Shohag Bus Counter',
          'Bagan Bari',
          'Chowdury Para',
          'Chowdury para Iqra Masjid Goli',
          'Komor Uddin Tower',
          'Abul Hotel',
          'South Point School',
          'Labaid Hospital',
          'Ibne Sina Hospital',
          'Padma Cinema Hall',
          'Chand Bakery Goli',
          'Bangla motor',
        ],
      },
      {
        name: 'DIT Road',
        areas: [
          'Molla Tower',
          'Ulon',
          'DIT Project',
          'Mohanagar Project',
          'Wapda Road',
          'Hazi Para',
          'Bagichar tek',
          'Omor Ali Lane',
          'Jheelkanon',
        ],
      },
      {
        name: 'Rampura',
        areas: [
          'TV Road',
          'Boubazar',
          'High School Goli',
          'Titash Road',
          'Kunjabon',
          'Post Office Goli',
          'Moulovirtek Jame Mosque',
          'Zakir Road',
          'Old Pollice Fari',
          'Agni Shikha Goli',
          'Polashbag',
          'Shimulbag',
          'Purabarir Goli',
          'Jomidar Goli',
          'Bhuyia Para',
          'East Rampura',
          'khilgaon Chowdhurypara',
          'Mailbag Chowdhurypara',
        ],
      },
      {
        name: 'Aftabnagar',
        areas: [
          'Block B',
          'Block C',
          'Block D',
          'Block E',
          'Block F',
          'Block G',
          'Block H',
          'Block I',
          'Block J',
          'Block K',
          'Block L',
          'Zahirul Isam City',
          'East West University',
          'Ananda Nagar',
          'Baithakhali',
          'West Merul Badda',
          'Merul Badda',
        ],
      },
      {
        name: 'Mugda',
        areas: [
          'Uttar Mugda',
          'Dakhin Mugda',
          'Mugda Stadiam',
          'Mugda Boro Masjid',
          'Mugda Bengal Garments',
          'Bashar Tower',
          'Mugda Hospital',
          'Mugda Thana',
          'Wapda Goli',
          'Jannatbag',
          'Zero Point Goli',
          'Modinabag',
          'Wasa Road',
          'Bank Colony',
          'Manik Nagar Miyazan Lane',
          'Mugda',
        ],
      },
      {
        name: 'Manda',
        areas: [
          'Lalmia Goli',
          'Hero Mia Road',
          'Chata Masjid',
          'Sona Mia Road',
          'Kodom Ali Jheelpar',
          'Green Model Town',
          'Manda Garments',
        ],
      },
      {
        name: 'Bashabo',
        areas: [
          'Rajarbag Mor',
          'Bagpara',
          'Kalibari',
          'Kusumbag',
          'Dhakshingaon Bazar',
          'Maya Kanon',
          'Ahmedbag',
          'Ahmedbag Wasa Pump',
          'Kodomtola 1st Lane',
          'Kodomtola Sky View Market',
          'Kodomtola High School',
          'Kodomtola Chayapoth',
          'Kodomtola House Society',
          'Madina Masjid Sarak',
          'Purbo Bashabo',
          'Ittadi Goli',
          'Patwary Goli',
          'Bank Polly',
          'Bashabo Patuwary Goli',
          'Madina Sarak',
          'Minara Goli',
          'Sarakarpara Club Goli',
          'Madertak Chawrasta',
          'Uttar Para Baitulla Masjid',
          'Madertak Elahibag',
          'Madartek Natunpara',
          'Pabna Goli',
          'Baganbari',
          'Singapore Road',
          'Adarshapara',
          'Boroitola',
          'Sicily Garments Goli',
          'Krishi Bank Goli',
          'Nandi Para Bridge',
          'Nandi Para',
          'Choto Bottola, Nandipara',
          'Boro Bottola, Nandipara',
          'Noor Masjid, Nandipara',
          'Dakshingaon Jheelpar Masjid',
          'Dakshingaon Tajuddin School',
          'Chapakhana, Nandipara',
          'Lal Masjid, Nandipara',
          'Newajbag',
          'Shekher Jaiga',
          'Moddho Bashabo',
          'Uttar Bashabo',
          'Dakshin Basabo',
          'Navana Tower',
          'Boubazar',
          'Tilpapara Culvert',
          'Bashabo Wap Collony',
          'Tempu Stand',
          '10 Er Mor',
          'Commissionar er Goli',
          'Chaya Bithi Eastern Housing',
          'Arong Bashabo',
        ],
      },
      {
        name: 'Shantinagar',
        areas: [
          'Zonaki Holler Goli',
          'Bhashani Goli',
          'Chamelibag',
          'Pirshaherber Goli',
          'Bazar Road',
          'Eastern Plus Market',
        ],
      },
      {
        name: 'Shiddeshwari',
        areas: [
          'Shiddeshwari Road',
          'Shiddeshwari Lane',
          'Shiddeshwari Circular Road',
          'Khondokar Goli',
          'Viqarunnesa Noon School & Collage',
          'Monowara Hospital',
          'KFC Goli',
          'Hare Road',
          'Circuit House',
          'Kali Mondir',
          'Mouchak Marcket',
          'Anarkoli Super Market',
          'Bicharpoti Bhavan',
          'Karnafuli Market',
        ],
      },
      {
        name: 'Shegunbagicha',
        areas: [
          'Segunbagicha',
          'Rajashya Bhaban',
          'DUDOK Office',
          'Shilpo Bhaban',
          'Bhutto Bhaban',
          'Audit Bhaban',
          'BMIT Bhaban',
          'Department of Narcotics Control',
          'Dhaka Reporters Unity',
          'Birdem Hospital 2',
          'Purtya Bhaban',
          'Matshya Bhaban',
          'Jatio Grihayan Bhaban',
          'Shram Bhaban',
          'Agoara Goli',
          'NSI',
          'Janoshastho Bhaban',
        ],
      },
      {
        name: 'Beraid',
        areas: [
          'Fokirkhali',
          'Chinadi Bazar',
          'Beraid Boatghat',
          'Mogadiya Bazar',
          'Merul',
          'Kathaldiya',
          'Baganbari',
          'Boro Beraid',
          'Choto Beraid',
          'Kali Mata Debi Mandir',
          'Beraid Bazar',
        ],
      },
      {
        name: 'Motijheel',
        areas: [
          'Shapla Chottor',
          'Dilkhusha',
          'DIT Avenue',
          'Health Engineering Dep ( HED)',
          'BIWTA Bhaban',
          'Bangladesh Krishi Bank || Head Office',
          'Islami Bank Head office',
          'Metlife Bangladesh',
          'Motijheel Commercial Area',
          'Rajdhani Unnayan Kartripakkha',
        ],
      },
      {
        name: 'Kamlapur',
        areas: [
          'Arambagh',
          'Notre Dame university College',
          'Mohammedan Sporting Club',
          "Arambagh Girls' High School and College",
          'Motijheel Police Station',
          'Kobi Jashim uddin Road',
          'Kamlapur Rail Station',
          'ICT Gate',
          'Bangladesh bank colony',
          'AGB colony',
          'TNT colony',
          'Ideal Zone Colony',
          'Ideal school',
        ],
      },
      {
        name: 'Gulisthan',
        areas: [
          'Bongo Bhaban',
          'Baitul Mukarram',
          'Stadium Market',
          'North South',
          'kaptan bazar',
          'Bonogram road/lane',
          'Juginagar',
          'Chondra mohan basak lane',
          'Moha jon pur lane',
          'Taherbag lane',
          'Teker hat lane',
          'Nawabpur Road',
          'Malitola',
          'Manoshi Cinema Hall',
          'Shundorban squre market',
          'Trade centre',
          'BCC Road',
          'Lalchan mokim lane',
          'Golokpal lane',
          'Modon pal lane',
          'Jhuriyatuli lane',
          'Noyabpur',
        ],
      },
      {
        name: 'Paltan',
        areas: [
          'IFIC Tower',
          '55/B Purana Paltan, Dhaka 1000',
          'Culvert Road',
          'Bijoynagar (0ld), New-172 Shahid Syed Nazrul Islam Sarani, Dhaka 1000',
          'Al Razi Complex 166-167, Dhaka 1000',
          'Purana Paltan Line, Dhaka 1205',
          'Topkhana Road, Dhaka 1205',
          'Purana Paltan, Madina Tower, 165 Shahid Syed Nazrul Islam Sharani, Dhaka 1000',
          'Baitul View Tower. Paltan, Dhaka 1000',
          'Noya Paltan',
          'Paltan Line',
          'Hotel 71',
          'Paltan Tower',
          'Ministry of Foreign Affairs',
          'Press club',
          'High court',
          'Ministry office',
          'Bijoy Nagar',
          'BNP party office',
          'Anondo vobon',
          'Purana Paltan',
          'Fokirapool',
          'Rail Bhaban',
          'Razarbag Police line',
        ],
      },
      {
        name: 'Gopibag',
        areas: [
          'R.k mission road',
          'K M Dash Lane',
          'Avoy dash lane',
          'Bhogo boti Benarji road',
          'Rose garden',
          'Hatkhola',
          'Rajdhani market',
          'ittefaq mor',
          'Sher E Bangla School',
          'Gopibag rail line',
          'Golapbag rail gate',
          'Tikatoli',
        ],
      },
      {
        name: 'Wari',
        areas: [
          'Rankin street',
          'Nowab street',
          'Chondi Choron bose street',
          'Larmini street',
          'Hair street',
          'Wire street',
          'A K sen lane',
          'Gopikishan lane',
          'North Mosundi',
          'Folder street',
          'Bhagwati Banerjee Road',
          'Rupchan Lane',
          'Bhojhari Saha Street',
          'Haricharan Roy Road',
          'Methorpotty',
          'Wari TSO',
        ],
      },
      {
        name: 'Narinda',
        areas: [
          'Voter goli',
          'Vojohori shaha steet',
          'Vogbot shah sankho Nidhi len',
          'Monir hosen len',
          'Shorot gupta road',
          'Begamganj len',
          'Lalmohon shah steet',
          'South Mosundi',
          'Shah Shaheb len',
          'Padma Nidhi len',
          'Jorpul len',
          'Sharat chandra chakraborty lane',
        ],
      },
      {
        name: 'Jatrabari',
        areas: [
          'koratitola',
          'Ideal school jatrabari',
          'Doya ganj',
          'shohid faruk road',
          'Dholpur',
          'Nobi nagar',
          'Dhonia',
          'shuruj nagar',
          'Biddut golli',
          'shekhpara',
          'Jatrabari mor',
          'Obda colony',
          'Jatrabari maser Arot',
          'kajla',
          'Sontek',
          'adorsho balika school road kalja',
          'shekdi',
          'gobindo pur',
          'Sydabad tarminal',
          'jonopod mor',
          'Kolar Arot jatrabari',
          'shohid Zia girls school',
          'Bibir bagicha-1,2,3,4 no gate',
          'Kajla uttor para',
          'Vhanga press',
          'Chan mia road',
          'Chagol er Arot',
          'Kazir daw',
          'Sheikhdi',
          'Donia',
          'Karatitola',
        ],
      },
      {
        name: 'Maniknogor',
        areas: [
          'golapbag mor',
          'Manik Nagor Pukurpar',
          'Dholpur staff quater',
          'Miyajan lane',
          'RAB 10 dhoplur',
          'maniknogor 6 tala',
          '19 kata balur math',
          'maniknogor model school',
          'washa road',
          'Manikngor main road',
          'golapbag',
        ],
      },
      {
        name: 'Dholpur',
        areas: [
          'khalikuzzaman school',
          'Licu bagan Dholpur',
          'Madrasa Road',
          'Badol Sorder lane',
          'dholpur community centre',
        ],
      },
      {
        name: 'Kadamtoli',
        areas: [
          'Rosul pur',
          'south dhoniya',
          'Puraton AK school road',
          'Dholaipar bazar',
          'dolaipar nur bag',
        ],
      },
      {
        name: 'Shanir Akhra',
        areas: [
          'polashpur',
          'Gas road',
          'Japani bazar',
          '24 feet',
          'jiya shoroni bridge',
          'paterbag',
          'nur pur',
          'bank colony',
          'New Ak school',
          'Dhoniya club',
          'Goyal barir mor',
          'dhoniya collage',
        ],
      },
      {
        name: 'Rayerbag',
        areas: [
          'Jonotabag',
          'Rayerbag Gas road',
          'Azi oshimoddin road',
          'modinabag',
          'Kodomtoli thana',
          'Apon bazar',
          'Muzahid nagor',
          'Habib nagor',
          'Rois nogor',
          'Meraj nogor A block, B block, C block',
          'Mohommod bag',
          'Kadamtoli',
          'Kodomtoli pakar matha',
          'Munshibag',
          'Rayerbag solimolla school',
        ],
      },
      {
        name: 'Matuail',
        areas: [
          'Shohid nagar',
          'Adorsho nogor',
          'Tushar dhara',
          'Saddam market',
          'Giridhara',
          'SritiDhara',
          'Badsha mia road',
          'Muslim nogar',
          'Shohor polli',
          'Mogol nogor',
          'Kodomtoli chow rasta',
          'Collage road',
          'Lotib mia collage',
          'Inu potti',
          'Keranipara',
          'hasem road',
          'South rayerbag gas road',
          'Khanbari 4 rasta',
          'Rofikul islam road',
        ],
      },
      {
        name: 'shampur',
        areas: [
          'Moradpur boro madrasa road',
          'Kudrot ali bazar',
          'Alibohor',
          'Shyampur Kacha Bazar',
          'Moradpur medical road',
          'Postogola',
          'Korimullabag',
          'Jurain koborastan',
          'Bikrampur Plaza',
          'Alam Market',
          'Ali bohor',
          'Shampur bazar',
          'D I T Plot, Shyampur',
          'Nama Shampur',
          'Shampur Bot Tola',
          'Dhaka match',
          'Dhaka Washa',
          'Electricity Power house, Shyampur',
          'Dholairpar',
          'Khondoker road',
        ],
      },
      {
        name: 'Jurain',
        areas: [
          'Alam Market, Shopping Mall',
          'Miru Bazar',
          'Mistir Dokan',
          'Mirhazirbag',
          'Alambag',
          'Khaza Moinuddin cisty road',
          'Jurain',
          'Komisoner road',
          'Muradpur',
          'Chairmen Bari',
          'Kudar Bazar',
          'Shyampur Bridge',
          'Muradpur Medical road',
          'Hrishi para',
        ],
      },
      {
        name: 'Faridabad',
        areas: [
          'Bahadur pul len',
          'Boisaki housing',
          'Akota housing',
          'Shotota Housing',
          'Nasir khaner goli',
          'IG gate',
          'Arsine gate',
          'Vandari goli',
          'Banladesh bank colony',
        ],
      },
      {
        name: 'Gandaria',
        areas: [
          'Distilary road',
          'Din Nath Sen Road',
          'Rojoni Chowdhury road',
          'Sosivuson Chaterji len',
          'Sarafat ganj len',
          'SK dash road',
          'Sotish sarker road',
          'Gandaria D. I. T. Plot',
          'Dhalka Nagar Lane, Dhaka',
          '52 katha',
          '100 katha',
          'Shakari Nagor len',
          'Lohar pul',
          'Khater pul',
          'Sohid nagor',
          'Kali Choron Shah road',
          'Deen Nath Sen Road',
          'Karimullarbagh',
          'Satish Sarkar Road',
        ],
      },
      {
        name: 'Sutrapur',
        areas: [
          'Ruplal dash len',
          'Suklal dash len',
          'Debebn drow nath dash len',
          'Mohoni Mohon dash len',
          'Forashganj',
          'Malaker tola',
          'BK Dash road',
          'Alomganj road',
          'Katherpul Lane',
          'Jorpool Lane',
          'Dhalkanagar Lane',
          'Shingtola',
          'Kagoji Tola',
          'Ahsan Manjil',
          'Goalghat',
        ],
      },
      {
        name: 'Demra',
        areas: [
          'Madrasha bazar',
          'Shorif para',
          'councile office',
          'matuali koborsthan',
          'Matuail high school',
          'Molla bridge',
          'mendi bari',
          'katherpul',
          'Golden bridge',
          'Kona para stand',
          'Dharmik para',
          'baserpul',
          'konapara farmer mor',
          'Dogai bazar',
          'Bamoil',
          'Adorsho bag',
          'Boro vanga',
          'Staff Quarter, Demra,',
          'Tarabo bazar',
          'Chonpara bridge',
          'Demra bazar',
          'Sharuliya Bazar',
          'Box nogor',
          'Sharuliya rani mohol',
          'Gola kata bridge',
          'Shukoroshi Bazar',
          'Shukoroshi Grabiyard',
          'Municipal Staff Quarter',
        ],
      },
      {
        name: 'Lalbag',
        areas: [
          'Khaje Dewan 2Nd Lane',
          'Rohimbox Lane',
          'Kellarmor',
          'Dhakeshwari Road',
          'Lalbagh Road',
          'Shaista Khan Road',
          'Royel Lane',
          'Kamrangirchor',
          'Devidas Ghat Lane',
          'Posta',
          'Rahmatganj',
          'Hosseni Dalan',
          'Chotokatra',
          'Borokatra',
          'Shatrawza',
          'Education Board',
          'Kashmirtola',
          'Karim Bag',
          'Rajni Chowdhury Road',
          'Shoarighat',
        ],
      },
      {
        name: 'Shahid Nagar',
        areas: [
          'Jogonnath Saha Road',
          'Amligola',
          'Hojrot Bag',
          'Sheikh Shaheb Bazar Road',
          'Khan Mohammad Masjid',
          'Raza Sreenath Street, Dhaka',
          'Shohidnogor Lalbagh',
          'Shoshan Ghat Lalbagh',
          'Balu Ghat',
          'Kashmiri Tola Lane',
          'Choto Bhatt Masjid',
          'Boro Bhatt Masjid',
          'Abdul Aziz Lane',
          'Nogor Beltola Lane',
          'R.N.D Road',
          'Gongaram Bazar',
          'Dhuri Angul',
          'Rasulbag',
        ],
      },
      {
        name: 'Hazaribag',
        areas: [
          'Lolit Mohon Das Lane',
          'Enayetgonj',
          'Gonoktoli Lane',
          'Baghalpur',
          'Hatirghat,Section',
          'Companyghat',
          'Baddanogor',
          'Ali Alaka',
          'Monessor Road ,Hazaribag',
          'Bdr 5No Gate',
          'Leather Collage',
          'Shikaritola',
          'Nobipur',
          'Bashtolal Goli ,Bdr 5No Gate',
          'Hazaribag Beribad',
          'Kalunogor Golden City',
          'Zawchor',
          'Zawchor Bhuiyan Street',
          'Kazirbag',
          'Kulalmohol',
          'Burhanpur',
          'Kalunogor',
          'Bottola ,Hazaribag',
          'Amra Tower Goli',
          'Shajahan Market',
          'Gudaraghat,Zawchor',
          'Zawchor Lononer Goli',
          'Enayetgonj Lane',
          'Sher-E-Bangla Road,Hazaribag',
          'Vogolpur Lane',
          'Hazariabg Road',
          'Gajmohol',
          'Mc Roy Lane',
          'Jawchar',
          'Somatar Goli',
          'Hazaribag Model Town',
          'Nelambor Saha Road',
          'Gajmohol Puran Thana',
          'Tanary Mor',
          'Kajirbag',
          'Hazaribag Golden City',
          'Bgb 1 No Gate',
          'Jauchar Lobon Factory',
          'Haji Abdul Awal Road ,Jawchar',
          'Hazaribag Bazar',
          'Kalunagar',
          'Bdr 5 No Gate',
          'Baddanogor Panir Tank',
          'Baddanogor Lane',
          'Nobipur Lane ,Hazaribag',
          'Gonoktuli',
        ],
      },
      {
        name: 'Jigatola',
        areas: [
          'Bgb 4 No Gate',
          'Abdul Hatem Lane',
          'Jigatola Post Office Road',
          'Kazi Nazimuddin Lane',
          'Puranton Kacha Bazar',
          'Munshi Bari Road',
          'Abdul Hai Road',
          'Sylheti Para',
          '96 Goli',
          'Tin Mazar Mosjod Goli',
          'Jigatola Tenarry Mor',
          'Tolla Bag',
          'Sher-E-Bangla Road,Teranimor',
          'Shonatonghor',
          'Shikdar Real State',
          'Shikdar Medical',
          'Gabtola Masjid',
          'Monassor Road',
          'Hazaribag Notun Thana',
          'Jigatala Bus Stand',
          'Moneshwar Road',
          'Jigatola Post office',
          'Mitali Road',
          'Hazi Afsar uddin Lane',
          'Rayerbazar',
          'Tollabagh',
        ],
      },
      {
        name: 'Azimpur',
        areas: [
          'Azimpur Chapra Masjid',
          'Azimpur Bottola',
          'Azimpu Koborstan',
          'Azimpur Staff Quater',
          'Pilkhana Road',
          'Bgb 2 No Gate',
          'Bgb 1No Gate',
          'Bgb 3 No Gate',
          'New Polton',
          'Bcs Chottor',
          'China Building R Goli',
          'Azimpur Metani',
          'Azimpur yatimkhana',
          'Azimpur Post Office',
          'Azimpur Community Center',
          'Iraqi Play Ground',
          'Bdr Gate 3 (Bir Uttam Habibur Rahman Gate)',
          'Choto Dayera Sharif Dayemiya Masjid',
          'Azimpur Model Government Primary',
          'Azimpur Model Government Primary',
          'Dc Traffic Office Azimpur',
          'New Polton Kazi Office',
          'Iraqi Play Ground',
          'Azimpur Graveyard',
        ],
      },
      {
        name: 'New Elephant Road',
        areas: [
          'Gausia Market',
          'Elephant Road Aeroplane Masjid',
          'Basundhara Goli',
          'Nurjahan Market',
          'Ismail Mansion Supermarket',
          'Meghna Petrol Pump',
          'Nurjahan Super Market',
          'Priyangan Shopping Center',
          'Ucc Science Lab.',
          'Eastern Mollika Shopping Complex',
          'Meena Bazaar Newmarket',
          'Rajdhani Market, Newmarket',
          'Jamia Sahbania Darul Uloom Madrasha Goli, Newmaarket',
          'Nurani Market',
        ],
      },
      {
        name: 'Newmarket',
        areas: [
          'New Super Market',
          'Chandrima Super Market',
          'Bonolota Super Market',
          'Bcs Quater',
          'Post Office Gate',
          'Newmarket 1 No Gate',
          'Newmarket 2 No Gate',
          'Newmarket 3 No Gate',
          'Dhaka Collage',
          'Naem Road',
          'Globe Shopping Center,Newmarket',
          'Mistir Goli',
          'Bot Tola New Market Dhaka',
          'Main Gate Of New Market',
          'Moriom Bibi Shahi Mosque',
          'Sandhani Eye Hospital',
          'Sj Jahanara Imam Sarani Newmarket',
          'National Academy For Educational Management (Naem)',
          'Meghna Petrol Pump Newmarket',
          'Shahnewaz Hall Dhaka University',
          'Bangamata Sheikh Fazilatunnesa Mujib Hall',
          'Bdr Gate 3 (Bir Uttam Habibur Rahman Gate)',
          "Govt. Teachers' Training College, Dhaka",
          'New Market Baitul Aman Jaame Masjid',
          'Dhaka New Market Byaboshahi Somiti',
          'New Market Morh',
          'Duet Bus Stand Goli(New Market)',
          'Bangladesh-Kuwait Friendship Hall',
          'Pilkhana Road',
          'Elephent Road',
          'Kataban',
        ],
      },
      {
        name: 'nilkhet',
        areas: [
          'Babupara Market',
          'Chandni Chwak Shopping Complex',
          'Newmarket Thana',
          'Balaka Cinema Hall Market',
          'Badruddoza Super Market',
          'Nilkhet Book Market',
          'Nilkhet Kormojibi Mohila Hostel',
          'National Academy For Planning And Development',
          'New Market Police Station',
          'Q. G. Samdani & Co And Cng Filling Station Raod',
        ],
      },
      {
        name: 'Elephant Road',
        areas: [
          'Sj Jahanara Imam Soroni',
          'Laboratory Road',
          'Science Lab Colony Masjid',
          'Sherin Plaza, Laboratory Road',
          'Multiplan Center',
          'Cats Eye Goli',
          'Priyangan Shopping Center',
          'Priyangan Shopping Center Goli Elephant Road',
          'Bangladesh Council Of Scientific And Industrial Research (Bcsir)',
          'Science Lab Colony Masjid (Bcsir Central Jaame Masjid)',
          "Baitul Ma'Mur Jaame Masjid Elephant Road",
          'Ucc Science Lab',
          'Eastern Mollika Goli',
          'Science Lab Colony Masjid (Bcsir Central',
          'Bcsir Secretariate',
        ],
      },
      {
        name: 'Central Road',
        areas: [
          'Vooter Goli Water Pump Goli',
          'Circular Road',
          'North Circular Road',
          'Vuter Goli,',
          'Green Road',
          'Cresent Road',
          'Green Corner',
          'Centarl Road',
          'Children Home Pre-Cadet High School Goli',
          'Labaid Besthospital Goli',
          'West East St Central Road',
          'Central Hospital Nursing Institute Road',
          'Green Corner Jame Masjid Road',
          'Hotel Kaderia And Chistia Restaurant Goli',
          'Ideal College',
          'Kalabagan Police Station, Dmp',
          'Al Hera Jame Masjid',
        ],
      },
      {
        name: 'Hatirpool',
        areas: [
          'Sonar Tori Hotel & Restaurant Goli Hatirpool',
          'S Kamruzzaman Sarani',
          'New Life Hospital Limited',
          'Crecent Road',
          'Motalib Plaza',
          'Nahar Plaza',
          'Eastran Plaza',
          'Hatirpool Link Road',
          'Bangladesh Law College',
          'Hatirpool Jame Masjid',
          'Free School Street Hatirpool',
          'Hatirpool',
        ],
      },
      {
        name: 'Kathalbagan',
        areas: [
          'Al-Amin Road',
          'Free School Street Jame Masjid, Pukurpar',
          'Sonargaon Road Hatirpool',
          'Kathalbagan Dhal',
        ],
      },
      {
        name: 'kathalbagan',
        areas: [
          'Pukurpar',
          'Kathabagan Dhal',
          'Box Culvert Road Kalabagan',
          'Laki Hotel Goli',
          'Kathalbagan Bazar',
          'Tiloker Goli',
          'Cricent Road',
        ],
      },
      {
        name: 'Shahbagh',
        areas: [
          'National Museum Shahbagh',
          'Katabon Market',
          'Katabon Chowrasta',
          'Bata Signal',
          'Genaral Hospital Goli',
          'Vojjo Teler Goli - Kataban Dhal',
          'Bcsir Quater Goli',
          'Hogh Mention Market',
          'Top Ten Goli',
          'Ziyan Resturant Goli',
          'Judge Goli',
          'Nawab Habibullah Road',
          'Mymensingh Road, Shahbag',
          'Tennis Club Shahbag',
          'Birdem General Hospital',
          'Bangabandhu Sheikh Mujib Medical University Hospital',
          'Central Masjid Kataban',
          'S Kamruzzaman Sharani Road',
          'Sonargaon Road Katabon',
          'Paribagh Wapda Officers Quarter',
          'Bangladesh Power Development Board (Bpdb) Central',
          'Katabon Pdb',
          'Katabon Boighar',
          'Prani Shastho Sheba Kendro Katabon',
          'Brighton Hospital Katabon',
          'Bsmmu Road Shahbagh',
          'Pg Hospital, D Block',
          'Bsmmu Outdoor 1',
          'Central Jame Mosque Goli, Bsmmu',
          'Paribagh Jame Masjid',
          'Paribagh Road No 2',
          'Kazi Nazrul Islam Ave Shahbagh Road',
          'Milon Hall At Bsmmu',
          'Bangladesh National Museum',
          'Sufia Kamal National Public Library',
          'Module General Hospital Road',
          'Motalib Plaza',
          'Paribagh Shonargaon St',
          'Nahar Plaza',
          'Sonargaon Road Hatirpool',
          'Bir Uttam Cr Dutta Link Road',
          'Dhaka Club',
          'Daerasharif',
          'Ladis club',
        ],
      },
      {
        name: 'Dhaka University',
        areas: [
          'Shahid Minar',
          'Fular Road',
          'Polashi Market',
          'Dokkhin Nilkhet',
          'Tsc Mor',
          'Dhaka Medical',
          'Dhaka Medical Mohila Hostel',
          'Raju Baskorjo',
          'Rokeya Hall',
          'Secretariate Road',
          'Milon Chottor',
          'Mukto Moncho',
          'Madhur Canteen',
          'Kabi Jasim Uddin Hall',
          'Surja Sen Hall',
          'Dhaka University Market',
          'Provost Quater',
          'Faculty Of Business ,Dhaka University',
          'Kola Bhaban',
          'Kazi Nazrul Islam Somadhisowdho',
          'Mohsin Hall',
          'Bijoy Ekattor Hall',
          'Nilkhet Police Fari',
          'Zahir Rayhan Hall',
        ],
      },
      {
        name: 'Chawkbazar',
        areas: [
          'Becharam Dewri',
          'Golam Mostafa Lane',
          'Begum Bazar',
          'K.Am Azam Lane',
          'Hafizlah Lane',
          'Nurbox Lane',
          'Ali Hosen Khan Road',
          'Moulavi Bazar',
          'Mukum Katara',
          'Aga Nabab Dewri',
          'Imamgong',
          'Panghat',
          'Champatoli',
          'Sowarighat',
          'Boro Katara',
          'Choto Katara',
          'Habibullah Road',
          'Chakbazar',
          'Urdu Road',
          'Azgar Lane',
          'Chrihatta Jame Masjid',
          'Haydar Box Lane',
          'Dal Potti',
          'Chak Circular Road',
          'Zail Khana Road',
          'Hakim Habibur Rahman Road',
          'Ganguly Lane',
          'Midford',
        ],
      },
      {
        name: 'Bangshal',
        areas: [
          'Nurbox Lane',
          'S.C.C Road',
          'Abul Kairat Road Armanitola',
          'Armanitola',
          'Armania Street',
          'K.P Gost Street',
          'Samsabad Lane',
          'Bagdasa Lane',
          'Kosaitoli',
          'Bangsal Road',
          'Bangsal Pukur Par',
          'Shikkatoli',
          'French Road',
          'Abdullah Sarkar Lane',
          'North South Road Bangsal',
          'Malitola',
          'Purano Mangal Toli',
          'Suritola',
          'Nababpur',
          'Nayabazar',
          'Zindhabahar 1St Lane',
          'Zindhabahar 2 Nd Lane',
          'Tatibazar',
          'Babubazar',
          'Issorchandra Road',
          'Abdul Hadi Lane',
          'Abdul gani road',
          'Agamasi Lane',
          'Hhaji Osman Gani Road',
          'Kasaituly',
          'Sikkatuli lane',
          'Thathari Bazar',
          'Sayed hasan Ali Lane',
          'Armenian Charch',
          'Zindabazar',
          'Zindhabahar Park',
          'Badamtoli',
        ],
      },
      {
        name: 'Islampur',
        areas: [
          'Haybad Nogor Lane',
          'Ahasan Ullah Road',
          'Nobab Bari Pukurpar',
          'Waiz Ghat Road',
          'Patuatoli Road',
          'Patuatoli Lane',
          'Kolpotti',
          'Shazada Mia Lane',
          'Syed Hasan Ali Lane',
          'Syed Awlad Hosen Lane',
          'Asek Lane',
          'Zindhabahar 1St Lane',
          'Zindhabahar 2Nd Lane',
          'Zindhabahar 3Rd Lane',
          'Possondho Poddar Lane',
          'Radhika Mohon Bosak Lane',
          'Basbari Lane',
          'Tati Bazar',
          'Panni Tola',
          'Kotwali Road',
          'Kumarbari',
          'Noboroy Lane',
          'Kobiraz Lane',
          'Romakanto Nondi Lane',
          'Nawab Yusuf Road Puran Dhaka',
        ],
      },
      {
        name: 'Sadarghat',
        areas: [
          'Gowalnogor',
          'Ray Saheb Bazar Sadarghat',
          'Rozar Dewerii',
          'Nasir Uddin Sardar Lane',
          'Lalmohan Shah Street, Dholaikhal',
          'Hazi Abdul Nazid Lane',
          'Karkun Bari Lane',
          'Subas Bos Avenue',
          'Rokon Pur 1St Lane',
          'Rokon Pur 2Nd Lane',
          'Rokon Pur 3Rnd Lane',
          'Pach Vai Lane',
          'Rogunath Lane',
          'Waise ghat',
          'Dhaka Court',
          'Shyambazar',
          'Ahsan Ullah Road',
        ],
      },
      {
        name: 'Lakshmibazar',
        areas: [
          'Shakari Bazar',
          'Jagannat University',
          'Semson Road',
          'Chirtranjan Avenue',
          'Liakat Avenue',
          'Victory Park',
          'Kazi Abdul Rouf Road',
          'Rogunath Dash Street',
          'Nondolal Dotto Lane',
          'Kunzo Babu Lane',
          'Raj Chandra Lane',
          'Rishibos Das Lane',
          'Dubar Das Lane',
          'Pisi Benarzi Lane',
          'Govindo Dotto Lane',
          'Golap Shah Lane',
          'Shama Prosad Lane',
          'K.G Gupto Lane',
          'Patla Khan Lane',
          'North Book Hall Road',
          'Pari Das Lane',
          'Joy Chandra Lane',
          'Bangla Bazar',
          'Shirisdas Lane',
          'Issordas Lane',
          'Lalkuthi',
          'Jubli School Road',
          'Justice Lalmohon Das Lane',
        ],
      },
      {
        name: 'Kamranggirchar',
        areas: [
          'Jawlahati Chowrasta',
          'Mujibor Gaht',
          'Khalpar Chowrasta',
          'Rony Market More',
          'Nurbag,Kamrangirchor',
          'Koylar Ghat',
          'Madbor Bazar',
          'Asrafabad',
          'Kholamora Ghat',
          'Burigonga City Market',
          'Eidgah Math',
          'Hazan Nogor',
          'Mohammadnogor',
          'Khalifa Ghat',
          'Middle Mominbag',
          'Chad Masjid Main Road',
          'Tekerhati',
          'Monshihati',
          'Kurar Ghat',
          'Abu Sayed Bazar,Kamrangirchor',
          'Bettary Ghat',
          'Hasan Nogor Bandari Mor',
          'Borogram',
          'Hujurpara',
          'Islam Nogor',
          'Ali Nagar Bazar',
          'Khatpotti',
          'Nabinagar Nascari Goli',
          'Acarwala Ghat',
          'Kazi Bari Goli Khalifaghat',
          'West Rosulpur',
          'Sylhet Bazar',
          'Tenaripukur Par',
          'Pakapul',
        ],
      },
      {
        name: 'Bakshi Bazar',
        areas: [
          'Fazle Rabbi hall,main building',
          'Nimtoli Jolla Ln',
          'Shaikh Burhanuddin Post Graduate College',
          'Aga Sadek Rd',
          'Abul Hasnat Road DTW, Dhaka WASA',
          'Ahsan Plastic Industry Road',
          'Chankharpool Government Primary School',
          'Golam Makhdum Jame Mosque Goli',
          'Khwaja Anwarul Hoque Tower',
          'Nazimuddin Rd',
          'Suku Miah Ln',
          'Toiyabiya Jame Masjid Goli',
          'Qayet Tuly Ln',
          'Baitur Rahman Mosque Goli',
          'Nobab Katara (Nimtoli) Chata Masjid Goli',
          'Nabab Katara kholipa Potti Road',
          'Dhaka Law College Road',
          'Nazira Bazar Choto Jame Mosque Goli',
          'Bismillah Kabab Ghar Goli',
          'Bangladesh Fire Service and Civil Defense Head Quarter Road',
        ],
      },
      {
        name: 'Mirpur 1',
        areas: [
          'lalkhuthi',
          'Boshupara',
          'Piyangon housing',
          'North Tolarbag',
          'Paikpara staff quater',
          'Government Bangla College',
          'Kallyanpur Housing Estate',
          'South Paikpara',
          'Ahmed Nagar',
          'Baitul Mamur Jame Masjid',
          'Janata Housing',
          'Misco Super Market',
          'Zoo Road',
          'Rainkhola Bazar',
          'Mirpur 1 Block (A)',
          'Mirpur 1 Block (B)',
          'Mirpur 1 Block (C)',
          'Mirpur 1 Block (D)',
          'Mirpur 1 Block (E)',
          'Mirpur 1 Block (G)',
          'Mirpur 1 Block (G1)',
          'Mirpur 1 Block (H)',
          'Mirpur 1 Block',
          'Muktobangla shoping mall',
          'Barabag',
          'Hakim plaza',
          'Shinepukur',
          'Shah Ali',
          'Zoo',
        ],
      },
      {
        name: 'Mirpur 2',
        areas: [
          'Mirpur 2 Block(A)',
          'Mirpur 2 Block(B)',
          'Mirpur 2 Block(C)',
          'Mirpur 2 Block(D)',
          'Mirpur 2 Block(E)',
          'Mirpur 2 Block(F)',
          'Mirpur 2 Block(G)',
          'Mirpur 2 Block(G1)',
          'Mirpur 2 Block(H)',
          'Mirpur 2',
          'Rain khola',
        ],
      },
      {
        name: 'MONIPUR',
        areas: ['Middle Monipur', 'South Monipur', 'West Monipur'],
      },
      {
        name: 'PIRERBAG',
        areas: ['Middle Pirerbag', 'South Pirerbag', 'West kafrul'],
      },
      { name: 'SHEWRAPARA', areas: ['West Kazipara'] },
      { name: 'MIRPUR 10', areas: ['Mirpur 6 Block Kha'] },
      {
        name: 'MIRPUR 6',
        areas: [
          'Mirpur 6 Block A',
          'Mirpur 6 Block B',
          'Mirpur 6 Block C',
          'Mirpur 6 Block D',
          'Mirpur 6 Block E',
          'MIRPUR 6 Block',
        ],
      },
      {
        name: 'Rupnagar',
        areas: [
          'Arambag Block A',
          'Arambag Block A',
          'Arambag Block C',
          'Arambag Block D',
          'Arambag Block E',
          'Arambag Block F',
          'Arifabad',
          'Mirpur -7 (R/A)',
          'Mirpur -7 (C/A)',
          'Prosika',
        ],
      },
      {
        name: 'Pallabi',
        areas: [
          'Pallabi R/A',
          'Eastern housing',
          'Alubdhi',
          'Milkvita',
          'Ceramic',
          'MIST',
          'Purobi',
        ],
      },
      { name: 'DOHS', areas: ['Mirpur DOHS', 'Ceramic Block PO'] },
      {
        name: 'MIRPUR 12',
        areas: [
          'Mirpur 12 Block B',
          'Mirpur 12 Block C',
          'Mirpur 12 Block D',
          'Mirpur 12 Block E',
          'Mirpur 12 Block Tha',
          'Mirpur 12 Block Dha',
          'Mirpur 12 Duip Area',
          'Mirpur 12 Sagufta',
        ],
      },
      {
        name: 'Mirpur 10',
        areas: [
          'Block A',
          'Block B',
          'Block C',
          'Block D',
          'East kazipara',
          'East shewrapara',
        ],
      },
      {
        name: 'Mirpur 13',
        areas: ['Block B', 'Block C', 'Block D', 'Shamol polli kalbat'],
      },
      {
        name: 'Mirpur 14',
        areas: ['Block B', 'Block C', 'Block D', 'Kafrul', 'Ibrahimpur'],
      },
      {
        name: 'Kafrul',
        areas: ['South kafrul', 'North kafrul', 'East Kafrul'],
      },
      {
        name: 'Balurghat',
        areas: [
          'Aziz market',
          'Hazi market,',
          'Bepari market',
          'Namapara Borobari',
          'Balurgat Bazar',
        ],
      },
      {
        name: 'manikdi',
        areas: [
          'Manikdi Bazar',
          'Ecb Chottor Bus Stand',
          'Namapara, Cantonment,',
        ],
      },
      {
        name: 'matikata',
        areas: ['West matikata', 'Vasantak Bazar', 'Baganbari'],
      },
      {
        name: 'Dhaka Cantonment',
        areas: [
          'Post office Road 1',
          'Post office Road 2',
          'Post office Road 3',
          'Post office Road 4',
          'Post office Road 5',
          'Post office Road 6',
          'Post office Road 7',
          'Post office Road 8',
          'Post office Road 9',
          'Post office Road 10',
          'Post office Road 11',
          'Stuff Road',
          'Nirjhor',
          'Belayat road',
          'Mostafa kamal line',
          'Mannan Line',
          'Zia colony',
          'Army Golf Club',
          'Kurmitola Medical College',
          'Balughat',
          'MES',
          'Old Airport',
          'BAF Officers Mess',
        ],
      },
      {
        name: 'Uttara Sector 4',
        areas: [
          'Road 2',
          'Road 3',
          'Road 4',
          'Road 5',
          'Road 6',
          'Road 7',
          'Road 8',
          'Road 9',
          'Road 10',
          'Road 11',
          'Road 12',
          'Road 13',
          'Road 14',
          'Road 15',
          'Road 16',
          'Road 17',
          'Road 18',
          'Road 19',
          'Road 20',
          'Road 21',
          'Road 22',
          'Azampur',
          'Jasimuddin',
          'Sector 2, Uttara',
        ],
      },
      {
        name: 'Uttara Sector 6',
        areas: [
          'Road 2',
          'Road 3',
          'Road 4',
          'Road 5',
          'Road 6',
          'Road 7',
          'Road 8',
          'Road 9',
          'Road 10',
          'Road 11',
          'Road 12',
          'Road 13',
          'Road 14',
          'Road 15',
          'Road 16',
          'Shahjalal Avenue',
          'Alal Avenue',
          'House Building',
        ],
      },
      {
        name: 'Uttara Sector 8',
        areas: [
          'Road 2',
          'Road 3',
          'Road 4',
          'Road 5',
          'Road 6',
          'Abdullahpur',
        ],
      },
      {
        name: 'Uttara Sector 1',
        areas: [
          'Road 2',
          'Road 3',
          'Road 4',
          'Road 5',
          'Road 6',
          'Road 7',
          'Road 8',
          'Road 9',
          'Road 10',
          'Road 11',
          'Road 12',
          'Road 13',
          'Road 14',
          'Road 15',
          'Road 16',
          'Airport',
          'Airport Custom House',
          'Rab Headquarter',
        ],
      },
      {
        name: 'Kawla',
        areas: [
          'Northern University',
          'Staff Quater',
          'Kawla Bazar',
          'Kawla Mondir',
          'Gowal Bari',
          'Jamtola',
          'Jomidar bari',
          'Dakhil Madrasha',
          'Noya Bari',
          'Bepari Bari',
          'Sandar tek',
          'Nama Para',
          'Mollah Bari',
          'Shamsul Ulum Madrasha',
          'FDD Complex',
        ],
      },
      {
        name: 'Uttarkhan',
        areas: [
          'Atipara',
          'Balur Math',
          'Adarsha Para',
          'Jamtola',
          'Madarbari',
          'Helal market',
          'Dobadiya',
          'Chamurkhan',
          'Kanchkura',
          'Jiyabagh',
          'Chapan',
          'Termukh',
          'Munda',
          'Kalabagan',
          'KuriPara',
          'ChanPara',
          'MoinarTek',
        ],
      },
      {
        name: 'Faidabad',
        areas: [
          'Master Para',
          'Chan Para',
          'Moinar Tek',
          'Mausaid',
          'Uzampur',
          'Transmiter',
        ],
      },
      {
        name: 'Dakshinkhan',
        areas: [
          'Army Society',
          'Chalaban',
          'CNG Pump',
          'Kachabazar',
          'Faidabad Chowrasta',
          'Dakshinkhan',
          'Koshaibari',
          'Prembagan',
          'Mollar Tek',
          'Holan',
          'Anol',
          'Dewan City',
          'Dewanbari',
          'Gawair',
          'Pandit Para',
          'Mollah Bari',
          'Chairman Bari',
          'Joynal Market',
          'Tic Colony',
          'Mojibor Market',
          'Farid market',
          'Nodda Para',
          'Ainus Bagh',
          'Airport Railway Station',
          'Choaritek',
          'Faydabad',
          'Mazar',
          'Sarulia',
          'DakkhinKhan Bazar',
          'Dobadiya',
          'Kachkura',
          'Hollan',
          'Sarder Bari',
          'Betuli',
          'Tetultola',
          'Shiyaldanga',
          'CAAB Quarter',
        ],
      },
      {
        name: 'Ashkona',
        areas: [
          'Parvin Hotel',
          'Al-Huda Masjid',
          'Islami Bank',
          'Shajalal Bank',
          'Sonali Garden',
          'Medical Road',
          'Uchartek Mor',
          'Ashkona College Road',
          'Rasul Bag',
          'City Complex',
          'Ashkona Primary School',
          'Ashkona Panir Pump',
          'Macher Arrot',
          'Rupali Garden',
          'Taltola',
        ],
      },
      {
        name: 'Turag',
        areas: [
          'Ahaliya',
          'Habib Market',
          'Priyanka City',
          'Hashur Bottola',
          'Diya bari',
          'Khalpar',
          'Nolvouge',
          'Fulbairya',
          'Shiraz market',
          'Nishat nagar',
          'Kamar para',
          'Dhour',
          'East West Medical College',
          'Ramjan market',
          'Noya nagar',
          'Chandalvouge',
          'Rajabari',
          'Prottasha',
          'Sholohati, Sector 18',
          'kalibari',
          'Aziz Market (Near Mirpur)',
          'Ashulia Baribadh',
          'Akij Foundation',
          'Rana Vola',
          'Golgolar Mor',
          'Siraj Market',
          'Beribadh',
          'Dharangertek',
          'Bamnartek',
          'Ranavola Bottola',
          'Rupayan City',
          'TararTek',
          'Jatrabari besides diabari',
        ],
      },
      {
        name: 'Bawnia',
        areas: ['Badaldi', 'Bawnia', 'Uludaha', 'Pakar Matha'],
      },
      {
        name: 'Uttara Sector 10',
        areas: [
          'Road 2',
          'Road 3',
          'Road 4',
          'Road 5',
          'Road 6',
          'Road 7',
          'Road 8',
          'Road 9',
          'Road 10',
          'Road 11',
          'Road 12',
          'Road 13',
          'Road 14',
          'Road 15',
          'Road 16',
          'Road 17',
          'Road 18',
          'Road 19',
          'Road 20',
          'Road 21',
          'Road 22',
          'Road 23',
          'Road 24',
        ],
      },
      {
        name: 'Uttara Sector 5',
        areas: [
          'Road 2',
          'Road 3',
          'Road 4',
          'Road 5',
          'Road 6',
          'Road 7',
          'Road 8',
          'Road 9',
        ],
      },
      {
        name: 'Uttara Sector 12',
        areas: [
          'Road 2',
          'Road 3',
          'Road 4',
          'Road 5',
          'Road 6',
          'Road 7',
          'Road 8',
          'Road 9',
          'Road 10',
          'Road 11',
          'Road 12',
          'Road 13',
          'Road 14',
          'Road 15',
          'Road 16',
          'Road 17',
          'Road 18',
          'Road 19',
          'Moylarmor',
        ],
      },
      {
        name: 'Uttara Sector 11',
        areas: [
          'Road 2',
          'Road 3',
          'Road 4',
          'Road 5',
          'Road 6',
          'Road 7',
          'Road 8',
          'Road 9',
          'Road 10',
          'Road 11',
          'Road 12',
          'Road 13',
          'Road 14',
          'Road 15',
          'Road 16',
          'Road 17',
          'Road 18',
          'Road 19',
          'Road 20',
          'Road 21',
        ],
      },
      {
        name: 'Uttara Sector 14',
        areas: [
          'Road 2',
          'Road 3',
          'Road 4',
          'Road 5',
          'Road 6',
          'Road 7',
          'Road 8',
          'Road 9',
          'Road 10',
          'Road 11',
          'Road 12',
          'Road 13',
          'Road 14',
          'Road 15',
          'Road 16',
          'Road 17',
          'Road 18',
          'Road 19',
        ],
      },
      {
        name: 'Uttara Sector 7',
        areas: [
          'Road 2',
          'Road 3',
          'Road 4',
          'Road 5',
          'Road 6',
          'Road 7',
          'Road 8',
          'Road 9',
          'Road 10',
          'Road 11',
          'Road 12',
          'Road 13',
          'Road 14',
          'Road 15',
          'Road 16',
          'Road 17',
          'Road 18',
          'Road 19',
          'Road 20',
          'Road 21',
          'Road 22',
          'Road 23',
          'Road 24',
          'Road 25',
          'Road 26',
          'Road 27',
          'Road 28',
          'Road 29',
          'Road 30',
          'Road 31',
          'Road 32',
          'Road 33',
          'Road 34',
          'Road 35',
          'Road 36',
        ],
      },
      {
        name: 'Uttara Sector 9',
        areas: [
          'Road 2',
          'Road 3',
          'Road 4',
          'Road 5',
          'Road 6',
          'Road 7',
          'Road 8',
          'Road 9',
          'Road 10',
          'Road 11',
          'Road 12',
        ],
      },
      {
        name: 'Uttara Sector 3',
        areas: [
          'Road 2',
          'Road 3',
          'Road 4',
          'Road 5',
          'Road 6',
          'Road 7',
          'Road 8',
          'Road 9',
          'Road 10',
          'Road 11',
          'Road 12',
          'Road 13',
          'Road 14',
          'Road 15',
          'Road 16',
          'Road 17',
          'Road 18',
          'Road 19',
          'Road 20',
          'Road 21',
          'Rajlaxmi',
        ],
      },
      {
        name: 'Uttara Sector 13',
        areas: [
          'Road 2',
          'Road 3',
          'Road 4',
          'Road 5',
          'Road 6',
          'Road 7',
          'Road 8',
          'Road 9',
          'Road 10',
          'Road 11',
          'Road 12',
          'Road 13',
          'Road 14',
          'Road 15',
          'Road 16',
          'Road 17',
          'Road 18',
          'Road 19',
          'Sector 13 Road 20',
          'Sector 13 Road 21',
        ],
      },
      { name: 'Uttara Sector 15', areas: ['KhanTek'] },
      {
        name: 'Dhanmondi',
        areas: [
          'Dhanmondi 1',
          'Dhanmondi 2',
          'Dhanmondi 3',
          'Dhanmondi 4',
          'Dhanmondi 5',
          'Dhanmondi 6',
          'Dhanmondi 7',
          'Dhanmondi 8',
          'Dhanmondi 9',
          'Dhanmondi 10',
          'Dhanmondi 11',
          'Dhanmondi 12',
          'Dhanmondi 13',
          'Dhanmondi 14',
          'Dhanmondi 15',
          'Dhanmondi 16',
          'Dhanmondi 27',
          'Dhanmondi 32',
          'Dhanmondi 1A',
          'Dhanmondi 2A',
          'Dhanmondi 3A',
          'Dhanmondi 4A',
          'Dhanmondi 5A',
          'Dhanmondi 6A',
          'Dhanmondi 7A',
          'Dhanmondi 8A',
          'Farmgate',
          'Dhanmondi 10A',
          'Dhanmondi 11A',
          'Dhanmondi 12A',
          'Dhanmondi 13A',
          'Shankar',
          'West Dhanmondi',
          'Genetic Plaza',
          'Bangladesh Eye Hospital',
          'Bangladesh Medical College',
          'Shimanto Square',
          'Shatmosjid Road',
          'Old Dhanmondi all roads',
        ],
      },
      {
        name: 'Shyamoli',
        areas: [
          'Golden Street',
          'Pc Culture Hosing',
          'Shyamoli Road 1',
          'Shyamoli Road 2',
          'Shyamoli Road 3',
          'Tajmohol road',
          'Babor road',
          'khilji road',
          'Humayun road',
          'Bijli Moholla',
          'Shyamoli square',
          'Tikka Para',
        ],
      },
      {
        name: 'Adabor',
        areas: [
          'Adabor Road 2',
          'Adabor Road 3',
          'Adabor Road 4',
          'Adabor Road 5',
          'Adabor Road 6',
          'Adabor Road 7',
          'Adabor Road 8',
          'Adabor Road 9',
          'Adabor Road 10',
          'Adabor Road 11',
          'Adabor Road 12',
          'Adabor Road 13',
          'Adabor Road 14',
          'Monsurabad',
          'Suchona Community Center',
          'Baitul Aman Housing Socity',
          'Shekhertekh Road 1',
          'Shekhertekh Road 2',
          'Shekhertekh Road 3',
          'Shekhertekh Road 4',
          'Shekhertekh Road 5',
          'Shekhertekh Road 6',
          'Shekhertekh Road 7',
          'Shekhertekh Road 8',
          'Shekhertekh Road 9',
          'Shekhertekh Road 10',
          'Japan Garden City',
          'Pc Culture Hosing Sekertak',
          'Chaduddan',
          'Krishi Market',
          'Nabi Nagar Housing',
          'Dhaka Uddan',
          'Mohammadia Housing Limited',
          'Nobodoy Housing Society',
          'Housing Society',
        ],
      },
      {
        name: 'Mohammadpur',
        areas: [
          'Lalmatia Block B',
          'Lalmatia Block C',
          'Lalmatia Block D',
          'Lalmatia Block E',
          'Lalmatia Block F',
          'Lalmatia Block G',
          'Arong',
          'Zakir Hossain Road',
          'Asad gate',
          'Asad Avenue',
          'Bosila',
          'Kaderabad housing',
          'Mokbul Hossain College',
          'Katashur',
          'Kaderabad',
          'Jafrabad',
          'Pulpar',
          'Iqbal road',
          'Shahjahan Road',
          'Nurjahan Road',
          'Rajia Sultana road',
          'Salimullah Road',
          'Mohammadpur Bus Stand',
          'Tajmahal Road',
          'Bashbari',
          'Geneva camp',
        ],
      },
      {
        name: 'Kallyanpur',
        areas: [
          'Gabtoli Bus Stand',
          'Darussalam Tower',
          'Kallyanpur Bus Stop',
          'Kallyanpur Road 1',
          'Kallyanpur Road 2',
          'Kallyanpur Road 3',
          'Kallyanpur Road 4',
          'Kallyanpur Road 5',
          'Kallyanpur Road 6',
          'Kallyanpur Road 7',
          'Kallyanpur Road 8',
          'Kallyanpur Road 9',
          'Kallyanpur Road 10',
          'Kallyanpur Road 11',
          'Kallyanpur Road 12',
          'Bagbari-Kallyanpur',
          'Golarteak',
          'Technical-Kallyanpur',
          'Bishil',
          'Technical',
          'Mirpur 60 FT',
          'Diabari (bottola to gabtoli)',
          'Gopipara',
          'Ansar camp-Kallyanpur',
        ],
      },
      {
        name: 'Agargaon',
        areas: [
          'Department of Environment',
          'BNP Bazar',
          'Bangladesh Technical Education Board',
          'Divisional Passport And Visa Office',
          'Sher-e bangla Agriculture University',
          'Shaheed Suhrawardy Medical College and Hospital',
          'National Parliament',
          'Chandrima Uddayan',
          'Bangabandhu International Conference Center',
          'IDB Bhaban',
          'Agargaon Bus Stop',
          'West Agargaon',
          'Shere Bangla Nagar',
          'Agargaon Taltola',
          'Shishu Mela',
          'Ganobhabon',
        ],
      },
      {
        name: 'Farmgate',
        areas: [
          'Razabazar',
          'Manik Mia Aveneu',
          'East Razabazar',
          'West Razabazar',
          'Khamarbari',
          'Tallabagh',
          'Monipuri para',
          'Tejkunipara',
          'Tejturi Bazar',
          'Farmgate',
        ],
      },
      {
        name: 'Kawranbazar',
        areas: [
          'Dhaka Trade Centre',
          'Karwan Bazar Post Office',
          'TCB Bhaban',
          'Sonargaon Signal',
        ],
      },
      {
        name: 'Panthapath',
        areas: [
          'Basundhara City',
          'Square hospital',
          'BRB Hospital',
          'Pathapath signal',
          'Shukrabad',
        ],
      },
      {
        name: 'Kalabagan',
        areas: [
          'Lake Circus',
          'Kalabagan 1st lane',
          'Kalabagan 2nd Lane',
          'Kalabagan 3rd lane',
          'Bashir Uddin Road',
          'Govt Quarter',
          'Molatab plaza',
          'Sobhanbagh',
        ],
      },
      {
        name: 'Green Road',
        areas: [
          'Green Corner',
          'Green Road Staff Quarter',
          'Central hospital',
          'Green Road',
        ],
      },
      {
        name: 'Savar',
        postCode: '1340',
        areas: [
          'Parboti Nagar',
          'Bhagolpur',
          'Doriyapur',
          'Katlapur',
          'Tarapur',
          'Imandipur Chowrasta',
          'Anandapur',
          'Majidpur',
          'Shahibag',
          'Dogormora',
          'CRP Road',
          'Garadge (Rajashon Road)',
          'Eicha Nowadda (Rajashon Road)',
          'Amtola Mowr (Rajashon Road)',
          'Delta Mowr (Rajashon Road)',
          'Rajashon Primary School',
          'Daroga Market (Rajashon Road)',
          'Nirmol Market (Rajashon Road)',
          'Polu Market (Rajashon Road)',
          'Nabinagar bus stand',
          'Kur Ga',
          'Nabinagar DOHS',
          'Polli bidyut',
          'Amin Model Town',
          'GajirChot , Bottola',
          'Palashbari',
          'Baipail',
          'Ashulia Thana',
          'New EPZ',
          'Old EPZ',
          'Bolivodro',
          'Sreepur',
          'Mojarmill',
          'Chowkroborti',
          'Nabi Teztile',
          'BKSP , Zirani',
          'Kbirpur',
          'Baroipara',
          'College Road Savar',
          'Chapra Moshjid',
          'Madrasa Moshjid',
          'Uttor Para',
          'Dakshin Para',
          'Nama Bazar',
          'Ghosh Para',
          'Kamal Road',
          'Ara Para',
          'Bazar Road Savar',
          'Rajar Lakh',
          'Shobuj Bag',
          'Sobhan Bag',
          'Bon Pukur',
          'Binod baid',
          'Roshid Member er mowr',
          'Chaya Bithi',
          'Aam Tola',
          'Jahangir Nagar Housing Society',
          'Ella Mopshjid Road',
          'Jaleshowor Road',
          'Radio Colony',
          'Bhatt Para',
          'Jamshing Society',
          'Nobin Market',
          'Solaiman Market',
          'Bohu Bazar',
          'Bpatc',
          'Nayabari',
          'City Center Savar',
          'Razzak Plaza',
          'Utshob Plaza',
          'Wapda Road Savar',
          'Malancha View',
          'Boktarpur',
          'Rari Bari',
          'Khaja Moshjid',
          'Kazi Mokma Para',
          'Shornopotti',
          'Shoronika',
          'Amin Community Center',
          'Khaleq Chainman Market',
          'Hijra Potti',
          'Bedey Para',
          'Bottola Savar',
          'Upozilla Pichon gate',
          'Anandapur Savar',
          'City Lane',
          'Pouroshova Gate',
          'Genda Pukur Paar',
          'Genda Chapra Moshjid',
          'Upozilla Gate Savar',
          'Rajabari',
          'Tia Bari',
          'Ulail',
          'Al-Muslim Garments',
          'Al-Madani Restaurant',
          'Korno Para',
          'Moilar Mor',
          'Bank Town Savar',
          'West Bank Town',
          'Bank Town Paglar Mowr',
          'Police Town',
          'Raj FulBaria',
          'Fulbaria Bazar',
          'Shovapur',
          'Varari Bottola',
          'JorPur',
          'Tetuljhora Mowr',
          "Padma'r Mowr",
          'Horin Dhora , Bagbari mowr',
          'Jamuna Garments',
          'Shympur club Matth',
          'Hemayatpur',
          'Joyna Bari',
          'Jadur Chor',
          'Tibbot Mowr',
          'Alam Nagar Housing',
          'Boliarpur',
          'Konda Bazar',
          'Hangail Bridge',
          'Konda High School',
          'Bhanga Bridge',
          'Amin Bazar Savar',
          'Salehpur',
          'Pachgathiya Madrasa',
          'Bordeshi Gram',
          'Jahangir Nagar University',
          'Bish Mile',
          'Prantik Gate',
          'Islam Nagar',
          'Geruwa',
          'Gokul Nagar',
          'Pandowa',
          'Dairy Gate',
          'VC Chottor',
          'BLRI',
          'Kuturiya',
          'Dewan Market',
          'Nikkon Housing',
          'AamTola',
          'Mack pie er Mowr',
          'Dewan Bari Stand',
          'Kathgora ChowRasta',
          'Ar Gents',
          'Alam Super Market',
          'Fashion.com , Durgapur',
          'Continental er Mowr',
          'Pukur paar',
          'Ghosh Baag',
          'Nishchintopur Savar',
          "Paaka'r Mowr",
          'Nishchintopur Praimary School',
          'Norshinghopur',
          'Ondho Colony',
          'Deko Design',
          'Yousuf Market',
          'Norshinghopur Garage',
          'Shorkar Market',
          'Nightingale Medical College & Hospital',
          '6 / Choi Tala',
          'Jamgora',
          'Fantasy Kingdom',
          'Baipail',
          'Unique Savar',
          'Nur-E-Madina Madrasa',
          'Tetultola',
          'Shin Shin er Mowr',
          'Jamgora Primary School',
          'Shimultola',
          'Gajirchot High School',
          'Aliya Madrasa',
          'Noya Bari',
          'BPATC',
          'Shenoliya',
          'Aam bagan',
          'Kathal Bagan',
          'Chowrongi',
          "Teacher's Quarter",
          'Shopno Nagar',
          'JU Campus',
          'Rouf Gate',
          'Podatik Gate',
          'CMP Center',
          'Nabinagar bus stand',
          'KUr Ga Society',
          'Gajir Chowt',
          'Sher-Ali Market',
          'Dholpur',
          'Dewan Market',
          'Jamgora Savar',
          'Enam Madical',
          'Muktir Mor',
          'Thana Stand',
          'radio Colony',
          'Nama Bazer',
          'bank Colony',
          'Bank towan',
          'Olail Bus Stand',
          'Hamayet pur',
          'Jamshing',
          'Noyarhat Dhamai',
          'Dhamrai Bazar',
          'Dhamrai Rotkhola',
          'Collage Road Dhamrai',
          'Niribili Bus Dhamrai',
          'Beltola Stand',
          '22 mail Dhamrai',
          'Islampur',
          'Dulibita Stand',
          'Joypura Bus Stand',
          'kalampur Bus Stand',
          'katlapur',
          'vahbanipur',
          'Bazar Road',
          'Kamal Road',
          'sobahanbag Savar',
          'Shubojbag Savar',
          'Boktar pur',
          'Vat Para',
          'Jahangirnagor Housing',
          'Nayabari radio Colony',
          'Savar Polli Bidyut',
          'Savar Narshinghapur',
          'Savar Charabag More',
          'Nabinagor',
          'CNB-Savar',
          'Kolma',
          'BurirBazar',
          'Bogabari',
          'Gazirchat',
          'Tongabari',
          'DoctorBari',
          'Anwar Jong Road',
          'Gonakbari',
          '6 Tala Bus Stop',
          'Itkhola',
          'Yarpur',
          'Bhuiyapara',
          'Vadail',
          'Hasem Plaza',
          'Chondrima Cinema Hall',
          'Jirani Bazar',
          'Cokroborti',
          'Savar',
          'Ganda-Savar',
          'Savar Bazar',
          'Kacha Bazar-Savar',
          'Khagan Bazar',
          'Doapara',
          'Savar Cantonment',
        ],
      },
      {
        name: 'Keraniganj',
        postCode: '1344',
        areas: [
          'kodomtoli khalper',
          'aganagar',
          'ambagicha',
          'notun shuvadda',
          'tajmohol road',
          'zila parishal road',
          'babor ali road',
          'aganagar choto/boro mosjid',
          'kadira hall',
          'thana road',
          'bottola',
          'chatgaon',
          'ferighat',
          'gulzerbag',
          'keranigonj madaripur',
          'potkajor',
          'torikulla road',
          'jawbari',
          'notun rasta',
          'BRT road',
          'mokampara',
          'chowdhury nagar',
          'alom tower',
          'nagarmohol',
          'ispahani',
          'kathuria',
          'hafej road',
          'tawapotti',
          'namabari',
          'guljerbagh',
          'rahomotpur',
          'rasulpur',
          'najorgonj',
          'mandail',
          'choto/boro kuisherbagh',
          'kalindi',
          'amirabagh',
          'borishur',
          'muslimbagh',
          'nekrojbagh math',
          'brammonkitta',
          'goderbagh',
          'atashur',
          'char khejurbag',
          'amin para',
          'boroitola',
          'kaligonj bazar',
          'char kutub',
          'noya bari',
          '1 no bari',
          'pakapul',
          'atipara',
          'chowdhury para',
          'charman bari road',
          'nazirabag',
          'chunkutia possimpara',
          'baganbari',
          'kalibari',
          'mohoripotti',
          'mather kona',
          'bathan bari',
          'mokka nagar',
          'modina nagar',
          'shuvadda poddopara',
          'shuvadda uttar para',
          'shanti nagar',
          'shanto para',
          'keranigonj digirpar',
          'gopper',
          'monubaparir dhal',
          'borhanibagh',
          'vagna',
          'golambazar',
          'bondakpara',
          'kodontoli gol chottor',
          'model town',
          'shohid nagar',
          'zia nagar',
          'begun bari',
          'koiborto para',
          'gosh bari',
          'chita khola',
          'shuvadda possim para',
          'shuvadda uttar para',
          'nazirabagh',
          'saban factory',
          'emam bari',
          'olinagar',
          'charkaligonj',
          'kaligonj bazar',
          'khejurbagh',
          'kaligonj boro mosjid',
          'pargandaria',
          'mirerbagh',
          'telghat',
          'affice math',
          'army camp',
          'chunkutia',
          'amritopur',
          'hawly',
          'old baby stand',
          'hijon tola bazar',
          'muslimabad',
          'muslim nagar',
          'Equria',
          'abdullahpur',
          'hasnabad',
          'bashundhora',
          'doleswer',
          'nazimuddin complex',
          'majar road',
          'Keraniganj central road',
          'mokampara',
          'bank colony',
          'Oshudh factory',
          'power house',
          'tila bari',
          'equria muslim nagar',
          'equria bepari para',
          'Addin hospital',
          'doleshar army camp',
          '3 raster mor',
          'dokkhin pangaon',
          'lal mosjid',
          'brammongaon',
          'oil mill road',
          'zarira board gard',
          'moyder mill',
          'sadur bazar',
          'bagoir',
          'Jail gate',
          'mosjid gate',
          'bonogram',
          'pangaon port',
          'uttar pangaon',
          'fokir market',
          'montri bari road',
          'dholewer college',
          'cholewer primary school',
          'check post',
          'white house',
          'basundhara park',
          'basundhara boro madrasha',
          'bashundhra office',
          'monurbagh',
          'sharighat',
          'ainta',
          'maltifood',
          'teguria',
          'zazira',
          'pangon',
          'bibirbazar',
          'Eastern bazar',
          'konakhola',
          'Ramerkanda',
          'rohitpur',
          'kharakandi',
          'talepur',
          'monohoria',
          'laboni point',
          'ghaterchar',
          'joy nagar',
          'khagail',
          'jia nagar',
          'bamonsur',
          'shakta',
          'noya bazar',
          'baralia',
          'baowal',
          'modhu city',
          'Dohar',
          'arshinagar',
          'bosila',
          'washpur',
          'ati bazar',
          'kolatia',
          'ronjitpur',
          'galimpur',
          'joypara',
          'narisha',
          'nowabgonj',
          'tikirpur',
          'komorgonj',
          'agla',
          'mazirkanda',
          'bandura',
          'bagmara',
          'Dhaka Jute Mills',
          'Kalatia',
          'Kadamtali',
          'Darigow',
          'Mokshudpur Unioun',
        ],
      },
    ],
    type: 'insideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Faridpur',
    zones: [
      {
        name: 'Alfadanga',
        postCode: '7870',
        areas: [
          'Alfadanga Bazar',
          'Falia',
          'Gopalpur West Para',
          'Gopalpur East Para',
          'Pachuria',
          'tagarbanda',
        ],
      },
      {
        name: 'Boalmari',
        postCode: '7860',
        areas: [
          'Amgram',
          'Barkhadia',
          'Chholna',
          'Chukinagar',
          'Kalianda',
          'Kamargram',
          'Ramnagar',
          'Saidpur',
          'Shibpur',
          'Sutasi',
          'Chatul',
          'Dadpur',
          'Ghoshpur',
          'Gunbaha',
          'Moyna',
          'Parameshwardi',
          'Rupapat',
          'Satair',
          'Shekhar',
          'Kamaleshwardi',
          'Kondardia',
          'Krishnanagar',
          'Kumarkhali Boalmari',
          'Madhabpur Boalmari',
          'Mobarakdia',
          'Natibdia',
          'Pashchim Bhatdi',
          'Purba Bhatdi',
          'Ranga Mularkandi',
          'Sarbandia',
          'Sugandhi',
          'Bahirnagar',
          'Bhimpur',
          'Chandibila',
          'Daitarkathi',
          'Deogaccha',
          'Dharmahati',
          'Gangi',
          'Gobindapur Boalmari',
          'Khamarpara',
          'Lankar Char',
          'Pabila',
          'Baguan',
          'Bhabanipur',
          'Chandani',
          'Dari Hariharnagar',
          'Dhopa Para',
          'Gurdia',
          'Hariharnagar',
          'Kamarhela',
          'Minajpur',
          'Muktarpur',
          'Sonanagar',
          'Umarnagar',
          'Belnaji',
          'Bil Kamrail',
          'Bil Sarail',
          'Char Ruijani',
          'Kandakul',
          'Khail Para',
          'Khorsuti',
          'Murail Purbapara',
          'Purba Charbarni',
          'Thakurpur',
          'Arazi Sreenagar',
          'Aurjug',
          'Baurkandi',
          'Bhabkhanda',
          'Bil Tamarhazi',
          'Chak Khajura',
          'Daharnagar',
          'Dhuljora',
          'Joypasha',
          'malikhali',
          'Mayandia',
          'Meghadanga',
          'Putanti',
          'Sarandi',
          'Sreenagar',
          'Surjuadia',
          'Tamarhazi',
          'Arazi Tetulia',
          'Banamalipur',
          'Bandapasha',
          'Binnahuri',
          'Bishnopur',
          'Deuli Boalmari',
          'Icchadanga',
          'Jagir Deuli',
          'Kadmi',
          'kalimajhi',
          'Kalinagar',
          'Katagarh',
          'Kumrail',
          'Mora',
          'Pacha Magura',
          'Purba Mora',
          'Surjargha',
          'Sutalia',
          'Tentulia',
          'Tongrail',
          'Arazi Paikhati',
          'Arazi Shibanandapur',
          'Boro Nagar',
          'Basudebpur',
          'Beradi',
          'Dariramdia',
          'Dobra',
          'Gola Joynagar',
          'Kadirdi',
          'Kankahardia',
          'Kayra',
          'Kersail',
          'Mahisala',
          'Majurdia',
          'Mohanpur',
          'Ramchandrapur',
          'Premtara',
          'Ramdia',
          'Rupdia',
          'Shibanandanpur',
          'Arazi Magura',
          'Bagdanga',
          'Barangkula',
          'Bayra Barangati',
          'Bhat Para',
          'Chatrakanda',
          'Dighirpar',
          'Durgapur',
          'Ganganandapur',
          'Magura',
          'Maitkumra',
          'Nidhipur',
          'Rakhaltali',
          'Roybari',
          'Sahasrail',
          'Sekhpura',
          'Sholkhadia',
          'Teljuri',
          'Pashchim Charbarni',
        ],
      },
      {
        name: 'Charbhadrashan',
        postCode: '7810',
        areas: [
          'Char Salepur',
          'Salepur',
          'Char Rehai Gopalpur',
          'Gopalpur',
          'Kalyanpur',
          'Mirzapur',
          'Muzafarabad',
          'Char Ajodhya',
          'Char Amarapur',
          'Char Maijuddin',
          'Char Mohanmia',
          'Char Sarbandia',
          'Char Sultanpur',
          'Gazir Tek',
          'Hajiganj',
          'Hossainpur',
          'Charbhadrashan',
          'Charbhadrasan',
          'Dakkhin Nawabgonj',
          'Char Harirampur',
          'Harirampur',
          'Jhaukanda',
          'Gazir Tek',
        ],
      },
      {
        name: 'Faridpur Sadar',
        postCode: '7800',
        areas: [
          'Bin Mamudpur',
          'Sadipur',
          'Dikrir chor',
          'Ambikapur',
          'Baitulaman Politecni',
          'Shriangan',
          'Komolapur',
          'Tentultola',
          'Aliabad',
          'Bhajandanga',
          'Bil Gazaria',
          'bil Mamudpur',
          'Chauhatta',
          'Gadadhardangi',
          'Sadipur',
          'Adampur',
          'Alipur',
          'Bhelabad',
          'Guhalakshmipur',
          'Komarpur',
          'Pashchim Ramkantapur',
          'Raghunandanpur',
          'Shobharampur',
          'Char Madhabdia',
          'Pashchim Tepakhola',
          'Purba Tepakhola',
          'Decreerchor',
          'Tepurakandi',
          'Faridpur Paurashava',
          'Bakhunda',
          'Bokail',
          'Dhuldi',
          'Gerda',
          'Habeli Doyarampur',
          'Ikri',
          'Jayar',
          'Kafura',
          'Kesab Nagar',
          'Nikhurdi',
          'Pasora',
          'Bishnupur',
          'Chak Bhabanipur',
          'Char Durgapur',
          'Char Nasirpur',
          'Borokhoda',
          'Fatehpur',
          'Gopalpur',
          'Akain',
          'Badarpur',
          'Bhelabaj',
          'Bhuakandi',
          'Chapaibil',
          'Char Krishnagar',
          'Lakshmikol',
          'Maharajpur',
          'Mallikpur',
          'Narasinghadia',
          'Narayanpur',
          'Paramanandapur',
          'Bagchar',
          'Chandipur',
          'Dayarampur',
          'Dhuldi Rajapur',
          'Gandia',
          'Ghanashyampur',
          'Joydebpur',
          'Khalilpur',
          'Shibrampur',
          'Bengdoba',
          'Betbaria',
          'Bhatpara',
          'Domrakandi',
          'Habeli Rampura',
          'Kabirpur',
          'Kachnail',
          'kaijuri',
          'Mangalkot',
          'Muraridaha',
          'Purba Gangabardi',
          'Saicha',
          'Samaspur',
          'Tambulkhana',
          'Tulagram',
          'Basu Narsinghadia',
          'Bhati Kanaipur',
          'Chahabaria',
          'Fursa',
          'Ghatakura',
          'Hoglakandi',
          'Ibrahimdi',
          'Jatarkandi',
          'Jhaukhola',
          'Kanaipur',
          'Karimpur',
          'Kosa Gopalpur',
          'Lakshmipur',
          'Mrigi',
          'Pakur para',
          'Purdia Kasimabad',
          'Raikali Khaskandi',
          'Ramkanda',
          'Rankali',
          'Rashiknagar',
          'Sonakunda',
          'Sonapacha',
          'Tentulia',
          'Bahadurpur',
          'Bara Bhagia',
          'Bara Madhabpur',
          'Batikamari',
          'Bhabanipur',
        ],
      },
      {
        name: 'Modhukhali',
        postCode: '7851',
        areas: [
          'Gomara',
          'Mitain Chandpur',
          'Mitain',
          'Raizadapur',
          'Arpara',
          'Bhubandi',
          'Gariadaha',
          'Niamatpur',
          'Rupdia',
          'Sidhlajuri',
          'Baikunthapur',
          'Baleshwar Ramakantapur',
          'Bamani Baliakandi',
          'Bandar Sankarpur',
          'Baspur Ramdia',
          'Char Chandana',
          'Char Lakshmipur',
          'Char Laujana',
          'Char Mahishapura',
          'Char Naopara',
          'Dhopaganti',
          'Khodabaspur',
          'Laujana Asapur',
          'Mahispur',
          'Mathurapur',
          'Mirzapur',
          'Mollahdangi Sreerampur',
          'Naopara Modhukhali',
          'Noabari Ghoshkandi',
          'Puran Madhukhali',
          'Madhukhali',
          'Raipur',
          'Bhabukdia',
          'Choto Gopaldi',
          'Dumain',
          'Gajna',
          'Jahapur',
          'Kamarkhali',
          'Madhukhali Bazar',
          'Megchami',
          'Madukhali',
          'Kamarkali',
        ],
      },
      {
        name: 'Nagarkanda',
        postCode: '7840',
        areas: [
          'Ballavdi',
          'Bhowal',
          'Charjochardi',
          'Dangi',
          'Fulsuti',
          'Gatti',
          'Jadunandi',
          'Kaichail',
          'Kodalia Shaheed Nagar',
          'Laskardia',
          'Majhardia',
          'Purapara',
          'Ramkantopur',
          'Ramnagar',
          'Sonapur',
          'Nagarkanda Bridge',
          'Talma',
        ],
      },
      {
        name: 'Bhanga',
        postCode: '7830',
        areas: [
          'Shuadi',
          'nidhirampur',
          'sulina',
          'oporpotri',
          'koikhali',
          'Purba Algi',
          'Bidyanandi',
          'Baliya',
          'manikdi',
          'Uttar Borodia',
          'Chor Balia',
          'Talkanda',
          'Shahmollikdi',
          'arambag chorkanda',
          'munshikanda',
          'Majhardia',
          'Shonakhola',
          'Purba Aruakandi',
          'Pashchim Aruakandi',
          'Chandra',
          'Naura',
          'nolia',
          'Poshchim Algi',
          'Shukhni',
          'Noyakanda',
          'Nagar Manikdi',
          'Choto Khardia',
          'Dakkhin Borodia',
          'Pirerchor',
          'Vor Manikdi',
          'Chorkanda',
          'Sharshakandi',
          'baliachora',
          'gunpaldi',
          'Poradia',
          'Bramhanpara',
          'Chatlarpar',
          'Ghoshgram',
          'Ishwardi',
          'Jangalpasha',
          'Karra',
          'Patrail',
          'Pukurpar',
          'Tarail',
          'Thanmatta',
          'Chumordi',
          'Wahedpur',
          'Burirpar',
          'Shibnagar',
          'Shubil',
          'Raghabpur',
          'Abdullahpur',
          'narayanpur',
          'masuabad',
          'Putiapara',
          'Noyakandi',
          'nodona',
          'Jagajibonpur',
          'Gojaria',
          'Bagbaria',
          'Kaluai',
          'Dokkhin Shaktola',
          'Uttar Shaktola',
          'Debpur',
          'Panch Baria',
          'Hatgao',
          'Borpit',
          'Manikpur',
          'Chanpotri',
          'Choukighata',
          'Bamonkanda',
          'Rashibpura',
          'sautikanda',
          'Hiraldi',
          'Gongadhorodi',
          'Dongarpar',
          'Khaminarbag',
          'Kumarkhali',
          'Mokrompatti',
          'Mathapara',
          'Laskardia',
          'Sharifabad',
          'Bibirkanda',
          'Khardia',
          'Mujrikanda',
          'Rajesshordi',
          'Hajrakanda',
          'Gopinathpur',
          'Hoglakandi',
          'Madhabpur',
          'Mohesshwadri',
          'Majhikanda',
          'Monsurabad',
          'Noapara',
          'Atra Basra',
          'Varilhat',
          'Bhasra',
          'Deora',
          'Dolkundi',
          'Kala Mridha',
          'Miapara',
          'Roy Nagar',
          'Saother',
          'Shonamayir Chor',
          'Bisarikanda',
          'Bramandi',
          'Chorabhita',
          'Jangalkanda',
          'Komorkanda',
          'Kaolibera',
          'Khatra',
          'Maijhail',
          'Matra',
          'Mukdoba',
          'Nishchintopur',
          'Pollibera',
          'Pararar',
          'Pranpur',
          'Utia',
          'Shekhpura',
          'Adampur',
          'Bil Bhara',
          'Fazilpur',
          'Goaldangi',
          'Jahanpur',
          'Lakshmipur',
          'Manikdaha',
          'Nazirpur',
          'Pukharia',
          'Rajapur',
          'Tulshighata',
          'Uttar Brahmankanda',
          'Abdullahbad',
          'Baliahati',
          'Bhadrakanda',
          'Duair',
          'Gazaria',
          'Koshadanga',
          'Nasirabad',
          'Sikdarkanda',
          'Bakpur',
          'Barra',
          'Bhangardia',
          'Bil Dhopdanga',
          'Dakkhin Akanbaria',
          'Dharmadi',
          'Fukurhati',
          'Fulmallik',
          'Gangadhardi',
          'Nurullaganj',
          'Uttar Akanbaria',
          'Bhadrasan',
          'Bil Chatal',
          'Charaldia',
          'Jandi',
          'Kafurpur',
          'Saraibari',
          'Tazurpur',
          'Uchabari',
        ],
      },
      {
        name: 'Sadarpur',
        postCode: '7820',
        areas: [
          'Akoter Char',
          'Bhashanchar',
          'Char Bishnupur',
          'Char Manair',
          'Char Nasirpur',
          'Dheukhali',
          'Krishnapur',
          'Narikelbaria',
          'Sadarpur',
          'Hat Krishapur',
          'Bishwa jaker Manjil',
        ],
      },
      {
        name: 'Saltha',
        postCode: '7820',
        areas: [
          'Akoter Char',
          'Ballabhdi',
          'Bhawal',
          'Gatti',
          'jadunandi',
          'Mazadia',
          'Ramkantapur',
          'Sonapur',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Madaripur',
    zones: [
      {
        name: 'Madaripur Sadar',
        postCode: '7900',
        areas: [
          'Pani chortro',
          'chowrasta',
          'Dorgashorif road',
          'lonchghat',
          'Puranbazar',
          'Mastaer coloni',
          'Kadpotti bridge',
          'UI school',
          'Niramoy hospital',
          'Pouroshova Madaripur Sadar',
          'Ukilpara',
          'Donovan school',
          'Tnt road',
          'Alzabir high school',
          'soiderbali road',
          'Kukrail road',
          'sohid baccho sorok',
          'Bagherpar',
          'PTI school road',
          'Hajirhawla',
          'Notun bridge',
          'khoajpur college',
          'Shorifbari',
          '1 no sokuni road',
          '2 no sokuni',
          'Santinogor',
          'Sobujbag Madaripur Sadar',
          'Badamtola road',
          'Tatibari',
          'Mother bajar',
          'Ghotokchor',
          'jhawdi',
          'Montarpol',
          'Amirabad',
          'Tormoguria',
          'Madaripur Sadar stadium',
          'Thanar shamne',
          'pathokkandi',
          'Judge court Madaripur Sadar',
          'Thantoli',
          'Notun bus stand',
          '9 no bridge',
          'khagdi',
          'Chormuguria',
          'Pearpur',
          'kumartek',
          'Hawsdi bazar',
          'Mithapur',
          'Siddikkhola',
          'chokdar bridge',
          'pollibiddut office',
          'Gogonpur bazar',
          'Tubba bazar',
          'Kalirbazar',
          'Srinodi',
          'hobigonj bridge',
          'Babnatola',
          'Ghotmazi',
          'Pachkhola',
          'Khoajpur',
          'jhaodi',
          'kendua',
          'Dudhkhali',
          'Mostofapur',
          'kalikapur',
          'Bahadurpur Madaripur Sadar',
          'dhurail',
          'shirkhara',
          'kunia',
          'Rasti',
          'chilarchor',
          'Charmugria',
          'Kulpaddi',
          'Habiganj',
          'Mustafapur',
          'Barhamganj',
          'Nilaksmibandar',
        ],
      },
      {
        name: 'Rajoir thana',
        postCode: '7911',
        areas: [
          'somaddar',
          'sadhurbridge',
          'rajoir stand',
          'tekerhat stand',
          'Sanerpar',
          'Thanarmor',
          'Kolabari',
          'Katherpool',
          'Amgran bridge',
          'Kodombari',
          'Bajidpur',
          'Amgram',
          'Master colony',
          'khalia',
          'paikpara Rajoir thana',
          'Horidasdi',
          'kobirajpur',
          'Bodorpasa',
          'Hosenpur',
        ],
      },
      {
        name: 'Kalkini Thana',
        postCode: '7920',
        areas: [
          'Vangabridge',
          'Pathoriapar',
          'Dholugram bazar',
          'Vurghata',
          'kalkini college',
          'kalkini thana',
          'kaligonj',
          'Taltola Kalkini Thana',
          'Sonmandi',
          'Basgari',
          'Anayed nogor',
          'Ali nogor',
          'Gopalpulpur',
          'Lokkhipur',
          'Chordowlod khan',
          'Koyaria',
          'Romjanpur',
          'Sahebrampur',
          'Sikarmongol',
        ],
      },
      {
        name: 'Dasar Thana',
        postCode: '',
        areas: [
          'Birmohon school',
          'Mollabarir rasta',
          'Maijpara',
          'Rendi tola',
          'Ghoserhat',
          'Katerpul',
          'Vawtoli madrasa',
          'Dasar bazar',
          'Botla bazar',
          'Baligram',
          'Kajibakay',
          'Nobogram',
        ],
      },
      {
        name: 'Shibchor Thana',
        postCode: '',
        areas: [
          'Shakpur bajar',
          'saheberhat',
          'shomvuk bridge',
          'Chanderchor',
          'chilarchor',
          'vennatala bazar',
          'keranibad',
          'hatirbagan',
          'Shibchorbazar',
          'Dc road',
          'jaduarchor road',
          'college mor',
          'Mohoripotti',
          'Umedpur',
          'Boheratola uttor',
          'Ditito khondo',
          'Boheratola dokkhin',
          'Vodrason',
          'Sonnasichor',
          'Bondorkhola',
          'Kutubpur',
          'Dotopara',
          'Chorjanajat',
          'Vandarikandi',
          'Siruoail',
          'Baskandi',
          'Kadirpur',
          'Madoborer chor',
          'Nilkhi',
          'Kathalbari khat',
          'Pacchor',
          'Surjanagar',
          'Dattopara',
          'Anando Bazar',
          'Noyabazar',
          'Utrail',
          'Siruail',
          'Bachamara',
          'Bajehar Chor',
          'Chor Bachamara',
          'Chor Dattopara',
          'Gupter Kandi',
          'Mogra Pukur Par',
          'Soulla',
          'Uttar Chor tajpur',
          'Badsha Kandi',
          'Moulovi Kandi',
          'Khoj Kandi',
          'Kholifa Kandi',
          'Majhi Kandi',
          'Gupter Kandi',
          'Tajpur',
          'Guyagachia',
          'Mogra',
          'Kalamirdha Bazar',
          'Chipaikandi',
          'Kharakandi',
          'Mojno Market',
          'Utrail Modder Bazar',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Gazipur',
    zones: [
      {
        name: 'Tongi',
        postCode: '1230',
        areas: [
          'Notun Bazar',
          'Poran Bazar',
          'Jamai Bazar',
          'Bou Bazar',
          'Chompakoli',
          'AnarKoli',
          'Shapla Koli',
          'BataGat',
          'Nodibondor',
          'Arichpur',
          'PanirTAnki',
          'Sher a Bangla Road',
          'Kachabazar',
          '3 tala Moshjeed',
          'Girls School road',
          'Shena Kollyan',
          'Ashraf shetu',
          'Bostapotti',
          'Nowakhalipotti',
          'Shorkaarbari',
          'Abeda',
          'Tongi Station road',
          'Miroshpara',
          'Jheenu MArket',
          'Amtoli',
          'Pagar',
          'Jaber & Jubayer',
          'Bot-tola',
          'Salamer eter khula',
          'Salamer atar call',
          'Kiron commishioner er bari',
          'Hazi Market',
          'Fokir Market',
          'Bisic',
          'T & T',
          'Gupalpur',
          'Dhaka dying road',
          'Pathan para',
          'K2 gat',
          'Shilmon',
          'morkun',
          'Mullar Garaz',
          'Sahara Market',
          'Naogao',
          '10 tola',
          'Maa Tower',
          'cocacola gate',
          'Murir Fectori',
          'Shorkari Koborosthan',
          'mill gat',
          'Monnu nagar',
          'Peoples ceramic',
          'Lal Moshjeed',
          'Truck stand',
          'Speed oil',
          'Aftab CNG',
          'Tistar gate',
          'Tongi',
          'Shinbari',
          'Gazipura',
          'Besic industrial area',
          '27 Bus Stand',
          'Nirash Para',
          'Nimtola-Tongi',
          'TNT Bazar',
          'Nishatnagar',
          'cheragali',
          'Tongi college gate',
          'Arshadnagar',
          'Datta Para-Tongi',
          'Badam',
        ],
      },
      {
        name: 'Tongi Cherag Ali',
        postCode: '1230',
        areas: [
          'Tistargat Bou bazar',
          'Hoque er moor',
          'Vadam',
          'Mudafa',
          'Beximco',
          'Popular',
          'SKF',
          'Drug international',
          'Prince appreal',
          'Madborbari Road',
          'Kosumuddin school',
          'Mondol Market',
          'Munshi Para',
          'Mainuddin Marked',
          'Akash Market',
          'Boro Dewra',
          'Soto Dewra',
          'Shing bari',
          'Concept moor',
          'Madrasha Bazar',
          'Rod mail',
        ],
      },
      {
        name: 'College gate',
        postCode: '1230',
        areas: [
          'Sultana Rajiya',
          'Shurtorongo',
          'Pilot school College gate',
          'marite school',
          'Muktar bari',
          'Babuler Dokan',
          'Mirti Bari',
          'Kholil Market',
          'Jaman Tower',
          'Shofiuddin',
          'Ambaar',
          'Vorer Haat',
          'rosulbag',
          'Moddho rosulbag',
          'Mullabari',
          'MullaBazar',
          'TVS Goli',
          'BonMala Road',
          'Hassan lene',
          'Dotto para',
          'House building',
          'Mullabari Pukur paar',
          'Hazi Market',
          'Primery school',
          'Osman Goni Road',
          'Mirdhabari road',
          'TVS Factory',
          'Shantibaag',
          'Brack Town',
          'Bonmala railgat',
          'Bonmala Mazar',
          'Johir Market',
          'Alom Market',
          'Riya Garments',
          'Koshai Bari',
          'Senior Madrasa',
          'Shahajuddin School',
          'Tara building',
          'Shofiuddin Bazar',
          'Hosen Market',
          'Ledu mulla road',
          'Highway police Karjaloy',
          'deegher paar',
          'Atar Potti',
          'Mulla Bari',
          'Ershadnogor',
          'Chankir Tek',
        ],
      },
      {
        name: 'Gazipura',
        postCode: '1230',
        areas: [
          'Asia Pump',
          'Bot tola',
          'Khapara 10 tola',
          'Ashamoni Park',
          'Tiler Ghati',
          'Guttia medicle',
          'International Medicle',
          '27 Moor',
          'Asia Hospital',
          'Moshar coil',
          'Tamirul Millat',
          'kazi Bari',
          'Baladi Ameen Moshjeed',
          'Shumon Praimery School',
          'Mondol Market',
          'Belatex',
          'Khortoil',
          '27 Chowrasta',
          'Shorif Market',
          'Masco Bazar',
          'Bkash goli',
          'ZomZom Tower',
          'Kaal',
          'BaganBari',
        ],
      },
      {
        name: 'Borobari',
        postCode: '1230',
        areas: [
          'Chairmen Bari',
          'Madbor Bari',
          'Chandura road',
          'Sultan Market',
          'Chandura Madrasa',
          'Gacha Road',
          'Forchuna',
          'Borobari',
          'Joybangla Road',
          'Jeebon Market',
          'Apon Market',
          'Baghar Market',
          'Khaikoor',
          'Akkas Market',
          'Showroprodeep teck',
          'Hydarabaad Primery school',
          'Hydarabaad Madrasa',
          'Sabed Market',
          'Laal bridge',
          'Hindubarir moor',
        ],
      },
      {
        name: 'Board Bazar',
        postCode: '1704',
        areas: [
          'City corporation office',
          'Hannan Group',
          'Sultan madicle road',
          'Hazi Market',
          'Jalal Market',
          'Mizan Market',
          'Jobbar Market',
          'Eakub Market',
          'polash Market',
          'Membar Market',
          'Bot tola road',
          'Paka Brige',
          'Jhajor',
          'Board Bazar Bot tola',
          'Tetul Tola',
          'Mridha Bari',
          'Mridha school',
          'Arabian goli',
          'Shumi Garments',
          'Soniya Garments',
          'IUT',
          'Kolomesshor',
          'Moiraan',
          'IUT road',
          'BNU',
          'Muktar Bari Road',
          'Unmokto',
          'Kamar jhuri',
          'Sign board',
          'Kolomesshor bridge',
          'Abu Sayeed Market',
          'Bohor Ali Market',
          'KK Tower',
          'Khal paar',
          'Busher mill',
          'Osman Goni Road',
          'Member Bari road',
          'Hazir Pukur',
          'Harican',
          'Harican road',
          'soydana',
          'joora building',
          'Notun Bazar',
          'Deger chala road',
          'Fine sweater',
          'Fokir Market',
          'Purbachol Rod mill',
          'Standerd ceramic',
        ],
      },
      {
        name: 'Bason',
        postCode: '1704',
        areas: [
          'Sharifpur',
          'Colombia',
          'Balaka Garments',
          'Sharifpur primery school',
          'Bijoy shoroni',
          '10 tola',
          'Kathoora',
          'Belmont',
          'Bashon shorok',
          'Mugorkhal',
          'Navanar moor',
          'Bypaas',
          'Square gate',
          'Kacha Bazar',
          'Notun Arot',
          'Puran Arot',
          'Golakata bridge',
          'Bashon Thana',
          'Islampur',
          'Vogra Bypass',
          'Bhawal Badre Alam College Chowrasta',
          'Dhirashom Joydebpur',
          'Chandna',
          'Chandona Chowrasta',
          'Chowdhury bari',
          'Bonorupa road',
          'Chowrasta truck stand',
          'Vasermil',
          'Hazipukur',
          'Hariken',
          'Molla Market',
        ],
      },
      {
        name: 'Gazipur Chowrasta',
        postCode: '1703',
        areas: [
          'Onupom Supar Market',
          'Borsha cenema hall',
          'Chowdhuri bari',
          'Vawal point',
          'Jahanara complex',
          'Moshjeed Merket',
          'Rohoman shopping Mall',
          'Sheba medicle road',
          'deghir chala road',
          'City medical road',
          'Vawal College',
          'Eta Hata',
          'Bhodre Alom College',
          'Unishe Tower',
          'Ulka cenema Hall',
          'T & T Road',
          'Telipara',
          'Brac Hospital',
          'Teck nogo Para',
          'Shalna',
          '10 tola road',
          'Coil Factory',
          'Robin Fectori',
          'BRTC',
          'Chokkhu Hospital',
          'Deshipara',
          'Montri Para',
          'Naga',
          'Great wall cety',
          'Apex hospital',
          'Ashaloy',
          'Shaheen Cadet school',
          'Laal deghir paar',
          'Warless',
          'Noljanee',
          'Borkot Shoroni',
          'Rowshon Shorok',
          'Gutam Fetory',
          'CID office',
          'Nayeem School',
          'Metro Politon',
          'Gazipur Sadar',
          'Bager Bazar Mowna',
          'Chowrasta Warelessgate',
          'Shalom Bari',
          'Taknogopara',
          'Salna',
          'Forest office',
        ],
      },
      {
        name: 'Joydebpur',
        postCode: '1703',
        areas: [
          'Lokkshmi pura',
          'BIDC',
          'Teen Shorok',
          'BRRI 1',
          'BRRI 2',
          'BARI',
          'Sheeb Bari',
          'Bank Colony',
          'Akbor Clenic',
          'Mondol Bari',
          'Bilashpur',
          'Munshipara',
          'Nowvanga',
          'titash',
          'DBL Group',
          'Majheer Khola',
          'MIST',
          'DUET',
          'Fakir Bari',
          'BIDC',
          'BOF',
          'BMTF',
          'Shimultoli',
          'Deshipara Road',
          'Mariyaly',
          'Takshal',
          'Chottor',
          'Shawpno',
          'Joydebpur Rail station',
          'Kolapotti',
          'Kazi Market',
          'Dhirasrom',
          'Korotpara',
          'Shamontopur',
          'Choto Dewra',
          'Gurosthan',
          'Deeghir paar',
          'TAX office',
          'Rajbari Math',
          'DC Office Joydebpur',
          'Judge court Joydebpur',
          'Panir Tanki',
          'Raj deegher paar',
          'Shodor Hospital',
          'TajUddin Medicle',
          'Cha Bagan',
          'Roth Khola',
          'Hazi Baag',
          'Counsilor office',
          'Niler Paar',
          'Mohila college Joydebpur',
          'Shaheen Cadet College',
          'Azimuddin College road',
          'Depu Commissioner er bari',
          'Madhob Bari',
          'Chaya Kunjo',
          'Milestone School',
          'Society School',
          'Society Moshjeed',
          'Nasir er moor',
          'Charuchattar',
          'Metropolitan College',
          'Dokkhin Chayabithi',
          'Uttar Chayabithi',
          'Jorrpukur',
          'Barudar Dhal',
          'Harinal',
          'Moddho chayabithi',
          'Hakkani Housing',
          '10 tola building',
          'Lalmati',
          'Joydebpur Housing Society',
          'Shoshan Moor',
          'Daroga Moshjeed',
          'Dhakkinan Joydebpur',
          'B.R.R',
          'Shibbari more',
          'Joydebpur Bus stand',
          'Rail gate-Joydebpur',
          'Ranibelas',
          'Kuniya',
          'Dakshin Chayabithy',
          'Jorpukhuri',
          'Kalne',
          'Pukur par',
          'Lalmathiya',
          'Loha gacha',
          'Neyamot shorok',
          'Fakir bari more',
          'ATI Campus',
          'Chottor Bazar',
          'Montri bari road',
          'Shantibag mosjid',
          'Bilaspur',
          'BSM Agriculture University',
        ],
      },
      {
        name: 'Sreepur',
        postCode: '1744',
        areas: [
          'Porabari',
          'RAB Training center',
          'Mirzapur Mastar Bari',
          'Veem Bazar',
          'Vawal Mirzapur',
          'Bongobondhu University',
          'Talha gat',
          'Bottola',
          'upileon er gate',
          'Ambar ghat',
          'Bangla Bazar Sreepur',
          'Rajendropur Chowrasta',
          'Rajendropur Bazar',
          'Rajendropur rail gat',
          'RP gate',
          'Pepsi gate',
          'Public school',
          'CID Math',
          'Beepshot Gat',
          'Dirt gate',
          'Shatiya bari',
          'Raja Bazar',
          'Reneta',
          'Sreepur',
          'Kewa Bazar',
          'VangaHati',
          'New Hope garments',
          'Sreepur Chowrasta',
          'Puraton Bazar-Sreepur',
          'Motkhola road',
          'Gorgoria',
          'Lichubagan',
          'Bairagir chala',
          'Gilar Chala',
          'Aaspara more',
          '1no.C&B Bazar',
          'Anchor road',
          'Baraider Chala',
          'Channa para',
          'Mawna bazar',
          'Sholing more',
          'Madical more',
          'Alauddin more',
          'Anandabazar-Sreepur',
          'Gazipur Bazar',
          'Chokpara',
          'Shemlapara',
          'Off the more',
          'Ronggila bazar',
          'Khan Bari more',
          'Dhonia bazar',
          'Hanu market',
          'Soilalt',
          'Loha Bazar',
          'Jogir shit',
          'Chair Bazar',
          'Shishu polli',
          'Rajendrapur',
          'BAT Campus',
          'Master bari',
          'Nandun',
          'Vimbazar',
          'Sikder Bari',
          'Kanajul',
          'Mirzapur Bazar-Sreepur',
          'Bahadurpur-Sreepur',
          'Dogori',
          'B.K bari',
          'Noapara-Sreepur',
          'Taltoly Monipur Market',
          'Tarek zia more',
          'Shorokghat',
          'Pirujali bazar',
          'Rajendrapur Canttoment',
          'RP Gate Bazar',
          'Zanakur bazar',
          'Rajendrapur bazar',
          'Fawgan bazar',
          'Dholadiya bazar',
          'Rajabari-Sreepur',
          'Barmi',
          'Tepirbari',
          'Tengra bazar',
          'Shatkhamar',
          'Bormi bazar',
          'Durlavpur',
          'Guntegor',
          'Sreepur bazar',
          'railgate',
          'Thanar more',
          'Naris poltri',
          'Cinema hall',
          'Mollabari mosjid',
          'vangna hati',
          'Satkhamair',
          'Kawraid',
          'Bashamur',
          'Boubi',
        ],
      },
      {
        name: 'Kapashiya',
        postCode: '1703',
        areas: ['Kapashiya notun Bazar', 'Kapashiya Puran Bazar'],
      },
      {
        name: 'Hotapara',
        postCode: '1703',
        areas: [
          'Member Bari',
          'Bagher Bazar',
          'Gorgoriya',
          'CNB',
          'Ansar Road',
        ],
      },
      {
        name: 'Mawna',
        postCode: '1703',
        areas: [
          'Kitab Ali',
          'Ali Palaza',
          'Moshjeed Market',
          'Eakub Ali Market',
          'MC Bazar',
          'Noyon pur',
          'RAk Ceramic',
          'Joyna Bazar',
        ],
      },
      {
        name: 'Bashon',
        postCode: '1703',
        areas: ['kodda', 'Baimail', 'Riazpump', 'Konabari'],
      },
      {
        name: 'Kaliakair',
        postCode: '1751',
        areas: [
          'Sultan Market',
          'Kazi Market',
          'Shardaganj',
          'Kashimpur stand',
          'Mowchak',
          'Kaliakaar Police fari',
          'Shafipur',
          'Apex',
          'Anser Academy',
          'Pollybiddut',
          'Zora Pump',
          'Ambag',
          'Chondra',
          'Hightec',
          'Microtec',
          'Hazibari Moor',
          'Kaliyakoor',
          'Nondon Park',
          'Baroipara Masjid',
          'Walton Hi-Tech',
          'Walton Micro-Tech',
          'Polli biddut-Kaliakair',
          'Shattar gate',
          'Uloshara',
          'Horinhate',
          'Grambangla',
          'Baroipara',
          'Khara zora',
          'Board ghor Bazar',
          'Baypass',
          'Kaliakoir bazar',
          'boliyadi',
          'Mouchak',
          'Jamtola bazar',
          'Lohakoir',
          'hatimara',
          'Vannara',
          'Nischintapur-Kaliakair',
          'Rotonpur-Kaliakair',
          'Board mill',
          'Shirirchala',
          'Boroibari',
        ],
      },
      {
        name: 'Kaliganj',
        postCode: '1722',
        areas: [
          'Mirer Bazar',
          'Pubail',
          'Kaliganj Mission',
          'Kapashiya Road',
          'Arikhola',
          'Nimtoli Kaliganj',
          'Kaliganj',
          'Vaoal Jamalpur',
        ],
      },
      {
        name: 'Gacha',
        postCode: '1704',
        areas: [
          'Kamarjuri',
          'Badsha Mia road',
          'Jazor',
          'Grosthan',
          'Signboard',
          'Targach',
        ],
      },
    ],
    type: 'dhakaSuburbs',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Gopalganj',
    zones: [
      {
        name: 'Gopalganj Sadar',
        postCode: '8100',
        areas: [
          'Bakchibari',
          'Mohammadpara',
          'Chadmari',
          'Bankpara',
          'Sobujbagh',
          'Court Mosque',
          'Model School',
          'Post Office mor',
          'Uddayan road',
          'Bottola',
          'Gohata',
          'Gullibarir mor',
          'Jonota Road',
          'Nichupara',
          'Arambagh',
          'Layeker mor',
          'Kazi office road',
          'Mia para',
          'Bedgram',
          'Kuadanga',
          'Gatepara',
          'Bisic Bridge',
          'Launch Ghat',
          'Chowrangi',
          'DC road',
          'Thana para',
          'Puran Bazar Road',
          'Nuton Bazar road',
          'Sihkderpara',
          'Shahapara Gopalganj Sadar',
          'Moulovipara Gopalganj Sadar',
          'Masterpara Gopalganj Sadar',
          'Gosher chor',
          'Islampara',
          'Jail khana road',
          'Mia bari',
          'Markaz moholla',
          'Gopalganj Sadar Hospital',
          'Shonakur',
          'Nobinbagh',
          'Pachuria',
          'Bongobondhu shorok',
          'Bangobondhu College',
          'Teguria',
          'Gopalganj Sadar Upozila',
          'Borashi',
          'Baultali Gopalganj Sadar',
          'Chandra Dighalia',
          'Durgapur Gopalganj Sadar',
          'Gobra',
          'Gopinathpur Gopalganj Sadar',
          'Haridaspur',
          'Jalalabad Gopalganj Sadar',
          'Kajulia',
          'Karpara Gopalganj Sadar',
          'Kati',
          'Latifpur Gopalganj Sadar',
          'Majhigati',
          'Nizra',
          'Paikkandi',
          'Raghunathpur Gopalganj Sadar',
          'Sahapur Gopalganj Sadar',
          'Satpar',
          'Suktail',
          'Ulpur',
          'Upafi',
          'Ghosherchar',
          'Charmanikdha',
          'Ghonapara',
          'Police Line-Gopalganj Sadar',
          'Arpara -Gopalganj Sadar',
          'East Arpara',
          'Malenga',
          'Andarkotha',
          'Barfa',
          'Tetuliya',
          'Modhumoti',
          'Fukra',
        ],
      },
      {
        name: 'Kashiani',
        postCode: '8130',
        areas: [
          'Hataira',
          'Kashiani',
          'Mahespur Kashiani',
          'Mahmudpur Kashiani',
          'Nijamkandi',
          'Orakandi',
          'Parulia Kashiani',
          'Puishur',
          'Pukra',
          'Rajpat',
          'Ratail',
          'Sajail',
          'Singa',
          'Ramdia College',
          'Jonapur',
        ],
      },
      {
        name: 'Kotalipara',
        postCode: '8130',
        areas: [
          'Amtali Kotalipara',
          'Bandhabari',
          'Ghagar',
          'Hiran',
          'Kalabari',
          'Kandi',
          'Kushla',
          'Pinjuri',
          'Radhaganj',
          'Ramshil',
          'Sadullapur Kotalipara',
          'Shuagram',
        ],
      },
      {
        name: 'Muksudpur',
        postCode: '8142',
        areas: [
          'Bhabrasur',
          'Bhabrasur Bazar',
          'Bahugram',
          'Boaila',
          'Kalinagar',
          'Bamonpara',
          'Banshbaria',
          'Baliyakandi Bazar',
          'Jhutigram',
          'Garolgati',
          'Majhigati Muksudpur',
          'Tarail Muksudpur',
          'Bahugram Bazar',
          'Batikamari Muksudpur',
          'Batikamari Bazar',
          'Chaocha',
          'Holudvita',
          'Bahara Muksudpur',
          'Dignagar',
          'Dignagar Bazar',
          'Borni',
          'Fotepotti',
          'Bibirkandi',
          'Boroiitola',
          'Gobindapur Muksudpur',
          'Gohala',
          'Jalirpar',
          'Kasalia',
          'Kasalia Bazar',
          'Khandarpar',
          'Khandarpar Bazar',
          'Maharajpur Muksudpur',
          'Shimultola Bazar',
          'Bezra',
          'Mochna',
          'Mochna Bazar',
          'Nanikhir',
          'Nanikhir Bazar',
          'Pasargati',
          'Pasargati Bazar',
          'Raghdi',
          'Ujani Muksudpur',
          'Ujani Bazar',
          'Ujani place',
          'Garakhola',
          'Adampur',
          'Vaquri',
          'Gobindapur Bazar',
          'Old Muksudpur',
          'Bongram Muksudpur',
          'Lohair',
          'Nowhata',
          'Aikhdia',
          'Khanpura',
          'Paikdia',
          'Bahirbagh',
          'Krisnaidia',
          'Kamaldia',
          'Chorprosonnodi',
          'Molladi',
          'Srijutpur',
          'Chagolchira',
          'Jalilpar Bazar',
          'Jalilpar Bus Stand',
          'Baniarchor',
          'Shantipur',
          'Gohala Bazar',
          'Shindiaghat',
          'Bamondanga Muksudpur',
          'Prosonnopur',
          'Komlapur Muksudpur',
          'Tangrakhola',
          'Gopinathpur Muksudpur',
          'Chondripordi',
          'Upojela',
        ],
      },
      {
        name: 'Tungipara',
        postCode: '8121',
        areas: [
          'Barni',
          'Dumuria Tungipara',
          'Gopalpur Tungipara',
          'Kushli',
          'Patgathi',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Jamalpur',
    zones: [
      {
        name: 'Bakshiganj',
        postCode: '2140',
        areas: [
          'Bakshiganj',
          'Bagar Char',
          'Battajore',
          'Merur Char',
          'Shadhur Para',
        ],
      },
      {
        name: 'Islampur',
        postCode: '2020',
        areas: [
          'Kulkandi',
          'Gaibandha',
          'Goaler Char',
          'Char Goalini',
          'Char Putimari',
          'Chinadulli',
          'Noarpara',
          'Patharsi',
          'Palbandha',
          'Belgachha',
          'Sapdhari',
          'Durmoot',
        ],
      },
      {
        name: 'Dewangonj',
        postCode: '2022',
        areas: [
          'Char Aomkhaoa',
          'Chikajani',
          'Chukaibari',
          'Dangdhara',
          'Dewanganj',
          'Par Ramrampur',
          'Bahadurabad',
          'Hatebhanga',
          'Dewangonj S. Mills',
        ],
      },
      {
        name: 'Madarganj',
        postCode: '2040',
        areas: [
          'Karaichara',
          'Gunaritala',
          'Char Pakerdaha',
          'Jorekhali',
          'Balijuri',
          'Sidhuli',
        ],
      },
      {
        name: 'Melandaha',
        postCode: '2040',
        areas: [
          'Kulia',
          'Ghosher Para',
          'Char Banipakuri',
          'Jhaugara',
          'Durmut',
          'Nayanagar',
          'Nangla',
          'Fulkocha',
          'Mahmudpur',
          'Shaympur',
          'Malancha',
        ],
      },
      {
        name: 'Jamalpur Sadar',
        postCode: '2000',
        areas: [
          'Kendua',
          'Ghoradhap',
          'Titpalla',
          'Tulsir Char',
          'Digpaith',
          'Narundi',
          'Banshchara',
          'Meshta',
          'Rashidpur',
          'Ranagachha',
          'Lakshmir Char',
          'Sharifpur',
          'Sahabajpur',
          'Sreepur',
          'Bash Char',
          'Itail',
          'Tamaltola mor',
          'Bakultala mor',
          'College road',
          'Jamalpur medical road',
          '5 rastha mor',
          '4 rasatar mor',
          'Foujdari mor',
          'bank colloni',
          'Jamalpur general hospital',
          'Jamalpur pouroshava',
          'Govt Ashek Mahmud college',
          'Jamalpur mohila college',
          'Sardar para',
          'Puraton Pouroshava',
          'Jamalpur gate par',
          'Sokal bazar',
          'Ranigonj bazar',
          'Baniabazar',
          'Jamalpur sherpur bridge',
          'Anondo bazar',
          'Sharifpur Bazar',
          'United powerplant',
          'Kothakoli market',
          'Jamalpur boro mosjid',
          'Amlapara',
          'BGb camp',
          'Fulbaria',
          'Nandina',
          'Tulshipur',
        ],
      },
      {
        name: 'Sarishabari',
        postCode: '2050',
        areas: [
          'Kazla',
          'Kamalpur',
          'Kutubpur',
          'Chandan Baisha',
          'Chaluabari',
          'Narchi',
          'Fulbari',
          'Bohail',
          'Bhelabari',
          'Sariakandi',
          'Hat Sherpur',
          'Gunerbari',
          'Bausee',
          'Jagannath Ghat',
          'Pingna',
          'Jamuna Sar Karkhana',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Kishoreganj',
    zones: [
      { name: 'Bhairab', postCode: '2300', areas: ['Bhairab Bazar Tinpotty'] },
      {
        name: 'Kishoreganj Sadar',
        postCode: '2300',
        areas: [
          'Rothkhola',
          'shilpokola road',
          'SES mor',
          'atimkhana road',
          'jelashoronir mur',
          'Bus stand Kishoregonj Sadar',
          'monipur ghat',
          'akrampur Bus stand',
          'station road',
          'Khan collage',
          'Eid gah math',
          'Gach Bazar',
          'Bonani mor',
          'Chor Sulakia',
          'islamiya market',
          'Boro bazar Kishoregonj Sadar',
          'gourango Bazar',
          'Boilaa',
          'Gaital bus stand',
          'Farm er mor',
          'police line Kishoregonj Sadar',
          'circuit house',
          'jonota school road',
          'pani unnoyon board',
          'purano court',
          'new stadium',
          'bokultola mor',
          'nilgonj mor',
          'batar bhaban',
          'awami league office',
          'pagla mosjid mur',
          'college mor Kishoregonj Sadar',
          'day night hospital',
          'shaheb bari mor',
          'B.a.d.c mor',
          'bothtola',
          'khidma hospital',
          'new polli',
          'methor potti',
          'ukilpara',
          'feasahare road',
          'fire services',
          'tomal tola school',
          'protom mur',
          'animal hospital',
          'azimoddin school',
          'tarapasha',
          'hafejiya madrasha',
          'tinpotti',
          'gourango Bazar mur',
          'notun polli',
          'pouro mohila college',
          'ideal school',
          'luknath mondir',
          'shamoli road',
          'hasmot school',
          'binnogaon',
          'nama para',
          'mohila college Kishoregonj Sadar',
          'adorsho school',
          'jura pokur par',
          'Kishoreganj S.Mills',
          'Maizhati',
        ],
      },
      {
        name: 'Bajitpur',
        postCode: '2336',
        areas: [
          'Johirul Islam medical',
          'Shouorachor',
          'Agurpur',
          'Sararchar',
          'Bhagalpur',
          'Laksmipur',
        ],
      },
      {
        name: 'Hossainpur',
        postCode: '2320',
        areas: [
          'rampur bazar',
          'chor pumdi',
          'bazar',
          'hossinpur bazar',
          'Hospital mor Hossainpur',
          'basic shilpo nogori',
          'Syed najrul madical',
          'Sheddeshowri barir mur',
          'Hossainpur-Hossainpur',
        ],
      },
      {
        name: 'Karimganj',
        postCode: '2310',
        areas: [
          'Abdul hamid medical',
          'Katiadi Bus Stand',
          'jafrabad',
          'niyamothpur',
          'morichkhali',
        ],
      },
      {
        name: 'Kuliarchar',
        postCode: '2341',
        areas: [
          'Manikkhali Bazar',
          'Pularghat Bazar',
          'Kuliarchor bazar',
          'nandil chu rasta mur',
          'Chhoysuti',
        ],
      },
      {
        name: 'Pakundia',
        postCode: '2326',
        areas: ['Binnati', 'Pakundia pilot school'],
      },
      { name: 'Tarial', postCode: '2316', areas: [] },
      { name: 'Nikli', postCode: '2360', areas: ['Nikli Bazar'] },
      { name: 'Mithamoin', postCode: '2370', areas: ['Abdullahpur'] },
      { name: 'Itna', postCode: '2390', areas: [] },
      { name: 'Astagram', postCode: '2380', areas: [] },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Manikganj',
    zones: [
      {
        name: 'Manikganj Sadar',
        postCode: '1800',
        areas: [
          'Debendra College',
          'Gongadhorpotti',
          'Bonogram',
          'Khan Bahadur College',
          'Kali Bari Manikganj Sadar',
          'Tora',
          'Krishnopur',
          'Lokhonda',
          'Kawarjani',
          'Porra',
          'Shib Bari',
          'Nag Bari',
          'Hijuli',
          'Siddiq Nagar',
          'Dashora',
          'Noyakandi',
          'Zorina College',
          'Bewtha',
          'Baroil Bazar',
          'Nabogram Bazar',
          'Saropai Bazar',
          'Bandutiya',
          'Sewta',
          'Manikganj Bus Stand',
          'Gorpara',
          'Bangladesh Hat',
          'Molla Bazar',
          'Jagir',
          'Gilondo',
          'Powli',
          'Motto',
          'Joyra',
          'Cornel Malek Medical College Hospital',
          'Ghosher Bazar',
          'Dawtiya Bazar',
          'Profesor Houseing',
          'L.G.D Road',
          'C.R.P Road',
          'Romzan Ali Road',
          'Manora',
          'Diyara vabnipur',
          'Katigram',
          'Atigram',
          'Dhighi',
          'Bharaia',
          'Hatipara',
          'putail',
          'Manikganj Bazar',
          'Sheuta',
          'Beutha',
          'Zarina callage',
          'Lonce ghat',
          'Kalibari',
          'purra',
          'Kachari Math',
          'Gangadhar patti',
          'Chandair',
          'Garpara',
          'Theguri',
          'Alinagar',
          'Mokimpur',
          'Barahirchor',
          'Barai vhikora',
        ],
      },
      {
        name: 'Singair',
        postCode: '1822',
        areas: [
          'Davoltoli',
          'Bayra',
          'Islamnagar',
          'Rishipara',
          'Notun Bazar Singair',
          'Singair Bazar',
          'Joymontop',
          'Vumdokkhin',
          'Jayegir',
          'Dholla',
          'Frodnagar',
          'Shahorail',
        ],
      },
      {
        name: 'Harirampur',
        postCode: '1830',
        areas: [
          'Jhitka',
          'Bolora',
          'Kouri College Mor',
          'Balirtak',
          'Malakarbari Mor',
          'Hatipara',
          'Bongkhori',
          'Basudebpur',
          'Gala',
          'gilondo',
          'Monno medical',
          'kalta',
          'Bathaimuri',
          'koyra',
          'Lechhraganj',
        ],
      },
      {
        name: 'Ghior Thana',
        postCode: '1840',
        areas: [
          'Basta',
          'Jabra',
          'Gheor Bazar',
          'Monno Medical College',
          'Mailagi',
          'Pacharkanda',
          'Baliyakhora',
          'Pukhuriya',
          'Joka',
          'Poyla',
          'Singzuri',
          'Nalee',
        ],
      },
      {
        name: 'Shivalaya',
        postCode: '1850',
        areas: [
          'Borongail',
          'Tapra',
          'Uthuli',
          'Nobogram',
          'Amdala',
          'Aricha',
          'Tewta',
          'Paturia',
          'Pukhuria',
          'Dhlondy',
          'Teuta',
          'Shimuli',
          'Ulail',
          'Arua',
          'Pachuria',
          'Datora',
          'Barangail bus',
          'Nippon Paint',
          'Modhumoti',
          'Sharasin',
        ],
      },
      {
        name: 'Daulatpur - Manikganj',
        postCode: '1860',
        areas: [
          'Niluyar Mor',
          'Mandatta',
          'Chairman Bari',
          'Upozila',
          'Daulatpur - Manikganj',
        ],
      },
      {
        name: 'Saturia',
        postCode: '1810',
        areas: [
          'Golora',
          'Noyadingi',
          'Barobaria',
          'Katikgram',
          'Baliati Jomidar Bari',
          'Baliati Bus Stand',
          'Nahar Garden',
          'Janna',
          'Kandapara',
          'Horgoj',
          'Fukurhati',
          'Dorogram',
          'Tilli',
          'Railla Bazar',
          'Kazi Bari',
          'Dhulla',
          'Vatara',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Munshiganj',
    zones: [
      {
        name: 'Munshiganj Sadar',
        postCode: '1500',
        areas: [
          'Kachari',
          'Khalist',
          'Munshirhat',
          'Katakhali',
          'Gonokpara',
          'Devor',
          'loharpool',
          'Shordarpara',
          'Courtgaon',
          'Munshiganj Sadar Supermarket',
          'Hat laxmiganj',
          'Idrakpur',
          'Noyagaon',
          'Manikpur',
          'Mukterpur',
          'Rikabi Bazar',
          'Malpara',
          'Mirkadim',
          'Bagmahamud Ali Para',
          'Shilmondi',
          'Munshiganj Sadar Islambag',
          'Munshiganj Sadar Islampur',
          'Rotonpur',
          'Majhi Barir Math',
          'Shipahipara',
          'Noyapara',
          'Ronso',
          'Ruhitpur',
          'Shatanikhil',
          'Makahati',
          'idrakpur fort',
          'middle courtgaon',
          'rampal',
          'loharpool',
          'bangla bazar',
          'choddumuria',
          'panchgharia kandi',
          'nalbunia kandi',
          'char mosura',
          'Munshiganj Sadar fultola',
          'bagahia kandi',
          'firingi bazar',
          'sadar haspital road',
          'sadar thana',
          'jomidar para',
          'chorkishorganj',
          'feri ghat',
          'panna hall',
          'rotonpur',
          'rajarchor',
          'khasherhat bazar',
          'holarchor',
          'chormosura',
          'jajira',
          'raribari',
          'jail khana road',
          'methorpotti',
          'mollarchor',
          'Munshiganj Sadar police line',
          'puran bus stand',
          'Jugnighat',
          'boikhor',
          'Khaskandi',
          'Romjanbeg',
          'Khasherhat',
          'Ghuhakandi',
          'Dewan Bazar',
          'Maligaon',
          'Mirapara',
          'Malirpathor',
          'Doyal Bazar',
          'Bilaikani',
          'Dholagaon Bazar',
          'Rampal',
          'Modina Bazar',
          'Chodri Bazar',
          'Bangla Bazar',
          'Choddumuria',
          'Kathakhali',
        ],
      },
      {
        name: 'Tongibari',
        postCode: '1523',
        areas: [
          'Baligaon',
          'paikpara',
          'bajrozogoni',
          'Dhamaron',
          'Boruliya',
          'Shonarong',
          'rongmeher',
          'Hashail',
          'ramshing',
          'nahapara',
          'Tongibari abdullahpur',
          'Betka',
          'Aldi Bazar',
          'Dhighirpar',
          'Sherajabad',
          'Pura Bazar',
          'kathadia',
          'Shimuliya',
          'Shotkewar',
          'kamarkhara',
          'Kewar',
          'aldi bazar',
          'modina bazar',
          'dholagaon',
          'Tongibari rampal',
          'chander bazar',
          'shiddessori bazar',
          'airolbazar',
          'tongibari thana',
          'togibari chowrasta',
          'togibari bazar',
          'chot kewar',
          'Dekrapara',
          'Vangga',
          'Bajrajugini',
          'Hasail',
          'Dighirpar-Tongibari',
        ],
      },
      {
        name: 'Sirajdikhan',
        postCode: '1543',
        areas: [
          'taltola bazar',
          'dhaliambar',
          'kakaldi',
          'ichapura',
          'shirajdhikhan sub register office',
          'imamganj',
          'shirajdikhan bazar',
          'shirajdhikhan thana',
          'shirajdhikhan graminbank office',
          'balurchor',
          'shukher thikana',
          'rajanagar',
          'shirajdhikhan upozila office',
          'rajdia',
          'ghoradhour',
          'Alamin Bazar',
          'Nimtoli',
          'Kola',
        ],
      },
      {
        name: 'Gazaria',
        postCode: '1512',
        areas: ['Hossendi', 'Rasulpur-Gazaria'],
      },
      {
        name: 'Lohajong',
        postCode: '1533',
        areas: [
          'Bejgaon',
          'Khaidir Para',
          'Baultali',
          'kanaksar',
          'Kumarbhog',
          'Haldia',
          'Medini mandal',
          'Teotia',
        ],
      },
      {
        name: 'Sreenagar',
        postCode: '1557',
        areas: [
          'Baghra Sreenagar',
          'Baraikhali',
          'Bhagyakul',
          'Birtara',
          'Hasara',
          'Kolapara Sreenagar',
          'Kukutia',
          'Patabhog',
          'sholloghor',
          'Holodiya',
          'ghordhour',
          'tantar',
          'hashara',
          'rarikhal',
          'shyamsiddhi',
          'sreenagar thana',
          'm rahman market',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Mymensing',
    zones: [
      {
        name: 'Ishwarganj',
        postCode: '2280',
        areas: [
          'Uchakhila',
          'Jatia',
          'Tarundia',
          'Ishwarganj Bus stand',
          'Ishwarganj Thana',
          'Ishwarganj Upazila Health Complex',
          'Ishwarganj Bazar',
          'Ishwarganj Station',
          'Sohagi',
          'Usthi',
        ],
      },
      {
        name: 'Gafargaon',
        postCode: '2230',
        areas: [
          'Gaffargaon Bus stand',
          'Gaffargaon Thana',
          'Gaffargaon Upazila Health Complex',
          'Gaffargaon Bazar',
          'Gaffargaon Station',
          'Gaffargaon Cng Stand',
          'Barabaria',
          'Mashakhali',
          'Jessora',
          'Rasulpur',
          'Raona',
          'Langair',
          'Saltia',
          'Shibganj',
          'Usti',
          'Kandipara',
          'Duttarbazar',
        ],
      },
      {
        name: 'Gauripur',
        postCode: '2270',
        areas: [
          'Gauripur',
          'Dowhakhala',
          'Bokainagar',
          'Bhangnamari',
          'Mailakanda',
          'Maoha',
          'Ramgopalpur',
          'Sahanati',
          'Sidhla',
          'Gouripur Bus stand',
          'Gouripur Thana',
          'Gouripur Upazila Health Complex',
          'Gouripur Bazar',
          'Gouripur Station',
        ],
      },
      {
        name: 'Trishal',
        postCode: '2220',
        areas: [
          'Kanthal',
          'Kanihari',
          'Trishal High School Road',
          'Dhanikhola',
          'Bali Para',
          'Bailar',
          'Mathbari',
          'Mokshapur',
          'Rampur',
          'Sakhua',
          'Harirampur',
          'Trishal Bus stand',
          'Trishal Bazar',
          'Trishal Thana',
          'Trishal Health Complex',
          'Trishal Kabi Nazrul University',
          'Ahmadbad',
          'Ram Amritaganj',
          'Dhala',
          'Porabari',
        ],
      },
      {
        name: 'Dhobaura',
        postCode: '',
        areas: [
          'Dhobaura Thana',
          'Dhobaura Bazar',
          'Dhobaura Cng stand',
          'Dhobaura Boro Mosjid',
          'Pora Kandulia',
          'Baghber',
        ],
      },
      {
        name: 'Nandail',
        postCode: '2290',
        areas: [
          'Kharua',
          'Gangail',
          'Chandipasha',
          'Jahangirpur',
          'Nandail',
          'Betagair',
          'Musuli',
          'Moazzempur',
          'Rajgati',
          'Sherpur',
          'Singrail',
        ],
      },
      {
        name: 'Phulpur',
        postCode: '2250',
        areas: [
          'Kamargaon',
          'Kamaria',
          'Galagaon',
          'Sandhara',
          'Dhakua',
          'Tarakanda',
          'Payari',
          'Phulpur',
          'Baola',
          'Banihala',
          'Balikhan',
          'Balia',
          'Bishka',
          'Bhaitkandi',
          'Rupasi',
          'Rahimganj',
          'Rampur',
          'Rambhadrapur',
          'Singheshwar',
          'Phulpur Bus stand',
          'Phulpur Thana',
          'Phulpur Upazila Health Complex',
          'Fulbaria Moddho Bazar',
          'Beltia',
        ],
      },
      {
        name: 'Fulbaria',
        postCode: '2216',
        areas: [
          'Fulbaria Thana',
          'Fulbaria Upazila Health Complex',
          'Fulbaria Moddho Bazar',
          'Gazaria',
          'Fazlupur',
          'Fulchhari',
          'Fulbaria Asim',
        ],
      },
      {
        name: 'Bhaluka',
        postCode: '2240',
        areas: [
          'Bhaluka Thana',
          'Bhaluka Bus stand',
          'Bhaluka Boro Mosjid',
          'Masterbari Bhaluka',
          'Bharadoba',
          'Bhaluka',
          'Mallikbari',
          'Meduary',
          'Rajai',
          'Harirbari',
        ],
      },
      {
        name: 'Muktagacha',
        postCode: '2210',
        areas: [
          'Muktagachha Thana',
          'Muktagachha Auditorium',
          'Muktagachha Jomidar Bari',
        ],
      },
      {
        name: 'Haluaghat',
        postCode: '2260',
        areas: [
          'Kaichapur',
          'Gazir Bhita',
          'Jugli',
          'Dhara',
          'Haluaghat Bazar',
          'Haluaghat Thana',
          'Haluaghat Upazila Health Complex',
          'Haluaghat Moddho Bazar',
          'Haluaghat College Road',
          'Haluaghat Bus stand',
          'Haluaghat Cng Stand',
          'Munshirhat',
        ],
      },
      {
        name: 'Mymensing Sadar',
        postCode: '2200',
        areas: [
          'Notun bazar Rail Crossing',
          'Toimur Excel Tower',
          'Charpara',
          'Maskanda',
          'Notun Bazar',
          'Zilla school',
          'Gazinagar',
          'Dhupkhola',
          'Akua',
          'Shankipara',
          'Town hall',
          'Kali bari',
          'Maharaja Road',
          'Sen bari',
          'Sarker Bari',
          'Nondi Bari',
          'Pondit Bari',
          'Pondit para',
          'Jamtola',
          'Golapjan Road',
          'College Road',
          'Kachari',
          'Police line Mymensingh Sadar',
          'Thana Ghat',
          'Purohit para',
          'Nahar Road',
          'Boundary Road',
          'Golki Bari',
          'Station Road',
          'Mymensingh Medical',
          'Hindu Polli',
          'Batir kol',
          'Agriculture University Road',
          'Agriculture University 1st Gate',
          'Agriculture University K R Market',
          'Agriculture University Jobbarer Mur',
          'Agriculture University Boro Mosjid',
          'Agriculture University Fosiler Mur',
          'Agriculture University Shes Mur',
          'Agriculture University Chotropur School Road',
          'Agriculture University K B High School Road',
          'Agriculture University K B College Road',
          'Agriculture University Wireless Road',
          'Agriculture University Begum Rokeya Hall',
          'Agriculture University Shek Fajilutunnesa Hall',
          'Agriculture University Rojina Hall',
          'Agriculture University Utility Vobon',
          'Agriculture University Votanical Garden',
          'Agriculture University London Bridge',
          'Agriculture University Sharuardi Hall',
          'Agriculture University Ashraful Haque Hall',
          'Agriculture University Shek Mojibur Rahman Hall',
          'Agriculture University Staff Quarter',
          'Agriculture University Football Academy',
          'Agriculture University Park',
          'Agriculture University Stadium',
          'Agriculture University Mukto Moncho',
          'Agriculture University Department',
          'Agriculture University CFC',
          'Agriculture University Bank Road',
          'Agriculture University Rest House',
          'Agriculture University Club',
          'Agriculture University Hall Room',
          'Agriculture University Security Zone 1',
          'Agriculture University Security Zone 2',
          'Agriculture University Security Zone 3',
          'Agriculture University Boishaki Chottor',
          'Maskanda Bus Stand Road',
          'Dhaka Bypass Road',
          'Obdar Mur',
          'Obdar Mur Rail Crossing',
          'Power House Road',
          'Power House',
          'Power House Quater',
          'Power House Boro Mosjid',
          'University TNT Road',
          'Poltrir Mur',
          'Moynar Mur',
          'Morakhola Bridge',
          'Morakhola Eidgah Road',
          'Kewatkhali Bazar',
          'Kewatkhali High School Road',
          'Kewatkhali Mur',
          'Kewatkhali PBI Office',
          'Hakkani Mosjid',
          'Kewatkhali Bormon Para',
          'Kewatkhali Mondir',
          'Kewatkhali Hindu Polli',
          'Kewatkhali BADC Point',
          'Muktijudda Polli',
          'Kashbon Model Town, Morakhola Road',
          'Atimkhana Road, Bridge Mur',
          'Brommoputro Bridge Mur',
          'Bridge Mur Boro Mosjid',
          'Momenshahi Mohila Madrasa',
          'Pathgudam High School',
          'Pathgudam LGED Office',
          'Pathgudam Pukur Par',
          'Pathgudam Moder Dipu',
          'Relir Mur',
          'Shomvuganj Bazar(Point Delivery)',
          'Bolashpur Pachtala',
          'Bolashpur Akkur Mosjid',
          'Bolashpur Morol Para',
          'Bolashpur Borobari',
          'Aliya Madrasa Mur',
          'Aliya Madrasa Road',
          'Vatikashor Borobari',
          'Vatikashor Mission School',
          'Vatikashor Gurosthan',
          'Vatikashor Boro Mosjid',
          'Vatikashor Primary School Road',
          'Vatikashor Bazar',
          'Padri Mission Road',
          'Vatikahsor Bihari Camp',
          'Atani Pukur Par',
          'Lengrar Mur',
          'C.K. Gosh Road',
          'Rahamatpur Engineering College',
          'Sankipara Jamtola Ses More',
          'Mymensingh Zero Point',
          'Chorpara Area',
          'Kawatkhali',
          'Churkhai',
          'Biddyaganj',
          'Pearpur',
          'Kalibari Road',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Narayanganj',
    zones: [
      { name: 'Baidder Bazar', postCode: '1442', areas: [] },
      {
        name: 'Bandar',
        postCode: '1410',
        areas: [
          'BIDS',
          'D.C Mills',
          'Madanganj',
          'Nabiganj',
          'Bandar Ghat',
          'Rupali Housing',
          'Wilson Road',
          'Amin Housing',
          'Sonakanda',
          'Forazi Kanda',
          'Baghbari',
          'Bandar Rail Line',
          'Kollandi',
          '1 No Madobpasa',
          '2 No Madobpasa',
          'Enayetnagar',
          'Kadam Rasul Dargah/ Majar',
          'Loharpul',
          'Lokkhon Khola',
        ],
      },
      {
        name: 'Fatullah',
        postCode: '1421',
        areas: [
          'Signboard',
          'Bhuigor',
          'Jalkury',
          'Delpara',
          'Fatullah Stadium',
          'Shibu Market',
          'Chanmari',
          'Panchaboti',
          'Dormoganj',
          'Lalkha',
          'Bscic',
          'Kashipur-Fatullah',
          'Dewanbari',
          'Boktoboli',
          'Fatullah bazar Post office',
          'Haji barir mor',
          'Ramarbagh',
        ],
      },
      {
        name: 'Narayanganj Sadar',
        postCode: '1400',
        areas: [
          'Pagla',
          'Kutubpur',
          'Delpara',
          'Godnail',
          'Rupayan Town',
          'Court kachari',
          'Bhuiyan Para',
          'Nitaigonj',
          'Shahid nagar',
          'Tanbazar',
          'Tanbazar bandar',
          'Khanpur',
          'Jora panir tangki',
          'ACI road',
          'CTG road',
          'Chashara',
          'Narayanganj Gabtoli',
          'Masdair Graveyard',
          'Barma stand',
          'Narayanganj Dhakeshwari',
          'Narayanganj Mouchak',
          'Shanarpar',
          'Syedpur',
          'Tat khana',
          'Pathantuli',
          'Kellarpool',
          'Tolla',
          'Zila Parishad',
          'Jamtola',
          'Amlapara',
          'DIT market',
          'Baburail',
          'Mondol Para',
          'Tamak potti',
          'Jute potti',
          'Ranada Prasad Saha University',
          'Sastapur',
          'Narayanganj College road',
          'Muktarpur',
          'Isdair',
          'Goalpara',
          'Muktarpur',
          'Kasem nogor abasik alaka',
          'Potegga mat',
          'Alamin MOQ',
          'Octo Office',
        ],
      },
      {
        name: 'Bhulta/Gawsia',
        postCode: '',
        areas: ['Gawsia', 'Banijjo Mela'],
      },
      {
        name: 'Siddirganj',
        postCode: '1431',
        areas: [
          'Chowdhury bari',
          'Mizmizi',
          'EPZ',
          'Chit Road',
          'Pathantuli',
          'Barma Stand',
          'Siddirganj pool',
          'Adamjee nagar',
          'LN Mills',
        ],
      },
      {
        name: 'Sonargaon',
        postCode: '1460',
        areas: ['Kachpur', 'Barodi', 'Baidder Bazar', 'Jampur', 'Mograpara'],
      },
      {
        name: 'Araihazar Thana',
        postCode: '',
        areas: [
          'Araihazar Thana Samne',
          'Gopaldi Bazar Cng Stand',
          'Duptara Kalibari Primer Bank',
          'Pachrokhi Busstand Mideline Bank',
          'Banti Bazar Dutch Bangla Bank',
        ],
      },
      {
        name: 'Rupganj',
        postCode: '1460',
        areas: [
          'Rupganj',
          'Bhulta Al-Rafi Hospital',
          'Bhulta/ Gousia Market',
          'Kanchan Bazar',
          'Kanchan Bazar Maya Bari Bus stand',
          'Rupshi Bus stand',
          'Barpa Bus stand',
          'Tarabo Bisha Road',
          'Golakandial Cng Stand',
          'Borabo Busstand',
          'Morapara Cng Stand',
          'Robin Tex',
          'Grament',
          'Nagri',
          'Murapara',
        ],
      },
      { name: 'Madanpur', postCode: '1464', areas: [] },
    ],
    type: 'dhakaSuburbs',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Narshingdi',
    zones: [
      {
        name: 'Belabo',
        postCode: '1640',
        areas: [
          'Morjal Busstand',
          'Baraicha Busstand',
          'Amlaba',
          'Wari',
          'Bateshwar',
          'Kandua',
          'Ichartek',
          'Haniabaid',
          'Rajarbagh',
          'Shah Para',
          'Ujilaba',
          'Lakhpur',
          'Abdulnagar',
          'Ujilab',
          'Dhukundi',
          'Bajnaba',
          'Baribari',
          'Bir Bhagber',
          'Chandanpur',
          'Chitam',
          'Harisenagar',
          'Char Belabo',
          'Char Lakshmipur',
          'Purba Char Lakshmipu',
          'Lakshmipur Madhyapa',
          'Bhawaler Char',
          'Binnabaid',
          'Char Bhagbar',
          'Birkanda',
          'Kashimnagar',
          'Latifpur',
          'Rajarampur',
          'Chhayan',
          'Lohajuri Chak',
          'Jalalabad',
          'Patiladaya',
          'Kangalia',
          'Joar Gobindapur',
          'Batibond',
          'Darikandi',
          'Amtali',
          'Joar Lakshmipur',
          'Narayanpur',
          'Bangalgaon',
          'Babla',
          'Chak Amkhola',
          'Chandipura',
          'Dapunia',
          'Dhanartek',
          'Galgalia',
          'Gobindadi',
          'Habispur',
          'Jamtala',
          'Kauarteki',
          'Muga',
          'Nagar Naluakot',
          'Nandarampur',
          'Paler Tek',
          'Patuli',
          'Poradia',
          'Royergaon',
          'Salma',
          'Tangirtek',
          'Dakshin Kalikaprasad',
          'Ibrahimpur',
          'Sarrabad',
          'Uttar Kalikaprasad',
        ],
      },
      {
        name: 'Monohordi',
        postCode: '1651',
        areas: [
          'Hatirdia Bazar Bank Asia',
          'Monohardi Busstand',
          'Monohardi Bazar Brac Bank',
          'Chalakchar Busstand( Sahajalal Bank)',
          'Aloakandi',
          'Atosal',
          'Balia',
          'Bara Chapa',
          'Bharadia',
          'Bhayasan',
          'Bir Maijdia',
          'Brahmanergaon',
          'Chanditala',
          'Char Krishnapur',
          'Char Tarakandi',
          'Jamalpur',
          'Kahetergaon',
          'Kalidhakona',
          'Kasba',
          'Noanagar',
          'Paikan',
          'Panchhati',
          'Urulia',
          'Chalak Char',
          'Chengain',
          'Baghber',
          'Masimpur',
          'Hafizpur',
          'Asadnagar',
          'Arjun Char',
          'Chandanbari',
          'Hetemdi',
          'Lakhpur',
          'Nalua Chak',
          'Kismat Sarerkandi',
          'Manarkandi',
          'Mahishkandi',
          'Char Galgalia',
          'Char Mandalia',
          'Khidirpur',
          'Char Daulatpur',
          'Darbesherkandi',
          'Daulatpur',
          'Harinarayanpur',
          'Kirtibasdi',
          'Kocher Char',
          'Nischintapur',
          'Paterdia',
          'Alalpur',
          'Bagadi',
          'Basubhanda',
          'Basulirkandi',
          'Bhasatia',
          'Bilagi',
          'Changa Bhanda',
          'Chikadi',
          'Dhulandi',
          'Eakduaria',
          'Gokhula',
          'Hatirdia',
          'Hitasi',
          'Kamar Algi',
          'Khilua',
          'Kuripaika',
          'Mandaldia',
          'Nadhair',
          'Randandia',
          'Saidergaon',
          'Noadia',
          'Akanagar',
          'Brahmanhata',
          'Chula',
          'Rajballabhkandi',
          'Baghibari',
          'Mamdartek',
          'Mamudpur',
          'Doaigaon',
          'Gotashia',
          'Panchkandi',
          'Sarbalak Shana',
          'Bara Mirzapur',
          'Baraidia',
          'Ghoshgaon',
          'Gauribhanga',
          'Gobindapur',
          'Harir Char',
          'Harishpur',
          'Kanchikata',
          'Katabaria',
          'Kharaba',
          'Madhushal',
          'Maheshpur Chak',
          'Maijdia',
          'Ruperkandi',
          'Sekhertek',
          'Singrakandi',
          'Baraidia',
          'Ghoshgaon',
          'Kalir Char',
          'Char Sagardi',
          'Khidirpur',
          'Kismat Ahmedpur',
          'Bir Ahmedpur',
          'Noor Ahmedpur',
          'Uzan Char',
          'Char Gajaria',
          'Dairer Para',
          'Dharubandha',
          'Dighirpar',
          'Gajaria',
          'Gangkul',
          'Haji Khaner Char',
          'Jhal Para',
          'Lemutala',
          'Narendrapur',
          'Sharifpur',
          'Sekhergaon',
          'Tarakandi',
          'Tatirdi',
          'Balu Para',
          'Char Narandi',
          'Dasdonar Chak',
          'Dighakandi',
          'Gandhardia',
          'Khanjarhat',
          'Narandi',
          'Ramchandradi',
          'Sabhardia',
          'Sallabaid',
          'Shukundi',
          'Swalpa Panchkandi',
          'Char Charpara',
          'Char Tanpara',
          'Chandanbari',
          'Chak Madhabdi',
          'Sallabaid',
          'Uttar Manohardi',
          'Uttar Harordia',
          'Dakshin Harordia',
        ],
      },
      {
        name: 'Narshingdi Sadar',
        postCode: '1605',
        areas: [
          'Sadar Road',
          'Sutapatty Narshingdi',
          'Narshingdi Bazar',
          'Laibary potty',
          'Paira Chattar',
          'Kowria para',
          'Satirpara 10 Tala',
          'Sadar Hospital',
          'Hamandra sahar More (Islam Plaza)',
          'Station Road',
          'Asinagar Park',
          'Narshingdi Gov.College',
          'Bramandi More',
          'Upazilar More',
          'Sangita More',
          'Velanagar Bazar',
          'Velanagar Dhaka Bus stand',
          'DC Road Narshingdi',
          'Velanagar Jelkhaner More',
          'Basail Railgate',
          'Sahaprotab More',
          'Gash Office Road',
          'Pachdona More',
          'Pachdona Bazar',
          'Madhabdi Bazar (DBBL Gali)',
          'Madhabdi School Super Market',
          'Madhabdi Bank Potty',
          'Madhabdi Kashipur',
          'Madhabdi College Road',
          'Amdia',
          'Baghata',
          'Bagbari',
          'Jinardi',
          'Boroitola',
          'Morjal',
          'Baroicha',
          'Bhelanagar',
          'Basail',
          'Bilasdi',
          'Toroa',
          'Baluakandi',
          'Birpur',
          'Baniachal',
          'Bhuakur',
          'Paschim Kanda Para',
          'Bazar',
          'Datta Para',
          'Takpara',
          'Jangalia Para',
          'Shatir Para',
          'Alijan Jute Mill',
          'Brahman Para',
          'Kauria Para',
          'U. M. C. New Colony',
          'Kamargaon',
          'Nagariakandi',
          'Baghdi',
          'Chowala',
          'Khatehara',
          'Salida',
          'Alokbali',
          'Char Madhabpur',
          'Satpara',
          'Muradnagar',
          'Bokshalipur',
          'Kajirkandi',
          'Nekjanpur',
          'Birgaon',
          'Khodadila',
          'Aolahat',
          'Bailain',
          'Banbhag',
          'Baradia',
          'Bara Banaid',
          'Bara Bhum',
          'Belabo',
          'Chandalchira',
          'Chherenda',
          'Chhota Amdia',
          'Dharmapur',
          'Kairbari',
          'Shankhola',
          'Dhundurpar',
          'Grindan Bhum',
          'Kandail',
          'Mathra',
          'Keta Para',
          'Kuralia',
          'Anaibari',
          'Mathra',
          'Char Dighaldi',
          'Char Ramprasadpur',
          'Kalikapur',
          'Jitrampur',
          'Kholbandha',
          'Doani',
          'Araiani',
          'Nawabpur',
          'Anantarampur',
          'Nayakandi',
          'Dagaria',
          'Ghoradia',
          'Isakpur',
          'Jaharajia',
          'Munsefer Char',
          'Nandalalpur',
          'Puran Para',
          'Ghoradia',
          'Sonatala',
          'Tauadi',
          'Baduar Cha',
          'Char Hajipur',
          'Hossainpur',
          'Nandalalpur',
          'Karimpur',
          'Nawabpur',
          'Paschim Bausia',
          'Purba Bausia',
          'Sreenagar',
          'Jagatpur',
          'Rasulpur',
          'Rasulpur Kanda Para',
          'Bala Chandradi',
          'Bara Maishadi',
          'Maishadi',
          'Milky Para',
          'Bhuiyankandi',
          'Chaugharia',
          'Dasturdi',
          'Daukandi',
          'Abdullahkandi',
          'Baraikandi',
          'Fazurkandi',
          'Hasimerkandi',
          'Kalyanpur',
          'Kanthalia',
          'Khamardi',
          'Kharia',
          'Darikandi',
          'Koladpur',
          'Nayakandi',
          'Rahimdi',
          'Nayakandi',
          'Royerkandi',
          'Algi',
          'Algir Char',
          'Anandi',
          'Balabhadradi',
          'Bara Noapara',
          'Roynadi',
          'Khudra Noapara',
          'Kanda Para',
          'Birampur',
          'Chhota Maishadi',
          'Chatto Ramchandradi',
          'Gadair Char',
          'Nuralapur',
          'Khidirkandi',
          'Nuralapur',
          'Putia',
          'Rangpur',
          'Sardarerkandi',
          'Shimulkandi',
          'Senerkandi',
          'At Paika',
          'Baiderkandi',
          'Balusair',
          'Baniadi',
          'Balu Char',
          'Chander Para',
          'Khilgaon',
          'Gazirgaon',
          'Khanmardi',
          'Kotowaler Char',
          'Darikandi',
          'Bilpar',
          'Maishasura',
          'Uttar Chaua',
          'Chaitaba',
          'Dakshin Burairhat',
          'Chandan',
          'Chaua',
          'Nagrarhat',
          'Daudpur Char',
          'Doaichaitab',
          'Goalnagar',
          'Hanumantapur',
          'Kabirajpur',
          'Majankandi',
          'Matia',
          'Meher Para',
          'Nisundaria',
          'Paulanpur',
          'Rupaba',
          'Sarpanigair',
          'Sreechandi',
          'Burairhat',
          'Swarupaba',
          'Alinagar',
          'Champak Nagar',
          'Dilarpur',
          'Baher Char',
          'Nazarpur',
          'Changatali',
          'Chagharia Para',
          'Nabipur',
          'Jamaliakandi',
          'Dari Nabipur',
          'Bangar Char',
          'Baniar Char',
          'Bara Balapur',
          'Bala Purer Char',
          'Menurkandi',
          'Bibirkandi',
          'Sagardi',
          'Khamar Char',
          'Baterkandi',
          'Kandarpadi',
          'Khadimer Char',
          'Nareshwardi',
          'Puran Char',
          'Meratali',
          'Asmandir Char',
          'Baghbari',
          'Bharsangan',
          'Bhat Para',
          'Char Madhabdi',
          'Char Para',
          'Chhoto Panchdona',
          'Daudpur',
          'Kansardi',
          'Hajrahat',
          'Kakasia',
          'Mulpara',
          'Nagar Panchdona',
          'Nalua',
          'Nasaraddi',
          'Nehaba',
          'Panchdona',
          'Patua',
          'Sonar',
          'Uttar Daudpur',
          'Uttar Kansardi',
          'Baghata',
          'Ganergaon',
          'Hogalia',
          'Khidirpur',
          'Kismat Baniadi',
          'Nagar Baniadi',
        ],
      },
      {
        name: 'Palash',
        postCode: '1612',
        areas: [
          'Gorashal Cng Stand',
          'Gorashal Pran 2&4 No Gate',
          'Palash Wapda Gate',
          'Palash Badc More',
          'Palash Busstand Chowrastar More',
          'Ghorashal Sarker khana Gate',
          'Charsundur Bazar Puratan Cng Stand',
          'Danga Bazar Primer Bank',
          'Chalana',
          'Char Madhabdi',
          'Char Sindur',
          'Dakshin Deora',
          'Gokulnagar',
          'Kauadi',
          'Pakraganj',
          'Char Alinagar',
          'Uttar Deora',
          'Loharkanda',
          'Kala Paika',
          'Birinda',
          'Joynagar',
          'Danga',
          'Galimpur',
          'Hasanhata',
          'Kajair',
          'Kenduaba',
          'Kajir Char',
          'Khil Para',
          'Santan Para',
          'Taltala',
          'Char Mamudpur',
          'Chhota Baktarpur',
          'Chhota Rampur',
          'Nityanandi',
          'Dari Char',
          'Dhanair Char',
          'Ichhakhali',
          'Noakanda',
          'Ichhakhali',
          'Daluar Cha',
          'Musarkanda',
          'Khashawala',
          'Karar Char',
          'Narsinhar Char',
          'Nimainandi',
          'Pitambardi',
          'Ramanandi',
          'Saiyadkhola',
          'Sekandardi',
          'Atashi Para',
          'Barar Char',
          'Char Nagardi',
          'Chaudharia',
          'Chhota Lakshmipur',
          'Chhota Taragaon',
          'Gabtali',
          'Gayespur',
          'Goalbathan Jinardi',
          'Kataber',
          'Kuraitali',
          'Barabo',
          'Baghaba',
          'Baribari',
          'Bad Barabo',
          'Lakheraj',
          'Majher Char',
          'Nagar Narasingpur',
          'Parulia',
          'Parulia Chak',
          'Raban',
          'Sattekia',
          'Bhaluka Para',
          'Bilpar',
          'Raghabdi',
          'Sanerbari',
          'Tengar Para',
          'Uttar Chandan',
          'Khanepur',
          'Khanepur Bazar',
          'Kathalia Para',
          'Balia',
          'Bhagger Para',
          'Dorihowla Para',
          'Garpara',
          'Banik Para',
          'Baluchar Para',
          'Nator Para',
          'Palash Bazar',
          'Kutir Para',
          'Naton Bazar',
          'Chatal',
          'South Palasha',
          'Doladia',
          'Kasmirer Tak',
          'Adarsha Gram',
          'Bhagopara',
          'Vararia Para',
          'Char Palash',
          'Kayasta Para',
          'Talekhala Para',
          'Digda',
          'Kotan Tak',
          'Lebu Para',
          'Paiksa Kanda',
          'Paiksa',
          'Sarkar Tak',
          'Lagalia',
          'Kumar Tak',
          'Chamrabo',
          'North Char Para',
          'North Tangar Para',
          'Darar Tak',
          'South Tangar Para',
          'Alir Tak',
          'Ghorashal',
          'South Char Para',
          'Fuldir Tak',
          'North Mia Para',
          'South Mia Para',
          'Tak Para',
          'Khaliskar Tak',
          'Pirinder Tak',
          'Atia',
          'Ghagra',
          'Bhagdi',
          'Kartatail',
          'Khagair',
          'Rajab',
        ],
      },
      {
        name: 'Raypura',
        postCode: '1631',
        areas: [
          'Sreerampur Railgate',
          'Hatubanga Railgate',
          'Hasnabad Bazar Union Bank',
          'Amirgong Cng Stand',
          'Baluakandi',
          'Khala Para',
          'Joynagar',
          'Dari Baliakandi',
          'Bhati Badarpur',
          'Mirzanagar',
          'Hasnabad',
          'Karimganj',
          'Nalbata',
          'Hasnabad',
          'Aganagar',
          'Mamudnagar',
          'Rahimabad',
          'Adiabad',
          'Sherpur',
          'Naya Char',
          'Alipura',
          'Bahadurpur',
          'Nabiabad',
          'Kangalimara',
          'Nabuar Char',
          'Tulatuli',
          'Sahebnagar',
          'Jahangirnagar',
          'Boalmara',
          'Banshgari',
          'Banshgari',
          'Dighaliakandi',
          'Battalikandi',
          'Rajnagar',
          'Payarakandi',
          'Sobanpur',
          'Char Meghna',
          'Chanderkandi',
          'Najarpur',
          'Sreenidhi',
          'Barakanda',
          'Kharitala',
          'Baher Char',
          'Bagh Duariakandi',
          'Kalikapur',
          'Chandpur Deara',
          'Majher Char',
          'Islampur',
          'Purba Hossainnagar',
          'Sadagarkandi',
          'Sujatpur',
          'Mohinipur',
          'Majidpur',
          'Kurerpar',
          'Imander Kandi',
          'Battali',
          'Batakandi',
          'Battali',
          'Khamar',
          'Char Aralia',
          'Bagaikandi',
          'Aralia',
          'Baradakandi',
          'Char Madhua',
          'Bir Charmadhua',
          'Samibad',
          'Gazipur',
          'Abdullapur',
          'Meher Nagar',
          'Bhaishber',
          'Ballabhpur',
          'Char Subuddi',
          'Lalpur',
          'Adiabad',
          'Char Khainku',
          'Noabad',
          'Daukar Char',
          'Khas Howla',
          'Kabirpur',
          'Kashimnagar',
          'Teli Para',
          'Birkandi',
          'Birkandi',
          'Manipura',
          'Hairmara',
          'Saudmara',
          'Bangalinagar',
          'Majherkandi',
          'Hatubhanga',
          'Purbakandi',
          'Hoglakandi',
          'Baher Cha',
          'Anwarabad',
          'Mirzapur',
          'Kukurmara',
          'Mamudabad',
          'Pirajkandi',
          'Dari Para',
          'Pirpur',
          'Khanda Para',
          'Sadhunagar',
          'Begamabad',
          'Char Begamabad',
          'Gauripur',
          'Musapur',
          'Paharkharkandi',
          'Purba Haripur',
          'Fatehpur',
          'Latia',
          'Nilakhya',
          'Sonakandi',
          'Kanda Para',
          'Birgaon Paschim Para',
          'Darigaon',
          'Gopinathpur',
          'Sonatala',
          'Asarampur',
          'Dakshin Gobindapur',
          'Khag Char',
          'Khalilabad',
          'Mallikpur',
          'Methikanda',
          'Tulatali',
          'Palashtali',
          'Phuldi',
          'Chasiamara',
          'Taki Pura',
          'Sahapur',
          'Seoratali',
          'Bakharnagar',
          'Chhota Asrafpur',
          'Dari Sapmara',
          'Saharkhola',
          'Chhota Ban',
          'Raipura',
          'Rajprasad',
          'Char Sadarland',
          'Islampur',
          'Mursidabad',
          'Fakirer Char',
          'Rangpur',
          'Abdullar Char',
          'Sreenagar',
          'Bhalua Char',
          'Vojranathpur',
          'Kalipur',
          'Nobabpur',
          'Sonakanda',
          'Bahadurpur',
          'Bara Char',
          'Uttar Bakharnagar',
          'Bakharnagar',
          'Ratanpur',
          'Gopalpur',
          'Lochanpur',
          'Shibpur',
          'Bathtali Hati',
          'Roypura',
          'Dalilnagar',
          'Ramnagar',
          'Thanahati',
          'Kanda Para',
          'Taktokanda',
          'Tekpara',
          'Hashimpur',
          'Kalabaria',
          'Kurerpar',
          'Baikanthapur',
          'Darihati',
          'Haripur',
          'Mahishmari',
          'Sreerampur',
          'Meratali',
          'Rajanagar',
          'Methikanda',
          'Tulatuli',
          'Radhaganj bazar',
        ],
      },
      {
        name: 'Shibpur',
        postCode: '1620',
        areas: [
          'Tharmex Karardi',
          'Itakhola Chowrasta More',
          'Shibpur College Gate (Modhumati Bank)',
          'Shibpur Bus stand',
          'Shibpur Bazar (Prime Bank)',
          'Abdulkhana',
          'Shibpur',
          'Sunandi',
          'Saidargaon',
          'Bajnaba',
          'Bandardia',
          'Nagar Bekarpara',
          'Chaltakandi',
          'Baniadi',
          'Arali',
          'Baliahali',
          'Kutubertek',
          'Chak Radha',
          'Kanahata',
          'Asrafpur',
          'Nagar Fulbaria',
        ],
      },
      {
        name: 'Madhabdi',
        postCode: '1604',
        areas: [
          'Chhato Godair Char',
          'Birampur',
          'Nao Para',
          'Tatapara',
          'Manoharpur',
          'Kotalir Char',
          'Par Kashipur',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Netrakona',
    zones: [
      {
        name: 'Natrokuna Sadar',
        postCode: '2400',
        areas: [
          'Nobinogor',
          'Madanpur',
          'Noyani Bazar',
          'College Mor Natrokuna Sadar',
          'Sherpur bus stand',
          'Kuarpar',
          'Police line',
          'Kandapara',
          'Chatalpur',
          'Etimkhana (Orphanage)',
          'Kusumhati',
          'Nondirbazar',
          'Khorompur',
          'Kamaria',
          'Kamarchor',
          'Gazirkamar',
          'Chor pokkimari',
          'Chormochuria',
          'Chor Sherpur',
          'Dhola',
          'Pakuria',
          'Batmari',
          'Bolayatcor',
          'Bazitkhila',
          'Vatshala',
          'Rouha Batmari',
          'Losmonpur',
          'Chokpatok',
          'Ostimtola',
          'Seribrize',
          'Munshichar',
          'Bus Stand Natrokuna Sadar',
          'Capatoli',
          'Etimkhana mor',
          'Sarkit House',
          'Boira poranpur',
          'Griddanarayanpur',
          'Madhoppur',
          'Rugunat bazar',
          'Munshi bazar',
          'Ghosh potti',
          'goalpotti',
          'rajbolobpur',
          'Poli askrim mor',
          'Hajir mor',
          'Istidam',
          'Driver potti',
          'Track Stand',
          'Gopalbari',
          'Bottola Natrokuna Sadar',
          'Hospital mor Natrokuna Sadar',
          'Nak para',
          'Orlas mor',
          'Sitolpur',
          'Khurarpar',
          'Mirjapur',
          'Gouripur Natrokuna Sadar',
          'Tarabazar',
          'Jelkhana mor',
          'Tatalpur',
          'Kanasakhula',
          'Dubar Char',
          'Kamar char',
          'Baikherhati',
          'Teribazar',
          'Purba Chokpara',
          'Poschim chokpara',
          'Satpai',
          'Moinpur',
          'Railway Crossing',
          'Changkharmor',
          'Choto gara',
          'Boro gara',
          'Gainpara',
          'Netrokona - Dhaka bus stand',
          'Parla bazar',
          'Kurpar',
          'Dhaira',
          'Khoti nowga',
          'Katly',
          'Malni road',
          'Choto bazar',
          'Boro bazar Natrokuna Sadar',
          'Muktarpara',
          'New Town',
          'Barhatta road',
          'Rajur bazar',
          'Ronger bazar',
          'Madni',
          'Bahir chapra',
          'Bonna para',
          'Netrokona - Pukoria',
          'Nagra',
          'Ajohor road',
          'Gojinpur mor',
          'Anondo bazar',
          'Nterakona court station',
          'Collisha bazar',
          'Shakua bazar',
          'Madan bustand',
          'Lokkhiganj bazar',
          'Bali bazar',
          'Islampur Natrokuna Sadar',
          'Mach bazar',
          'Guruhatta',
          'Hatkola bazar',
          'Newoaj nagor',
        ],
      },
      {
        name: 'Atpara',
        postCode: '2470',
        areas: [
          'Duaz',
          'Baniajan',
          'Loneshwar',
          'Sarmaisa',
          'Sukhari',
          'Sonai',
        ],
      },
      {
        name: 'Kalmakanda',
        postCode: '2430',
        areas: [
          'Kailati',
          'Kharnai',
          'Nazirpur',
          'Pogla',
          'Bara Kharpan',
          'Rangchhati',
          'Lengura',
        ],
      },
      {
        name: 'Kendua',
        postCode: '2480',
        areas: [
          'Kendua',
          'Garadoba',
          'Ganda',
          'Chirang',
          'Dalpa',
          'Noapara Kendua',
          'Paikura',
          'Balaishimul',
          'Roailbari',
          'Mashka',
          'Muzaffarpur',
          'Sandikona',
        ],
      },
      {
        name: 'Khaliajuri',
        postCode: '2460',
        areas: ['Khaliajuri', 'Chakua', 'Gazipur', 'Nagar', 'Mendipu'],
      },
      {
        name: 'Susang Durgapur',
        postCode: '2460',
        areas: [
          'Birishiri',
          'susang duragapur post office',
          'condhigor',
          'chanda',
          'bagoljura',
          'bijoypur',
          'dawdukhun',
        ],
      },
      {
        name: 'Purbadhala',
        postCode: '2410',
        areas: [
          'Khalishapur',
          'Gohalakanda',
          'Ghagra Purbadhala',
          'Jaria Purbadhala',
          'Dhala Mulgaon',
          'Narandia',
          'Purbadhala',
          'Bishkakuni',
          'Bairati',
          'Hogla',
          'Shamgonj',
        ],
      },
      {
        name: 'Barhatta',
        postCode: '2440',
        areas: [
          'Chhiram',
          'Baushi',
          'Roypur Barhatta',
          'Barhatta',
          'Sahata',
          'Singdha',
        ],
      },
      {
        name: 'Madan',
        postCode: '2490',
        areas: [
          'Gobindasree',
          'Chandgaon',
          'Tiasree',
          'Nayekpur',
          'Fatehpur Madan',
          'Madan',
          'Maghan',
        ],
      },
      {
        name: 'Mohanganj',
        postCode: '2446',
        areas: [
          'Tentulia',
          'Barkashia Birampur',
          'Baratali Banihari',
          'Maghan Siadhar',
          'Samaj Sahildeo',
          'Suair',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Rajbari',
    zones: [
      {
        name: 'Rajbari Sadar',
        postCode: '7700',
        areas: [
          'Kali mondir',
          'Rajbari staduim',
          'Kahar para',
          'Sobuj bagan',
          'Poschim Bhabanipur',
          'Dhokkhin Bhabanipur',
          'Mollabari mosjid',
          'Segun bagicha club',
          'Segun bagicha mohila odhidoptor',
          'Shoidullah chotpoti dokan',
          'Pirtola math',
          '2 no rail gate',
          'Bank para',
          'Bhokesonal road',
          'Food office',
          'Lalmia shorok',
          'Rajbari fultola',
          'Rajbari college mor',
          'RSK mor',
          'Binetpur police fari',
          'Rajbari etim khana',
          'Rajbari press club',
          'Rajbari gorur hat',
          'Rajbari rail ar math',
          'Binetpur diabetics hospital',
          'Rajbari bash hat',
          'English medium sschool',
          'Rajbari kindergarten',
          'Puraton hori shova',
          'Vajon bari',
          'Tetul mor',
          'Rajbari Greeen food',
          'Beradanga Arai',
          'Rajbari Sishu park',
          'Rajbari medical center',
          'Rajbari hasher khamar',
          'Rajbari zoj court',
          'Rajbari DC office',
          'Rajbari police supper karjaloy',
          'Rajbari Ongkur school',
          'Rajbari Powroshova',
          'Rajbari Boro mosjid',
          'Rajbari somaj sheba office',
          'Rajbari fire service',
          'Abul Hossain college',
          'Rajbari Aam tola',
          'Rajbari khajar bari',
          'Pre-cadet school',
          'Rajbari Sadar Girls school',
          'Nur jahan school',
          'Rajbari Yasin school',
          'Popular clinic',
          'Rajbari sadar hospital',
          'Christian koborsthan',
          'Jomidar sorok',
          'Rajbari nursery',
          'Bhumi office',
          'Asad sorok',
          'Rajbari thana',
          'Rajbari mach bazar',
          'Dry factory',
          'Notun mosjid',
          'Haji supper market',
          'Rajbari athas colony',
          'rajbari rail station',
          'Rajbari madok office',
          'Rajbari kapor bazar',
          'Singer ar mor',
          'Kazikanda',
          'Shojon kanda',
          'College para Rajbari Sadar',
          'Rail gate',
          'Biletpur',
          'Panna chottar',
          '1 no. Beradanga',
          '2 no. Beradanga',
          '3 no. Beradanga',
          'Hospital road',
          'Khogirer dokan',
          'Ansar camp',
          'Public health',
          'Kahan para',
          'Police line',
          'Murgi farm',
          'Bhabani pur',
          'Hori Shova',
          'Madok office',
          '2 no. rail gate',
          'Sreepur bazar',
          'TNT para',
          'Kamona building',
          'Rail ar math',
          'Rajbari bazar',
          'Bisic road',
          'Shobuj bangla',
          'Kazi badhamor',
          'Notun bazar',
          'Mayna bhaban',
          'Rajbari clinic',
          'Yatim khana',
          'Diabetics hospital',
          'Bhajon chala',
          'Bhajon chala Primary school',
          'Naran babur pukur chala',
          'Khankhanapur',
        ],
      },
      {
        name: 'Pangsha',
        postCode: '7720',
        areas: [
          'Bagh mara bus stand',
          'Chondoni bus stand',
          'Chor afra bus stand',
          'Gandimara bus stand',
          'Pangsha college mor',
          'Ajiz sordar bus stand',
          'Pangsha bus stand',
          'Shilpokola mor',
          'Ramkol',
          'Ratandia',
          'Mrigibazar',
        ],
      },
      {
        name: 'Kalukhali',
        postCode: '7700',
        areas: [
          'Kalukhali bazar',
          'Kalukhali rail station',
          'Sonapur bus stand',
          'Buyalia bus stand',
          'Pakistan bus stand',
        ],
      },
      {
        name: 'Goalanda',
        postCode: '7710',
        areas: [
          'Kamaldia bridge',
          'Aladipur bazar',
          'Aladipur Jut mill',
          'Notun rasta main road',
          'Felur dokan',
          'Kollanpur bazar',
          'Goalondo mor',
          'Bosontopur rail gate',
          'Bosontopur rail station',
          'Khankhanapur',
          'Khankhanapur bazar',
          'Khankhanapur rail station',
          'Choto bridge',
          'Jomidar bridge',
          'Goalondo rail gate',
          'Goalondo upozila',
          'Goalondo powroshova',
          'Goalondo bus stand',
          'Goalondo bazar',
          'Goalondo rail station',
          'Malla potti bridge',
          'Goalanda ghat',
          'Goalondo ghat board office',
        ],
      },
      {
        name: 'Baliakandi',
        postCode: '7730',
        areas: [
          'Mati para',
          'Bohor pur bazar',
          'Bonibohor bazar',
          'Baliakandi bazar',
          'Baliakandi bridge',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Shariatpur',
    zones: [
      {
        name: 'Jajira',
        postCode: '8010',
        areas: [
          'Bot tola Jajira',
          'Jajira College',
          'TNT Mor',
          'Upazila Helth Complex, Jajira',
          'Bank Moor (New)',
          'Jajira Thana',
          'Munshi Kandi',
          'Jajira bazar (Stand)',
          'Jajira bazar (AC Land office)',
          'Sonkhola School',
          'Bilashpur Jora bridge',
          'Bilashpur Bazar',
          'Budhair Hat Bazar',
          'Mal kandi Thanar math',
          'jajira Godaun Moor',
          'Jajira Lonch Ghat',
          'Jaira Bank moor (Old)',
          'jajira Girls School',
          'jajira Sub station',
          'Jajira Roser Moor',
          'Jaira Fire Sercive (aracondri moor)',
          'Lawkhola bazar',
          'Dhon Boaloa Boro Bridge',
          'Gongonogor Bazar',
          'Kajirhat Brack office',
          'kajirhat Bus Stand',
          'kajir hat national Bank',
          'kajirhat Bus Stand (new)',
          'Rupbabur Hat',
          'Gonir Moor',
          'Majhir Ghat',
          'Jaiira Cantonment',
          'Nawdoba Gol cottor',
          'Nawdoba Bazar',
          'SA-2 Padma Bridge Project',
          'BK Nagar Anoda bazar',
          'BK Nagar Doinik Bazar',
          'Club Moor Jajira',
          'Bongo Bazar Jajira',
          'Senrchar',
          'Palerchar Bazar',
          'Palerchar Union porishad',
          'Gram Chikondi',
          'Town Cikondi',
          'Domshar Bus stand',
        ],
      },
      {
        name: 'Shariatpur Sadar (Palong)',
        postCode: '8000',
        areas: [
          'Sador Hospital',
          'Cowrongi Moor',
          'Bot tola(Sodor)',
          'Moddha Bazar',
          'Palong Thana',
          'Rajgonj Bridge',
          'horishova',
          'Santinagar',
          'Palong School',
          'Kali Khola',
          'Fire service(Shariatpur)',
          'Sadar Bus Stand',
          'Kotapara',
          'Premtola',
          'Balakhana',
          'Taltola atipara',
          'Shariatpur Court',
          'LGD Office',
          'Pakar Matha',
          'Shariatpur Sadar Colony',
          "Golam Hyder Womens' college",
          'Circuit House',
          'Shariatpur Sadar Stadium',
          'Shariatpur College',
          'PWD',
          'Police Line Shariatpur Sadar',
          'Monora Moor',
          'Monora Gorur Math',
          'Angaria Boro Bridge',
          'Angaria Union porishad',
          'Angaria bazar',
          'Molla barir Moor',
          'Dighir Paar',
          'heliport',
          'Arigoan Bridge',
          'Arigoan bazar',
          'Goyatola Bazar',
          'Chandrapur bazar',
          'Bidodpur bazar',
          'Mohammadpur Bazar',
          'Mohammadpur BM College',
          'Bera Chikondi',
          'Domshar Bazar',
          'kotapara boro bridge',
          'Atong',
          'kagdi',
          'balar Bazar',
          'Rudrakor',
          'Makshar',
          'bilash khan',
          'Subuchini',
          'Tulatola',
          'Ronger Bazar',
          'Dori kandi',
        ],
      },
      {
        name: 'Damuddya',
        postCode: '8040',
        areas: [
          'Islampur Bazar',
          'Tinkhamba',
          'khejur Toli',
          'Konokshar',
          'Rishibarir Moor',
          'Damuddya Bus Stand',
          'MP bari',
          'Polli Biddut',
          'Arong mor',
          'Boro bridge',
          'Amin bazar Damuddya',
          'krishi Bank',
          'Purba Madaripur College',
          'Damuddya high School',
          'Damuddya Thana',
          'Upazila Helth Complex, Damuddya',
          'Damuddya Upazila',
          'Damuddya Bazar',
          'Khan Barir Bridge',
        ],
      },
      {
        name: 'Goshir Hat',
        postCode: '8050',
        areas: [
          'Sidhulkura',
          'Nagerpara',
          'Upazila helth Complex Goshirhat',
          'Fire service(Goshirhat)',
          'GoshirHat Thana',
          'Samchur Rahman College',
          'GoshirHat High School',
          'GoshirHat badamtola Bus stand',
          'GoshirHat Gorur math',
          'haturia',
          'Kodalpur',
          'National Bank',
          'Sundorban Currier',
          'kapor Potti',
          'Lonch Ghat',
        ],
      },
      {
        name: 'Naria',
        postCode: '8021',
        areas: [
          'Vojessor Gorur Math',
          'Vojessor Bus stand',
          'Vojessor Tin Dokan',
          'Vojessor Bijhari Bazar',
          'Vojessor Poanch polli',
          'Fotejong pur Bazar',
          'Vojessor fire Sirvice Office',
          'Mazeda Hospital',
          'Naria Thana',
          'Boishakhipara',
          'Noria College',
          'Noria Upazila',
          'Biharilal High School',
          'Sundorban Currier (Noria)',
          'National Bank(Noria)',
          'majhi plaza',
          'Islami Bank (Noria)',
          'Balurmath',
          'Dhalibarir Moor',
          'Sonar bazar',
          'Paikpara',
          'Chakdho Bazar',
          'Chakdho Islami Bank',
          'Mulfathgonj madrasha',
          'Mulfathgonj Bazar',
          'Mulfathgonj Hospital',
          'Kalukhathi',
          'Suressor',
          'Kodomtola Naria',
          'Saldho Satkuni',
          'Golar Bazar',
          'Ponditsar Bazar',
          'Paglar Moor',
          'Gorishar Adhunik Hospital',
          'Gorishar national Bank',
          'Gorishar Janata Bank',
          'Gorishar Bazar Uttor matha',
          'Baroipara Mondir',
          'Baroipara primary School',
          'Kartrikpur Bazar',
          'Mominali Mollar Bazar',
          'Sokhipur bazar',
          'Sokhipur National Bank',
          'kashimpur',
          'Balar Bazar',
          'Roshid Beparir Bazar',
          'Mollar Bazar',
          'Chandpur Ferry Ghat',
          'Bhozeshwar',
          'Gharisar',
          'Upshi',
          'Kartikpur',
        ],
      },
      {
        name: 'Bhedorganj',
        postCode: '8030',
        areas: [
          'Bhedorgonj Thana',
          'Maa Plaza',
          'M.A Raza College',
          'Upazila Helth Complex Bhedorgonj',
          'Muktijoddha Complex Bhedorgonj',
          'Buro Office Damuddya Road',
          'Ramvodropur',
          'Rari Kandi',
          'Sajonpur',
          'Soy Goan Banglabazar',
          'Soy Goan Primarry School',
          'Desh Koraltoli',
          'Chor Koraltoli',
          'BhedorgonjAC land Office',
          'Sottopur Khea Ghat',
          'Kanchanpara',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Sherpur',
    zones: [
      {
        name: 'Sherpur Sadar',
        postCode: '2100',
        areas: [
          'Nobinogor',
          'Madanpur',
          'Noyani Bazar',
          'College Mor',
          'Sherpur bus stand',
          'Kuarpar',
          'Police line',
          'Kandapara',
          'Chatalpur',
          'Etimkhana (Orphanage)',
          'Kusumhati',
          'Nondirbazar',
          'Khorompur',
          'Bakshigonj',
        ],
      },
      {
        name: 'Jhenaigati',
        postCode: '2120',
        areas: [
          'Gauripur',
          'Jhenaigati',
          'Dhanshail',
          'Nalkura',
          'Malijhikanda',
          'Hatibandha',
        ],
      },
      {
        name: 'Nakla',
        postCode: '2150',
        areas: [
          'Kursa Badagair',
          'Ganapaddi',
          'Chandrakona',
          'Char Ashtadhar',
          'Talki',
          'Nakla',
          'Pathakata',
          'Baneshwardi',
        ],
      },
      {
        name: 'Nalitabari',
        postCode: '2111',
        areas: [
          'Kakarkandi',
          'Nunni',
          'Nayabil',
          'Nalitabari',
          'Poragaon',
          'Baghber',
          'Marichpura',
          'Jogania',
          'Rupnarayankura',
          'Rajnagar',
          'Ramchandrakura Mandalia',
          'Hatibandha',
        ],
      },
      {
        name: 'Sreebardi',
        postCode: '2130',
        areas: [
          'Kurikahania',
          'Kharia Kazir Char',
          'Garjaripa',
          'Gosaipur',
          'Tantihati',
          'Bhelua',
          'Rani Shimul',
          'Sreebardi',
          'Singa Baruna',
          'Sreebardi Upazila Gate',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Tangail',
    zones: [
      {
        name: 'Tangail Sadar',
        postCode: '1900',
        areas: [
          'Karatia Sadat College',
          'Purabari',
          'Santosh',
          'New Bus Stand',
          'Old Stand Tangail Sadar',
          'Taltola Tangail Sadar',
          'Bottola Tangail Sadar',
          'Abhawa Office',
          'Musim Para',
          'Stadium bridge',
          'West Akurtakur para',
          'Kagmara',
          'Akurtakurpara Zilla Sadar Road',
          'Registry Para',
          'Dighulia',
          'Polashtoli',
          'Par Dighulia',
          'Adalotpara',
          'Purbo Adalotpara',
          'Bazitpur Road',
          'Charabari',
          'Ashikpur',
          'Nirala Mor',
          'Pacani Bazar',
          'Choyani',
          'Baganbari',
          'Beparipara',
          'Baganbari',
          'Adi Tangail Chapra Moshjid',
          'College para',
          'Shimultoli',
          'Dewla',
          'Boilla Bazar',
          'Kodalia',
          'Medical College',
          'Sabalia',
          'LGED mor',
          'Polytechnic college',
          'Housing State',
          'District Querter',
          'SP park',
          'District gate',
          'Boro kalibari',
          'Choto kali bari',
          'Kumudini college Gate',
          'Airport Road',
          'Victoria Road',
          'Paradise Para',
          'Beradoma',
          'Kumudini college gate',
          'Bisses Betka',
          'Suparibagan',
          'Godawn Bridge',
          'Amin Bazar',
          'Tangail Sadar',
          'Shuparipara',
          'Tangail Thana',
          'Paza Para',
          'Bortola',
          'Court kacheri',
          'Tangail Polytechnic',
          'Garinda Bazar',
          'SolePark',
          'Bill Garinda',
          'TangailBypass',
          'Thanapara',
          'Boali',
          'Bow bazar',
          'Park Bazar',
          'Palpara-Tangail Sadar',
          'Mirza Mart',
          'Maser Market',
          'Muslim Para',
          'Shantikunju',
          'Gov.MM Ali College',
          'Korotia',
          'Korotia College',
          'Korotia Jamidar Bari',
          'Koratipara Bypas',
          'Korotia Hat Bypass',
          'Soyani Bazar',
          'Kandapara-Tangail Sadar',
        ],
      },
      { name: 'Dhanbari', postCode: '1997', areas: ['Dhanbari Thana'] },
      {
        name: 'Bashail',
        postCode: '1920',
        areas: ['Bottola', 'Bashail CNG station'],
      },
      {
        name: 'Sokhipur',
        postCode: '1950',
        areas: [
          'Sokhipur Thana',
          'Chowrasta mor',
          'Upozilla Porishad',
          'Nalua',
          'Sokhipur Bazar',
          'Paharkanchanpur BAF',
        ],
      },
      {
        name: 'Nagarpur',
        postCode: '1936',
        areas: [
          'Nagapur Upozilla Porishad',
          'Battola',
          'College Gate',
          'Salimabad',
          'Nagarpur Bazar',
          'Nagarpur',
        ],
      },
      {
        name: 'Mirzapur',
        postCode: '1940',
        areas: [
          'Mohera By pass',
          'Natiapara',
          'Jamurki',
          'Pakulla',
          'Mirzapur Cadet College',
          'Kumudini Medical College',
          'Mirzapur Thana',
          'Hatubhanga',
          'Mirzapur Bazar',
          'Mirzapur By pass',
          'Nasir Glass Gorai',
          'Halim Adhunik Hospital',
          'Natipara Nasir Glass',
        ],
      },
      {
        name: 'Madhupur',
        postCode: '1996',
        areas: [
          'Front of Rani Bhabai School',
          'Jamalpur Road',
          'Modhupur Thana',
          'Madhupur',
        ],
      },
      {
        name: 'Kalihati',
        postCode: '1975',
        areas: [
          'Elenga',
          'Kalihati Bus Stand',
          'Kalihati Thana',
          'Nagarbari',
          'Nagbari',
          'Palisha',
          'Rajafair',
        ],
      },
      {
        name: 'Goapalpur',
        postCode: '1990',
        areas: ['Gopalpur Thana', 'Hemnagar', 'Gopalpur-Gopalpur'],
      },
      {
        name: 'Ghatail',
        postCode: '1984',
        areas: [
          'College More',
          'Jhorka Bazar',
          'Ghatail cantonment',
          'Ghatail Thana',
          'Ghatail Main Road',
          'Ghatail New Market',
          'Ghatail Upozila Porishad',
          'Ghatail Main Road',
        ],
      },
      {
        name: 'Delduar',
        postCode: '1910',
        areas: ['Delduar Thana', 'Pathrail', 'Elashin', 'Lawhati'],
      },
      {
        name: 'Bhuapur',
        postCode: '1960',
        areas: [
          'Bhuapur Thana',
          'Bhuapur Cantonment',
          'Bhuapur Upozila Porishod',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Bandarban',
    zones: [
      { name: 'Naikhong', postCode: '4660', areas: [] },
      {
        name: 'Roanchhari',
        postCode: '4610',
        areas: [
          'Khansama Para',
          'BGB Cantonment.',
          'Police Camp Roanchhari',
          'Roangchari Bazar',
          'Taracha union',
          'Sualok',
          'Faruk Para',
          'Fire Service Area Roanchhari',
          'Suanlu Para',
          'Bengchari',
          'Roangchari Sadar',
          'Roanchhari',
        ],
      },
      {
        name: 'Ruma',
        postCode: '4620',
        areas: [
          'Y Jongson',
          'Murong Bazar.',
          '1 no Ghat',
          'Bethel Para',
          'Ruma Bazar',
          'Hospital Area',
          'Eden Road',
          'Ruma Upazila',
          'Chimbuk',
          'Girison Army Camp',
          'Ruma',
        ],
      },
      {
        name: 'Thanchi',
        postCode: '4641',
        areas: ['Thanchi', 'Nilgiri', 'Boli para', 'Thanchi Bazar'],
      },
      {
        name: 'Bandarban Sadar',
        postCode: '4600',
        areas: [
          'Maddhyam Para',
          'Rajar Math',
          'Bazar Bandarban Sadar',
          'Press Club',
          'Army para',
          'Bus stand Bandarban Sadar',
          'Member Para',
          'Bonurupa',
          'Fire Service Area',
          'College Road',
          'Bandarban Sadar Hospital',
          'New gulsan',
          'Keching Ghata',
          'Kalaghata',
          'Balaghata Bazar',
          'MDS gate, Cantonment',
          '26 bir Cantonment',
          'Sarno mondir, Balaghata.',
          'Hafezghuna',
          'Keching Para',
          'Jotho Khamar',
          'Meghla',
          'Talukder Para',
          'Reicha',
          'Roangchari Bustand',
          'Bajalia',
          'Noa Para',
          'Hill bird',
          'Bandarban University',
          'Stadium Area',
          'Nilachol',
          'Tiger Para',
          'Dulo Para',
          'Lemujhire Para',
          'Lemujhire Aga Para',
          'Swith Gate',
          'Keyang More',
          'Ruma Bustand',
          'Magistrate Colony',
          'Chowdhuri Market area',
          "Kazi's dine Area",
          'Tongchanga Para',
          'Police Line Bandarban Sadar',
          'Cantonment Public School and Collge',
          'Hindu Mondir',
          'Bandarban Govt.Girl College',
          'Bandarban Radio station',
          'Forest Office',
        ],
      },
      {
        name: 'Lama',
        postCode: '4641',
        areas: ['Lama Sadar', 'Lama Porosovha', 'Rupose Para', 'Lama'],
      },
      {
        name: 'Alikadam',
        postCode: '4650',
        areas: [
          'Refar Bari',
          'Upazilla Alikadam',
          'Pan Bazar',
          'Alikdam Bazar',
          'Noiya Para',
          'Alikdam Bazar para',
          'Cantonment Alikadam',
          'Chohomoni',
          'Alikadam',
        ],
      },
      { name: 'Kalaghata', postCode: '', areas: ['Jadi Para'] },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Brahmanbaria',
    zones: [
      {
        name: 'Akhaura',
        postCode: '3451',
        areas: [
          'TANPARA',
          'AKHAURA UTTAR',
          'PURBA CORNER',
          'DAKKHIN KHARAMPUR',
          'KUMARPARA COLONY',
          'RAILWAY PASCHIMCOLONY',
          'RAILWAY PURBA COLONY',
          'UTTAR AKHAURA',
          'BARA BAZAR',
          'DAKHIN AKHAURA',
          'PASCHIM RADHANAGAR',
          'SARAK BAZAR',
          'NARAYAN PUR',
          'PURBA RADHANAGAR',
          'DAKSHIN RADHANAGAR',
          'DEBAGRAM',
          'SUBAR PUKUR PAR',
          'UTTAR DEBAGRAM',
          'DAKSHIN DEBAGRAM',
          'PURBA DEBAGRAM',
          'TARAGAN',
          'Akhaura',
          'ABDULLAHPUR',
          'ANANDAPUR',
          'BANGER CHAR',
          'BARA KURIPAIKA',
          'BIR CHANDRAPUR',
          'DILJOYPUR',
          'HIRAPUR',
          'HAPANIA',
          'KALIKAPUR',
          'KALINAGAR',
          'KENDUAI',
          'CHHOTA KURIPAIKA',
          'NURPUR',
          'NURPUR LAMARBARI 2',
          'RAJBALLABHPUR',
          'RAHIMPUR',
          'SATPARA',
          'SAHEBNAGAR',
          'ADILPUR',
          'BANGANJ',
          'BHATAMATHA',
          'TAN CHANDPUR',
          'CHANDPUR',
          'DHARKHAR',
          'GOLKHAR',
          'IDILPUR',
          'JIKUTIA',
          'KRISHNANAGAR',
          'MADHUPUR',
          'NOWA PARA',
          'RUTI',
          'RANIKHAR',
          'SATURA',
          'TULATALI',
          'TANTAR',
          'AIRAL',
          'ATKA PARA',
          'BAHER BELABAR',
          'BAHER GAZARIA',
          'BARA GANGAIL',
          'BARA LOHAGHAR',
          'BHANDUSAR',
          'BISHNUPUR',
          'BARA TANKI',
          'BEKINAGAR',
          'CHHOTA TANKI',
          'DAKSHIN MINARKOT',
          'UTTAR DHARMANAGAR',
          'FATEHPUR',
          'GOBINDAPUR',
          'GOAL GANGAIL',
          'KISMAT GHAGTIA',
          'GIRISHNAGAR',
          'GOPALPUR',
          'HARIPUR',
          'ITNA',
          'KHARKOT',
          'KATAMURA',
          'LAKSHMIPUR',
          'MINARKOT',
          'MAJHIGACHHA',
          'MANIAND',
          'MIRPUR',
          'RAJMANGALPUR',
          'SIKARMURA',
          'SHIBNAGAR',
          'SHON LOHAGHA',
          'TAN MANDAIL',
          'TULAI SHIMUL',
          'ADAMPUR',
          'BHABANIPUR',
          'BACHIRA',
          'BAGHITALA',
          'CHAR NARAYANPUR',
          'CHAYGHARIA',
          'GANGASAGAR DIGHIRPAR',
          'DURJOYNAGAR',
          'DHATUR PAILA',
          'DHARMANAGAR',
          'DHANRAJPUR',
          'GANGA BHANGA',
          'GANGANAGAR',
          'JANGAL',
          'JOYNAGAR',
          'KHOLA PARA',
          'KUSUMBARI',
          'LONASAR',
          'MOGRA BAZAR',
          'MOGRA',
          'NOWAPARA',
          'NILAKHAD',
          'RAMNAGAR',
          'RAJENDRAPUR',
          'SENARABADI',
          'SENARABADI CHECKPOST',
          'TANUA PARA',
          'TULABARI',
          'UMEDPUR',
          'AMADABAD',
          'ANWARPUR',
          'AZAMPUR',
          'KALYANPUR',
          'KAURATALI',
          'RAJAPUR',
          'RAMDON NAGAR',
          'TANGRA PARA',
        ],
      },
      {
        name: 'Banchharampur',
        postCode: '3420',
        areas: [
          'JAGANNATHPUR',
          'DARIBANCHARAMPU R',
          'BANCHARAMP BHUYANBARI',
          'BOROBARI',
          'MONAPARA',
          'MOLLABARI',
          'T AND T PARA',
          'BAGERHATI',
          'NOTUNHATI',
          'SAFIRKANDI',
          'BORO KANDA',
          'CHHOTOKANDA',
          'NOMOPARA',
          'SAHAPARA',
          'DURGARAMPUR',
          'TEKPARA',
          'THANA COLLONI',
          'ASADNAGARPURBO',
          'ASADNAGAR PASCHIMPARA',
          'DAKSHINPARA',
          'MADYAPARA',
          'AYUBPUR',
          'BANSHGARI',
          'CHAR CHHAYANI',
          'DASANIA',
          'KANAINAGAR',
          'KARIKANDI',
          'KHASNAGAR',
          'NAGARIR CHAR',
          'PADMAPUR',
          'ALIPUR',
          'BHABANATHPUR',
          'DHARIAR CHAR',
          'KHUSKANDI',
          'MANIKHALI',
          'PANCHAMPUR',
          'TALIKANDI',
          'SAIFULLAKANDI',
          'DAKSHIN BHELANAGAR',
          'DARI BHELANAGAR',
          'DOMRAKANDI',
          'KANCHANPUR',
          'MADHYANAGAR',
          'MACHHIMNAGAR',
          'PARATALI',
          'SHARIFPUR',
          'UTTAR BALUAKAND',
          'BAKHARNAGAR',
          'DARIA DAULAT',
          'KALAINAGAR',
          'MARICHAKANDI',
          'NATUN KADAMTALI',
          'KADAMTALI (OLD)',
          'SHUTKIKANDI',
          'TATUAKANDI',
          'BAHADURPUR',
          'DARIGAON',
          'DARIKANDI',
          'GOKULNAGAR',
          'GOPALNAGAR',
          'IMAMNAGAR',
          'KHALLA',
          'MADHYA DARIKAND',
          'CHAR LAHANIA',
          'FARDABAD',
          'GAORALTALI',
          'KOLAKANDI',
          'NIJKANDI ROYPURA',
          'PURBAHATI',
          'PURBAKANDI',
          'TILAKKANDI',
          'BAHER CHAR',
          'CHAR MANIKPUR',
          'MAYARAMPUR',
          'ULKANDI',
          'BARAKANDI',
          'NABIPUR',
          'HIZLAKANDI',
          'JOYKALIPUR',
          'KALAKANDI',
          'MUNSHINAGAR',
          'PAHARIAKANDI',
          'ULUKANDI',
          'HOGLAKANDA',
          'ASHRAFPUR',
          'KHAORPUR',
          'RUPASDI MADHYAPARA',
          'RUPASDI DAKSHINPARA',
          'ASHRAFBAD',
          'BHURBHURIA',
          'DAKSHIN HAIDARNAGAR',
          'HOSSAINPUR',
          'JHUNAR CHAR',
          'KAMALPUR',
          'SATDONA KHAKKANDA',
          'NILKHI SAMIBAD',
          'PAIKAR CHAR',
          'FATHAMARA',
          'SAHEB NAGAR',
          'SALIMABAD',
          'SATBILA',
          'AKANAGAR',
          'BISHNURAMPUR',
          'DAKSHINPARA (GOTKAND',
          'HARINAGAR',
          'HASANNAGAR',
          'TEJKHALI',
          'BUDAIRKANDI',
          'RADHANAGAR',
          'SARISHAR CHAR',
          'SEKHARKANDI',
          'UJAN CHAR',
        ],
      },
      {
        name: 'Brahamanbaria Sadar',
        postCode: '3402',
        areas: [
          'PASHCHIM MEDDA',
          'PURBA MEDDA',
          'PAIKPARA(PART) 3',
          'SHERPUR',
          'KHAIASHAR',
          'FULBARIA',
          'WEST PAIKPARA',
          'BAZAR AREA',
          'PURBA PAIKPARA',
          'DAKSHIN MADHYA PARA',
          'BRAHMANBARIA UTTAR MADHYAPARA',
          'CHHAYGHARIA',
          'DARIAPUR',
          'DAKSHIN PAIRTALA',
          'AMINPUR',
          'CHHAYBARIA',
          'CHHOTA GOKARNA',
          'CHANDALKHIL',
          'KAZIPARA',
          'DATIARA',
          'DAKSHIN MORAIL',
          'NAYANPUR',
          'PUNIOUT',
          'UTTAR MORAIL',
          'KANDI PARA',
          'KAWTALI',
          'UTTAR MURAIL',
          'UTTAR BHADUGHAR',
          'SIMRAILKANDI',
          'DAKSHIN BHADUGHAR',
          'All Ward-1 to 12',
          'AHARAND',
          'BAISHNABPUR',
          'BARISAL',
          'BASUDEB',
          'BHATSALA',
          'CHANDI',
          'DUBLA',
          'DATAISAR',
          'GHATIARA',
          'JARAILTALA',
          'KODDA',
          'KORABARI',
          'PURBA PAIK PARA',
          'UJANISAR',
          'BETBARIA',
          'CHANDIARA',
          'CHHATIAN',
          'JANGLISAR',
          'KHATIHATA',
          'MALIHATA',
          'NANDANPUR',
          'SUTIARA',
          'BARA KALISIMA',
          'BIL KENDUAI',
          'BARA HARAN',
          'CHHOTA HARAN',
          'GACHHTALA',
          'NARASINGHESWAR',
          'SALGAON',
          'SINDURURA',
          'ATLA',
          'ALAKPUR',
          'BHAT PARA',
          'BHADESHWARA',
          'CHINAIAR',
          'CHAPAIR',
          'DAKSHIN JAGATSAR',
          'GAZARIA',
          'KACHAIT',
          'KAZIRKHOLA',
          'KHEOAI',
          'MACHHIHATA',
          'PAGHACHANG',
          'RADHIKA',
          'UTTAR JAGATSAR',
          'BARA BAKAIL',
          'CHHOTA NISCHINTAPUR',
          'CHHOTA BAKAIL',
          'DARMA',
          'JAFARGANJ',
          'KHARAMPUR',
          'MAIND',
          'MAJLISHPUR',
          'MAKDAMPUR',
          'SHAMPUR',
          'ASHTAGRAM',
          'DHANSAR',
          'MOHANPUR',
          'POOTHAI',
          'SONASAR',
          'TELINAGAR',
          'CHANDPUR UTTAR',
          'BHOLACHANG',
          'BIALISWAR',
          'BIJESHWAR',
          'JATRABARI',
          'MAGURURA',
          'GANGIHATHA (MOHAMMADPUR)',
          'RAMRAIL',
          'SENDA',
          'SURIAKHOLA',
          'SOHATA',
          'SREERAMPUR',
          'ULCHA PARA',
          'BIRAMPUR',
          'CHILOKUT',
          'DAMCHAIL',
          'KHAGCHAIL',
          'RAJAKHA',
          'SADEKPUR',
          'AURKAUR',
          'ALAMPARA',
          'DAKSHIN KENDUBARIA',
          'DAKSHIN GHATURA',
          'GOPINATHPUR',
          'GAUTAM PARA',
          'HARINADI',
          'HARIA',
          'HINDU PARA',
          'KALAMURI',
          'NADA PARA',
          'NAOBARI',
          'PARULIA PARA',
          'PATHAN PARA',
          'SITANAGAR',
          'HABLAUCHCHA',
          'ISHAN NAGAR',
          'JHOKARPAR',
          'MAHIUDDINNAGAR',
          'RAJKRISHNAPUR',
          'SHAHAPUR',
          'SILAURA',
          'SITASHAR',
          'TANGARPAR',
          'URSIURA',
          'UTTAR JANGAL',
          'UTTAR SULTANPUR 4',
          'YAKUBNAGAR',
          'BEHAIR',
          'BHULTARA',
          'BIRASAR',
          'KHODRA BRAHMANBARIA',
          'NATAI',
          'NOAPARA',
          'RAJGHAR',
          'THALIARA',
          'TALE PARA',
          'ARAISIDHA',
          'Talshahar',
          'Ashuganj',
          'Ashuganj Share',
          'Poun',
        ],
      },
      {
        name: 'Kasba',
        postCode: '3462',
        areas: [
          'TILARPAR',
          'ULUCHARA',
          'GURIARUP',
          'TALTALA',
          'BISHARABARI',
          'BAGAIA',
          'KASBA PASCHIM',
          'SHALIK PARA',
          'ARIBARI',
          'FULTALI',
          'GURUHIT',
          'KASBA SADAR',
          'TETAIA',
          'SITALPARA',
          'AKUBPUR',
          'CHARNAL',
          'KHAR PARA',
          'TARAPUR',
          'MURA PUKURPAR',
          'BAGA BARI',
          'KAKAR',
          'KANCHANMURI',
          'KRISNAPUR',
          'PANIARPARA',
          'BADAIR',
          'BARNI',
          'HATURBARI',
          'JAMSHERPUR',
          'KARAMULLAPUR',
          'KALSAR',
          'MANDARPUR',
          'NURULLAPUR',
          'SHIKARPUR',
          'BALIAHURA',
          'BAYEK',
          'BADALIA',
          'BARIKHOLA',
          'CHARUA',
          'DHOPAKHALA',
          'GAURANGALA',
          'GOPINAGAR',
          'JOYDEBPUR',
          'RAGHURAMPUR',
          'SAGARTALA',
          'SASTAPUR',
          'SHYAMPUR',
          'ANANTAPUR',
          'BRAHMANGRAM',
          'CHANDRAPUR',
          'CHAPIA',
          'CHANDAISAR',
          'CHAK CHANDRAPUR 0',
          'DHAMSAR',
          'DURAIN',
          'HAJIPUR',
          'KHIDIRPUR',
          'KHARGHAR',
          'MAINCHANG',
          'NAMTABAD',
          'ROJA BAR',
          'RAUTHHAT',
          'RAUTHHAT BAZAR',
          'SAIDABAD',
          'GABBARI',
          'TIGHARIA',
          'ALAMPUR',
          'DAJANAGAR',
          'DIGHIRPAR',
          'ENAYETPUR',
          'GOSAIRSTHAL',
          'GUNPUR',
          'GOSHAIPUR',
          'GANAKMURA',
          'JETUAMURA',
          'KHIRNAL',
          'KAZIATALA',
          'KASHIPUR',
          'LATUAMURA',
          'CHATAKHOLA',
          'JAJISHAR',
          'JIKRA',
          'KAIMPUR',
          'KALTA',
          'KHURISHER',
          'MANDABAGH',
          'MAINPUR',
          'NAKAHURA',
          'OMARPUR',
          'RAMKHOLA',
          'ROUTHKHOLA',
          'SREEPUR',
          'BUGAIR',
          'DELI',
          'DHARMAPUR',
          'DAMSAR',
          'GOLASAR',
          'KEAIR',
          'KHERERA',
          'MANKASAIR',
          'MADHABPUR',
          'PATAISAR',
          'SONARGAON',
          'ATKHA PARA',
          'BILGHAR',
          'BAISAR',
          'BHAIRABNAGAR',
          'CHANDINAGAR',
          'DAKSHINKHAR',
          'GUNSAGAR',
          'GAURIPUR',
          'JAJIARA',
          'SHASANSAR',
          'SHANTIPUR',
          'SHARATNAGAR',
          'TARANAGAR',
          'BALLABHPUR',
          'BAHERHATA',
          'BAMUTIA',
          'CHHABPUR',
          'ESAN NAGAR',
          'JAMUNA',
          'KHEORA',
          'MEHARI',
          'PURKUIL',
          'SIMRAIL',
          'AMKHAR',
          'BAURKHANDA',
          'CHARGACHH',
          'JOYPUR',
          'LABKHAR',
          'MULGRAM',
          'NIAMATPUR',
          'NIMBARI',
          'PUKURPAR',
          'ROYTALA',
          'SHYAMNAGAR',
          'SHYAMBARI',
          'Kuti',
          'Chandidar',
          'Gopinathpur',
        ],
      },
      {
        name: 'Nasirnagar',
        postCode: '3441',
        areas: [
          'BAGHI',
          'BALIKHOLA',
          'BARUICHIRA',
          'KANDI',
          'KHAGALIA',
          'KUTAI',
          'KADARPUR',
          'SADHAN',
          'ASHURAIL',
          'UTTAR BHAT PARA',
          'BURISHWAR',
          'CHAND PARA',
          'DAKSHIN SINGAON',
          'ICHHAPUR',
          'SREEGHAR',
          'TILPARA',
          'BENGAUTA',
          'BARAIURI',
          'CHAPARTALA',
          'GARAUK',
          'KALIUTA',
          'KHANDURA',
          'TARAULLA',
          'URIAIN',
          'BILERPAR',
          'BARANAGAR',
          'BARUAIL',
          'CHATALPAR',
          'DHANTALIA',
          'FAKIRDIA',
          'HASIMPUR',
          'NIAZPUR',
          'RATANPUR',
          'Fandauk',
        ],
      },
      {
        name: 'Sarail',
        postCode: '3432',
        areas: [
          'BADE AORAIL',
          'BARA PAIKA',
          'DHAMAURA',
          'DUBAJAIL',
          'KAKARIA',
          'RANIDIA',
          'SOLAKANDI',
          'BARAIL',
          'BARA BALLAH',
          'CHUNTA',
          'DEOBARI',
          'GHAGRAJOR',
          'KARATKANDI',
          'LOPARA',
          'NARSINGHPUR',
          'NATUNHAT',
          'RASULPUR',
          'RANGURI PARA',
          'BISHUTARA',
          'CHAKSAR',
          'DHARMATIRTHA',
          'SAHAPUR',
          'GALANIA',
          'GUNARA',
          'KABITARA',
          'DHARMA THIRTA',
          'MULBARGA',
          'SHAHPUR',
          'SURJYAKANDI',
          'AKHITARA',
          'BUDDA',
          'AIRAL',
          'BUCHIURA',
          'BARUIURA',
          'CHOWRAGUDA',
          'DHALLA',
          'GUCHCHHAGRAM',
          'GOGADH',
          'KATANISAR',
          'KAZIURA',
          'KUCHNI',
          'KANIUCH',
          'MAHALDARA',
          'NOAGAON',
          'TERKANDA',
          'BRAHMANGAON',
          'BHUISAR',
          'BARAICHARA',
          'JOYDHARKANDI',
          'KALI SHIMUL',
          'MEGHAL',
          'PAK SHIMUL',
          'PARAMANANDAPUR',
          'SHAITBARIA',
          'TELIKANDI',
          'BARAIBARI',
          'BERTALA',
          'BITGHAR',
          'DIOBARIA',
          'NAILABAND',
          'PANISAR',
          'SAKHAITI',
          'SULABARI',
          'SITAHARAN',
          'KANIKHAI',
          'TIGHAR',
          'ALINAGAR',
          'BODDA PARA',
          'BARA GUNARA',
          'BANIK PARA',
          'BARA DEWAN PARA 0',
          'CHHOTA GUNARA',
          'UTTAR PASCHIM BEPARI PARA 0',
          'CHANDMANI PARA',
          'CHHOTA DEWAN PARA 0',
          'DAKSHIN ARIFIEL',
          'DEWAN HABLI',
          'HABLI PARA',
          'HALUA PARA',
          'JILUKDAR PARA',
          'KUTTA PARA',
          'LATA BAIDDYA PARA',
          'MOGALTULA',
          'NATUN HABLI',
          'NIJ SARIL',
          'PATHAN PARA 3',
          'BALARAMPUR',
          'BARA DHITPUR',
          'BISHAMARA',
          'JADABPUR',
          'NOAGOAN',
          'SAHBAZPUR',
          'BHATBARIA',
          'DHAURIA',
          'DEORA',
          'DEOBARIA',
          'GAZIPUR',
          'MALAIS',
          'Shahbajpur',
          'Chandura',
        ],
      },
      {
        name: 'Asugonj',
        postCode: '3402',
        areas: [
          'DAGARISAR',
          'LAUSAR',
          'CHAR CHARTALA',
          'ASHUGANG',
          'BAIKUNTHAPUR',
          'BARATALA',
          'CHAR SONARAMPUR',
          'JATRAPUR',
          'JAGADISHPUR',
          'KHARASHAR',
          'NAOGHAT',
          'SONARAMPUR',
          'BOGAIR',
          'BAHADURPUR G.C',
          'KHARIALA',
          'SOHAGPUR',
          'TAJPUR',
          'CHAR LALPUR',
          'LALPUR HOSSAINPUR',
          'LAMA BAYEK',
          'DAKSHIN TARUA',
          'LAMA SHARIFPUR',
          'ANDIDIL',
          'BASUTARA',
          'KAMAURA',
          'MAISHAIR',
          'TALSAHAR G.C',
          'BARABAN',
          'BABUDI',
          'JHARAKHALA',
          'PURBA TARUA',
          'SALUK PARA',
          'TARUA',
        ],
      },
      {
        name: 'Bijoynagar',
        postCode: '3401',
        areas: [
          'BAKTARMURA',
          'CHHATARPUR',
          'DAKSHIN BISHNUPUR',
          'DULALPUR',
          'GHANASHYAMPUR',
          'EKARTALI',
          'KAZIMURA',
          'MAHISHPUR',
          'PASCHIM KALACHHARA',
          'PURBA KALACHHARA',
          'BINNIGHAT',
          'BUDHANTI',
          'BIRPASHA',
          'BARGHARIA',
          'KENA',
          'KHATABARI',
          'MERASANI',
          'SATBARGA',
          'SHEMRA',
          'SHASHAI',
          'SREENAGAR',
          'BADE HARIA',
          'BARIRBAND',
          'BARA HARIA-1',
          'GERARGAON',
          'GOPALPUR KHOLA',
          'JAMALPUR',
          'JAMALPUR KHOLA 1',
          'KHUDE HARIA',
          'MASIMPUR',
          'PETUAJUR',
          'RAMCHANDRAPUR',
          'SATIR PARA',
          'SONABARSHI PARA',
          'TAN GARARGAON',
          'ANANDAGRAM',
          'BHATI KALISIMA',
          'BASUDEBPUR',
          'CHHOTA KALISIMA',
          'CHANDURA',
          'DAKSHIN PURBABHAG',
          'IBRAHIMPUR',
          'JAGATPUR',
          'JALALPUR',
          'KAYESTHA PARA',
          'MANIKPUR',
          'PASARCHAND',
          'RAMPUR',
          'SAPUTIA',
          'SATGAON',
          'AMANPUR',
          'BARAMURA',
          'BAIRAGHIHATI',
          'CHAR PARENGA',
          'CHAR KHOLA',
          'DAKSHIN PELUNPUR',
          'DAKSHIN RAJABARI',
          'GACHULIAHATI',
          'KALYANMURI',
          'MORAILMURA',
          'MADHABPUR STHAL 2',
          'MAMUDPUR',
          'NAJIRBARI',
          'PASCHIM ISLAMPUR 3',
          'RAKHALMURI',
          'RANGINBARI',
          'ROSHNAKANDI',
          'SHIKARPUR',
          'SADULLABARI',
          'SOLAMURA',
          'UTTAR FELUNPUR',
          'BAGHALPUR',
          'KHIRATALA',
          'KASHIMNAGAR',
          'KASHIMPUR',
          'MADHYA PARA',
          'MERASHANI',
          'MURADNAGAR',
          'NAYABADI',
          'NALGARIA',
          'SINGERBIL',
          'SINGERBIL BAZAR',
          'OTARIA PARA',
          'ATURA PARA',
          'BAGHDIA',
          'BARA UTHAN',
          'BARA CHAL',
          'BULLA',
          'EKTARPUR',
          'HARASHPUR',
          'KAICHA PARA',
          'MEGH SIMUIL',
          'NIDARABAD',
          'NAYAHATI',
          'PANCHGAON',
          'PAIK PARA',
          'SHISHAURA',
          'SONAMURA',
          'DHURANAL',
          'DALILNAGAR',
          'DAULATPUR',
          'GOALNAGAR',
          'JASHAMANTAPUR',
          'JALILPUR',
          'KAMALMURA',
          'KHATENGA',
          'PAHARPUR',
          'PURBA NAYANPUR 3',
          'SHEJAMURA',
          'PATTAN',
          'UTTAR ICHHAPUR',
        ],
      },
      {
        name: 'Nabinagar',
        postCode: '3401',
        areas: [
          'ALAM NAGAR',
          'BHATANA BINAGAR',
          'HASPATAL PARA',
          'NABINAGAR BANIK PARA',
          'NABINAGAR BIJOY PARA',
          'NABINAGAR DASPARA',
          'NABINABINAGAR PASCHIM PARA',
          'IMAM BARI',
          'KHAZA NAGAR',
          'NABINAGAR PAL PAR',
          'NABINAGAR UTTAR PARA',
          'ADALAT PARA',
          'COLLEGE PARA',
          'OFFICE PARA',
          'PARBA PARA',
          'PADMA PARA',
          'SAHA PARA',
          'TANDT PARA',
          'ALIABAD',
          'MAZIKARA',
          'JALLA',
          'UTTAR NARAYANPUR',
          'DAKSHIN NARAYANPUR',
          'DASPARA',
          'PALPARA',
          'PURBA DOLABAR',
          'RISHI PARA',
          'BHELANAGAR',
          'GUNIN PARA',
          'KARMAKER PARA',
          'PASCHIM DOLABARI',
          'RUDRA PARA',
          'SHOHATA',
          'Jibanganj',
          'Salimganj-Nabinagar',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Chandpur',
    zones: [
      {
        name: 'Chandpur Sadar',
        postCode: '3600',
        areas: [
          'Chandpur Puran Bazar',
          'Momefactory',
          'Refugee Colony',
          'Uttar Ramany Mohan Road',
          'Uttar Merkatig Road',
          'Madhya Sreeramdi',
          'Gosh Para (Part)',
          'Paschim Sreeramdi',
          'Paschim Jafrabad (Paura Part)',
          'Royez Road (Uttar)',
          'Sayal Bari Road (Uttar)',
          'Daspara',
          'Dakshin Sreeramdi',
          'Palpara',
          'Jafrabad',
          'Ladaras Company',
          'Amjad Ali Road',
          'Hazi Sariat Ullha Road(Purbapt',
          'Purba Sreeramdi',
          'Madhya Jafrabad',
          'Star Al-Kaid Jute Mill',
          'W.R.Jute Mill',
          'Municipality Mauza',
          'Uttar Ragunath Pu',
          'Bakultala Road',
          'J.M sengopta Road',
          'Matarniti Road',
          'Metha Road',
          'Nadir Parer Kandi',
          'Stand Road',
          'Comilla Road',
          'Pal Bazar',
          'Nisi Quatar Kacha Colony',
          'G.R.P Colony & Loko Collony',
          'Jamuna Road (P.H)',
          'Madrasha Road',
          'Jamtala Nisi Road',
          'Railway Sweeper Colony',
          'Railway Saramik Colony',
          'Railway Kabarstan',
          'Railway Colony',
          'Railway Club Road',
          'Tila Bari',
          'Adarsha Muslim Para',
          'Chowduri Para',
          'Gosh Para',
          'Hazi Mohshin Road',
          'Guakhola Road',
          'J.T.C. Kuli Bagan',
          'Kuralia Road Paschim',
          'Kuralia Road Purba',
          'Munshef Para',
          'Sahid Muktijudda Sarak',
          'Railway Fidar Road',
          'Mahila Colege Road',
          'Bakaul Bari Road',
          'Addapak Para',
          'Bus Stand',
          'Shingha Para',
          'Koralia',
          'Paschim Bishnadi Road',
          'Pir Badshamia Road',
          'Biddabotikhal',
          'Staff Quater',
          'Sarankhula Road',
          'Pashim Bishnadi Mohalla',
          'Adalat Para',
          'Alim Para',
          'C.Sd Colony',
          'Ganguly Para',
          'Hazi Mohashin Road',
          'Palpara',
          'Kadamtala',
          'Natun Alim Para',
          'Purba J.M.Seengupta Road',
          'Rahamatpur Abashikelaka',
          'Shahid Jabed Darak',
          'Shahid Ka.Kha.Su.Sankar Road',
          'Ukil Para',
          'Char Ragunath Pur Mohalla',
          'Gunarajdipower Station Mohalla',
          'Madhya Ichali Mohalla',
          'Rashid Patwary Road',
          'Bangabandhu Sarak',
          'Dakshin G.T. Road',
          'Haji Moshahin Road (East)',
          'Eye (Chakku)Hospital Alaka',
          'Kabarastan Alaka',
          'Mission Road',
          'Nazir Para',
          'Zila Prasasaker Karzzalay',
          'Uttar Gunarajdi',
          'Baherkhali Shaduli Mahalla',
          'Chandpur-F.G.Rd E.S.D.Tarpur',
          'Chandpur-Faridgong Rd W.S.D.Tp',
          'Khalishaduli North',
          'Enayet Nagar Mahalla',
          'Gucha Gram',
          'Khalishaduli South',
          'Police Line',
          'Mirzapur Mahalla',
          'U.N.O Officc&Cerkithouse Elaka',
          'Dhakin Ashikati Mahalla',
          'Dhakin Dashdi Model Towan',
          'Silandia Mahalla',
          'Uttar Ashikati Mahalla',
          'B.T. Road Alak',
          'Bank Colony',
          'Purba Bishanadi Road',
          'Uttar G.T. Road',
          'Uttar Biddabati Khaler',
          'Motsha Gobesona Kandra Alaka',
          'Telephone Exch.Off.Elaka',
          'Ashikati Union.',
          'Kalyanpur Union',
          'Baghadi Union',
          'Balia Union',
          'Bishnupur Union',
          'Chandra Union',
          'Hanar Char Union',
          'Ibrahimpur Union',
          'Maishadi Union',
          'Rajrajeshwar Union',
          'Shah Mahmudpur Union',
          'Rampur Union',
          'Sakhua Union',
          'Tarpur Chandi Union',
          'Ashikati',
          'Hapania',
          'Hossainpur',
          'Paikasta',
          'Raldia',
          'Sengaon',
          'Amanullahpur',
          'Dasadi',
          'Kalyandi',
          'Pathalia',
          'Rangergaon',
          'Baghadi',
          'Brahman Sakhua',
          'Char Baghadi',
          'Char Measa',
          'Gachhtala',
          'Ghasipur Char',
          'Hajra',
          'Islampur Gachhtala',
          'Makimpur',
          'Nanupur',
          'Pakshidia',
          'Paschim Sakdi',
          'Ramchandrapur',
          'Sobhanpur',
          'Chapila',
          'Durgat',
          'Gulisa',
          'Ichali',
          'Kumurua',
          'Sapdi',
          'Uttar Balia',
          'Bishnupur',
          'Damodardi',
          'Dhanparddi',
          'Hasadi',
          'Kanudasdi',
          'Kherudia',
          'Lalpur',
          'Madhyam Char',
          'Manoharkhadi',
          'Nurullapur',
          'Projapaddi',
          'Sarangdi',
          'Sugandhi',
          'Bakharpur',
          'Dakshin Balia',
          'Madna',
          'Gobindia',
          'Hanar Char',
          'Sadullapur',
          'Char Fatehjangpur',
          'Ibrahimpur',
          'Jafrabad',
          'Mukundi',
          'Narahundi',
          'Prasannapur',
          'Sakhua',
          'Hamankardi',
          'Maishadi',
          'Balasia',
          'Ralasia',
          'Raher Cha',
          'Goalnagar',
          'Baliar Char',
          'Banshgari',
          'Char Jahajmara',
          'Char Suresh',
          'Chirar Char',
          'Ghoramara',
          'Mugadi',
          'Raj Rajeswar',
          'Silar Char',
          'Alumura',
          'Bara Sahatali',
          'Bharangar Char',
          'Bhatergaon',
          'Bimalergaon',
          'Chhota Sahatali',
          'Damakergaon',
          'Dasergaon',
          'Kardi Panchgaon',
          'Ketua',
          'Krishnapur',
          'Kumardugi',
          'Lodhergaon',
          'Paikdi',
          'Ranbaila',
          'Taharkhil',
          'Algi',
          'Bara Sundar',
          'Chanderbagh',
          'Char Bakila',
          'Chhota Sunda',
          'Debpur',
          'Kamranga',
          'Manihar',
          'Panchbaria',
          'Rampur',
          'Rarir Char',
          'Sakdi Panchgaon',
          'Araji Balia',
          'Baharia',
          'Bara Raghunathpur',
          'Kotrabad',
          'Lakshmipur',
          'Kamalapur',
          'Sreerampur',
          'Ramdasdi',
          'Baburhat',
          'Sahatali',
        ],
      },
      {
        name: 'Faridganj',
        postCode: '3651',
        areas: [
          'Keroa',
          'Gudara Char',
          'Bhatirgaon',
          'Mirpur',
          'Nayagaon',
          'Sahapur',
          'Barali',
          'Bhatirgaon',
          'Rudragaon',
          'Char Hogla',
          'Sapua',
          'Faridganj',
          'Kasiara',
          'Bhatialpur',
          'Char Kumira',
          'Kumira Mathura Cha',
          'Baliarpur',
          'Beharipur',
          'Char Matki',
          'Dakshin Rajapur',
          'Kharkhadia',
          'Lohagarh',
          'Madanergaon',
          'Paltaluk',
          'Sakdi Rampur',
          'Sekhdi',
          'Tarjirandi',
          'Tubgi',
          'Balothupa',
          'Dalmagar',
          'Dalmagar Char',
          'Debipur/Islampur',
          'Deichar',
          'Diar Mondal',
          'Krishnapur',
          'Manikraj',
          'Masimpur',
          'Mulpara',
          'Ranbaila Char',
          'Shahid Saiyed Nagar',
          'Shasair Cha',
          'Uttar Rajapur',
          'Alunia',
          'Ekhlaspur',
          'Murarir Tek',
          'Santoshpur',
          'Bishkatali',
          'Char Channa',
          'Char Dukhia',
          'Larair Char',
          'Purba Larura',
          'Putia',
          'Char Barali',
          'Char Para',
          'Char Rampur',
          'Gazaria',
          'Harni',
          'Powa',
          'Ranatali',
          'Chandpur',
          'Char Mathura',
          'Chiraka',
          'Dhanua',
          'Dighaldi',
          'Harina',
          'Pratyasi',
          'Sobhan',
          'Char Bhagal',
          'Char Raghabroy',
          'Gobindapur',
          'Haoakandi',
          'Hasa',
          'Lamchar',
          'Naldugi',
          'Paschim Larua',
          'Rampurhat',
          'Ashta',
          'Baichatali',
          'Balumura',
          'Datra',
          'Dhar Para',
          'Domaria',
          'Ghania',
          'Guatoba',
          'Gupti',
          'Manuri',
          'Narikeltala',
          'Sreekalia',
          'Tridona',
          'Adsa',
          'Hamchapur',
          'Hogli',
          'Khajuria',
          'Lautali',
          'Mandartali',
          'Saisanga',
          'Sholadana',
          'Asatkuari',
          'Bhawal',
          'Bisher Bonda',
          'Dattasolla',
          'Purba Joysree',
          'Paschim Joysree',
          'Kamalpur',
          'Kasara',
          'Naya Para',
          'Paik Para',
          'Paltaluk',
          'Sachiakhali',
          'Sasiali',
          'Upadhik',
          'Uttar Nadona',
          'Balichatia',
          'Bhangergaon',
          'Chaumukha',
          'Daichora',
          'Dakshin Nadona',
          'Gazipur',
          'Ichhapur',
          'Jamalpur',
          'Kabi Rupsa',
          'Karaitali',
          'Uttar Karaital',
          'Dakshin Karaital',
          'Khurumkhali',
          'Ramdaserbagh',
          'Sahapur',
          'Badarpur',
          'Badiuzzamanpur',
          'Bara Paika',
          'Bhaterhad',
          'Gabdergaon',
          'Para Iabdergaon',
          'Ghorashala',
          'Komalkandi',
          'Kutubpur',
          'Rupsa',
          'Rustampur',
          'Char Maghua',
          'Char Mandar',
          'Chhota Raghunathpur',
          'Gridha Kalindia',
          'Kaonia',
          'Mandarkhil',
          'Nalgora',
          'Pakshiar Char',
          'Sahebganj',
          'Bagura',
          'Basara',
          'Chalia Para',
          'Digdhair',
          'Lakshmipur',
          'Mantala',
          'Phanisair',
          'Sonacho',
          'Subidpur',
          'Surangachail',
          'Telisair',
          'Ubharampur',
          'Aitpara',
          'Bachpar',
          'Badarpur',
          'Baragaon',
          'Bhulachow',
          'Chauranga',
          'Gharihana',
          'Gobarchitra',
          'Kamta',
          'Mukundapur',
          'Nurpur',
          'Saidpur',
          'Sajanmegh',
          'Saldaha',
          'Solla',
          'Tamrashasan',
          'Ut-Tali',
          'Chandra',
          'Rampurbazar',
          'Islampur Shah Isain',
        ],
      },
      {
        name: 'Haziganj',
        postCode: '3610',
        areas: [
          'Sree Narayn Pur',
          'Paschim Dherra',
          'Purba Balakhal',
          'Khatra Beluai',
          'Purba Dherra',
          'Uttar Makimabad',
          'Dakshin Makimabad',
          'Purba Makimabad',
          'Badarpur',
          'Pschim Kongaish',
          'Uttar Toragar',
          'Dakshin Toragarh',
          'Enayetpur',
          'Purba Kongaish',
          'Randhanimura',
          'Aruli',
          'Barkul',
          'Uttar Barku',
          'Madhya Barkul',
          'Dakshin Barkul',
          'Mohanpur',
          'Annatoli',
          'Dakatia Char',
          'Digchail',
          'Ghasipur',
          'Kaijanga',
          'Kazirkhil',
          'Kondra',
          'Mollar Dahar',
          'Rasulpur',
          'Nasirpur',
          'Raicho',
          'Uttar Raicho',
          'Dakshin Raicho',
          'Nobadda',
          'Sendra',
          'Sonaimuri',
          'Brahmanichoa',
          'Debipur',
          'Gobindapur',
          'Jakani',
          'Kurali',
          'Gupalkhur',
          'Natehara',
          'Pratappur',
          'Ramchandrapur-Cahndpur',
          'Sadra',
          'Samaspur',
          'Ahammadpur',
          'Gandharabpur',
          'Maligaon',
          'Shamuli Guchchhagram',
          'Haripur',
          'Jagannathpur',
          'Kakairtala',
          'Khashkhasia',
          'Mohammadpur',
          'Maisamura',
          'Palesara',
          'Sarbatara',
          'Taralia',
          'Bhaor Para',
          'Dhatra Shibpur',
          'Deshgaon',
          'Khagaria',
          'Hotani',
          'Joysara',
          'Kashimpur',
          'Nischintapur',
          'Pachai',
          'Payaljos',
          'Badda',
          'Baura',
          'Betia Para',
          'Doalia',
          'Kazirgaon',
          'Purba Kazirgaon',
          'Paschim Kazirgaon',
          'Maisaid',
          'Matain',
          'Satbaria',
          'Subidpur',
          'Sudhaia',
          'Suilpur',
          'Suilpur',
          'Kasbaria',
          'Shapur',
          'Uchanga',
          'Baniacho',
          'Brahmangaon',
          'Char Para',
          'Chilacho',
          'Deodron',
          'Fechualezil',
          'Kapaikap',
          'Rabidas Para',
          'Khil Para',
          'Mahabbatpur',
          'Marki',
          'Nischintapur',
          'Nayagaon',
          'Sihircho',
          'Tarapalla',
          'Baniakandi',
          'Bajnakhal',
          'Bhatra',
          'Kalocho',
          'Mahamadpur',
          'Kordi',
          'Maramura',
          'Naohata',
          'Saksi Para',
          'Orpur',
          'Parapur',
          'Rampur',
          'Sidla',
          'Dhadda',
          'Gaurishwar',
          'Kanthali',
          'Sarasia',
          'Noapara West',
          'Patanis',
          'Nischintapur',
          'Balia',
          'Belghar',
          'Rajapur',
          'Belghar',
          'Hariain',
          'Hatila',
          'Hatila',
          'Ganganagar',
          'Laokara',
          'Noapara East',
          'Padua',
          'Tangirpar',
          'Ahmedabad',
          'Mala Para',
          'Menapur',
          'Pepia',
          'Mukundasar',
          'Singair',
          'Kumarchar',
          'Nairain',
          'Nasirkot',
          'Char Rian',
          'Rajargaon',
          'Purba Rajargaon',
          'Paschim Rajargaon',
          'Bakhar Para',
          'Bakila',
          'Borkhal',
          'Chatantar',
          'Chhaychila',
          'Dakshin Sreepur',
          'Digai',
          'Gogra',
          'Kirtankhola',
          'Lodpara',
          'Maheshpur',
          'Uttar Sreepur',
          'Phulchua',
          'Radhasar',
          'Sanna',
          'Khola Para',
          'Satbari Bhanga',
        ],
      },
      {
        name: 'Hayemchar',
        postCode: '3661',
        areas: [
          'Lamchari',
          'Mahazampur',
          'Nayani Lakshmipur',
          'Uttar Algi',
          'Char Bhanga',
          'Char Poramukhi',
          'Dakshin Algi',
          'Gandamara',
          'Paschim Char Krishnapur',
          'Purba Char Krishnapur',
          'Haim Char',
          'Char Koralia',
          'Char Pokkeydia',
          'Bazapti',
          'Gazipur Kutubpur',
          'Manipur',
          'Gazipur Manipur',
          'Char Ishanbala',
          'Jabbar Mollakandi',
          'Atabuddin Sikdar Kandi',
          'Chokidar Kandi',
          'Bapari Kandi',
          'Dakshin Dira Kandi',
          'Baher Char',
          'Suruja Kamal(Malak)Sardarkandi',
          'Teher/Kashim(Rahimsardar Kandi',
          'Khalil(Khlak)Sardar Kand',
          'Char Rao',
          'Kamal Sharder Kandi',
          'Mannan Mal Kandi',
          'Giashuddin Sikder Kandi',
          'Ishanbala',
          'Sultan Mollarkandi',
          'Hamid Mollarkandi',
          'Sona Mia Bapari Kandi',
          'Shabuddin Sharder',
          'Ishanbala Bazar',
          'Terashe Kand',
          'Kala Bepari Kandi',
          'Habib Sardar Kandi',
          'Miar Char',
          'Nilkamal',
          'Ukil Kandi',
          'Aliullah Bapari Kandi',
          'Shazan Molla Kandi',
          'Amo Haldar Kandi',
          'Monsi Kandi',
          'Topazal Sardar',
          'Hasam Malar Kandi',
          'Napal Kandi',
          'Sikdar Kandi',
          'Hazi Kandi',
          'Ratan Hazi Kandi',
          'Mazi Kandi',
          'Sardar Kandi',
          'Ali Hossani Kandi',
          'Bapari Kand',
          'Pada(Chokidar)Kandi',
        ],
      },
      {
        name: 'Kachua',
        postCode: '3630',
        areas: [
          'Kanderpar',
          'Uttar Koachandpur',
          'Dakshin Koachandpur',
          'Koa',
          'Nilam Para',
          'Palashpur',
          'Purba Koa',
          'Paschim Uttar Karaia',
          'Paschim Karaia',
          'Purba Karaia',
          'Dakshim Purba Koresh',
          'Karaia',
          'Lakshmipur',
          'Dhamalia',
          'Masimpur',
          'Uttar Paschim Koresh',
          'Hat Kuchada',
          'Paschim Koresh',
          'Koresh Purba',
          'Ashrafpur',
          'Bara Bhabanipur',
          'Bara Para',
          'Chakra',
          'Changini',
          'Chhota Bhabanipu',
          'Dhanaia',
          'Jagatpur',
          'Kalocho',
          'Kamairbagh',
          'Masnigachha',
          'Mathurapur',
          'Pipulkara',
          'Punsahi',
          'Junasar',
          'Rasulpur',
          'Rampur',
          'Sanandakara',
          'Ujali',
          'Abhay Para',
          'Baichhara',
          'Laia Mohar',
          'Pasdara',
          'Nowapara',
          'Bitara Durgapur',
          'Bitara',
          'Durgapur',
          'Jola Bitara',
          'Budhanda',
          'Mazigachha',
          'Jugichapor',
          'Changpur',
          'Teguria',
          'Batapukuria',
          'Shajirpar',
          'Chand Para',
          'Haripur',
          'Khalagaon',
          'Kundapur',
          'Sorialkandi',
          'Nindapur',
          'Rajbari',
          'Sasan Para',
          'Silastambha',
          'Silastambha Tely',
          'Uttar Shibpu',
          'Aingiri',
          'Burgi',
          'Batabaria',
          'Bedpur',
          'Harichail',
          'Hasempur',
          'Kalamian',
          'Khila',
          'Naula',
          'Palgiri',
          'Satbaria',
          'Padua',
          'Tafira',
          'Taltali',
          'Baratulagaon',
          'Amujan',
          'Balara',
          'Chandiara',
          'Chanpatali',
          'Dari Gobindapur',
          'Gobindapur',
          'Gohat',
          'Islampur',
          'Kaitoba',
          'Kandirpar',
          'Kesarkot',
          'Khajuria',
          'Naopura',
          'Paragaon',
          'Rajapur',
          'Saharpar',
          'Sakhura',
          'Uchitgaba',
          'Bara Char',
          'Dahar Char',
          'Khidda',
          'Nahara',
          'Uttar Karaia',
          'Latifpur',
          'Noadda',
          'Singadda',
          'Tetaia',
          'Jala Tetaia',
          'Ujani',
          'Uttar Noagaon',
          'Akania',
          'Andirpar',
          'Badarpur',
          'Bakhaia',
          'Dhali Kachua',
          'Ghagra',
          'Hossainpur',
          'Kajkamta',
          'Abdullapur',
          'Kumarkasa',
          'Tulpai',
          'Daulatpur',
          'Rajapur',
          'Kachua',
          'Ayma',
          'Sasankhola',
          'Tegharia',
          'Baraigaon',
          'Sahapur',
          'Chak Mohammadpur',
          'Debipur',
          'Doghar',
          'Goal Bhaor',
          'Kadla',
          'Kafilabari',
          'Madhupur',
          'Mahaddirbagh',
          'Manpur',
          'Nayakandi',
          'Sakhipura',
          'Bhatabaria',
          'Muradpur',
          'Nischintapur',
          'Akania Nasirpur',
          'Akania',
          'Nairpur',
          'Basabari',
          'Dakshin Daulatpur',
          'Dari Hayatpur',
          'Dakshin Noagaon',
          'Dari Lakshmipur',
          'Dakshin Rajapur',
          'Dumuria',
          'Hayatpur',
          'Kalthuri',
          'Kutaia',
          'Lumonti',
          'Manoharpur',
          'Joarikhola',
          'Ragdail',
          'Sachar',
          'Gograbari',
          'Bazarikhola',
          'Nayakandi',
          'Rajarampur',
          'Kalakopa',
          'Maithar Dighirpar',
          'Chowdhury Dighirpar',
          'Hatirbandh',
          'Ramer Dighirpar',
          'Gupir Pukurpa',
          'Jamaddarer Pukurpar',
          'Sachar Bazar',
          'Chalakanda',
          'Atomopur',
          'Burburia',
          'Ramdebpur',
          'Darikandi',
          'Fatebapur',
          'Maligaon',
          'Bhagulia Para',
          'Gobarkhola',
          'Uttar Padu',
          'Ainpur',
          'Haolakandi',
          'Shankarpur',
          'Asharkota',
          'Uttar Nayakandi',
          'Hatpukuria',
          'Meghdair',
          'Safibad',
          'Aliara',
          'Jagir',
          'Kandi Roypur',
          'Nandanpur',
          'Kandirpar',
          'Bagmara',
          'Kadirkhil',
          'Tulpai',
          'Nangla',
          'Saidapur',
          'Pak Shrirampur',
          'Rahima Nagar',
          'Shachar',
        ],
      },
      {
        name: 'Matlab',
        postCode: '3642',
        areas: [
          'Paschim Baishpur',
          'Uttar Baishpur',
          'Baburpara',
          'Dagarpur',
          'Dhanarpar',
          'Pailpara',
          'Bhangerpar',
          'Dashpara',
          'Kaladi',
          'Matlab Bazar',
          'Char Nilakshm',
          'Char Mukundi',
          'Char Naya',
          'Char Pathalia',
          'Kadamtoli',
          'Nilakshmi',
          'Algi Mukundi',
          'Munsubdi',
          'Uttar Udamdi',
          'Subhangkardi',
          'Silmondi',
          'Dhakirgaon',
          'Nabakalas',
          'Uttar Dighaldi',
          'Durgaon',
          'Nalua',
          'Mobarakdi',
          'Madhya Dighaldi',
          'Dakshin Paschim Dighaldi',
          'Dakshin Purba Dighaldi',
          'Beloti',
          'Took Char Chandpur',
          'Garbhanga',
          'Lamchhari',
          'Narayanpur',
          'Padmapal',
          'Putia',
          'Mirama',
          'Khadergaon',
          'Badarpur',
          'Baroigaon',
          'Chapatali',
          'Chapatia',
          'Charat Bhanga',
          'Datikara',
          'Gabua',
          'Haridas Para',
          'Jogichapar',
          'Kalikapur',
          'Kasimpur',
          'Lekota',
          'Manigaon',
          'Natsal',
          'Patua Para',
          'Sarpar',
          'Gobindapur',
          'Aswinpur',
          'Char Silandi',
          'Kachiara',
          'Khidirpur',
          'Biswaspur',
          'Nandikhola',
          'Uttar Baragaon',
          'Dakshin Baragaon',
          'Tushpur',
          'Achalchhila',
          'Bahari',
          'Legakandi',
          'Dhuliaura',
          'Dingabhanga',
          'Naogaon',
          'Panchak Para',
          'Aswinpur',
          'Patan',
          'Char Nayergaon',
          'Harian',
          'Kaziara',
          'Panchgharia',
          'Khidirpur',
          'Shibpur',
          'Bakra',
          'Dakshin Gobindapur',
          'Dhaniatali',
          'Ghoradhari',
          'Gonsaipur',
          'Karbanda',
          'Kotrabanda',
          'Pingra',
          'Sreepur',
          'Mohanpur',
          'Kalipur',
        ],
      },
      {
        name: 'Matlab-Uttar',
        postCode: '3641',
        areas: [
          'Changar Char Bazar',
          'Baroani',
          'Jorkhali',
          'Nayakanda Sikir Char',
          'Purba Sikir Char',
          'Sugandhi',
          'Paschim Sikir Char',
          'Uttar Sikir Char',
          'Uttar Kalakanda',
          'Aduruiti',
          'Palalokdi',
          'Goipur',
          'Thakur Char',
          'Dewanjee Para',
          'Dulalkandi',
          'Ghaniar Par',
          'Malaikandi',
          'Otar Char',
          'Soiyalkandi',
          'Bara Maradan',
          'Bara Jhinaia',
          'Chhota Jhinaia',
          'Taltoli',
          'Bagbari',
          'Dangur Viti',
          'Chhota Maradan',
          'Dayakhola',
          'Panchgachhia',
          'Ruhitar Par',
          'Zibgaon',
          'Balai Kharkandi',
          'Chand Kabilarkandi',
          'Dalikandi',
          'Keshirkandi',
          'Nathu Tahbilderkandi',
          'Niz Changar Char',
          'Chand Sarderkandi',
          'Mulluk Majhikandi',
          'Manikerkandi',
          'Mollahkandi',
          'Kazir Kamta',
        ],
      },
      {
        name: 'Shahrasti',
        postCode: '3624',
        areas: [
          'Badia',
          'Suapara',
          'Kalia Para',
          'Suapara',
          'Ghughusal',
          'Naora',
          'Shahapur',
          'Upalata',
          'Kazirkap',
          'Kazir Nagar',
          'Sree Pur',
          'Nij Mehar',
          'Bajatola',
          'Barai Pukharia',
          'Bettala',
          'Chak Basta',
          'Chandal',
          'Chitasi',
          'Ghari Mondal',
          'Kadra',
          'Kalacho',
          'Karna Para',
          'Kasba',
          'Khitarpar',
          'Selenga',
          'Rajarampur',
          'Aynatali',
          'Bagair',
          'Daiara',
          'Haria',
          'Laliara',
          'Nunia',
          'Laliara',
          'Noapara',
          'Seti Narayanpur',
          'Shimail',
          'Raghabpur',
          'Baniacho',
          'Barulia',
          'Bhathikhola',
          'Bhingra',
          'Debipur',
          'Durgapur',
          'Kadamtali',
          'Kakairtala',
          'Khaneshwar',
          'Nayanpur',
          'Naynagar',
          'Paticho',
          'Sekhkuni',
          'Sengaon',
          'Sonapur',
          'Tarapur',
          'Bhol Dighi',
          'Bishara',
          'Darunkara',
          'Debkara',
          'Krishnapur',
          'Malara',
          'Noagaon',
          'Padua',
          'Anandapur',
          'Atakara',
          'Bogra',
          'Chandipur',
          'Dadia Para',
          'Dahasree',
          'Gangarampur',
          'Hatpar',
          'Jadabpur',
          'Khampar',
          'Khatra',
          'Rajapur',
          'Rashidpur',
          'Bijoypur',
          'Ghughurchap',
          'Ahmednagar',
          'Khila',
          'Krishnapur',
          'Kurkamta',
          'Nahara',
          'Fatik Khira',
          'Prasannapur',
          'Raghurampur',
          'Shibpur',
          'Pashchim Kherihar Al',
          'Chotoshi',
          'Islamia Madrasha',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Chittagong',
    zones: [
      {
        name: 'Panchlaish',
        postCode: '4000',
        areas: [
          'Nasirabad R/A',
          'Panchlaish R/A',
          'Sugondha R/A',
          'Muradpur',
          'Mirzapol R/A',
          'Katalgonj R/A',
          'Mimi R/A',
          'Sanondha R/A',
          'Suborna R/A',
          'Golpahar',
          'Amirbag R/A',
          'Probortak',
          'Chittagong medical',
          'M.m ali road',
          'Mirzapol',
          'Ctg medical hostel',
          'Bevirlly hill',
          'OR nizam r/a',
          'Bata goli',
          'Mosjid goli R/A',
          'Lords in Hotel',
          'CNB colony',
          'Apon nibash',
          'Asian women collage',
          'CTG shopping Complex',
          'Sholoshahar',
          'Mehedibag',
        ],
      },
      {
        name: 'Khulshi',
        postCode: '4225',
        areas: [
          'Women college Mor',
          'Bhuiyan goli',
          'Nasirabad Properties',
          'Zakir hossain road',
          'Al fala goli',
          'West khulshi',
          'Jhautola',
          'Wirless more',
          'Lalkhan bazar',
          'Asian hiway R/A',
          'Goribullah shah',
          'Sunmari road',
          'Baggona more',
          'Wasa',
          'Dampara R/A',
          'MR siddik gate',
          'Kusum bag R/A',
          'Hilavel Road',
          'Dampara police line',
          'Bagmoniram',
          'Chattershori road',
          'Bettary goli',
          'Radison blue',
          'CRB',
          'Navy colony',
          'Love line',
          'S s khaled road',
          'Askardigir par',
          'CPDL R/A',
          'Jamalkhan more',
          'Cheragi pahar',
          'Foyes lake',
          'Akbarsha R/A',
          'USTC',
          'Eye hospital',
          'Green housing',
          'Seven Market',
          'Kacha Bazar-Khulshi',
          'CDA-1',
          'Bishacoly',
          'Asram Gate',
          'New Mansurabad',
          'Prasantha Abasik',
        ],
      },
      {
        name: 'ChawkBazar',
        postCode: '4203',
        areas: [
          'Teliportti',
          'Kachabazar more',
          'Fultola',
          'Gasiya para',
          'Bormia mosjid',
          'aturar dokan more',
          'nur begum mosjid',
          'Asian city R/A',
          'Rasulbag R/A',
          'Sobuj bag R/A',
          'DC road 1,2,3,4,',
          'Mushomi abashik',
          'chandanpura',
          'Dewanbazar 1,2,3',
          'didar market',
          'deowanzee pokurpar',
          'Masuya jorna',
          'CTG college East gate',
          'gony bekari',
          'nirbana R/A',
          'devpahar',
          'CTG college',
          'Moshin college',
          'parcivil hill 1,2,3',
          'KSD goli',
          'bora pukur par',
          'Afgan mosjid',
          'bogarbill',
          'Shanti nagar',
          'Syeed shah road',
          'K B aman ali road',
          'joynagar 1,2,3',
          'Gulzar More',
          'Colleage Road',
          'Gony Golony',
          'Ctg. Medical',
        ],
      },
      {
        name: 'Andarkilla',
        postCode: '4100',
        areas: [
          'midtowan R/A',
          'anderkilla',
          'Ghat Farhadbeg',
          'masterpol',
          'korbanigong',
          'boluardighir par',
          'Ashadgonj',
          'khatungonj',
        ],
      },
      {
        name: 'Enayet bazar',
        postCode: '4000',
        areas: [
          'Enayet bazar',
          'nandankanon R/A',
          'DC hill',
          'cinema place',
          'court building',
        ],
      },
      {
        name: 'Chandgaon',
        postCode: '4100',
        areas: [
          'Solokbahar',
          'pillkhana mosjid',
          'Mohammadpur',
          'Badurtala',
          'khotiberhat',
          'kapashgola',
          'new chandaon R/A',
          'sunniya Madrasha',
          'faridarpara',
          'chandgaon R/A',
          'puraton chandgaon thana',
          'moulovi pokurpar',
          'Kaptai',
          'Kaptai rastar matha',
          'kalurgath',
          'CNB Chandgaon',
          'Bepari Para',
          'Bahir Signal',
          'Gas Colony',
          'Osmania pool',
          'C.M.B',
          'Arkan Road',
          'Bahir Signal',
          'Moulavi Bazar-Chandgaon',
          'Kamal Bazar',
          'Kazir Hat',
          'Ispahani Depot',
          'Khejurtala',
          'Bus Terminal-Chandgaon',
        ],
      },
      {
        name: 'Bakoliya thana',
        postCode: '4212',
        areas: [
          'Boddharhat more',
          'Khaja road',
          '1 kilometer',
          'rahattarpul',
          'kalamiyabazar',
          'miakhan nagar',
          'raja khali',
          'new bridge',
          'kolpolok R/A',
          'Chaktai',
          'New Chaktai',
        ],
      },
      {
        name: 'Bayazid',
        postCode: '4212',
        areas: [
          'cosmopoliton',
          'baby super more',
          'adorsho para',
          'hasmikal',
          'maizpara',
          'rahman nagar',
          'hill view',
          'hamzarbag',
          'technical',
          'rupushi R/A',
          'mojaffar nagar.',
          'saymolchaya R/A',
          'chandra nagar',
          'baizeed nagar',
          'green velly',
          'textile',
          'kunjochaya',
          'mohammad nagar',
          'shanti nagar',
          'ananda nagar',
          'amin jutmile',
          'aturardipu',
          'roufabad',
          'oxygen',
          'koylar ghor',
          'paharika R/A',
          'Dosh',
          'Cantonment',
          'baizeed',
          'Ser shah',
          'Ali nagar',
          'Nasirabad I/A',
          'Arefin Nagar',
          'Bizid Bostami Rd',
          'Baizid Bostami Mazar',
          'Shersha Colony',
          'Barma colony',
          'Nabi nagar',
          'Bayezid Bostami',
          'Wazedia',
          'Quaish',
          'Jalalabad-Bayazid',
          'Baluchara-Bayazid',
          'Sohan Society',
          'Gulbag R/A',
          'Jalabad H/S',
          'Chittagong Cantonment',
        ],
      },
      {
        name: 'Hathazari',
        postCode: '4330',
        areas: [
          'notunpara',
          'amanbazar',
          'BRTA',
          'Bordigir par',
          'chowdhoury hat',
          'fatehabad',
          'nondirhat',
          'CU 1 no gate',
          '0 point',
          'CU 2 no gate',
          '11 mile',
          'hathazari bus stand',
          'Chitt.University',
          'Gorduara',
          'Mirzapur-Hathazari',
          'Nuralibari',
          'Yunus Nagar',
          'Madrasa',
          'Quaish college',
        ],
      },
      {
        name: 'Nazirhat',
        postCode: '4353',
        areas: [
          'katirhat',
          'nurali miyar hat',
          'nazirhat bazar',
          'nanupur bazar',
          'bibirhat',
        ],
      },
      {
        name: 'Raozan',
        postCode: '4349',
        areas: [
          'sultan pur',
          'munshighata',
          'jolil nagar',
          'Kuaish',
          'Najumiar hat',
          'noapara',
          'Raozan pahartoli',
          'CUET',
          'Ishakhali',
          'Roawzar hat',
          'Ranguniya',
          'Lichu bagan',
          'Beenajuri',
          'Kundeshwari',
          'Gahira',
          'jagannath Hat',
          'Fatepur',
          'Guzra Noapara',
          'Dewanpur',
          'Mohamuni',
          'Ashraf Sha Mazar Gate',
          'B.I.T Post Office',
        ],
      },
      {
        name: 'Patia',
        postCode: '4370',
        areas: [
          'Manosha Badamtal',
          'Anjur hat',
          'Polli biddut',
          'Monsef Bazar',
          'Postoffice',
          'Potiya Hospital Gate',
          'Thanar Mor',
          'Potiya Bus Stand',
          'Budhpara',
        ],
      },
      {
        name: 'Satkaniya',
        postCode: '4386',
        areas: [
          'BGB trust',
          'College gate',
          'Duhazari',
          'Keranirhat',
          'Satkaniya',
          'lohaghora',
        ],
      },
      {
        name: 'Anowara',
        postCode: '4376',
        areas: [
          'Crossing',
          'Fokirnerhat',
          'Chaturir chowmahany',
          'Kafco Housing Colony',
          'Center',
          'Cufl Housing colony',
          'Battali-Anowara',
          'Chaturi Chowmuhani',
        ],
      },
      {
        name: 'Bashkhali',
        postCode: '4376',
        areas: [
          'Banigram',
          'Shadanpur',
          'gunaguri',
          'Kalipur',
          'Meyar bazar',
          'Joldi pourashaba',
          'Shilkop',
          'Chumbol',
          'Gunagari',
          'Banigram',
        ],
      },
      {
        name: 'Boalkhali',
        postCode: '4376',
        areas: [
          'C A office',
          'Gumdondi Fultola',
          'millitary pol',
          'ky fecetory Badamtol',
          'Kanongo para',
          'kodurkhil',
          'chowdhoury hat',
          'Koruldanga',
          'Iqbal Park',
          'Boalkhali -Boalkhali',
          'Charandwip',
        ],
      },
      {
        name: 'Double mooring',
        postCode: '4217',
        areas: [
          'Badamtoli',
          'Commerce College',
          'CGS Colony',
          'Bank colony',
          'Bohutola colony',
          'West motherbari',
          'Agrabaad Asscess Road',
          'Barek building',
          'Mogoltuly',
          'Pathantuly',
          'Deowanhat',
          'Munsurabad',
          'Misterypara',
          'Doniwalapara',
          'Kodomtoli',
          'Chowmohuni',
          'Hazipara',
          'Mohuripara',
          'Supariwalapara',
          'Mollapara',
          '3no fokirhat',
          'Gosaildanga',
          'CDA 1 no road',
          'CDA 2 no road',
          'CDA 3 no road',
          'CDA 4',
          'CDA 5',
          'CDA 6',
          'CDA 7',
          'CDA 8',
          'CDA 9',
          'CDA 10',
          'CDA 11',
          'CDA 12',
          'CDA 13',
          'CDA 14',
          'CDA 15',
          'CDA 16',
          'CDA 17',
          'CDA 18',
          'CDA 19',
          'CDA 20',
          'CDA 21',
          'CDA 22',
          'CDA 23',
          'CDA 24',
          'CDA 25',
          'CDA 26',
          'CDA 27',
          'CDA 28',
          'CDA 29',
          'CDA 30',
          'Cotopol',
          'Islamiya Breakfild',
          'Shantibag pollice line',
          'Shantibag R/A',
          'Banani R/A',
          'Rongipara',
          'Shaymoli R/A',
          'Katabotgac more',
          'Damua Pokurpar',
          'Debarpar',
          'Sheik mojib road',
          'Strand Road',
          'Rashid building',
          'Barua Para',
          'Sk Mujib Road',
          'Majirghat',
          'Askarabad',
        ],
      },
      {
        name: 'EPZ',
        postCode: '4223',
        areas: [
          'Custom',
          'Hindupara',
          'Bandar port',
          'Soltgola crossing',
          'BANDARTILA',
          'KEPZ',
          'CEPZ',
          'Kolshi digirpar',
          'Navy gate',
          'Shahin gate',
          'Zahirul gate',
          'Ishakhan gate',
          'Nabik colony',
          'Barrister collage',
          'Bijoynagar',
          'Potenga',
          'Airport',
          'Cement crossing',
          'Kathghar',
          'Freeport',
          'Sea beach',
          'South Middel Halishahar',
          'Charpara',
          'Grad room',
          'Alisha para taltola',
          'Steel mill',
          '2No mailer matha',
          'Dry dalk colony',
          'MPP gate',
          'Noyarhat',
          'Toktarpol',
          'Kazirgoli',
          'Chattogram Customs Acca',
          'Chattogram Bandar',
          'Patenga',
          'Full chari',
          'Bazar Pukur',
          'Najir Para',
          'VIP Road',
          'Chari Halda',
          'Dhum Para',
          'Purba Katgar',
          'Hossain para',
          'Chittagong Airport',
          '15 no. Ghat',
          'islamia Hat',
          'TSP complex',
          'CTG. Dridog',
          'BF shamin Colleage gate',
          'Miaj Para',
          'Colonel hat',
          'Chittagong Customs Accademy',
          'Port colony',
          'Middle-Patenga',
          'Sailers Coloni',
          'Sailers colony-1,2',
          'Export-Processing',
          'South Halishahar',
        ],
      },
      {
        name: 'Rangunia',
        postCode: '4360',
        areas: ['Rangunia', 'Dhamair', 'Lichu bagan'],
      },
      {
        name: 'East Joara',
        postCode: '4380',
        areas: ['Gachbaria', 'Dohazari'],
      },
      { name: 'Karnophuli', postCode: '4206', areas: [] },
      {
        name: 'Halishahar',
        postCode: '4216',
        areas: [
          'Block A',
          'Block I',
          'Block J',
          'Block K',
          'Block L',
          'Block G',
          'Block H',
          'Anandabazar',
          'Boropol',
          'Newmoring R/A',
          'Abbaspara',
          'Chowdhoury para',
          'Chowchala',
          'Port colony',
          'Nimtol',
          'Noyabazar',
          'Bowbazar',
          'Pahartoli abul kheyer',
          'Chuna factory',
          'Foillatali bazar',
          'Shobujbag R/A',
          'Basundhara R/A',
          'Shaymoli R/A',
          'Halishahar Cantonment',
          'North Halishahar',
          'BDR Hall',
          'Alankar',
          'Bitak',
          'Sagorika',
          'CDA marken',
          'Pahartoli Bazar',
          'North kattuli',
          'CDA 7 market',
          'Eidhgah',
          'Saraipara',
          'Sagorika',
          'Bitak',
          'Kolka CNG',
          'CDA market',
          'Pran horidas road',
          'Borapukur par',
          'DT Road',
          'Alankar more',
        ],
      },
      {
        name: 'Akbarsha',
        postCode: '4225',
        areas: [
          'A k khan',
          'Firozshah',
          'Ispahani Rail gate',
          'Bissho Colony',
          'Paharika R/A',
          'Fakirhat CTG',
          'City Gate',
          'CDA 1,2,3',
          'Faujdarhat',
          'Bhatiyari',
          'Kumira',
          'Shitakund',
          'Kattuli',
          'South Kattali',
          'North Katuli',
          'Halishshar',
          'North-Halishahar',
        ],
      },
      {
        name: 'kotwali',
        postCode: '4100',
        areas: [
          'Firinghee Bazar',
          'bridge ghata',
          'teri bazar',
          'new market',
          'kotwali',
          'laldigi',
          'alkaran',
          'kobi nazrul road',
          'Kazir deweri',
          'south nalapara',
          'north nalapara',
          'new market',
          'Kadam Tali',
        ],
      },
      {
        name: 'Kotowali Chittagong',
        postCode: '4000',
        areas: [
          'Rajakhali',
          'Natun bridge',
          'AsadGonj',
          'Askerdigir Par',
          'Love Lane',
          'Jamal Khan',
          'Momin Road',
        ],
      },
      { name: 'New Market CTG', postCode: '4100', areas: ['Reaz uddin bazar'] },
      {
        name: 'Sadargath',
        postCode: '4100',
        areas: [
          'Mazirgath',
          'stand road',
          'east motherbari',
          'kodomtoli',
          'ice factory road',
        ],
      },
      {
        name: 'Satkania',
        postCode: '4386',
        areas: [
          'Amtoli Para',
          'Satkania, Keranihat',
          'Molobhi dokan',
          'Satkania Upozila',
          'Juma Mosjid',
          'Dulo Bridge',
          'Bangla Bazar',
          'Satkania Govt High School',
          'Hospital',
          'fultala',
          'Keuchia',
          'bangla club',
          'Jut pokoria',
          'Nanu Pukur par',
          'Fakir madrasha',
          'Dieo Dighi',
          'Dhemsha',
        ],
      },
      {
        name: 'Lohagara',
        postCode: '4396',
        areas: [
          'Paduar bazar',
          'Ma Moni hospital',
          'Amirabad',
          'Adhunagar',
          'Chairman Hat',
          'Police Box',
          'Alur ghat',
          'Lohagara Upozilla',
        ],
      },
      { name: 'Keranihat', postCode: '4386', areas: [] },
      {
        name: 'Zorarganj',
        postCode: '4324',
        areas: [
          'Aburhat Bazar',
          'Borer Bazar',
          'Patakot',
          'Bamonsundor darogarhat',
          'Sofiulla para',
          'Pathan Bari',
          'Babu Kha Digi',
          'Aburhat Road',
          'Tajpur',
          'Old DHK CTG Road',
          'Nadanpur',
          'Gobindapur',
          'Arshi Nagar Future park',
          'Osmanpur',
          'Bangla Bazar',
          'Gonoker Hat',
          'Charsarat hindu para',
          'Charsarat Bangla Bazar',
          'Zero Point Zorarganj',
          'Forest office Zorarganj',
          'Durgapur Zorarganj',
          'Mohajon Hat',
          'Guccho Gram',
          'Mehedi Nagar',
          'Santir hat',
          'Osmanpur',
          'Azampur Bazar',
          'Moulovi Bazar',
          'Shantu Road',
          'Naherpur Road',
          'Azizulla Sadak Road',
          'Shareg Bari Road',
          'Shantir Hat',
          'Noah Para',
          'North Dhoom',
          'Gonokchora',
          'Chataria',
          'Karerhat',
          'Shahpur',
          'Baraiyar hat College Road',
          'Bangli Road',
          'Mehedi Nagar Road',
          'Hinguli Road',
          'Baraiyarhat',
          'Mehedi Nagar',
          'Mirshorai Economic Zone',
        ],
      },
      {
        name: 'Fatikchhari',
        postCode: '4324',
        areas: [
          'Narayonhat',
          'Nazim Medical Hall',
          'Datmara Bazar',
          'Master Para',
          'Bara Betua Notun Bazar',
          'Chomuhuni Bazar',
          'Shantirhat',
          'Heyako',
          'Nanupur',
          'Bhandar Sharif',
          'Subail',
          'Najirhat',
        ],
      },
      {
        name: 'Mirsharai',
        postCode: '4321',
        areas: [
          'Mirshorai College Road',
          'Mirshorai Rail Station',
          'Thakur Dighi',
          'Mithachara',
          'Mithanala',
          'Baria Khali',
          'Mohamaya Lake',
          'Pattar Pukur Bazar',
          'South Ambia',
          'Baman Sundar Road',
          'Pathan Para',
          'Nazir Para Jame mosjid',
          'South Rahamatabad',
          'Eshak Driver Hat',
          'Matbarhat Bazar',
          'Harihoropur Shorok',
          'Bortakia Bazar',
          'Takia para road',
          'Dos Mohammad Road',
          'Mirza para Road',
          'Khaiyachara School Road',
          'Poolmogra Road',
          'Ahmmad Shobhan Road',
          'Shonaichhori Jhorna',
          'Kari choron Road',
          'Bhuiya Para Road',
          'Nath Para Road',
          'Khoiyachora Waterfalls Road',
          'Bhuiya Para Jame Moshjid',
          'Shadon Ali Munshi Road',
          'Thakur Hat Bazar',
          'Mozumdar Hat',
          'Hashim Nagar',
          'Abutorab Bazar',
          'Master Para',
          'Mayani',
          'Kheyerhat',
          'Nizampur Bazar',
          'Damdama Baza',
          'Shaherkhali',
          'Borer Bazar',
          'Noyduaria',
          'Hadi Fakir Hat',
          'Haidkandi',
          'Wahedpur Eidgha',
          'Shonaichori',
          'Satbaria',
          'Mohalanka',
          'Kamaldaha',
          'Choto Darogarhat',
          'Dharmopur',
          'Mohanagar',
          'Sohosrodhara Road',
          'Terial CNG Station',
          'Baraiyadala Forest office',
          'Choto Darogarhat',
          'Mirer dighi',
          'Chakladar Para',
          'Choto Kali tola Mondir',
          'Jafar Nagar',
          'Bariadala',
          'Saidpur',
          'Muradpur',
          'Nizampur',
          'Boro Darogarhat',
          'Bortakia',
          'Abutorab',
          'Darrogahat',
          'Bharawazhat',
          'Joarganj',
          'Azampur-Mirsharai',
          'Korerhat',
          'Mohazanhat',
        ],
      },
      {
        name: 'Sitakundo',
        postCode: '4316',
        areas: [
          'Tample Road',
          'Suklalhat',
          'Sobahanbag R/A',
          'Siraj Buiyan Road',
          'Sandwip ferighat Road',
          'Rahamat Nagar',
          'Olinagar Foloiyar para',
          'New para',
          'Miazy para',
          'Mandaritola Road',
          'Mahmudabad Jame mosjid',
          'Kashiyar para',
          'Habib Road',
          'Gulia Khali',
          'Doyazipara Road',
          'Botanical Garden',
          'Bolanandagiri Road',
          'Barai Para Road',
          'Barabkunda',
          'Amirabad Road',
          'Amir ali Buiyan Road',
          'Akilpur sea beach',
          'Banshbaria',
          'Sultana Mondir',
          'Gul Ahmed Bazar',
          'Banshbaria sea beach',
          'Hazi Para',
          'mohori Para Jame Mosjid',
          'Ashkar para',
          'Nadalia village road',
          'Kolta bazar',
          'Makhon lal road',
          'Jamader para',
          'Katgor Govt. School',
          'Anawara jute mill Road',
          'Moulana ispat lane',
          'Maza Mazi Para',
          'Choto Kumira',
          'Baroidhala',
          'Bhatiari',
          'Fouzdarhat',
          'Jafrabad',
        ],
      },
      {
        name: 'Sandwip',
        postCode: '4301',
        areas: [
          'Complex',
          'Shiberhat',
          'Munshir Hat',
          'Akbar Hat',
          'Nazirhat',
          'Badamtali',
          'Ghat Nazirhat',
          'Guptochora',
          'MogDhara',
          'Kalapania',
          'Gachua',
          'Horishpur',
          'Pouroshova Bazar',
          'Sener Hut',
          'Kenjatoli Bazar',
          'Rohomotpur',
        ],
      },
      {
        name: 'Pahartali',
        postCode: '4000',
        areas: [
          'Pahartali',
          'Dakkshin Pahartoli',
          'Ambagan',
          'Sakarika',
          'Basic-Pahartali',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Cumilla',
    zones: [
      {
        name: 'Cumilla Sadar',
        postCode: '3500',
        areas: [
          'Satora',
          'Comilla Railway Station',
          'Station Road',
          'Kotbari Road',
          'Didar Model High School',
          'Doulotpur',
          'Comilla Victoria Govt.College',
          'Doulotpur Central Jame Masjid',
          'Comilla Shikkha Board',
          'Takurpara Jame Masjid',
          'Comilla residential School & College',
          'Oppo Service Center',
          'Demo',
          'BSCIC Industrial Estate',
          'Takurpara Road.',
          'Bagan Bari Road',
          'Salauddin',
          'Asoktola',
          'Ranir Bazar',
          'Comilla Tower',
          'Chaya Bitan',
          'New Chowdurypara',
          "Comillla Govt.Women's College",
          'Civil Surgon Office',
          'Kamal Uddin Chowduri Road',
          'North Chortha',
          'South Chortha',
          'Ibn Taimiya School & College',
          'Dr. Akther Hamid Khan Road',
          'Second Muradpur',
          'Bhuiyan Bari',
          'Pizza Dot.',
          'Lau Dighi',
          'Sadar Hospital Road',
          'Nurupur',
          'Nanua Dighi',
          'Shah Gazipuri Road',
          'Munshi Altaf Ali sorok',
          'Abdur Rahman Khan Road',
          'Chortha Jame masjid',
          'Thira Pukurpar',
          'Sourov Boading Store',
          'Koborosthan Jamne Masjid',
          'Novab bari',
          'Novab Bari Chowmuhoni',
          'Hucchamia School',
          'Bozropur',
          'Daroga Bari',
          'Rajgonj',
          'ChowlkBazar',
          'Comilla Bypass Road',
          'Walton Plaza',
          'Standard Bank',
          'Dutch Bangla Bank',
          'Pubali Bank',
          'First Security Islami Bank',
          'Kasharipotty',
          'Goalpotti',
          'Gangchor',
          'Gorjonkhola',
          'Rishipotti',
          'Amir Digi',
          'Chowlk Bazar Goru Bazar',
          'Kormosongsthan Bank',
          'Islamia Alia Kamil Madrasha',
          'United Commercial Bank',
          'Digamboritola',
          'Moulovipara Road',
          'Munshi Altaf ali Road',
          'Katabil',
          'Nurpur',
          'Sujanagar',
          'Songraish',
          'Shuvopur',
          'Moynamoti Medical College & Hospital',
          'Kuchaitoli',
          'Baropara',
          'Jaam Dighi',
          'Nursing Institute',
          'Housing Area',
          'Comilla Medical College Hospital',
          'Dulipara',
          'Rajapara',
          'Nurjahan Eco Park',
          'Batpara',
          'Comilla EPZ',
          'Chatipotti',
          'Halima Nagar',
          'Kandirpar Town Hall',
          'Novab Foyjunnesa Govt School',
          'Rajgonj Bazar',
          'Al-Arafa Islami Bank',
          'Raj Bari Pukur',
          'Matrivandar',
          'Deshwalipotti',
          'Bozropur',
          'IFIC Bank Limited',
          'Sonali Bank Ltd.',
          'Uzirdighi',
          'Comilla Zella School',
          'Comilla Post Office',
          'Twon Hall Super market',
          'CD Path Hospital Road',
          'Sattar khan Complex',
          'Khondokar Complex',
          'Pubali Chottor',
          'New Market',
          'Export Import Bank',
          'Comilla City Market',
          'Kazi Zahirul Kaiyum Road',
          'Munsef Bari',
          'Ranir Digirpar',
          'Monohorpur',
          'Ak.Fazlul Hoque Road',
          'Rajonj Dud Bazar',
          'Munshi Kabri Uddin Road',
          'Bangla Restora',
          'Stadium',
          'Comilla Central Eidgah',
          'Badurtoal',
          'Nazrul Avenue',
          'Darogabir',
          'Thana Road',
          'Mogoltoli',
          'Kotwali Thana',
          'Molla Pukurpar',
          'Comilla Colectoret School & College',
          'premier Bank',
          'Shah Suja Mosjid',
          'Zella Shilpokola Academy',
          'BTCL Comilla',
          'Police Supper Office',
          'Circute House',
          'Press Club',
          'Nagar Shishu Uddan',
          'Jhawtola',
          'Racecourcce',
          'Comilla Zoo & Botanical garden',
          'Court Road',
          'Comilla DC office',
          'Kaptan Bazar',
          'Nazir Road',
          'Bishnupur Road',
          'Kaliyajuri',
          'Bou Bazar',
          'Islampur Road',
          'District Election Office',
          'Judge Court',
          'Halima telicome',
          'Mogbari Chowmuhoni',
          'Munsef Quarter',
          'Chotora',
          'Central jail',
          'Bishnupur Panir tank',
          'Bagichagaon',
          'Comilla Ideal School',
          'Primary techaers training institute',
          'Moulovipara Eidgah',
          'Grameenphone Center',
          'Police Line',
          'Mafiz Uddin Road',
          'Badsha Miar Bazar',
          'Shahid Samsulhoque Sarak',
          'Comilla Markaz Mosjid',
          'Nur Masjid Road',
          'Nisha Tower',
          'Yakub Plaza',
          'Kather Poll Road',
          'Chotora Moddhopara',
          'Kaliajuri Mazar',
          'Puraton Chowdurypara',
          'Jangalia',
          'Poduar Bazar',
          'Lalmai',
          'Bagmara',
          'Horischor',
          'Jungsion Market',
          'Railstation',
          'Bangladesh Servey Institute',
          'Shikkha Board Model College',
          'Doulotpur',
          'Rab office',
          'BGB Btelian Head Quarter',
          'Didar market',
          'Kotbari Road',
          'Kotbari Bisso Road',
          'Halima Nagar Post Office',
          'Changini Bazar',
          'Comilla Passport Office',
          'Shasongacha',
          'Ariora',
          'Suaganj',
          'Paduar Bazar',
          'Dharmopur',
          'Tomsom Bridge',
          'Faujdari',
          'Murapara',
          'Chandpur',
          'Majhigacha',
          'Chawk Baza',
          'Kapuria potti',
          'Bhooter Goli',
          'Rammala',
          'Halua Para',
          'Komolpur',
          'Ashrafpur',
          'Yasin Market',
          'Ram Ghatla/Ramghat',
          'Tower Hospital',
          'Bolorampur',
          'Noapara-Cumilla Sadar',
          'Krishnanagar',
          'Durgapur-Cumilla Sadar',
          'Araiora',
          'Palpara',
          'Shimaikhara',
          'Rajbari Compound',
          'Khetasar',
          'Bamisha',
          'Chowara',
          'Bijoypur',
          'Comilla Cantoment',
          'Boro Alampur',
          'Jhagurjhuli',
          'Bordol',
          'Court Bari Bisso Road',
          'South Changini',
          'Rampur-Cumilla Sadar',
          'Gondhomoti',
          'Cumilla University',
          'Kalir Bazar',
          'Dhonua Khola',
          'MP Gate',
          'Polytechnic',
          'Suaganj',
          'Chowara',
          'Balkutupa',
          'Bibir Bazar',
          'Cantonment TSO',
        ],
      },
      {
        name: 'Laksam',
        postCode: '3572',
        areas: [
          'Dutiapur Moddhopara',
          'Digalgaong Bazar',
          'Moulovo Bazar',
          'Mohidpur Bazar',
          'Daulotgonj Railway Station',
          'Aliswar',
          'Horichor',
          'Mohonpur',
          'Muradorga',
          'Haji Saheb',
          'Dorga',
          'Nawab Faizunnesa Girls College',
          'Paikpara',
          'Rajghat',
          'Bipulasar',
        ],
      },
      {
        name: 'Nangolkot',
        postCode: '3572',
        areas: ['Dhalua', 'Chhariabazar', 'Gunabati'],
      },
      { name: 'Muddafarganj', postCode: '3500', areas: [] },
      {
        name: 'Mia-Bazar',
        postCode: '3500',
        areas: ['Suagazi Main Bazar- 17 Km.'],
      },
      { name: 'Kotbari', postCode: '3500', areas: [] },
      { name: 'Devidwar', postCode: '3500', areas: ['Barashalghar'] },
      {
        name: 'B.Para',
        postCode: '3500',
        areas: [
          'Shahebabad Bazar',
          'Mirpur',
          'Shidlai',
          'Chandla',
          'Rasulpur (Brahmman Para)',
          'Yousufpur',
          'Madobpur',
          'Sabujpara',
        ],
      },
      { name: 'Monohorgonj', postCode: '3500', areas: ['Bipulashar', 'Khila'] },
      {
        name: 'Sadar Dakkhin',
        postCode: '3500',
        areas: [
          'Nurjahan Hotel',
          'Bijoypur Bazar',
          'Lalmai Govt College',
          'Bangladesh Vetar',
          'Sadar Dakkhin Thana',
          'Central Medical College',
          'Comilla Pallibiddut Samiti',
          'ART Narsing College',
        ],
      },
      {
        name: 'Barura',
        postCode: '3560',
        areas: [
          'Hajaripara',
          'Amratoli Bazar',
          'Jhalam',
          'Arjuntola',
          'Shilmuri',
          'Dhaluya',
          'Kashtola',
          'Borura Main City- 24 Km.',
        ],
      },
      { name: 'Lalmai', postCode: '3500', areas: [] },
      {
        name: 'Chauddagram',
        postCode: '3572',
        areas: [
          'Abdul Ali Filling Station',
          'Mostafapur',
          'Hotel Bhai Bhai & Restaurant',
          'Comilla Sadar Dakkhin Upazilla Complex',
          'Lakshminagar',
          'Highway Inn Hotel',
          'RFL Comilla Factory',
          'Sandu Hotel & Resturent',
          'Komolapur',
          'Old garden Trunk Road',
          'Bhatpara',
          'Mathua',
          'Babucchi Bazar',
          'Amangonda',
          'Nobogram Bus Stand',
          'Jugirhaat',
          'Bimpur',
          'Pashakot',
          'Dolly Resort',
          'Chira',
          'Choayara',
          'Miabazar',
          'Balutopa',
          'Bibir Bazar',
          'Kashinagar',
          'Munshirhaat Main Bazar- 36 Km.',
          'Hotel Tajmahal',
          'Nalgor',
          'Kaduchi',
          'Gangra',
          'Chauddagram Bazar',
          'Chauddagram Bus Stand',
          'Wapda Road-Chauddagram',
          'Laksham Road',
          'Batisa',
        ],
      },
      {
        name: 'Cantonment',
        postCode: '3500',
        areas: [
          'Kotbari Police Station',
          'Comilla City College',
          'BARD',
          "Teacher's TrainingCollege",
          'Boro Doil',
          'Alekharchar',
          'Moynamoti',
          'Cumilla Cantonment',
          'Ispahani publice School & College',
          'Cumilla - CMH',
          'Forezpur',
          'Moinamoti Saber Bazar',
          'Kalirbazar',
          'Eastern Medical College',
          'Kabila',
          'Bangla Bazar',
          'Cadet College',
          'Comilla University',
        ],
      },
      {
        name: 'Burichong',
        postCode: '3520',
        areas: [
          'Nimsar',
          'Banasua',
          'Borosarbazar',
          'Sonar Bangla College',
          'Purnomoti',
        ],
      },
      {
        name: 'Chandina',
        postCode: '3511',
        areas: [
          'Korpai Bazar',
          'Gobindopur',
          'Asara',
          'Chandina Mohiila College',
          'Belashor',
          'Baguir',
          'Shalbon jadughor',
          'Boddhobihar',
          'Soiadpur',
          'Katherpol',
          'Madaiya',
          'Chandina Bazar',
          'Chandina Bus Stand',
          'Chandina Thana',
          'Chandina Upazilla',
          'Dhormoshagorpar',
          'Dollai Nawabpur',
          'Mohichail',
          'Barera',
          'R&R Pump',
        ],
      },
      {
        name: 'Muradnagar',
        postCode: '3543',
        areas: [
          'Gaituli',
          'Morichakandi',
          'Mirzanagar',
          'Pannarpol',
          'Noakhandi',
          'Slimpur',
          'Nabipur',
          'Sebanipur',
          'Rahimpur',
          'Tetultola',
          'Hirarkandi',
          'Dilalpur',
          'Ramcondropur',
          'Bakhrabad',
          'Paramtola',
          'Zatrapur',
          'Susunda',
        ],
      },
      {
        name: 'Comilla Companigonj',
        postCode: '3500',
        areas: ['Companigonj', 'Zaforgonj'],
      },
      {
        name: 'Eliotgonj',
        postCode: '3519',
        areas: ['Mobarokpur', 'Haripur', 'Eliotgonj Main City- 34 Km.'],
      },
      {
        name: 'Gouripur',
        postCode: '1517',
        areas: ['Jinglatoli', 'Angaura', 'Gouripur Main City- 45 Km.'],
      },
      {
        name: 'Daudkandi',
        postCode: '3500',
        areas: [
          'Asmania',
          'Raipur',
          'Zearkandi',
          'Sholakandi',
          'Shibpur',
          'Moutupi',
          'Korikandi',
          'Daudkandi Bus Stand',
          'Boldhar Khal',
          'Jhautola',
          'Juranpur',
          'ShahidNagar',
          'Pathantuli',
          'Pachgachia',
          'Goalmari',
          'Hasanpur',
          'Shapara',
          'Gouripur Bus Stand',
          'Barapara',
          'Amirabad',
          'Gouripur Bazar',
          'Batakandi',
          'Jiarkandi',
          'Noyakandi',
          'Machimpur',
        ],
      },
      { name: 'Gazipur Thana', postCode: '', areas: [] },
      {
        name: 'Titash',
        postCode: '3500',
        areas: [
          'Titash',
          'Kalakandi',
          'Karikandi',
          'Monaikandi',
          'Batakandi',
          'Hujurkandi',
        ],
      },
      {
        name: 'Homna',
        postCode: '3456',
        areas: [
          'Garmura Road',
          'Kalikapur',
          'Machimpur',
          'Kashipur - Homna',
          'Ragunathpur',
          'Shahapur - Homna',
          'Solakandi',
          'Matha Banga',
          'Juranpur - Homna',
          'Shundolpur',
          'Chander Char',
          'Dulalpur',
          'Bottoli',
          'Mirash',
          'Homna Bazar',
        ],
      },
      { name: 'Megna', postCode: '3560', areas: ['Meghna', 'Borokanda'] },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Cox’s Bazar',
    zones: [
      {
        name: "Cox's Bazar Sadar",
        postCode: '4700',
        areas: [
          'Taraboniar chora',
          'Pahartoli',
          'Shikdar Mohol',
          'Chowmohoni',
          'Borof kol',
          'South Romaliar chora',
          'Brick field road',
          "PTI School Cox's Bazar Sadar",
          'Hashemia Madarasha',
          'Shahittika polli',
          'SM Para',
          "City college Cox's Bazar Sadar",
          'Shurjer hashi road',
          'Haque tower 2',
          "Chowdury para Cox's Bazar Sadar",
          'sikdar para',
          'borua para',
          'BGB station',
          'Lar para',
          'Napanja para',
          "Captain cox's",
          'Power house',
          'upozilla porishod area',
          'Harvad college road',
          'Medical college area',
          'Govt. college station',
          'Haji para',
          'Petrol pump area',
          'link road station',
          'polli biddut office',
          'bisic area',
          'poly technical road',
          'RAB Camp',
          'Mohuri para',
          'Marine city',
          "Bangla bazar Cox's Bazar Sadar",
          "Police line Cox's Bazar Sadar",
          'Uttoron society',
          'Adorsho Gram',
          'Dolfin mor',
          'nirshorgo hotel',
          'Modern society',
          'Shugondha point A block',
          'Shugondha point B block',
          'Shugondha point D block',
          'Light House',
          'Laboni Point',
          'Cultural Center',
          'Radar unit',
          'Marine drive',
          "Cox's DA",
          'Ghul chattar',
          'joillar dokan',
          'International school',
          'ghonoshasto',
          'old sayman resort',
          'jawtola',
          'garir math',
          'holiday mor',
          "Cox's Bazar Sadar Bazar",
          'Bangladesh Air force',
          'kutubdia para',
          'nuniyarchora',
          'airport gate',
          '6no. fishari ghat',
          'kg School station',
          'Bodormukam Moshjid',
          'kuba twin tower',
          'zilla porishad',
          'bilkis market',
          'kosturi ghat',
          'hotel panuwa',
          "Sadar hospital Cox's Bazar Sadar",
          'apon tower',
          'andersion road',
          'peshkar para',
          'bazarghata',
          "boro bazar Cox's Bazar Sadar",
          'Mike service',
          'primary school raod',
          'purbo peshkar para',
          'Guldighir par',
          'baitush shorof road',
          'Zhilanja',
          'Eidga',
        ],
      },
      {
        name: 'Ramu',
        postCode: '4730',
        areas: [
          'kholghor',
          'Madrasha Ramu',
          'Station Ramu',
          'Point Ramu',
          'shil ghate',
          'Elephant ghat',
          'Upozilla Ramu',
          'point Ramu',
        ],
      },
      { name: 'Eidgah', postCode: '4702', areas: [] },
      { name: 'Ukhia', postCode: '4750', areas: ['Ukhiya point'] },
      {
        name: 'Teknaf',
        postCode: '4761',
        areas: ['Hnila', 'Huaikong BGB Check Post'],
      },
      {
        name: 'Chakaria',
        postCode: '',
        areas: [
          'sobujbag',
          'Fultola Chakaria',
          'Thana road Chakaria',
          'Upazila Chakaria',
          'Mogbazar Chakaria',
          'Hospital road Chakaria',
          'Tarminal Chakaria',
          'Busghat road',
          'Station para Chakaria',
          'Halkakara',
          'Army camp',
          'Thana center Chakaria',
          'Society para',
          'Koderkhom',
          'Telipara Chakaria',
          'Masterpara Chakaria',
          'Torosgat',
          'Ornesdipu',
          'Mowlobirsor',
          'Binamara',
          'Grameen bank',
          'Hindu para Chakaria',
          'Sowpnopori Club',
          'Somserpara',
          'Baitursharf Road',
          'Boramohori',
          'Hospital para Chakaria',
          'Bimanbandor para',
          'Biddanogor',
          'West Bathakhali',
          'Kamarpara Chakaria',
          'Khariagona',
          'Kondoker para',
          'Ukilpara Chakaria',
          'Molibirkom bazar',
          'Fashiakhali',
          'Malumghat Station',
          'Police fari Chakaria',
          'Safari Park',
          'Dulahazara Bazar',
          'Kutakhali Bazar',
          'Station Chakaria',
          'Porishod Chakaria',
          'Bazar Chakaria',
          'Dorbeskhata Bazar',
          '2No. Gate, Matarbari Power Plant',
          'Bodorkhali Bazar',
        ],
      },
      {
        name: 'Moheshkhali',
        postCode: '',
        areas: [
          'Kalarmarchara',
          'Howanok',
          'Timebazar',
          'Mohoraghata',
          'Borodill',
          'Notunbazar',
          'Gorokghat',
          'Notunbazar Moheshkhali',
          'Poranbazar',
          'Banglabazar',
          '2 no ghat',
        ],
      },
      { name: 'Pakua', postCode: '', areas: ['Pakua Bazar', 'Mognama'] },
      { name: 'Kutubdia', postCode: '', areas: ['dorogbazar'] },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Feni',
    zones: [
      {
        name: 'Chhagalnaiya',
        postCode: '3912',
        areas: [
          'Dakhin satara purba',
          'Ichhara chara',
          'Bansh para',
          'Uttar panua',
          'Paschim chhagalnaiya',
          'Dakhin jaspur',
          'Matua',
          'Purba chhagalnaiya',
          'Ward 01',
          'Ward 02',
          'Ward 03',
          'Ward 04',
          'Ward 05',
          'Ward 06',
          'Ward 07',
          'Ward 08',
          'Ward 09',
          'Auhduripara',
          'Alukdia',
          'Bokshipara',
          'Chayghura',
          'Durgapaur Singanaghar',
          'Daulotpur',
          'Gopal',
          'Langolmura',
          'Minakazikona',
          'Nizkunjhora',
          'Nowapara',
          'Santipara',
          'Shekjikona',
          'Elona Pather',
          'Joynagar',
          'Matiagada',
          'Debipur',
          'Satyanagar',
          'Jaspur',
          'Satara',
          'Bathania',
          'jangali',
          'Chandpur',
          'Haripur',
          'Gandharabpur',
          'kanthalia',
          'Madaiya Shilua',
          'Pathannagar',
          'Andarmanik',
          'Jhuar Gram',
          'Kashipur',
          'Lokkhipur',
          'Mokamia',
          'Nichinta',
          'Nasirgram',
          'Nij Panna',
          'Modhugram',
          'Khuma',
          'Balirchar',
          'Char Tulatoli',
          'Champaknagar',
          'Chayagharia',
          'Ballabpur',
          'Mandia',
          'Joychandpur',
          'Joypur',
          'Kaiara',
          'Tilakerchar',
          'Udaymahganarchar',
          'Maharajganj',
          'Daraga Hat',
          'Puabashimulia',
        ],
      },
      {
        name: 'Daganbhuiyan',
        postCode: '3922',
        areas: [
          'Uttar sreedharpur',
          'Aziz Fazilpur',
          'Uttar karimpur',
          'Betua',
          'Chhota Lalpur',
          'Daganbhuiya',
          'Uttar sreedharpur',
          'Jagatpur',
          'Ramanandapur',
          'Badarpur',
          'Krishnarampur',
          'Lakhanpur',
          'Chandpur',
          'Ganipur',
          'Udrajpur',
          'Abhirampur',
          'Amanullapur',
          'Udrajpur',
          'Ward 01- Daganbhuiyan',
          'Ward 02- Daganbhuiyan',
          'Ward 03- Daganbhuiyan',
          'Ward 04- Daganbhuiyan',
          'Ward 05- Daganbhuiyan',
          'Ward 06- Daganbhuiyan',
          'Ward 07- Daganbhuiyan',
          'Ward 08- Daganbhuiyan',
          'Ward 09- Daganbhuiyan',
          'Basedpur',
          'Malipur',
          'Shreedharpur',
          'Alipur',
          'Dharmapur',
          'Fatullalpur',
          'Hayatpur',
          'Fatehopur',
          'Lalpur',
          'Rashidpur',
          'Ahmmadpur',
          'Borai Gobindha',
          'Jailoskara',
          'Khusipur',
          'Omarpur',
          'Purba Hirapur',
          'Paschim Sonapur',
          'Purba Ramchandrapur',
          'Newazpur',
          'Enayetpur',
          'Nuruliapur',
          'Ashrappur',
          'Bargadia',
          'Mamarizpur',
          'Mahespur',
          'Hirapur',
          'Rahi Praza',
          'Salam Nagar',
          'Singerkhil',
          'Udaynagar',
          'Bhaitarpar',
          'Dharapur',
          'Hasan Ganipur',
          'Keronia',
          'kisorgonj',
          'Nayanpur',
          'Omrabad',
          'Pratappur',
          'Abdulnabi',
          'Batsheree',
          'Bhabanipur',
          'Elashkhan',
          'Jangalia',
          'Jadabpur',
          'Joy Narayanpur',
          'Kamar Pukuria',
          'Latifpur',
          'Mahadipur',
          'Nandigram',
          'Paschim Shibpur',
          'Sapua',
          'Samaspur',
          'Rajapur',
          'Gobindhapur',
          'Mohammdapur',
          'Ramnagar',
          'Sekandharpur',
          'Alatali',
          'Baruikandi',
          'Sundhorpur',
          'Chandrapur',
          'Kaushalla',
          'Dilpur',
          'Gatamkhali',
          'Khaikhali',
          'Korbanpur',
          'Nasaratpur',
          'Noadda',
          'Shariffpur',
          'Sadeqpur',
          'Sujatpur',
          'Masimpur',
          'Narayanpur',
          'Uttar ragunathpur',
          'Baraia',
          'Debrampur',
          'Karamullapur',
          'Yakubpur',
          'Dudmukha',
        ],
      },
      {
        name: 'Feni Sadar',
        postCode: '3901',
        areas: [
          'Jail Khana',
          'Feni College',
          'Shahadebpur',
          'Kadal Gazi',
          'Shahdebpur',
          'Birinchi',
          'Foleshwari',
          'Sultanpur',
          'Barahipur',
          'Shibpur',
          'Mizan Maidan',
          'Daktar Para',
          'Pathanbari',
          'South Pathan Bari',
          'Maddya Charipur',
          'Uttar Charipur',
          'Dakshin Charipur',
          'Panchgacia',
          'Purba Bijoysing',
          'Madhupur',
          'Rampur',
          'Feni Bazar',
          'Takiabari',
          'Saudagar Para',
          'Ward No-01',
          'Ward No-02',
          'Ward No-03',
          'Ward No-04',
          'Ward No-05',
          'Ward No-06',
          'Ward No-07',
          'Ward No-08',
          'Ward No-09',
          'Ward No-10',
          'Ward No-11',
          'Ward No-12',
          'Ward No-13',
          'Ward No-14',
          'Ward No-15',
          'Ward No-16',
          'Ward No-17',
          'Ward No-18',
          'Baisgharia',
          'Bara Dhalia',
          'Daulatpur',
          'Paschim Rajnagar',
          'Sarasia',
          'Joar Kachar',
          'Mathbaria',
          'Padua',
          'Pithapasari',
          'Purba Kachhar',
          'Saran Pahartali',
          'Char Lala',
          'Farhadnagar',
          'Jagajibanpur',
          'Mobarakghona',
          'Nairajpur',
          'Purba Sultanpur',
          'Subalpur',
          'Akrampur',
          'Baligaon',
          'Betagaon',
          'Char Hakdi',
          'Char Huzuri',
          'Char Madhuai',
          'Dhansahadda',
          'Domra',
          'Hakdi',
          'Kuruchia',
          'Katalia',
          'Madhuai',
          'Maruar Char',
          'Sundarpur',
          'Fazilpur',
          'Purba Rajnagar',
          'Purba Shibpur',
          'Alokdia',
          'Bhalukia',
          'Cheoria',
          'Goharua',
          'Jatrasiddhi',
          'Kalidaha',
          'Maijbaria',
          'Paschim Chhilania',
          'Tulabaria',
          'Uttar Gobindapur',
          'Aswadia',
          'Gilabaria',
          'Kazirbagh',
          'Ruhitia',
          'Kasba',
          'Lemua',
          'Mirganj',
          'Niamatpur',
          'Purba Dharmapur',
          'Purba Narayanpur',
          'Baghaia',
          'Ganganagar',
          'Izzatpur',
          'Kachua',
          'Lakshmipur',
          'Matabi',
          'Satsati',
          'Silua',
          'Uttar Sahapur',
          'Bagair',
          'Bhagabanpur',
          'Bhaskar',
          'Bijoy sing',
          'Birali',
          'Dumuria',
          'Ilaspur',
          'Jagirgaon',
          'Kashimpur',
          'Lakshmiara',
          'Mathiara',
          'Nagar kandi',
          'Panchgachhia',
          'Ujalia',
          'Damdama',
          'Purba Chhilania',
          'Sanua',
          'Tangirpar',
          'Tetia',
          'Abupur',
          'Chochhna',
          'Dakshin Abupur',
          'Dakshin Khanebari',
          'Daripatti',
          'Darkhi',
          'Gazariakandi',
          'Ghagra',
          'Jahanpur',
          'Kumira',
          'Noabad',
          'Nutan Khanebari',
          'Rashterkhil',
          'Safiabad',
          'Sharshadi',
          'Shindua',
          'Uttar Khanebari',
          'Zerkachhar',
          'Laskarhat',
        ],
      },
      {
        name: 'Sonagazi',
        postCode: '3930',
        areas: [
          'Mahish Char',
          'Uttar Char Chandia',
          'Uttar Char Chandi',
          'Char Ganesh Uttar',
          'Saraitkandi',
          'Paschim Tulatali',
          'Purba Tulatali',
          'Char Chandia',
          'Maidda Char Ganesh',
          'Char Ganesh Dakshin Purba',
          'Ward 01 - Sonagazi',
          'Ward 02 - Sonagazi',
          'Ward 03 - Sonagazi',
          'Ward 04 - Sonagazi',
          'Bara Ahammadpur',
          'Char Duba',
          'Char Krishnajoy',
          'Garamara',
          'Namchiduba',
          'Safarpur',
          'Sonapur',
          'Alampur',
          'Arkaim',
          'Aruarkhil',
          'Baduria',
          'Bagadana',
          'Bara Halia',
          'Brahmani Bazar',
          'Durgapur',
          'Gazaria',
          'Gunak',
          'Gunak Babupur',
          'Ichhapur',
          'Khodainagar',
          'Mandari',
          'Nadona',
          'Paik Para',
          'Bahir cha',
          'Bakuria',
          'Char Chandia',
          'Charmohish',
          'Purba Baradhali',
          'Bagispur',
          'Char Darbesh',
          'Char Sahabhikari',
          'Senerkhil',
          'Swalpa Mandari',
          'Bishnupur',
          'Chandla',
          'Char Badarpur',
          'Char Goalgaon',
          'Char Lakshmiganj',
          'Char Majlishpur',
          'Dakshin Narayanpur',
          'Dilrajpur',
          'Goalgaon',
          'Katakhil',
          'Majlishpur',
          'Raghabpur',
          'Akilpur',
          'Anandipur',
          'Daspaia',
          'Dakshin Lakshmipur',
          'Mangalkandi',
          'Mirzapur',
          'Dakhin Rajapur',
          'Sampur',
          'Bhadadia',
          'Bhoag',
          'Char Elahi',
          'Daulatkandi',
          'Ismailpur',
          'Jitpur',
          'Khichara',
          'Pakshia',
          'Palgiri',
          'Paniamalang',
          'Ramchandrapur',
          'Saidpur',
          'Satbaria',
          'Sonapur',
          'Sujapur',
          'Swarajpur',
          'Char Allen',
          'Char Ganash(Part)',
          'Char Khondakar',
          'Char Khoaz',
          'Char Khoazernamchh',
          'Char Ramnarayan',
          'Char Sahapur',
          'Dakshin Sujapur',
          'Dakshin Sahapur',
          'Saraitkandi',
          'Thak Khoazernamchhi',
          'Tulatali',
          'Char Kalidas',
          'Fatehpur',
          'Goalia',
          'Gobindapur',
          'Hajipur',
          'Mahadia',
          'Majupur',
          'Nawabpur',
          'Nazirpur',
          'Paschim Sultanpur',
          'Raghunathpur',
          'Safrabad',
          'Mobarakgona',
          'Motiganj',
          'Ahmadpur',
          'Kazirhat',
        ],
      },
      {
        name: 'Ful-Gazi',
        postCode: '3942',
        areas: [
          'Dakshin Dharmapur',
          'Khajuria',
          'Manipur',
          'Uttar Tarakuchia',
          'Anandapur',
          'Banoa',
          'Purba Basikpur',
          'Lakshmipur',
          'Nurpur',
          'Sharifpur',
          'Bandua',
          'Daulatpur',
          'Hasanpur',
          'Sree Chandpur',
          'Somvuchand',
          'Paschim Basikpur',
          'Uttar Ketranga',
          'Charigram',
          'Mohammadpur',
          'Narinia',
          'Uttar Talbariua',
          'Uttar Loteshwar',
          'Satkuchia',
          'Dakhinm Gutuma',
          'Dakhin Ketranga',
          'Dakhin Toleshwar',
          'Darbarpur',
          'Dhalia',
          'Kismat Basura',
          'Bijoypur',
          'Bairagpur',
          'Dakshin Basura',
          'Derpara',
          'Dakshin Daulatpur',
          'Dakshin Sonapur',
          'Dakshin sreepur',
          'Uttar Basura',
          'Gabtala',
          'Kismat Ghaniamora',
          'Gosaipur',
          'Kismat Bijoypur',
          'Joypur',
          'Mantala',
          'Madhya Basura',
          'Paschim Ghaniamora',
          'Nilakhi',
          'Purba Ghaniamora',
          'Uttar Baria',
          'Uttar Ghania',
          'Uttar Daulatpur',
          'Uttar sripur',
          'Badarpur',
          'Chandpur',
          'Jammura',
          'Fatehpur',
          'Jammura',
          'Dakshin Sreepur',
          'Dakshin Taralia',
          'Kalikapur',
          'Kamua',
          'Karaiya',
          'Kutubpur',
          'Mandarpur',
          'Noapur',
          'Poithara',
          'Sreepur (Anandapur)',
          'Uttar Anandapur',
        ],
      },
      {
        name: 'Pashuram',
        postCode: '3900',
        areas: [
          'Dakshin Rajaspur',
          'Dakshin Jangalghona',
          'Dakshin Saldhar',
          'Durgapur (Khandal)',
          'Jagamohanpur',
          'Kunderpar',
          'Madhyam Dhankunda',
          'Madhyam Sreepur',
          'Mali Patar (Mali Bil)',
          'Madhyam Chandana',
          'Noapur',
          'Paglirkul',
          'Palpara',
          'Paschim Alaka',
          'Purba Alaka',
          'Rampur',
          'Ratanpur',
          'Kismat Saldhar',
          'Kismat Sreepur',
          'Uttar Chandana',
          'Uttar Dhankunda',
          'Uttar Rajaspur',
          'Uttar Saldhar',
          'Uttar Sonapur',
          'Dakshin Chandana Dihi',
          'Uttar Sreepur',
          'Bagmara',
          'Charigram',
          'Dakshin Ketranga',
          'Dakshin Guthuma',
          'Dakshin Tateswar',
          'Jamirgaon',
          'Kohua (Rajorkhil)',
          'Kismat Tateswar',
          'Khodabox',
          'Mohammadpur',
          'Naronia',
          'Satkuchia',
          'Uttar Talbaria',
          'Uttar Tateswar',
          'Uttar Ketranga',
          'Asrafpur',
          'Birchandranagar',
          'Champaknagar',
          'Dakshin Kautali',
          'Durgapur',
          'Joyantanagar',
          'Joy Chandpur',
          'Kali Krisnanagar',
          'Kashinagar',
          'Madhugram',
          'Manikghona',
          'Maheshpuskarani',
          'Manipur',
          'Melaghar',
          'Mirzanagar',
          'Nij Kalikapur',
          'Noapur (Khandal)',
          'Uttar Jangalghona',
          'Paschim Sahebnagar',
          'Purba Sahebnagar',
          'Rangamatia',
          'D.M. Sahebnagar',
          'Satyanagar',
          'Uttar Kautali',
          'Asrayon Gram',
          'Baurkhuma',
          'Dublachand',
          'Khandakia',
          'Baur Pathar',
          'Anantapur',
          'Salia',
          'Kolapara',
          'Dakshin Kolapara',
          'Uttar Kolapara',
          'Banshpadua',
          'Uttar Gutuma Mauza',
          'Uttar Gutuma Purba',
          'Dakshin Berabaria',
          'Uttar Gutuma Paschim',
          'Ward 01 - Posuram',
          'Ward 02 - Posuram',
          'Ward 03 - Posuram',
          'Ward 04 - Posuram',
          'Ward 05 - Posuram',
          'Ward 06 - Posuram',
          'Ward 07 - Posuram',
          'Ward 08 - Posuram',
          'Ward 09 - Posuram',
          'Shuarbazar',
          'Munshirhat-Pashuram',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Khagrachari',
    zones: [
      {
        name: 'Khagrachari Sadar',
        postCode: '4400',
        areas: [
          'Khagrachari BUS Stand',
          'Master Para',
          'Kalabagan',
          'Court Building',
          'Milonpur',
          'Hospital gate',
          'Modupur',
          'Kharagpur',
          'Islampur',
          'Pankhyapara',
          'Kollan Pur',
          'Anndanagar',
          'Noyonpur',
          'Muslimpara',
          'Narikel Bagan',
          'Mohajon Para',
          'Collage-gate',
          'Union Porishad',
          'Sonirvor',
          'Golabari',
        ],
      },
      { name: 'Laxmichhari', postCode: '4470', areas: [] },
      { name: 'Manikchari', postCode: '4430', areas: ['Manikchari'] },
      {
        name: 'Matiranga',
        postCode: '4450',
        areas: [
          'Matiranga Bazar',
          'Matiranga Army CAmp',
          'Matiranga Hospital',
          'Matirtanga Thana',
          'Tainog Bazar',
          'Khedachara BGB Camp',
          'Tobolchori',
        ],
      },
      {
        name: 'Panchhari',
        postCode: '4410',
        areas: [
          'Vaibonchora',
          'Panchori Bazar',
          'hospital Gate Panchhari',
          'Ultachori',
          'Logag Bazar',
          'Shiv Mondir',
          '12 Numbar',
          'Fuzgag Bazer',
        ],
      },
      { name: 'Ramghar Head Office', postCode: '4440', areas: [] },
      {
        name: 'Khagrachhari Sadar',
        postCode: '4400',
        areas: [
          'Dokkhin Matha',
          'Nicher bazar',
          'Bulbuli potti',
          'Pouroshova',
          'Masterpara',
          'Kolabagan',
          'Bhanga brige',
          'Natun Kuri Cantonment Highschool',
          'Govt.High School',
          'DC Office',
          'Dc Banglo',
          'SP Office',
          'Hill District Council',
          'Hill District Council Quarter',
          'Mollapara',
          'Peon Colony',
          '1 number Kodomtoli',
          'Nanchi Bazar',
          'Khagrapur',
          'Khagrapur Notun Poline Line',
          'Khagrapur Master Dokan',
          'Khagrapur Tourist Police Office',
          'Islampur',
          'Islampur Golpara',
          'Islampur Army Cricent Gate',
          'Salbagan',
          'Sapla Mor ,Salbagan',
          'Mohommadpur, Salbagan',
          'Modhupur Bazar',
          'Moiti para',
          'Noyapara Modhupur',
          'APBN muslimpara',
          'APBN',
          'Tetultola Khagrachhari Sadar',
          'Puraton Pan Bazar',
          'Pankhiya Para School',
          'Switch Gate Khagrachhari Sadar',
          'Anondo Nagar',
          'Kollanpur',
          'System Hotel Pankhaiya Para',
          'Pankhiya para',
          'Noyonpur',
          'TTC',
          'Mohila Collage Khagrachhari Sadar',
          'Bottolli',
          'Apar perachora',
          'Newzealand',
          'Rupnagar',
          'Gawsiya Moshjid',
          'Joy Nursery',
          'Milonpur',
          'Milonpur Hotel Gairing',
          'Arambag Khagrachhari Sadar',
          'Muslimpara Khagrachhari Sadar',
          'Narikel Bagan',
          'Mohjonpara',
          'Santi Nagar',
          'Santi Nagar Bosundhara Avasik',
          'khagrachhari Gate',
          'College gate Khagrachhari Sadar',
          'Circuit House Khagrachhari Sadar',
          'Khagrachhari Cantonment 1 no gate',
          'MES Gate',
          'Sobujbag Khagrachhari Sadar',
          'Bus stand Khagrachhari Sadar',
          'Sobdomiya para',
          'Fire Service Khagrachhari Sadar',
          'Zero MIle',
          'PTI Khagrachhari Sadar',
          'Gonjjopara',
          'Kobompuria',
          'Dokkin Kobompuria',
          'Sonirvor Bazar',
          'Towfa School',
          'Naraiyenkhaiya',
          'BGB gate',
          'Stadium Khagrachhari Sadar',
          'Uno Office',
          'Talukder para',
          'khejurbagan',
          'Cangi squre',
        ],
      },
      {
        name: 'Dighinala',
        postCode: '4420',
        areas: [
          'Boro Merug',
          'Choto merug',
          'Babuchora',
          'Babuchora Natun Bazar',
          'Babuchora Puraton Bazar',
          'Borodom Babuchora',
          'Bagaihaat',
          'Machalong',
          'Kachalong',
          'Sajek',
          'Betchori',
        ],
      },
      {
        name: 'Mohalchori',
        postCode: '',
        areas: [
          'Nunchori',
          'Hospital Gate Mohalchori',
          'Hospital Quarter',
          'Mohalchori Collage',
          'Nonchori',
        ],
      },
      {
        name: 'Maischori',
        postCode: '',
        areas: ['Gugrachori', 'Maischhari School'],
      },
      {
        name: 'Manikchhari',
        postCode: '4430',
        areas: ['Manikchori Bazar', 'Mahamoni', 'Manikchori Hospital'],
      },
      {
        name: 'Lakkhichhari',
        postCode: '4470',
        areas: ['Hospital Quarter Lakkhichhari', 'Lakkhichari Busstand'],
      },
      {
        name: 'Ramgar',
        postCode: '4440',
        areas: [
          'Ramgar Collage',
          'Badamgach Mor',
          'Ramgar Poroshova',
          'Ramgar cinema Hall Market',
          'Ramghar Head Office',
        ],
      },
      {
        name: 'Guimara',
        postCode: '',
        areas: ['Guimara Thana', 'BGB Hospital', 'Army Camp'],
      },
      { name: 'Mahalchhari', postCode: '4430', areas: [] },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Lakshmipur',
    zones: [
      {
        name: 'Lakshimpur Sadar',
        postCode: '3702',
        areas: [
          'Sahapur',
          'Banchanagar',
          'Lakshmipur',
          'Shomsherabad',
          'Laharkandi',
          'Atiatali',
          'Abirnagar',
          'Rasulgonj',
          'Stadium',
          'Sadar Hospital Road',
          'Gov: College',
          'Mohila College',
          'Chokbazar',
          'Jumur hall',
          'Happy hall',
          'Thana',
          'Shishu park',
          'City Hospital',
          'Rehan uddin Road',
          'Miah Bari Road',
          'Darul Ulum Kamil Madrasa',
          'judge Court',
          'Civil sugeon Office',
          'Polli Biddut',
          'Police Line',
          'Kalibazar Road',
          'Meghna Road',
          'Mohammadia Hotel',
          'Walton Service Point',
          'BSCIC',
          'Passport Office',
          'Iter Pol',
          'Bus terminal',
          'Goruhata',
          'Shakjor chorasta',
          'Ramgoti Road',
          'Ward 1 - Lakshimpur Sadar',
          'Ward 2 - Lakshimpur Sadar',
          'Ward 3 - Lakshimpur Sadar',
          'Ward 4 - Lakshimpur Sadar',
          'Ward 5 - Lakshimpur Sadar',
          'Ward 6 - Lakshimpur Sadar',
          'Ward 7 - Lakshimpur Sadar',
          'Ward 8 - Lakshimpur Sadar',
          'Ward 9 - Lakshimpur Sadar',
          'Ward 10 - Lakshimpur Sadar',
          'Ward 11 - Lakshimpur Sadar',
          'Ward 12 - Lakshimpur Sadar',
          'Abhirkhil',
          'Alipur',
          'Amanullahpur',
          'Bangakha',
          'Debananda Rayerkhil',
          'Radapur',
          'Dulakandi',
          'Gaurinagar',
          'Hogaldahari',
          'Maheshpur',
          'Mirikpur',
          'Niamatpur',
          'Rajibpur',
          'Alampur',
          'Anandipur',
          'Balashpur',
          'Ramnagar',
          'Birahimpur',
          'Rukanpur',
          'Durgadaspur',
          'Fateh Dharmapur',
          'Junudpur',
          'Kashipur',
          'Khodawondpur',
          'Madanpur',
          'Nandigram',
          'Paschim Serpur',
          'Purba Serpur',
          'Shibrampur',
          'Usiarkandi',
          'Abdullahpur',
          'Bhabaniganj',
          'Char Buata',
          'Char Ubhut',
          'Kamalpur',
          'Piarapur',
          'Sharifpur',
          'Amani Lakshmipur',
          'Basuduhita',
          'Bhairabnagar',
          'Rashidpur',
          'Deopara',
          'Ganipur',
          'Panch Para',
          'Paschim Latifpur',
          'Rajapur',
          'Ramchandrapur',
          'Ramkrishnapur',
          'Charramani Mohan',
          'Char Ali Hasan',
          'Madhya Charramani Mohan',
          'Purba Char Ramani Mohan',
          'Char Megha',
          'Charsai',
          'Chhota Ballabhpur',
          'Gobindapur',
          'Itkhola',
          'Jagannathpur',
          'Jaliakandi',
          'Nurullahpur',
          'Purba Jafarpur',
          'Purba Sahapur',
          'Purba Saiyedpur',
          'Rahimpur',
          'Rampur',
          'Titarkandi',
          'Char Balam Mara',
          'Char Lamchhi',
          'Char Manda',
          'Char Ruhita',
          'Majanandi',
          'Dahashala Char Ruhita',
          'Khidirpur',
          'Khondakarpur',
          'Lakshmipur',
          'Mahadebpur',
          'Baralia',
          'Bara Para',
          'Battali',
          'Darji Para',
          'Datta Para',
          'Ganga Shibpur',
          'Karaitala',
          'Lalpur',
          'Narsinghapur',
          'Punianagar',
          'Ramarkhil',
          'Totarkhil',
          'Uttar Dhainyapur',
          'Durgapur',
          'Rajapur',
          'Dighali',
          'Fatehpur',
          'Fatehullahpur',
          'Gopiarkhil',
          'Hariballabhpur',
          'Khagaria',
          'Nischintapur',
          'Jafarpur',
          'Jamirtali',
          'Ramapur',
          'Sankibhanga',
          'Bara Ballabhpur',
          'Barampur',
          'Char Chamita',
          'Char Muhammadpur',
          'Joypur',
          'Harihar Chakra',
          'Hasanpur',
          'Hajir Para',
          'Indrapur',
          'Jagatpur',
          'Jatrapur',
          'Mirpur',
          'Nabi Taherpur',
          'Uttar Kalidasbagh',
          'Purba Aladadpur',
          'Ratanerkhil',
          'Shibpur',
          'Chandrapur',
          'Yousufpur',
          'Chiladi',
          'Farashganj',
          'Gorarbagh',
          'Jhaudagi',
          'Kalir Britti',
          'Kalyanpur',
          'Kathali',
          'Kushakhali',
          'Madna',
          'Mitharkhil',
          'Naldagi',
          'Nurkhan',
          'Pukurdia',
          'Purba Char Matua',
          'Abirnagar',
          'Athiatali',
          'Chandkhali',
          'Habibpur',
          'Idilpur',
          'Khajurtali',
          'Kumedpur',
          'Kutubpur',
          'Laharkandi',
          'Ramandi',
          'Safipur',
          'Talhati',
          'Gandharbapur',
          'Jadia',
          'Mandari',
          'Matabi',
          'Mohammadnagar',
          'Ratanpur',
          'Samaspur',
          'Uttar Durgapur',
          'Bijoynagar',
          'Hasandi',
          'Mirzapur',
          'Sabilpur',
          'Shyamganj',
          'Uttar Hamchadi',
          'Kafilatali',
          'Muktarampur',
          'Charerkandi',
          'Ahammadpur',
          'Dilsadpur',
          'Makaradhwaj',
          'Ahaladinagar',
          'Saydabad',
          'Bishnunagar',
          'Masimnagar',
          'Matlabpur',
          'Parbatinagar',
          'Wahidpur',
          'Puratan Shak Char',
          'Tumchar',
          'Dinga Manik',
          'Jagatber',
          'Kalir Char',
          'Choupalli',
        ],
      },
      {
        name: 'Ramganj',
        postCode: '3721',
        areas: [
          'Char Daktar',
          'Char Sikandar',
          'Shyamolgram',
          'Ward 1 - Ramganj',
          'Ward 2 - Ramganj',
          'Ward 3 - Ramganj',
          'Ward 4 - Ramganj',
          'Ward 5 - Ramganj',
          'Ward 6 - Ramganj',
          'Ward 7 - Ramganj',
          'Ward 8 - Ramganj',
          'Ward 9 - Ramganj',
          'Bhabanipur',
          'Chandpur',
          'Paitpur',
          'Hano Biyas',
          'Kethuri Kailas',
          'Rajarampur',
          'Purba Bhadur',
          'Madhya Bhadur',
          'Shamaspur',
          'Shirandi',
          'Baurkhara',
          'Bishnupur',
          'Dakshinpara',
          'Dalta',
          'Hasimpur',
          'Kandirpar',
          'Jafarnagar',
          'Jamalpur',
          'Joydebpur',
          'Khurdnagar',
          'Kumartala',
          'Mamudpur',
          'Maij Para',
          'Herapur',
          'Nandiara',
          'Panchrukhi',
          'Ramsinhapur',
          'Sarippur',
          'Akartama',
          'Amirpur',
          'Atakhara',
          'Abdulla Pur',
          'Bholakot',
          'Debnagar',
          'Dehala',
          'Dudrajpur',
          'Hapania',
          'Lakshmidhar Para',
          'Madhyapara',
          'Moktarpur',
          'Munsabpur',
          'Nagmud',
          'Nagraj Rampur',
          'Noapara',
          'Shahar Para',
          'Saktala',
          'Sreedharpur',
          'Sreenarayanpur',
          'Tiari',
          'Udaypur',
          'Arijpur',
          'Aiyanagar',
          'Baktarpur',
          'Darbesh Pu',
          'Madhya Darbeshpur',
          'Uttar Darbeshpur',
          'Purba Darbeshpur',
          'Jagatpur',
          'Majhirgaon',
          'Paschim Sohalia',
          'Narayanpur',
          'Nayanpur',
          'Raghabpur',
          'Sondara',
          'Sreerampur',
          'Shyamdampur',
          'Bigha',
          'Pachmbigha',
          'Purba Bigha',
          'Brahma Para',
          'Desua',
          'Hajir Para',
          'Joypura',
          'Kawalidanga',
          'Nichahara',
          'Safali Para',
          'Sainal',
          'Sekhpura',
          'Anandipur',
          'Azimpur',
          'Badarpur',
          'Bhatialpur',
          'Dumaria',
          'Gauripur',
          'Gazipur',
          'Fatehpur',
          'Karpara',
          'Sreepur',
          'Shyampur',
          'Bishnu Ballabpur',
          'Dakshin Hazipur',
          'Daspara',
          'Uttar Kalikapur',
          'Dakshin Kalikapur',
          'Kashimnagar',
          'Lamchar',
          'Majupur',
          'Panpara',
          'Rasulpur',
          'Shailkhali',
          'Samadpur',
          'Tahirpur',
          'Asapura',
          'Asarkota',
          'Balora',
          'Baragharia',
          'Baria Ais',
          'Dhomnadi',
          'Hotatia',
          'Noagaon',
          'Paniala',
          'Sadherkhil',
          'Singbar Ais',
          'Sorsai',
          'Udan Para',
          'Alipur-Ramganj',
          'Kanchanpur-Ramganj',
          'Dolta',
        ],
      },
      {
        name: 'Raipur',
        postCode: '3714',
        areas: [
          'Kalakopa',
          'Sagardi',
          'Saicha',
          'Shibpur',
          'Char Ababil',
          'Dighaldi',
          'Jhaudagi',
          'Keoradagi',
          'Pakshir Char',
          'Pangashia',
          'Baidyer Char',
          'Baludhum',
          'Beparir Char',
          'Boro Char',
          'Gaiar Char',
          'Takuar Char',
          'Udmara',
          'Char Bangshi',
          'Uttar Bolasiakandi',
          'Gazikandi',
          'Dewankandi',
          'Barakandi',
          'Haldarkandi',
          'Uttar Dalikandi',
          'Char Bangshi',
          'Kachiakhali',
          'Khasherhat',
          'Ilishakandi',
          'Hilar Char',
          'Kuchiamora',
          'Amtalipara',
          'Ghar Pagla',
          'Ghasiar Char',
          'Induria',
          'Majhikandi',
          'Machuakandi',
          'Jaliar Char',
          'Natun Kanibogar Char',
          'Beacons Field',
          'Char Mohana',
          'Roypur',
          'Char Palwan',
          'Char Tum',
          'Debipur',
          'Kazir Char',
          'Napiter Char',
          'Purba Lachh',
          'Baga Rakhalia',
          'Char Baga',
          'Sonapur',
          'Purbalach',
          'Keroya',
          'Denayetpur',
          'Denayetpur Bazar',
          'Madhupur',
          'Kanchanpur',
          'Shibpur',
          'Full Raypur Pourosova',
          'Ward 1 - Raipur',
          'Ward 2 - Raipur',
          'Ward 3 - Raipur',
          'Ward 4 - Raipur',
          'Ward 5 - Raipur',
          'Ward 6 - Raipur',
          'Ward 7 - Raipur',
          'Ward 8 - Raipur',
          'Ward 9 - Raipur',
          'Nagerdighirpar',
          'Haydarganj',
          'Bhuabari',
        ],
      },
      {
        name: 'Ramgoti',
        postCode: '3720',
        areas: [
          'Raghunathpur',
          'Char Abdullah',
          'Char Gazaria',
          'Char Savage',
          'Taler Char',
          'Char Alexandar',
          'Ashal Para',
          'Lambakhali',
          'Sabagram',
          'Madya Alexander',
          'Char Daktar',
          'Balur Char',
          'Manikpur',
          'Char Algi',
          'Char Takabi',
          'Char Niamat',
          'Char Sikanda',
          'Char Kalakopa',
          'Paschim Char Sita',
          'Purba Char Sita',
          'Char Afzal',
          'Char Gazi',
          'Char Lakshmi',
          'Char Darbesh',
          'Dakshin Tumchar',
          'Char Bedama',
          'Char Kalakopa',
          'Madhya Char Kalakopa',
          'Purba Kalakopa',
          'Char Poragachha',
          'Char Gosai',
          'Char Mehar',
          'Uttar Char Mehar',
          'Madhya Char Mehar',
          'Dakshin Purba Char Mehar',
          'Char Ramiz',
          'Char Sita',
          'Char Sikandar',
          'Shyamolgram',
          'Sabujgram',
          'Alexandar',
          'Shikhyagram',
          'Ward 1 - Ramgoti',
          'Ward 2 - Ramgoti',
          'Ward 3 - Ramgoti',
          'Ward 4 - Ramgoti',
          'Ward 5 - Ramgoti',
          'Ward 6 - Ramgoti',
          'Ward 7 - Ramgoti',
          'Ward 8 - Ramgoti',
          'Ward 9 - Ramgoti',
        ],
      },
      {
        name: 'Komalnagar',
        postCode: '3730',
        areas: [
          'Char Falcon',
          'Char Matabbar',
          'Char Basu',
          'Char Kadira',
          'Dakshin Char Pagla',
          'Char Thika',
          'Dakshin Char Kadira',
          'Kalkini',
          'Char Lawrence',
          'Char Samsuddin',
          'Char Martin',
          'Hajirhat',
          'Patarir Hat',
          'Torabgang',
          'Saheberhat',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Noakhali',
    zones: [
      {
        name: 'Noakhali Sadar',
        postCode: '3801',
        areas: [
          'Noakhali College Old Campus',
          'Noakhali College New Campus',
          'Maijdee Sadar',
          'Maijdee bazar',
          'Mominnagar',
          'Projuki',
          'Ahladinagar',
          'Joykrishnopur',
          'Babunagar',
          'Companigonj - Noakhali',
          'Haji Sidul Hoque Road',
          'Karagar',
          'Khondokarpara',
          'Horinarayonpur',
          'Noakhali Stadium',
          'Sudharam Police Station',
          'Mohila College',
          'Police Line - Noakhali',
          'Maijdee Court',
          'Lawyer Coloni',
          'Notun Bazar - Noakhali',
          'Master para',
          'Maijdee Housing Estate',
          'Dotter hat',
          'Sonapur',
          'East Maijdee',
          'West Maijdee',
          'Pouro Bazar',
          'Anantapur',
          'Chowmuhani Bazar',
          'Chowrasta',
          'Karimpur',
          'Gonipur',
          'Ekhlaspur',
          'Gabua',
          'TV centre',
          'Pak Kishoreganj',
          'Sonapur Main City',
          'Bus Stand - Noakhali',
          'Rail gate - Noakhali',
          'Hawkers Market',
          'Mondolpara',
          'Gola Bariya',
          'Kismat Karimpur Apon Nibas',
          'Dakkhin Bazar',
          'Napiterpol',
          'Kuripara',
          'Pora Bari',
          'Noakhali Medical College',
          'Miayarpol',
          'Din Monir Hat',
          'Pak Kishoreganj',
          'Mriddarhat',
          'Charam Tua',
          'Chaprashir Hat',
          'Char Jabbar',
        ],
      },
      {
        name: 'Begumgonj',
        postCode: '3821',
        areas: [
          'Korimpur Main City area',
          'Bus Stand',
          'Hawkers market',
          'Rail gate',
          'Mondolpara',
          'College Road.',
          'Gola Bariya',
          'Kismat Karimpur Apon Nibas',
          'Dokkhin Bazzar',
          'Napiterpol',
          'Kuripara',
          'Pora Bari',
          'Noakhali Medical College',
          'Miayarpol',
          'Gonipur',
          'Karimpur',
          'Durgapur',
          'Hajipur',
          'norottompur',
          'Mirwarishpur',
          'Alipur',
          'Amanatpur',
          'Begumganj',
          'Khanpur',
          'kutubpur',
          'Rasulpur',
          'Laksminarayanpur',
          'Abdullahpur',
          'Senbagh',
          'Bejbagh',
          'Kadra',
          'Motimiyarhat',
          'Rajapur',
          'Enayetpur',
          'Nateshwer',
          'kazinogor',
          'Barogaon',
          'Hatirpar',
          'Motin Miyar Bazar',
          'Ekota Bazar',
          'Chatarpaiya',
          'Hossainpur',
          'Kalikapur',
          'Gopalganj',
          'Gopalpur',
          'Amin Bazar',
          'Bangla bazar - Begumganj',
          'Main Bazar',
          'Choumohani',
          'Bazra',
          'Jamidar Hat',
          'Joynarayanpur',
          'Alaiarpur',
          'Tangirpar',
          'Khalafat Bazar',
          'Rajganj',
          'Oachhekpur',
          'Bhabani Jibanpur',
          'Maheshganj',
          'Nadona',
          'Nandiapara',
          'Khalishpur',
          'Dauti',
          'Joyag',
          'Thanar Hat',
          'Amisha Para',
        ],
      },
      {
        name: 'Maizdee',
        postCode: '3800',
        areas: [
          'Rajganj',
          'Ahladinagar',
          'Full City',
          'Noakhali College Old Campus',
          'Haji Sidul Hoque Road',
          'Karagar',
          'Khondokarpara',
          'Housing',
          'College New Campus',
          'Horinarayonpur',
          'Puro Bazar',
          'Stadium',
          'Sudaram Thana',
          'Mohila College',
          'Police Line',
          'Court',
          'Lawyer Coloni',
          'Notun Bazar',
          'Mastarpara',
        ],
      },
      {
        name: 'Senbag',
        postCode: '3860',
        areas: [
          'Mohammedpur',
          'Bhawanipur',
          'Takiya',
          'Ramnogor',
          'Azizpur',
          'Lalpur',
          'Dildermarket',
          'kabilpur',
          'Moijdipur',
          'Baliyakandi',
          'Kutubpur',
          'Bottola bazar',
          'Rasulpur',
          'Kadirpur',
          'Hatirpar',
          'Arjuntola',
          'Moizdipur',
          'Ambornogor',
          'Hazirhat',
          'Edilpur',
          'Kallyandi',
          'Beezbag',
          'Kankirhat',
          'Chatarpaia',
          'T.P. Lamua',
        ],
      },
      {
        name: 'Sonaimuri',
        postCode: '3827',
        areas: [
          'Deoiti',
          'Amishapara',
          'Joyag',
          'Sonaimuri',
          'Baragon',
          'Police station',
          'College -Sonaimuri',
          'Chashirhaat',
          'Bipulasar',
        ],
      },
      {
        name: 'Chatkhil',
        postCode: '3870',
        areas: [
          'Trigriya',
          'Boshikpur',
          'Doulotpur',
          'Damaliya',
          'Togba',
          'Chatkhil main City',
          'Full Bazar',
          'Sampara',
        ],
      },
      {
        name: 'Chondrogonj Thana',
        postCode: '3804',
        areas: [
          'Kofil Uddin Digri college',
          'Boroitola',
          'Kamarhaat',
          'Hajirpara',
          'Baluchar',
          'Charshahi',
          'Pachpara',
          'jaforpur',
          'Nabinagar',
          'Gobindapur',
          'Khaguriya',
        ],
      },
      {
        name: 'Sonapur',
        postCode: '3802',
        areas: [
          'Datterhat',
          'Mominnagar',
          'Sonapur Full City',
          'Projuki',
          'Main Bazar',
        ],
      },
      { name: 'Boshur Haat', postCode: '3802', areas: [] },
      { name: 'Companigonj', postCode: '3812', areas: ['Charhajari'] },
      {
        name: 'Subornochar',
        postCode: '3812',
        areas: [
          'Mannan nogor',
          'Momin nagar bazar',
          'Kormullah',
          'Kazirtek bazar',
          'keramot nogor bazar',
          'Abdullahmiyarhat',
          'Thanarhat',
          'Chandhuri hat',
          'Subornochor upozila',
          'Khaserhat',
          'Charjabbar',
          'Charbhata',
          'Charjublee',
          'abdullah miyar hat',
        ],
      },
      {
        name: 'Hatia',
        postCode: '3890',
        areas: [
          'Khashar Hat',
          'Brick field',
          'Ochkhali',
          'Khabir',
          'Beker Bazar',
          'Tomuruddin Bazar',
          'Puran Bazar',
          'New Market Hatia',
          'Moulavibazar Hatia',
          'Soudia Bazar',
          'Chaumuhani Bazar',
          'Saguria Bazar',
          'Zahazmara',
          'Afazia',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Rangamati',
    zones: [
      {
        name: 'Kotwali Thana',
        postCode: '',
        areas: [
          'Mohosin Colony',
          'Mosjid Colony',
          'Press Club Kotwali Thana',
          'CNG Station Kotwali Thana',
          'DC Banglo',
          'Moni Sopon Dewan Goli',
          'Govt High School',
          'Reserve Mukh',
          'Launch Ghat Kotwali Thana',
          'Swarnatila',
          'Amda Mia Hill',
          'Art Colony',
          'Master Colony',
          'Tabalchari bazar',
          'BGB Gate',
          'Kotwali Thana',
          'Tribal Officers Colony',
          'Shaha High School',
          'Mazerbosti',
          'Tabachari Ghat',
          'Textile Market',
          'Contractor para',
          'Jail Road Kotwali Thana',
          'Ananda Bihar',
          'Old Bus Station',
          'Fishery Ghat',
          'Fire Service Station Kotwali Thana',
          'Pouroshova Gate Kotwali Thana',
          'Gorjontuli',
          'Kotwali Thana lakers School',
          'Doya para',
          'kathaltuli mosjid',
          'IDF Office',
          'BM Market 1',
          'BM Market 2',
          'North Forest Colony',
          'South Forest Colony',
          'Katha Pahar',
          'Sholok Market',
          'Dewan Para Kotwali Thana',
          'Shoshi Dewan Para',
          'Bolpiye Adam',
          'Somota Ghat',
          'Chokro para Kotwali Thana',
          'Rajdip',
          'East Tribal adam',
          'West Tirbal Adam',
          'Al Fesani School',
          'RPTI',
          'Wapda Rest House',
          'CNG Station',
          'Last Green Hill',
          'First Green Hill',
          'Happy Moor',
          'Gausia market',
          'New Market Kotwali Thana',
          'DC Office Kotwali Thana',
          'Monoskuli Pharmacy',
          '3 rasta moor',
          'Gobindo store',
          'Chingku Roaza Building',
          'Kalindipur Dam',
          'Maldip',
          'Hindu mondir',
          'Sonali Bag',
          'Public health Kotwali Thana',
          'Bijon Sorony',
          'Jamini Sorony',
          'Rani School',
          'Rajbari Road',
          'Silpokola',
          'Rani School (Primary)',
          'Stadiam Area',
          'Valedi',
          'Bridge Kotwali Thana',
          'Rajbana Bihar',
          'Stediam Area',
          'Hospital Road Kotwali Thana',
          'Hospital Area Kotwali Thana',
          'Devashis nagar Mukh',
          'Primary School',
          'LGED office Kotwali Thana',
          'TNT Road',
          'Kallyanpur Kotwali Thana',
          'TTC Road',
          'Magistrate Colony Kotwali Thana',
          'CIPD Office',
          'Motel George',
          'Sudipta Dewan Para',
          'Valedi Kotwali Thana',
          'Bijoy Nagar',
          'Moittree Nogar',
          'Kabab Ghor',
          'C Office',
          'College Gate Kotwali Thana',
          'Amanat Bag',
          'Montree Para',
          'Cantonment Kotwali Thana',
          'Vedvedi biddhut Office',
          'NSI Office',
          'Ansar Kamp',
          'Rajmuni Para',
          'Songram Bihar',
          'Passport Office Kotwali Thana',
          'Kali Bari Road',
          'Vedvedi CNG Station',
          'Jubo Unnayan',
          'Udonni adam',
          'Montola',
          'Monoghor School',
          'Rangapannye mat',
          'Rangapannye CNG Station',
          'Sukhinil ganj police line',
          'Mohajon Para',
          'Dewan Para',
          'Noa adam',
          'Lumbini',
          'Kingdom Restuarant',
          'krishi Farm',
          'Narikel bagan',
          'Boradam',
          'Primary School Kotwali Thana',
          'Asambosti Bazar',
          'Police line Kotwali Thana',
          'BGB Camp Kotwali Thana',
          'Huduk chari Bazar',
          '3 Rasta more',
          'Agriculture Training Institute',
          'Nirban Ratna Bana Vabona Kutir',
          'Sapchari Primary School',
        ],
      },
      {
        name: 'Kaptai Thana',
        postCode: '4530',
        areas: [
          'Jeeptuli Army kamp',
          'Suidish',
          'Kaptai Notun Bazar',
          'Kaptai Project',
          'Kaptai Army Kamp',
          'BGB Kamp Kaptai Thana',
          'Upazilla Kaptai Thana',
          'Resom Bagan',
          'Bogapara',
          'Headman para',
          'Murali Para',
          'Sapchari Buddha Mondir',
          'Dovashi Bazar',
          'Lichu Bagan',
          'Mishion Hospital',
          'KPM',
          'Bazar Kaptai Thana',
          'Kusto Hospital',
        ],
      },
      {
        name: 'Kawkhali Thana',
        postCode: '',
        areas: [
          'Ranir Hat',
          '13 no Islampur Union Council',
          'Bangabondhu Satelite',
          'Upazilla Sadar',
          'Gagra Textile Mill',
          'Gagra High School',
          'Army Kamp',
          'Gagra Bazar',
        ],
      },
      {
        name: 'Naniar Char Thana',
        postCode: '4520',
        areas: ['Naniarchar Bridge', 'Naniarchar Bazar', 'Bogachari Bazar'],
      },
      { name: 'Rajasthali', postCode: '', areas: [] },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Habiganj',
    zones: [
      {
        name: 'Azmireeganj',
        postCode: '3360',
        areas: [
          'Jalsuka',
          'Badalpur',
          'Shibpasha',
          'Jolshukha',
          'Union office',
          'ABC pilot high school area',
          'Hospital road',
        ],
      },
      {
        name: 'Bahubal',
        postCode: '3310',
        areas: [
          'Bahubal',
          'Bhadeshwar',
          'Mirpur',
          'Shayestaganj',
          'Lamatashi',
          'Satkapan',
          'Snanghat',
          'The Palace Resort',
          'Joypur',
        ],
      },
      {
        name: 'Baniachong',
        postCode: '3350',
        areas: [
          'Rotna',
          'Vobanipur',
          'Cng Stand',
          'Moddho Bazaar',
          'Boro bazaar',
          'Jatrapasha',
        ],
      },
      {
        name: 'Chunarughat',
        postCode: '3320',
        areas: [
          'Ahmadabad',
          'Gazipur',
          'Chunarughat',
          'Deorgachh',
          'Paik Para',
          'Mirahi',
          'Ranigaon',
          'Shatiajuri',
          'Sankhola',
          'Balla road',
          'Cross road',
          'Moddho bazaar',
          'Uttor bazaar',
          'Thana area',
          'Pirer bazaar',
          'Shreekuta',
          'Durgapur bazaar',
          'Chandpurbagan',
          'Narapati',
        ],
      },
      {
        name: 'Habiganj Sadar',
        postCode: '3300',
        areas: [
          'Alga bari',
          'Chirakandi',
          'Baganbari',
          'Noabad',
          'Puran munsefi',
          'uttor syamoli',
          'Dokkhin shyamoli',
          'Horipur',
          'Diyanot saha bari',
          'Rampur',
          'Natirabad',
          'Ashkornogor',
          'Goshainogor',
          'Inatabad',
          'Hospital Road',
          'New Muslim quarter',
          'Staff quarter',
          'Anantapur',
          '2 number pool',
          '3 number pool',
          'Dhuliakhal',
          'Mojlishpur',
          'Gopaya',
          'Town Hall Road',
          'Cinema hall road',
          'p.t.i road',
          'Rajnogor',
          'court station',
          'tinkuna pukur par',
          'Noahati',
          'Josher abda',
          'Batirpur',
          'Shoshanghat',
          'R.K mission road',
          'Kalibari cross road',
          'Mohila college road',
          'Narayanpur',
          'Shobujbag',
          'Circuit house road',
          'Fire service road',
          'Puran bustand',
          'Machuliya',
          'Mahmudabad',
          'Busstand',
          'Sultan Mahmud pur',
          'Anwarpur',
          'Ghatia bazar',
          'Bogla bazaar',
          'Guarning park',
          'Puran Bazaar',
          'Kamrapur',
          'Daniarpur',
          'Master Quartar',
          'Quresh Nogor',
          'Digonto para',
          'Nayeber pukur',
          'Jalalabad',
          'Jhilpar',
          'Baby stand',
          'Shangkar er mukh',
          'Chowdhury bazaar',
          'Sayestanogor',
          'Eidgah road',
          'Chirakhana road',
          'Bodiuzzaman khan road',
          'Ghosh para',
          'Habiganj Bus Stand',
          'Mohonpur',
          'College Quarter',
          'Anowarpur',
          'Poddar Bari',
          'Vanga Pull',
          'Commercial Area',
          'Shaestaganj',
          'Natun Bridge',
          'Olipur',
          'Rail Station',
          'Daud nagar Bazar',
          'Workshop area',
          'Police Line-Habiganj Sadar',
          'Murali bazar',
          'koytadi',
        ],
      },
      {
        name: 'Lakhai',
        postCode: '3341',
        areas: ['Bamai', 'Bulla', 'Murakari', 'Muriauk', 'Lakhai', 'Kalauk'],
      },
      {
        name: 'Madhabpur',
        postCode: '3330',
        areas: [
          'Andiurauk',
          'Chowmohani',
          'Chhatiain',
          'Jagadishpur',
          'Dharmaghar',
          'Noapara',
          'Bagasura',
          'Bahara',
          'Bulla',
          'Shahjahanpur',
          'Itakhola',
          'Shahajibazar',
          'Saihamnagar',
          'Dharmaghar 1 no union',
        ],
      },
      {
        name: 'Nabiganj',
        postCode: '3370',
        areas: [
          'Inathganj',
          'Kargaon',
          'Kalair Banga',
          'Kurshi',
          'Gaznapur',
          'Dighalbak',
          'Debpara',
          'Nabiganj',
          'Paschim Bara Bhakhair',
          'Paniunda',
          'Purba Bara Bakhair',
          'Bausha',
          'Golduba',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Moulvibazar',
    zones: [
      {
        name: 'Moulvibazar Sadar',
        postCode: '3200',
        areas: [
          'Borkapon',
          'Purbo Borhat',
          'Poschin Borhat',
          'Dokkin Borhat',
          'Kusumbag',
          'Berirpar',
          'Dorjimohol',
          'Busstand',
          'Jogonnatpur police line',
          'Mostofapur',
          'Dhorkapon',
          'Gobindosree',
          'Islambag',
          'Pagulia',
          'Purbo Girjapara',
          'Poschim Girjapara',
          'Fatabil',
          'Radar unit, Mokambazar',
          'Cercuit House Area',
          'TV Hospital Road',
          'Arambag',
          'Uttor Kolimabad',
          'Dokkin Kolimabad',
          'Technical School Road',
          'Sunapur',
          'Bonosree',
          'Bonobiti',
          'Wapda',
          'Rogunondonpur',
          'Poschim Sultanpur',
          'Abu Tahir Road',
          'Purbo Sultanpur',
          'Kajirgao',
          'Poschimbazar',
          'Berirchor',
          'Syed Mojtoba Ali Road',
          'Muslim Quarter',
          'Sikander Ali Road',
          'Moddopara',
          'Santibag',
          'Goshpotti',
          'Kashinat road',
          'Soiyarpur',
          'Majer hati',
          'Sunabibi road',
          'Maijpara',
          'Shimultola',
          'Borshijura',
          'Kartik Chondro Road',
          'Chorarpar',
          'Chadnighat',
          'Forest Office Road',
          'Sherpur',
          'Kajirbazar',
          'Konokpur',
          'Sorkarbazar',
          'Office Bazar',
          'Sikandar Ali Road',
          'Monumukh',
          'Chowmohona',
          'Matarkapon',
          'Pouroshova',
          'Nama para',
          'Terminal',
          'Thana road',
          'Kashinath road',
          'Mirzapar',
          'Afrozganj',
          'Bakakapan',
          'Central road',
          'Old hospital road',
          'Lake road',
          'Mustafa road',
          'Sultanpurr',
          'CMV coloni',
          'PTI school road',
          'Stadium road',
          'Pani unnoyon board',
          'Wapda point',
          'Kudalipur',
          'Mustafapur',
          'Dorgamoholla',
          'Prithimpasha',
        ],
      },
      {
        name: 'Baralekha',
        postCode: '3250',
        areas: [
          'Uttar Shahabajpur',
          'Shahabajpur',
          'Talimpur',
          'Dakshin Shahabajpur',
          'Daser Bazar',
          'Nij Bahadurpur',
          'Barlekha',
          'Barni',
          'Suri Kandi',
          'Choumuhona',
          'Panidar',
          'Kanongo Bazar',
          'Azimgonj',
          'Rotuli Bazar',
          'Kataltoli',
          'Dokkin Bag',
          'Madhobkundo',
          'Upozila Chattar',
          'Juri-Baralekha',
          'Purbashahabajpur',
        ],
      },
      {
        name: 'Juri',
        postCode: '3251',
        areas: [
          'Dakshin Daskinvagh',
          'Paschim Juri',
          'Purba Juri',
          'Jafarnagar',
          'Sujanagar',
          'Sagarnal',
          'Fultala',
          'Juri',
        ],
      },
      {
        name: 'Kamalganj',
        postCode: '3220',
        areas: [
          'Alinagar',
          'Islampur',
          'Kamalganj',
          'Patanushar',
          'Madhabpur',
          'Munshi Bazar',
          'Rahimpur',
          'Shamshernagar',
          'Bhanugach',
          'Patrakhola',
        ],
      },
      {
        name: 'Kulaura',
        postCode: '3230',
        areas: [
          'Kadirpur',
          'Kulaura',
          'Joychandi',
          'Tilagaon',
          'Prithim Pasha',
          'Baramchal',
          'Brahman Bazar',
          'Bhukshimail',
          'Bhatera',
          'Routhgaon',
          'Sharifpur',
          'Hajipur',
          'Langla',
          'Kajaldhara',
          'Robirbazar',
        ],
      },
      {
        name: 'Rajnagar',
        postCode: '3240',
        areas: [
          'Kamar Chak',
          'Tengra',
          'Panchgaon',
          'Fatehpur',
          'Mansurnagar',
          'Munshi Bazar',
          'Rajnagar',
          'Tengrabazar',
          'Karimpur',
          'Tarapasa',
        ],
      },
      {
        name: 'Sreemangal',
        postCode: '3210',
        areas: [
          'Kalapur',
          'Kalighat',
          'Bhunabir',
          'Mirzapur',
          'Sreemangal',
          'Satgaon',
          'Sindurkhan',
          'Ukiilbari',
          'Missoin road',
          'Bongobir road',
          'Catholic mission rd',
          'Baridhara',
          'Arambag',
          'Ruposhpur',
          'Sondhani para',
          'Surovi para',
          'Sobujbag',
          'Lalbag',
          'Debbari',
          'Hobigonj road',
          'Central road',
          'Shantibag',
          'Shaplabag',
          'Station road',
          'Purbasa',
          'Noagao',
          'Kakiabazar',
          'Kalapur',
          'Sindurkhan road',
          'Jalalia road',
          'Fulchira',
          'Khejuri',
          'Shamoli R/A',
          'Choumohona',
          'College road',
          'Narain Chora',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Sunamganj',
    zones: [
      { name: 'Bishwamvarpur', postCode: '3010', areas: ['Bili Juri'] },
      {
        name: 'Dharmapasha',
        postCode: '2450',
        areas: [
          'Uttar Sukhair Rajapur',
          'Chamardani',
          'Joysree',
          'Dakshin Bongshikunda',
          'Dakshin Sukhair Rajapur',
          'Dharmapasha',
          'Paikurati',
          'Madhyanagar',
          'Selborash',
          'Dharmapasha',
        ],
      },
      {
        name: 'Chhatak',
        postCode: '3080',
        areas: [
          'Kalaruka',
          'Charmohalla',
          'Chhatak',
          'Jawar Bazar',
          'Dakshin Islampur',
          'Dakshin Khurma',
          'Dular Bazar',
          'Noarai',
          'Bhatgaon',
          'Sing Chapair',
          'Saila Afzalabad',
          'Saidergaon',
          'Chhatak Cement Factory',
          'Chhatak Paper Mills',
          'Chourangi Bazar',
          'Gabindaganj',
          'Gabindaganj Natun Bazar',
          'Islamabad',
          'Jahidpur',
          'Khurma',
          'Moinpur',
        ],
      },
      {
        name: 'Derai',
        postCode: '3040',
        areas: [
          'Kulanj',
          'Charnar Char',
          'Jagaddal',
          'Taral',
          'Derai Sarmangal',
          'Bhatipara',
          'Rafinagar',
          'Rajanagar',
          'Noakhali Bazar',
          'Pataira Bazar',
          'Katoir Bazar',
          'Joy Nogor',
        ],
      },
      {
        name: 'Dowarabazar',
        postCode: '3070',
        areas: [
          'Duhalia',
          'Narsing Pur',
          'Pandergaon',
          'Bangla Bazar',
          'Bougla Bazar',
          'Mannargaon',
          'Lakshmipur',
          'Surma',
        ],
      },
      {
        name: 'Jagannathpur',
        postCode: '3060',
        areas: [
          'Asharkandi',
          'Kalkalia',
          'Pailgaon',
          'Patali',
          'Mirpur',
          'Raniganj',
          'Syedpur',
          'Haldipur',
          'Atuajan',
          'Hasan Fatemapur',
          'Rasulganj',
          'Shiramsi',
          'Mirpur Bazar',
          'Pirer Bazar',
          'Vober Bazar',
          'Hospital point',
        ],
      },
      {
        name: 'Jamalganj',
        postCode: '3020',
        areas: ['Fenarbak', 'Beheli', 'Vimkhali', 'Sachna Bazar', 'Sachna'],
      },
      { name: 'Shantiganj', postCode: '', areas: [] },
      { name: 'Salla', postCode: '', areas: ['Atgaon', 'Bahara', 'Habibpur'] },
      {
        name: 'Sunamganj Sadar',
        postCode: '3000',
        areas: [
          'Patharia',
          'Hason nogor',
          'Mollik pur',
          'Bilpar',
          'Bonanipara',
          'Nobinogor',
          'Sulogor,',
          'Mohammedpur',
          'Bihari Point',
          'Moynar Point',
          'Notunpara',
          'Pochchim Notun Para',
          'Purbo Notun Para',
          'Hazi Para',
          'Pochchim Hazipar',
          'BGB Camp',
          'RAB 9 Camp',
          'Kalipur',
          'Sabbari Ghat',
          'Launch Ghat',
          'Ibrahimpur',
          'VARD Eye Hospital',
          'Atrfin Nogor',
          'Jamtola',
          'Jamai Para',
          'Kalibari',
          'Moddobazar',
          'Jail Road',
          'Muktar Para',
          'Ray Para',
          'SC Girls School',
          'Lobjan High School',
          'Court Point',
          'Jolilpur',
          'Ambari Bazar',
          'Lilpur',
          'Gonigonj',
          'Dirai Rastar Mur',
          'Dabor',
          'Santigonj',
          'Tukerbazar',
          'Maijbari',
          'Wajkhali',
          'Aftabnagar',
          'Sultanpur',
          'Paton bari',
          'Bitgonj',
          'Joggibon pur',
          'Surma market',
          'Kazir point',
          'Dhirai Chandpur',
          'Ghungiar',
        ],
      },
      {
        name: 'Tahirpur',
        postCode: '3030',
        areas: [
          'Uttar Badaghat',
          'Uttar Sreepur',
          'Tahirpur',
          'Dakshin Badal',
          'Dakshin Sreepur',
          'Balijuri',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Sylhet',
    zones: [
      {
        name: 'Sylhet Sadar',
        postCode: '3100',
        areas: [
          'Lama Para',
          'Majumder Para',
          'Raynagar',
          'Senpara',
          'Sonarpara',
          'Shadipur',
          'Chandani Tila',
          'Daptari Para',
          'Darjee Band',
          'Darjee Para',
          'Goner Para',
          'Lakri Para',
          'Tilaghar',
          'Bhatatikr',
          'Brahman Para',
          'Gopal Tila',
          'Hatimbagh',
          'Shobujbagh',
          'Gulapbagh',
          'Shaplabagh',
          'Mirapara',
          'Kollayanpur',
          'Kushighat',
          'Lamapara',
          'Sadatikar',
          'Saderpara',
          'Sadipur-2',
          'Tultikar',
          'Mejortila',
          'Islampur',
          'Sayedpur',
          'Purobi',
          'Mohammedpur',
          'Nurpur',
          'Chamelibagh',
          'Khadim Para',
          'Khadimpara 1',
          'Khadimpara 2',
          'Khadimpara 3',
          'Khadimpara 4',
          'Khadimpara 5',
          'Khadimpara 6',
          'Khadimpara 7',
          'Bohor R/A',
          'BADC',
          'Hill View Tower',
          'Nipovon',
          'Shah Paran Bazar',
          'Shahporan Hospital',
          'Khadimpara Union Parishad',
          'Shah Paran R/A',
          'Bahubol R/A',
          'Bypass',
          'Agriculture Training Institute',
          'BSTI Sylhet office',
          'BSCIC Area',
          'Dashpara Bazar',
          'Prgona Bazar',
          'Pirer Bazar',
          'Brac Learning Center Sylhet',
          'Metropolitan University',
          'Bateshwar Bazar',
          'Jalalabad Cantonment',
          'Muradpur Bazar',
          'BKSP Sylhet',
          'Sylhet Cantonment',
          'Kacha Lonka',
          'RAB 9 Headquarter',
          'Amborkhana',
          'Dorga gate',
          'Darshan deuri',
          'Rajargoli',
          'Dargah moholla',
          'Dariapara',
          'Kazi Elias',
          'Lama Bazar',
          'Housing state',
          'Lichu Baghan',
          'Mazumdari',
          'Dattapara',
          'Mia fazil chist',
          'Mirermoydan',
          'Purbo Subudbazar',
          'Kajolsah',
          'Keyapara',
          'Munshipara',
          'Boro Bazar',
          'Electric Supply',
          'Goypara',
          'Khasdobir',
          'Akhalia',
          'Bagbari',
          'SUST (Shahjalal University of Science and Technology)',
          'Khuliapara',
          'Modina market',
          'Neharipara',
          'Sagordighir par',
          'Pathantula',
          'Gasitula',
          'Shamimabad',
          'Kanishail',
          'Mollapara',
          'Nobabroad',
          'Wapda',
          'Mojumdarpara',
          'Bhatalia',
          'Bil Par',
          'Kajalshah',
          'Kuarpar',
          'Lala Dighirpar',
          'Madhu Shahid',
          'Noapara',
          'Rekabi Baza',
          'Bhangatikar',
          'Itakhola',
          'Saudagartala',
          'Sheikhghat',
          'taltola south',
          'Tupkhana',
          'Masudhighir par',
          'Mirza Zungle',
          'Bandar Bazar',
          'Brahmandi Bazar',
          'Chali Bandar',
          'Chararpar',
          'Hasan Market',
          'Dak Bangla Road',
          'Dhupa Dighirpar',
          'Jallar Par',
          'Jamtala',
          'Houkers Market',
          'Kastagarh',
          'Kamal Garh',
          'Kalighat',
          'Paura Biponi',
          'Talitala',
          'Zindabazar',
          'Baruth Khana',
          'Chali Bandar',
          'Churi Patti',
          'Jail Road',
          'Joynagar',
          'Jatarpur',
          'Nayarpool',
          'Subhani Ghat',
          'Puran Lane',
          'Uttar Dhopa Dighirpar',
          'Charadigirpar',
          'Dhopa Digirpar',
          'Hauapara',
          'Kahan Daura',
          'Kumarpara',
          'Purba Zinda Bazar',
          'Naya Sarak',
          'Saodagor Tola',
          'Tatipara',
          'Kazitula',
          'Mirboxtula',
          'Chondontula',
          'Chowhatta',
          'Uchasarak',
          'Brajahat Tila',
          'Evergreen',
          'Jhornar Par',
          'Jheri jheri Para',
          'KumarPara',
          'Mira Bazar',
          'Mousumi',
          'Sabuj Bagh',
          'Shahi Eidgah',
          'National Heart Foundation',
          'TB Gate',
          'Arambag',
          'Shakhari Para',
          'Uposhohor Block A',
          'Uposhohor Block B',
          'Uposhohor Block C',
          'Uposhohor Block D',
          'Uposhohor Block E',
          'Uposhohor Block F',
          'Uposhohor Block G',
          'Uposhohor Block I',
          'Uposhohor Block J',
          'Shahjalal Uposhohor Bangladesh Bank Colony',
          'Mehendibagh',
          'Machimpur',
          'Bharthokhola',
          'Chandnighat',
          'Jalopara',
          'Kadamtali',
          'Jalopara',
          'Humayun Rashid Chottor',
          'Shibbari',
          'Joinpur',
          'Brahman Sashsan',
          'Haldar para',
          'Korerpara',
          'Ponitula',
          'Uttarpir moholla ( Paharika )',
          'Guabari',
          'Kandhigaon',
          'Muoghalgaon',
          'Hat Khola',
          'Tuker Bazar',
          'Badam Bagicha',
          'Choukidighi',
          'Eliaskandi',
          'Jalalabad',
          'West Pir Moholla',
          'Soyef Khan Road',
          'Haji Para',
          'Bon kolapara',
          'Fazil Chishti',
          'Kolapara',
          'Londoni Road',
          'Khadimnagar',
          'Sylhet Cadet College',
          'Osmani International Airport',
          'Sylhet Divisional Stadium',
          'Malinichhara Tea Garden',
          'Tarapur Tea Garden',
          'Lakkatura Tea Garden',
          'Shalutikor',
          'Borshala',
          'Baishtila (22 tila)',
          'Sylhet Sadar',
          'Kazir Bazar',
          'Boteshshor',
          'Alampur-Sylhet Sadar',
          'Chandipul',
          'Sylhet Biman Bandar',
          'Jalalabad Cantoment',
          'Jhalopara',
          'Birahimpur',
          'Dhupadighirpar',
          'Ranga Hajiganj',
        ],
      },
      {
        name: 'Dakshin Surma',
        postCode: '3250',
        areas: [
          'Khojarkhola',
          'Mominkhola',
          'Musargoan',
          'Lawai',
          'Alampur',
          'Ganganagar',
          'Kuchai',
          'Tetli',
          'Baraikandi',
          'Technical Road',
          'Mollargaon',
          'Kamalbazer',
          'Silam',
          'Boroikandi',
          'Choker Bazar',
          'North East Medical College & Hospital',
          'Gutatikar',
        ],
      },
      {
        name: 'Fenchuganj',
        postCode: '3116',
        areas: [
          'Daudpur',
          'Mogla Bazar',
          'Lala Bazar',
          'Fenchuganj',
          'Fenchuganj SarKarkhana',
          'Maijgaon',
          'Gilachhara',
        ],
      },
      {
        name: 'Bishwanath',
        postCode: '3130',
        areas: [
          'Roshidpur',
          'Dashghar',
          'Deokalas',
          'Doulathpur',
          'Singer kanch',
        ],
      },
      {
        name: 'Gowainghat',
        postCode: '3150',
        areas: ['Goinghat Bazar', 'Saraighat', 'Jaflong', 'Mamar Dukan'],
      },
      {
        name: 'Golapganj',
        postCode: '3160',
        areas: [
          'Chandanpur',
          'Dakkhin Bhadashore',
          'Bhadashore',
          'Gopalgannj',
          'Rajagonj',
          'Ranaping',
          'Ramda',
          'Barokut',
          'Dhakashaksin',
          'Hetimgonj',
        ],
      },
      {
        name: 'Jaintiapur',
        postCode: '3156',
        areas: [
          'Jaintiapur',
          'Charikata',
          'Chiknagul',
          'Darbast',
          'Nijpat',
          'Fatehpur',
        ],
      },
      {
        name: 'Companyganj',
        postCode: '3140',
        areas: [
          'Bholaganj',
          'Islampur Paschim',
          'Islampur Purba',
          'Ishakalas',
          'Telikhal',
          'Ranikhai Uttar',
          'Ranikhai Dakshin',
        ],
      },
      {
        name: 'Balaganj',
        postCode: '3120',
        areas: [
          'Bualjur Bazar',
          'Begumpur',
          'Brahman Shashon',
          'Gaharpur',
          'Goala Bazar',
          'Karua',
          'Kathal Khair',
          'Notun Bazar',
          'Omarpur',
          'Tajpur',
          'Dayamir',
          'Dewan Bazar',
          'Paschim Gauripur',
          'Paschim Pailanpur',
          'Purba Gauripur',
          'Purba Pailanpur',
          'Burunga',
          'Boaljur Bazar',
          'Sadipur',
        ],
      },
      {
        name: 'Beanibazar',
        postCode: '3170',
        areas: [
          'College road',
          'Churkai',
          'Jaldup',
          'Dubag',
          'Kurar bazar',
          'Mathiura',
          'Kakordia',
          'Kzir bazar',
          'Ramda Bazar',
          'Boiragi Bazar',
          'Sheola',
          'Thanna Bazar',
          'Baroigram',
          'Saropar',
          'Tilpara',
          'Mullagram',
          'Nidonpur',
          'Choria',
          'Sutarkandi',
          'Khosba Timukhi',
          'Supatola',
          'Lauta Bazar',
          'Joldup Bazar',
          'Joldup Womens College',
          'Office Bazar',
          'Kashapandit Para',
        ],
      },
      { name: 'Osmani Nagar', postCode: '3175', areas: [] },
      {
        name: 'Kanaighat',
        postCode: '3180',
        areas: [
          'Jhingrabari',
          'Dakshin Banigram',
          'Paschim Dighirpar',
          'Paschim Lakshmi Prasad',
          'Purba Dighirpar',
          'Purba Lakshmi Prasad',
          'Bara Chatul',
          'Rajaganj',
          'Chatulbazar',
          'Gachbari',
          'Manikganj',
        ],
      },
      {
        name: 'Zakiganj',
        postCode: '3190',
        areas: [
          'Khas Kanakpur',
          'Kholachhara',
          'Zakiganj',
          'Bara Thakuri',
          'Barahal',
          'Birasree',
          'Manikpur',
          'Sultanpur',
          'Atgram',
          'Shah Goli Bazar',
          'Kaligonj',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Bogra',
    zones: [
      {
        name: 'Bogra Sadar',
        postCode: '5801',
        areas: [
          'Jhawtola',
          'Borogola Mor',
          'New Market',
          'Tin porti',
          'Raza Bazar',
          'Rannar Plaza',
          'Parti Office',
          'Jaleshwaritola',
          'Yeakubia mor',
          'Mofizpaglar mor',
          'Judge court',
          'DC banglar mor',
          'Rahaman nagor',
          'Jabbar club',
          'Malotinagor',
          'Boksibazar',
          'Ms Club math',
          'Kalitola',
          'Jamil Shoping Mahal',
          'Kornetion School',
          'Kalitola bazar',
          'Big Bazar',
          'Shibbati',
          'Bindabon para',
          'Bisic',
          'Charmatha',
          'Johurulnagar',
          'Nuranir mor',
          'TTC mor',
          'BETACK',
          'Fokiroddin School',
          'Bus Tarminal',
          'Godar para Bazar',
          'Choto kumira',
          'Erulia',
          'Tinmatha',
          'Kalar bazar',
          'Kamargari',
          'Jamilnagor',
          'Azizul haque college',
          'Puran bogra',
          'Adorsho College',
          'Shohor dighi',
          'Shelimpur',
          'Choto Belayel',
          'Shahid Ziarul Rahman Medicel',
          'Matidali',
          'Joypurpara',
          'Matidali upozila porisad',
          'Matidali Bazar',
          'Manik chok',
          'Noudapara',
          'Momo in Hotel',
          'Tmss Medicel college',
          'Bagopara',
          'Gokul bazar',
          'Baropur radar unit',
          'Bogra Uposhahar',
          'Namazgor',
          'Shultangong para',
          'Kathnar para',
          'Uther mor',
          'Hakir mor',
          'Uposhohar Bazar',
          'Olir Bazar',
          'Baropur rod',
          'Chelopara',
          'Boubazar',
          'Kazibari mor',
          'Koipara',
          'Nataipara',
          'Naruli',
          'Bogra College',
          'Shabgram',
          'PTI Mor',
          'Thonthonia',
          'Colony Bazar',
          'Tecnical',
          'RAB 12',
          'Bonany Bazar',
          'Betgari',
          'Khandar',
          'CA Office',
          'Mothsho Office',
          'Malgram',
          'Fulltola',
          'Fulldighi',
          'Jahangirabad Canttoman',
          'Shakpala',
          'Ranirhat',
          'Primary Training Institute (P.T.I) More',
          'Gohail road',
          'Sobujbag-Bogra Sadar',
          'Saousgari Palpara',
          'Sutrapur',
          'Badurtola-Bogra Sadar',
          'Chalk Sutrapur',
          'Bangladesh Bank Staff Quarter',
          'Dattabari',
          'Mujibur Rahman Mohila College',
          'Phulbari-Bogra Sadar',
          'Brindabon Para',
          'Basic area',
          'Nishindara',
          'Kanajgari',
          'Polytechnical',
          'Shah Sultan College, Banani',
          'Shaheed Ziaur Rahman Medical College',
          'Traffice Office',
        ],
      },
      { name: 'Shajahanpur', postCode: '5801', areas: [] },
      {
        name: 'Shahajanpur',
        postCode: '5801',
        areas: [
          'B- Block',
          'C- Block',
          'Ariabazar',
          'Fuldighi',
          'Ahangirabad Cantonment',
          'Shajahanpur Thana',
          'B Block, C Block, near Majhira Cantorment',
        ],
      },
      {
        name: 'Sherpur',
        postCode: '5840',
        areas: [
          'Palli Akadami',
          'Mohipur Bazar',
          'Dj school',
          'New Market',
          'Thana road',
          'Tauon Colony',
          'Dhunat mor',
          'Sonka',
          'Bogra bazar',
          'S R kemicel',
          'Noymile',
          'Daridoho',
          'Saudia Hotel',
          'Chandaikona',
        ],
      },
      {
        name: 'Sariakandi',
        postCode: '5831',
        areas: [
          'Thana (Shariakandi)',
          'Shariakandi upozila health complex',
          'Shariakandi Hospital',
          'Kalitola',
          'Shariakandi New Market',
          'Fullbari bazar',
          'Degree College',
          'CNG Stant',
          'Sheikh Bari rod',
          'Chandan Baisha-Sariakandi',
        ],
      },
      {
        name: 'Bogra - Gabtoli',
        postCode: '5820',
        areas: [
          'Nepaltoli',
          'Gaptoli College',
          'Nauramala Bazar',
          'Bayguni Bazar',
          'Railway station (Gabtoli)',
          'Upozila health complex',
          'Model Police Station',
          'College road',
          'Adur Bazar road',
          'Sukhanpukur',
        ],
      },
      {
        name: 'Kahalu',
        postCode: '5870',
        areas: [
          'Charmatha',
          'Dorgahat',
          'Malonncha',
          'Koichor',
          'Chanay',
          'Kahalu Railway station',
          'Kahalu Degree College',
          'Model high School',
          'girls High school rod',
          'Upozila Quarter',
        ],
      },
      {
        name: 'Dupchachia',
        postCode: '5880',
        areas: [
          'Dupchachia Thana mor',
          'Dhaperhat',
          'Chahomoni',
          'Sharpukur',
          'Puraton bazar',
          'shekahar',
          'Dupchachia New Market',
          'Naogaon Bus stan',
          'boro bazar',
          'Talora rod',
          'Womens College',
          'Upozila health complex road',
          'mohit atto mil',
          'Dupchachia',
          'Talora',
        ],
      },
      {
        name: 'Adamdighi',
        postCode: '5890',
        areas: [
          'Adomdighi Thana Road',
          'Upozila health complex rod',
          'Muroil',
          'Boro Bridge',
          'Madrasha Mor',
          'Adomdighi Railway station',
          'Adamdighi bazar',
          'Kalai road',
          'Mamun Supermarket',
          'Police Station',
          'Adamdighi',
          'Santahar',
          'Nasharatpur',
        ],
      },
      {
        name: 'Nandigram',
        postCode: '5860',
        areas: [
          'Upozila health complex',
          'Pilot School',
          'Damgara',
          'KundarHat',
          'Umirpur',
          'Alam Supermarket',
          'Nandigram Upozila Park rod',
          'Post office mor',
          'Kaligonge road',
          'Jonota market',
          'Haque super market',
          'Nandigram Bus Stand',
          'Mandir road',
        ],
      },
      {
        name: 'Sonatola',
        postCode: '5826',
        areas: [
          'Brac Office Mor',
          'Sonatola Thana Mor',
          'Sonatola Bazar Mor',
          'Rail gate',
          'Upozilla Road',
          'Madrasha road',
          'Mohila College',
          'Goverment Nazir Akhtar College',
          'Pilot School Road',
          'Fire Station road',
          'Police Station',
          'Kazibari road',
          'Alomdighi Railway station',
          'Bus Stant mor',
          'Bkash care Center',
          'agrani bank',
        ],
      },
      {
        name: 'Shibganj',
        postCode: '5880',
        areas: [
          'Sonali bank',
          'Hospital mor',
          'Nagarbondor mor',
          'Shipgonge Bazar',
          'Amtolir Bazar',
          'Mokamtola Bazar',
          'Upozela Parisad mor',
          'Shibganj Thana',
          'Police Station (Shibganj)',
          'Rupali bank',
          'Police high school',
          'Upozila shishu park',
          'Shibganj Govt. College',
          'Bhaondhon traders',
          'Shibganj Bazar mor',
        ],
      },
      {
        name: 'Dhunat',
        postCode: '5851',
        areas: [
          'Dhunat Borobazar',
          'Police Station (Dhunat)',
          'Razzal Teen stall',
          'Dhunat post office',
          'Khan market',
          'Dhunat hospital stuff quarter',
          'Baypass Mosjid rod',
          'Filling Station',
          'Gosaibari',
        ],
      },
      {
        name: 'Shantaher',
        postCode: '5891',
        areas: [
          'Sonar bangla merket',
          'Houseing colony',
          'Cha bagan',
          'Dhaka road',
          'Eyad colony',
          'Hervy school',
          'Hat kola',
          'Rothbari',
          'Poirosova mor',
          'B.P school',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Chapainawabganj',
    zones: [
      {
        name: 'Chapainawabganj Sadar',
        postCode: '6300',
        areas: [
          'CCDB Mor',
          'Notun jailkhana',
          'Ansar Camp',
          'Lichu Bagan',
          'Octiamor',
          'Shiala colony',
          'Hajirmor',
          'Gonka',
          'Mohanada Bus stand',
          'Rail station',
          'Alinagar High School',
          'Nakrajpar',
          'Dhaka Bus stand',
          'LGD Office',
          'Sorkarmor',
          'Nimgachi',
          'Shantirmor',
          'Chowdhuripara',
          'Ramkrishnopur',
          'Baligram',
          'Shahibag',
          'Pathanpara',
          'Puraton jailkhana mor',
          'Walton mor',
          'Udown mor',
          'Power house Mor',
          'BGB-53 Camp',
          'Hoticulture',
          'Sarkit house mor',
          'Professor para',
          'Sonarmor',
          'Police Fari',
          'DC Market',
          'Chapainawabganj Sadar Medical Mor',
          'Moszidpara',
          'Bosuniapoti',
          'Tawon High School',
          'Exim University',
          'Gabtola mor',
          'New market',
          'Chapainawabganj DC Office',
          'Chapainawabganj Food Office',
          'Nowonshuka',
          'Pikortoala',
          'Notunhat',
          'Dapa para',
          'Chowmohoni',
          'Poladanga',
          'Korim mollar mil',
          'Newbalubagan 1,2,3,4,5 Get',
          'Nimtola',
          'Mistiripara',
          'Batenkhar mor',
          'Chapainawabganj Arambag',
          'Balur mor',
          'Uporaj rampur',
          'Malopara',
          'Boro Gorosthan',
          'Namorajrampur',
          'Hamidulla High School',
          'Dariapur',
          'Chohoditola',
          'Hata',
          'Chapainawabganj Durgapur',
          'Chapainawabganj Miapara',
          'Rehiachor',
          'Adoshomor',
          'Chormohonpur',
          'Chadilai',
          'Tolgor',
          'PTI Master para',
          'Udoi shongo mor',
          'Chapainawabganj Police Line',
          'Foklain mor',
          'Balugram',
          'Chapainawabganj Baliadanga',
          'Atahir',
          'Haitmor',
          'Chataidubi',
          'Debinagor',
          'TTC Mor',
          'Chamagram',
          'Chapainawabganj Laxmipur',
          'Filterhat',
          'Ramchondoropur hat',
          'Gorastand',
          'Malar mor',
          'Boitola',
          'Barogoria',
          'Moharajpur',
          'Noagola',
          'Islampur',
          'Tikrampur',
          'Shibtola',
          'Rajrampur',
          'Horipur',
          'Ajaipur',
          'Balubagan',
          'Boroindara mor',
          'Namosokorbati',
          'Shantibag',
          'Puraton Bazar',
          'Kollanpur',
          'Shantibag',
          'Bottolahat',
          'Hujrapur',
          'Belepur',
          'Alinagar',
          'Bisho road',
          'Rajarampur',
          'Amnura',
        ],
      },
      {
        name: 'Sibganj',
        postCode: '6341',
        areas: [
          'Holmor',
          'Ranihat Collage',
          'Women Collage',
          'Master para',
          'Court bazar',
          'Karbalmor',
          'Piling mor',
          'Israilmor',
          'Sibganj Bus Stand',
          'Sibganj Medical Mor',
          'Biddyut Office',
          'Binodpur',
          'Adina College',
          'Khaserhat Sibganj',
          'Kalopur',
          'Chokkirti',
          'Kanshat Bazar',
          'Golapbazar',
          'Abbas bazar',
          'Sonamoshjid',
          'Amm Bazar',
          'Monakosa',
          'Kansat',
          'Sibganj Bazar',
          'Ranihat',
        ],
      },
      {
        name: 'Gomastapur',
        postCode: '',
        areas: [
          'Kholsi',
          'Prosadpur',
          'Chodala',
          'Gomastapur College mor',
          'Boro Bazar',
          'Nungoal Bus Stand',
          'Medical Mor Gomastapur',
          'Khohar mor',
          'Koloni',
          'Rail station',
          'Rohomotpara',
          'Dakbangla',
          'Tetul mor',
          'Adda',
          'Fatehpur',
          'Mohipur',
          'Chapai Polasa',
          'Gobratolla BGB 59',
          'Rohonpur',
          'Mollikpur',
          'Gomastapur Bazar',
          'Rohanpur',
          'Gomastapur',
        ],
      },
      {
        name: 'Nachole',
        postCode: '6310',
        areas: [
          'Nachole Police station',
          'Nachole Collage mor',
          'Master para',
          'Rail station',
          'Muradpur',
          'Hatbakoil',
          'Hamidpur',
          'Fire service',
          'Jonakipara',
          'Jilimbazar',
          'Dinagar',
          'jamtola',
          'Verendi',
          'Bholamor',
          'Dansura',
          'Nezmpur',
          'Amnura',
          'Nachole Bazar',
        ],
      },
      {
        name: 'Bholahat',
        postCode: '6330',
        areas: ['Bus Stand', 'Collage mor', 'Musri buja', 'Bollia', 'Doldoli'],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Joypurhat',
    zones: [
      {
        name: 'Joypurhat Sadar',
        postCode: '5900',
        areas: [
          'BITC Mor',
          'Sahebpara',
          'Bulupara',
          'Gulshan Mor',
          'Mission Mor',
          'Khanjanpur High School',
          "Girl's Cadet College",
          'Thana Road Joypurhat Sadar',
          'New Market Joypurhat Sadar',
          'Sugar Mill Road',
          'Station Road',
          'Joypurhat Station',
          'Shantinagar',
          'Joypurhat Govt. College',
          'Sagarpara',
          'Sakhidarpara',
          'Adorshopara',
          'Teghor',
          'Dewanpara',
          'Notun Hat',
          'Sordarpara',
          'Shekhpara',
          'Janiar Bagan',
          'Professor para',
          'Madrashapara',
          'Bongobondhu Road',
          'Hazi Badar Uddin Raod',
          'Boiragir Mor',
          'Abul Kashem Moydan',
          'Pachur Mor',
          'Arafat Nagar',
          'Chamra Gudam',
          'DC Office Joypurhat Sadar',
          'Circuit House Joypurhat Sadar',
          'SP Office',
          'Sadar Hospital Joypurhat Sadar',
          'Bogra Bus Stand',
          'Housing State Joypurhat Sadar',
          'Patarpara',
          'Dayabetic Hospital Mor',
          'Bagichapara',
          'Joypurhat Polli Budyut Somiti',
          'LGED Office',
          'Debipur Joypurhat Sadar',
          'Amtoli',
          'Sobujnagar',
          'Shahid Zia College',
          'Aramnagar',
          'Iraknagar',
          'Biswaspara',
          'Kashiabari',
          'BGB Camp',
          'RAB Camp',
          'Tajurmor',
          'Joypurhat Stadium',
          'Joypurhat Pourosova',
          'Chitrapara',
          'Teghor High School',
          'Tatipara',
          'Shishu Uddan',
          'Dhanmondi Joypurhat Sadar',
        ],
      },
      {
        name: 'Panchbibi',
        postCode: '5910',
        areas: ['Tin Matha Mor', 'Pach Matha Mor', 'Railway Station Panchbibi'],
      },
      {
        name: 'Kalai',
        postCode: '5930',
        areas: [
          'Bottoli Bazar',
          'Kalai Upazila Gate',
          'Kalai Bazar',
          'Nishchinta Bazar',
        ],
      },
      {
        name: 'Khetlal',
        postCode: '5920',
        areas: [
          'Khetlal Upazila Porishad',
          'Rupali Bank Mor',
          'Kacharihat',
          'Alampur Khetlal',
          'Khetlal Pilot High School',
        ],
      },
      {
        name: 'Akkelpur',
        postCode: '5940',
        areas: [
          'Akkelpur Railway Station',
          'Akkelpur Filling Station',
          'Sonali Bank Mor',
          'Ferdaous Plaza',
          'Station Market',
          'Hazipara Akkelpur',
          'Tilakpur',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Naogaon',
    zones: [
      {
        name: 'Naogaon sadar',
        postCode: '6500',
        areas: [
          'Cornetion para',
          'K . D mor',
          'P.T.I mor',
          'Noyazoyan math',
          'Mondal para',
          'A.T.M math',
          'Anamuler mor',
          'Bulur math',
          'Sohiduler mor',
          'Chakdeb para',
          'Kajir pukur',
          'Shorisahatir mor',
          'Doctor para',
          'Torofdar para',
          'Post office para',
          'Tennis club',
          'New merket',
          'Biyam school',
          'Batar mor',
          'Puraton bustand',
          'Brij mor',
          'Boi potti',
          'Puraton hastapan',
          'Sonar potti',
          'Tula potti',
          'Kapor potti',
          'Gostho hatir mor',
          'Churi potti',
          'Nobir doctor mor',
          'Khas naogaon',
          'Eidor botli mor',
          'Dab potti',
          'Mirda para',
          'Khalitola mor',
          'Binapanir mor',
          'Vumi office',
          'Shaban factory',
          'Patalir mor',
          'Naeit para',
          'Doptori para',
          'Bonai para',
          'Gosh para',
          'Poly technic mor',
          'Lata para',
          'Arji naogaon',
          'Chokproshad',
          'Shahanabag city',
          'Somobai mor',
          'Shahi morjid',
          'Anondo nagaor',
          'Dubolhati',
          'Taltoli',
          'Per naogaon',
          'Tajer mor',
          'Sultanpur',
          'Dhaka bustand',
          'Dopa para',
          'Kaji para',
          'B.G.B cump',
          'Rojakpur bou bazar',
          'Khataltoli',
          'Kajir mor',
          'Food godown',
          'Ukil para',
          'Uttra school',
          'Press club',
          'Sheba shroom',
          'Shahin school',
          'Bihari coloni',
          'Ukil para suice get',
          'Rubir mor',
          'Doyaler mor',
          'Chokayanet school',
          'Bou bazar',
          'Hero town',
          'Shomer doctorer goli',
          'Malik shumiti office',
          'North bengel',
          'Bokul tolar mor',
          'Haspatal mor',
          'Mohila hostel mor',
          'Vocetional college mor',
          'Jollil er school',
          'Bangabarira',
          'Kumaigari',
          'Bai pas brij',
          'Degree mor',
          'T.T.C office',
          'Shoukir alir mor',
          'Monshi para',
          'C.O office',
          'Chokbariya',
          'L.G.D office',
          'Thanar mor',
          'Judge cuort',
          'Jail khanar mor',
          'Police line',
          'Chokbikram',
          'Bisik',
          'Doctor er mor',
          'Jobar mor',
          'Baludhanga bustand',
        ],
      },
      {
        name: 'Atrai',
        postCode: '6550',
        areas: [
          'haspatal mor',
          'College mor',
          'Upazila porisod',
          'Mirjapur bazar',
          'Rail station',
          'Ahosangang',
          'Ahsanganj',
          'Bandai',
        ],
      },
      {
        name: 'Raninagaor',
        postCode: '6590',
        areas: [
          'B N P er mor',
          'Rail station',
          'College mor',
          'Hospital mor',
          'Upozilla porisod',
          'Kujail bazar',
          'Betgari bazar',
          'Raninagaor bajar',
          'Vandargram',
          'Kashimpur',
        ],
      },
      {
        name: 'Manda',
        postCode: '6511',
        areas: [
          'Medical mor',
          'Thanar mor (Manda)',
          'Upozilla porisod',
          'College mor',
          'Shoti hat',
          'Bolihar Rajbari',
          'Manda ferigat',
          'Prasadpur',
          'Balihar',
        ],
      },
      {
        name: 'Niyamatpur',
        postCode: '6520',
        areas: [
          'College mor (Niyamatpur)',
          'Upozilla porisod (Niyamatpur)',
          'Gostho hatir mor',
          'Hospital mor (Niyamatpur)',
          'Niyamatpur bustand',
          'Niyamatpur bazar',
        ],
      },
      {
        name: 'Mohadebpur',
        postCode: '6530',
        areas: [
          'Bok chortor',
          'Thanar mor - Mohadebpur',
          'Poirosova mor',
          'Mohadebpur Bazar',
          'Healthy complex mor',
          'Upozilla complex',
          'Lohatar mor',
          'Mohadebpur bustand',
        ],
      },
      {
        name: 'Potnitola',
        postCode: '6540',
        areas: [
          'Morjid Market',
          'Upozilla porisod (Potnilota)',
          'Thanar mor (Potnitola)',
          'Hospital mor',
          'Modail bazar',
          'Nazipur bazar',
          'Nazipur bustand',
          'Tuknni para',
          'B.G.B camp',
          'Nazpur govt gorostan',
          'Madrsha para',
          'Mohila college mor',
          'Puran bazar',
          'Notun hat',
        ],
      },
      {
        name: 'Sapaher',
        postCode: '6540',
        areas: [
          'Sapaher bazar',
          'Thanar mor Sapaher',
          'Sapaher Master para',
          'Upzilla porisod',
          'Hospital mor',
          'New merket',
          'Zero point',
          'Moduhil',
        ],
      },
      {
        name: 'Porsha',
        postCode: '6570',
        areas: [
          'Nitpur bustand',
          'Upozilla porisod',
          'Thanar mor Porsha',
          'Hospital mor',
          'Shisa bazar',
          'Zero point Porsha',
          'Porsha bustand',
          'Nitpur',
          'Panguria',
        ],
      },
      {
        name: 'Dhamairhat',
        postCode: '6580',
        areas: [
          'Thanar mor Dhamairhat',
          'Healthy complex',
          'Upozilla porisod',
          'Amaitara mor',
          'Zero point Dhamairhat',
          'College mor',
          'Dhamairhat bustand',
        ],
      },
      {
        name: 'Bodolgaci',
        postCode: '6500',
        areas: [
          'Hospital mor Bodolgaci',
          'Mohila college get',
          'Bodolgaci bazar',
          'Upozilla get Bodolgaci',
          'Mataji hat',
          'Kolavander pur',
          'Paharpur bazar',
          'Khirtipur bazar',
          'Bodolgaci bustand',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Natore',
    zones: [
      {
        name: 'Natore Sadar',
        postCode: '6400',
        areas: [
          'Dukhan Patuya para',
          'Kaloor Mor',
          'Dom para Mart',
          'Upor Bajar',
          'Stadium Natore Sadar',
          'jhowtala',
          'Horticulture',
          'Judge court',
          'Full bagan',
          'Uppo jila',
          'Heliport mart',
          'Sharukh Paribahon',
          'Horish pur Baipass',
          'Chakram Pur',
          'Kamar para',
          'Passport office',
          'Police line',
          'Mission Hospital',
          'Mulla Bazar',
          'Jail khana',
          'Chairman Road',
          'Tecnical College',
          'Khanai Khali',
          'Chaudhuri para',
          'Lal Bazar',
          'Kali bari',
          'Pilkhana',
          'Bhanga Masjid',
          'Sahara plaza',
          'Notun thana',
          'Kandi vhita',
          'PTI',
          'Sadar Hospital',
          'Joy Bangla mor',
          'Ghos para',
          'Sonapatti',
          'walton plaza',
          'Chawal putti',
          'Masjid market',
          'Nicha bazar',
          'Traffic more',
          'sayabani mor',
          'bater goli',
          'kaporiya potti',
          'muslim institue',
          'kacha bazer',
          'poroshova',
          'matani hospital',
          'Alipure',
          'mirepara',
          'palpara',
          'puraton jinna school',
          'girls school',
          'upposhor',
          'alipure primary school',
          'dhopapara',
          'Hafrastha',
          'banubakari',
          'boys school',
          'hafrasta more',
          'hossan hajir bari',
          'taltola hafrastha',
          'Uttor boroghacha',
          'jolar par uttor',
          'jolar par passim',
          'college mojid',
          'boroghacha boro mor',
          'boubazer',
          'Dokkin boroghacga',
          'NS colleage',
          'buradorgha mojid',
          'tabariya',
          'modumiyar am bagan',
          'rouson chudhurir am bagan',
          'kalibari mondir',
          'tabariya hat',
          'Station bazer',
          'Pump',
          'new noion hotel',
          'hotel prince',
          'station mojid',
          'rozi market',
          'ser potti',
          'rail gate',
          'Gur potti',
          'chok badana',
          'chamrapotti',
          'patorkuchi math',
          'Station baipus',
          'belghoriya mor',
          'Bypass',
          'Bonbelghoriya high school',
          'care medical',
          'hassari',
          'mohila college',
          'Yatim khana',
          'suvaccha clinic',
          'Suger mill',
          'city college',
          'mode factory',
          'gm vhoben',
          'sugermill collony',
          'suger mill school',
          'bihari bara',
          'sha para',
          'chatni',
          'Dhigapottiya union',
          'digapotiya bazer',
          'digapotiya college',
          'Halsha cng stand',
          'Lokkipur union',
          'lokkipur bazer',
          'hobit pur',
          'tokiya',
          'akdala',
          'chanpur',
          'matiyapara',
          'pran gate',
          'dhalan',
          'Baiddyabal Gharia',
          'Lalpur Bazar',
          'Madhnagar',
        ],
      },
      {
        name: 'Singra',
        postCode: '6450',
        areas: [
          'kalam',
          'hatiandha',
          'Khejur tola',
          'Sher kul bus stand',
          'Sher kul jame mosjid',
          'Sher kul boro nodi',
          'Singra bazar',
          'Dhom dhoma school',
          'Pouroshova',
          'Singra Bus stand',
          'Joy Bangla mor',
          'college mojid',
          'Shasto complex',
        ],
      },
      {
        name: 'Bagatipara',
        postCode: '',
        areas: [
          'jam nagar',
          'Model mosjid',
          'college gate',
          'Food park',
          'shasto complex',
          'tamaltola',
          'Fire service mor',
          'Bagan bari',
          'Nodir par',
          'Mastan mor',
          'Hindu para',
          'Bonpara',
          'Bonpara Bypas',
          'Bonpara Purshova',
          'Bonpara College',
          'Bonpara Pollibiddut',
          'Qadiradat Cantonment',
          'BUAET',
          'Qadirabad Bazar',
          'Doyarampur',
          'Sonapur',
          'Doyarampur Heath Cmplex',
          'Dhubol Bazar',
          'Walton plaza',
          'Shena Fastood',
          'Malonchi Bazar',
          'Malonchi Station',
        ],
      },
      {
        name: 'Baraigram',
        postCode: '6432',
        areas: [
          'fire service',
          'Rajjak mor',
          'Manik pur',
          'Pouroshova mor',
          'Cng stand',
          'Shasto complex',
          'College gate',
          'Mukhora',
          'Model school',
          'Boro mor',
          'Walton plaza',
          'Harua',
          'Dayarampur',
          'Baraigram',
        ],
      },
      {
        name: 'Gurdaspur',
        postCode: '6420',
        areas: [
          'Bus stand Gurdaspur',
          'Muktijodhs office',
          'Shastocomplex',
          'Pouroshova mor',
          'Chaskor bazar',
          'Model school',
          'Misti bazar',
          'Sonar potti',
          'Gosh hati',
          'Najirpur bazar',
          'lichu hata',
          'Gosh misti bari',
          'Shosan ghat',
          'Hindu para',
          'Godam para',
          'Purbo para',
          'Dokkan ghor',
          'School marth',
          'Hatgurudaspur',
        ],
      },
      {
        name: 'Naldanga - Natore',
        postCode: '6432',
        areas: ['Patul', 'Naldanga bazer', 'Khula bariya'],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Pabna',
    zones: [
      {
        name: 'Pabna Sadar',
        postCode: '6600',
        areas: [
          'Singa primery school',
          'Boroitola',
          'Rainbow school',
          'Esamomti school',
          'Hamid master ar goli',
          'Jamai para',
          'Naraionpur',
          'Edward college main gate',
          'Rodhghor',
          'Jora kobbor',
          'Poilanpur',
          'Alia Madrasha',
          'Pabna decoretor',
          'Power house para',
          'Sobeda bagan',
          'Choncol garej',
          'Delivery hospital',
          'Gobinda',
          'Rupkotha goli',
          'Adorsho school',
          'RM academi',
          'Boro bridge',
          'Central Girls School',
          'Kosai potti',
          'Joy kali mondir',
          'AR cornar',
          'Indara mor',
          'Khea Ghat',
          'Thana mor',
          'Maligoli school',
          'Square pharmecy',
          'Khaddo gudam',
          'Jubli tank',
          'Sona potti',
          'Pabna college',
          'Bangladesh Eidgah',
          'Mujahed club',
          'Square School',
          'Puraton atimkhana',
          'Peradise mor',
          'Math para',
          'Manama tower',
          'Golam Kader ar bari',
          'Puraton BRTC',
          'Samoli garej',
          'Meril bypass',
          'Sordar para',
          'Meril get',
          'Loskorpur',
          'Homeo college',
          'Caton ar mor',
          'Sadar hospital rood',
          'Anowara clinic',
          'English rood',
          'Golapbag',
          'TB hospital',
          'Singa bazaar',
          'Nurpur',
          'Berak mor',
          'Esha ticket counter',
          'Moktob mor',
          'Balia halot',
          'Gacpara',
          'Upozilla mor',
          'Pacmatha mor',
          'Dutch Bangla mor',
          'Post office mor',
          'SA paribahan',
          'Jugi para',
          'Mojumdar para',
          'Bus terminal Pabna Sadar',
          'Monsurabad',
          'Mohendropur',
          'TTC',
          'Jubo unnoyon',
          'Pabna science & technology universtiy',
          'Eye Hospiter',
          'Bangla clinic',
          'Moes ar depu',
          'Panir tank',
          'Metu clinic',
          'Ononto',
          'Arifpur',
          'Goribulla',
          'Arifpur Kobosthan',
          'Razar bot tola',
          'Hajir hat',
          'Bete para',
          'Dohar para',
          'Dipcor',
          'Imam Gajjali school',
          'Shibrampur',
          'Jor Bangla',
          'Bangla Bazar',
          'Technical mor',
          'Zilla school',
          'Mohela college',
          'Boro Bazaar',
          'Caoul potti',
          'Doi bazaar mor',
          'Caki bari',
          'Car Mathar mor',
          'Pathor tola',
          'Dilalapur',
          'Rofik school',
          'Kalachad para',
          'Sofik hospital',
          'Kofiluddin para',
          'Court',
          'Polithin rood',
          'City college',
          'Ghos para',
          'Pourosovha Pabna Sadar',
          'DC office',
          'Kachari para',
          'Mondol para',
          'Jelkhana',
          'Maita sorok',
          'Chadmari',
          'Sarkit house',
          'DC ar bari',
          'Mosarofer goli',
          'Kamiler goli',
          'Cadakhar bastola',
          'Library Bazaar',
          'Sadhu para',
          'Mental Hospital',
          'Bisic',
          'Kasipur',
          'Nazirpur',
          'Tikori bazeer',
          'Dapunia',
          'Hemaetpur',
          'Corghospur',
          'Kaliko Cotton Mills',
          'Hamayetpur',
          'Aminpur',
        ],
      },
      {
        name: 'Ishwardi',
        postCode: '6623',
        areas: [
          'Post office mor',
          'Sirsha rood',
          'Bazaar',
          'Beltola',
          'Alubag',
          'Boroichora',
          'College road Ishwardi',
          'Hospital road',
          'Rail gate Ishwardi',
          'Chadmari mor',
          'Azim tola',
          'Sadhu para',
          'Green city 1 number',
          'Green city 2 number',
          'Green city 3 number',
          'Green city Notun Hat',
          'Green city Simul tola',
          'Green city- Manik nogor',
          'Green city- Joy nogor',
          'Green city- Alhazmor',
          'Green city- Solimpur',
          'Ruppur- EPZ',
          'Bicuttola',
          'Jigatola',
          'Ruppur mor',
          'Pathsola mor',
          'Dasuria',
          'Sokher Bazaar',
          'College rood Ishwardi',
          'Doutch Bangla',
          'Kalikapur',
          'Mir kamari',
          'Dhapari',
          'Pakshi',
          'Rajapur-Ishwardi',
        ],
      },
      {
        name: 'Tebunia',
        postCode: '',
        areas: ['Tebunia Bazaar', 'Maligaca', 'Monohorpur'],
      },
      {
        name: 'Atghoria',
        postCode: '6610',
        areas: [
          'Thana mor Atghoria',
          'Upozilla complex',
          'Deuttor Bazzar',
          'Debottar',
        ],
      },
      {
        name: 'Chatmohor',
        postCode: '6630',
        areas: [
          'Rail station Chatmohor',
          'Bhai bhai narsari',
          'Semultoli',
          'Mulgram',
          'Thana mor Chatmohor',
          'Dardiz mor',
          'Nordan mor',
          'Chatmohor Bus stand',
          'Zero point Chatmohor',
          'Narikel bari',
        ],
      },
      {
        name: 'Bhangura',
        postCode: '6640',
        areas: [
          'Hospital rood',
          'Kalibari Bhangura',
          'Masterpara',
          'Muktomonch',
          'Bhangura high school',
          'Sorotnogor',
          'Boral bridge',
          'Sorotnogor bazaar',
          'Agroni bank',
          'Haji Jmal uddin college',
          'Upozilla Porisodh Bhangura',
        ],
      },
      {
        name: 'Faridpur',
        postCode: '6650',
        areas: [
          'Thana mor Faridpur',
          'Thana para Faridpur',
          'Polli biddut office',
          'Bonainogor Bazaar',
          'Muktomonch',
          'Gopalpur',
          'Faridpur Hospital',
          'CB Pailot school',
          'Razbari',
          'Banwarinagar',
        ],
      },
      {
        name: 'Sujanogor',
        postCode: '6660',
        areas: ['Tarabari', 'Dublia', 'Pourosovha'],
      },
      {
        name: 'Sathia',
        postCode: '6670',
        areas: ['Simultoli Bazaar', 'Baolmari', 'Jorgaca', 'Pilot school'],
      },
      {
        name: 'Bera',
        postCode: '6680',
        areas: [
          'Cnb Bazaar',
          'Alhera nogor',
          'Notun para',
          'Bogura bus stand',
          'Nakalia',
          'Kashinathpur',
          'Puran Bharenga',
        ],
      },
      {
        name: 'Kasinathpur',
        postCode: '6682',
        areas: ['Police box', 'Birampur', 'Cinakhora', 'Cobbis mile'],
      },
      {
        name: 'Ataikula',
        postCode: '6600',
        areas: ['Square marker', 'Madpur', 'Bongram', 'Dhulai'],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Rajshahi',
    zones: [
      {
        name: 'Rajshahi Sadar (Boalia)',
        postCode: '6000',
        areas: [
          'Fudki Para',
          'Monichattar',
          'Mia para',
          'Shonadighi Mor',
          'Gonok Para',
          'Tulapotti',
          'Reshompotti',
          'Shornokarpotti',
          'Borokuthi',
          'Kumarpara',
          'Boalia Thanar Mor',
          'Masterpara',
          'Padma Garden',
          'Chalpotti',
          'RDA Market',
          'Shomobay Market',
          'New Market',
          'Gorhanga',
          'Omer Plaza',
          'Railget',
          'Shosthitola',
          'Malopara',
          'Mohila College',
          'Alokar More',
          'Ranibazar',
          'Tiles Potti',
          'Munshidanga',
          'Greater Road',
          'Nogor Bhaban',
          'Kadirganj',
          'Jadughar Mor',
          'Rajshahi College',
          'Hetem Khan',
          'Shobji Para',
          'City College',
          'Rajarhata',
          'Dorgapara',
          'Bohumukhi Girls School',
          'Pathan Para',
          'Shipaipara',
          'Fire service More',
          'Betpotti',
          'Ghoshpara',
          'Muktomancha',
          'BGB Shimanto Obokash',
          'Alupotti',
          'Khanshamar Chock',
          'Ghoramara',
          'Post office',
          'Barendra College',
          'Sagor Para',
          'Orchid Chatri Nibash',
          'Bosh Para',
          'Ponchoboti',
          'Ahmedpur',
          'Ramchandrapur',
          'Sagorpara Bot tola',
          'Beldar para',
          'Sutanabad Masjeed',
          'Sultanabad',
          'Master para',
          'Shiroil',
          'Doshor Mondoler More',
          'Sheroil Kacha bazar',
          'Modhpukur',
          'Shantibag',
          'Tiakapra',
          'Mirerchak',
          'Baliapukur',
          'Baliapukur Chotobot tola',
          'Balia Pukur Boro Bot Tola',
          'Dosh Talar More',
          '27 No Word Councilor er Mor',
          'Biddaniketon School',
          'Uporvodra',
          'Shadhur Mor',
          'Khulipara',
          'Bashar Road',
          'Bihari Para',
          'Terokhadia Stadium',
          'City Convension',
          'New Bilshimpla',
          'Dabtola',
          'Terokhadia Poshchimpara',
          'Uttorpara',
          'College para',
          'Koshaiyer Mor',
          'Terokhadia Kachabazar',
          'Colonel Zahid Gate',
          'Malda Coloni',
          'Chistian Para',
          'Modhurdanga',
          'Bou Bazar',
          'Uposhohor',
          'Bornali',
          'Uttora Clinic',
          'Nur Masjeed',
          'Sector - 1',
          'Sector - 2',
          'Sector - 3',
          'Uposhohor New Market',
          'Cannotment Main Gate',
          'Uposhohor Boro Masjeed',
          'Markas Masjeed',
          'Eid Gha Math',
          'Panir Tanki',
          'Kamruzzaman College',
          'Dorikharbona',
          'Poshu Hospital',
          'Satelight School',
          'Sopura',
          'Bisic',
          'Kath mil',
          'Shoyghati Masjeed',
          'Mach Factory',
          'TTC',
          'Shopura Silk Mor',
          'Ghuramara',
          'Rajshahi City',
          'Rajshahi Court',
          'Lalitganj',
          'Rajshahi Sugar Mills',
          'Shyampur',
          'Khodmohanpur',
        ],
      },
      {
        name: 'Rajpara',
        postCode: '6281',
        areas: [
          'Laxmipur More',
          'gpo',
          'Laxmipur Kachabazar',
          'Popular',
          'Medipath',
          'CNB',
          'Puraton DB Office er Goli',
          '10 Tola',
          'Nanking Bazar',
          'Cirkit House',
          'Zomzom Hospital',
          'Bangladesh Bank',
          'Radio Center',
          'Jail Khana',
          'Chiriakhana',
          'Chondipur Ghoshpara',
          'New Govt Degree College',
          'Rajpara Thana',
          'Mission Hospital',
          'Index Plaza',
          'Vatapara',
          'Jhaotola',
          'Mithurr More',
          'Kader Mondoler More',
          'Apple Decoretor er Mor',
          'Mohishbathan',
          'Chondipur',
          'Shufianer Mor',
          'Helenabad School',
          'Rajpara',
          'DIG Office',
          'Pollige Line',
          'PTI',
          'Veripara',
          'T Badth',
          'Srirampur',
          'DC Office',
          'DC Bashbhaban',
          'Porjoton',
          'Betiapara',
          'Court Bazar',
          'Horogram Bazar',
          'Dhalur More',
          'Court New Market',
          'Court Station',
          'Court Station Bypus',
          'Mollapara',
          'Mohishbathan Uttorpara',
          'Tultuli Para',
          'Ghora chattar',
          'Bondhogate',
          'Dashpukur',
          'City Bypus Mor',
          'Dingadoba',
          'Bohorompur',
          'Mohila Complex',
          'Nimtolaa',
          'Ahintola',
          'Kashiadanga Bypus',
          'Kathalbaria',
          'Raipara',
          'RAB - 5',
          'I Badh',
          'Guripara',
          'Charkotha Mor',
          'Under Kotha',
          'Horipur',
          'Al Amin er Mor',
        ],
      },
      {
        name: 'Shah Mokdum',
        postCode: '',
        areas: [
          'Polly technique',
          'Shalbagan',
          'Powerhouse',
          'Asam Coloni',
          'Professor para',
          'Shah Mokdum Thanar Mor',
          'Baro Rastar More',
          'Shujanogor',
          'Shilpi Para',
          'Bonolota Abashik',
          'Shah Mokdum Abashik',
          'Krishi Unnoyon Bank',
          'Paba Notun Para',
          'Naodapara Bazar',
          'Zia park',
          'Chayanir Abashik',
          'Rai Para',
          'Commercial College',
          'Mojila Pollytechnique',
          'Aam Chattar',
          'Omor Pur',
          'Kalur More',
          'Mohila Madrasha',
          'Macher Arot',
          'Islami Medical College',
          'Noudapara Bus Stand',
          'BRTA Office',
          'Kismot Petrol Pump',
          'Vugroil',
          'City Hut',
          'Tractor Mor',
        ],
      },
      {
        name: 'Paba',
        postCode: '',
        areas: [
          'Bhola bari',
          'Baroi Para',
          'Darusha',
          'Bishur Mor',
          'Lili Hall',
          'Etim Khana',
          'Nawahata Bazar',
          'Airport',
          'College Mor',
          'Bridge Ghat',
          'Pillapara',
          'Shahapara',
          'Ansar Camp',
        ],
      },
      {
        name: 'Mohonpur',
        postCode: '6220',
        areas: [
          'Bidirpur',
          'Trimohoni',
          'Ghopaghata bazar',
          'Shah Shaji Para',
          'Bakroil',
          'Kuthibari',
          'Shaipara',
          'Keshorhat',
        ],
      },
      { name: 'Tanore', postCode: '6230', areas: ['Kaliganj', 'Nice Garden'] },
      {
        name: 'Bagmara',
        postCode: '6250',
        areas: [
          'Bhabaiganj New market',
          'Bhabaniganj upozila',
          'Bhabaniganj Busstand',
          'Bangabandu Jadughar',
          'Godown er More',
          'Bagmara',
          'Bagramara Bazar',
          'Mochmoil Bazar',
          'Bagmara Thana',
          'Mohonganj',
          'Madariganj',
          'Upuzilla heath complex',
          'Kaiganj',
          'Daukandi',
          'Taherpur',
          'Therpur Bazar',
          'Piajhata bazar',
          'Taherpur Bridge',
          'College Mor',
          'Tin Potti',
          'Taherpur Bus Stand',
          'Bhabaniganj',
          'Taharpur',
        ],
      },
      {
        name: 'Durgapur',
        postCode: '6240',
        areas: [
          'Durgapur hardinje Bridge',
          'Upozila Complex',
          'Durgapur Bazar',
          'Kanpara bazar',
          'Hat godagari',
          'Hat Ramchandrapur',
        ],
      },
      {
        name: 'Motihar',
        postCode: '',
        areas: [
          'Rail Office',
          'Bihari Coloni',
          'Rail Station',
          'Rail bazar',
          'Sheroil Busstand',
          'Hazra Pukur',
          'Razzaker Mor',
          'Bissho Godown',
          'Boro Bongram',
          'Vodra park',
          'Vodra masjeed',
          'Parijat Abashik',
          'Padma Abashik',
          'Hajer Mor',
          'Chandrima',
          'Pentamari',
          'Bangabandhu Deegree college',
          'Barind Medical',
          'Kharakhari Bupus',
          'Meherchandi',
          'Gash Pump Kharkhari',
          'Moslemer More',
          'Ashrafer Mor',
          'Jamalpur',
          'Bank er More',
          'Shah Mokdum Medical',
          'Ramchandrapur',
          'Vodra Busstand',
          'Vodra Railget',
          'Shuvo petrol Pump',
          'Reshom Gobeshona Kendra',
          'Baliapukur Boro bottola',
          'Islampur',
          'Nordan Mor',
          'Munafer More',
          'RUET Pocket Gate',
          'Debising Para',
          'Talaimari Mor',
          'Amana Clinic',
          'kazi Golam Mostofa Road',
          'Shahed Minar',
          'Hadir Mor',
          'Kedur Mor',
          'City Medical',
          'RUET main Gate',
          'RUET',
          'Noyon Petrol Pump',
          'Octer Mor',
          'Jamrul Tola',
          'Motihar Thanar Mor',
          'Bou Bazar',
          'Fultola',
          'Dhorompur Bazar',
          'Alomer More',
          'Amjader More',
          'Mijaner More',
          'Shympur',
          'Mirzapur Pllice Fari',
          'Kazla',
          'Sweet er More',
          'KD club',
          'RU main Gate',
          'Rajshahi University',
          'ZOZOK Tower',
          'Barendra University',
          'RU Station Bazar',
          'Mondoler Mor',
          'Lebubagan',
          'RU 3rd Gate',
          'Tetul Tola',
          'Binodpur bazar',
          'Islamia College',
          'Shalimar',
          'Islam Tower',
          'IBA',
          'BIHASH',
          'Choddopai',
        ],
      },
      {
        name: 'Katakhali',
        postCode: '',
        areas: [
          'Machkata dighi',
          'City Gate',
          'Katakhali Bazar',
          'Horian',
          'Police Fari',
          'Katakhai Jute Mill',
          'NESCO',
          'Obdaa',
          'Horian Suger Mill',
          'Cricket Academy',
          'Baneshwar Bazar',
          'Kapashia',
          'Belpukur',
          'Trafic More',
          'Baneshwar College Gate',
          'Sharda Road',
          'Holdagachi',
          'Sharda Trafic More',
          'Rajshahi Cadet College',
          'Pollice Academi',
          'Mukter Pur',
          'Sharda Bazar',
        ],
      },
      {
        name: 'Charghat',
        postCode: '6270',
        areas: [
          'Polli biddut Mor',
          'Chargat Thana',
          'Charghat Bazar',
          'Charghat Medical',
          'Kakmari Bazar',
          'Pranishompod',
          'Poranpur',
          'Mirganj',
          'Reshompotti Kakramari',
        ],
      },
      {
        name: 'Bagha',
        postCode: '6281',
        areas: [
          'Binodpur bagha',
          'Monigram bazar',
          'Monighram High School Market',
          'Monigram Chatar',
          'Bagha',
          'Bagha Bazar',
          'Banga Bandhu Chattar',
          'Bagha Bus Stand',
          'Bagha CNG Stand',
          'Puraton Bus Stand',
          'Upozila Complex Bagha',
          'Bagha Mazar',
        ],
      },
      {
        name: 'Puthiya',
        postCode: '6260',
        areas: [
          'Puthiya Bazar',
          'Boro masjeed',
          'Dhopa para',
          'Rajbari',
          'Ek ani para',
          'Dudur mor',
          'Pouroshova',
          'Upozila complex',
          'Kolahata',
          'Tarapur',
          'Medical mor',
          'Jholmolia bridge ghat',
          'Jholmolia bazar',
          'Bhulpara',
          'Biraldaha mazar',
          'Biraldaha bazar',
          'Shibpur',
          'Mai para',
          'Vallukgachi',
          'Ujirpara',
          'Arani',
        ],
      },
      {
        name: 'Godagari',
        postCode: '6290',
        areas: [
          'Police Station',
          'Medical Mor',
          'Mosalbari',
          'Hatpara',
          'Upojelaget',
          'Daingpara Mor',
          'Kamar para',
          'Foridpur',
          'Suizget',
          'Baliagata',
          'Kasempur',
          'Loskorhati',
          'Rail bazar',
          'City By Pass',
          'Gopalpurr',
          'Railgate',
          'Kashimpur',
          'Premtoli',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Sirajganj',
    zones: [
      {
        name: 'Sirajganj Sadar',
        postCode: '6700',
        areas: [
          'Podyapukur',
          'Judge court',
          'New Market Sirajganj Sadar',
          'Masumpur',
          'Mahmudpur',
          'Raypur',
          'Rail coloni',
          'Khedon soddar more',
          'Koilapotti',
          'Kazipur More',
          'Soyadhangora',
          'Noyon More',
          'Soddarpara/Diyardhangora',
          'Shialkol bazar',
          'Shohid Munshur Ali Medical college & Hospital',
          'soyadhangora khapara',
          'kolyani',
          'Katherpul',
          'Notun-Vanga Bari',
          'Rahmotganj 1 no. Goli',
          'Rahmotganj 2 no. Goli',
          'Rahmotganj 3 no. Goli',
          'Rahmotganj 4 no. Goli',
          'Telkupi',
          'Fulbari',
          'Rotonganj',
          'Shohidgang',
          'Somajkolyan More',
          'Sirajganj Sadar Thana',
          'Bepari-para',
          'Puraton Vangabari',
          'Khan-Saheber Math',
          'Milon More',
          'Jublibagan',
          'Dorghah Road',
          'Arshinogor',
          'Post-Office',
          'Boropul',
          'S.S Road',
          'Mujib Sorok',
          'PDB',
          'Goshpara',
          'Bank-para',
          'Janpur',
          'B.A . College Road',
          'Dotto-Bari',
          'E.B. Road',
          'Malshapara',
          'Hossanpur',
          'Malshapara',
          'Biralakuthi',
          'Mirpur Sirajganj Sadar',
          'Rail Gate',
          'Stadium Road Sirajganj Sadar',
          'Dhanbandhi',
          'North Bangal Madical college',
          'I.I College Road',
          'Amlapara',
          'Ekdala',
          'Rashidabad',
          'Baiddya Jam Toil',
        ],
      },
      {
        name: 'Kamarkhanda',
        postCode: '6730',
        areas: [
          'Bhadraghat Bazar',
          'Mukbelai',
          'Jhatibelai',
          'Kamarkhanda police Station',
          'Upozilla Porishod Kamarkhanda',
          'Jamtail',
          'Koddar More',
        ],
      },
      {
        name: 'Belkuchi',
        postCode: '6740',
        areas: [
          'Soydabad',
          'Maizail',
          'Someshpur',
          'Mukondogati Bazar',
          'Shernogor',
          'Kamarpara Belkuchi',
          'Thana Belkuchi',
          'Upozila Porishod Belkuchi',
          'Islami Bank',
          'Shuborno-shara',
          'Kazipura',
          'Kalibari Belkuchi',
          'Agogora bus stand',
          'Khukni',
          'Rupnai',
          'Khawaja Yunus Ali Medical College & Hospital',
          'Kezir More',
          'Sohagpur',
          'Rajapur-Belkuchi',
          'Enayetpur-Belkuchi',
        ],
      },
      {
        name: 'Shahjadpur-Shahjadpur',
        postCode: '6772',
        areas: [
          'Thanar-Ghat',
          'She-E- Bangla road',
          'Dilruba Bus stand',
          'Dakbanglo',
          'Bazar Kalimondir',
          'Shahjadpur Bridge',
          'BSCIC Bus Stop',
          'Ruppur',
          'Talgachi',
          'Baghabari',
          'Shorishakol',
          'Porjana',
          'Jamirta',
        ],
      },
      {
        name: 'Ullapara',
        postCode: '6762',
        areas: [
          'Jhikira',
          'Upojelaporishod',
          'Thanar more',
          'Khan-Plaza',
          'Ninety-Five Market',
          'Gauchiya Market',
          'Keya hospital',
          'Nort-Bangol Hospital',
          'Sreekola Bazar',
          'Ullahpara Science College',
          'Ghat Saheber More',
          'Pukur-Par',
          'WAPDA',
          'Sirajganj Road',
          'Ullapara R.S',
          'Lahiri Mohanpur',
        ],
      },
      {
        name: 'Salonga',
        postCode: '',
        areas: [
          'RAB- 12',
          'Salonga Bazar',
          'Salonga Thana',
          'Maittry Hospital',
          'Sirajganj Road',
        ],
      },
      {
        name: 'Tarash',
        postCode: '6780',
        areas: [
          'Upojelaporishod Tarash',
          'Tarash College',
          'Eye Hospital',
          'Polli-Biddut',
          'Mannan-Nogor Bazar',
          'Khankula Bazar',
          'Dabirgonj',
        ],
      },
      {
        name: 'Rayganj',
        postCode: '6701',
        areas: [
          'Dhangora Mohila College',
          'Post office Rayganj',
          'Proshikha',
          'Chandaikona Bazar',
          'Pabna Bazar',
          'Chandaikona Bohumukhi Uccho-Bidyaloy',
          'Bhuiyagati Bazar',
          'Nimgachi Bazar',
          'Pangashi Bazar',
          'Dhangora',
          'Malonga',
        ],
      },
      {
        name: 'Kazipur',
        postCode: '6712',
        areas: [
          'Fokirtola',
          'Khokshabari',
          'Songasa',
          'Pipulbariya Bazar',
          'Bagbati',
          'Vamara Bazar',
          'Simant Bazar',
          'Rotonkandi',
          'Alompur Chowrasta',
          'Upojelaporishod Kazipur',
          'Meghai',
          'Gandhail',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Dinajpur',
    zones: [
      {
        name: 'Dinajpur Sadar',
        postCode: '5200',
        areas: [
          'Dinajpur Sadar medical',
          'Rab camp',
          'Ramdubi more',
          'Ranigonj more',
          'Haji Mohammad danesh University',
          'College more Dinajpur Sadar',
          'Lili more',
          'Dinajpur Sadar hospital more',
          '6 rastar more',
          'Dinajpur boro math',
          'Housing more Dinajpur Sadar',
          'Moharajar more',
          'Dinajpur Rajbari',
          'New Town',
          'lebur more',
          'balubari shahi mosque',
          'mamuner more',
          'Technical more',
          'Dinajpur mohila college more',
          'Noyonpur',
          'ramnagar',
          'khettripara',
          'ondho hapejer mor',
          'kanar more',
          'Sostitola',
          'charubabur more',
          'munshi para',
          'AIN college more',
          'chourangi',
          'kalur more',
          'bus Tarminal',
          'mirjapur suihari',
          'Sehelgai school',
          'PTI more Dinajpur Sadar',
          'khal para',
          'paglar more',
          'golab bag',
          'Dinajpur rail station',
          'Fokir para jame mosjid',
          'maldoho potti',
          'pulice line school',
          'Nimtola',
          'Fulbari bus stand',
          'Bolai more',
          'Modern More',
          'KBM College',
          'Maharajganj',
        ],
      },
      {
        name: 'Chirirbandar',
        postCode: '5240',
        areas: [
          'ghuguratoli',
          'vober Bazar',
          'Ranibondor',
          'Bekipul',
          'Ranirbandar',
        ],
      },
      {
        name: 'Birganj',
        postCode: '5220',
        areas: [
          'birgonj govt College',
          'swich get',
          'birgonj pailot school',
          'ullas Cinema hall',
        ],
      },
      {
        name: 'Kaharol',
        postCode: '',
        areas: ['kaharole Pilot school more', 'kaharole bazar'],
      },
      {
        name: 'Khanshama',
        postCode: '5230',
        areas: ['paker hate', 'Bhushirbondar'],
      },
      {
        name: 'Bochaganj',
        postCode: '',
        areas: ['Shetabganj Bazar', 'Setabganj chourasta'],
      },
      {
        name: 'Parbatipur',
        postCode: '5250',
        areas: [
          'Muktijoddha complex',
          'Holdibari get',
          'shahidminar more',
          'Railway Station',
          'Kholahati Cantonment',
          'Parbatipur',
        ],
      },
      {
        name: 'Fulbari',
        postCode: '5260',
        areas: ['fulbari Cantonment', 'koylakhoni', 'Dhakar More', 'Fulbari'],
      },
      {
        name: 'Birampur',
        postCode: '5266',
        areas: ['Dhaka Mor', 'Birampur Fire service', 'College Road Birampur'],
      },
      {
        name: 'Nawbganj',
        postCode: '5281',
        areas: ['Vaduria', 'Daudpur', 'Gopalpur'],
      },
      { name: 'Ghoraghat', postCode: '5291', areas: ['Ranigonj', 'Osmanpur'] },
      {
        name: 'Biral',
        postCode: '5210',
        areas: ['Biral Bazar', 'Dhukurjhari bazar'],
      },
      {
        name: 'Hakimpur (Hili)',
        postCode: '5270',
        areas: [
          'Hillpost office',
          'Customs office',
          'CP Road',
          'Check Post',
          'Bangla Hili',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Gaibandha',
    zones: [
      {
        name: 'Gaibandha Sadar',
        postCode: '5730',
        areas: [
          'Indiarar Par',
          'Board Bazar',
          'Police Lines',
          'Gaibandha Stadium',
          "Boy's School",
          "Hawker's Market",
          'Shohidminar Road',
          'Shapla Mill',
          'Gaibandha Pouro Park',
          'DB Road',
          'Alhaz Rice Mill',
          'Munshipara',
          'Sordarpara',
          'Kali Mondir',
          'Puraton Bridge',
          'Notun Bridge',
          'Durga Mondir',
          'DC Office',
          'LGED Office',
          'Gail Road',
          'Tulshighat',
          'Bus Terminal',
          'Brac Regional Office',
          'Polashpara',
          'Fire Service',
          'College Road Gaibandha Sadar',
          'Sundarban Courier',
          'Shapla Para',
          'Khanka Shorif Road',
          'Hassan Road',
          'Paschimpara',
          'Sarkarpara',
          'New market Gaibandha Sadar',
          'Malipara',
          'Modhapara',
          'Asaduzzaman Market',
          'Gorosthan Raod',
          'Wapda Road',
          'Park Road',
          'Girls School',
          'Railway Station',
          'Post Office Gaibandha Sadar',
        ],
      },
      {
        name: 'Sundarganj',
        postCode: '5721',
        areas: ['Bamondanga', 'Noldanga', 'Kathal tolar mor'],
      },
      {
        name: 'Saghata',
        postCode: '5751',
        areas: [
          'Saghata union Parishad',
          'Saghata police station',
          'Saghata Bazar',
          'Bonarpara',
        ],
      },
      {
        name: 'Palashbari',
        postCode: '5730',
        areas: ['Dholvanga Bazar', 'Thutiapukur Bazar', 'Bus Stand Palashbari'],
      },
      {
        name: 'Gobindaganj',
        postCode: '5740',
        areas: [
          '5 Len Road',
          '6 Len Road',
          '7 Len Road',
          '8 Len Road',
          'Mahimaganj',
        ],
      },
      {
        name: 'Sadulllahpur',
        postCode: '5711',
        areas: [
          'Dhaperhat',
          'Shohidminar',
          'Bus Stand Sadulllahpur',
          'Naldanga',
        ],
      },
      {
        name: 'Fulchhari',
        postCode: '5720',
        areas: [
          'Fulchhari Upazila Gate',
          'Fulchhari Bazar',
          'Phulchhari',
          'Bharatkhali',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Kurigram',
    zones: [
      {
        name: 'Kurigram Sadar',
        postCode: '5600',
        areas: [
          'Kalibari',
          'Railgunti',
          'Super Market',
          'Zia Bazar',
          'Jhinuk hall',
          'Ghosh Para',
          'Posur Hashpatal More',
          'Jahaj Ghor',
          'LGED More Kurigram Sadar',
          'College More Kurigram Sadar',
          'Powro Bazar',
          'Seba Clicnic',
          'Sapla Chattar',
          'Zila Parishad',
          'Ghosh Para',
          'Mistri Para',
          'Trimohoni More',
          'Upazila',
          'Station More',
          'Chamrar Gola',
          'Hana Ghor',
          'Vela Kopa',
          'Gorer Par',
          'Chirar Mill',
          'Sarder Para',
          'New Poshu Haspatal More',
          'Charua Para',
          'Vocational More',
          'Judge Court',
          'Polytechnical More',
          'Nazira',
          'Kobiraz Para',
          'Modhur More',
          'Khejurer Tol',
          'C&B More',
          'Power House Para',
          'Collector School More',
          'Kalir Hat',
          'Ansarer Field',
          'Aktaram Bel Gasa',
          'Godown Kurigram Sadar',
          'Adibas Gate',
          'Kholilgonj Bazar',
          'Bus Terminal Kurigram Sadar',
          'Kazi office',
          'Rowmari Para',
          'Hospital More',
          'Dasher Hut',
          'Bangtur Ghat',
          'RDRS Bazar',
          'Agomoni',
          'Quat More',
          'Raypur',
          'Textile More',
          'Ketar More',
          'Mossho Khamar',
          'Pochar Mosjid',
          'Jotiner Hat',
          'BSCIC More',
          'Nazira Miapara',
          'Bridger Matha',
          'Pach Gachi College More',
          'Sulkur Bazar',
          'Pachgachi Bazar',
          'Polli Biddut',
          'Sujamer More',
          'Ambari Ghat',
          'Cerengha',
          'Majhi Para',
          'Baniya Para',
          'Horikesh More',
          'Khalifar More',
          'Tapur Char',
          'Holokhana',
          'Noyar hat',
          'Burir Bazar',
          'Suvarkuti',
          'Chaddir More',
          'Sawdagor Para',
          'Diabetes Hospital More',
          'Chowrastar More',
          'Ekota Para',
          'Guyati Para',
          'Jolilbiri More',
          'Thana Para Kurigram Sadar',
          'Munshi Para Kurigram Sadar',
          'Police Fari Kurigram Sadar',
          'Chowra Bazar Kurigram Sadar',
          'Kathalbari Petrol Pump',
          'Amin Bazar',
          'Mogolbasa',
          'Sat Kurar Par',
          'Joshimuddiner More',
          'Kathalbari',
          'City More',
          'Jatrapur Bazar',
          'Jalaler More',
          'Gaber Toll',
          'Korimer Khamar School More',
          'Nilaram',
          'Sener Khamar',
          'College Para Kurigram Sadar',
          'Voroshar More',
          'Gorer Par Kurigram Sadar',
          'Boirager Bazar',
          'Jurabandha',
          'Garial Para',
          'Masterer Hat',
          'Moktarer Hat',
          'Araji Polash Bari',
          'Patershawari',
          'Moratari',
          'Ghoghadoho Bazar',
          'Mather Par',
          'Nonir More',
          'Kumorpur More',
          'Bhogdanga',
          'Mou Bazar',
        ],
      },
      {
        name: 'Bhurungamari',
        postCode: '5670',
        areas: ['Joymonirhat', 'Mothergonj', 'Paikerchhara'],
      },
      {
        name: 'Phulbari',
        postCode: '5680',
        areas: ['Kashipur', 'Naodanga', 'Bhangamore'],
      },
      {
        name: 'Rajarhat',
        postCode: '5611',
        areas: ['Nazimkhan', 'Chakirpashar', 'Chhinai'],
      },
      {
        name: 'Ulipur',
        postCode: '5621',
        areas: ['Durgapur Ulipur', 'Pandul', 'Thetrai', 'Bazarhat'],
      },
      {
        name: 'Chilmari',
        postCode: '5630',
        areas: ['Thanahat', 'Nayarhat Chilmari', 'Raniganj', 'Jorgachh'],
      },
      {
        name: 'Nagesshari',
        postCode: '5660',
        areas: ['Berubari', 'Royganj', 'Bhitarband'],
      },
      {
        name: 'Rawmari',
        postCode: '5640',
        areas: ['Dantbhanga', 'Jadurchar', 'Shailmari'],
      },
      {
        name: 'Char Rajibpur',
        postCode: '5650',
        areas: ['Kodalkati', 'Mohanganj'],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Lalmonirhat',
    zones: [
      {
        name: 'Lalmonirhat Sadar',
        postCode: '5500',
        areas: [
          'Lalmonirhat Bus Terminal',
          'Haribhanga',
          'Station Raod Lalmonirhat Sadar',
          'Lalmonirhat Railway Station',
          'Lalmonirhat Govt. College',
          'bata more',
          'goshala road',
          'fire service road',
          'chadni bajar',
          'jail road',
          'purba harivanga',
          'LGED more Lalmonirhat Sadar',
          'balatari',
          'Zilla porishod more',
          'pingkir more',
          'medical more',
          'noyar hat',
          'kulaghat',
          'thanapara',
          'namatari',
          'BDR gate',
          'banvasa more',
          'sayeed more',
          'kalibari',
          'saptana',
          'alorupa mor',
          'hat khola',
          'stadium para',
          'teli para',
          'modina para',
          'ram krishno road',
          'sobuj para',
          'sadhutari',
          'dalpotti',
          'saheb para',
          'santi nagor',
          'master para',
          'vatibari',
          'apon para',
          'Tista railway station',
          'Tista bazar',
          'tista bus stand',
          'mostofi',
          'borobari',
          'khuniyagas',
          'mohendro nagar',
          'kulaghat',
          'fokir er tokeya',
          'Moghalhat',
        ],
      },
      {
        name: 'Aditmari',
        postCode: '5510',
        areas: [
          'momin er garage',
          'Medical more Aditmari',
          'Upozila porishod Aditmari',
        ],
      },
      {
        name: 'Kaliganj - Lalmonirhat',
        postCode: '',
        areas: [
          'Thana more Kaliganj',
          'Railway station Kaliganj',
          'chowdhury more',
          'chaparhat',
          'kakina',
        ],
      },
      {
        name: 'Hatibandha',
        postCode: '5530',
        areas: [
          'parulia',
          'Hatibandha medical more',
          'Hatibandha upozila porishod',
          'doikhawa',
          'milon bazar',
        ],
      },
      {
        name: 'Patgram',
        postCode: '5542',
        areas: ['Burimari', 'Borokhata', 'Chowrangi more', 'Baura Patgram'],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Nilphamari',
    zones: [
      {
        name: 'Nilphamari sadar',
        postCode: '5300',
        areas: [
          'Belalermor',
          'Jumma Para',
          'Shantinaga',
          'New babupara',
          'Ukilpara',
          'Baraiparan',
          'Debirdanga',
          'New bazar',
          'Dakbanglo',
          'Stafkoyater',
          'Thana para Nilphamari sadar',
          'Kazibari',
          'Upazilla',
          'College para Nilphamari sadar',
          'Ukilermor',
          'Madharmor',
          'Hospital Nilphamari sadar',
          'Big bazar',
          'New babu para',
          'Sobujpara',
          'Chawrowgi Nilphamari sadar',
        ],
      },
      {
        name: 'Jaldhaka',
        postCode: '5330',
        areas: ['Chapani', 'Golmunda', 'Balagram', 'Dhaka Bus Stand'],
      },
      {
        name: 'Dimla',
        postCode: '5350',
        areas: ['Sutibari', 'Posto mor', 'Tunir hat'],
      },
      {
        name: 'Kishoriganj -Nilphamari',
        postCode: '5320',
        areas: ['Kochukata', 'Tangormari', 'Borovita'],
      },
      {
        name: 'Domar',
        postCode: '5341',
        areas: [
          'Domar Bazar',
          'Chekonmati',
          'Dhorigonj',
          'Chilahati Railway Station',
          'Poramanikpara',
          'Chilahati Police Fari',
          'Chilahati Bazar',
          'Chilahati Chowrasta',
        ],
      },
      {
        name: 'Saidpur',
        postCode: '5310',
        areas: [
          'Kundol',
          'GRP Police Lines',
          'Lions School & College',
          'Saidpur Super Market',
          'Saidpur Plaza',
          'Dr Dill Afroz Memorial Hospital',
          'Bangabandhu High School',
          'Shah Jalal Mazar',
          'Kamal Road',
          'Saidpur Railway Station',
          'Saidpur Post Office',
          'New Babupara',
          'Saidpur Fire Service',
          'Press Club',
          'Dr Zikrul Haque Road',
          'Station Road Saidpur',
          'Noyatola Mosjid',
          'Saidpur Theme Park',
          'Saidpur Bus Terminal',
          'Kadamtali Bazar',
          'Kamarpukur',
          'Chini Mosjid',
          'Wapda Mor',
          'Madina Road',
          'Golahat Colony',
          'Hawladarpara',
          'Saidpur Cantonment',
          'Sutkir Bazar',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Panchagarh',
    zones: [
      {
        name: 'Panchagarh Sadar',
        postCode: '5000',
        areas: [
          'Mr college',
          'Police line Panchagarh Sadar',
          'Satkhamar',
          'Tunirhat',
          'Kamlapur bazar',
          'Millget bazar',
          '18 bgb cam',
          'Jalshe mor',
          'Baniapotti',
          'Islambag',
          'Rosonabag',
          'Docoro para',
          'Ragnogor',
          'Kamarpotti',
          'Cinema hall rod',
          'Ramardaga',
          'Tulardaga',
          'Ahmadnagar',
          'CNB more',
          'Bus terminal Panchagarh Sadar',
          'Panchagarh Sadar truck terminal',
          'Talma bazar',
          'Mithapukur',
          'Kamat para',
          'BM college mor',
          'Stadium road Panchagarh Sadar',
          'Masjid para',
          'Singparar',
          'kholapara',
          'Shahid minar',
          'BRTC bus counter',
          'Mirgarh',
          'Heliboard Bazar',
          'Bagan Bari',
          'Fakirhat Bazar Panchagarh Sadar',
          'Boleyapara Bazar',
          'Dardaria Para',
          'Puratan Panchagarh',
        ],
      },
      {
        name: 'Atwari',
        postCode: '',
        areas: [
          'Mirjapur',
          'Paltapara',
          'Magura',
          'Chotto Dab',
          'Mirjapur -Atwari',
        ],
      },
      {
        name: 'Tetulia',
        postCode: '5030',
        areas: ['Shalbon bazar', 'Vojonpur', 'Tinoy bazar', 'Banglabandha'],
      },
      {
        name: 'Debiganj',
        postCode: '5020',
        areas: ['Debiganj college para', 'Kaliaganj', 'Lokhyhat', 'Bhawlaganj'],
      },
      {
        name: 'Boda',
        postCode: '5010',
        areas: ['Boda hospital mor', 'Maidandighi', 'Chandanbari', 'Sakowa'],
      },
      { name: 'Rangpur Sadar(Kotowali Thana)', postCode: '5400', areas: [] },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Rangpur',
    zones: [
      {
        name: 'Rangpur Sadar(Kotowali Thana)',
        postCode: '5400',
        areas: [
          'Shapla Chattar',
          'Adarshopara',
          'Sordarpara',
          'Hazipara',
          'Alamnagar',
          'Station Road',
          'Becon Mor',
          'Chartala Mor',
          'Abohawa Office',
          'Babukha',
          'Masterpara',
          'Bananipara',
          'Khamar Mor',
          'Lalbagh',
          'KDC Road',
          'Carmichael College',
          'College Para',
          'Alhaznagar',
          'Dorshona Mor',
          'Rangpur Public School',
          'Ashratpur',
          'Park Mor',
          'Chawkbazar',
          'Dhakaia Para',
          'Rangpur Railway Station',
          'KG Market',
          'Babupara',
          'Thikadarpara',
          'Pirpur',
          'Ghorapir Mazar',
          'Golakata Mor',
          'Tajhat Mor',
          'RAB 13',
          'Tatipara',
          'Robertsongonj',
          'Kutirpara',
          'Mondalpara',
          'Patbari',
          'Karupannya',
          'Tabligue Mosjid',
          'Rangpur Textile College',
          'Nurpur',
          'Mohadebpur',
          'JNC Road',
          'Ghuptopra',
          'Bouragipara',
          'Jahaj Company Mor',
          'GL Ray Road',
          'Fire Service',
          'Hotel Grand Palace',
          'SA Paribahan',
          'Kamal Kachna',
          'Mayamoyi Lane',
          'Rupkotha Theme Park',
          'Dohiganj',
          'Rangpur Engineering College',
          'Dokhiganj',
          'Tinmatha',
          'Satmatha',
          'Khashbagh',
          'Bahar Kachna',
          'Cigarette Compani',
          'Gunjon Mor',
          'Rokeya College Road',
          'Botlapara',
          'Shiyalur Mor',
          'Shalbon Mistripara',
          'Shalbon',
          'RCCI Mor',
          'Indira Mor',
          'Central Road',
          'Hazi Lane',
          'Rangpur Politechnic College',
          'New Jummapara',
          'Jummapra',
          'Bhumi Office',
          'Hotel North View',
          'Payra Chattar',
          'City Market',
          'Betpotti',
          'Super Market',
          'Nababganj Police Fari',
          'Town Hall',
          'Gomastapara',
          'New Engineerpara',
          'Rangppur Zoo Road',
          'Police Lines',
          'Homumantola',
          'Islampurr',
          'Jalkar mor',
          'Kukrul',
          'Mouvashi Mor',
          'Rangpur Stadium',
          'Passport Office',
          'Radhaballav',
          'Rokeya University',
          'Surovi Uddan',
          'DC Office',
          'DC Mor',
          'Bangladesh Bank Mor',
          'Bangladesh Betar',
          'Shimulbagh Community Center',
          'Kosir Uddine Medical College',
          'Medical East Gate',
          'Medical Pakarmatha',
          'Northern Medical',
          'Doctors Hospital',
          'Sagarpara',
          'Chikli Lane',
          'Dhap',
          'Shymoli Lane',
          'Kakoli Lane',
          'Dhap Police Fari',
          'Traffice Offie',
          'Medical Mor',
          'Rangpur Medical College',
          'Jail Road',
          'Dhap Hazipara',
          'Dhap Engineerpara',
          'Sordarpara',
          'Kellaband',
          'CO Bazar',
          'Rangpur BGB',
          'Cantonment Public School & College',
          'RAMC Market',
          'Desh Clinic',
          'Check Post',
          'Lalkuthi Mor',
          'Dhap City Bazar',
          'Keranipara',
          'Munshipara',
          'Kotkipara',
          'PTI Road',
          'Circuit House Lane',
          'Kolpona Chartrinibash',
          'Mulatol Amtoli',
          'Mulatol Pakarmatha',
          'Mulatol',
          'Goneshpur Club Mor',
          'Terminal Road',
          'Goneshpur',
          'Kamarpara Bus Stand',
          'Pasaripara',
          'Ideal Mor',
          'Textile Mor',
          'Porjatal Motel',
          'Rampura',
          'Shatgara',
          'Shatgara Mistripara',
          'Darogar Mor',
          'Borobari',
          'Police Training Center',
          'Rangpur Central Bus Tarminal',
          'Prime Medical',
          'Suntan Mor',
          'BSTI Office',
          'BNCC Mor',
          'BRTC Depo',
          'Koronjoy Road',
          'Nehal Hoter er Goli',
          'Haragach',
          'Boubazar-Rangpur Sadar',
          'Pouro Bazar',
          'Guptopara',
          'Mordern',
          'Kachari Bazar',
          'Gurati Para',
          'C.M.B Quarter',
          'Checkpost 3 Numbar',
          'Satgara Mistripara',
          'Nisbedganj',
          'Bokultola',
          'Shantibag-Rangpur Sadar',
          'Medical Quater',
          'Dhap Engineer Para',
          'Vogi Lane',
          'Dhap Sardar Para',
          'Beacon Mor',
          'Ansari Mor',
          'Shayamoli Lane',
          'Nil Kantho',
          'Parbotipur Uposohor',
          'Katki Para',
          'Gamostopara',
          'Press Club',
          'Hanumantola',
          'D.C Mor',
          'C.O Bazar',
          'Rfl Foundary',
          'Nazirrerhat',
          'Sultanmor',
          'RK Road',
          'Ghunjonmor',
          'Boiragi Para',
          'Siyalurmor',
          'Palpara-Rangpur Sadar',
          'Indrarmore',
          'Jummapara Pakarmatha',
          'Asrotpur',
          'Karuponno Alamnagor',
          'Tajhat',
          'Mondol Para',
          'Arshad Nagor',
          'Dharsona',
          'Sardarpara',
          'Rangpur Upa-Shahar',
          'Mahiganj',
          'Rangpur Cadet Colleg',
        ],
      },
      {
        name: 'Mahiganj Thana',
        postCode: '5403',
        areas: [
          'Tajhat Jomidarbari',
          'Golakata Mor',
          'Ershadnagar',
          'Ashratpur',
          'Chawkbazar',
          'Rice Research Institute',
        ],
      },
      {
        name: 'Tajhat Thana',
        postCode: '',
        areas: [
          'Dhorshona Mor',
          'Rangpur Publice School',
          'Sordarpara',
          'Ghaghotpar',
          'Rangpur Cadet College',
          'Brack Learling Center',
          'Alhajnagar',
          'BRTC Depo',
          'Balapara',
        ],
      },
      {
        name: 'Porshurampur Thana',
        postCode: '',
        areas: [
          'Rangpur Metro',
          'Politon Police Lines (RMP)',
          'Burihat',
          '24 Hazari',
        ],
      },
      {
        name: 'Rangpur Cantonment',
        postCode: '',
        areas: [
          'Vogibalapara',
          'Parbatipur',
          'Sobujbagh',
          'Bou Bazar',
          'Check Post-1',
          'Check Post-2',
          'Check Post-3',
          'Check Post-4',
          'Check Post-5',
        ],
      },
      {
        name: 'Pirganj Thana',
        postCode: '',
        areas: ['Pirganj Bus Stand', 'Jamtola Madrasha', 'Thana Road'],
      },
      {
        name: 'Mithapukur Thana',
        postCode: '5460',
        areas: [
          'Payraband Bus Stand',
          'Boldipurkur Bus Stand',
          'Shothibari Bus Stand',
          'Mithpukur Bus Stand',
          'Jaigir Bus Stand',
        ],
      },
      {
        name: 'Badarganj',
        postCode: '5430',
        areas: [
          'Lahirirhat',
          'Mominpur Bus Stop',
          'CO Bazar',
          'Hoque Saheb er Mor',
          'Railway Station',
          'Shohid Minar',
          'Shyampur',
        ],
      },
      {
        name: 'Taraganj Thana',
        postCode: '5420',
        areas: [
          'Paglapir Bazar',
          'Ekorchali Bus Stop',
          'Taraganj Bus Stop',
          'Munshiparaa',
          'Uttarpara',
        ],
      },
      {
        name: 'Gangachara Thana',
        postCode: '5410',
        areas: [
          'Porshurampur thana',
          'Burirhat',
          'Gangachara Bazar',
          'Sanabil market',
          'Gangachara Zero Point',
        ],
      },
      {
        name: 'Pirgacha Thana',
        postCode: '5450',
        areas: [
          'Saidpur Bazar',
          'Pirganj Bus Stand',
          'Shopla Chattar',
          'Pirgacha Railway Station',
        ],
      },
      {
        name: 'Kaunia Thana',
        postCode: '5440',
        areas: ['Mirbagh Bazar', 'Baily Bridge', 'Kaunia Bus Stand'],
      },
      { name: 'Haragach Thana', postCode: '5441', areas: ['Haragach Bazar'] },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Thakurgaon',
    zones: [
      {
        name: 'Thakurgaon Sadar',
        postCode: '5100',
        areas: [
          'Basic',
          'Hazipara',
          'College para',
          'Choudhurihat',
          'Goreya',
          'Vulli Bazar',
          'Harinarayanpur',
          'Shantinagar',
          'Vaularhat',
          'Bokosher Hat',
          'Kalibari',
          'Mandirpara',
          'Salandar',
          'Kalitola',
          'Balia',
          'Goshpara',
          'Farabari',
          'Goudhulibazar',
          'Asrompara',
          'Thakurgaon Govt Mohila College',
          'Thakurgaon Boromath',
          "Thakurgaon Govt Gril's School",
          'Mothurapur Thakurgaon Sadar',
          'Thakurgaon Govt College',
          'Thakurgaon Road',
          'Shibganj-Thakurgaon Sadar',
          'Jibanpur',
        ],
      },
      {
        name: 'Baliadangi',
        postCode: '5141',
        areas: ['Harinmari', 'Kalmegh', 'Nekmorod', 'Lahiri'],
      },
      {
        name: 'Haripur',
        postCode: '',
        areas: ['Routhnagar', 'Vaturiya', 'Birgorh'],
      },
      {
        name: 'Pirganj',
        postCode: '5110',
        areas: ['Shengaon', 'Dawlatpur', 'Jobarhat'],
      },
      {
        name: 'Ranisankail',
        postCode: '5121',
        areas: ['Dangipara', 'Hossiangaon', 'Gedura'],
      },
      {
        name: 'Ruhiya',
        postCode: '5103',
        areas: ['Akhanagar', 'Pariya', 'Barabari', 'Charol'],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Chuadanga',
    zones: [
      {
        name: 'Chuadanga Sadar',
        postCode: '7200',
        areas: [
          'Jolibila',
          'Zadobpur',
          'Mohamodjoma',
          'Kutubpur',
          'Nobinnogor',
          'Vultia',
          'Shankarchandra',
          'Hanurbaradi',
          'Ghadghat',
          'Makhaldanga',
          'Boalia',
          'Fulbari',
          'Subadiya',
          'Nimtala',
          'Kedarganj',
          'CNB para',
          'Muktipara',
          'Taltala',
          'Farmpara',
          'Academy Mor',
          'Arampara',
          'Bagan para',
          'Balia Kandi',
          'Bhandar Daha',
          'Bhelgasi',
          'Bodor Gonj',
          'Bolaka Para',
          'Chuadanga Sadar Boro Bazar',
          'C N B Para',
          'Chondi Pur',
          'Cinema Hall para',
          'Chuadanga Sadar College Para',
          'Colony para',
          'Dewanbag Para',
          'Badarganj Degree College',
          'Dinanathpur',
          'Dinghadha',
          'Dosh Mile',
          'Daniel',
          'Duphabila',
          'Dutur Hat',
          'Eidga Para',
          'Ferry ghat',
          'Gostopur',
          'Chuadanga Sadar Gobindapur',
          'Gulshan Para',
          'Haque Para',
          'Hat Kalugonj',
          'Hazrahati',
          'Hidar Pur',
          'Hizrapara',
          'Islam Para',
          'Jafarpur',
          'Jhil Khali',
          'Jibonnogor Bus Stand Para',
          'Jintola Para',
          'Kalopur',
          'Khajura',
          'Kharaguda',
          'Khaspara',
          'Khuso Danga',
          'Kukhia chandpur',
          'Kulchara',
          'Madrasha Para',
          'Malo Para',
          'Manikdihi',
          'Mastar Para',
          'Mohila College',
          'Muchi Para',
          'Nilar Mor',
          'Chuadanga Sadar Notun Bazar',
          'Notun Jal Khana Para',
          'Noy Mile',
          'Nunnogor',
          'Piroz Khali',
          'Polash Para',
          'Police Line Chuadanga Sadar',
          'Post Office Para',
          'Pouro College Para',
          'Puraton Bus Stand Para',
          'Puraton Hospital Para',
          'Sarajgonj',
          'Shadak Ali Mollik Para',
          'Shahib Nogor',
          'Shakratola',
          'Shanti Para',
          'Shapla Chhotor',
          'Shatgari',
          'Shobuj Para',
          'Shumordia',
          'Sree Kul',
          'Shujat pur',
          'Taltola Chuadanga Sadar',
          'Thakur Pur',
          'Thana Council Para',
          'TNT Mor',
          'Ukta',
          'Vimrilla',
          'Wapda Para',
          'Yatim Khana Para',
          'Munshiganj',
        ],
      },
      {
        name: 'Alamdanga',
        postCode: '7211',
        areas: [
          'Station Para',
          'Babu para',
          'Shohag Mor',
          'Nantu Togor Mor',
          'Bitim Para',
          'Yatim Para',
          'Court Para',
          'Thana Para',
          'College Para Alamdanga',
          'Madrasha Para',
          '4 tola mor',
          'Chatal Mor',
          'Housh Pur',
          'Hazi Mor',
          'Go Hat',
          'Master Para Alamdanga',
          'Puroshova Para',
          'Kapor Potti Alamdanga',
          'Shona Potti',
          'Holud Potti',
          'Mia Para',
          'Lal Bridge',
          'Shada Bridge',
          'EarshadPur Bot tola',
          'Gobind Pur',
          'Northern Bondho Bill',
          'Northern Gobindo Pur',
          'Altiba Mor',
          'Foridpur Alamdanga',
          '71 Mor',
          'Choto Kopat',
          'Kamar Pur',
          'Kali Das Pur',
          'Ashan Nogor',
          'Shila Hall Mor',
          'Notun Bus Stand',
          'Puraton Bus Stand Para',
          'Alamdanga Upazila',
          'Dawoki',
          'Badu Mazu',
          'Kachari Bazar',
          'Zuta Potti',
          'Bagadi',
          'Bashbaria',
          'Bachamari',
          'Vogel',
          'Mohesh Pur',
          'Chor Bowalia',
          'Chord',
          'Hat Bowali',
          'Nogor Bowalia',
          'Chor Jadavpur',
          'Osman Pur',
          'Prag Pur',
          'Keshob Pur',
          'Lokkhi Pur',
          'Bodonath Pur',
          'Shak Para',
          'Udaipur',
          'Par durgapur',
          'Dighol Danga',
          'Durlov Pur',
          'Islam Pur',
          'Alihi Nogor',
          'Kumari',
          'Nati Danga',
          'Goal Bari',
          'Gopal Nogor',
          'Anupam Nogor',
          'Athar Khada',
          'Pulta Danga',
          'Anit Pur',
          'Nandabar',
          'Ram Nogor',
          'Fulbogadi',
          'Mochan Nogor',
          'Bro Gani',
          'Pas Komolapur',
          'Mazhad',
          'Kantopur',
          'Alit Nogor',
          'Botia Para',
          'Gorchapra',
          'Puti Mari',
          'Bridge Mor',
          'Kasto Pur',
          'Bhalgasi',
          'Jamjami',
          'Dawoki',
          'Kajur tola',
          'Bolsha pur',
          'Baro Ghori',
          'Zhor Gachha',
          'Jhapur',
          'Gholdari',
          'Paik Para Alamdanga',
          'Kuti Para',
          'Hossain Pur',
          'Soygoria',
          'Modhu Pur',
          'Sishar Math',
          'Bagundo',
          'Nowlamari',
          'Ramdia Alamdanga',
          'Kitepara',
          'Riasha',
          'Khuakarora',
          'Nichadpur',
          'Kabil Nogor',
          'Laxmipur Alamdanga',
          'Par Lokhi Pur',
          'Hakim pur',
          'Tuor Bila',
          'Taluk Karora',
          'Alamdanga',
          'Hardi',
        ],
      },
      {
        name: 'Damurhuda',
        postCode: '7220',
        areas: [
          'Bisnopur',
          'Ramnagar',
          'Chondrobash',
          'Chotipur',
          'Chitla',
          'Dali',
          'Dhamurhuda Thana',
          'Dudh Patila',
          'Dugdugi',
          'Gobindopur',
          'Hogol Danga',
          'Jogonnathpur',
          'Jorampur',
          'Kadipur',
          'Karpasdanga',
          'Kolabari',
          'Komorpur',
          'Kurulgachi',
          'kutubpur',
          'Loknathpur',
          'Muktarpur',
          'Munshipur',
          'Natudha',
          'Pirpur',
          'Pura Para',
          'Putraton BASB',
          'Rogunathpur',
          'Rudro Nogor',
          'Shibnogor',
          'Shujalpur',
          'Tarin Pur',
          'Uzir pur',
          'Vogirath Pur',
          'Darshana',
          'Andulbaria',
        ],
      },
      {
        name: 'Jibannagar',
        postCode: '',
        areas: [
          'Hospital Para',
          'Post office Para',
          'Shapla Kali Para',
          'Jiban nagar',
          'Adorsho Mhoila college Para',
          'Alipur Jiban nagar',
          'Ashtola Para',
          'Baka',
          'Banipur',
          'Bazdia',
          'Bodonat Pur',
          'Boshoti Para',
          'Cham Kali',
          'Dahati',
          'Dopahakhali',
          'Doulotgonj',
          'Dumuria Jiban nagar',
          'Fire Service Jiban nagar',
          'Gayeshpur',
          'Ghoshnogor',
          'Goal Para',
          'Gonga Das Pur',
          'Gopalpur Jiban nagar',
          'Horin Nogor',
          'Islam Para',
          'Jiban nagar Bus Stand',
          'Kaligonj Para',
          'Kashipur Jiban nagar',
          'Katapol',
          'Kazi Tower',
          'Khayerhuda',
          'Korcha Danga',
          'Koya',
          'Kultola',
          'Madhobkhali',
          'Madhobpur',
          'Minazpur',
          'Mominpur',
          'Monoharpur',
          'Muktarpur',
          'Mukti Joddha Complex',
          'New Market Jiban nagar',
          'Nishchintapur',
          'Pait Tola',
          'Paka',
          'Pathila',
          'Raghunathpur',
          'Raynagor',
          'Senerhuda',
          'Sobul Para',
          'Sree Rampur',
          'Tetulia',
          'TNT para',
        ],
      },
      {
        name: 'Darshana',
        postCode: '7221',
        areas: [
          'Holt Chadpur',
          'Carew & Co. Area',
          'Shampur',
          'Puraton Bazar',
          'Dwosta',
          'Akondo baria',
          'Paranpur',
          'Darshana College Road',
          'Modna',
          'Hotath Para',
          'Rail Para',
          'Shanti Para',
          'Dokkhin Chand Pur',
          'Mubarok Para',
          'Mohammadpur',
          'BgB Camp',
          'Joynogor Border',
          'Fultola',
          'Carew & Co. Area School Para',
          'Mastar Para',
          'Aria',
          'Dudpatila',
          'Hizol Gari',
          'Broshula',
          'Boldia',
          'Chotosulia',
          'Bagampur',
          'Kasto Pur',
          'Rongouil Para',
          'Kutali',
          'Forshad Pur',
          'Nolbari',
          'Sholmari',
          'Uzolpur',
          'Zadabpur',
          'Golap Nogor',
          'Titudha',
          'Nehal Pur',
          'Suzat Pur',
          'Gobor Gara',
          'Chock Para',
          'Khash Para',
          'Girish Nagor',
        ],
      },
      { name: 'Doulatganj', postCode: '7230', areas: [] },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Bagherhat',
    zones: [
      {
        name: 'Bagerhat Sadar',
        postCode: '9300',
        areas: [
          'Traffic mor',
          'Shonatola',
          'Shonatola praimari school',
          'Kara para',
          'Ful tola',
          'Kara para bank ar mor',
          'Pocha dighir par',
          'Mohila School',
          'Magni tola',
          'Badam tola',
          'Asrom ar math',
          'Kara para union parishad',
          'Bagerhat sader upazila',
          'Dosh gombuz morsid',
          'Ronobejoy pur',
          'Majar mor',
          'Katal tola',
          'Shundor ghona',
          'Horin khana',
          'Panch rasta mor',
          'College gate',
          'P.C college',
          'Jadunath',
          'Shommellon ar mor',
          'Staff quarter',
          'Mats ar mor',
          'Nur masjid mor',
          "Boys' school",
          'Old police line',
          'New police line',
          'Khanka shorif',
          'Munigonj khayaghat',
          'Sadar hospital',
          'Munigonj primary school',
          'Munigonj bridge',
          'Hari khali',
          'Dhakhil madrasa',
          'Tatul tola',
          'Hari khali primary school',
          'Khan jahan ali collage',
          'Notun court',
          'D.C office',
          'Nirbachan office',
          'S.P office',
          'V.I.P mor',
          'Passport office Bagerhat Sadar',
          'Mollick ar mor',
          'V.I.P road',
          'Chilta tola',
          'Alia madrasa',
          'Shishu hospital',
          'Mitha pukur par',
          'Shal tola',
          'Amla para',
          'Puraton bazar Bagerhat Sadar',
          'Al islah mor',
          'Sundarban courier service mor',
          'Shadonar mor',
          'D.C bangalo',
          'S.P bangalo',
          'Press ciub',
          'Girls school',
          'Adarsh school',
          'Bagerhat model thana',
          'Rail road',
          'Mukti clinic',
          'Das para',
          'Bagerhat Sadar Stadium',
          'S.A paribahan mor',
          'Monica hall',
          'Basabati',
          'Pani unnayan board',
          'Sorui boro kabristan',
          'Sorui soto kabristan',
          'Basabati mandir',
          'Bus stand Bagerhat Sadar',
          'Shaha para',
          'Shahapara school',
          'Bisic',
          'Doratana bridge',
          'Basabati kali mondir',
          'Basabati police quarter',
          'Basabati pongu hospital',
          'Pouro park',
          'Nagar bajar',
          'Fol potti',
          'Beribadh',
          'Shonali bank',
          'Krishi bank',
          'Rahat ar mor',
          'Muktijoddha market',
          'Puraton Court',
          'Court masjid',
          'Launch ghat',
          'P.T.I mor',
          'Dak banglow mor',
          'Digital X-ray center',
          'Arjun Bahar',
          'Badekarapara',
          'Bagerhat Collectorate',
          'Baitpur',
          'Binetala',
          'Chakshree Bazar',
          'Chalk Kumaria Jola',
          'Churulia',
          'Dashani',
          'Debraj',
          'Denatala',
          'Fatehpur- Bagerhat Sadar',
          'Fulhata',
          'Gajalia-Bagerhat Sadar',
          'Ghoperhat',
          'Ghoshiakhali',
          'Gilatala',
          'Gotapara',
          'Haolar Danga',
          'Hedayetpur',
          'Hermahat',
          'Islamganj',
          'Kafurpura',
          'Kamarhati Depara',
          'Kandapara-Bagerhat Sadar',
          'Kartikdia',
          'Kashimpur Bazar',
          'Khanpur-Bagerhat Sadar',
          'Kodla',
          'Koramara',
          'Magra hat',
          'Malliker ber',
          'Nager Bazar',
          'Nightkhali',
          'Patilakhali',
          'Rangdia',
          'Raghunathpur-Bagerhat Sadar',
          'Rakhalgachi',
          'Sabukhali Bakhergonj',
          'Samaddarkhali',
          'Saira',
          'Shah Auliabag',
          'Sundarghona',
          'Uthkul',
        ],
      },
      {
        name: 'Fakirhat',
        postCode: '9372',
        areas: [
          'Kulia Dar badamtola',
          'Dori talluk',
          'Sixty gombuj station',
          'Polli bidyut office',
          'Bajondar bari mor',
          'Vangor partex company',
          'Jatrapur',
          'Rayenda school and collage',
          'Shingoti',
          'Fakirhat bisso road',
          'Fakirhat bazar',
          'Shantidam ar mor',
          'Shahin School',
          'Dak banglow mor',
          'Fakirhat thana',
          'Fakirhat Hospital road',
          'Upazila mor Fakirhat',
          'Mohila collage Fakirhat',
          'Sheikh Hasina karigori collage',
          'Nuapara',
          'Kata khali',
          'Lockpur',
          'C.M.B bazar',
          'Sri ghat',
          'Gabkhali',
          'Ghatbhog',
          'Hakimpur',
          'Jhalodanga',
          'Kalkalia-Fakirhat',
          'Mansa',
          'Mulghar',
          'Naldha',
          'Piljanga',
          'Raypara-Fakirhat',
          'Satsaiya',
          'Sayed Mohalla',
          'Shattala',
          'Town Noapara',
          'Bhanganpar Bazar',
          'Attraka',
          'Bhattraprotap',
          'Chandarhat',
          'Chulkati',
          'Diganga',
        ],
      },
      {
        name: 'Chitalmari',
        postCode: '9361',
        areas: [
          'Kanda para',
          'Dai para',
          'Sheyal kati',
          'Gojalia',
          'Dhopa khali',
          'BakarGonj',
          'Chatolmary',
          'Chitalmari thana',
          'Chitalmari upozila',
          'Chitalmari Bazar',
          'Chitalmari health complex',
          'Helra bazar',
          'Pas para',
          'Naluya',
          'Borobaria',
          'Boit pur',
          'Chatolmary mas ar arot',
          'Chingari Bazar',
          'Dakkhin Barashia',
          'Hizla',
          'Kalatala',
          'Khasherhat-Chitalmari',
          'Patar Para',
          'Rahamatpur Madrasa',
          'Shailadaha',
          'Umajuri',
          'Teker Bazar',
          'Shibpur-Chitalmari',
          'Barabaria',
          'Aduadihee',
          'Babuganj Bazar',
          'Bangla Bazar-Chitalmari',
        ],
      },
      {
        name: 'Mollahat',
        postCode: '9383',
        areas: [
          'Mollahat',
          'Mollahat upozila',
          'Mollahat thana',
          'Mollahat Bazar',
          'Mollahat Bridge',
          'Mollahat power plant',
          'Hospital mor Mollahat',
          'Bisso road',
          'Petrol pump',
          'Kulia Baraghat Kulia',
          'Machhuar Kul',
          'Kahalpur',
          'Gaola Bazar',
          'Kanchanpur',
          'Kodalia Rangamati',
          'Dariala',
          'Chawlturee',
          'Charkulia',
          'Atjuri',
          'Baraguni Bazar',
          'Nagarkandi',
          'Pak Gangni',
          'Sachiadaha',
        ],
      },
      {
        name: 'Rampal',
        postCode: '9341',
        areas: [
          'Dama',
          'Dama madrasa',
          'Kashampur bazar',
          'Gelatola bazar',
          'Islamabad chipel tola',
          'muktijoddha complex',
          'Bus stand Rampal',
          'Rampal thanar shamne',
          'Rampal ghat',
          'Foyla',
          'Gouramva Bazar',
          'Jhonjhonia hospital',
          'Vanga collage',
          'Rampal-Rampal',
          'Kumlai',
          'Pereekhali',
          'Pipul bunia',
          'Sanbandha',
          'Sanbandha, Sanyasee High School',
          'Amratala',
          'Baburhat',
          'Barakatalia',
          'Barnisayrabad',
          'Bashtali',
          'Bhandar kot',
          'Bhuiar Kandar',
          'Chhota Sanyasee',
          'Sonatunia',
          'Sundarban Girls College',
          'Varasapur',
          'Vekatmaree',
          'Gourambha',
          'Halia',
          'Jhanjhania',
          'Kalekhar Ber',
          'Khejur Mahal',
        ],
      },
      {
        name: 'Mongla',
        postCode: '9351',
        areas: [
          'Chun Khati',
          'Chondro Mohol',
          'Jhonjhonia vanga',
          'Power project',
          'Zero point',
          'Meghna cement factory',
          'Bash bazar',
          'Dikraz',
          'Coast ghat gate',
          'Navi ghat',
          'Malti abashik',
          'Dubai bangladesh cement power house',
          'Madhubi abashik',
          'E.P.Z bondor gate',
          'Mongla bus stand',
          'Mamar Ghat',
          'Chaudhari mor',
          'Salabunia',
          'Moyla pota mor',
          'Mongla thana',
          'Mongla upozila mor',
          'Mongla pourasabha mor',
          'Chalna Ankorage',
          'Baidyamaree',
          'Burirdanga',
          'Chandpai',
          'Chila Bazar',
          'Mongla Port',
          'Dutter Meth',
          'Haldibunia',
          'Mithakhali',
          'Saheber Math',
          'Sundarban',
        ],
      },
      {
        name: 'Kachua',
        postCode: '9310',
        areas: [
          'Botpur school',
          'Fotapur bazar',
          'Goyalmari school',
          'Sign board Kachua',
          'Parinon company',
          'Kochua Zero point',
          'Kochua upozila',
          'Kochua karigori collage',
          'Rakhal bazar',
          'Pastola',
          'Bonogram',
          'Doibo kati bazar',
          'Joka bazar',
          'Amtola bazar',
          'Kalkeni bazar',
          'Goyal mat',
          'Kochuya bus stand',
          'Andharmanik',
          'Badhal Bazar',
          'Bowlpur',
          'Charkati',
          'Fultalahat',
          'Gopalpur-Kachua',
          'Kathaltala',
          'Kumarkhali-Kachua',
          'Sonarkola',
          'Maghia',
          'Masni',
          'Sangdia',
          'Shailkhali-Kachua',
          'Sreepur Banagram',
          'Tangrakhali',
        ],
      },
      {
        name: 'Morelganj',
        postCode: '9320',
        areas: [
          'Morelganj steel bridge',
          'Morelganj bus stand',
          'Morelganj bazar',
          'Morelganj dhalai bridge',
          'Morelganj pourasabha',
          'Habibullah Abad',
          'Jewdhara',
          'Jomaddar para',
          'Kachubunia',
          'Kalibari-Morelganj',
          'Kalikabari Bazar',
          'Maddhayaguatala',
          'Malamgachha',
          'Mangalerhat',
          'Mathbaria-Morelganj',
          'North Baraikhali',
          'Sannasi Bazar',
          'Amtali Bazar',
          'Badshah Miar hat',
          'Baharbunia',
          'Bara Hoglabunia',
          'Baruikhali',
          'Bishareeghata',
          'Chalitabunia',
          'Chhota Vadura',
          'Chingrakhali',
          'Chipabaruikhali',
          'Daibaggahat',
          'Dhan Sagar',
          'Dhepuarpar',
          'Dhuligati',
          'Telisatee',
          'Polerhat',
          'Putikhali',
          'Samdibhanga',
          'Salimgarh Bazar',
          'Sonakhali',
          'Sutalari',
          'Tetul Bari Hat',
        ],
      },
      {
        name: 'Rayenda',
        postCode: '9330',
        areas: ['Rayentha bazar', 'Rayentha bus stand'],
      },
      {
        name: 'Sarankhola',
        postCode: '9330',
        areas: [
          'Bagi Bandar',
          'Janata',
          'Janerpar',
          'Kadomtala',
          'Khontakata Bazar',
          'Nalbunia',
          'North Badhal',
          'Razapur Bazar',
          'Razapur Sharankhola',
          'Sonatala-Sarankhola',
          'Tafalbari',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Jessore',
    zones: [
      {
        name: 'Jessore Sadar',
        postCode: '7400',
        areas: [
          'NAJIRSANGKARPUR',
          'SOSTITOTAH',
          'KHORKI',
          'R.N. ROAD',
          'MONIHAR',
          'MOLLAHPARA',
          'BARANDIPARA',
          'STADIAM PARA',
          'KAJIPARA',
          'HAMIDPUR BAZAR',
          'FOTEHPUR BAZAR',
          'PURATON KOSBA',
          'PALBARI MOR',
          'AROBPUR MOR',
          'CHACRAH BAZAR',
          'PULERHUT BAZAR',
          'KHOLADANGAH',
          'BOCKCHOR',
          'NILGONJ',
          'JUMJUMPUR',
          'MUROLIRMOR',
          'MISSION PARA',
          'NEW MARKET',
          'RAIL GATE',
          'RAI PARA',
          'VATURIAH',
          'MAHIDEAH BAZAR',
          'HASEMPUR BAZAR',
          'MONOHORPUR BAZAR',
          'Cantonment',
          'HALSA BAZAR',
          'DHORMOTOLAH',
          'NEW KHOARTOLAH',
          'KHOARTOLAH',
          'BAHADURPUR',
          'SHEIKH HATI BAZAR',
          'TALBAREAH',
          'LABUTOLAH',
          'VAKUTEAH BAZAR',
          'PTENGGALE',
          'CHOPDAR PARA',
          'NEW BUS TERMINAL',
          'CHACRA CHECK POST',
          'CHATALER MOR',
          'ZERO POINT',
          'COLD STORE',
          'SADEK DAROGAR MOR',
          'ASROM ROAD',
          'CHOWRASTA',
          'SHEIKH RASEL CHOTTOR',
          'UPOSHOR',
          'AIRPORT',
          'Jessore Airbach',
          'Rupdia',
          'Basundia',
          'Churamankathi',
          'Noapara-Jessore Sadar',
          'Rajghat',
        ],
      },
      {
        name: 'Bagharpara',
        postCode: '7471',
        areas: [
          'Tetul Tola',
          'Gaidghat Bazar',
          'Khajura Petrol pam',
          'Kodom Tola',
          'Puler Hat',
          'Mirzapur',
          'Raghobpur Bazar',
          'Shadipur',
          'Bhataram Tola',
          'BAGHARPARA HOSPITAL',
          'KHAJURA BAZAR',
          'NAREKALBARIAH',
          'CHARAVETAH BAZAR',
          'CHATHEANTOLAH BAZAR',
          'Gouranagar',
        ],
      },
      {
        name: 'Jhikargacha',
        postCode: '7420',
        areas: [
          'HazerAli mor',
          'Beji Tola',
          'Narangali',
          'Nowali',
          'Bollah',
          'Panishara',
          'Notun Haat',
          'CHUTIPUR BAZAR',
          'GODHKHALE',
          'Hall road',
          'Thanar mor Jikorhachha',
          'Hospital road Jikorhachha',
          'Kata khal',
          'Jhikargachha Bazar',
          'Amrita Bazar',
          'Bodhkhana',
          'Digdana',
          'Dumurkhali',
          'Gadkhali',
          'Gazir Darga Foyzabad',
          'Gonganandapur',
          'Haalsa',
          'Jhikargachha',
          'Kayemkhola Bazar',
          'Mathshia',
          'Mirzaapur',
          'Modanpur',
          'Moshchim Nagar',
          'Palla Bazar',
          'Pashapole',
          'Raghunath Nagar',
          'Sharaskathi',
          'Shimulia Gopinathpur',
          'Shiordaha',
          'Sholakhada',
          'Sonakur Bakra',
          'Kashipur Bazar',
          'Mishori Deora',
          'Mahammadpur',
          'Bangda',
          'Paksia Bazar',
          'SharaTola',
          'Gourpara',
          'Gatipara',
          'Shreekona',
          'Nijampur',
          'Shuborno khali',
          'Kazir Ber',
          'Beneali',
          'Hazirali',
          'Bezitola',
          'Malopara',
          'Bollah Bazar',
          'Borne Bazar',
        ],
      },
      {
        name: 'Sarsa',
        postCode: '7433',
        areas: [
          'Uloshe',
          'Kuchomura',
          'Harikhale',
          'Jamtola',
          'Aam Tola',
          'Mati Kumra',
          'Brishi Para',
          'Olakol Bazar',
          'Pach Faiba',
          'Sathmile',
          'Goga Bazar',
          'Boshotpur',
          'KathalTola',
          'Putkhali Boarder',
          'Baroputa',
          'Balunda',
          'Kagmari',
          'Bolputa',
          'Panta Para',
          'Ram pur',
          'Sarsa Bazar',
          'NAVARON SATKHERA MOR',
          'Ghour Para',
          'Collage mor Sarsha',
          'NAVARON COLLAGE GATE MOR',
          'SARSHA THANA MOR',
          'Bahadurpur',
          'KAGOJPUKUR',
          'Phut Khali',
          'Choto achra',
          'BENAPOLE BAZAR',
          "Custom's house",
          'Jadabpur',
          'Bag Achra',
        ],
      },
      {
        name: 'Benapole',
        postCode: '',
        areas: [
          'Naraonpur',
          'Purabari',
          'Boileputa',
          'Shikarpur',
          'Lokhonpur',
          'Shakariputa',
          'Shikri Bottola',
          'Aamrakhali checkpost',
          'Benapole Bus stand',
          'Old Bus stand',
          'Rahman Chamber',
        ],
      },
      {
        name: 'Keshobpur',
        postCode: '7450',
        areas: [
          'Ahsan pur',
          'Mongol khot',
          'Verse police Camp',
          'Vallok ghor',
          'Chingra Bazar',
          'Bel tola',
          'Sath Baria Bazar',
          'PAJIAH',
          'TRIMOHINEH',
          'SAGORDARI',
          'KOLAHGASI',
          'Keshobpur',
        ],
      },
      {
        name: 'Monirampur',
        postCode: '7440',
        areas: [
          'Kalibari',
          'Kopalia',
          'Katakhali',
          'Nengurhat',
          'Shundor Pur',
          'Chalki Danga',
          'Chaklakathaltola',
          'Shutikata',
          'Begariitola',
          'Khordo Bridge',
          'Chalte Danga',
          'Khedapara',
          'Bagerhat',
          'Vandaria',
          'Helanchi',
          'Par khajura',
          'Bag Doop',
          'Polashi',
          'MOHONPUR',
          'MOHISHATE',
          'CHENATHOLAH',
          'AUREH',
          'DHAKUREAH',
        ],
      },
      {
        name: 'Obhoynagar',
        postCode: '',
        areas: [
          'PREMBEG GATE',
          'KHOARTOLAH BAZAR',
          'CHINUTEAH BAZAR',
          'PACH KOBOR',
          'BENGGAL GATE',
          'NOAHPARA STATION BAZAR',
          'NURBAG MOR',
          'PROFESAR PARA',
          'MODEL SCHOOL',
          'HAJI MARKET',
          'Vangga gate',
          'Shundoli',
          'L B TOWER',
          'Bhat villa',
          'Shingia',
          'Rupdia Norendrapur',
          'Chengutia',
          'Rupdia',
          'RAJGHAT',
          'Bashundia',
          'GOVERNMENT HOSPITEL',
          'MOHILAH COLLAGE GATE',
          'ALIPUR MOR',
          'PALSE HOSPITEL',
          'AKIJ CITY',
          'TALTOTAL MOR',
        ],
      },
      {
        name: 'Chaugachha',
        postCode: '7410',
        areas: [
          'JUST',
          'Shingho bule',
          'CHOWGASAH BAZAR',
          'PUROH PARA',
          'SHOLUAH BAZAR',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Jhenaidah',
    zones: [
      {
        name: 'Jhenaidah sadar',
        postCode: '7300',
        areas: [
          'Goyalpara Bazar',
          'Madhupur Bazar',
          'Narikelbariya Bazar',
          'Jhenaidah Termenal',
          'Arappur Bus Stand',
          'Amtola Bazar',
          'Mojib chottor',
          'Nagarbatan Bazar',
          'Holidani Bazar',
          'Dakbangla Bazar',
          'Hamdha Bus Stand',
          'Hamdah bypass',
          'Sodor Hospital',
          'Paira chottor',
          'Kola Bagan',
          'Modern More',
          'Chaklapara',
          'Judge court',
          'Baparipara',
          'Mester Para',
          'Ukilpara',
          'Khancannagor',
          'Paglakanai',
          'Khancanpur',
          'Parkpara',
          '3 No panir Tang',
          '2 No panir Tang',
          '1 No panir Tang',
          'Bakultola',
          'Upjelaporisod',
          'Steduam',
          'Police Line Jhenaidah sadar',
          'Soto Kamarkundo',
          'Boro Kamarkundo',
          'Jinaidaha Cadet College',
          'Naldanga',
          'Hatbar Bazar',
        ],
      },
      {
        name: 'Harinakundo',
        postCode: '7310',
        areas: [
          'Aktara More',
          'Upozilla porishad Harinakundo',
          'Lalsha college',
          'Mohila college',
          'Pilot school',
          'Bhabanipur',
          'Satbrige',
          'Jorada Bazar',
          'Mothurapur',
          'Chadpur Bazar',
          'Shakharidah Bazar',
          'Caratola Bazar',
          'Sadhuhati Bazar',
          'Riskali Bazar',
          'Dokolpur Bazar',
        ],
      },
      {
        name: 'Shailokupa',
        postCode: '7321',
        areas: [
          'Garagonj Bazar',
          'Shailokupa sadar',
          'Coror bil Bazar',
          'Sekpara Bazar',
          'Kabirpur',
          'Haspatal more',
          'Kaddo gudam',
          'Upozilla porishad Shailokupa',
          'Haspatal more',
        ],
      },
      {
        name: 'Kaligonj',
        postCode: '7350',
        areas: [
          'BaroBazar',
          'Naldanga Resote',
          'Mubarak cinir Mail',
          'Main Bus Stand',
          'Nimtola',
          'Haspatal more Kaligonj',
          'Upozilla porishad Kaligonj',
          'Kaligonj thana',
          'Pilot school Kaligonj',
          'Kula stand',
        ],
      },
      {
        name: 'Mohespur',
        postCode: '7340',
        areas: [
          'Khalispur Bazar',
          'Haspatal More Mohespur',
          'Panta Para',
          'Mohespur Micro Stand',
          'Jinnahnagar',
          'Mohespur Mohila College',
          'Mohespur upjela',
          'Mohespur Bus Stand',
          'Mohespur Grils school',
        ],
      },
      {
        name: 'Kotchandpur',
        postCode: '7330',
        areas: [
          'Haspatal More Kotchandpur',
          'College Stand Kotchandpur',
          'Dudsora Bazar',
          'Grils School Kotchandpur',
          'Boro Mosjid More',
          'Boro Bazar Kotchandpur',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Khulna',
    zones: [
      {
        name: 'Khulna Sadar',
        postCode: '9202',
        areas: [
          'Farighat',
          'Haji mohshin road',
          'Ahsan ahmed road',
          'PTI more',
          'South central road',
          'Deben babu road',
          'Beni babu road',
          'Samsur rahman road',
          'Gagon babu road',
          'Coustom ghat',
          'Natun bazar',
          'Rupsha stand road',
          'Rupsha ghat',
          'Purbobaniya khamar',
          'Lower jessore road',
          'Khan jahan ali road',
          'Tank road',
          'Zilla school',
          'Munshipara',
          'Voirob stand road',
          'Boro bazar',
          'Cemetery road',
          'Dhormoshova road',
          'Mirzapur',
          'Picture place more',
          'Forest ghat',
          'Feri ghat',
          'Modern furnitur road',
          'Baiti para',
          'Babu khan road',
          'Khan a sabur road',
          'Railway market',
          'Helatola',
          'kali bari ghat road',
          'Mohashin more',
          'Khulna GPO',
          'Sher-a bagnla road',
          'Khanjahan Ali',
          'Sachibunia',
          '250 Bed,khulna medical',
          'Shamsur rahman road',
          'KDA new market',
          'Chitrali mor',
          'Shipyard',
          'Khulna BIT',
          'Siramani',
          'Jahanabad Canttonmen',
          'Sonali Jute Mills',
          'Atra Shilpa Area',
          'Harintana',
        ],
      },
      {
        name: 'Rupsha',
        postCode: '9201',
        areas: [
          'Mistri para bazar road',
          'Poshchim baniakhamar',
          'Royel more',
          'Riupsha bridge',
          'Matha vanga',
          'Labonchora',
          'Tutpara',
          'Rupsha daroga leage',
          'Poshchim tutpata',
          'Chanmari bazar',
          'Zinna para',
          'Ray para',
          'Dolkhola',
          'TB boundary road',
          'Shipyeard',
          'Tut para main road',
          'Taltola',
          'Ray para cross road',
          'Musolman para',
          'Krishtan para',
          '7 rastar more',
          'Moulovipara',
          'Foraji para',
          'Hadis Park',
          'Kotoali Thana',
          'Ram Chandra Das lane',
          'Glaxor more',
          'Sadar Hospital Road',
          'Khulna KCC Market',
          'Dashpara',
          'Daroga para',
          'Lohar gate',
          'Alaipur',
          'Aichgati',
          'Amtola-Rupsha',
          'Anandanagar',
          'Elaipur',
          'Harikhali',
          'Jabusha',
          'Joyar',
          'Kachdia bazar',
          'Katakhali-Rupsha',
          'Kudirbottola',
          'Lokhpur',
          'Mushigoni',
          'Palerhat',
          'Pithevog',
          'Shekpuro',
          'Shirgati',
          'Shrifatala',
          'Vobani pur',
          'Belphulia',
        ],
      },
      {
        name: 'Sonadanga',
        postCode: '',
        areas: [
          'Basupara',
          'Chiruni factory',
          'Moylapota',
          'Poi para',
          'Bashtolar more',
          'Atim khanar more',
          'Siddikiya moholla',
          'Bismillah moholla',
          'Metopol',
          'Banorgati',
          'Ali club er more',
          'Azad laoundry',
          'Aolayman para',
          'Kholil chamber',
          'Kda avenue',
          'Goborchaka',
          'Pollimangol',
          'Boubazar',
          'Rail station road',
          'Nobopolli',
          'Nirala',
          'Prantika',
          'Khulna university',
          'Krishno nagor',
          'Mohammad nagor',
          'Chourasata',
          'Kashem Nagor 1,2,3,4',
          'Gollamari',
          'Bagmara',
          'Riyabazar',
          'Raypara',
          'Bokshipara',
          'Arambag',
          'Alamin Mohollah',
          'Puraton Gollamari',
          'Sher-E-Bangla Road',
          'North Khal Bank Road',
          'Andhir Pukur',
          'Zero Point Sonadanga',
          'New market Sonadanga',
          'Shibbari',
          'Dalmil More',
          'Shaikhpara',
          'Sonadanga Phase 1',
          'Sonadanga Phase 2',
          'Sonadanga Phase 3',
          'Hasanbag Lane 1,2,3',
          'Korim Nagar',
          'Kha Barir More',
          'Sonadanga Bus Stand',
          'Sonadanga Bypass Road',
          'Iqbal Nagor',
          'Khajur Tola More Road',
          'Rayer Mohol Dokkhin Para',
          'Alishan Er Mor',
          'Truck stand',
          'Shabarir Mor',
          'Khulna Nobinogor',
          'Shaikhpare Chamrapotti',
          'Sonadanga Girza Road',
          'Sonadanga Thana Road',
          'Kalvat er Mor',
          'Sobujbag lane 1,2,3',
          'Darul Alam Mosjid Road',
        ],
      },
      {
        name: 'Boyra',
        postCode: '9290',
        areas: [
          'Boyra 1 No Gate',
          '250 Bed',
          'Grameen Abashik',
          'Khalashi',
          'Boyra Bazar',
          'Madrasa Road',
          'Vanga Pole',
          'Member Bari',
          'Farukiya Moshjid Road',
          'Farukiya Moshjid Cross Road',
          'Aziz Er More',
          'Khokon Commisoner More',
          'Rayer Mohol',
          'Switch Gate',
          'Matobbar Bazar',
          'Dhansiri Abashik',
          'Sobur Er More',
          'Pal Para',
          'Polish Fari Boyra',
          'Police Line Boyra',
          'Mujgunni Abashik',
          'Mujgunni Park Road',
          'Gmb Colony',
          'Abu Naser Hospital Road',
          'Labi Tolar More',
          'Kobir Bottola',
          'Sabuj Sangho',
          'Karikorpara',
          'Chunur Bottola',
          'Jessore More',
        ],
      },
      {
        name: 'Khalishpur',
        postCode: '9000',
        areas: [
          'Khalish Pur Ghat Area',
          'Pouroshovar More',
          'Khalishpur Natun Thana',
          'Custome Office',
          'Tin Tola',
          'Manushir More',
          'Megar More',
          'Mohashin College Road',
          'Pouroshovar More',
          'Noyabati',
          'Moti Masjid Road',
          'Chittrali Bzar',
          'Dirbar Songho Club Road',
          'Alam Nagor',
          'Chorer Hat',
          'Navy Colony',
          'Housining Bazar',
          'Newsprint Mill',
          "Pepol'S Gate",
          'Crecent Jute Mill',
          'Nave Gate 2 Area',
          'Platinum Jute Mill',
          'Kodomtola',
          'Polytaknik More',
          'Boikali',
          'Dolar Housing',
          'Jongshong More',
          'Ad Din',
          'Goyalkhali',
          'Patka Bazar',
          'Nartun Rasta',
          'Nur Nagor',
          'Munshibari',
          'Srishnagor',
          'Amtolar More',
          'Mosttor More',
          'Gadon Para',
          'Khalishpur Park Road',
          'Nazir Ghat',
          'Driver Golli',
          'Rajdhanir More',
          'Banglar More',
          'Anjuman Road',
          'Bokultolar More',
          'Alfaruk Er More',
          'Hafij Nagar',
          'Islam Nagor',
        ],
      },
      {
        name: 'Daulatpur',
        postCode: '',
        areas: [
          'Baypass',
          'Sholua Bazar',
          'Thukra Bazar',
          'Rongpur Bazar',
          'Shapur Bazar',
          'Mikshimil Bazar',
          'Moheswarpasa kalibari',
          'Moddhodanga',
          'Asad er mor',
          'Bou bazar',
          'Gazir mor',
          'Religate',
          'Sahebpara',
          'Police fari',
          'Matherbari School',
          'Zia colege mor',
          'Munishpara',
          'De para',
          'Maiktola',
          'Molla barir Mor',
          'Palpara',
          'Jora pach Tola',
          'Mohashin More',
          'Deyana Dokkhin Para',
          'Deyana Uttor Para',
          'Deyana Purbo Para',
          'Deyana Poshchim Para',
          'Soshi Vushon Road',
          'Deyana Moddho Para',
          'Dofadar Para',
          'Tin Dokan More',
          'Haspatal Mor',
          'Mollar More',
          'Bonik Para',
          'Day Night College Road',
          'Saha Para',
          'Boiragi Para',
          'Ideal School Road',
          'Aronghata',
          'Krishi College Daulatpur',
          'Daulatpur Bazar',
          'Bl College Road',
          'Daulotpur Bus Stand Road',
          'Kha Para',
          'Afiluddin School Road',
          'Pabla',
          'Boyra Png Colony',
          'College Boundary Road',
          'Boyra Mohila College Road',
          'Mohashin More',
          'Krishi collage',
          'Gaikur',
          'Arongghata',
        ],
      },
      {
        name: 'Fultola',
        postCode: '9210',
        areas: [
          'Kuet',
          'Fulbarigate',
          'Poket Gate Road',
          'Raligate',
          'Moshassarpasa',
          'Kalibari',
          'Gajir More',
          'Sikarir Kor',
          'Shiromoni',
          'Mirerdanga',
          'Kulibagan',
          'Attra Industrial Area',
          'Fultola',
          'Ptherbazar',
          'Damudar',
          'Mcsk',
          'Garishon',
          'Dockter Bari',
          'Gilatola Mokktar More',
          'Cantonment Fultola',
          'Shikirhat',
          'Upojilla',
          'Pother Bazar',
          'Eastern Gate',
          'Bejar Danga',
          'Barmashil',
          'Atapollan',
          'Shiromoni Chal Potti',
          'Aphilgate',
          'Father bazar',
          'Phultala stand',
          'Fulbarigate Bazar',
          'Kazibarir Mor',
          'Khanabari',
          'Sheikh Chottor',
          'Rongmil Road',
          'Katholik Colony',
          'Bonikpara',
          'TTC',
          'Golokdham',
          'Pochimpara',
          'Boroitola Ghat',
          'Senpara',
          'Jabdipur',
          'Jogipul',
          'Police Training Center',
          'RRF',
          'Cabols ghat',
          'Gaffar food er mor',
          'Shiramoni Abashik',
          'Shiramoni Bazar',
          'Anish Morol Sorok',
          'Islami bank road',
          'Chal potti Road',
          'Mudi pottie Road',
          'Tetul tola Road',
          'Moddhopara',
          'Uttorpara',
          'Dokkhinpara',
          'Linda Clinic',
          'Mirbari',
          'Madrasa Road',
          'Shiromoni hechari',
          'Cantonment public school gate',
          'Sonatola Ghat',
          'Jahanabad Cantonment',
          'Latex',
          'Doctor Bari',
          'Afilgate',
          'BKSP',
          'Stan Gate',
          'Moshiali',
          'Potherbazar',
          'Stetion Road',
          'Damudor Mondir',
          'Damudor High school',
          'Damudor Primery School',
          'Notun Hat',
          'Upozila Helth Complex',
          'Upozila Mor',
          'Alka',
          'Gofur Memorial hospital mor',
          'Super filling Stetion',
          'Fultola bazar',
          'Jamrultola',
          'Sikirhat',
          'Rofik Sorok',
          'Hazi market',
          'Reunion School',
          'Beltola ghat',
          'Fultola MM collage',
          'Fultola National School Mor',
          'Super Jute Mil',
          'Bejerdanga',
        ],
      },
      {
        name: 'Dumuria',
        postCode: '9252',
        areas: [
          'Sahapur Bazar',
          'Koya bazar',
          'Gutudia Bazar',
          'Dumuria Bazar',
          'Tipna Bus stand',
          'Khornia Bazar',
          'Khataltola bus Stand',
          'Chuknagar Bazar',
          'AtharoMile Bazar',
          'Sajiara',
          'Sajiara',
          'Ghonabanda',
          'Shahapur',
          'Shorafpur',
        ],
      },
      {
        name: 'Batiaghata',
        postCode: '9201',
        areas: [
          'Suchibunia Bazar',
          'Darogar Vita',
          'Chokrokhali Bazar',
          'Mollik Mor',
          'Shoulmari Bridge',
          'Pollibiddut',
          'Batiaghat Bazar',
          'Nijkhamar Bazar',
          'Hogladanga Bazar',
          'Batiaghat',
          'Shurkhali',
          'Chokrakhali',
          'Sachibunia',
          'Surkalee',
        ],
      },
      {
        name: 'Digholia',
        postCode: '9220',
        areas: [
          'Senhati Bazar',
          'Upozelar mor',
          'Pother bazar',
          'Aronghata Bazar',
          'Chandni Mahal',
          'Chandrani',
          'Ghoshghati',
          'Gazirhat',
          'Upozilar Mor',
          'Digholia MM College',
          'Ghosgati',
          'Barakpur',
        ],
      },
      {
        name: 'Tala Upozilla',
        postCode: '',
        areas: [
          'Tetulia Bus Stand',
          'Shapur Bus Stand',
          'Muktijudda College More',
          'Mela Bazar',
          'Tala Bazar',
          'Gonali Bazar',
          'Kashimnagar Bazar',
        ],
      },
      {
        name: 'Paikgachha Upozila',
        postCode: '9282',
        areas: [
          'Golabari Bus Stand',
          'Horidhali Bus stand',
          'Dorga Mohol Bus stand',
          'Akorghata Bazar',
          'Godaipur Bus Stand',
          'Notun Bazar',
          'Boyeli Mor',
          'Paikgasa Bus stand',
          'Godaipur',
          'Kapilmoni',
          'Katipara',
          'Chandkhali',
          'Garaikhali',
        ],
      },
      { name: 'Dacope', postCode: '9270', areas: ['Dakop', 'Nalian'] },
      { name: 'Terokhada', postCode: '9230', areas: ['Pak Barasat'] },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Kustia',
    zones: [
      {
        name: 'Kushtia Sadar',
        postCode: '7000',
        areas: [
          'Bojlurmor',
          'Kutipara',
          'Horishongkorpur',
          'Milpara',
          'Borobajar',
          'Courtpara',
          'Narikeltala',
          'Goshala',
          'Rabgoli',
          'Kataikhana mor',
          'College mor Kushtia Sadar',
          'Omor Tower',
          'Hospital mor Kushtia Sadar',
          'Eidgah para',
          'Peyaratola',
          'Habibul Basar goli',
          'IU Club Goli',
          'Sonar Bangla Moshjid Road',
          'Muktir Pukur',
          'Jail Khana Mor',
          'Mollar Teghoria',
          'Housing Kushtia Sadar',
          'Kodomtola Kushtia Sadar',
          'Nishan Mor',
          'Chadagara',
          'Lahini Bottola',
          'Markas Moshjid Goli',
          'Jamtola Mor',
          'Boubajar',
          'Mohilar Dokan',
          'Khan er Dokan',
          'Kalisongorpur',
          'Koshaipara',
          'Gurukul School',
          'Jogoti',
          'Kumargara',
          'BRB',
          'Bot Tol Vadalia Bajar, Alampur Bajar',
          'NS Road',
          'Singer Mor',
          'Mojjem Store',
          'Girls School Kushtia Sadar',
          'Puraton SA Paribahan er Goli',
          '5 No School',
          'Moubon',
          'Islamia College',
          'Sot Songo Mondir',
          'Burmese Goli',
          'Thana Mor',
          'Mannan Heart Hospital',
          '6 Rastar Mor',
          'Ad-Din Hospital',
          'Matri -Sonod Shishu Hospital',
          'Rain week Mor',
          '5 Rastar Mor',
          'Sundor Bon Curier Service',
          'Jugia Palpara',
          'Madar-Sha Mor',
          'Kana Biler Mor',
          'Tirimohoni Bajar',
          'Bypass Mor Kushtia Sadar',
          'Police Line Kushtia Sadar',
          'Mojompur',
          'Purbo Mojompur',
          'Hajir Goli',
          'Bonani Cinema Hall',
          'Jhao Tola Mor',
          'Zilla School Kushtia Sadar',
          'Saddam Bajar',
          'DC Court',
          'SP Banglo',
          'PTI Road Kushtia Sadar',
          'Custom Mor',
          'Upozilla mor Kushtia Sadar',
          'BRB Hospital Kushtia Sadar',
          'Komlapur',
          'Kushtia Mohini',
          'Jagati',
        ],
      },
      {
        name: 'Kumar Khali',
        postCode: '7010',
        areas: [
          'Train Station',
          'Kundu Para',
          'Upozilla Road',
          'Thana Mor Kumar Khali',
          'Bus Stand Kumar Khali',
          'Chal Potti',
          'Paner Bjar',
          'Torun Mor',
          'Kali Mor',
          'Panti Bajar',
          'Joduboira',
          'Bash Gram',
          'Chourongi Bajar',
          'Naturia Bajar',
          'Vorua para bajar',
          'Choler Mor',
          'Durbachara Bajar',
          'Komlapur Bajar',
          'Badh Bajar',
          'Dakbanglo Kumar Khali',
          'Lahini Para',
          'Alauddin Nogor',
          'Robindro Kutibari',
          'Lalon Sha Majar',
          'Cheoria',
          'Mondol Para Kumar Khali',
          'Joina Bad Bajar',
        ],
      },
      {
        name: 'Khoksha',
        postCode: '7020',
        areas: [
          'Khoksha Bajar',
          'Hall Bajar',
          'SomeshPur',
          'Chokiripur',
          'Bilani Bajar',
          'Janipur',
          'Kokhsha hospital',
        ],
      },
      {
        name: 'Mirpur',
        postCode: '7030',
        areas: [
          'Haru Mor',
          'Kapur er Hat',
          'Poradhao Rail Station',
          'Ail chara Bajar',
          'Halsha Bajar Mirpur',
          'Hazrahati',
          'Fakirabad',
          'Bolidapara',
          'Moshan Bajar',
          'Nowapara bajar',
          'BGB Camp Mirpur',
          'Zia Road',
          'Mirpur Bajar',
          'Upozilla mor Mirpur',
          'Chithulia Bajar',
          'Nimtola Bajar',
          'Arif Sorok',
          'Amla Bajar',
          'Sodorpur Bajar',
          'Amla Police Camp',
          'Katlamari bajar',
          'Kholisha Kundu bajar',
        ],
      },
      {
        name: 'Daulatpur',
        postCode: '7320',
        areas: [
          'Dowlotpur thana',
          'Eagle Cottor',
          'Hospital mor Daulatpur',
          'Taragunia Bajar',
          'Rafayetpur',
          'Khasmathurapur',
          'Taragunia bus Stand',
          'Taragunia Thanar Mor',
        ],
      },
      {
        name: 'Bheramara',
        postCode: '7041',
        areas: [
          'Bheramara Junction',
          'Shapla Chottor',
          'Thanar Mor Bheramara',
          'Godown Mor Bheramara',
          'Bok Chottor',
          'Power Plant',
          'Mohila College Bheramara',
          'Allah er Doroja',
          'Pilot High School',
          'Ganges Bheramara',
        ],
      },
      {
        name: 'Islamic University',
        postCode: '',
        areas: [
          'Santidanga',
          'Shaikpara Bajar',
          'Horinarayan Pur bajar',
          'Kanchan Pur',
          'Baradi',
          'Shibpur Islamic University',
          'Laxmipur Bajar',
          'Modhupur Bajar',
          'Sugribpur',
          'Purbo Abdal Pur',
          'Bittipara Bajar',
          'Ujan Gram Bajar',
          'Jhaodia Bajar',
          '11 Mile',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Magura',
    zones: [
      {
        name: 'Magura Sadar',
        postCode: '7600',
        areas: [
          'Stadium Para',
          'IT- Park',
          'Circuit House Magura',
          'LGED Office',
          'Vaina mor',
          'Santibag',
          'Upozila para',
          'Police Line Magura Sadar',
          'Karikor para',
          'Balir Math',
          'Kukna',
          'Alamin Para',
          'Akh centre',
          'Saji-ara',
          'Poly Technical College',
          'Itkhola',
          'Abal pur',
          'Defulia',
          'Choto Bridge',
          'Kasinathpur',
          'Isa-kada',
          'Nori-Hati',
          'Hazipur',
          'Hazrapur union',
          'Mirjapur',
          'Saitiris',
          'Alom Khali',
          'Fulbari',
          'Doar par (atim khana)',
          'Sibrampur',
          'Pakar matha',
          'Boddi bari',
          'Athar khada',
          'Kolkolia para',
          'Mistri para',
          'Batikadanga',
          'Saha-para',
          'Bottola Magura',
          'Notun Bazar',
          'Beltola Magura',
          'Lichu tola',
          'Kesob mor',
          'Mohila college para',
          'Zila para',
          'Bash tola',
          'Haji Saheb Road',
          'M.R. Road',
          'Chowrongi mor',
          'Islampur para',
          'Nijnanduali',
          'Tengakhali',
          'Soyed Ator Ali road',
          'Juta potti',
          'Council Para',
          'Hospital para',
          'Pouroshova',
          'Vaina Koborsthan Road',
          'PTI para',
          'Poshu Hospital para',
          'Puraton Bazar',
          'Thana para Magura Sadar',
          'Jamrul tola',
          'Dhakar Road',
          'Parnanduali',
          'Satdoha Para',
          'Tatipara',
          'Kacha maler arot',
          'Khan Para',
          'Molla para',
          'Parla',
          'Khalkul para',
          'Ghora nas',
          'Gobindopur',
          'Ghop danga',
          'Jogdol',
          'Amuria',
          'TNT office',
          'Textile Mill gate',
          'Vitasar',
          'Katakhali',
          'kather Bridge',
          'Malik gram',
          'Dhorla',
          'Chad pur',
          'Lokkhikandor',
          'Ramnogor',
          'Kushundi bazar',
          'Alokdia',
          'Boroi bazar',
          'Barasia',
          'BorunaTol',
          'Sheikh Para',
          'Moghir Dhalu',
          'Aroo para',
          'Raghob dair',
          'Jagla',
          'Magura sadar',
          'Bangladesh Krisi Bank',
          'Malonchi',
        ],
      },
      {
        name: 'Sreepur',
        postCode: '7610',
        areas: [
          'Borishat',
          'Gangnalia Bazar',
          'Khamarpara Bazar',
          'Sreepur Bazar',
          'Degree College',
          'Nakole Stand',
          'Hanifer Mor',
          'Barishat',
          'Dui-shotine',
          'Goyaspur',
          'Joka',
          'Rajapur',
          'Hogoldanga',
          'Kollyanpur',
          'Tarawjial',
          'Dariapur',
          'Chowgasi',
          'Matikata',
          'Aschorjopur',
          'Langolbadh Bazar',
          'Kazoli',
          'Amtoli',
          'Komlapur',
          'Tupi para',
          'Sasilapur',
          'Gopalpur',
          'Kosua',
          'Fultola',
          'Tokholpur',
          'Sobdalpur',
          'Radhanogor',
          'Ghasiara',
          'Nakole Bazar',
          'Owapda Bazar',
        ],
      },
      {
        name: 'Mohammadpur',
        postCode: '7630',
        areas: [
          'Hore krisnopur bazar',
          'Mohammadpur Bazar',
          'Notun Bridge',
          'Urora',
          'Tollabaria',
          'Kalupara',
          'Bethulia Bazar',
          'Hatbaria',
          'Chowbaria',
          'Raipur',
          'Dhuljuri Bazar',
          'Kashipur',
          'Urura',
          'Kalukandi',
          'Bethuliya',
          'Polashbariya',
          'Lahuriya',
          'Kaligonj',
          'Mondolgathi',
          'Kholishkhali',
          'Fullbariya',
          'Shikdar mor',
          'Kolomdhari',
          'Shotrujitpur',
          'Goalbathan',
          'Mono khali',
          'Korsa danga',
          'Digha',
          'Dumursia',
          'ThakurerHat',
          'Khalia',
          'Pukhuria',
          'Binodpur',
          'Kanutia',
          'Awnara',
          'Babukhali',
          'Nagra',
          'Panighata',
          'Shottijitpur',
          'Rajapur',
          'Nohata',
          'Jhama Bazar',
          'Dangapara',
          'Polita Bazar',
          'Gongarampur',
        ],
      },
      {
        name: 'Shalikha',
        postCode: '7620',
        areas: [
          'Degree College Magura',
          'Junarir Mor',
          'Thana gate',
          'Vobonhati',
          'Sotokhali',
          'Simakhali',
          'Salikha Bazar',
          'Bunagati',
          'Singra',
          'Choturbari',
          'Deruabari',
          'Naghousa',
          'Sanra',
          'Sablate',
          'Puskhali',
          'Tilkhori',
          'Pathorghata',
          'Talkhori',
          'Sonadanga',
          'Sat Nafuria',
          'Sildanga',
          'Piyerpur',
          'Dhonessorgati',
          'Pujgram',
          'Kabasoti',
          'Tila',
          'Najimpara',
          'Katoli',
          'Jonari',
          'Horispur',
          'Puragasi',
          'Gopalgram',
          'Shalikha Dighi',
          'Siret',
          'Jonari',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Meherpur',
    zones: [
      {
        name: 'Meherpur Sadar',
        postCode: '7101',
        areas: [
          'Mukharji para',
          'Mondol para',
          'Dighir para',
          'Chokro para',
          'Kathuli Bus stand',
          'Berpara',
          'Saji para',
          'Tati para',
          'Shekh para',
          'Hothat para',
          'Thana para Meherpur Sadar',
          'Bosh para',
          'Ghos para',
          'Haldar para',
          'Nilmoni Hol para',
          'boro Bazar',
          'hotel Bazar',
          'Piyada para',
          'post offiec para',
          'Court para Meherpur Sadar',
          'Bamon para',
          'Jellkhana',
          'Khondokar para',
          'Raipur Meherpur Sadar',
          'Studium para',
          'new post offiec para',
          'Gorosthan para',
          'College mor Meherpur Sadar',
          'Old bus stand para',
          'new bus stand para',
          'Jadob pur rod',
          'Samsujjaha park',
          'Nimtola',
          'Chulkuni mor',
          'Meherpur Sadar porosova',
          'kasob para',
          'poro college para',
          'sona potti goli',
          'Miya para',
          'Fozdari para',
          'Poro eidga para',
          'Ofda mor',
          'Meherpur Sadar College road',
          'Govipur',
          'Amdah',
          'Sosan ghat',
          'Meherpur Sadar Police line',
          'Meherpur pollibiddut',
          'Amjupi Bazar',
          'Chadbil',
          'Baradi bazar',
          'asraf pur',
          'T N T rod',
          'gopalpur',
          'Modna danga',
          'Aolmpur',
          'Fulbagan para',
          'Kala Chadpur Road',
          'Thana Road Meherpur',
          'Joha Mor',
          'Gor Para',
          'Paik para',
          'Haji para',
          'Asia net Mor',
          'Kali Mondir Mor',
          'Mohila college road',
          'Katai khana Road',
          'Dighir Mor',
          'City College Road',
          'SA Poribihon Road',
          'PW Mor',
          'BRTA Road',
          'Alia Madrasha Road',
          'LGED Road',
          'BADC Road',
          'Khamar Bari',
          'Tafick Mor',
          'Kacha bazar',
          'Fire Servec Road',
          'TTC Road',
          'PTI Road',
          'Fling station mor',
          'bokul tola',
          'tetul tola',
          'kathal tolA',
          'Mollik pukur',
          'Darus salam Road',
          'Mujib Nagar Complex',
        ],
      },
      {
        name: 'Gangni',
        postCode: '7110',
        areas: [
          'garadob Rich Mill',
          'Purapara Bazar',
          'Basbariya Bazar',
          'Gangni Holpara',
          'bon bivag para',
          'Sishir para',
          'Degree college rode',
          'Ab. Salam Rod',
          'Chowgasa',
          'Haspatal Bazar',
          'Upozilla porishod',
          'Sondhani college road',
          'Gangni Kathili bus stand',
          'Gangni bus stand',
          'Gangni Bazar',
          'Thana road Gangni',
          'RAB camp',
          'Purbo Malshadah',
          'Shaharbati',
          'nowpara',
          'Vatpara',
          'Kulbaria',
          'HijolBaria',
          'Hinda',
          'Vumordah',
          'Dhormo Chaki',
          'Changgara',
          'Polashi para',
          'Koromdi',
          'Tetulbariya',
          'Sohoratola',
          'Haravangga Bazar',
          'sahebnogor Bazar',
          'Kazipur golam bazar',
          'Nowdapara Bazar',
          'Kollan pur bazar',
          'Bamundi Bazar',
          'Tirael Bazar',
          'Zorpukur Bazar',
          'Choktula Bus Stand',
          'Solo taka',
          'Satiyan Bus stand',
          'Baot Bazar',
          'Gangni alia madrasha rod',
          'Gangni zela porishod market',
          'sp plaza gangni',
          'posshim malshadah',
          'gangni poroshova',
        ],
      },
      {
        name: 'Mujibnagar',
        postCode: '7102',
        areas: [
          'Coksampur',
          'chok Samnogor',
          'Dariyapur Bazar',
          'Mujibnogor Fling station',
          'Kedar gongz Bazar',
          'Mujibnogor Helth Sentar',
          'Mujibnogor Thana',
          'Mujibnogor Bazar',
          'Nurpur Mujibnagar',
          'Islampur Mujibnagar',
          'Piroj pur',
          'Komor pur',
          'Mohajonpur',
          'Kathal puta',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Narail',
    zones: [
      {
        name: 'Narail Sadar',
        postCode: '7500',
        areas: [
          'Maliat Bazar',
          'Hatiara',
          'Benahati',
          'Mulia',
          'Bakali',
          'Basvita',
          'Muchipol',
          'Rupgonj Bazar',
          'Vuakhali',
          'Mosihkhola',
          'Ghorakhali',
          'Police Line',
          'Dakkhin Narail',
          'Dhopakhola',
          'Gobra Bazar',
          'Malibag mor',
          'Maijpara',
          'Dumurtola',
          'Garochora Bazar',
          'Charikhada',
          'Durgapur',
          'Boramara',
          'Mena Bazar',
          'Bakasadanga',
          'Bahir Gram',
          'Habakhali',
          'Kalora',
          'Shaikhati',
          'Bhadrabila',
          'Bashgram',
          'Auria',
          'Bongram',
          'Purulia',
          'Bishnupur',
          'Chanchari Bazar',
          'Singasolpur',
          'Borgati Bazar',
          'Roghunatpur',
          'Ratanganj',
        ],
      },
      {
        name: 'Lohagara',
        postCode: '7511',
        areas: [
          'Dattopara',
          'Ramchandrapur',
          'Chowgasa Bazar',
          'Basupati Bazar',
          'Aranda Bus Stand',
          'Laxmipasha Bus Stand',
          'Lohagara Army Camp',
          'Alla Munshi Mor',
          'Kalna Ghat',
          'Lohagara Bazar',
          'Foyez mor',
          'Poddarpara',
          'Joypur',
          'Jamrultola',
          'Marich Pasha',
          'Manikgonj',
          'Lahuria',
          'Rajupur',
          'Dighalia',
          'Lutia Bazar',
          'Mahajon Bazar',
          'Naragati',
          'Cmb Chourasta',
          'Kundoshi',
          'Charmollickpur',
          'Karfa',
          'Atoshpara',
          'Itna',
          'Naldi',
          'Baradia',
        ],
      },
      {
        name: 'Kalia',
        postCode: '7520',
        areas: [
          'kalia Bazar',
          'Kanchanpur Bazar',
          'Baka Kalia',
          'Chorkhali',
          'Bordia Kalia',
        ],
      },
      {
        name: 'Terokhada',
        postCode: '9230',
        areas: [
          'Monpur Bazar',
          'Mirzapur Bus Stand',
          'Joypur Bus stand',
          'Chalna Bazar',
          'Mathavanga Bazar',
          'Boronal Bazar',
          'Bilduria Bazar',
          'Rammajhe Bazar',
          'Joysena Bazar',
          'Terakhada Bazar',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Satkhira',
    zones: [
      {
        name: 'Satkhira Sadar',
        postCode: '9400',
        areas: [
          'Amtola',
          'Kadamtola',
          'Circuit House',
          'Banglar mor',
          'Registry office',
          'Puraton Satkhira',
          'Kazipara',
          'Kumorpara',
          'PTI Math Satkhira Sadar',
          'Kamarpara',
          'Chokidar ar mor',
          'Rasulpur School',
          'Police Line School',
          'SP Banglor Mor',
          'China Bangla Market',
          'South Polashpole',
          'North Polashpole',
          'East Polashpole',
          'West Polashpole',
          'Newmarket Mor',
          'Laboni Mor',
          'Vumi office',
          'Settelement office',
          'Day Night College mor',
          'Digirpar',
          'Thana mor',
          'BWBD Mor',
          'Obda Mor',
          'Aynuddin Mohila Madrasa',
          'Naburn School Mor,',
          'Girls School Mor',
          'Boys School Mor',
          'Town girls Mor',
          'Asadulmarket',
          'Kirishi bank road',
          'Technical mor',
          'Unmukto Bissobiddaloy',
          'Itecgacha Bazar',
          'Itecgacha Police Fari',
          'Ideal School',
          'Ansar Battalion',
          'Alia Madrasa',
          'Yatimkhana Road',
          'Kobiraj Bari',
          'Gosh Para',
          'ChalteTola',
          'Vanga Mondir',
          'Stadium',
          'Tetultola',
          'Hatkhola',
          'Jomaddarbari',
          'Coldstore Mor',
          'Bakal School',
          'Sangram Tower',
          'Kukralir Mor',
          'Kukralir Field',
          'nattional bank road',
          'Dhaka Counter Road',
          'Judge court mor',
          'Gonomuki MAth',
          'Chowdurypara',
          'CB Hospiral',
          'Chourongipara',
          'Polashpole Boubazar',
          'Bangdah',
          'Mejo Miar Mor',
          'Shahapara',
          'Karima School',
          'Jhilpara',
          'Masterpara',
          'Mathpara',
          'Kashempur',
          'Debnagar',
          'Bakal',
          'Modhumalardangi',
          'Khulna Road',
          'Boro Bazar',
          'Radhanagar',
          'Old Satkhira',
          'Jhutitola',
          'Salley',
          'Binerpota',
          'PTI Field,',
          'Machkhola',
          'Police line Satkhira Sadar',
          'Rasulpur',
          'Thanaghata',
          'Boubazar',
          'Polashpole',
          'Munjitpur',
          'New Market Satkhira Sadar',
          'Pakapole',
          'AsadulMarket',
          'North Katia',
          'South Katia',
          'West Katia',
          'East Katia',
          'Kamanangar,',
          'Sangitata Mor',
          'Sultanpur',
          'Lakeview,',
          'Itegacha',
          'Munshipara Satkhira Sadar',
          'Taltola Satkhira Sadar',
          'Food Office',
          'Post office Satkhira Sadar',
          'College Mor Satkhira Sadar',
          'Razzak Park',
          'City College Satkhira Sadar',
          'Mehedibag Satkhira Sadar',
          'Labsha',
          'Satkhira Medical',
          'Mill Bazar',
          'Laskarpara',
          'Dhalipara',
          'Musa Moshjid Road',
          'Satkhira Islamia Acc',
          'Gunakar kati',
          'Budhhat',
        ],
      },
      {
        name: 'kalaroa',
        postCode: '9414',
        areas: [
          'Jhawdanga(Point)',
          'Raida Bazar',
          'DomDom bazar',
          'Jawdanga College',
          'Diarabazar',
          'Gopinathpur',
          'Kolagachir Mor',
          'Rogunathpur',
          'Jonaki Cinemahall',
          'Jhawdanga Bazar',
          'Akhrakhola',
          'Tuzulpur',
          'Sonabaria',
          'Goyra Bazar',
          'Kazihat',
          'Khorde Bazar',
          'chayghoria',
          'Kolaroa upazila',
          'Borojobaksha',
          'Kolaroa College',
          'Bashtola',
          'Ghona',
          'Abadarhat',
          'Murarikati',
          'Hamidpur',
        ],
      },
      {
        name: 'Tala',
        postCode: '9420',
        areas: [
          'Sujansaha Bazar',
          'Tala Govt college',
          'Bodorer Mor',
          'Tala thana',
          'Tala bazar',
          'Tala Updazila',
          'Tala(Point Delivery)',
          '30mile Bazar',
          'Patkelghata ball field',
          'Patkelghata Bazar',
          'Patkelghata Bridge',
          'Kumira Bazar',
        ],
      },
      {
        name: 'Kaliganj',
        postCode: '9442',
        areas: [
          'Alipur Chechkpost',
          'Mahammudpur,',
          'Puspokathi',
          'Sakandra',
          'Sakhipur Bus stop',
          'Bohera Bazar',
          'Nathpara',
          'Gazirhat',
          'Mahatpur',
          'Jahajgahata',
          'Baliadanga',
          'Fultola Kaliganj',
          'Moutola',
          'Badamtola Kaliganj',
          'Shokipur Bazar',
          'Vomra Landport',
          'Kulia Bridge',
          'Alipur Bazar',
          'Debhata(point)',
          'Nalta Mubaroknagar',
          'Ratanpur -Kaliganj',
        ],
      },
      {
        name: 'Shymnagar',
        postCode: '',
        areas: [
          'shymnagar bus stand',
          'Shymnagar Updazila',
          'Shymnagar Hospital',
          'Noornagar',
          'Nakipur',
          'Naobeki',
        ],
      },
      {
        name: 'Debhata Thana',
        postCode: '9431',
        areas: [
          'Kadamtola',
          'Parulia Bazar',
          'papratola',
          'Alipur Chechkpost',
          'Mahammudpur Debhata',
          'Badamtola',
          'Shokipur Bazar',
          'Vomra Landport',
          'Kulia Bridge',
          'Alipur Bazar',
          'Debhata(point)',
          'Gurugram',
        ],
      },
      {
        name: 'Asasuni',
        postCode: '9461',
        areas: [
          'Dhulihar',
          'Budhata',
          'Asasuni Bus stand,',
          'Asasuni Bazar',
          'Kalibari',
          'Asasuni(Point)',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Barguna',
    zones: [
      {
        name: 'Barguna Sadar',
        postCode: '8700',
        areas: [
          'TTC Road',
          'Bot tola',
          'Muslim para',
          'Amtolar par',
          'P T I',
          'Bank Colony',
          'Gono kobor Road',
          'Korok',
          'KG School Road',
          'Dhan shiri road',
          'Howladar bari road',
          'Kath potty',
          'Launch Ghat',
          'Dolua',
          'Bad ghat',
          'Borai tola',
          'Poli tacknick',
          'Puran Lonch ghat',
          'Posu Hospital road',
          'Collage road',
          'Branch road',
          'Nath potty',
          'Lack potty',
          'Dormo tola',
          'Powro market',
          'DC ghat',
          'Arod potty',
          'Sare bangla road',
          'Bongo Bondu road',
          'Saha potty',
          'Ful tola',
          'Modena Mosjed road',
          'Icecream Factory',
          'Boro bary',
          'Boro brej',
          'Batmor',
          'Moddo para',
          'Sonaly para',
          'Sonar bangla',
          'Bazar brej',
          'Eidgha mat road',
          'Thana para',
          'Bagan bary',
          'Thana road',
          'Madrasha road',
          'Kura potty',
          'Muktijodha complex',
          'Puran bus stand',
          'New bus stand',
          'Talar pamp',
          'Ukil bar',
          'Ukil potty',
          'Town hall',
          'Vutmara',
          'Berek field',
          'Dighir par',
          'Chorkolony Pechaner road',
          'Girls school road',
          'Dak bangla',
          'Chadnir dokan',
          'police line quarter',
          'Kalibari',
          'Maita',
          'Borial para',
          'Jailkhana road',
          'Passport office road',
          'Chorkolony',
          'Chadse road',
          'Arshi nogor',
          'Stadium road',
          'Staff quarter road',
          'Sujar khaya ghat',
          'Separ khal',
          'Surassar',
          'Lobon gola',
          'Kaorabunea',
          'Douatola',
          'Chalita tola',
          'Hospital road',
          'Porisoad road',
          'Islam pur',
          'Gogon ali road',
          'amjad market',
          'Nomor hat',
          'Gaptola',
          'Badam tola',
          'Kodom tola',
          'Asa khaly',
          'Sonakhaly',
          'Achraywon',
          'Gucho gram',
          'Chairman bazar',
          'Bisshas bari road',
          'Noyakata',
          'Nali Bandar',
        ],
      },
      {
        name: 'Taltoly',
        postCode: '',
        areas: [
          'Boro Boge',
          'Choto Boge',
          'Chalita tola',
          'Boge Khaya ghat',
          'Pocha koraliya',
          'Lawpara',
          'Koraibaria',
          'China port',
        ],
      },
      {
        name: 'Amtoly',
        postCode: '8710',
        areas: [
          'Khayaghat',
          'Puraghata',
          'Bank road',
          'Ferighat',
          'Bus stand',
          'Shorok road',
          'Sonak road',
          'Osman market',
          'Launch Ghat',
          'AK School road',
          'Gazipur',
          'Mohiskata',
          'Chorkukri mukri',
          'Chor bazar',
          'College road',
          'High School road',
          'Madrasha road',
          'Kat potti',
          'commissioner road',
        ],
      },
      {
        name: 'Betage',
        postCode: '8740',
        areas: [
          'Collage road',
          'Madrasha road',
          'Hospital road',
          'Kawnea bazar',
          'Sorisamuri',
          'Badghat',
          'Kalab mor',
          'Launch ghat',
          'Bangla mor',
          'Chor potty',
          'Chairman bazar',
          'Chor khaly',
          'Darul Ulam',
        ],
      },
      {
        name: 'Bamna',
        postCode: '8730',
        areas: [
          'Kheyaghat',
          'Bus stand',
          'Chola vanga',
          'colony',
          'Sordar para',
        ],
      },
      {
        name: 'Pathor ghata',
        postCode: '8720',
        areas: [
          'Gan para',
          'BFDC ghat',
          'Taltola',
          'Boro Bridge',
          'Gol chottor',
          'Machar khal',
          'Kamar hat',
          'Guda bacha',
          'Badutola',
          'Hatimpur',
          'Tangra',
          'Nachna para',
          'Horin ghata',
          'Parir khal',
          'Gorjon bunia',
          'Noltona',
          'Moksadpur',
          'Dalvanga',
          'Babugong',
          'Belar rasta',
          'Gude ghata',
          'Majar chor',
          'Gulisa khali',
          'Lamua',
          'Modopara',
          'Dowatola',
          'Lohar bridge',
          'Asarchor',
          'Choto bazar',
          'Boro bazar',
          'Mosjed road',
          'Notun rasta',
          'Chow rasta',
          'New town',
          'Boroitola',
          'Kakchira',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Barisal',
    zones: [
      {
        name: 'BARISAL SADAR',
        postCode: '8200',
        areas: [
          'KALIBARI ROAD',
          'GIRJA MOHOLA',
          'JAIL KHANA MORE',
          'FAKIR BARI ROAD',
          'AGURPUR ROAD',
          'PARA ROAD',
          'CHALK ER POLL',
          'BAZAR ROAD',
          'PORT ROAD',
          'JAIL ROAD',
          'POLICE LINE',
          'NEW CERCLUR ROAD',
          'KALUSHA SOROK',
          'AMTOLA',
          'KAZI PARA',
          'KALU KHAN BARI',
          'AMTOLA MORE',
          'MEDICAL COLLAGE',
          'BORO BARI',
          'NEW HOUSE ROAD',
          'MIRA BARIR POL',
          'JORDAN ROAD',
          'AMIR KUTHER',
          'NOBO GRAM ROAD',
          'ADOM ALI HAZIR GOLI',
          'PASSPORT GOLI',
          'OXFORD MISSON ROAD',
          'KORIM KUTHER',
          'FORESTER BARI',
          'SOSI MISTY',
          'DINO BONDU SAN',
          'SAM BABUR LEN',
          'BRAND COMPAOUND',
          'GOROSTHAN ROAD',
          'MUNSHE GARAGE',
          'SHITLA KHOLA',
          'HOSPITAL ROAD',
          'JAOW TOLA',
          'NOTULA BAD',
          'B.M.COLLAGE',
          'KAWNIA',
          'Sagardi-Barisal Sadar',
          'Tia Khali',
          'Kawnia',
          'Bibir Pukur',
          'Chahutpur',
          'Taltali-Barisal Sadar',
          'Kazipara-Barisal Sadar',
          'Natun Bazar',
          'Rajmahal',
          'Bukhainagar',
          'Saheberhat',
          'Sugandia',
          'Patang',
          'Kashipur-Barisal Sadar',
          'Kalijira bazar',
          'Tiakhali',
          'Horinaphunia',
          'Korapur',
          'nalidas',
          'Sonamiah pol',
          'Jorapol',
          'Bot tola-Barisal Sadar',
          'Sagordi',
          'Malek fakir bari',
          'Rupatoli Housing Estate',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Barishal',
    zones: [
      {
        name: 'Babuganj',
        postCode: '8211',
        areas: [
          'Madhabpasha',
          'Nizamuddin College',
          'Barishal Cadet',
          'Agarpur',
        ],
      },
      {
        name: 'Barisal Bandor Thana',
        postCode: '',
        areas: [
          'Durgapur Barisal',
          'Biswasher Hut',
          'Chader Hut',
          'Charmonai Madrasha',
          'Dingar Manik',
          'Kather Pole',
          'Bukhay Nagar Bazar',
          'Razar Hut',
          'Chawmatha',
          'Shaheber Hut',
          'Tongi Para',
          'Mollahbari Bus Stand',
          'Lahar Hut',
          'DC Road',
          'Nelua',
          'Goma Ferighat',
          'Paschim Chorammodi',
          'Mojid Hawlader Bari Bus stand',
          'Moulovir Hut',
          'Bori Tola',
          'Potang Bazar',
          'Nehalgonj Ferighat',
          'Choramoddi Mia Bari',
          'Kakorda',
          'Kamar khali',
        ],
      },
      {
        name: 'Kotowali',
        postCode: '',
        areas: [
          'BODO PARA',
          'PROFESSOR GOLI',
          'LUTFOR RAHAMAN SOROK',
          'NATUN BAZAR',
          'HOSPITAL ROAD',
          'NAZIR MOHOLLA',
          'SO ROAD',
          'BAZAR ROAD',
          'AMANO GONG',
          'POLASH PUR',
          'VATEKHANA',
          'SADUR BOTTOLA',
          'SAGORDE',
          'DHAN GOBESONA ROAD',
          'CHANDU MARKET',
          'FAKIRBARIR SCHOOL',
          'FAKIRBARI BRANCH',
          'SERA BANGLA SOROK',
          'GAOWCEA SOROK',
          'MAOELANA VASANI SOROK',
          'NAZRUL SOROK',
          'RUPATOLE HOUSING',
          'AHAMED MOLLAHA SOROK',
          'UKIL BARI SOROK',
          'BASUNDORA HOUSING',
          'KALIZIRA BAZAR',
          'JAGUA COLLAGE',
          'GASTER BAIN',
          'SAMIT POWER',
          'Barisal University',
          'Talukder Market',
          'Air Khan',
          'Choner Masjid Beltola',
          'Borai Tola',
          'Talukder Hut',
          'Andir Par',
          'Hizol Tola Bazar',
          'Mokhhima Bad Bazar',
          'Pokkhishil',
          'MUKTEJODHA SOROK',
        ],
      },
      {
        name: 'Gauronodi',
        postCode: '8230',
        areas: [
          'BARTHI',
          'Kashemabad',
          'VURGATA',
          'BAKAI',
          'MAGURA',
          'MADHAKUL',
          'Dhandoba',
          'Gouranodi Bus stand',
          'Joyosree',
          'HOSNABAD',
          'SOREKOL',
          'NOLCHIRA',
          'Ashokathi',
          'MAHILARA',
          'BATAJOR',
          'Pingolakathi',
          'BILLO GRAM',
          'MOLLAHA BARI',
          'NATHOIE',
          'Chandshi',
          'Ellah',
          'Nalchira',
        ],
      },
      {
        name: 'Agailzhara',
        postCode: '8240',
        areas: [
          'NIMTOLA',
          'Shujonkathi',
          'SARAL',
          'Rajihar',
          'SAHEBER HAT',
          'Baropaika',
          'ASKOR',
          'Kalupara',
          'JOBARPAR',
          'BAGDA',
          'POYSARHAT',
          'Shatla',
          'BAHADURPUR',
          'BAKAL',
          'Bormagora',
          'BASAIL',
          'MOLLAHA PARA',
          'Hoicharmath',
          'Batra',
          'CHOY GRAM',
          'Paisarhat',
        ],
      },
      { name: 'HIZLA', postCode: '', areas: ['Barajalia', 'Osman Manjil'] },
      {
        name: 'Muladi',
        postCode: '8250',
        areas: ['Kazirchar', 'Charkalekhan'],
      },
      { name: 'BANAREPARA', postCode: '', areas: ['Banaripara', 'Chakhar'] },
      {
        name: 'Bakergonj',
        postCode: '',
        areas: [
          'Sahebganj-Bakerganj',
          'Charamandi',
          'Padri Shibpur',
          'kalaskati',
          'Bakergonj govt. college',
          'Bakergonj sador road',
          'Runshi',
          'T & T road',
          'Madraca road',
          'Boalia',
          'Shempur bazar',
          'Sahebgonj ghat',
          'Bakergonj Pourasova',
          'Bakergonj Bus stand',
          'Vorpasha',
          'Lokkhi Pasa',
          'Dadur Hut',
          'Dudhol Mou',
          'Ansar Raod',
          'Sheikh hasina Cantonment',
          'Rahomgonj',
          'Kaligonj',
          'Padriship Pur',
          'Padriship Pur Complex',
          'Vobanipur',
          'Dakter Bari',
          'Bangla Bazar',
          'Moddho Mohesh Pur',
          'Mohesh Pur',
          'Chamta',
          'Niamoti',
          'Girza',
          'Kanki',
          'Bismilla Bazar',
          'Kathaltoli',
        ],
      },
      {
        name: 'Mahendiganj',
        postCode: '8270',
        areas: ['Laskarpur', 'Ulania', 'Nalgora-Mahendiganj', 'Langutia'],
      },
      {
        name: 'WAZIRPUR POWROSOVA',
        postCode: '',
        areas: [
          'Bamrail',
          'Sanuhat',
          'Eidgah Market',
          'Joyosree',
          'Shikarpur',
          'Eshladi',
          'Daberkul',
          'Borkotha',
          'Dhamura',
          'Sholok',
          'Misropara',
          'Mongsha',
          'Habibpur',
          'Vobanipur',
          'Karfa',
          'Soudimarket',
          'Baherghat',
          'Harta',
          'Jugirkanda',
          'Dakuarhat',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Bhola',
    zones: [
      {
        name: 'Bhola Sadar',
        postCode: '8300',
        areas: [
          'Zugirghol',
          'Adorshopara',
          'Western para',
          'Officer para Bhola Sadar',
          'Muslim para',
          'Police line Bhola Sadar',
          'Weather office',
          'Bhodro para',
          'Pourokathali',
          'Amanot para',
          'Barisal dalan',
          'Kalinat Bazar',
          'Molla potty',
          'Natun Bazar',
          'Bangla School',
          'Gazipur Road',
          'Sagar bakery',
          'Alinagar Bhola Sadar',
          'Joynagar',
        ],
      },
      {
        name: 'Doulatkhan',
        postCode: '8310',
        areas: [
          'Balia santirhat',
          'Pollibiddut office',
          'Bagmara breg',
          'komoruddin',
          'Khaerhat rastar matha',
          'Nur miarhat',
          'munshir hat',
          'Dayra bari',
          'Guinger hat',
          'mollar hat',
          'miarhat',
          'Sukdeb',
          'radabollob',
          'Doulatkhan',
          'Hajipur-Doulatkhan',
        ],
      },
      {
        name: 'Burhanuddin',
        postCode: '8320',
        areas: [
          'Vatamara',
          'Borhangonj',
          'kunzer hat',
          'Power Plant',
          'kheaghat',
          'Debir char',
          'Moniram Bazar',
          'Udoypur',
          'Hakimuddin',
          'Kazirhat',
          'mainkarhat',
          'Uttar Bazar',
          'Dokkhi Bazar',
          'Dorun Bazar',
          'Deula',
          'School bari',
          'Mohajon Bazar',
          'Mirzakalu',
        ],
      },
      {
        name: 'Lalmohon',
        postCode: '8331',
        areas: [
          'Hazirhat Bazar',
          'Nazirpur Bazar',
          'Nobinagar',
          'Lalmohon sadar',
          'Mongol sikder',
          'Dalal Bazar',
          'Chatla Bazar',
          'Raychad',
          'Lordharding',
          'Gazaria',
          'Nangalkhali',
          'Dahori Bazar',
          'Western para Lalmohon',
          'Uttar bazar',
          'Kheyaghat',
          'Dholigor Nagar',
          'Satbaria Lalmohon',
          'Rayrabad',
          'Bodorpur',
          'Islampur Lalmohon',
          'Daurihat',
        ],
      },
      {
        name: 'Tazumuddin',
        postCode: '',
        areas: [
          'Dokkhin khasherhat',
          'hazirhat',
          'Choumuhoni',
          'Upozilla Tazumuddin',
          'Tazumuddin Sadar',
          'Pakarmatha',
          'Dasherhat khasherhat',
          'Choto dahori',
        ],
      },
      {
        name: 'Char fashion',
        postCode: '8341',
        areas: [
          'Jonota Bazar',
          'Jacob tower',
          'Shorif para',
          'Kasem gonj',
          'Osman gonj',
          'Haspatal Road',
          'Abdullahpur Char fashion',
          'Ewajpur',
          'keramot gonj',
          'Etimkhana Road',
          'Aslampur',
          'Hazirhat Char fashion',
          'Rohmot gonj',
          'Shiba choumuhuni',
          'Helipad Road',
          'Upazila Road',
          'Dularhat',
          'Monpura',
        ],
      },
      {
        name: 'Dokkhin aicha',
        postCode: '',
        areas: [
          'Jinna gor',
          'Nilkomol',
          'Nurabad',
          'Char kolmi',
          'Char manika',
          'Hajari gonj',
          'Rosulpur',
          'Aminabad',
          'Jahanpur',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Jhalokathi',
    zones: [
      {
        name: 'Jhalokathi Sadar',
        postCode: '8400',
        areas: [
          'MOMONIHOTEL',
          'Doctor Potti',
          'ARODDAR PORTY',
          'TNT ROAD',
          'Jhalakathi Sadar Upazila',
          'KOLABAGAN',
          'KATPORTY',
          'TOLAR GAT',
          'Baspotti',
          'Torkari Potti',
          'Tawn Mosjid',
          'College More',
          'Brac More',
          'Krisno kathi',
          'Purbo Chadkathi',
          'Kamar Potti',
          'College Kheyaghat',
          'Nircharabad',
          'Gabkhan',
          'Sekherhat',
          'Krittipasha',
          'Boidharapur',
          'Baukathi',
          'Binoy kathi',
          'Chamta',
          'SADHONARMOR',
          'BANDA GAT',
          'Fire More',
          'Basondha',
          'Nobogram',
          'Shekherhat',
        ],
      },
      {
        name: 'Rajapur',
        postCode: '8410',
        areas: [
          'Sohag Clinic',
          'Medical More',
          'Galua Bazar',
          'Bazar Road Rajapur',
          'Bash tola',
          'Bypass',
          'Bagri Bazar',
        ],
      },
      {
        name: 'Nalchhiti',
        postCode: '8421',
        areas: [
          'China Math',
          'TNT Road Nalchhiti',
          'Medical More Nalchhiti',
          'Jailkhana Road',
          'Launch Ghaat',
          'Degree College',
        ],
      },
      {
        name: 'Kathalia',
        postCode: '8430',
        areas: [
          'Bot tola',
          'Taal tola',
          'Amua bus stand',
          'Amua bazar',
          'Kathalia Bus Stand',
          'Amua',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Patuakhali',
    zones: [
      {
        name: 'Bauphal',
        postCode: '8620',
        areas: [
          'Birpasha',
          'Kalaia',
          'Kalishari',
          'Bagabandar',
          'Kalishari',
          'Kalaia',
        ],
      },
      { name: 'Dashmina', postCode: '8630', areas: [] },
      { name: 'Galachipa', postCode: '8640', areas: ['Gazipur Bandar'] },
      { name: 'Khepupara', postCode: '8650', areas: ['Mahipur'] },
      {
        name: 'Patuakhali Sadar',
        postCode: '8601',
        areas: [
          'Hetalia Badh Ghat',
          'Patuakhali Bus Stop',
          'Chowrasta',
          'Purono Ferighat',
          'Fotiker Kheyaghat',
          'Koborosthan Road',
          'Pabna Stadium',
          'New Market',
          'Notun Bazar',
          'Charpara',
          'Karim Mridha College',
          'Alauddin Shishu Park',
          'Sadar Road',
          'Ferry ghat',
          'Chawkbazar',
          'Puran Bazar',
          'Mitha pukur par',
          'Mukul Cinema Hall',
          'Puran Bazar Kathpotti',
          'Shimul Bagh',
          'Eye Hospital',
          'Hotel Banan Road',
          'Pabna Govt. College',
          'Badhghat 2',
          'Bohalgachia',
          'Gorur Badhghat',
          'Upazila Porishod',
          'Shantibag',
          'Gulbagh',
          'B Type Bazar',
          'Govt. Polytechnic College',
          'Jailkhana',
          'Girls School',
          'Mollika Restora',
          "Women's College Raod",
          'Muktir Mor',
          'Bamagar Mor',
          'Sobujbag',
          'Kazipara',
          'Titash Mor',
          'Circuit House',
          'Al-Aqsa mosque',
          'Lake Road',
          'Patuakhali - Police Line',
          'Kolatola',
          'Choto Chowrasta',
          'Kolatola Balur Math',
          'Patuakhali Mohila Hostel',
          'Bauphal Thana',
          'Kalapara Thana',
          'Rangabali Thana',
          'Rahimabad',
        ],
      },
      { name: 'Dumkee', postCode: '', areas: [] },
      { name: 'Kala para', postCode: '', areas: ['Kala para'] },
      { name: 'Rangabali', postCode: '', areas: [] },
      { name: 'Subidkhali', postCode: '8610', areas: [] },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
  {
    district: 'Pirojpur',
    zones: [
      {
        name: 'Pirojpur Sadar',
        postCode: '8500',
        areas: [
          'Mollik Bari Stand',
          'Shankorpasa',
          'Vizora',
          'Boro pul',
          'Puraton Bus Stand',
          'New Bus Stand',
          'Sargekal',
          'Dopa barir Mor',
          'Bolaka Club',
          'SE Office Mor',
          'Duppasha',
          'Hularhat',
          'Ranipur',
          'Sharektola Bazar',
          'Judge court',
          'PTI Pirojpur Sadar',
          'Boddo Para',
          'Palpara Pirojpur Sadar',
          'RajarHat',
          'Adorsho Para',
          'C I para',
          'Moddo Rasta',
          'Sheak Para',
          'MP Mor',
          'Mataneti Rode',
          '10 Nera Goli',
          'Shekarpur',
          'Namazpur',
          'Shekha office Rode',
          'Ukil Para Pirojpur Sadar',
          'Amla Para',
          'Zia Nagar',
        ],
      },
      { name: 'Kawkhali', postCode: '8510', areas: [] },
      {
        name: 'kaukhali',
        postCode: '8510',
        areas: [
          'Shaw rasta',
          'Chera Para',
          'Amra Jure',
          'Pangasea',
          'Keundia',
          'Joykul',
        ],
      },
      {
        name: 'Swarupkathi',
        postCode: '8522',
        areas: [
          'Magura',
          'Sasena',
          'Kurena',
          'A kolom',
          'Merhat',
          'Inderhat',
          'Darus Sunnat',
          'Jalabari',
        ],
      },
      {
        name: 'Bhandaria',
        postCode: '8551',
        areas: [
          'kaplerhat',
          'bot tola Bahandarea',
          'nodmulla',
          'kolne bazar',
          'dowa Bahandarea',
          'Gurur Hat',
          'Launch Ghat',
          'Collage mor Bahandarea',
          'Kanudashkathi',
        ],
      },
      {
        name: 'Mathbaria',
        postCode: '8565',
        areas: [
          'Shafa Bazar',
          'Tuskhale',
          'Marbarea Sador',
          'Amragasea',
          'Saplaza',
          'Gude kata',
          'Jawtola',
          'Musulle Bari',
          'Dabepur',
          'Juskhale',
          'Halta',
          'Gulishakhali',
          'Tiarkhali',
          'Betmor Natun Hat',
          'Goliyakhali Bazar',
          'Shilarganj',
        ],
      },
      {
        name: 'Nazirpur',
        postCode: '8540',
        areas: [
          'Kodomtola',
          'Jojkhola',
          'Ada Jure',
          'Paspara Bazar',
          'Sikder Mollick',
          'Satkasema Bazar',
          'Gowkhale Bazar',
          'Dirga Bazar',
          'Chatkhate Bazar',
          'Boidagata Bazar',
          'Deger Jan Bazar',
          'Chaleta Bari Bazar',
          'Tarabunea Bazar',
          'Notun Rasta',
          'Mathevhanga Bazar',
          'Kalibari Mor',
          'Seramkhate Bazar',
          'Baburhat Bazar',
          'Rogunatpur Bazar',
        ],
      },
    ],
    type: 'outsideDhaka',
    COD_charge: 1,
    home_delivery: true,
  },
];
