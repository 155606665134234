import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Aside from './aside';
import CreateParcelForm from './create-parcel-form';

const CreateParcel = () => {
  const [chargeData, setChargeData] = useState();

  return (
    <Container fluid className='px-4'>
      <Row className='g-4 mb-5'>
        <Col sm='12' lg='8'>
          <CreateParcelForm setChargeData={setChargeData} />
        </Col>
        <Col sm='12' lg='4'>
          <Aside chargeData={chargeData} />
        </Col>
      </Row>
    </Container>
  );
};

export default CreateParcel;
