export const OrderStatuses = [
  'PENDING',
  'ACCEPTED',
  'PICKED',
  'AT_THE_SORTING_HUB',
  // 'PROCESSED',
  // 'UNPROCESSED',
  'IN_TRANSIT',
  'AT_THE_DELIVERY_HUB',
  'OUT_FOR_DELIVERY',
  'DELIVERED',
  'HOLD',
  'PARTIALLY_DELIVERED',
  'EXCHANGE',
  'LOST_&_DAMAGE',
  'RETURN',
  'RETURN_RECEIVED_AT_THE_SORTING_HUB',
  'RETURN_ON_THE_WAY_TO_THE_MERCHANT',
  'RETURNED_TO_THE_MERCHANT',
];
