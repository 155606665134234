import React from 'react';
import { Container, Row } from 'react-bootstrap';
import InvoicesTable from './invoice-table';

const Deliveries = () => {
  return (
    <Container fluid>
      <InvoicesTable />
    </Container>
  );
};

export default Deliveries;
