import React, { Fragment, useEffect, useState } from 'react';
import { Table, Container } from 'react-bootstrap';
import { parcelsData } from '../../assets';
import { v4 } from 'uuid';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useApi, useOrder, useStore } from '../../hooks';
import { Loader } from '../loader';
import { useAuth, useLoading } from '../../contexts';
import {
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiTitle,
  EuiButton,
  EuiPanel,
  EuiTabs,
  EuiSpacer,
  EuiTab,
  EuiText,
  EuiNotificationBadge,
  LEFT_ALIGNMENT,
  EuiBadge,
  EuiTableHeaderCell,
  EuiButtonIcon,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiLink,
  EuiPopover,
  EuiTableRow,
  EuiTableRowCell,
  EuiTableHeaderMobile,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTable,
  EuiTableBody,
  EuiTableHeader,
  EuiIcon,
  EuiAvatar,
  CENTER_ALIGNMENT,
  EuiCallOut,
  EuiPage,
  EuiLoadingSpinner,
  EuiPageTemplate,
  EuiFilterGroup,
  EuiFilterButton,
  EuiSelectable,
  EuiPopoverTitle,
  EuiFieldSearch,
  useGeneratedHtmlId,
  EuiDatePickerRange,
  EuiDatePicker,
  EuiPagination,
  EuiTablePagination,
} from '@elastic/eui';
import { Tag } from 'antd';
import { Dimmer, Segment, Image } from 'semantic-ui-react';
import { NoContent } from 'components/common';
import { CustomerInvoice } from 'components/invoice-pdf';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import moment from 'moment';

function getTagColor(key) {
  const map = {
    failed: 'red',
    canceled: 'red',
    returned: 'magenta',
    picked: 'volcano',
    processing: 'orange',
    ssl: 'gold',
    delivered: 'lime',
    success: 'green',
    paid: 'cyan',
    initiated: 'blue',
    reviewed: 'geekblue',
    outForDelivery: 'purple',
    [key]: key,
  };
  return map[key] || map['success'];
}

const ParcelsTable = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [status, setStatus] = useState('');
  const [orderId, setOrderId] = useState('');
  const [merchantOrderId, setMerchantOrderId] = useState('');
  const [storeName, setStoreName] = useState('');
  const [receiver, setReceiver] = useState();
  const [recipientName, setRecipientName] = useState();
  const [startFrom, setStartFrom] = useState();
  const [endAt, setEndAt] = useState();

  const { token } = useAuth();
  const { orders, ordersData, getOrderList } = useOrder();
  const { stores, getStoreList } = useStore();
  const navigate = useNavigate();
  const { loading } = useLoading();

  const totalEntries = ordersData?.totalElements;
  const [activePage, setActivePage] = useState(0);
  const [rowSize, setRowSize] = useState(50);
  const [pageCount, setPageCount] = useState(1);
  console.log("both"+ activePage,totalEntries)

  useEffect(() => {
    console.log(pageCount)
    if (token) {
      getOrderList(
        activePage,
        rowSize,
        status,
        '',
        orderId,
        merchantOrderId,
        storeName,
        receiver,
        recipientName,
        startFrom,
        endAt
      );
    }
  }, [token, status, orderId,merchantOrderId, storeName, receiver, recipientName, startFrom, endAt,activePage]);

  console.log(orders);

  useEffect(() => {
    if (token) {
      getStoreList();
    }
  }, [token]);

  useEffect(() => {
    if(totalEntries && rowSize){
    setPageCount(Math.ceil(Number(totalEntries) / Number(rowSize)))
    } 
  }, [totalEntries, rowSize]);

  function getVal(val) {
    let escaped = val;
    if (typeof val === "string") {
      escaped = ("" + val).replace(/"/g, '"');
      // @ts-ignore
      escaped = escaped?.replace(/[\t\n\"]/gi, " ");
    }
    return `"${escaped}"`; // To escape the comma in a address like string.
  }


  const renderTabs = () => {
    return [
      {
        name: 'All',
        status: '',
        append: status === '' && (
          <EuiNotificationBadge className='eui-alignCenter' size='m'>
            {ordersData?.totalElements || 0}
          </EuiNotificationBadge>
        ),
      },
      {
        name: 'Active',
        status: 'ACTIVE',
        append: status === 'ACTIVE' && (
          <EuiNotificationBadge className='eui-alignCenter' size='m'>
            {ordersData?.totalElements || 0}
          </EuiNotificationBadge>
        ),
      },
      {
        name: 'Delivered',
        status: 'DELIVERED',
        append: status === 'DELIVERED' && (
          <EuiNotificationBadge className='eui-alignCenter' size='m'>
            {ordersData?.totalElements || 0}
          </EuiNotificationBadge>
        ),
      },
      {
        name: 'Return',
        status: 'RETURN',
        append: status === 'RETURN' && (
          <EuiNotificationBadge className='eui-alignCenter' size='m'>
            {ordersData?.totalElements || 0}
          </EuiNotificationBadge>
        ),
      },
    ].map((tab, index) => (
      <EuiTab
        key={index}
        href={tab.href}
        onClick={() => setStatus(tab.status)}
        isSelected={tab.status === status}
        disabled={tab.disabled}
        prepend={tab.prepend}
        append={tab.append}
        className='p-2 px-2 px-md-5'
      >
        {tab.name}
      </EuiTab>
    ));
  };

  const columns = [
    {
      id: 'checkbox',
      isCheckbox: true,
      textOnly: false,
      width: '32px',
      alignment: LEFT_ALIGNMENT,
      mobileOptions: {
        show: false,
      },
      render: (title, item) => <h4 className='fw-bold'></h4>,
    },
    {
      id: 'orderId',
      label: 'Order ID',
      // isVisuallyHiddenLabel: true,
      alignment: LEFT_ALIGNMENT,
      width: '100%',
      mobileOptions: {
        // show: false,
      },
      render: (title, item) => <h5 style={{ fontSize: '.95rem' }}>{title}</h5>,
    },
    {
      id: 'store',
      label: 'Store',
      isVisuallyHiddenLabel: true,
      alignment: LEFT_ALIGNMENT,
      width: '100%',
      mobileOptions: {
        // show: false,
      },
    },
    {
      id: 'merchantOrderId',
      label: 'Merchant OID',
      isVisuallyHiddenLabel: true,
      alignment: LEFT_ALIGNMENT,
      width: '100%',
      mobileOptions: {
        // show: false,
      },
    },
    {
      id: 'recipientInfo',
      label: 'Recipient Info',
      footer: '',
      alignment: LEFT_ALIGNMENT,
      width: '100%',
      mobileOptions: {
        // header: false,
      },
      // render: (title, item) => <div>{title}</div>,
    },
    {
      id: 'deliveryStatus',
      label: 'Delivery Status',
      footer: <em>Name</em>,
      alignment: LEFT_ALIGNMENT,
      isSortable: true,
      mobileOptions: {
        // show: false,
      },
      width: '100%',
      enlarge: true,
      render: (title, item) => (
        <div>
          <Tag
            color={getTagColor()}
            className='p-1 px-2'
            style={{ verticalAlign: 'text-top' }}
          >
            {title}
          </Tag>
        </div>
      ),
    },
    {
      id: 'amount',
      label: 'Amount',
      mobileOptions: {
        // only: true,
        // header: false,
        enlarge: true,
        width: '100%',
      },
      alignment: LEFT_ALIGNMENT,
      width: '150px',
      render: (title, item) => (
        <span>{title < 0 ? `-৳${Math.abs(title)}` : `৳${title}`}</span>
      ),
      // cellProvider: (cell) => <EuiIcon type={cell} size='m' />,
    },
    {
      id: 'payment',
      label: 'Payment',
      footer: 'Payment',
      alignment: CENTER_ALIGNMENT,
      isSortable: true,
      width: '100%',
      enlarge: true,
      render: (title, item) => (
        <small>
          <Tag
            color={getTagColor('#e6f1ff')}
            className='p-1 px-3'
            style={{ verticalAlign: 'text-top', color: '#3d70b2' }}
          >
            {title}
          </Tag>
        </small>
      ),
    },
    // {
    //   id: 'magnitude',
    //   label: 'Orders of magnitude',
    //   footer: ({ items, pagination }) => {
    //     const { pageIndex, pageSize } = pagination;
    //     const startIndex = pageIndex * pageSize;
    //     const pageOfItems = items.slice(
    //       startIndex,
    //       Math.min(startIndex + pageSize, items.length)
    //     );
    //     return (
    //       <strong>
    //         Total: {pageOfItems.reduce((acc, cur) => acc + cur.magnitude, 0)}
    //       </strong>
    //     );
    //   },
    //   alignment: RIGHT_ALIGNMENT,
    //   isSortable: true,
    // },
    {
      id: 'actions',
      label: 'Actions',
      isVisuallyHiddenLabel: true,
      alignment: LEFT_ALIGNMENT,
      isActionsPopover: true,
      width: '120px',
      enlarge: true,
      // render: (title, item) => (
      //   <div className='fw-bold'>
      //     <EuiIcon
      //       type={item.type}
      //       size='m'
      //       style={{ verticalAlign: 'text-top' }}
      //     />
      //     {title}
      //   </div>
      // ),
    },
  ];

  const items = orders?.map((order, i) => {
    return {
      ...order,
      id: i,
      name: order.name,
      address: <p>{order?.address}</p>,
      magnitude: 1,
      orderId: order?.orderId,
      type: order?.deliveryType,
      store: order?.storeName,
      amount: order?.amountToCollect || 0,
      deliveryStatus: order?.status?.replace(/_/g, ' '),
      payment: order?.paymentStatus,
      recipientInfo: (
        <div>
          <div className='d-flex align-items-center'>
            <EuiIcon type='user' className='me-2 p-1' />
            {order?.recipientName}
          </div>
          <div className='d-flex align-items-center'>
            <EuiIcon type='mobile' className='me-2 p-1' />
            {order?.recipientPhone}
          </div>
          <div className='d-flex align-items-center'>
            <EuiIcon type='mapMarker' className='me-2 p-1' />
            {order?.recipientAddress || '-'}
          </div>
        </div>
      ),
      health: (
        <EuiBadge color={i % 2 == 0 ? 'danger' : 'warning'}>
          {order?.id?.slice(-6)}
        </EuiBadge>
      ),
    };
  });

  function renderHeaderCells() {
    const headers = [];

    columns.forEach((column, columnIndex) => {
      if (true) {
        headers.push(
          <EuiTableHeaderCell
            key={column.id}
            align={columns[columnIndex].alignment}
            width={column.width}
            mobileOptions={column.mobileOptions}
            style={{ paddingBottom: 12, paddingTop: 12 }}
          >
            {column.label}
          </EuiTableHeaderCell>
        );
      }
    });
    return headers.length ? headers : null;
  }

  function renderRows() {
    const renderRow = (item) => {
      const cells = columns.map((column) => {
        const cell = item[column.id];
        let child;
        if (column.isActionsPopover) {
          return (
            <EuiTableRowCell
              key={column.id}
              header={column.label}
              textOnly={false}
              hasActions={true}
              align='left'
            >
              <div>
                <EuiButton
                  onClick={() => navigate(`/deliveries/${item.orderId}`)}
                  key='A'
                  size='s'
                  color='success'
                  className='text-success outlined mb-1 compressed collapsed d-none d-md-block fw-normal'
                  style={{ width: 100 }}
                >
                  View
                </EuiButton>
                <EuiButton
                  key='B'
                  iconType='download'
                  iconSize='s'
                  size='s'
                  color='text'
                  className='compressed text-warning collapsed d-none d-md-block'
                  style={{
                    width: 100,
                    border: '1px solid rgba(80, 80, 0, .2)',
                  }}
                >
                  <PDFDownloadLink
                    document={
                      <>
                        <CustomerInvoice data={item} />
                      </>
                    }
                    fileName={`invoice-${item.orderId}`}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? 'Loading...' : 'Invoice'
                    }
                  </PDFDownloadLink>
                </EuiButton>
              </div>
              <EuiIcon
                onClick={() => navigate(`/deliveries/${item.orderId}`)}
                type='eye'
                size='l'
                style={{ cursor: 'pointer' }}
                className='p-1 cursor-pointer ms-1 bg-gray rounded-1 text-danger d-block d-md-none outlined fw-normal'
              />
            </EuiTableRowCell>
          );
        }

        if (column.render) {
          // const title = item.name?.isLink ? (
          //   <EuiLink href=''>{item.name.value}</EuiLink>
          // ) : (
          //   titleText
          // );
          child = column.render(cell, item);
        } else if (column.cellProvider) {
          child = column.cellProvider(cell);
        } else if (cell?.isLink) {
          child = <EuiLink href=''>{cell.value}</EuiLink>;
        } else if (cell?.truncateText) {
          child = cell.value;
        } else {
          child = cell;
        }

        return (
          <EuiTableRowCell
            key={column.id}
            align={column.alignment}
            truncateText={cell && cell.truncateText}
            textOnly={cell ? cell.textOnly : true}
            mobileOptions={{
              header: column.label,
              ...column.mobileOptions,
            }}
            className='p-1 p-md-4'
          >
            {child}
          </EuiTableRowCell>
        );
      });

      return (
        <EuiTableRow key={item.id} isSelectable={true} hasActions={true}>
          {cells}
        </EuiTableRow>
      );
    };

    const rows = [];
    for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
      const item = items[itemIndex];
      rows.push(renderRow(item));
    }

    return rows;
  }

  const exampleId = 'example-id';
  const filterGroupPopoverId = useGeneratedHtmlId({
    prefix: 'filterGroupPopover',
  });

  const onButtonClick = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const closePopover = () => {
    setIsPopoverOpen(false);
  };

  const goToPage = (pageNumber) => 
  {
    //console.log("got to page"+pageNumber)
    setActivePage(pageNumber)
  };
  const changeItemsPerPage = (pageSize) => {
    //console.log("pageSize"+pageSize)
    setPageCount(Math.ceil(totalEntries / pageSize));
    setRowSize(pageSize);
    setActivePage(0);
    //console.log("activePage"+  activePage)
  };
  useEffect(() => {
    goToPage()
  }, []);

  return (
    <Container fluid>
      <EuiPageHeader>
        <EuiPageHeaderSection className='ms-3 ms-lg-0 mb-4'>
          <EuiTitle size='s'>
            <h3>Deliveries</h3>
          </EuiTitle>
        </EuiPageHeaderSection>
        <EuiPageHeaderSection className='ms-3 ms-lg-0 mb-3'>
          <EuiButton fill className='' color="primary" style={{marginTop:'-10px', marginRight:"15px", backgroundColor:"rgb(0, 119, 204)"}}
            onClick={()=>{
              console.log(orders)
              let orderList = [];
              orders?.forEach((order) => {
                let obj = {};
                obj["orderId"] = order?.orderId;
                obj["status"] = order?.status;
                obj["createdAt"] = moment(order?.createdAt).format(
                  "DD-MM-yyyy hh:mma"
                );
                obj["updatedAt"] = moment(order?.updatedAt).format(
                  "DD-MM-yyyy hh:mma"
                );
                const store = order?.parcelOrder?.stores?.find((store)=> store.name === order?.storeName);
                
                if(store){
                  delete order?.parcelOrder?.stores;
                  obj['storeContactNumber'] = store?.contactNumber
                }else{
                  obj['storeContactNumber'] = ''
                }
                delete order?.parcelOrder?.merchantPaymentInfo;
                obj = {
                  ...obj,
                  ...(order?.parcelOrder || {}),
                  expectedPayoutAmount:
                    order?.parcelOrder?.amountToCollect - order?.parcelOrder?.deliveryCharge - order?.parcelOrder?.codCharge,
                };
                //obj["orderNote"] = order?.orderNote;

                for (let [key, val] of Object.entries(obj)) {
                  obj[key] = getVal(val);
                }
                orderList?.push(obj);
              });
              const titleKeys = Object.keys(orderList[0]).map((key) =>
                getVal(key)
              );

              const refinedData = [];
              refinedData.push(titleKeys);
              console.log(orderList)

              orderList.forEach((item) => {
                refinedData.push(Object.values(item));
              });
              let csvContent = "";
              refinedData.forEach((row) => {
                csvContent += row.join(",") + "\n";
              });

              // @ts-ignore
              const blob = new Blob([csvContent], {
                type: "text/csv;charset=utf-8,",
              });

              const objUrl = URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.setAttribute("href", objUrl);
              link.setAttribute(
                "download",
                `orders-${orders?.length}.csv`
              );
              link?.click();
            }}>
            Download
          </EuiButton>
          
          <EuiButton
            color='text'
            className='me-3 mb-2'
            onClick={() => navigate('/deliveries/create/bulk')}
          >
            Bulk Order
          </EuiButton>
          {/* <EuiButton className='me-3 mb-2' onClick={() => navigate('/deliveries/create/partial')}>Partial Delivery</EuiButton> */}
          {/* <EuiButton className='me-3 mb-2 outlined' onClick={() => navigate('/deliveries/create/link')}>
            Create Delivery Link
          </EuiButton> */}
          <EuiButton
            fill
            href='/deliveries/create'
            className='mb-2'
            onClick={() => navigate('/deliveries/create')}
          >
            New Delivery
          </EuiButton>
        </EuiPageHeaderSection>
      </EuiPageHeader>

      <EuiPanel
        hasShadow={false}
        hasBorder={true}
        paddingSize='none'
        className='mb-5'
      >
        <>
          <EuiTabs className='ps-3'>{renderTabs()}</EuiTabs>

          <EuiFilterGroup  fullWidth={true} className='m-2'>
            <EuiFieldSearch
              placeholder='Search by Order Id'
              value={orderId}
              isClearable={true}
              aria-label='Search by Order Id'
              onChange={(e) => setOrderId(e.target.value)}
              width={80}
              fullWidth={false}
              className='me-2'
            />
            <EuiFieldSearch
              placeholder='Search by Merchant Order Id'
              value={merchantOrderId}
              isClearable={true}
              aria-label='Search by Merchant Order Id'
              onChange={(e) => setMerchantOrderId(e.target.value)}
              // width={100}
              fullWidth={false}
              
            />
            <EuiPopover
              id={filterGroupPopoverId}
              style={{width: '100%', minWidth:112}}
              button={
                <EuiFilterButton
                  iconType='arrowDown'
                  onClick={onButtonClick}
                  isSelected={true}
                  numFilters={stores.length}
                  hasActiveFilters={!!storeName}
                  numActiveFilters={storeName ? 1 : 0}
                  style={{width:'100%', minWidth: 112}}
                  grow={true}
                >
                  Stores
                </EuiFilterButton>
              }
              isOpen={isPopoverOpen}
              closePopover={closePopover}
              panelPaddingSize='none'
              
            >
              <EuiSelectable
                allowExclusions
                searchable
                searchProps={{
                  placeholder: 'Filter store',
                  compressed: true,
                }}
                aria-label='Composers'
                options={stores
                  ?.map(
                    (s) =>
                      s.name && {
                        label: s.name,
                      }
                  )
                  ?.filter((item) => item)}
                isLoading={loading}
                loadingMessage='Loading Stores'
                emptyMessage='No stores available'
                noMatchesMessage='No stores found'
                onChange={(list) =>
                  setStoreName(
                    list?.find((item) => item.checked === 'on')?.label
                  )
                }
              >
                {(list, search) => (
                  <div style={{ width: 300 }}>
                    <EuiPopoverTitle paddingSize='s'>{search}</EuiPopoverTitle>
                    {list}
                  </div>
                )}
                
              </EuiSelectable>
            </EuiPopover>
            <EuiFieldSearch
              placeholder='Receiver Mobile'
              value={receiver}
              isClearable={true}
              aria-label='Receiver Mobile'
              onChange={(e) => setReceiver(e.target.value)}
              style={{width:'100%'}}
              fullWidth={true}
            />
            <EuiFieldSearch
              placeholder='Recipient Name'
              value={recipientName}
              isClearable={true}
              aria-label='Receiver Mobile'
              onChange={(e) => setRecipientName(e.target.value)}
              style={{width:'100%'}}
              fullWidth={true}
            />
            <EuiDatePickerRange
              isInvalid={startFrom > endAt}
              fullWidth={true}
              startDateControl={
                <EuiDatePicker
                  selected={startFrom}
                  onChange={(date) => setStartFrom(date)}
                  startDate={startFrom}
                  endDate={endAt}
                  aria-label='Start date'
                  // showTimeSelect
                  maxDate={endAt}
                />
              }
              endDateControl={
                <EuiDatePicker
                  selected={endAt}
                  onChange={(date) => setEndAt(date)}
                  startDate={startFrom}
                  endDate={endAt}
                  aria-label='End date'
                  // showTimeSelect
                  minDate={startFrom}
                />
              }
            />
          </EuiFilterGroup>

          <Fragment>
            {/* <EuiSpacer /> */}
            <EuiText>
              {loading ? (
                <Loader />
              ) : orders?.length ? (
                <div>
                  <EuiTableHeaderMobile>
                    <EuiFlexGroup
                      responsive={false}
                      justifyContent='spaceBetween'
                      alignItems='baseline'
                    >
                      <EuiFlexItem grow={false}></EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiTableHeaderMobile>

                  <EuiTable
                    color='accent'
                    // cellPadding={100}
                    // cellSpacing={100}
                    id={exampleId}
                    className='border-top'
                  >
                    <EuiTableHeader>{renderHeaderCells()}</EuiTableHeader>
                    <EuiTableBody>{renderRows()}</EuiTableBody>
                    {/* <EuiTableFooter>{renderFooterCells()}</EuiTableFooter> */}
                  </EuiTable>
                </div>
              ) : (
                <NoContent
                  title='No Deliveries Found'
                  description='There is no delivery that matches your filter or you have not
                created one yet. Let’s create one.'
                  icon='searchProfilerApp'
                  hasButton
                  btnLink='/deliveries/create'
                  btnText='New Delivery'
                />
              )}
            </EuiText>
          </Fragment>
        </>
      </EuiPanel>

         <EuiTablePagination
           aria-label="Table pagination example"
           pageCount={pageCount}
           activePage={activePage}
           onChangePage={goToPage}
           itemsPerPage={rowSize}
           onChangeItemsPerPage={changeItemsPerPage}
           itemsPerPageOptions={[50,100, 200,300,400,500,600,700]}
         /> 
        
    </Container>
  );
};

export default ParcelsTable;
