export const productWeights = [
  {
    label: '0-1',
    value: 1,
  },
  {
    label: '1-2',
    value: 2,
  },
  {
    label: '2-3',
    value: 3,
  },
  {
    label: '3-4',
    value: 4,
  },
  {
    label: '4-5',
    value: 5,
  },
  {
    label: '5-6',
    value: 6,
  },
  {
    label: '6-7',
    value: 7,
  },
  {
    label: '7-8',
    value: 8,
  },
  {
    label: '8-9',
    value: 9,
  },
  {
    label: '9-10',
    value: 10,
  },
  {
    label: '10-11',
    value: 11,
  },
  {
    label: '11-12',
    value: 12,
  },
  {
    label: '12-13',
    value: 13,
  },
  {
    label: '13-14',
    value: 14,
  },
  {
    label: '14-15',
    value: 15,
  },
];
