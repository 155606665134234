import React from 'react';
import { Container, Accordion } from 'react-bootstrap';
import { faqData } from '../assets';
import { v4 } from 'uuid';

const FAQs = () => {
  return (
    <div className='bg-gray py-5' id="faq_section">
      <Container id='faqs' className='py-5 section'>
        <h2 className='fs-1 mt-5 fw-bold mb-5 text-center text-danger'>
          Have A Question
        </h2>
        <div className='accordian py-4'>
          <Accordion
            className='mx-auto faq-accordion'
            style={{ maxWidth: '800px' }}
            defaultActiveKey={0}
          >
            {faqData.map((data, index) => (
              <Accordion.Item
                key={v4()}
                eventKey={index}
                className='my-3 rounded border-0'
                style={{ overflow: 'hidden' }}
              >
                <Accordion.Header>
                  <span
                    className='d-block fw-bold fs-5 text-secondary'
                    style={{ width: '90%' }}
                  >
                    {index + 1}. {data.ques}
                  </span>
                </Accordion.Header>
                <Accordion.Body className='fs-6'>{data.ans}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </Container>
    </div>
  );
};

export default FAQs;
