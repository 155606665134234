import React from 'react';
import { Container, Row } from 'react-bootstrap';
import HeroRight from './hero-right';
import HeroLeft from './hero-left';

const Hero = () => {
  return (
    <Container
      fluid
      className='d-flex home-banner align-items-center text-white'
      style={{
        minHeight: 'calc(60vh - 75px)',
        animation: 'on-mount 0.6s ease-out forwards',
      }}
    >
      <Container className=' px-4 y-5'>
        <Row className='align-items-center'>
          <HeroLeft />
          <HeroRight />
        </Row>
      </Container>
    </Container>
  );
};

export default Hero;
