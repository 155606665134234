import GetStarted from 'components/get-started';
import { Layout } from 'components/layout';
import Services from 'components/services';
import React from 'react';
import { Hero, About, PriceCalculator } from '../components';
import FAQs from '../components/faqs';
import { useNavLinks, useAuth } from '../contexts';

export default function Home() {
  const { homeLinks, userLinks } = useNavLinks();
  const { checkForUser, isAuthenticated } = useAuth();
  const [animation, setAnimation] = React.useState('');

  React.useEffect(() => {
    if (isAuthenticated) {
      userLinks();
    } else {
      homeLinks();
    }
    checkForUser();
    setAnimation('on-mount 0.4s ease-out forwards');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        animation: animation,
      }}
    >
      <Layout>
        <Hero />
        <Services />
        <About />
        <PriceCalculator />
        <FAQs />
        <GetStarted />
      </Layout>
    </div>
  );
}
