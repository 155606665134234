import { brandLogoVertical, LogoLight } from 'assets';
import { Loader } from 'components';
import { useAuth } from 'contexts';
import { useApi } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams, useSearchParams } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';
import { TrackingDetails } from '.';

const TrackingPage = () => {
  const [info, setInfo] = useState({ loading: true, data: null });
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');
  const { getTrackingDetails } = useApi();

  useEffect(() => {
    if (orderId) {
      getTrackingDetails(orderId)
        .then((res) => setInfo({ loading: false, data: res }))
        .catch((e) => {
          console.error(e);
          setInfo({ loading: false, data: null });
        });
    }
  }, [orderId]);

  return (
    <Container className='p-4 mt-0 mt-md-5'>
      <Row className='g-5 mt-2 flex justify-content-center'>
        <Col md='6' lg='4'>
          <div className='flex justify-content-center mb-4'>
            <img src={brandLogoVertical} height={60} />
          </div>
          <div className='bg-whitex'>
            {orderId ? (
              info?.loading ? (
                <Loader />
              ) : info?.data ? (
                <>
                  <TrackingDetails data={info?.data} />
                </>
              ) : (
                <Segment clearing>No Data Found!</Segment>
              )
            ) : (
              <Segment clearing color='red'>
                Invalid Url!
              </Segment>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default TrackingPage;
