import React, { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import {
  heroImg,
  deliveryBoy,
  hour24,
  card,
  parcel,
  onlineService,
  realtimeTracking,
  warehouse,
  homeboy,
} from '../../assets';
import $ from 'jquery';

const HeroRight = () => {
  // useEffect(() => {
  //   (function () {
  //     typing(0, $('.typewriter-text').data('text'));
  //     function typing(index, text) {
  //       var textIndex = 1;
  //       var tmp = setInterval(function () {
  //         if (textIndex < text[index].length + 1) {
  //           $('.typewriter-text').text(text[index].substr(0, textIndex));
  //           console.log('start', index);
  //           if (index == 0) {
  //             document.getElementById('banner-top-img-0').src = deliveryBoy;
  //           }
  //           if (index == 1) {
  //             document.getElementById('banner-top-img-1').src = card;
  //           }
  //           if (index == 2) {
  //             document.getElementById('banner-top-img-2').src = parcel;
  //           }
  //           if (index == 3) {
  //             document.getElementById('banner-top-img-3').src = onlineService;
  //           }
  //           if (index == 4) {
  //             document.getElementById('banner-top-img-4').src = hour24;
  //           }
  //           if (index == 5) {
  //             document.getElementById('banner-top-img-5').src =
  //               realtimeTracking;
  //           }
  //           if (index == 6) {
  //             document.getElementById('banner-top-img-6').src = warehouse;
  //           }
  //           $('#banner-top-img-' + index).fadeIn(500);
  //           textIndex++;
  //         } else {
  //           setTimeout(function () {
  //             deleting(index, text);
  //             $('#banner-top-img-' + index).fadeOut(500);
  //           }, 4000);
  //           clearInterval(tmp);
  //         }
  //       }, 100);
  //     }
  //     function deleting(index, text) {
  //       var textIndex = text[index].length;
  //       var tmp = setInterval(function () {
  //         if (textIndex + 1 > 0) {
  //           $('.typewriter-text').text(text[index].substr(0, textIndex));
  //           textIndex--;
  //         } else {
  //           index++;
  //           if (index == text.length) {
  //             index = 0;
  //           }
  //           typing(index, text);
  //           clearInterval(tmp);
  //         }
  //       }, 30);
  //     }
  //   })();
  // }, []);

  return (
    <Col lg='5' className='d-flex align-items-center'>
      {/* <img
        src={heroImg}
        alt='hero'
      /> */}

      <div
        // className='pt-3 pt-sm-3'
        // style={{ width: 'clamp(300px, 80vw, 500px)', height: 'auto' }}
      >
        <div className='banner-left-top-imgx'>
          <img src={homeboy} style={{width: '100%'}} className='banner-character' />
          {/* <img id='banner-top-img-1' className='banner-top-img' />
          <img id='banner-top-img-2' className='banner-top-img' />
          <img id='banner-top-img-3' className='banner-top-img' />
          <img id='banner-top-img-4' className='banner-top-img' />
          <img id='banner-top-img-5' className='banner-top-img' />
          <img id='banner-top-img-6' className='banner-top-img' /> */}
        </div>
        {/* <div className='text-center tab-text my-5'>
          <span
            className='typewriter-text'
            data-text='[ "Delivery Hero ", "Secure Payment ", "PickUp & Drop ", "Online Services ", "24/7 Customer Support ", "Realtime Tracking ", "Distribution Center "]'
          ></span>
          <br />
        </div> */}
      </div>
    </Col>
  );
};

export default HeroRight;
