import React, { useState } from 'react';
import { useAlert, useLoading } from '../contexts';
import { useApi } from './use-api';

export function useStore() {
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState([]);

  const { showAlert } = useAlert();
  const { getStores, getStoreById , updateStore, deleteStore} = useApi();
  const { setLoading } = useLoading();

  async function getStoreList(page = 0, limit = 100) {
    try {
      setLoading(true);
      const res = await getStores(100);
      if (!res || res.statusCode !== 200) {
        showAlert(res?.message || 'Something went wrong!', 'error');
        return;
      }
      setStores(res?.stores || []);
      setLoading(false);
      return res?.stores || [];
    } catch (e) {
      console.error(e);
      setLoading(false);
      showAlert('Fetching stores failed!', 'error');
    }
  }

  async function getStoreByStoreId(storeId) {
    try {
      setLoading(true);
      const res = await getStoreById(storeId);
      if (!res || res.statusCode !== 200) {
        showAlert(res?.message || 'Something went wrong!', 'error');
        return;
      }
      setStore([res?.store] || []);
      setLoading(false);
      return [res?.store] || [];
    } catch (e) {
      console.error(e);
      setLoading(false);
      showAlert('Fetching stores failed!', 'error');
    }
  }

  async function updateStoreById(data) {
    try {
      setLoading(true);
      const res = await updateStore(data);
      if (!res || res.statusCode !== 200) {
        showAlert(res?.message || 'Something went wrong!', 'error');
        return;
      }
      //setStores([]);
      setLoading(false);
      showAlert('Store updated Successfully.','success')
      return 'SUCCESS';
    } catch (e) {
      console.error(e);
      setLoading(false);
      showAlert('Fetching stores failed!', 'error');
    }
  }

  async function deleteStoreById(storeId) {
    try {
      setLoading(true);
      const res = await deleteStore(storeId);
      if (!res || res.statusCode !== 200) {
        showAlert(res?.message || 'Something went wrong!', 'error');
        return;
      }
      //setStores([]);
      setLoading(false);
      showAlert('Store deleted Successfully.','success')
      return 'SUCCESS';
    } catch (e) {
      console.error(e);
      setLoading(false);
      showAlert('Fetching stores failed!', 'error');
    }
  }

  return {
    stores,
    store,
    getStoreList,
    getStoreByStoreId,
    updateStoreById,
    deleteStoreById
  };
}
