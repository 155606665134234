import {
  EuiPanel,
  EuiIcon,
  EuiBetaBadge,
  EuiTimeline,
  EuiSplitPanel,
  EuiAvatar,
  EuiHorizontalRule,
  EuiText,
  EuiTimelineItem,
  EuiToken,
} from '@elastic/eui';
import {
  acceptedImg,
  pickedImg,
  inTransitImg,
  outForDeliveryImg,
  deliveredImg,
  cancelledImg,
} from 'assets';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Segment, Step } from 'semantic-ui-react';

const TrackingDetails = ({ data }) => {
  const [info, setInfo] = useState();

  useEffect(() => {
    setInfo(data);
  }, [data]);

  console.log(info);

  const phase = (status) => {
    const checked = status?.value === 'DELIVERED';
    const cancelled = status?.value === 'CANCELLED';

    return (
      <EuiTimelineItem
        verticalAlign='top'
        className='border-0 p-0'
        icon={
          checked ? (
            <EuiAvatar
              name='Checked'
              iconType='check'
              iconColor='#198754'
              color='#dcf5de'
              className='fw-bold'
            />
          ) : cancelled ? (
            <EuiAvatar
              name='Unchecked'
              iconType='dot'
              iconColor='#aa0000'
              color='#ffeeee'
              className='fw-bold'
            />
          ) : (
            <EuiAvatar name='Unchecked' iconType='dot' color='subdued' />
          )
        }
      >
        <EuiSplitPanel.Outer color='transparent' hasBorder={false}>
          <EuiSplitPanel.Inner
            paddingSize='none'
            color={checked ? 'transparent' : 'transparent'}
          >
            <h2 className='fs-5 lh-sm'>
              {status?.value?.[0] + status?.value?.slice(1)?.toLowerCase()}{status?.note ? ` (${status?.note})` : ''}
            </h2>
          </EuiSplitPanel.Inner>
          <EuiHorizontalRule margin='none' />
          <EuiSplitPanel.Inner paddingSize='s'>
            <EuiText size='xs' grow={false}>
              <p className='d-flex align-items-center'>
                <EuiToken iconType='tokenDate' className='me-2' />
                {moment(status?.datetime).format('LLL')}
              </p>
            </EuiText>
          </EuiSplitPanel.Inner>
        </EuiSplitPanel.Outer>
      </EuiTimelineItem>
    );
  };

  let statuses = [
    {
      status: 'Accepted',
      value: 'ACCEPTED',
      img: acceptedImg,
    },
    {
      status: 'Picked',
      value: 'PICKED',
      img: pickedImg,
    },
    {
      status: 'In Transit',
      value: 'IN_TRANSIT',
      img: inTransitImg,
    },
    {
      status: 'Out For Delivery',
      value: 'OUT_FOR_DELIVERY',
      img: outForDeliveryImg,
    },
    {
      status: 'Delivered',
      value: 'DELIVERED',
      img: deliveredImg,
    },
    {
      status: 'Cancelled',
      value: 'CANCELLED',
      img: cancelledImg,
    },
  ];

  const logsMap = {};
  info?.statusLogs?.forEach((status, i) => {
    logsMap[status?.value] = status;
  });

  let isCancelled = false;

  return (
    <div>
      <h3 color='redx' className='bg-whitex text-center fw-bold'>
        TRACKING DETAILS
      </h3>
      <Segment color='red' className='bg-white fw-bold'>
        <div className='flex justify-content-between'>
          <h4>Order ID:</h4>
          <h4>{info?.orderId}</h4>
        </div>
      </Segment>
      {/* <Segment clearing>
        <div className='flex justify-content-between'>
          <h4 className='mb-1'>SHIPPING DETAILS</h4>
          <h4 className='flex-grow-1 text-end'>
            {info?.parcelOrder?.recipientPhone || '-'}
          </h4>
        </div>
        <div className='flex justify-content-end text-end'>
          <div>
            <h5 className='fw-bold'> {info?.parcelOrder?.recipientName}</h5>
            <p>{info?.parcelOrder?.recipientAddress}</p>
            <p>
              {info?.parcelOrder?.recipientArea}
              {info?.parcelOrder?.recipientArea && ', '}
              {info?.parcelOrder?.recipientZone}
              {info?.parcelOrder?.recipientZone && ', '}
              {info?.parcelOrder?.recipientCity}
            </p>
          </div>
        </div>
      </Segment> */}
      <Segment clearing>
        <div className=''>
          <h4 className='fw-bold'>STATUS : {info?.status}</h4>
        </div>
        <div>
          <EuiPanel
            hasShadow={false}
            hasBorder={false}
            className='h-100 bg-white'
            paddingSize='none'
          >
            <div>
              <Step.Group className='my-4 tracking-flex'>
                
                {statuses?.map((status, i) => {
                  isCancelled = !!logsMap['CANCELLED'];
                  if (!isCancelled && status.value === 'CANCELLED') return;
                  return (
                    <Step key={i} active={false}>
                      <Step.Content>
                        <Step.Title className='flex flex-column justify-centent-space align-items-center'>
                          <EuiIcon
                            type={status?.img}
                            size='xl'
                            className={`mb-2  ${
                              isCancelled && !logsMap[status?.value]
                                ? `gray-img`
                                : ``
                            }`}
                          />
                          <small
                            className={`fs-6 mb-2 text-center tracking-status-text ${
                              status?.value === 'CANCELLED' ? `text-danger` : ``
                            }`}
                            style={
                              isCancelled && !logsMap[status?.value]
                                ? { color: 'gray', maxWidth: 80 }
                                : { maxWidth: 80 }
                            }
                            dangerouslySetInnerHTML={{
                              __html: `${status?.status}`,
                            }}
                          ></small>
                          <EuiBetaBadge
                            color={
                              !!logsMap[status?.value] ? 'undefined' : 'hollow'
                            }
                            iconType={
                              status.value === 'CANCELLED' ? 'cross' : 'check'
                            }
                            className={
                              status?.value === 'CANCELLED'
                                ? 'text-white bg-danger'
                                : !!logsMap[status?.value] ||
                                  logsMap['DELIVERED']
                                ? 'text-white bg-success'
                                : 'text-white'
                            }
                            size='s'
                          />
                        </Step.Title>
                      </Step.Content>
                    </Step>
                  );
                })}
              </Step.Group>

              <EuiTimeline aria-label='Life cycle of statuses'>
                {[...(info?.statusLogs || [])]
                  ?.reverse()
                  ?.map((status) => phase(status))}
              </EuiTimeline>
            </div>
          </EuiPanel>
        </div>
      </Segment>
      {/* <Segment clearing>
        <div className='flex justify-content-between'>
          <h4 className='fw-bold mb-1'>Pricing</h4>
        </div>
        <div className='flex justify-content-end text-end'>
          <div>
            <h4 className='fw-bold'>BDT {info?.total || 0}</h4>
            <p>In Total</p>
          </div>
        </div>
      </Segment>
      <Segment clearing>
        <div className='flex justify-content-between text-end'>
          <h4 className='fw-bold mb-1'>Store</h4>
        </div>
        <div className='flex justify-content-end text-end'>
          <div>
            <p>{info?.parcelOrder?.storeName || '-'}</p>
          </div>
        </div>
      </Segment> */}
      <p className='text-center'>Piickme Express Ltd.</p>
    </div>
  );
};

export default TrackingDetails;
