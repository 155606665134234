import React from 'react';
import { useLocations } from './use-locations';
import { useApi } from './use-api';
import { useConstants, useLoading } from '../contexts';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../contexts';
import { useTypes } from './use-types';
import { formatMobile } from '../utils';

export function useRegistrationForm() {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [mobile, setMobile] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [district, setDistrict] = React.useState('');
  const [upazila, setUpazila] = React.useState('');
  const [thana, setThana] = React.useState('');
  const [area, setArea] = React.useState('');
  const [street, setStreet] = React.useState('');
  const [house, setHouse] = React.useState('');
  const [latitude, setLatitude] = React.useState();
  const [longitude, setLongitude] = React.useState();
  const [accountType, setAccountType] = React.useState('');
  const [districts, setDistricts] = React.useState(['']);
  const [upazilas, setUpazilas] = React.useState(['']);
  const [productTypes, setProductTypes] = React.useState(['']);
  const [productCategoryTypes, setProductCategoryTypes] = React.useState(['']);
  const [productType, setProductType] = React.useState('');
  const [productCategoryType, setProductCategoryType] = React.useState('');

  const { getDistricts, getUpazilas } = useLocations();
  const { getProductTypes } = useTypes();
  const functions = {};
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { registerUser } = useApi();
  const { REGISTER_USER_KEY } = useConstants();

  functions.handleFirstName = function (e) {
    setFirstName(e.currentTarget.value);
  };

  functions.handleLastName = function (e) {
    setLastName(e.currentTarget.value);
  };

  functions.handleEmail = function (e) {
    setEmail(e.currentTarget.value);
  };
  functions.handleMobile = function (e) {
    setMobile(e.currentTarget.value);
  };

  functions.handlePassword = function (e) {
    setPassword(e.currentTarget.value);
  };

  functions.handleConfirmPassword = function (e) {
    setConfirmPassword(e.currentTarget.value);
  };

  functions.handleDistrict = function (e) {
    setDistrict(e.currentTarget.value);
  };
  functions.handleUpazila = function (e) {
    setUpazila(e.currentTarget.value);
  };

  functions.handleArea = function (e) {
    setArea(e.currentTarget.value);
  };

  functions.handleStreet = function (e) {
    setStreet(e.currentTarget.value);
  };

  functions.handleHouse = function (e) {
    setHouse(e.currentTarget.value);
  };

  functions.handleAccountType = function (e) {
    setAccountType(e.currentTarget.value);
  };

  functions.handleThana = function (e) {
    setThana(e.currentTarget.value);
  };

  functions.handleLatitude = function (e) {
    setLatitude(e.currentTarget.value);
  };

  functions.handleLongitude = function (e) {
    setLongitude(e.currentTarget.value);
  };

  functions.handleProductType = function (e) {
    setProductType(e.currentTarget.value);
  };

  functions.handleProductCategoryType = function (e) {
    setProductCategoryType(e.currentTarget.value);
  };

  React.useEffect(() => {
    setDistricts([{ label: 'Select one' }, ...getDistricts()]);
  }, []);

  React.useEffect(() => {
    setUpazilas([{ label: 'Select one' }, ...getUpazilas(district)]);
  }, [district]);

  React.useEffect(() => {
    setProductTypes([{ label: 'Select one' }, ...getProductTypes()]);
  }, []);

  React.useEffect(() => {
    setProductCategoryTypes([{ label: 'Select one' }, ...getProductTypes()]);
  }, []);

  function resetInputs() {
    setFirstName('');
    setLastName('');
    setEmail('');
    setMobile('');
    setPassword('');
    setConfirmPassword('');
    setStreet('');
    setHouse('');
    setAccountType('');
    setDistrict('');
    setArea('');
    setProductType('');
    setProductCategoryType('');
  }

  functions.handleSubmit = async function (e) {
    e.preventDefault();
    // if (password !== confirmPassword) {
    //   showAlert('Your passwords are incorrect', 'error');
    //   return;
    // }
    setLoading(true);
    try {
      const readyData = {
        companyName: e.target.companyName.value,
        ownersName: e.target.ownersName.value,
        mobileNumber: formatMobile(e.target.mobileNumber.value),
        emailAddress: e.target.emailAddress.value,
        password: e.target.password.value,
      };

      const user = await registerUser(readyData);

      setLoading(false);
      if (!user) {
        showAlert('Ops! Something went wrong', 'error');
        return;
      }
      if (user.statusCode !== 200) {
        showAlert(user.message || 'Something went wrong', 'error');
        return;
      }
      delete readyData.password;
      // data.verification_key = user.data[0].verification_key;
      localStorage.setItem(REGISTER_USER_KEY, JSON.stringify(readyData));
      resetInputs();
      navigate('/dashboard');
    } catch (e) {
      console.error(e);
      setLoading(false);
      return;
    }
  };

  return {
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
    accountType,
    district,
    upazila,
    street,
    area,
    house,
    districts,
    upazilas,
    mobile,
    thana,
    productTypes,
    productCategoryTypes,
    productType,
    productCategoryType,
    latitude,
    longitude,
    ...functions,
  };
}
