import {
  deliveryTypes,
  locations,
  productWeights,
  zoneTypes,
  productTypes,
} from 'assets';
import { useStore } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert, useAuth, useLoading } from '../contexts';
import { formatMobile } from '../utils';
import { useApi } from './use-api';
import { useData } from './use-data';

export function useDeliveryCharge() {
  const { showAlert } = useAlert();
  const { setLoading } = useLoading();
  const { createParcel } = useApi();
  const navigate = useNavigate();
  const { stores, getStoreList } = useStore();
  const { getChargeCatalog, getCodCharge } = useData();

  const handlers = {};

  const calculateLocation = (deliveryType,recipientCity)=>{
    let location =[];
    if(deliveryType === 'sameCity' || deliveryType === 'dhakaSuburbs' || deliveryType === 'sameDayDelivery'){
      return locations.find((loc) => 
        loc.district?.toLowerCase() === (recipientCity || "Dhaka")?.toLowerCase()
      );
    }
    if(deliveryType == 'outsideDhaka'){
      return locations.find((loc) => 
        loc.district?.toLowerCase() !== ("Dhaka")?.toLowerCase()
      );
    }
  }

  handlers.calculateDeliveryCharge = function (
    deliveryType,
    itemWeight,
    recipientCity,
    recipientZone,
    amountToCollect = 0,
    itemQuantity
  ) {
    console.log(deliveryType)
    
    if(typeof deliveryType == 'undefined' || deliveryType == ''){
      deliveryType = 'sameCity';
    }
    if(typeof recipientCity == 'undefined'){
      recipientCity = 'Dhaka';
    }
    if(typeof recipientZone == 'undefined'){
      recipientZone = 'Badda';
    }

    if(!itemWeight){
      itemWeight = 1;
    }
    // const selectedLoc = locations.find((loc) => 
    //   loc.district?.toLowerCase() === (recipientCity || "Dhaka")?.toLowerCase()
    // );
    
    // const selectedLoc = calculateLocation(deliveryType,recipientCity)
    // let zoneType = selectedLoc?.type || zoneTypes[0] || 'insideDhaka';

    // if(zoneType === 'insideDhaka') {
    //   if(recipientZone?.toLowerCase() === "keraniganj" || recipientZone?.toLowerCase() === "savar") {
    //     zoneType = 'dhakaSuburbs';
    //   }
    // }
    
    // // if then replace hardly. Same day delivery priority is best, bcz it is exceptional and not like all types of inside, outside, suburbs (why with that list i don't know)
    // if (
    //   deliveryType?.trim()?.replace(/ /gi, '_') ===
    //   'sameDayDelivery'
    // ) {
    //   zoneType = 'sameDayDelivery';
    // }
    console.log(deliveryType)
    const catalog = getChargeCatalog(deliveryType);
    const codCharge = getCodCharge(deliveryType);

    const cod = Math.round(
      ((parseFloat(amountToCollect || 0) || 0) / 100) * codCharge
    );
    const deliveryCharge =
      ((Math.abs(parseFloat(itemWeight || 1)) || 1) - 1) * catalog.next +
      catalog.charge || 0;

    return {
      total: cod + deliveryCharge,
      cod,
      deliveryCharge,
    };
  };

  return {
    ...handlers,
  };
}
