import {
  brandLight,
  IconService1,
  IconService2,
  IconService3,
  IconService4,
  IconService5,
  ImgServiceHero,
} from 'assets';
import AboutFirstPart from 'components/about/about-first-part';
import { useData } from 'hooks';
import React, { useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Divider, Tab, Table } from 'semantic-ui-react';

const PriceCalculator = () => {
  const panes = [
    {
      menuItem: {
        name: 'Same City',
        icon: IconService1,
        value: 'sameCity',
      },
      render: {
        img: ImgServiceHero,
        list: [
          'Delivery within Dhaka: 6-8 hrs (same day delivery)',
          'Delivery within Dhaka: Next Day Delivery',
          'Delivery within Suburbs areas of Dhaka: 24-48 hrs',
          'Outside Dhaka: 2-3 Working days',
        ],
      },
    },
    {
      menuItem: {
        name: 'Dhaka Suburb',
        icon: IconService5,
        value: 'dhakaSuburbs',
      },
      render: {
        img: ImgServiceHero,
        list: [
          'Delivery within Dhaka: 6-8 hrs (same day delivery)',
          'Delivery within Dhaka: Next Day Delivery',
          'Delivery within Suburbs areas of Dhaka: 24-48 hrs',
          'Outside Dhaka: 2-3 Working days',
        ],
      },
    },
    {
      menuItem: {
        name: 'Outside City',
        icon: IconService2,
        value: 'outsideCity',
      },
      render: {
        img: ImgServiceHero,
        list: [
          'Delivery within Dhaka: 6-8 hrs (same day delivery)',
          'Delivery within Dhaka: Next Day Delivery',
          'Delivery within Suburbs areas of Dhaka: 24-48 hrs',
          'Outside Dhaka: 2-3 Working days',
        ],
      },
    },
    {
      menuItem: {
        name: 'Same Day Delivery',
        icon: IconService4,
        value: 'sameDayDelivery',
      },
      render: {
        img: ImgServiceHero,
        list: [
          'Delivery within Dhaka: 6-8 hrs (same day delivery)',
          'Delivery within Dhaka: Next Day Delivery',
          'Delivery within Suburbs areas of Dhaka: 24-48 hrs',
          'Outside Dhaka: 2-3 Working days',
        ],
      },
    },
  ];

  return (
    <Container fluid id='pricing_section' className='py-5 pricing-table-whole'>
      <Container className='section py-5'>
        <div className='mb-5'>
          <h1 className='fs-1 fw-bold text-center text-danger mb-5'>
            Our Price Plan{' '}
          </h1>
          <Row className='justify-content-center align-items-center'>
            <Col lg='12'>
              <MyTab panes={panes} />
            </Col>
          </Row>
        </div>
      </Container>
    </Container>
  );
};

const MenuItem = ({ name, icon, active, onClick }) => {
  return (
    <button
      className={`my-tab-btn py-4 border mb-3 ${
        active ? 'active bg-danger border-danger' : 'bg-transparent'
      }`}
      onClick={onClick}
    >
      {/* <div className='mb-2'>
        <img src={icon} alt='' height={35} />
      </div> */}
      <h4 className='text-nowrap'>{name}</h4>
    </button>
  );
};

const TabPane = ({ heading, img, list = [], value }) => {
  const { getChargeCatalogForBasic } = useData();
  const data = getChargeCatalogForBasic(value);

  return (
    <Row className='g-8'>
      <Col lg={12}>
        <Table celled structured className='rounded-1 price-table-home mb-5'>
          <Table.Header>
            <Table.Row textAlign='center'>
              <Table.HeaderCell className='fs-5'>Delivery Time</Table.HeaderCell>
              <Table.HeaderCell className='fs-5'>Upto 1KG</Table.HeaderCell>
              <Table.HeaderCell className='fs-5'>1-2KG</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row textAlign='center'>
              <Table.Cell className='fs-3'>{data?.time}</Table.Cell>
              <Table.Cell className='fs-3'>BDT {data?.charge}</Table.Cell>
              <Table.Cell className='fs-3'>
                BDT {data?.charge + data?.next}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <ul className='fs-5'>
          <li>1% COD charge will be applicable</li>
          <li>This price/plan is exclusive of any VAT/TAX</li>
          <li>
            For weight more than 2KG, an additional {data?.next} TK/per KG will
            be applicable.
          </li>
        </ul>
      </Col>
    </Row>
  );
};

const MyTab = ({ panes }) => {
  const [active, setActive] = useState(0);

  const pane = panes[active].render || panes[0]?.render;

  return (
    <div className='my-tab'>
      <Row className='d-flex justify-content-center'>
        <Col md={12} lg={11} xl={8}>
          <p className='mb-3'>Select the location as per your requirement</p>
          <div className='my-tab-btns mb-5'>
            {panes?.map((pane, i) => {
              return (
                <MenuItem
                  {...pane.menuItem}
                  active={i === active}
                  onClick={() => setActive(i)}
                />
              );
            })}
          </div>
        </Col>
        <Col lg={12} className='my-tab-pane'>
          <TabPane
            heading={panes[active].menuItem?.name}
            img={pane.img}
            list={pane.list}
            value={panes[active].menuItem?.value}
          />
        </Col>
      </Row>
    </div>
  );
};

export default PriceCalculator;
