import React, { useEffect } from 'react';
import {
  Page,
  Text,
  View,
  Image as Img,
  Document,
  StyleSheet,
  Svg,
  Path,
  G,
  Rect,
} from '@react-pdf/renderer';
// @ts-ignore
import logo from '../../assets/images/logo.png';
import JsBarcode from 'jsbarcode';
import moment from 'moment';
import { EuiIcon } from '@elastic/eui';
import { useData } from 'hooks';
import { USER_DATA_KEY } from 'contexts';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    padding: 20,
    fontSize: 9,
    lineHeight: 1.3,
  },
  sm: {
    fontSize: 7,
  },
  md: {
    fontSize: 10,
  },
  lg: {
    fontSize: 12,
  },
  bold: {
    fontWeight: 'bold',
  },
  info: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  icon: {
    width: 15,
    display: 'flex',
    margin: '0 5px',
  },
  section: {
    flexGrow: 1,
    // height: 842,
    // backgroundColor: 'white',
  },
  headerArea: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: 15,
    width: '100%',
  },
  headerLeft: {},
  logo: {
    width: 80,
    marginBottom: 5,
  },
  headerRight: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  shopName: {
    marginBottom: 5,
    textAlign: 'right',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  shopText: {
    marginBottom: 3,
    fontWeight: 300,
    textAlign: 'right',
    maxWidth: 200,
  },

  titleArea: {
    // backgroundColor: "#E5E5E5",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 6,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 10,
    width: 125,
    border: '2px solid #e3e3e3',
  },
  title: {
    fontWeight: 600,
    lineHeight: 1,
  },

  orderDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    // alignItems: "center",
    flexDirection: 'row',
    marginBottom: 25,
    marginTop: 25,
    width: '100%',
  },

  middleLeft: {
    maxWidth: 220,
    lineHeight: 1.5,
  },
  bilingTitle: {
    fontWeight: 600,
  },
  bilingTitleValue: {
    fontSize: 14,
    fontWeight: 900,
    marginBottom: 5,
  },
  middleRight: {
    width: '45%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },

  userName: {
    marginBottom: 5,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  userText: {
    textAlign: 'left',
    fontWeight: 200,
    display: 'flex',
    justifyContent: 'flex-start',
  },

  middleArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 5,
  },
  totalDue: {
    marginBottom: 0,
    color: '#bf1a0d',
  },

  productHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: '#efefef',
    borderRadius: 2,
    padding: '8px 15px',
    color: '#000',
  },
  productHeaderText: {
    textAlign: 'right',
    fontWeight: 'bold',
    padding: '0 5px',
    width: 100,
    whiteSpace: 'break-spaces',
  },
  productList: {
    borderTop: '1px solid #f6f6f5',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 5px',
  },

  producDetails: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
  },
  producText: {
    fontWeight: 400,
    textAlign: 'right',
    width: 100,
    whiteSpace: 'break-spaces',
    padding: '0 8px',
  },
  producName: {
    width: 200,
    textAlign: 'left',
  },
  imageArea: {
    height: 35,
    width: 35,
    display: 'flex',
    flexDirection: 'column',
    marginRight: 8,
  },
  image: {
    flexDirection: 'row',
    flex: 1,
    height: 35,
    width: 35,
    backgroundColor: '#ebebeb',
    borderRadius: 3,
  },

  acountSection: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'row',
    padding: '20px 0',
  },
  acountArea: {
    textAlign: 'right',
    marginBottom: 25,
    backgroundColor: '#f8f8f8',
    padding: 15,
  },

  acountAreaPara: {
    textAlign: 'right',
    marginBottom: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    flexDirection: 'row',
    width: 200,
    padding: '0px 5px',
    fontWeight: 'bold',
  },
  fromSmall: {
    fontWeight: 400,
    display: 'flex',
    justifyContent: 'flex-end',
  },

  footerArea: {
    textAlign: 'left',
    // maxWidth: 300,
    display: 'flex',
    alignSelf: 'baseline',
    justifyContent: 'flex-end',
    marginTop: 'auto',
  },
  footerText: {
    // display: "flex",
    flexDirection: 'row',
    fontWeight: 200,
    marginTop: 5,
  },
  textRed: {
    color: '#cd113b',
  },

  table: {
    border: '1.5px solid #e0e0e0',
  },
  tr: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  th: {
    fontWeight: 'bold',
    padding: 5,
    borderRight: '1.5px solid #e0e0e0',
    flex: '1 1 50%',
  },
  td: {
    padding: 5,
    flex: '1 1 50%',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

const MerchantInvoice = ({ data: orderList, invoice, store }) => {
  // it actually doesn't depend on current cod fee, delivery already created before with past charge
  const getCodCharge = () => {
    const user = localStorage.getItem(USER_DATA_KEY);
    if (user) {
      return JSON.parse(user)?.codCharge || 1;
    }

    return 1;
  };

  console.log(orderList, invoice);
  const totalCalc = orderList.reduce(
    (obj, order) => {
      const data = order?.parcelOrder;
      obj.amountToCollect += data?.amountToCollect;
      obj.cod += Math.round((data?.codCharge));
      obj.deliveryCharge += data?.deliveryCharge
      obj.totalCharge += data?.deliveryCharge + Math.round((data?.codCharge));
      obj.payout += data?.amountToCollect - obj?.totalCharge;
      return obj;
    },
    {
      amountToCollect: 0,
      deliveryCharge: 0,
      cod: 0,
      totalCharge: 0,
      payout: 0,
    }
  );

  const total = {
    amountToCollect: invoice?.collectedAmount || 0,
    deliveryCharge: totalCalc?.deliveryCharge || 0,
    cod: totalCalc?.cod || 0,
    totalCharge: invoice?.fee || 0,
    payout: invoice?.receivable || 0,
  };

  const orderDetails = orderList[0];

  return (
    <Document title='Order Invoice'>
      <Page wrap size='A4' style={styles.page}>
        <View style={styles.section}>
          <View style={styles.headerArea}>
            <View style={styles.headerLeft}>
              <Img src={logo} style={styles.logo} />
              <View style={styles.info}>
                <View>
                  <Text
                    style={{
                      ...styles.userText,
                      maxWidth: 120,
                      ...styles.sm,
                      color: 'gray',
                    }}
                  >
                    Niketan, House 86, Flat A2, Road 10/1, Block D, Dhaka 1212
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.headerRight}>
              <View style={styles.info}>
                <View style={styles.icon}>
                  <Email />
                </View>
                <View>
                  <Text style={{ ...styles.userText, marginBottom: 2 }}>
                    piickmeexpress@qcoom.com
                  </Text>
                </View>
              </View>
              <View style={styles.info}>
                <View style={{ ...styles.icon, marginBottom: 2 }}>
                  <Call />
                </View>
                <View>
                  <Text style={styles.userText}>+8809609414141 (Sat-Thu)</Text>
                </View>
              </View>
              <View style={styles.info}>
                <View style={styles.icon}>
                  <Web />
                </View>
                <View>
                  <Text style={styles.userText}>www.qcoom.com</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={styles?.orderDetails}>
            <View style={styles.middleLeft}>
              <View>
                <Text style={styles.bilingTitle}>INVOICE TO</Text>
              </View>
              <View>
                <Text style={styles.bilingTitleValue}>
                  {orderDetails?.parcelOrder?.storeName}
                </Text>
              </View>
              <View>
                <Text style={styles.userText}>
                  Contact:{' '}
                  {store?.contactNumber?.split('+88') ||
                    store?.secondaryContactNumber?.split('+88') ||
                    '-'}
                </Text>
              </View>
              <View>
                <Text style={{ ...styles.userText, marginBottom: 5 }}>
                  Address: {orderDetails?.parcelOrder?.storeAddress || '-'}
                </Text>
              </View>

              <View>
                <Text style={styles.userText}>
                  Owner: {store?.business?.ownersName || '-'}
                </Text>
              </View>
              <View>
                <Text style={styles.userText}>
                  Contact: {store?.business?.mobileNumber?.split('+88') || '-'}
                </Text>
              </View>
              <View>
                <Text style={styles.userText}>
                  Email: {store?.business?.emailAddress || '-'}
                </Text>
              </View>
            </View>

            <View style={styles?.middleRight}>
              <View style={styles?.table}>
                <View style={{ ...styles?.tr, backgroundColor: '#efefef' }}>
                  <View style={styles?.th}>
                    <Text>Total Paid Out</Text>
                  </View>
                  <View style={styles?.td}>
                    <Text>BDT {total.payout}</Text>
                  </View>
                </View>
                <View style={styles?.tr}>
                  <View style={styles?.th}>
                    <Text>Invoice Date</Text>
                  </View>
                  <View style={styles?.td}>
                    <Text>
                      {moment(invoice?.createdAt).format('DD-MM-yyyy')}
                    </Text>
                  </View>
                </View>
                <View style={{ ...styles?.tr, backgroundColor: '#efefef' }}>
                  <View style={styles?.th}>
                    <Text>Invoice Id #</Text>
                  </View>
                  <View style={styles?.td}>
                    <Text>{invoice?.invoiceId}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.middleArea}>
            <View>
              <Text style={styles.userText}>Details:</Text>
            </View>
          </View>

          <View>
            <View style={styles.productList}>
              <View style={{ ...styles.producDetails, color: 'black' }}>
                <Text style={styles.producName}>Order Info</Text>
                <Text style={{ ...styles.producText, fontWeight: 'bold' }}>
                  Delivery Type
                </Text>
                <Text style={{ ...styles.producText, fontWeight: 'bold' }}>
                  Merchant Order Id
                </Text>
                <Text style={{ ...styles.producText, fontWeight: 'bold' }}>
                  Collected Amount
                </Text>
                <Text style={{ ...styles.producText, fontWeight: 'bold' }}>
                  COD Fee
                </Text>
                <Text style={{ ...styles.producText, fontWeight: 'bold' }}>
                  Delivery Charge
                </Text>
                <Text style={{ ...styles.producText, fontWeight: 'bold' }}>
                  Total Charge
                </Text>
                <Text style={{ ...styles.producText, fontWeight: 'bold' }}>
                  Payout
                </Text>
              </View>
            </View>

            {orderList?.map((order, index) => (
              <View key={index} style={styles.productList}>
                <View style={{ ...styles.producDetails, color: 'gray' }}>
                  <View>
                    <Text style={styles.producName}>Invoice #</Text>
                    <Text style={styles.producName}>{order?.orderId}</Text>
                    <Text style={styles.producName}>
                      {order?.parcelOrder?.recipientName}
                    </Text>
                    <Text style={styles.producName}>
                      {order?.parcelOrder?.recipientAddress}
                    </Text>
                  </View>
                  <Text style={styles.producText}>{order?.parcelOrder?.deliveryType}</Text>
                  <Text style={styles.producText}>
                    {order?.parcelOrder?.merchantOrderId || '-'}
                  </Text>{' '}
                  <Text style={styles.producText}>
                    {order?.parcelOrder?.amountToCollect || 0}
                  </Text>{' '}
                  <Text style={styles.producText}>
                    {order?.parcelOrder?.codCharge || 0}
                  </Text>{' '}
                  <Text style={styles.producText}>
                    {order?.parcelOrder?.deliveryCharge || 0}
                  </Text>{' '}
                  <Text style={styles.producText}>
                    {order?.parcelOrder?.deliveryCharge + order?.parcelOrder?.codCharge || 0}
                  </Text>{' '}
                  <Text style={styles.producText}>
                    {order?.parcelOrder?.amountToCollect - (order?.parcelOrder?.deliveryCharge + order?.parcelOrder?.codCharge) || 0}
                  </Text>
                </View>
              </View>
            ))}
            <View style={styles.productHeader}>
              <Text style={styles.producName}>Total</Text>
              <Text style={styles.productHeaderText}></Text>
              <Text style={styles.productHeaderText}></Text>
              <Text style={styles.productHeaderText}>
                {total.amountToCollect}
              </Text>
              <Text style={styles.productHeaderText}>{total.cod}</Text>
              <Text style={styles.productHeaderText}>
                {total.deliveryCharge}
              </Text>
              <Text style={styles.productHeaderText}>{total.totalCharge}</Text>
              <Text style={styles.productHeaderText}>{total.payout}</Text>
            </View>
          </View>

          <View style={styles.footerArea}>
            <View>
              <Text style={styles.footerText}>
                *Terms and Conditions: Payment should be made within 48 hours by
                bank or mobile-banking.
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default MerchantInvoice;

const Email = () => (
  <Svg width='25px' height='12px' viewBox='0 0 80 80'>
    <Path
      fill='#e83330'
      d='M58.0034485,8H5.9965506c-3.3136795,0-5.9999995,2.6862001-5.9999995,6v36c0,3.3137016,2.6863203,6,5.9999995,6  h52.006897c3.3137016,0,6-2.6862984,6-6V14C64.0034485,10.6862001,61.3171501,8,58.0034485,8z M62.0034485,49.1108017  L43.084549,30.1919994l18.9188995-12.0555992V49.1108017z M5.9965506,10h52.006897c2.2056007,0,4,1.7943001,4,4v1.7664003  L34.4677505,33.3134003c-1.4902,0.9492989-3.3935013,0.9199982-4.8495998-0.0703011L1.9965508,14.4694996V14  C1.9965508,11.7943001,3.7910507,10,5.9965506,10z M1.9965508,16.8852005L21.182251,29.9251003L1.9965508,49.1108017V16.8852005z   M58.0034485,54H5.9965506c-1.6473999,0-3.0638998-1.0021019-3.6760998-2.4278984l20.5199013-20.5200024l5.6547985,3.843401  c1.0859013,0.7383003,2.3418007,1.1083984,3.5995998,1.1083984c1.1953011,0,2.3925018-0.3339996,3.4463005-1.0048981  l5.8423996-3.7230015l20.2961006,20.2961025C61.0673485,52.9978981,59.6508713,54,58.0034485,54z'
    />
    <G></G>
    <G></G>
    <G></G>
    <G></G>
    <G></G>
    <G></G>
    <G></G>
    <G></G>
    <G></G>
    <G></G>
    <G></G>
    <G></G>
    <G></G>
    <G></G>
    <G></G>
  </Svg>
);

const Call = () => (
  <Svg width='25px' height='12px' viewBox='0 0 28 24'>
    <Rect data-name='Rectangle 4' width='24' height='24' fill='none' />
    <Path
      d='M7.02,15.976,5.746,13.381a.7.7,0,0,0-.579-.407l-1.032-.056a.662.662,0,0,1-.579-.437,9.327,9.327,0,0,1,0-6.5.662.662,0,0,1,.579-.437l1.032-.109a.7.7,0,0,0,.589-.394L7.03,2.446l.331-.662a.708.708,0,0,0,.07-.308.692.692,0,0,0-.179-.467A3,3,0,0,0,4.693.017l-.235.03L4.336.063A1.556,1.556,0,0,0,4.17.089l-.162.04C1.857.679.165,4.207,0,8.585V9.83c.165,4.372,1.857,7.9,4,8.483l.162.04a1.556,1.556,0,0,0,.165.026l.122.017.235.03a3,3,0,0,0,2.558-.993.692.692,0,0,0,.179-.467.708.708,0,0,0-.07-.308Z'
      transform='translate(3.887 6.093) rotate(-30)'
      fill='none'
      stroke='#e83330'
      stroke-miterlimit='10'
      stroke-width='1.5'
    />
  </Svg>
);

const Web = () => (
  <Svg
    style={{ fontWeight: 100 }}
    width='25px'
    height='10px'
    viewBox='0 0 28 24'
  >
    <Path
      d='M3.51211712,15 L8.17190229,15 C8.05949197,14.0523506 8,13.0444554 8,12 C8,10.9555446 8.05949197,9.94764942 8.17190229,9 L3.51211712,9 C3.18046266,9.93833678 3,10.9480937 3,12 C3,13.0519063 3.18046266,14.0616632 3.51211712,15 L3.51211712,15 Z M3.93551965,16 C5.12590433,18.3953444 7.35207678,20.1851177 10.0280093,20.783292 C9.24889451,19.7227751 8.65216136,18.0371362 8.31375067,16 L3.93551965,16 L3.93551965,16 Z M20.4878829,15 C20.8195373,14.0616632 21,13.0519063 21,12 C21,10.9480937 20.8195373,9.93833678 20.4878829,9 L15.8280977,9 C15.940508,9.94764942 16,10.9555446 16,12 C16,13.0444554 15.940508,14.0523506 15.8280977,15 L20.4878829,15 L20.4878829,15 Z M20.0644804,16 L15.6862493,16 C15.3478386,18.0371362 14.7511055,19.7227751 13.9719907,20.783292 C16.6479232,20.1851177 18.8740957,18.3953444 20.0644804,16 L20.0644804,16 Z M9.18440269,15 L14.8155973,15 C14.9340177,14.0623882 15,13.0528256 15,12 C15,10.9471744 14.9340177,9.93761183 14.8155973,9 L9.18440269,9 C9.06598229,9.93761183 9,10.9471744 9,12 C9,13.0528256 9.06598229,14.0623882 9.18440269,15 L9.18440269,15 Z M9.3349823,16 C9.85717082,18.9678295 10.9180729,21 12,21 C13.0819271,21 14.1428292,18.9678295 14.6650177,16 L9.3349823,16 L9.3349823,16 Z M3.93551965,8 L8.31375067,8 C8.65216136,5.96286383 9.24889451,4.27722486 10.0280093,3.21670804 C7.35207678,3.81488234 5.12590433,5.60465556 3.93551965,8 L3.93551965,8 Z M20.0644804,8 C18.8740957,5.60465556 16.6479232,3.81488234 13.9719907,3.21670804 C14.7511055,4.27722486 15.3478386,5.96286383 15.6862493,8 L20.0644804,8 L20.0644804,8 Z M9.3349823,8 L14.6650177,8 C14.1428292,5.03217048 13.0819271,3 12,3 C10.9180729,3 9.85717082,5.03217048 9.3349823,8 L9.3349823,8 Z M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z'
      fill='none'
      stroke='#e83330'
      stroke-miterlimit='10'
      stroke-width='1.5'
    />
  </Svg>
);
