import React from 'react';
import './styles/main.scss';
import { Route, Routes } from 'react-router-dom';
import { Home, Logout } from './pages';
import { Providers } from './contexts';
import { Navbar, Alert } from './components';
import Footer from './components/footer';
import Login from './pages/login';
import Register from './pages/register';
import Users from './pages/users';
import { SocketProvider } from './contexts';
import { TrackingPage } from './pages/track';
import TermsModal from 'components/terms-modal';
import PaymentMethodModal from 'components/payment-method';
import ForgetPassword from 'pages/forget-password';
import ForgetPassWordVerify from 'pages/forget-password-verify';

export default function App() {
  return (
    <Providers>
      <Alert />
      <TermsModal />
      <PaymentMethodModal />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/login' element={<Login />} />
        <Route path='/forget-password' element={<ForgetPassword />} />
        <Route path='/forget-password-verify' element={<ForgetPassWordVerify />} />
        <Route path='/logout' element={<Logout />} />
        <Route path='/register/*' element={<Register />} />
        <Route path='/track' element={<TrackingPage />} />
        <Route
          path='/*'
          element={
            // <SocketProvider>
            <Users />
            // </SocketProvider>
          }
        />
        <Route path='/*' element={<>404</>} />
      </Routes>
    </Providers>
  );
}
