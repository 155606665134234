import React from 'react';
import { Container } from 'react-bootstrap';
import AboutFirstPart from './about-first-part';
import AboutLastPart from './about-last-part';

const About = () => {
  return (
    <Container fluid id="services_section">
      <Container className='section'>
        <AboutFirstPart />
      </Container>
      <div className='bg-gray py-5'>
        <Container className='section'>
          <AboutLastPart />
        </Container>
      </div>
    </Container>
  );
};

export default About;
