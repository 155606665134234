import React from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Nav,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { brandLight, brandShort, siteLinks } from '../assets';
import { Link } from 'react-router-dom';
import { v4 } from 'uuid';
import { FaFacebook, FaInstagram, FaEnvelope } from 'react-icons/fa';
import { useData } from 'hooks';
// @ts-ignore

const Footer = () => {
  const { getSiteInfos } = useData();
  const data = getSiteInfos();

  function copyMobile() {
    navigator.clipboard.writeText(data.contactNumber);
  }

  function copyEmail() {
    navigator.clipboard.writeText(data.email);
  }

  return (
    <Container fluid className='bg-white py-4 border-top'>
      <Container
        fluid
        className='d-flex flex-column flex-md-row align-items-center'
        style={{ justifyContent: 'center', gridGap: '2rem' }}
      >
        <small className='text-center center'>
          &copy; {new Date().getFullYear()} Piickme Express, Bangladesh
        </small>
      </Container>
    </Container>
  );
};

export default Footer;
