import { EuiPortal, EuiProgress } from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { LoadingScreen } from '../components';

const LoadingContext = React.createContext({
  loading: false,
  setLoading: function (value) {},
});

export const useLoading = () => {
  return React.useContext(LoadingContext);
};

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = React.useState(false);

  let progress2 = (
    <div>
      <div style={{ position: 'absolute', zIndex: '5' }}>
        <EuiPortal>
          <EuiProgress size='xs' color='accent' position='fixed' />
        </EuiPortal>
      </div>
    </div>
  );

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      {/* {loading && <LoadingScreen />} */}
      {children}
      {loading && progress2}
    </LoadingContext.Provider>
  );
};
