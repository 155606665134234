import {
  brandLight,
  IconService1,
  IconService2,
  IconService3,
  IconService4,
  IconService5,
  ImgServiceHero,
} from 'assets';
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Tab } from 'semantic-ui-react';
import Card from './card';

const AboutFirstPart = () => {
  const panes = [
    {
      menuItem: {
        name: 'Express Delivery',
        icon: IconService1,
      },
      render: {
        img: ImgServiceHero,
        list: [
          'Delivery within Dhaka: 6-8 hrs (same day delivery)',
          'Delivery within Dhaka: Next Day Delivery',
          'Delivery within Suburbs areas of Dhaka: 24-48 hrs',
          'Outside Dhaka: 2-3 Working days',
        ],
      },
    },
    {
      menuItem: {
        name: 'Nationwide Delivery',
        icon: IconService5,
      },
      render: {
        img: ImgServiceHero,
        list: [
          "Offering home delivery to 64 Districts, 492 Upazila, and all unions, nationwide where your packages will be delivered to your customer's doorstep within 48-72 hrs.",
        ],
      },
    },
    {
      menuItem: {
        name: 'Fulfillment Solution',
        icon: IconService2,
      },
      render: {
        img: ImgServiceHero,
        list: [
          'We have got you covered with our top-notch warehousing and storage services. Through warehousing and fulfillment solutions, offers customized service packages with inventory management support.',
        ],
      },
    },
    {
      menuItem: {
        name: 'Cash On Delivery',
        icon: IconService4,
      },
      render: {
        img: ImgServiceHero,
        list: [
          'We provide the option of cash on delivery by collecting the payment in cash at the time of delivery. And shall execute fund disbursement instructions to the merchant’s Account through banking or MFS channels on every working day of the week.',
        ],
      },
    },
    {
      menuItem: {
        name: 'Reverse Logistics',
        icon: IconService3,
      },
      render: {
        img: ImgServiceHero,
        list: [
          'Reverse logistics is provided that allows customers to return their products.',
        ],
      },
    },
  ];

  return (
    <div className='mb-5'>
      <h1 className='fs-1 fw-bold text-center text-danger mb-5'>
        Our Services
      </h1>
      <Row className='justify-content-center align-items-center'>
        <Col lg='12'>
          <MyTab panes={panes} />
        </Col>
      </Row>
    </div>
  );
};

const MenuItem = ({ name, icon, active, onClick }) => {
  return (
    <button
      className={`my-tab-btn mb-3 ${active ? 'active' : ''}`}
      onClick={onClick}
    >
      <div className='mb-2'>
        <img src={icon} alt='' height={35} />
      </div>
      <h4>{name}</h4>
    </button>
  );
};

const TabPane = ({ heading, img, list = [] }) => {
  return (
    <Row className='g-8'>
      <Col lg={6} className='pe-lg-4'>
        <img src={img} alt='' width='100%' />
      </Col>
      <Col lg={6}>
        <h2 className='fw-bold fs-2 my-4'>{heading}</h2>
        <ul>
          {list.map((item, i) => {
            return (
              <li key={i} className='fs-5'>
                {item}
              </li>
            );
          })}
        </ul>
      </Col>
    </Row>
  );
};

const MyTab = ({ panes }) => {
  const [active, setActive] = useState(0);

  const pane = panes[active].render || panes[0]?.render;

  return (
    <div className='my-tab'>
      <div className='my-tab-btns mb-5'>
        {panes?.map((pane, i) => {
          return (
            <MenuItem
              {...pane.menuItem}
              active={i === active}
              onClick={() => setActive(i)}
            />
          );
        })}
      </div>
      <div className='my-tab-pane mb-5'>
        <TabPane
          heading={panes[active].menuItem?.name}
          img={pane.img}
          list={pane.list}
        />
      </div>
    </div>
  );
};

export default AboutFirstPart;
