import React, { useEffect, useRef, useState } from 'react';
import { Form, Row, Col, Card, Container } from 'react-bootstrap';
import FormGroup from '../form-group';
import { useApi, useCreateParcel, useOrder, useStore } from '../../hooks';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  EuiButton,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiPanel,
  EuiTitle,
  EuiButtonGroup,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiSuperSelect,
  EuiHealth,
  EuiTextArea,
  EuiFormControlLayout,
  EuiFormLabel,
  EuiToolTip,
  EuiIcon,
  EuiFieldNumber,
  EuiText,
  EuiSelect,
  EuiComboBox,
  EuiProgress,
} from '@elastic/eui';
import {
  productTypes,
  deliveryTypes,
  productWeights,
  locations,
  OrderStatuses,
} from 'assets';
import { useAlert, useAuth, useLoading } from 'contexts';
import { AutoComplete, Cascader } from 'antd';
import { useTermsModal } from 'contexts/terms.context';
import { usePaymentMethodModal } from 'contexts/payment-method.context';

const CreateParcelForm = ({ setChargeData }) => {
  const [options, setOptions] = useState([]);
  const [hubList, setHubList] = useState([]);

  const [cityList, setCityList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [canEdit, setCanEdit] = useState(false);
  const formRef = useRef();
  const form = useCreateParcel(formRef);
  const {city,zone,area} = useCreateParcel(formRef);
  const { getStoreList, stores } = useStore();
  const { loadHubList,getCityList, getZoneList, getAreaList } = useApi();
  const { token, user } = useAuth();
  const { loading,setLoading } = useLoading();
  const navigate = useNavigate();
  const { showTermsModal } = useTermsModal();
  const { orderId } = useParams();
  const { showAlert } = useAlert();
  const { getOrderDetails, order } = useOrder();
  const { showPaymentMethodModal } = usePaymentMethodModal();

  const [itemDescription, setItemDescription] = useState('');
  const [specialInstruction, setSpecialInstruction] = useState('');

  useEffect(() => {
    if (orderId) {
      if (token) {
        getOrderDetails(orderId);
      }
    } else {
      if (window?.location?.pathname?.includes('/deliveries/update')) {
        showAlert('No order id found', 'error');
      }
    }
  }, [orderId, token]);

  useEffect(() => {
    if (order?.orderId) {
      form.handleAllForUpdate(order);
    } else {
      if (orderId && !loading && order) showAlert('Invalid orderId', 'error');
    }
  }, [order, orderId]);

  useEffect(() => {
    if (
      window?.location?.pathname?.includes('/deliveries/update') &&
      (!order?.orderId ||
        !OrderStatuses?.slice(0, 4).concat('INITIATED').includes(order?.status))
    ) {
      setCanEdit(false);
    } else {
      setCanEdit(true);
    }
  }, [order]);

  //console.log('canEdit', canEdit, order);
  
  React.useEffect(async() => {
    if (token) {
      getStoreList().then((list) => {
        if (order?.orderId) {
          const str = list?.find(
            (s) => s.name === order?.parcelOrder?.storeName
          );
          form.handleStore(str);
        } else {
          form.handleStore(list[0]);
        }
      });
      // const response = await loadHubList();
      // const hubList = response?.piHubs?.map((hub)=>{
      //   return{
      //     'value': hub?.id,
      //     'label': hub?.name,
      //   }
      // })
      // setHubList(hubList)
      // console.log(hubList)
    }
  }, [token, order]);

  React.useEffect(() => {
    setChargeData?.(form);
  }, [form.totalCharge]);

  // const districtDisabled = (district)=>{
  //   if(form.deliveryType === 'sameCity'){
  //     //console.log(district, form?.selectedStore?.city, district != form?.selectedStore?.city)
  //     console.log(form?.selectedStore?.city)

  //     return district != form?.selectedStore?.city
  //   }else if(form?.deliveryType ==='outsideCity'){
  //     return district == form?.selectedStore?.city
  //   }else if((form.deliveryType === 'sameDayDelivery' || form.deliveryType === 'sameCity') && district?.toLowerCase() =='dhaka'){
  //     return false;
  //   }
  //   else if(form.deliveryType === 'dhakaSuburbs' && (district?.toLowerCase() == 'dhaka' || district?.toLowerCase() == 'gazipur' || district?.toLowerCase() == 'narayanganj')){
  //     return false;
  //   } 
  //   else return true;  
  // }

  // const zoneDisabled = (district, zone)=>{
  //   if(form.deliveryType === 'dhakaSuburbs'){
  //     if(district?.toLowerCase() == 'dhaka'){
  //       if(zone?.toLowerCase() =='savar' || zone?.toLowerCase() =='keraniganj'){
  //         return false;
  //       }else return true;
  //     }else if(district?.toLowerCase() == 'gazipur' || district?.toLowerCase() == 'narayanganj'){
  //       return false;
  //     }else return true;
  //   }else return false;
  // }

  useEffect(async () => {
    setLoading(true)
    if(!form.deliveryType){
      form.handleDeliveryType('sameCity')
    }
    const c = await getFormattedCityList();
    const top = [{
      label:'Select',
      value:'',
      id : 'cityId'
    }]
    console.log(top.concat(c))
    setCityList(top.concat(c ? c :[]));
    if(order?.parcelOrder?.recipientCityId){
      const city = c.find(city=>city.id==order?.parcelOrder?.recipientCityId)
      form.handleCity(city)
    }
    setLoading(false)
    
    
  }, [form.deliveryType,form.selectedStore]);

  useEffect(()=>{
    //during update order
    if(form.deliveryType && form.deliveryType != order?.parcelOrder?.deliveryType){
      setZoneList([])
      setAreaList([])
      form.handleZone(undefined);
      form.handleArea(undefined);
    }
  },[order])

  const getFormattedCityList = async()=>{
    
    if(form.deliveryType && form.selectedStore){
      const cityList = await getCityList(form.selectedStore?.id,form.deliveryType);
      return cityList?.commonCities?.map((city)=>{return {
        label: city.name,
        value : city.name,
        id : city.id,
      }})
    }
  }

  useEffect(async()=>{
    setLoading(true)
    const z = await getFormattedZoneList();
    const top = [{
      label:'Select',
      value:'',
      id : 'zoneId'
    }]
    setZoneList(top.concat(z ? z : []))
    if(order?.parcelOrder?.recipientZoneId){
      const zone = z.find(zone=>zone.id == order?.parcelOrder?.recipientZoneId)
      form.handleZone(zone)
    }
    setLoading(false)
  },[form.city])

  const getFormattedZoneList = async()=>{
    const zoneList = await getZoneList(form.city?.id ? form.city?.id : order?.parcelOrder?.recipientCityId , form.deliveryType ? form.deliveryType : order?.parcelOrder?.deliveryType);
    //console.log(zoneList)
    return zoneList?.commonZones?.map((zone)=>{return {
      label: zone.name,
      value : zone.name,
      id : zone.id,
    }})
  }

  useEffect(async()=>{
    setLoading(true)
    const a = await getFormattedAreaList()
    const top = [{
      label:'Select',
      value:'',
      id : 'areaId'
    }]
    setAreaList(top.concat(a ? a : []))
    if(order?.parcelOrder?.recipientAreaId){
      const area = a.find(area=>area.id == order?.parcelOrder?.recipientAreaId)
      form.handleArea(area)
    }
    setLoading(false)
  },[form.zone])

  const getFormattedAreaList = async()=>{
    const areaList = await getAreaList(form.zone?.id ? form.zone?.id : order?.parcelOrder?.recipientZoneId , form.deliveryType ? form.deliveryType : order?.parcelOrder?.deliveryType);
    //console.log(areaList)
    return areaList?.commonAreas?.map((area)=>{return {
      label: area.name,
      value : area.name,
      id : area.id
    }})
  }


  const getDropdownFormatList = (items, plain) => {
    return (
      items
        ?.map((item) => ({
          value: plain ? item : item?.id || item?.value,
          label: plain ? item : item?.name || item?.label,
          disabled:
            item?.value === 'sameDayDelivery' &&
            new Date().getUTCHours() + 6 >= 14,
          inputDisplay: (
            <EuiHealth
              color={
                item.value === 'sameCity' || item.value === 'outsideCity' || item.value === 'dhakaSuburbs'
                  ? 'accent'
                  : item.value === 'sameDayDelivery'
                  ? 'accent'
                  : 'accent'
              }
              style={{ lineHeight: 'inherit' }}
            >
              {plain ? item : item?.name || item?.label}
            </EuiHealth>
          ),
          dropdownDisplay: !plain && (
            <>
              <EuiHealth
                color={
                  item.value === 'sameCity' || item.value === 'outsideCity' || item.value === 'dhakaSuburbs'
                    ? 'accent'
                    : item.value === 'sameDayDelivery'
                    ? 'accent'
                    : 'accent'
                }
              >
                {item.description ? (
                  <strong>{item.name || item?.label}</strong>
                ) : (
                  item.name || item?.label
                )}
              </EuiHealth>
              <EuiText size='xs' color='subdued'>
                <p>{item.description}</p>
              </EuiText>
            </>
          ),
        }))
        ?.filter((item) => item) || []
    );
  };

  const handleAreaClick = (e, label, option) => {
    e.stopPropagation();
    console.log('clicked', label, option);
  };


  useEffect(() => {
    formRef?.current && formRef.current.reset();
  }, [order]);

  return (
    <div className='h-100'>
      {loading && <EuiProgress size="xs" color="accent" />}
      <EuiPanel
        // hasBorder
        hasShadow={false}
        className='bg-white full-form-row h-100'
      >
        <Form action='submit' ref={formRef} onSubmit={form.handleSubmit}>
          <EuiForm component='div'>
            <Row>
              <Col>
                <EuiPageHeader bottomBorder>
                  <EuiPageHeaderSection>
                    <EuiTitle size='m'>
                      <h3>{order && orderId ? 'Update':'New'} Delivery</h3>
                    </EuiTitle>
                  </EuiPageHeaderSection>
                  <EuiPageHeaderSection>
                    {/* <EuiButton fill className='me-3 mb-2 mb-md-0'>
                      Create Delivery Link
                    </EuiButton> */}
                    <EuiButton
                      className='outlined'
                      onClick={() => navigate('/deliveries/create/bulk')}
                    >
                      Bulk Order
                    </EuiButton>
                  </EuiPageHeaderSection>
                </EuiPageHeader>
              </Col>
            </Row>

            <EuiFieldText type='hidden' value={order?.orderId} name='orderId' />
            <EuiFieldText
              type='hidden'
              value={order?.status}
              name='orderStatus'
            />

            <Row className='py-4 g-3'>
              <Col sm='12' lg='4'>
                <EuiFormRow
                  label={
                    <span>
                      Store{' '}
                      <EuiToolTip
                        position='right'
                        content='Pickup location from where the products for delivery will be provided.'
                      >
                        <EuiIcon type='questionInCircle' color='subdued' />
                      </EuiToolTip>
                    </span>
                  }
                >
                  <EuiSuperSelect
                    name='storeId'
                    options={getDropdownFormatList(stores)}
                    valueOfSelected={form.selectedStore?.id}
                    onChange={(val) =>
                      form.handleStore(
                        stores?.find((store) => store.id === val)
                      )
                    }
                  />
                </EuiFormRow>
              </Col>
              <Col sm='12' lg='4'>
                <EuiFormRow label='Product Type'>
                  <EuiSuperSelect
                    name='itemType'
                    options={getDropdownFormatList(productTypes, true)}
                    valueOfSelected={form.itemType}
                    onChange={form.handleItemType}
                  />
                </EuiFormRow>
              </Col>
              <Col sm='12' lg='4'>
                <EuiFormRow label='Merchant Order ID'>
                  <EuiFieldText
                    name='merchantOrderId'
                    // value={loginForm.mobileNumber}
                    onChange={form.handleMerchantOrderId}
                    placeholder='Type ID'
                    value={form?.merchantOrderId}
                    maxLength={15}
                  />
                </EuiFormRow>
              </Col>
              <Col sm='12' lg='7' className='mt-5'>
                <Row className='g-4'>
                  <Col sm='12' lg='12'>
                    <h4 className='fw-bold fs-4'>Recipient Details</h4>
                  </Col>
                  <Col sm='12' lg='12'>
                    <EuiFormRow label='Recipient’s Name'>
                      <EuiFieldText
                        type='text'
                        label='Recipient’s Name'
                        name='recipientName'
                        placeholder='e.g. John Doe'
                        required
                        value={form.recipientName}
                        // value={form.customerName}
                        onChange={form.handleRecipientName}
                      />
                    </EuiFormRow>
                    <EuiFormRow label='Recipient’s Phone'>
                      <EuiFieldText
                        type='text'
                        label='Recipient’s Phone'
                        name='recipientPhone'
                        placeholder='01XXXXXXXXX'
                        required
                        minLength={11}
                        maxLength={11}
                        value={form.recipientPhone}
                        // value={form.invoice}
                        onChange={form.handleRecipientPhone}
                      />
                    </EuiFormRow>

                    <Row className=''>
                      <Col>
                        <EuiFormRow
                          label='City'
                        >
                          <EuiSelect
                            name='city'
                            placeholder='Select City'
                            options={getDropdownFormatList(cityList)}
                            value={form.city?.id}
                            onChange={(e)=>{
                              const city = cityList.find(city=>city.id==e.currentTarget.value)
                              form.handleCity(city)
                            }}
                          />
                          
                        </EuiFormRow>
                      </Col>
                      <Col>
                        <EuiFormRow label='Zone'>
                          <EuiSelect
                            name='zone'
                            placeholder='Select Zone'
                            options={getDropdownFormatList(zoneList)}
                            value={form.zone?.id}
                            //selectedOptions={[form.zone]}
                            //singleSelection={{ asPlainText: false }}
                            onChange={(e)=>{
                              console.log(e.currentTarget.value)
                              const zone = zoneList.find(zone=>zone.id==e.currentTarget.value)
                              form.handleZone(zone)
                            }}
                          />
                        </EuiFormRow>
                      </Col>
                      <Col>
                        <EuiFormRow label='Area'>
                          <EuiSelect
                            name='area'
                            placeholder='Select Area'
                            options={getDropdownFormatList(areaList)}
                            value={form.area?.id}
                            onChange={(e)=>{
                              const area = areaList.find(area=>area.id==e.currentTarget.value)
                              form.handleArea(area) 
                            }}
                          />
                        </EuiFormRow>
                      </Col>
                    </Row>

                    {/* <EuiFormRow label='Delivery Area'>
                      <Cascader
                        options={options}
                        size='large'
                        value={
                          form.city || form.zone || form.area
                            ? [form.city, form.zone, form.area]
                            : undefined
                        }
                        displayRender={displayRender}
                        style={{ width: '100%', borderRadius: 4 }}
                        placeholder='Select area'
                        showSearch={{ filter }}
                        onChange={onChange}
                        onSearch={(value) => console.log('search', value)}
                      />
                    </EuiFormRow> */}
                    <EuiFormRow label='Recipient’s Address'>
                      <EuiTextArea
                        defaultValue={form.recipientAddressState ? form.recipientAddressState : order?.parcelOrder?.recipientAddress}
                        name='recipientAddress'
                        placeholder='Enter full address'
                        aria-label='Full Address'
                        compressed
                        fullWidth
                        required
                        value={form.recipientAddressState}
                        onChange={(e)=>{
                          e.preventDefault();
                          form.handleRecipientAddressState(e);
                        }}
                      />
                    </EuiFormRow>

                    <EuiFormRow label='Special Instructions'>
                        <EuiTextArea
                          style={{height:40, width:"100%"}}
                          name='specialInstruction'
                          placeholder='Type Special Instructions If Any'
                          aria-label='Special Instructions'
                          compressed
                          fullWidth= {false}
                          resize={"none"}
                          value={specialInstruction ? specialInstruction : order?.parcelOrder?.specialInstruction}
                          defaultValue={order?.parcelOrder?.specialInstruction}
                          maxLength={120}
                          onChange={(e) => {
                            e.preventDefault();
                            if(/^[\x00-\x7F]+$/i.test(e.target.value.trim())){
                              //valid
                              setSpecialInstruction(e.target.value)
                            }else{
                              if(e.target.value == ''){
                                setSpecialInstruction('')
                              }
                            }
                          }}
                        />
                      </EuiFormRow>
                  </Col>
                </Row>
              </Col>
              <Col sm='12' lg='5' className='mt-5'>
                <Row className='g-4'>
                  <Col sm='12' lg='12'>
                    <h4 className='fw-bold fs-4'>Delivery Details</h4>
                  </Col>
                  <Col sm='12'>
                    <EuiFormRow
                      label='Delivery Type'
                      style={{ marginBottom: 16 }}
                    >
                      <EuiSuperSelect
                        name='deliveryType'
                        placeholder='Select Delivery Type'
                        options={getDropdownFormatList(deliveryTypes)}
                        valueOfSelected={form.deliveryType}
                        onChange={(value)=>{
                          form.resetDeliveryArea();
                          form.handleDeliveryType(value)
                        }}
                      />
                    </EuiFormRow>
                    <Row className='g-4' style={{ marginBottom: 16 }}>
                      <Col>
                        <EuiFormRow
                          label='Total Weight'
                        >
                          <EuiSuperSelect
                            name='itemWeight'
                            append={<EuiFormLabel>kg</EuiFormLabel>}
                            placeholder='Select Prouduct Weight'
                            options={getDropdownFormatList(productWeights)}
                            valueOfSelected={form.itemWeight}
                            onChange={form.handleItemWeight}
                          />
                          
                        </EuiFormRow>
                      </Col>
                      <Col>
                        <EuiFormRow label='Quantity'>
                          <EuiFieldNumber
                            name='itemQuantity'
                            placeholder='Select Item Quantity'
                            className='euiSuperSelect--inGroup'
                            min={1}
                            value={form.itemQuantity}
                            onChange={form.handleItemQuantity}
                          />
                        </EuiFormRow>
                      </Col>
                    </Row>
                    

                    <EuiFormRow label='Amount to Collect'>
                      <EuiFieldNumber
                        name='amountToCollect'
                        placeholder='Enter Amount to Collect'
                        value={form.amountToCollect}
                        onChange={form.handleAmountToCollect}
                      />
                    </EuiFormRow>

                    <EuiFormRow label='Item Description & Price'>
                      <EuiTextArea
                        name='itemDesc'
                        placeholder='Enter item names & correcponding prices'
                        compressed
                        fullWidth
                        value={itemDescription ? itemDescription : order?.parcelOrder?.itemDesc}
                        defaultValue={order?.parcelOrder?.itemDesc}
                        onChange={(e) => {
                          e.preventDefault();
                          if(/^[\x00-\x7F]+$/i.test(e.target.value.trim())){
                            //valid
                            setItemDescription(e.target.value)
                          }else{
                            if(e.target.value == ''){
                              setItemDescription('')
                            }
                          }
                        }}
                      />
                    </EuiFormRow>

                    <EuiFormRow
                      label='Pickup Location'
                      style={{ marginBottom: 16 }}
                    >
                      <EuiFieldText
                        placeholder="Enter your pickup location"
                        value={form.pickupLocation}
                        onChange={form.handlePickupLocation}
                       
                      />
                    </EuiFormRow>

                  </Col>
                  {/* <Col>
                    <EuiFormRow
                      label={'Hub'}
                    >
                      <EuiSuperSelect
                        name='hubId'
                        placeholder='Select Hub'
                        options={getDropdownFormatList(hubList)}
                        valueOfSelected={form.hubId}
                        onChange={form.handleHubId}
                      />
                    </EuiFormRow>
                  </Col> */}
                </Row>
              </Col>
            </Row>
            <Row className='py-0'>
              <Col sm='12'>
                <div className='p-3 bg-light d-flex flex-column'>
                  <p>
                    Sending illegal products is completely prohibited. To learn
                    more read our{' '}
                    <Link
                      to='#'
                      className='text-center'
                      onClick={(e) => {
                        e.preventDefault();
                        showTermsModal(true);
                      }}
                    >
                      terms and service
                    </Link>
                  </p>
                  <Form.Check
                    type='checkbox'
                    id='terms-policy'
                    label='I accept'
                    required
                    value={form.acceptTerms}
                    onChange={form.handleAcceptTerms}
                  />
                </div>
                <div className='p-3 d-flex justify-content-start w-100'>
                  <EuiButton
                    disabled={!form.selectedStore || !canEdit}
                    type='submit'
                    fill
                    style={{ marginRight: 16 }}
                    isLoading={loading}
                    onClick={(e) => {
                      if (!user?.bankInfo && !user?.mfsInfo) {
                        e.preventDefault();
                        showPaymentMethodModal(true);
                      }
                    }}
                  >
                    {order?.orderId || orderId ? 'Update' : 'Create'}
                  </EuiButton>
                  <EuiButton
                    className='outlined'
                    onClick={() => navigate('/deliveries')}
                  >
                    Cancel
                  </EuiButton>
                </div>
              </Col>
            </Row>
          </EuiForm>
        </Form>
      </EuiPanel>
    </div>
  );
};

export default CreateParcelForm;
