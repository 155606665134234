import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useAlert, useLoading } from '../contexts';
import { useApi } from './use-api';

export function useInvoice() {
  const [invoices, setInvoices] = useState([]);
  const { showAlert } = useAlert();
  const { getInvoices } = useApi();
  const { setLoading } = useLoading();

  async function getInvoiceList(page = 0, limit = 100, startFrom, endAt) {
    try {
      setLoading(true);
      const res = await getInvoices(
        page,
        limit,
        startFrom ? moment(startFrom).format('yyyy-MM-DD') : '',
        endAt ? moment(endAt).format('yyyy-MM-DD') : ''
      );
      if (!res || res.statusCode !== 200) {
        showAlert(res?.message || 'Something went wrong!', 'error');
        return;
      }
      setInvoices(res?.invoices || []);

      setLoading(false);

      return;
    } catch (e) {
      console.error(e);
      setLoading(false);
      setInvoices([]);
      showAlert('Fetching invoices failed!', 'error');
    }
  }

  return {
    invoices,
    getInvoiceList,
  };
}
