import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts';

export function useApi() {
  const { user, token } = useAuth();
  const navigate = useNavigate();
  const auth_url = process.env.REACT_APP_AUTH_API;
  const user_url = process.env.REACT_APP_USER_API;
  const order_url = process.env.REACT_APP_ORDER_API;
  const writer_url = process.env.REACT_APP_CATALOG_WRITER_API;
  const reader_url = process.env.REACT_APP_CATALOG_READER_API;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const functions = {};

  functions.registerUser = async function (data) {
    try {
      const response = await fetch(user_url + '/business/registration', {
        method: 'POST',
        headers,
        body: JSON.stringify(data),
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  functions.loginUser = async function (mobileNumber, password) {
    try {
      const response = await fetch(auth_url + '/business/login', {
        method: 'POST',
        headers,
        body: JSON.stringify({ mobileNumber, password }),
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.log('error', err);
      return null;
    }
  };

  functions.forgetPasswordEmailSend = async function (email) {
    try {
      const response = await fetch(
        auth_url + '/business/forgot-password/by-email',
        {
          method: 'POST',
          headers,
          body: JSON.stringify({ email }),
        }
      );
      const result = await response.json();
      return result;
    } catch (err) {
      console.log('error', err);
      return null;
    }
  };

  functions.forgetPasswordVerify = async function (email, newPassword, otp) {
    try {
      const response = await fetch(
        auth_url + '/business/forgot-password/by-email/verify',
        {
          method: 'POST',
          headers,
          body: JSON.stringify({ email, newPassword, otp }),
        }
      );
      const result = await response.json();
      return result;
    } catch (err) {
      console.log('error', err);
      return null;
    }
  };

  /**
   * @param {string} token
   * @returns {Promise<{} | null>}
   */
  functions.getUserProfile = async function (token) {
    return user;
    // try {
    //   const path = auth_url + '/users/profile';
    //   const response = await fetch(path, {
    //     headers: {
    //       ...headers,
    //       : 'bearer ' + token,
    //     },
    //     method: 'GET',
    //   });
    //   if (!response.ok) return null;
    //   const result = await response.json();
    //   return result;
    // } catch (err) {
    //   console.log(err);
    //   return null;
    // }
  };

  /**
   * @returns {Promise<{} | null>}
   */
  functions.getMerchantData = async function (token) {
    try {
      const path = user_url + `/business/details-by-merchant`;
      const response = await fetch(path, {
        headers: {
          'Content-Type': 'application/json',
          authorization: 'Bearer ' + token,
        },
        method: 'GET',
      });
      if (!response.ok) return null;
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  functions.logoutUser = async function () {
    // try {
    // const path = auth_url + '/auth/logout';
    // const response = await fetch(path, {
    //   headers,
    //   method: 'POST',
    //   credentials: 'include',
    //   body: '',
    // });
    // const result = await response.json();
    // return result;
    return {
      message: 'Logout successful!',
      statusCode: 200,
    };
    // } catch (err) {
    //   console.log(err);
    //   return null;
    // }
  };

  functions.registerUserFinal = async function (data) {
    try {
      const result = await axios.post(user_url + '/auth/register/final', data);
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  /**
   * @param {string} traceId
   * @returns {Promise<{} | null>}
   */
  functions.getPacelInfo = async function (traceId) {
    try {
      const path = order_url + `/courier/order?traceId=${traceId}`;
      const response = await fetch(path, {
        headers: {
          ...headers,
        },
        method: 'GET',
      });
      if (!response.ok) return null;
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  functions.loadHubList = async function () {
    try {
      const path = reader_url + `/pi-hubs`;
      const response = await fetch(path, {
        headers: {
          ...headers,
        },
        method: 'GET',
      });
      if (!response.ok) return null;
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  functions.createParcel = async function (data) {
    try {
      const response = await fetch(order_url + '/order/parcel', {
        method: 'POST',
        headers,
        body: JSON.stringify(data),
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  functions.updateParcel = async function (data) {
    try {
      const response = await fetch(order_url + '/order/parcel', {
        method: 'PUT',
        headers,
        body: JSON.stringify(data),
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  functions.cancelledOrderStatus = async function (orderId) {
    const request = {
      orderIds: [orderId],
      status: 'PICKUP_CANCELLED',
    };
    try {
      const response = await fetch(order_url + '/admin/order/status-update', {
        method: 'PUT',
        headers,
        body: JSON.stringify(request),
      });
      const result = await response.json();
      return result;
    } catch (err) {
      return null;
    }
  };

  functions.createParcelBulk = async function (data) {
    try {
      const response = await fetch(order_url + '/order/parcel/bulk', {
        method: 'POST',
        headers,
        body: JSON.stringify(data),
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  functions.getOrders = async function (
    page,
    limit,
    status,
    paymentStatus,
    orderId,
    merchantOrderId,
    storeName,
    receiver,
    recipientName,
    startFrom,
    endAt
  ) {
    try {
      const path =
        order_url +
        `/order/orders-by-merchant?page=${page || 0}&limit=${limit || 100}` +
        (status ? `&status=${status}` : ``) +
        (paymentStatus ? `&paymentStatus=${paymentStatus}` : ``) +
        (orderId ? `&orderId=${orderId}` : ``) +
        (merchantOrderId ? `&merchantOrderId=${merchantOrderId}` : ``) +
        (storeName ? `&storeName=${storeName}` : ``) +
        (receiver ? `&receiver=${receiver}` : ``) +
        (recipientName ? `&recipientName=${recipientName}` : ``) +
        (startFrom ? `&startFrom=${startFrom}` : ``) +
        (endAt ? `&endAt=${endAt}` : ``);

      const response = await fetch(path, {
        headers: {
          ...headers,
        },
        method: 'GET',
      });
      if (!response.ok) return null;
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  functions.getOrdersByInvoice = async function (page, limit, invoiceId) {
    try {
      const path =
        order_url +
        `/order/order-by-invoice?page=${page || 0}&limit=${limit || 100}` +
        (invoiceId ? `&invoiceId=${invoiceId}` : ``);

      const response = await fetch(path, {
        headers: {
          ...headers,
        },
        method: 'PUT',
      });
      if (!response.ok) return null;
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  functions.getInvoices = async function (page, limit, startFrom, endAt) {
    try {
      const path =
        order_url +
        `/invoice?page=${page || 0}&limit=${limit || 100}` +
        (startFrom ? `&startFrom=${startFrom}` : ``) +
        (endAt ? `&endAt=${endAt}` : ``);

      const response = await fetch(path, {
        headers: {
          ...headers,
        },
        method: 'GET',
      });
      if (!response.ok) return null;
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  functions.getOrder = async function (orderId) {
    try {
      const path = order_url + `/order/details-by-merchant?orderId=${orderId}`;
      const response = await fetch(path, {
        headers: {
          ...headers,
        },
        method: 'GET',
      });
      if (!response.ok) return null;
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  functions.getCityList = async function (storeId,deliveryType) {
    if(storeId){
      try {
        const path = reader_url + `/common-city/cityListForOrder?storeId=${storeId}&deliveryType=${deliveryType}`;
        const response = await fetch(path, {
          headers: {
            ...headers,
          },
          method: 'GET',
        });
        if (!response.ok) return null;
        const result = await response.json();
        return result;
      } catch (err) {
        console.log(err);
        return null;
      }
    }
  };

  functions.getZoneList = async function (cityId,deliveryType) {
    if(cityId){
      try {
        const path = reader_url + `/common-zone?commonCityId=${cityId}&deliveryType=${deliveryType}`;
        const response = await fetch(path, {
          headers: {
            ...headers,
          },
          method: 'GET',
        });
        if (!response.ok) return null;
        const result = await response.json();
        return result;
      } catch (err) {
        console.log(err);
        return null;
      }
    }
  };

  functions.getAreaList = async function (zoneId,deliveryType) {
    if(zoneId){
      try {
        const path = reader_url + `/common-area?commonZoneId=${zoneId}&deliveryType=${deliveryType}`;
        const response = await fetch(path, {
          headers: {
            ...headers,
          },
          method: 'GET',
        });
        if (!response.ok) return null;
        const result = await response.json();
        return result;
      } catch (err) {
        console.log(err);
        return null;
      }
    }
  };

  functions.createStore = async function (data) {
    try {
      const response = await fetch(user_url + '/store', {
        method: 'POST',
        headers,
        body: JSON.stringify(data),
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  functions.updateStore = async function (data) {
    try {
      const response = await fetch(user_url + '/store', {
        method: 'PUT',
        headers,
        body: JSON.stringify(data),
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  functions.getStores = async function (data) {
    try {
      const response = await fetch(user_url + '/store/all', {
        method: 'GET',
        headers,
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  functions.getStoreById = async function (storeId) {
    try {
      const response = await fetch(user_url + '/store/' + storeId, {
        method: 'GET',
        headers,
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  functions.deleteStore = async function (storeId) {
    try {
      const response = await fetch(user_url + '/store/' + storeId, {
        method: 'DELETE',
        headers,
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  functions.getDashboardData = async function (from, to) {
    try {
      const response = await fetch(
        order_url + '/dashboard?from=' + from + '&to=' + to,
        {
          method: 'GET',
          headers,
        }
      );
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  functions.updatePayment = async function (name, data) {
    try {
      const response = await fetch(user_url + `/business/${name}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify(data),
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  functions.getTrackingDetails = async function (traceId, token) {
    try {
      const token = JSON.parse(localStorage.getItem('user-data'))?.token;
      const response = await fetch(
        order_url + `/order/order-tracking?orderId=${traceId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            authorization: 'Bearer ' + token,
          },
        }
      );
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  return functions;
}
