import React, { useEffect, useRef, useState } from 'react';
import { Form, Row, Col, Card, Container } from 'react-bootstrap';
import FormGroup from '../form-group';
import { useCreateParcel, useCreateStore } from '../../hooks';
import { Link, useNavigate } from 'react-router-dom';
import {
  EuiButton,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiPanel,
  EuiTitle,
  EuiButtonGroup,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiSuperSelect,
  EuiHealth,
  EuiTextArea,
  EuiFormControlLayout,
  EuiFormLabel,
  EuiToolTip,
  EuiIcon,
  EuiFieldNumber,
  EuiText,
  EuiFormControlLayoutDelimited,
  EuiFlexGroup,
} from '@elastic/eui';
import { productTypes, deliveryTypes, productWeights, locations } from 'assets';
import { Cascader } from 'antd';
import { useAuth, USER_DATA_KEY } from 'contexts';
import { usePaymentMethodModal } from 'contexts/payment-method.context';

const CreateStoreForm = () => {
  const [options, setOptions] = useState([]);
  const { user } = useAuth();
  const formRef = useRef();
  const hook = useCreateStore(formRef);
  const navigate = useNavigate();
  const { showPaymentMethodModal } = usePaymentMethodModal();

  useEffect(() => {
    setOptions(
      locations.map((location, i) => {
        let uniqueZones = new Set(location?.zones);
        uniqueZones = [...uniqueZones];

        return {
          value: location?.district,
          label: location?.district,
          children: uniqueZones?.map((zone) => {
            let uniqueAreas = new Set(zone?.areas);
            uniqueAreas = [...uniqueAreas];
            return {
              value: zone?.name,
              label: zone?.name,
              code: zone.postCode,
              children: uniqueAreas?.map((area) => ({
                value: area,
                label: area,
              })),
            };
          }),
        };
      })
    );
  }, []);

  const handleAreaClick = (e, label, option) => {
    e.stopPropagation();
  };

  useEffect(() => {
    function getCity(coordinates) {
      var xhr = new XMLHttpRequest();
      var lat = coordinates[0];
      var lng = coordinates[1];
      xhr.open(
        'GET',
        'https://api.bigdatacloud.net/data/reverse-geocode-client?localityLanguage=en' +
          '&latitude=' +
          lat +
          '&longitude=' +
          lng +
          '&format=json',
        true
      );
      xhr.send();
      xhr.onreadystatechange = processRequest;
      xhr.addEventListener('readystatechange', processRequest, false);

      function processRequest(e) {
        if (xhr.readyState == 4 && xhr.status == 200) {
          var response = JSON.parse(xhr.responseText);
          // var city = response.address.city;
          if (response) {
            if (response.city) hook.handleCity(response.city);
            if (response.zone) hook.handleZone(response.zone);
            if (response.area) hook.handleArea(response.area);
          }
          return;
        }
      }
    }

    function showPosition(position) {
      let lat = position.coords.latitude;
      let lng = position.coords.longitude;
      getCity([lat?.toString(), lng?.toString()]);
    }

    function showError(error) {
      console.log(error);
    }

    function getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition, showError);
      } else {
        console.log('Geolocation is not supported by this browser.');
      }
    }

    getLocation();
  }, []);

  const filter = (inputValue, path) =>
    path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );

  const displayRender = (labels, selectedOptions) =>
    labels.map((label, i) => {
      const option = selectedOptions[i];
      if (i === labels.length - 1) {
        return (
          <span key={option?.value}>
            {label}
            {option?.code && (
              <>
                (
                <a onClick={(e) => handleAreaClick(e, label, option)}>
                  {option?.code}
                </a>
                )
              </>
            )}
          </span>
        );
      }
      return <span key={option?.value}>{label} / </span>;
    });

  return (
    <div className='h-100'>
      <EuiPanel
        // hasBorder
        hasShadow={false}
        className='bg-white full-form-row h-100'
      >
        <Form
          action='submit'
          ref={formRef}
          onSubmit={hook.handleSubmit}
          autoComplete='off'
        >
          <EuiForm component='div'>
            <Row>
              <Col>
                <EuiPageHeader bottomBorder>
                  <EuiPageHeaderSection>
                    <EuiTitle size='s'>
                      <h3>Create a Store</h3>
                    </EuiTitle>
                  </EuiPageHeaderSection>
                </EuiPageHeader>
              </Col>
            </Row>
            <Row className='py-4 g-3'>
              <Col sm='12' lg='6'>
                <Row className='g-4'>
                  <Col sm='12' lg='12'>
                    <EuiFormRow label='Store Name'>
                      <EuiFieldText
                        type='text'
                        label='Store Name'
                        name='name'
                        placeholder='Type store name'
                        required
                        // value={hook.customerName}
                        // onChange={hook.handleCustomerName}
                      />
                    </EuiFormRow>
                    <EuiFormRow label='Contact Number'>
                      <EuiFieldText
                        type='text'
                        label='Customer Number'
                        name='contactNumber'
                        placeholder='Type contact number'
                        required
                        // value={hook.invoice}
                        // onChange={hook.handleInvoice}
                      />
                    </EuiFormRow>

                    <EuiFormRow label='Store Address'>
                      <EuiTextArea
                        name='address'
                        placeholder='Type Store address'
                        aria-label='Full Address'
                        compressed
                        fullWidth
                        required
                        // onChange={(e) => onChange(e)}
                      />
                    </EuiFormRow>
                  </Col>
                </Row>
              </Col>
              <Col sm='12' lg='6'>
                <Row className='g-4'>
                  <Col sm='12' lg='12'>
                    <EuiFormRow label='Store Contact Name'>
                      <EuiFieldText
                        type='text'
                        label='Store Contact Name'
                        name='contactName'
                        placeholder='Type store contact name'
                        required
                        // value={hook.customerName}
                        // onChange={hook.handleCustomerName}
                      />
                    </EuiFormRow>
                    <EuiFormRow label='Secondary Contact Number (optional)'>
                      <EuiFieldText
                        type='text'
                        label='Secondary Contact Number'
                        name='secondaryContactNumber'
                        placeholder='Type secondary contact number'
                        // value={hook.invoice}
                        // onChange={hook.handleInvoice}
                      />
                    </EuiFormRow>
                    <EuiFormRow
                      label='Location'
                      autoComplete='off'
                      autoCorrect='off'
                      autoCapitalize='off'
                    >
                    
                      <Cascader
                        options={options}
                        size='large'
                        value={
                          hook.city || hook.zone || hook.area
                            ? [hook.city, hook.zone, hook.area]
                            : undefined
                        }
                        displayRender={displayRender}
                        style={{ width: '100%', borderRadius: 4 }}
                        // bordered={false}
                        placeholder='Select location'
                        showSearch={{ filter }}
                        onChange={hook.handleLocation}
                        onSearch={(value) => console.log('search', value)}
                        autoComplete='off'
                        autoCorrect='off'
                        autoCapitalize='off'
                      />
                    </EuiFormRow>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <div className='p-3 d-flex justify-content-start w-100'>
                  <EuiButton
                    type='submit'
                    fill
                    style={{ marginRight: 16 }}
                    onClick={(e) => {
                      if (!JSON.parse(localStorage.getItem(USER_DATA_KEY))?.bankInfo && !JSON.parse(localStorage.getItem(USER_DATA_KEY))?.mfsInfo) {
                        e.preventDefault();
                        showPaymentMethodModal(true);
                      }
                    }}
                  >
                    Create
                  </EuiButton>
                  <EuiButton
                    className='outlined'
                    onClick={() => navigate('/stores')}
                  >
                    Cancel
                  </EuiButton>
                </div>
              </Col>
            </Row>
          </EuiForm>
        </Form>
      </EuiPanel>
    </div>
  );
};

export default CreateStoreForm;
