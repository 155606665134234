import React, { useEffect, useRef, useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Navbar as BNavbar, Container, Button, Modal, Table } from 'react-bootstrap';
import { useAuth, useNavLinks } from '../../contexts';
import NavMenu from './nav-menu';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  brandLogoFull as logo,
  brandLogoVertical as slogo,
} from '../../assets/index';
import {
  EuiHeader,
  EuiHeaderLogo,
  EuiHeaderLinks,
  EuiHeaderLink,
  EuiHeaderSectionItemButton,
  EuiBadge,
  EuiIcon,
  EuiAvatar,
  useEuiTheme,
  EuiFormRow,
  EuiButtonIcon,
} from '@elastic/eui';
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeaderBreadcrumbs,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiKeyPadMenu,
  EuiKeyPadMenuItem,
  EuiLink,
  EuiPopover,
  EuiPopoverFooter,
  EuiPopoverTitle,
  EuiSelectable,
  EuiSelectableMessage,
  EuiSelectableTemplateSitewide,
  EuiSpacer,
  EuiText,
  useGeneratedHtmlId,
  EuiPanel,
} from '@elastic/eui';
import { theme } from 'antd';
import { usePaymentMethodModal } from 'contexts/payment-method.context';
import { useApi } from 'hooks';

const Navbar = () => {
  const [showMenu, setShowMenu] = React.useState(false);
  const [animation, setAnimation] = React.useState('');
  const [isAvatarPopoverOpen, setIsAvatarPopoverOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [hubList, setHubList] = useState([]);
  const { loadHubList } = useApi();
  const handleClose = () => setShow(false);
  const handleShow = async() => {
    const response = await loadHubList()
    setHubList(response?.piHubs)
    setShow(true)
  };

  const { links } = useNavLinks();
  const loc = useLocation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { showPaymentMethodModal } = usePaymentMethodModal();

  useEffect(() => {
    window?.addEventListener('scroll', () => {
      setIsAvatarPopoverOpen(false);
    });
    return () => window.removeEventListener('scroll', () => {});
  }, []);

  function toggleMenu(e, value) {
    if (value !== undefined) {
      setShowMenu(value);
    } else {
      setShowMenu((prev) => !prev);
    }
  }

  const breadcrumbs = [
    {
      text: 'Home',
      href: '/',
    },
    ...(loc?.pathname === '/'
      ? []
      : loc?.pathname
          ?.split('/')
          ?.slice(0, -1)
          .map((path) => {
            return path
              ? {
                  text: path,
                  href: loc?.pathname?.slice(
                    0,
                    loc?.pathname?.indexOf(path) + path.length
                  ),
                }
              : undefined;
          })
    )?.filter((item) => item),
    {
      text:
        loc?.pathname === '/'
          ? 'Dashboard'
          : loc?.pathname?.split('/')?.slice(-1),
    },
  ];

  React.useEffect(() => {
    setAnimation('on-mount 0.6s ease-out forwards');
  }, []);

  const { euiTheme } = useEuiTheme();

  return (
    <>
      {/* <BNavbar
       bg='white'
       fixed='top'
       expand='lg'
       style={{ minHeight: 60, maxWidth: '100vw', animation: animation }}
       className='shadow-sm'
     > */}
      <EuiHeader
        position='fixed'
        style={{ height: 68 }}
        sections={[
          {
            items: [
              <EuiHeaderLogo
                href='/dashboard'
                onClick={(e) => e.preventDefault()}
                aria-label='Go to home page'
                className='me-0 me-md-4'
              >
                <BNavbar.Brand className='p-0 d-none d-md-block'>
                  <Link
                    to='/dashboard'
                    className='h5 m-0 text-bolder p-0 text-primary'
                  >
                    <img src={logo} height='35px' alt='Piickme Express' />
                  </Link>
                </BNavbar.Brand>
                <BNavbar.Brand className='p-0 d-block d-md-none'>
                  <Link
                    to='/dashboard'
                    className='h5 m-0 text-bolder p-0 text-primary'
                  >
                    <img src={slogo} height='40px' alt='Piickme Express' />
                  </Link>
                </BNavbar.Brand>
              </EuiHeaderLogo>,
              <EuiHeaderLinks aria-label='App navigation dark theme example'>
                <EuiHeaderLink
                  isActive={loc.pathname === '/dashboard'}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/dashboard');
                  }}
                >
                  Dashboard
                </EuiHeaderLink>
                <EuiHeaderLink
                  disabled={user?.status !=='ACTIVE'}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/deliveries');
                  }}
                  isActive={loc.pathname?.split('/')?.includes('deliveries')}
                >
                  Deliveries
                </EuiHeaderLink>
                <EuiHeaderLink
                  disabled={user?.status !=='ACTIVE'}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/invoices');
                  }}
                  isActive={loc.pathname?.split('/')?.includes('invoices')}
                >
                  Invoices
                </EuiHeaderLink>
                <EuiHeaderLink
                  disabled={user?.status !=='ACTIVE'}
                  iconType='tokenAnnotation'
                  shape='circle'
                  color='#ed1c25'
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/stores');
                  }}
                  isActive={loc.pathname?.split('/')?.includes('stores')}
                >
                  Stores
                </EuiHeaderLink>
              </EuiHeaderLinks>,
            ],
            borders: 'right',
          },
          {
            items: [
              <EuiLink
                color={euiTheme.colors.darkestShade}
                iconType='arrowDown'
                iconSide='right'
                onClick={(e) => {
                  e.preventDefault();
                  if(user?.status =='ACTIVE'){
                    navigate('/deliveries/create');
                  }
                }}
                isActive={loc.pathname === '/deliveries/create'}
              >
                <Button
                  disabled={user?.status !=='ACTIVE'}
                  variant='outline-primary'
                  className={`text-bold mx-3 me-2 me-md-3 rounded-1 d-block`}
                  style={{ borderColor: '#fc9c9a' }}
                >
                  New Delivery
                </Button>
              </EuiLink>,
                 <EuiLink
                 color={euiTheme.colors.darkestShade}
                 iconType='arrowDown'
                 iconSide='right'
                 onClick={handleShow}
                 
               >
                 <Button
                 
                   variant='outline-primary'
                   className={`text-bold mx-3 me-2 me-md-3 rounded-1 d-block`}
                   style={{ borderColor: '#fc9c9a' }}
                 >
                   Hub List
                 </Button>
               </EuiLink>,
              // <EuiHeaderSectionItemButton
              //   aria-label='2 Notifications'
              //   notification={'2'}
              // >
              //   <EuiIcon type='cheer' size='m' />
              // </EuiHeaderSectionItemButton>,
              <EuiPopover
                initialFocus='#name'
                button={
                  <EuiHeaderSectionItemButton
                    aria-label={user?.name || 'Account menu'}
                    onClick={() => setIsAvatarPopoverOpen(!isAvatarPopoverOpen)}
                  >
                    <EuiAvatar name={user?.name || 'A'} size='m' />
                  </EuiHeaderSectionItemButton>
                }
                isOpen={isAvatarPopoverOpen}
                closePopover={() => setIsAvatarPopoverOpen(false)}
                repositionOnScroll={false}
                // onBlur={() => setIsAvatarPopoverOpen(false)}
              >
                <EuiPanel size='s' fill color='accent'>
                  <EuiText textAlign='center'>
                    <h3 className='fs-5'>{user?.ownersName}</h3>
                  </EuiText>
                </EuiPanel>
                <EuiSpacer size='s' />
                <div className='px-2 py-1'>
                  <p className='text-left fs-xs me-2' style={{ fontSize: 10 }}>
                    Email:{' '}
                  </p>
                  <EuiText
                    textAlign='center'
                    color='black'
                    size='s'
                    className=''
                  >
                    {user?.emailAddress}
                  </EuiText>
                </div>

                {/* <div className='px-2 py-1'>
                  <p className='text-left fs-xs me-2' style={{ fontSize: 10 }}>
                    Merchant ID:{' '}
                  </p>
                  <EuiText
                    textAlign='center'
                    color='black'
                    size='s'
                    className=''
                  >
                    {user?.businessId}
                  </EuiText>
                </div> */}

                <div className='px-2 py-1'>
                  <p className='text-left fs-xs me-2' style={{ fontSize: 10 }}>
                    Mobile
                  </p>
                  <EuiText
                    textAlign='center'
                    color='black'
                    size='s'
                    className=''
                  >
                    {user?.mobileNumber}
                  </EuiText>
                </div>

                <div className='flex justify-content-between p-1 bg-gray mt-4 mb-2'>
                  <h4 className='text-left fs-xs me-2'>Payment</h4>
                  <EuiButtonIcon
                    display='base'
                    iconType='documentEdit'
                    aria-label='Delete'
                    color='warning'
                    onClick={() => showPaymentMethodModal(true)}
                  />
                </div>
                <div className='px-2 py-1'>
                  <p className='text-left fs-xs me-2' style={{ fontSize: 10 }}>
                    MFS
                  </p>
                  <EuiText textAlign='left' color='black' size='s' className=''>
                    {user?.mfsInfo?.mfsName}-{user?.mfsInfo?.mobileNumber}
                  </EuiText>
                </div>
                <div className='px-2 py-1'>
                  <p className='text-left fs-xs me-2' style={{ fontSize: 10 }}>
                    Bank
                  </p>
                  {(user?.bankInfo && (
                    <>
                      <EuiText
                        textAlign='left'
                        color='black'
                        size='s'
                        className=''
                      >
                        Name: {user?.bankInfo?.bankName}-
                        {user?.bankInfo?.branchName}
                      </EuiText>
                      <EuiText
                        textAlign='left'
                        color='black'
                        size='s'
                        className=''
                      >
                        Acc: {user?.bankInfo?.accountName} (
                        {user?.bankInfo?.accountNumber})
                      </EuiText>
                    </>
                  )) || (
                    <EuiText
                      textAlign='center'
                      color='black'
                      size='s'
                      className=''
                    >
                      -
                    </EuiText>
                  )}
                </div>
              </EuiPopover>,

              <EuiHeaderSectionItem>
                <HeaderAppMenu size='m' />
              </EuiHeaderSectionItem>,
            ],
            borders: 'none',
          },
        ]}
        className='shadow-xs'
      />
      <EuiHeader
        position='static'
        style={{
          top: 68,
          boxShadow: 'none',
          border: 'none',
          background: 'transparent',
          zIndex: 1,
        }}
        sections={[
          {
            items: [
              <EuiHeaderSectionItemButton aria-label='Account menu'>
                <EuiAvatar
                  color={euiTheme.colors.darkestShade}
                  type='space'
                  name='Default Space'
                  size='s'
                />
              </EuiHeaderSectionItemButton>,
            ],
            breadcrumbs: breadcrumbs,
            borders: 'right',
          },
          // {
          //   items: [
          //     <EuiHeaderSectionItemButton
          //       aria-label='News feed: Updates available'
          //       notification={true}
          //     >
          //       <EuiIcon type='cheer' size='m' />
          //     </EuiHeaderSectionItemButton>,
          //   ],
          //   borders: 'none',
          // },
        ]}
      />
      {/* <Button
          variant='white'
          className={`shadow-0 p-0 icon-btn justify-self-end d-block d-lg-none`}
          onClick={toggleMenu}
        >
          {showMenu ? <FaTimes /> : <FaBars />}
        </Button>
        

        <div className='d-flex align-items-center mr-4'>
          <NavMenu
            links={links}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
          />
          
        </div> */}
      {/* </Container> */}
      {/* </BNavbar> */}
      <Modal show={show} 
        onHide={handleClose}
        size="lg"
        style={{height:"500px",overflow:"auto"}}
        >
        <Modal.Header closeButton>
          <Modal.Title>Hub List</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <Table responsive="sm">
        <thead>
          <tr>
            <th>Hub Id</th>
            <th>Hub Name</th>
          </tr>
        </thead>
        <tbody>
        {hubList?.map((hub)=>(
          <tr>
            <td>{hub?.id}</td>
            <td>{hub?.name}</td>
          </tr>
        ))}
          
          
        </tbody>
      </Table>
        </Modal.Body>
       
      </Modal>
      
    </>
  );
};

const HeaderAppMenu = () => {
  const headerAppPopoverId = useGeneratedHtmlId({ prefix: 'headerAppPopover' });
  const headerAppKeyPadMenuId = useGeneratedHtmlId({
    prefix: 'headerAppKeyPadMenu',
  });
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const onMenuButtonClick = (e) => {
    setIsOpen(!isOpen);
  };
  const {user} = useAuth();

  const closeMenu = () => {
    setIsOpen(false);
  };

  const button = (
    <EuiHeaderSectionItemButton
      aria-controls={headerAppKeyPadMenuId}
      aria-expanded={isOpen}
      aria-haspopup='true'
      aria-label='Apps menu with 1 new app'
      // notification='1'
      onClick={onMenuButtonClick}
    >
      <EuiIcon type='apps' size='l' />
    </EuiHeaderSectionItemButton>
  );

  useEffect(() => {
    window?.addEventListener('scroll', () => {
      setIsOpen(false);
    });
    return () => window.removeEventListener('scroll', () => {});
  }, []);

  return (
    <EuiPopover
      id={headerAppPopoverId}
      button={button}
      isOpen={isOpen}
      anchorPosition='downRight'
      closePopover={closeMenu}
    >
      <EuiKeyPadMenu id={headerAppKeyPadMenuId} style={{ width: 288 }}>
        <EuiKeyPadMenuItem
          disabled={user?.status !=='ACTIVE'}
          label='Dashboard'
          onClick={(e) => {
            e.preventDefault();
            
            navigate('/dashboard');
          }}
        >
          <EuiIcon type='dashboardApp' size='l' />
        </EuiKeyPadMenuItem>

        {/* <EuiKeyPadMenuItem label='Discover' color='red'>
          <EuiIcon type='discoverApp' size='l' />
        </EuiKeyPadMenuItem> */}

        <EuiKeyPadMenuItem
          label='Stores'
          disabled={user?.status !=='ACTIVE'}
          onClick={(e) => {
            e.preventDefault();
            navigate('/stores');
          }}
        >
          <EuiIcon type='devToolsApp' size='l' />
        </EuiKeyPadMenuItem>

        {/* <EuiKeyPadMenuItem label='Machine Learning'>
          <EuiIcon type='machineLearningApp' size='l' />
        </EuiKeyPadMenuItem> */}

        <EuiKeyPadMenuItem
          label='Pricing Plan'
          onClick={(e) => {
            e.preventDefault();
            navigate('/pricing-plan');
          }}
        >
          <EuiIcon type='graphApp' size='l' />
        </EuiKeyPadMenuItem>

        <EuiKeyPadMenuItem
          label='Deliveries'
          disabled={user?.status !=='ACTIVE'}
          // betaBadgeLabel='Beta'
          onClick={(e) => {
            e.preventDefault();
            navigate('/deliveries');
          }}
        >
          <EuiIcon type='visualizeApp' size='l' />
        </EuiKeyPadMenuItem>

        <EuiKeyPadMenuItem
          label='Logout'
          onClick={(e) => {
            e.preventDefault();
            navigate('/logout');
          }}
        >
          <EuiIcon type='upgradeAssistantApp' size='l' />
        </EuiKeyPadMenuItem>
      </EuiKeyPadMenu>
    </EuiPopover>

    
  );
};

export default Navbar;
