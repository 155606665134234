import React from 'react';
import { Button, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { EuiButton } from '@elastic/eui';

const HeroLeft = () => {
  return (
    <Col
      lg='7'
      className='d-flex flex-column justify-content-center text-center text-lg-start'
    >
      <div className='pe-0 me-0 pe-md-5 me-md-5 mt-5'>
        <h1 className='display-3 text-bolder text-danger mb-5'>
          Fast, Competitive Reliable Courier Service{' '}
        </h1>
        <p className='text-secondary fs-5'>
          We have got you covered 24/7 across Bangladesh. We can pick up
          packages directly from your location or you can choose fulfillment
          services as well.
        </p>
        <Container
          fluid
          className='p-0 my-5 d-flex align-items-center justify-content-center justify-content-md-start'
        >
          <Link to='/login'>
            <EuiButton fill className='me-3 mb-2'>
              Login
            </EuiButton>
          </Link>
          <Link to='/register'>
            <EuiButton className='outlined mb-2'>Register</EuiButton>
          </Link>
        </Container>
      </div>
    </Col>
  );
};

export default HeroLeft;
