import React from 'react';

const TermsModalContext = React.createContext({
  termsModal: false,
  showTermsModal: function (val) {},
});

export function useTermsModal() {
  return React.useContext(TermsModalContext);
}

export function TermsModalProvider({ children }) {
  const [termsModal, setTermsModal] = React.useState(null);

  function showTermsModal(val) {
    setTermsModal(val);
  }

  return (
    <TermsModalContext.Provider value={{ termsModal, showTermsModal }}>
      {children}
    </TermsModalContext.Provider>
  );
}
