import { usePaymentMethodModal } from 'contexts/payment-method.context';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert, useAuth, useLoading, USER_DATA_KEY } from '../contexts';
import { formatMobile } from '../utils';
import { useApi } from './use-api';

export function useCreatePaymentMethod() {
  const [method, setMethod] = React.useState('mfs');

  const { showAlert } = useAlert();
  const { setLoading } = useLoading();
  const { updatePayment } = useApi();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { showPaymentMethodModal,paymentMethodModal } = usePaymentMethodModal();
  const [accountName , setAccountName] = useState('')
  const [accountNumber , setAccountNumber] = useState('')
  const [bankName , setBankName] = useState('')
  const [branchName , setBranchName] = useState('')
  const [routingNumber , setRoutingNumber] = useState('')

  const [mfsName , setMfsName] = useState('')
  const [mfsMobileNumber , setMfsMobileNumber] = useState('')

  const handlers = {};

  useEffect(()=>{
    setMfsName(user?.mfsInfo?.mfsName)
    setMfsMobileNumber(user?.mfsInfo?.mobileNumber)

    setAccountName(user?.bankInfo?.accountName)
    setAccountNumber(user?.bankInfo?.accountNumber)
    setBankName(user?.bankInfo?.bankName)
    setBranchName(user?.bankInfo?.branchName)
    setRoutingNumber(user?.bankInfo?.routingNumber)
    console.log(user?.mfsInfo?.mfsName)
    
  },[paymentMethodModal])

  //bank info
  handlers.setAccountName = (val)=>{
    setAccountName(val)
  }
  handlers.setAccountNumber = (val)=>{
    setAccountNumber(val)
  }
  handlers.setBankName = (val)=>{
    setBankName(val)
  }
  handlers.setRoutingNumber = (val)=>{
    setRoutingNumber(val)
  }
  handlers.setBranchName = (val)=>{
    setBranchName(val)
  }

  handlers.getAccountName = ()=>{
    return accountName;
  }
  handlers.getAccountNumber = ()=>{
    return accountNumber
  }
  handlers.getBankName = ()=>{
    return bankName
  }
  handlers.getRoutingNumber = ()=>{
    return routingNumber
  }
  handlers.getBranchName = ()=>{
    return branchName;
  }
  handlers.emptyBankInfoFromState = ()=>{
    setAccountName('');
    setAccountNumber('');
    setBankName('');
    setBranchName('');
    setRoutingNumber('');
  }
  // mfs info
  handlers.setMfsName = (val)=>{
    setMfsName(val)
  }

  handlers.setMfsMobileNumber = (val)=>{
    setMfsMobileNumber(val)
  }

  handlers.getMfsName = ()=>{
    return mfsName;
  }

  handlers.getMfsMobileNumber = (val)=>{
    return mfsMobileNumber;
  }

  handlers.emptyMFSInfoFromState = ()=>{
    setMfsName('');
    setMfsMobileNumber('');
  }

  handlers.handleMethod = function (val) {
    setMethod(val);
  };

  handlers.resetInputs = () => {
    setMethod('mfs');
  };

  handlers.getPaymentMethodFromStorage = ()=>{
    if(method === 'mfs'){
      // setMfsName(user?.mfsInfo.mfsName)
      // setMfsMobileNumber(user?.mfsInfo.mobileNumber)
      return user?.mfsInfo;
    } 
    if(method === 'bank') return user?.bankInfo;
  }
  //setMfsName(user?.mfsInfo.mfsName)

  // submit the whole form
  handlers.handleSubmit = async function (e) {
    e.preventDefault();
    if (!user?.id) {
      e.preventDefault();
      showAlert('Merchant info not found. Please login again!');
      return;
    }
    if (
      method === 'mfs' &&
      formatMobile(e.target.mobileNumber?.value)?.length !== 14
    ) {
      showAlert("Incorrect recipient's phone number", 'error');
      return;
    }
    if (method === 'mfs' || method === 'bank') {
      setLoading(true);
      try {
        const readyData = {
          mfs: {
            merchantId: user.id,
            mfs: {
              mfsName: e.target.mfsName?.value,
              mobileNumber: e.target.mobileNumber?.value,
            },
          },
          bank: {
            merchantId: user.id,
            bankInfo: {
              accountName: e.target.accountName?.value,
              accountNumber: e.target.accountNumber?.value,
              bankName: e.target.bankName?.value,
              branchName: e.target.branchName?.value,
              routingNumber: e.target.routingNumber?.value,
            },
          },
        };

        const res = await updatePayment(method, readyData[method]);

        setLoading(false);

        if (!res) {
          showAlert('Ops! Something went wrong', 'error');
          return;
        }
        if (res?.statusCode !== 200) {
          showAlert(res.message || 'Something went wrong!', 'error');
          return;
        }
        showPaymentMethodModal(false);
        showAlert(res?.message || 'Store created sucessfully!');

        const getData = localStorage.getItem(USER_DATA_KEY);
        if (getData) {
          const key = `${method}Info`;
          const newData = {
            ...(JSON.parse(getData) || {}),
            [key]: readyData[method]?.[key?.replace('mfsInfo', 'mfs')],
          };
          localStorage.setItem(USER_DATA_KEY, JSON.stringify(newData));
        }

        handlers.resetInputs();
        // navigate('/stores');
      } catch (e) {
        console.error(e);
        setLoading(false);
        return;
      }
    } else {
      showAlert('Please select correct method', 'error');
    }
  };

  return {
    method,
    ...handlers,
  };
}
