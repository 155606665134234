import React from 'react';
import { Footer } from './footer';
import { Header } from './header';

export function Layout({ children }) {
  return (
    <div className='bg-white'>
      <Header forFooter={false} />
      <main style={{ minHeight: 'calc(100vh - 150px)' }}>{children}</main>
      <Footer />
    </div>
  );
}
