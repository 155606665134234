import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import { BrowserRouter } from 'react-router-dom';

import 'semantic-ui-css/semantic.min.css';
import '@elastic/eui/dist/eui_theme_light.css';

import { EuiProvider } from '@elastic/eui';

const overrides = {
  colors: {
    LIGHT: {
      primary: '#ed1c25',
      lightShade: '#d3e6df',
      primaryText: '#5a6770',
      accent: '#ed1c25',
      text: '#5a6770',
      green: '#43a047',
      success: '#43a047',
    },
    DARK: { lightShade: '#394c4b' },
  },
  border: {
    color: '#ebeef0',
  },
  accent: {
    LIGHT: '#ed1c25',
    DARK: '#ed1c25',
  },
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <EuiProvider modify={overrides}>
        <App />
      </EuiProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
