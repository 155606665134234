// @ts-ignore
import data from '../assets/static/data.json';
import { useEffect, useState } from 'react';
import { useAuth, useLoading } from 'contexts';
import { useApi } from './use-api';
import moment from 'moment';

export const useData = () => {
  const [dashboardData, setDashboardData] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const { user } = useAuth();

  useEffect(() => {
    setFrom(moment().subtract(7, 'days'));
    setTo(moment());
  }, []);

  const { setLoading } = useLoading();
  const { getDashboardData } = useApi();

  const functions = {};

  functions.getSiteInfos = function () {
    return {
      contactNumber: data?.siteInfos?.contactNumber,
      email: data?.siteInfos?.email,
      address: data?.siteInfos?.address,
    };
  };

  functions.handleFrom = function (value) {
    setFrom(value);
  };

  functions.handleTo = function (value) {
    setTo(value);
  };

  functions.getChargeCatalog = function (type) {
    const list = {
      sameCity: {
        // charge: 60,
        // time: 'Next Day',
        // next: 15,
        ...(user?.sameCity ?? {}),
      },

      dhakaSuburbs: {
        // charge: 80,
        // time: '24 to 48 Hours',
        // next: 20,
        ...(user?.dhakaSuburbs ?? {}),
      },

      outsideCity: {
        // charge: 100,
        // time: '2 to 3 working days',
        // next: 20,
        ...(user?.outsideCity ?? {}),
      },

      sameDayDelivery: {
        // charge: 120,
        // time: '4 to 6 Hours',
        // next: 30,
        ...(user?.sameDayDelivery ?? {}),
      },
    };

    return list[type] || list['insideCity'];
  };

  functions.getChargeCatalogForBasic = function (type) {
    const list = {
      sameCity: {
        charge: 60,
        time: 'Next Day',
        next: 15,
      },

      dhakaSuburbs: {
        charge: 80,
        time: '24 to 48 Hours',
        next: 20,
      },

      outsideCity: {
        charge: 100,
        time: '2 to 3 working days',
        next: 20,
      },

      sameDayDelivery: {
        charge: 120,
        time: '4 to 6 Hours',
        next: 30,
      },
    };

    return list[type] || list['sameCity'];
  };

  functions.getChargeCatalogAll = function () {
    const list = {
      sameCity: {
        // charge: 60,
        // time: 'Next Day',
        // next: 15,
        ...(user?.sameCity ?? {}),
      },

      dhakaSuburbs: {
        // charge: 80,
        // time: '24 to 48 Hours',
        // next: 20,
        ...(user?.dhakaSuburbs ?? {}),
      },

      outsideCity: {
        // charge: 100,
        // time: '2 to 3 working days',
        // next: 20,
        ...(user?.outsideCity ?? {}),
      },

      sameDayDelivery: {
        // charge: 120,
        // time: '4 to 6 Hours',
        // next: 30,
        ...(user?.sameDayDelivery ?? {}),
      },
    };

    return list;
  };

  functions.getCodCharge = function (zoneType) {
    if(zoneType){
        if(zoneType == 'sameCity'){
          return user?.sameCity?.codCharge || 0;
        }else if(zoneType == 'dhakaSuburbs'){
          return user?.dhakaSuburbs?.codCharge || 0;
        }else if(zoneType == 'outsideCity'){
          return user?.outsideCity?.codCharge || 0;
        }else if(zoneType == 'sameDayDelivery'){
          return user?.sameDayDelivery?.codCharge || 0;
        }
    } else return user?.codCharge || 0;
  };

  functions.getDashboardData = async function () {
    try {
      setLoading(true);
      console.log(from?.toISOString())
      const res = await getDashboardData(
        from?.toISOString(),
        to?.toISOString()
      );
      if (!res || res.statusCode !== 200) {
        console.error(res?.message || 'Dashboard data fetch failed');
        return;
      }
      const { message, statusCode, ...actualData } = res;
      setDashboardData(actualData);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  return { dashboardData, from, to, ...functions };
};
