import { EuiPanel } from '@elastic/eui';
import React from 'react';
import { Container } from 'react-bootstrap';
import ChooseShop from './choose-shop';
import CostCalculation from './cost-calculation';
import OrderDetails from './order-details';
import OrderRules from './order-rules';

const Aside = ({ chargeData }) => {
  return (
    <div className='h-100'>
      <EuiPanel
        hasBorder
        hasShadow={false}
        className='bg-white h-100'
        paddingSize='l'
      >
        <CostCalculation chargeData={chargeData} />
        <OrderRules />
      </EuiPanel>
    </div>
  );
};

export default Aside;
