import data from '../assets/static/data.json';

export const useTypes = () => {
  const functions = {};
  
  /** Get the districts of a division
   * @param {string} division
   * @returns {{label: string, value: string}[]}
   */
  functions.getProductTypes = function () {
    const districts = [];
    for (const district of data['productTypes']) {
      districts.push({
        label: district,
        value: district,
      });
    }
    return districts;
  };
  
  /** Get the districts of a division
   * @param {string} division
   * @returns {{label: string, value: string}[]}
   */
   functions.getProductCategoryTypes = function () {
    const districts = [];
    for (const district of data['productTypes']) {
      districts.push({
        label: district,
        value: district,
      });
    }
    return districts;
  };

  return { ...functions };
};

/*  
Adabar Thana
Badda Thana
Bangsal Thana
Bimanbandar Thana (Dhaka)
Cantonment Thana
Chowkbazar Thana
Darus Salam Thana
Demra Thana
Dhanmondi Thana
Gendaria Thana
Gulshan Thana
Hazaribagh Thana
Jatrabari Thana
Kadamtali Thana
Kafrul Thana
Kalabagan
Kamrangirchar Thana
Khilgaon Thana
Khilkhet Thana
Kotwali Thana (Dhaka)
Lalbagh Thana
Mirpur Model Thana
Mohammadpur Thana
Motijheel Thana
New Market Thana
Pallabi Thana
Paltan
Panthapath
Ramna Thana
Rampura Thana
Sabujbagh Thana
Shah Ali Thana
Shahbag
Sher-e-Bangla Nagar
Shyampur Thana
Sutrapur Thana
Tejgaon Industrial Area Thana
Tejgaon Thana
Turag Thana
Uttar Khan Thana
Vatara Thana
Wari Thana
*/
