import React from 'react';
import { Container } from 'react-bootstrap';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from '../components/protected-route';
import UsersDashboard from '../components/users-dashboard';
import { CreateParcel, Parcels } from '../components';
import { useNavLinks } from '../contexts';
import { Home } from '.';
import { Navbar, Alert } from '../components';
import { Deliveries } from 'components/deliveries';
import PricingPlan from './pricing-plan';
import Stores from './stores';
import { CreateStore } from 'components/create-store';
import DeliveryDetailsPage from './delivery-details';
import Dashboard from './dashboard';
import Footer from 'components/footer';
import DeliveriesBulk from 'components/deliveries-bulk/deliveries';
import { Regions } from 'components/regions';
import { Invoices, OrdersByInvoice } from 'components/invoices';

const Users = () => {
  const { userLinks } = useNavLinks();
  React.useEffect(() => {
    userLinks();
  }, []);

  return (
    <>
      <Navbar />
      <Container
        fluid
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 48,
          minHeight: 'calc(100vh - 108px)',
        }}
      >
        <div className='mb-5'></div>
        <Routes>
          {/* <Route path='/' element={<ProtectedRoute />}> */}
          {/* <Route path='/' element={<UsersDashboard />} /> */}
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/profile' element={<h1>Profile</h1>} />
          <Route path='/parcels' element={<Parcels />} />
          <Route path='/pricing-plan' element={<PricingPlan />} />
          <Route path='/deliveries' element={<Deliveries />} />
          <Route path='/deliveries/create' element={<CreateParcel />} />
          <Route
            path='/deliveries/update/:orderId'
            element={<CreateParcel />}
          />
          <Route path='/deliveries/create/bulk' element={<DeliveriesBulk />} />
          <Route
            path='/deliveries/:orderId'
            element={<DeliveryDetailsPage />}
          />
          <Route path='/stores' element={<Stores />} />
          <Route path='/stores/create' element={<CreateStore />} />
          <Route path='/invoices' element={<Invoices />} />
          <Route path='/invoices/:invoiceId' element={<OrdersByInvoice />} />
          <Route path='/regions' element={<Regions />} />
          <Route path='/payments' element={<h1>Payments</h1>} />
          <Route path='/*' element={<ProtectedRoute />} />
          {/* </Route> */}
        </Routes>
      </Container>
      <Footer />
    </>
  );
};

export default Users;
