import { ImgPartner1, ImgPartner2, ImgPartner3 } from 'assets';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import FeatureCard from './feature-card';

const AboutLastPart = () => {
  return (
    <div className='partners'>
      <div className='flex'>
        <h2 className='text-danger fw-bold mb-5 large-text-heading'>
          Here’s What We Are Proud To Offer To Our Partners
        </h2>
      </div>
      <div className='mt-5'>
        <Row className='justify-content-center mt-5'>
          <Col lg='4' className='text-center'>
            <div className='p-4'>
              <img src={ImgPartner1} alt='' className='mb-4' height={150} />
              <h4 className='fw-bold fs-4 mb-4 text-black'>
                Dedicated KAM & <br />
                Customer Support
              </h4>
              <p>
                Every merchant will get dedicated KAM service and for any kind
                of help our customer service team is there to help you, always.
              </p>
            </div>
          </Col>
          <Col lg='4' className='text-center'>
            <div className='p-4'>
              <img src={ImgPartner2} alt='' className='mb-4' height={150} />
              <h4 className='fw-bold fs-4 mb-4 text-black'>
                Next-Day <br />
                Payment{' '}
              </h4>
              <p>
                Merchants will receive the money's worth of the orders on a
                next-day payment basis service.{' '}
              </p>
            </div>
          </Col>{' '}
          <Col lg='4' className='text-center'>
            <div className='p-4'>
              <img src={ImgPartner3} alt='' className='mb-4' height={150} />
              <h4 className='fw-bold fs-4 mb-4 text-black'>
                Live <br />
                Tracking{' '}
              </h4>
              <p>
                Keep track of your deliveries at any point in time and have
                complete visibility throughout the journey.{' '}
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AboutLastPart;
