import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, useAlert, useLoading } from '../contexts';
import { useApi } from './use-api';

export function useLoginForm() {
  const [mobileNumber, setMobileNumber] = React.useState('');
  const [password, setPassword] = React.useState('');
  const navigate = useNavigate();
  const { login } = useAuth();
  const { showAlert } = useAlert();
  const { loginUser, getUserProfile, getMerchantData } = useApi();
  const { setLoading } = useLoading();

  function handleMobileNumberChange(e) {
    const m = e.target.value?.replace('+88', '01');
    const hasPp8 = m?.startsWith('0101');
    setMobileNumber(m?.slice(hasPp8 ? 2 : 0, hasPp8 ? 13 : 11));
  }

  function handlePasswordChange(e) {
    setPassword(e.target.value);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await loginUser('+88' + mobileNumber, password);
      if (!res || res.statusCode !== 200) {
        showAlert(res?.message || 'Something went wrong!', 'error');
        setLoading(false);
        return;
      }
      const merchantData = await getMerchantData(res?.token);
      const userData = {
        ...(merchantData?.business || {}),
        ...res,
      };
      delete userData.statusCode;
      delete userData.message;

      // if (!userData) {
      //   showAlert('No user profile found please login again', 'error');
      //   setLoading(false);
      // }
      // if (userData.statusCode !== 200) {
      //   showAlert('No user profile found please login again', 'error');
      //   setLoading(false);
      // }
      login(res.token, userData);
      showAlert('Logged in', 'normal');
      setLoading(false);
      setMobileNumber('');
      setPassword('');
      navigate('/dashboard', { replace: true });
    } catch (e) {
      console.error(e);
      showAlert('User login failed!', 'error');
      setLoading(false);
    }
  }

  return {
    mobileNumber,
    password,
    handleMobileNumberChange,
    handlePasswordChange,
    handleSubmit,
  };
}
