import React from 'react';
import { Container, Row } from 'react-bootstrap';
import DeliveriesTableBulk from './deliveries-table-bulk';

const DeliveriesBulk = () => {
  return (
    <Container fluid>
      <DeliveriesTableBulk />
    </Container>
  );
};

export default DeliveriesBulk;
