import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, useAlert, useLoading } from '../contexts';
import { useApi } from './use-api';

export function useEmailSendForm() {
  const [email, setEmail] = React.useState('');
  const navigate = useNavigate();
  //const { login } = useAuth();
  const { showAlert } = useAlert();
  const { forgetPasswordEmailSend } = useApi();
  const { setLoading } = useLoading();

  function handleEmailChange(e) {
    setEmail(e.target.value);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await forgetPasswordEmailSend(email);
      if (!res || res.statusCode !== 200) {
        showAlert(res?.message || 'Something went wrong!', 'error');
        setLoading(false);
        return;
      }
      const userData = {
        ...res,
      };
      delete userData.statusCode;
      delete userData.message;

      //login(res.token, userData);
      showAlert('Reset Link send in your Email', 'normal');
      setLoading(false);
      setEmail('');
      //navigate('/dashboard', { replace: true });
    } catch (e) {
      console.error(e);
      showAlert('User login failed!', 'error');
      setLoading(false);
    }
  }

  return {
    email,
    handleEmailChange,
    handleSubmit,
  };
}
