import { useAuth } from 'contexts';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CreateStoreForm from './create-store-form';

const CreateStore = () => {
  const { checkForUser } = useAuth();

  React.useEffect(() => {
    checkForUser();
  }, []);

  return (
    <Container fluid className='px-4'>
      <Row className='g-4 mb-5'>
        <Col sm='12' lg='12'>
          <CreateStoreForm />
        </Col>
      </Row>
    </Container>
  );
};

export default CreateStore;
