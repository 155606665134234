import React, { useEffect } from 'react';
import { useConstants, useLoading } from './index';
import { useApi } from '../hooks';
import { useNavigate, useLocation } from 'react-router-dom';

export const USER_DATA_KEY = 'user-data';

const AuthContext = React.createContext({
  isAuthenticated: false,
  token: '',
  login: function (token, user) {},
  logout: function () {},
  checkForUser: async function () {},
  user: getSavedUser(USER_DATA_KEY)
    ? JSON.parse(getSavedUser(USER_DATA_KEY))
    : null,
});

function saveUser(key, userId) {
  localStorage.setItem(key, userId);
}

function removeSavedUser(key) {
  localStorage.removeItem(key);
}

function getSavedUser(key) {
  return localStorage.getItem(key);
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const { LOGIN_USER_KEY } = useConstants();
  const [token, setToken] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const { getUserProfile } = useApi();
  const location = useLocation();

  useEffect(() => {
    const userData = getSavedUser(USER_DATA_KEY);
    if (userData) {
      setUser(JSON.parse(userData));
    }
    setToken(getSavedUser(LOGIN_USER_KEY));
  }, []);

  function login(token, user) {
    setToken(token);
    saveUser(LOGIN_USER_KEY, token);
    saveUser(USER_DATA_KEY, JSON.stringify(user));
    setUser(user);
    setIsAuthenticated(true);
  }

  function logout() {
    setIsAuthenticated(false);
    setToken(null);
    removeSavedUser(LOGIN_USER_KEY);
    removeSavedUser(USER_DATA_KEY);
  }

  let redirectPath = location.pathname;

  async function checkForUser() {
    setLoading(true);
    try {
      if (isAuthenticated) {
        setLoading(false);

        navigate(
          redirectPath === '/login' ||
            redirectPath === '/register' ||
            redirectPath === '/'
            ? '/dashboard'
            : redirectPath
        );
        return;
      }

      const savedToken = getSavedUser(LOGIN_USER_KEY);
      if (!savedToken) setLoading(false);
      const data = await getUserProfile(savedToken);

      if (!data || !savedToken) {
        removeSavedUser(LOGIN_USER_KEY);
        setLoading(false);
        navigate(
          redirectPath === '/login' ||
            redirectPath === '/register' ||
            redirectPath === '/'
            ? redirectPath
            : '/login'
        );
        return;
      }
      login(savedToken, data);
      setLoading(false);
      navigate(
        redirectPath === '/login' ||
          redirectPath === '/register' ||
          redirectPath === '/'
          ? '/dashboard'
          : redirectPath
      );
    } catch (e) {
      console.error(e);
      removeSavedUser(LOGIN_USER_KEY);
      setLoading(false);
    }
  }

  const context = {
    user,
    token,
    isAuthenticated,
    login,
    logout,
    checkForUser,
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
}
