export const faqData = [
  {
    ques: 'What is Piickme Express?',
    ans: 'Piickme is an A-Z solution for e-commerce retailers and merchants that provides warehousing, storage, pick & pack, and delivery services across Bangladesh.',
  },
  {
    ques: 'How do I know if Piickme Express is right for me?',
    ans: 'If you’re a business owner that needs to deliver and take charge of the fulfillment of your packages to your customers, then Piickme is the right solution for you.',
  },
  {
    ques: 'Does Piickme Express provide payment on delivery (COD)?',
    ans: 'Yes, Piickme Express provides Cash On Delivery service.',
  },
  {
    ques: 'What is your return policy?',
    ans: 'With an evolving e-commerce shopping experience, we’ve made returns super simple for you.  Head to the shipment you’d like to request a return for and click on “Request a Return”.',
  },
  {
    ques: 'How can I contact customer support?',
    ans: 'You can reach out to our customer support team through the chat functionality on your Piickme dashboard. Or you can call directly at 09609-414141',
  },
  {
    ques: 'What are some prohibited items that Piickme Express can’t deliver?',
    ans: 'All items prohibited under law and that your business is not licensed to sell.',
  },
  {
    ques: 'What happens if my customer is not available when the courier arrives?',
    ans: 'Don’t worry, our couriers attempt a shipment up to 3 times if necessary. A package is returned to you if we’ve made sure that your customer does not want the package or if it is unavailable.',
  },
  {
    ques: 'Do you provide packaging for your fulfillment?',
    ans: 'Yes, we provide standard polybag packaging for fulfillment. If you need us to use custom packaging, you can also let us know.',
  },
  {
    ques: 'How quickly can you fulfill orders?',
    ans: 'Any orders placed before 2 pm are delivered on the given duration across Bangladesh.',
  },
  {
    ques: 'How can I track my inventory?',
    ans: 'You’ll be able to keep track of your inventory at all times through the database that we share with you.  It’s a live database, so you’ll always know what you have in stock. Plus our fulfillment team continuously takes stock count, checks expiration dates, and notifies you once quantities are critical.',
  },
];
