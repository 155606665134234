import React, { useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useData} from '../hooks';
import {useEmailSendForm} from '../hooks/forget-password-email-send'
import { useNavLinks, useAuth, useLoading } from '../contexts';
import { Grid, Image } from 'semantic-ui-react';
import { loginback } from '../assets/images';
import { Navbar as BNavbar } from 'react-bootstrap';
import { brandLogoFull as logo } from '../assets/index';
import {
  EuiHeaderLink,
  EuiCheckbox,
  EuiButton,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  useGeneratedHtmlId,
  EuiPanel,
  EuiFieldPassword,
} from '@elastic/eui';
import { EuiPageHeader, EuiPageHeaderSection, EuiTitle } from '@elastic/eui';

const ForgetPassword = () => {
  const [isSwitchChecked, setIsSwitchChecked] = useState(false);
  const [dual, setDual] = useState(true);

  const emailSendForm = useEmailSendForm();
  const { homeLinks } = useNavLinks();
  const [animation, setAnimation] = React.useState('');
  const { checkForUser } = useAuth();
  const { loading } = useLoading();
  const { getSiteInfos } = useData();
  const data = getSiteInfos();

  React.useEffect(() => {
    //checkForUser();
   // homeLinks();
   setAnimation('on-mount 0.4s ease-out forwards');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <div className='login'>
      <Grid celled style={{ margin: 0, minHeight: '100vh' }}>
        <Grid.Row>
          <Grid.Column
            style={{ padding: 0 }}
            mobile={16}
            tablet={6}
            computer={6}
            className='d-none d-md-block'
          >
            <Image
              src={loginback}
              style={{
                height: '100%',
                objectFit: 'cover',
                objectPosition: '-200px center',
              }}
            />
          </Grid.Column>

          <Grid.Column
            style={{ padding: 0 }}
            mobile={16}
            tablet={10}
            computer={10}
          >
            <Container
              fluid
              className='h-100'
              // className=' p-4'
              style={{
                // marginTop: '90px',
                // marginBottom: '90px',
                animation: animation,
              }}
            >
              <div className='d-flex align-items-center justify-content-between py-4'>
                <BNavbar.Brand className='p-0'>
                  <Link to='/' className='h5 m-0 text-bolder p-0 text-primary'>
                    <img src={logo} height='40px' alt='Piickme Express' />
                  </Link>
                </BNavbar.Brand>
                <EuiHeaderLink color='primary' iconType='mobile'>
                  {data?.contactNumber}
                </EuiHeaderLink>
              </div>
              <div className='pb-5 h-100 d-flex align-items-center w-100'>
                <EuiPanel
                  // hasBorder
                  hasShadow
                  className='bg-white p-4 p-md-5 mb-5 shadow-sm mx-auto'
                  style={{ maxWidth: 550, marginTop: -70 }}
                >
                  <Form
                    action='submit'
                    // className='bg-white shadow-sm p-3 rounded-2 mx-auto'
                    // style={{ maxWidth: '550px' }}
                    onSubmit={emailSendForm.handleSubmit}
                    autoComplete='off'
                  >
                    <EuiPageHeader className='mb-5'>
                      <EuiPageHeaderSection>
                        <EuiTitle size='m'>
                          <h3>Forget Password</h3>
                        </EuiTitle>
                      </EuiPageHeaderSection>
                      <EuiPageHeaderSection>As Merchant</EuiPageHeaderSection>
                    </EuiPageHeader>
                    <EuiForm component='div'>
                      <EuiFormRow
                        label='Enter your registration Email'
                        helpText='example@gmail.com'
                      >
                        <EuiFieldText
                          name='email'
                          required
                          icon='email'
                          value={emailSendForm.email}
                          onChange={emailSendForm.handleEmailChange}
                          autoComplete='off'
                          autoCorrect='off'
                          autoCapitalize='off'
                        />
                      </EuiFormRow>

                      

                      <EuiSpacer />

                      

                      <EuiButton
                        isLoading={loading}
                        type='submit'
                        fill
                        className='me-3'
                      >
                        Submit
                      </EuiButton>
                    
                    
                    </EuiForm>
                  </Form>
                </EuiPanel>
              </div>
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default ForgetPassword;
