import {
  deliveryTypes,
  locations,
  productWeights,
  zoneTypes,
  productTypes,
  OrderStatuses,
} from 'assets';
import { useStore } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert, useAuth, useLoading } from '../contexts';
import { formatMobile } from '../utils';
import { useApi } from './use-api';
import { useData } from './use-data';
import { useDeliveryCharge } from './use-delivery-charge';

const productBody = {
  instructionComment: '',
  productName: '',
  productPrice: 0,
  quantity: 0,
};

export function useCreateParcel(formRef) {
  const [invoice, setInvoice] = React.useState('');
  const [collection, setCollection] = React.useState('');
  const [customerName, setCustomerName] = React.useState('');
  const [customerMobile, setCustomerMobile] = React.useState('');
  const [deliveryDivision, setDeliveryDivision] = React.useState('');
  const [deliveryUpazila, setDeliveryUpazila] = React.useState('');
  const [deliveryDistrict, setDeliveryDistrict] = React.useState('');
  const [deliveryArea, setDeliveryArea] = React.useState('');
  const [deliveryStreet, setDeliveryStreet] = React.useState('');
  const [deliveryHouse, setDeliveryHouse] = React.useState('');
  const [itemQuantity, setItemQuantity] = React.useState(1);
  const [itemWeight, setItemWeight] = React.useState(
    productWeights[0].value || 1
  );
  const [itemType, setItemType] = React.useState('');
  const [merchantId, setMerchantId] = React.useState('');
  const [acceptTerms, setAcceptTerms] = React.useState(false);
  const [shopId, setShopId] = React.useState('');
  const [products, setProducts] = React.useState([productBody]);
  const [deliveryType, setDeliveryType] = React.useState('');
  const [city, setCity] = React.useState();
  const [area, setArea] = React.useState();
  const [zone, setZone] = React.useState();
  const [amountToCollect, setAmountToCollect] = React.useState(0);
  const [deliveryCharge, setDeliveryCharge] = React.useState(0);
  const [codFee, setCodFee] = React.useState(0);
  const [totalCharge, setTotalCharge] = React.useState(0);
  const [selectedStore, setSelectedStore] = React.useState();
  const [recipientName, setRecipientName] = React.useState('');
  const [hubId, setHubId] = React.useState('');
  const [recipientPhone, setRecipientPhone] = React.useState('');
  const [merchantOrderId, setMerchantOrderId] = React.useState('');
  const [pickupLocation, setPickupLocation] = React.useState('');
  const [recipientAddressState, setRecipientAddressState] = React.useState('');

  const { showAlert } = useAlert();
  const { setLoading } = useLoading();
  const { createParcel, updateParcel } = useApi();
  const navigate = useNavigate();
  const { token } = useAuth();
  const { getChargeCatalog } = useData();
  const { calculateDeliveryCharge } = useDeliveryCharge();

  const handlers = {};

  useEffect(() => {
    const result = calculateDeliveryCharge(
      deliveryType,
      itemWeight,
      city,
      zone,
      amountToCollect || 0,
      itemQuantity
    );

    setTotalCharge(result.total);
    setDeliveryCharge(result.deliveryCharge);
    setCodFee(result.cod);
    //console.log(result)
  }, [itemQuantity, itemWeight, deliveryType, city, zone, amountToCollect]);

  useEffect(() => {
    setItemType(productTypes?.[0]);
    setItemWeight(productWeights[0]?.value);
    //setDeliveryType(deliveryTypes[0]?.value);
  }, []);

  useEffect(() => {
    console.log(city, zone, area);
  }, [city, zone, area]);

  handlers.handleStore = function (val) {
    setSelectedStore(val);
  };

  handlers.handleInvoice = function (e) {
    setInvoice(e.target.value);
  };

  handlers.handleCollection = function (e) {
    setCollection(e.target.value);
  };

  handlers.handleCustomerName = function (e) {
    setCustomerName(e.target.value);
  };

  handlers.handleCustomerMobile = function (e) {
    setCustomerMobile(e.target.value);
  };

  handlers.handleDeliveryDivision = function (e) {
    setDeliveryDivision(e.target.value);
  };

  handlers.handleDeliveryDistrict = function (e) {
    setDeliveryDistrict(e.target.value);
  };

  handlers.handleDeliveryUpazila = function (e) {
    setDeliveryUpazila(e.target.value);
  };

  handlers.handleDeliveryArea = function (e) {
    setDeliveryArea(e.target.value);
  };

  handlers.handleDeliveryStreet = function (e) {
    setDeliveryStreet(e.target.value);
  };

  handlers.handleDeliveryHouse = function (e) {
    setDeliveryHouse(e.target.value);
  };

  handlers.handleItemWeight = function (val) {
    setItemWeight(val);
  };

  handlers.handleItemQuantity = function (e) {
    setItemQuantity(Math.abs(e.target.value * 1 || 1));
  };

  handlers.handleItemType = function (val) {
    setItemType(val);
  };

  handlers.handleCity = function (val) {
    console.log(val);
    setCity(val);
  };

  handlers.handleZone = function (val) {
    setZone(val);
  };

  handlers.handleArea = function (val) {
    setArea(val);
  };

  handlers.resetDeliveryArea = function () {
    setCity(undefined);
    setZone(undefined);
    setArea(undefined);
  };

  handlers.handleDeliveryType = function (val) {
    // if ((val === 'sameDayDelivery' || val ==='outsideDhaka') && city?.toLowerCase() !== 'dhaka') {
    // setCity('');
    // setZone('');
    // setArea('');
    // }
    setDeliveryType(val);
  };

  handlers.handleAcceptTerms = function (e) {
    setAcceptTerms(e.target.value);
  };

  handlers.handleStore = function (val) {
    setSelectedStore(val);
  };

  handlers.handleAmountToCollect = function (e) {
    setAmountToCollect(parseFloat(e.target.value));
  };

  handlers.updateShopId = function (e) {
    setShopId(e.target.value);
  };

  handlers.handleRecipientAddressState = function (e) {
    const inputValue = e.target.value;
    const allowedRegex = /^[^"']*$/; // Regular expression to allow only alphabetic text and commas

    if (allowedRegex.test(inputValue)) {
      setRecipientAddressState(inputValue);
    }
    //setRecipientAddressState(e.target.value);
  };

  handlers.handlePickupLocation = function (e) {
    const inputValue = e.target.value;
    const allowRegex = /^[^"']*$/;

    if (allowRegex.test(inputValue)) {
      setPickupLocation(e.target.value);
    }
  };

  handlers.handleRecipientName = function (e) {
    e.preventDefault();

    const inputValue = e.target.value;
    const allowRegex = /^[^"']*$/;

    if (allowRegex.test(inputValue)) {
      setRecipientName(inputValue);
    }

    // if (/^[\x00-\x7F]+$/i.test(e.target.value.trim())) {
    //   //valid
    //   setRecipientName(e.target.value);
    // } else {
    //   if (e.target.value == '') {
    //     setRecipientName('');
    //   }
    // }

    //setRecipientName(e.target.value);
  };

  handlers.handleRecipientPhone = function (e) {
    let mobile = e.target.value;
    mobile = mobile.replace(/[^\d]/g, '');
    console.log(mobile);
    setRecipientPhone(mobile);
  };

  handlers.handleMerchantOrderId = function (e) {
    e.preventDefault();
    if (/^[\x00-\x7F]+$/i.test(e.target.value.trim())) {
      //valid
      setMerchantOrderId(e.target.value);
    } else {
      if (e.target.value == '') {
        setMerchantOrderId('');
      }
    }
    //setMerchantOrderId(e.target.value);
  };

  // for products
  handlers.handleAddFields = () => {
    const values = [...products];
    values.push(productBody);
    setProducts(values);
  };

  handlers.handleRemoveFields = (index) => {
    const values = [...products];
    values.splice(index, 1);
    setProducts(values);
  };
  handlers.handleHubId = (hubId) => {
    setHubId(hubId);
  };

  handlers.handleInputChange = (index, event, number) => {
    const values = [...products];
    values[index] = {
      ...values[index],
      [event.target.name]: number
        ? Number(event.target.value) || 0
        : event.target.value,
    };

    setProducts(values);
  };

  handlers.resetInputs = () => {
    setSelectedStore(undefined);
    setItemType('');
    setDeliveryType('');
    setCity('');
    setZone('');
    setArea('');
    setItemWeight(productWeights[0]?.value);
    setDeliveryType(deliveryTypes[0]?.value);
    setItemQuantity(1);
    setCodFee(0);
    setPickupLocation('');
    if (formRef?.current) {
      formRef.current.reset();
    }
  };

  handlers.handleAllForUpdate = async (order) => {
    const po = order?.parcelOrder;
    setItemType(po?.itemType);
    setDeliveryType(po?.deliveryType);
    setCity(po?.recipientCity);
    setZone(po?.recipientZone);
    setArea(po?.recipientArea);
    setItemWeight(po?.itemWeight);
    setItemQuantity(po?.itemQuantity);
    setAmountToCollect(po?.amountToCollect);
    setRecipientName(po?.recipientName);
    setRecipientPhone(po?.recipientPhone);
    setMerchantOrderId(po?.merchantOrderId);
    setPickupLocation(po?.pickupLocation);
    setMerchantId(order?.customer?.id);
    setRecipientAddressState(po?.recipientAddress);
    setHubId(po?.piHubId)
  };

  // submit the whole form
  handlers.handleSubmit = async function (e) {
    e.preventDefault();
    if (acceptTerms) {
      const orderId = e.target.orderId?.value;
      const orderStatus = e.target.orderStatus?.value;
      if (
        orderId &&
        !OrderStatuses?.slice(0, 4).concat('INITIATED').includes(orderStatus)
      ) {
        showAlert('Your can not update order in current status', 'error');
        return;
      }
      if (!city) {
        showAlert(
          'Please specify city and other address fields well.',
          'error'
        );
        return;
      }
      if (formatMobile(e.target.recipientPhone?.value)?.length !== 14) {
        showAlert("Incorrect recipient's phone number", 'error');
        return;
      }
      setLoading(true);
      try {
        const readyData = {
          orderId: orderId,
          storeName: selectedStore?.name,
          storeAddress: selectedStore?.address,
          amountToCollect: amountToCollect || 0,
          deliveryType: e.target.deliveryType?.value,
          itemDesc: e.target.itemDesc?.value,
          itemQuantity: e.target.itemQuantity?.value * 1 || 0,
          itemType: e.target.itemType?.value,
          itemWeight: e.target.itemWeight?.value * 1 || 0,
          merchantOrderId: e.target.merchantOrderId?.value,
          recipientAddress: e.target.recipientAddress?.value,
          recipientCity: city?.value,
          recipientZone: zone?.value,
          recipientArea: area?.value,
          recipientName: e.target.recipientName?.value,
          recipientPhone: e.target.recipientPhone?.value,
          specialInstruction: e.target.specialInstruction?.value,
          deliveryCharge: totalCharge,
          merchantId: merchantId,
          pickupLocation: pickupLocation,
          recipientCityId: city?.id,
          recipientZoneId: zone?.id,
          recipientAreaId: area?.id,
        };
        console.log(readyData)
        
        const res = !orderId
          ? await createParcel(readyData)
          : await updateParcel(readyData);
        setLoading(false);

        if (!res) {
          showAlert('Ops! Something went wrong', 'error');
          return;
        }
        if (res?.statusCode !== 200) {
          showAlert(res.message || 'Something went wrong!', 'error');
          return;
        }
        showAlert(res?.message);
        handlers.resetInputs();
        orderId ? navigate(`/deliveries/${orderId}`) : navigate('/deliveries');
      } catch (e) {
        console.error(e);
        setLoading(false);
        return;
      }
    } else {
      showAlert('Please read & accept our terms & service doc', 'error');
    }
  };

  return {
    selectedStore,
    invoice,
    collection,
    customerName,
    customerMobile,
    deliveryDivision,
    deliveryUpazila,
    deliveryDistrict,
    deliveryArea,
    deliveryStreet,
    deliveryHouse,
    itemQuantity,
    itemType,
    itemWeight,
    acceptTerms,
    shopId,
    products,
    totalCharge,
    deliveryType,
    deliveryCharge,
    city,
    zone,
    area,
    codFee,
    amountToCollect,
    recipientName,
    recipientPhone,
    merchantOrderId,
    pickupLocation,
    recipientAddressState,
    hubId,
    ...handlers,
  };
}
