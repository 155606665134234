import React, { useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useData, useLoginForm } from '../hooks';
import { useNavLinks, useAuth, useLoading } from '../contexts';
import { Grid, Image } from 'semantic-ui-react';
import { loginback } from '../assets/images';
import { Navbar as BNavbar } from 'react-bootstrap';
import { brandLogoFull as logo } from '../assets/index';
import {
  EuiHeaderLink,
  EuiCheckbox,
  EuiButton,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  useGeneratedHtmlId,
  EuiPanel,
  EuiFieldPassword,
} from '@elastic/eui';
import { EuiPageHeader, EuiPageHeaderSection, EuiTitle } from '@elastic/eui';

const Login = () => {
  const [isSwitchChecked, setIsSwitchChecked] = useState(false);
  const [dual, setDual] = useState(true);

  const loginForm = useLoginForm();
  const { homeLinks } = useNavLinks();
  const [animation, setAnimation] = React.useState('');
  const { checkForUser } = useAuth();
  const { loading } = useLoading();
  const { getSiteInfos } = useData();
  const data = getSiteInfos();

  React.useEffect(() => {
    checkForUser();
    homeLinks();
    setAnimation('on-mount 0.4s ease-out forwards');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const basicCheckboxId = useGeneratedHtmlId({ prefix: 'basicCheckbox' });

  return (
    <div className='login'>
      <Grid celled style={{ margin: 0, minHeight: '100vh' }}>
        <Grid.Row>
          <Grid.Column
            style={{ padding: 0 }}
            mobile={16}
            tablet={6}
            computer={6}
            className='d-none d-md-block'
          >
            <Image
              src={loginback}
              style={{
                height: '100%',
                objectFit: 'cover',
                objectPosition: '-200px center',
              }}
            />
          </Grid.Column>

          <Grid.Column
            style={{ padding: 0 }}
            mobile={16}
            tablet={10}
            computer={10}
          >
            <Container
              fluid
              className='h-100'
              // className=' p-4'
              style={{
                // marginTop: '90px',
                // marginBottom: '90px',
                animation: animation,
              }}
            >
              <div className='d-flex align-items-center justify-content-between py-4'>
                <BNavbar.Brand className='p-0'>
                  <Link to='/' className='h5 m-0 text-bolder p-0 text-primary'>
                    <img src={logo} height='40px' alt='Piickme Express' />
                  </Link>
                </BNavbar.Brand>
                <EuiHeaderLink color='primary' iconType='mobile'>
                  {data?.contactNumber}
                </EuiHeaderLink>
              </div>
              <div className='pb-5 h-100 d-flex align-items-center w-100'>
                <EuiPanel
                  // hasBorder
                  hasShadow
                  className='bg-white p-4 p-md-5 mb-5 shadow-sm mx-auto'
                  style={{ maxWidth: 550, marginTop: -70 }}
                >
                  <Form
                    action='submit'
                    // className='bg-white shadow-sm p-3 rounded-2 mx-auto'
                    // style={{ maxWidth: '550px' }}
                    onSubmit={loginForm.handleSubmit}
                    autoComplete='off'
                  >
                    <EuiPageHeader className='mb-5'>
                      <EuiPageHeaderSection>
                        <EuiTitle size='m'>
                          <h3>Login</h3>
                        </EuiTitle>
                      </EuiPageHeaderSection>
                      <EuiPageHeaderSection>As Merchant</EuiPageHeaderSection>
                    </EuiPageHeader>
                    <EuiForm component='div'>
                      <EuiFormRow
                        label='Mobile Number'
                        helpText='Starts with 01...'
                      >
                        <EuiFieldText
                          name='mobileNumber'
                          required
                          icon='mobile'
                          value={loginForm.mobileNumber}
                          onChange={loginForm.handleMobileNumberChange}
                          autoComplete='off'
                          autoCorrect='off'
                          autoCapitalize='off'
                        />
                      </EuiFormRow>

                      <EuiFormRow label='Password'>
                        <EuiFieldPassword
                          name='password'
                          placeholder='Password'
                          type={dual ? 'dual' : undefined}
                          required
                          value={loginForm.password}
                          onChange={loginForm.handlePasswordChange}
                          aria-label='Password'
                          autoComplete='new-password'
                        />
                      </EuiFormRow>

                      <EuiSpacer />

                      <div className='flex justify-content-between'>
                      <EuiCheckbox
                        id={basicCheckboxId}
                        checked={isSwitchChecked}
                        onChange={(e) => setIsSwitchChecked(e.target.checked)}
                        label={<div>Remember password</div>}
                      />                     
                      <a href='/forget-password'>Forget Password?</a>
                      </div>

                      <EuiSpacer />

                      <EuiButton
                        isLoading={loading}
                        type='submit'
                        fill
                        className='me-3'
                      >
                        Login
                      </EuiButton>
                      <EuiButton
                        href='/register'
                        iconType='exit'
                        // type='text'
                        color='text'
                        className='outlined'
                        // style={{ backgroundColor: 'none' }}
                        // iconSide='right'
                      >
                        Sign up
                      </EuiButton>
                      {/* <div className='w-100 text-center mt-4'>
                        Don't have an account?{' '}
                        <Link to='/register'>Register now</Link>
                      </div> */}
                    </EuiForm>
                    {/* <FormControl
                  type='mobile'
                  id='mobileNumber'
                  name='mobileNumber'
                  placeholder='Mobile Number 01...'
                  required
                  className='mb-3'
                  value={loginForm.mobileNumber}
                  onChange={loginForm.handleMobileNumberChange}
                /> */}
                    {/* </FloatingLabel> */}
                    {/* <FloatingLabel label='Password'>
                    <FormControl
                      type='password'
                      id='password'
                      name='password'
                      placeholder='Password'
                      required
                      className='mb-3'
                      value={loginForm.password}
                      onChange={loginForm.handlePasswordChange}
                    />
                  </FloatingLabel> */}
                    {/* <Button type='submit' className='w-100'>
                    Login
                  </Button> */}
                  </Form>
                </EuiPanel>
              </div>
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default Login;
