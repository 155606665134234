import React from 'react';
import { Container, Accordion } from 'react-bootstrap';
import { faqData } from '../assets';
import { v4 } from 'uuid';
import { EuiButton } from '@elastic/eui';
import { Link } from 'react-router-dom';

const GetStarted = () => {
  return (
    <div className='get-started py-5' id="get_started_section">
      <Container
        id='faqs'
        className='py-5 section text-center d-flex flex-column align-items-center justify-content-center'
      >
        <h2 className='fs-1 mt-5 fw-bold mb-5 text-danger'>
          Get Started with Piickme{' '}
        </h2>
        <p className='fs-6'>
          Piickme is an A-Z solution for e-commerce retailers and merchants that
          provides warehousing, storage, pick & pack, and delivery services
          across Bangladesh.
        </p>
        <div className='py-4'>
          <Container
            fluid
            className='p-0 my-5 d-flex align-items-center justify-content-center'
          >
            <Link to='/login'>
              <EuiButton fill className='me-3 mb-2'>
                Login
              </EuiButton>
            </Link>
            <Link to='/register'>
              <EuiButton className='outlined mb-2'>Register</EuiButton>
            </Link>
          </Container>
        </div>
      </Container>
    </div>
  );
};

export default GetStarted;
