import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import * as d3 from 'd3';
// @ts-ignore
import file from '../../demo-files/regions.csv';
// @ts-ignore
import file2 from '../../demo-files/regions-2.csv';
import { useAuth } from 'contexts';

const Regions = () => {
  const [regions, setRegions] = useState([]);
  const { checkForUser, token } = useAuth();
  const [location, setLocation] = useState({});
  const [fileNumber, setFileNumber] = useState(0);

  const handleUploadFile = (file, num) => {
    d3.csv(file).then((res) => {
      const locations = location || {};
      for (let i = 0; i < res?.length; i++) {
        const row = res[i];
        const obj = locations[row['District']];
        if (!row['District']) continue;
        if (obj) {
          const zone = locations[row['District']]['zones'][row['Zone']];
          if (zone) {
            locations[row['District']]['zones'][row['Zone']].areas.push(
              row['Area']
            );
          } else {
            locations[row['District']]['zones'][row['Zone']] = {
              name: row['Zone'],
              postCode: row['Post Code'],
              areas: [],
            };
          }
        } else {
          locations[row['District']] = {
            district: row['District'],
            zones: {},
            type:
              row['District'] === 'Dhaka'
                ? 'INSIDE_DHAKA'
                : row['District'] === 'Gazipur' ||
                  row['District'] === 'Keraniganj' ||
                  row['District'] === 'Narayanganj' ||
                  row['District'] === 'Savar'
                ? 'DHAKA_SUBURBS'
                : 'OUTSIDE_DHAKA',
            COD_charge: 1,
            home_delivery: true,
          };
        }
      }
      setLocation(locations);
      setFileNumber((prev) => prev + 1);
    });
  };

  useEffect(() => {
    checkForUser();
    handleUploadFile(file2, 2);
    handleUploadFile(file, 1);
  }, []);

  useEffect(() => {
    if (fileNumber > 0) {
      const realFormat = [];
      Object.entries(location).forEach(([key, value]) => {
        const newObj = {
          district: key,
          ...value,
          zones: Object.entries(value?.zones).map(([key, value]) => ({
            name: key,
            ...value,
          })),
        };
        realFormat.push(newObj);
      });

      setRegions(realFormat);
    }
  }, [fileNumber]);

  return <Container fluid>{JSON.stringify(regions)}</Container>;
};

export default Regions;
