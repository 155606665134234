export const deliveryTypes = [
  // {
  //   label: 'Normal Delivery',
  //   value: 'NORMAL_DELIVERY',
  //   description: '',
  // },
  {
    label: 'Same City',
    value: 'sameCity',
    description: '',
  },
  {
    label: 'Outside City',
    value: 'outsideCity',
    description: '',
  },
  {
    label: 'Dhaka Suburbs',
    value: 'dhakaSuburbs',
    description: '',
  },
  {
    label: 'Same Day Delivery',
    value: 'sameDayDelivery',
    description:
      'Cut off time is 2:00pm. That means after 2:00pm same day delivery option will be gone. And same day delivery will be only applicable for deliveries same city.',
  },
];
