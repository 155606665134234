import React, { useState } from 'react';
import { Hero, About, PriceCalculator } from '../components';
import FAQs from '../components/faqs';
import { useNavLinks, useAuth } from '../contexts';
import {
  Card,
  Icon,
  Label,
  Segment,
  Header,
  Table,
  Step,
  Button,
} from 'semantic-ui-react';
import { Container, Row, Col, Badge } from 'react-bootstrap';
import { PartialDelivery } from '../components/partial-delivery';
import {
  EuiAccordion,
  EuiAvatar,
  EuiBadge,
  EuiBetaBadge,
  EuiButton,
  EuiButtonIcon,
  EuiConfirmModal,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiHorizontalRule,
  EuiHue,
  EuiIcon,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiPanel,
  EuiSpacer,
  EuiSplitPanel,
  EuiSwitch,
  EuiText,
  EuiTimeline,
  EuiTimelineItem,
  EuiTitle,
  EuiToken,
  useGeneratedHtmlId,
} from '@elastic/eui';
import { useData, useOrder } from 'hooks';
import { useNavigate, useParams } from 'react-router';
import { FaStore } from 'react-icons/fa';
import {
  acceptedImg,
  pickedImg,
  inTransitImg,
  outForDeliveryImg,
  deliveredImg,
  cancelledImg,
  OrderStatuses,
} from 'assets';
import moment from 'moment';

export default function DeliveryDetailsPage() {
  const { homeLinks, userLinks } = useNavLinks();
  const { checkForUser, token } = useAuth();
  const { getOrderDetails, order , cancelledOrder} = useOrder();
  const { orderId } = useParams();
  const { getCodCharge } = useData();
  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const closeCancelModal = () => setIsModalVisible(false);
  const showCancelModal = () => setIsModalVisible(true);

  React.useEffect(() => {
    if (token) {
      userLinks();
    } else {
      homeLinks();
    }
    checkForUser();
  }, [token]);

  React.useEffect(() => {
    if (orderId && token) {
      getOrderDetails(orderId);
    }
  }, [orderId, token]);

  const items = [
    {
      header: 'FB Group',
      description:
        'Please join in this FB group for getting regular updates about our services.',
      meta: 'Description',
      color: 'red',
      with: '4000px',
    },
    {
      header: 'Partial Order',
      description:
        'For Smooth Pickup please give entry before 4PM or Create Partial Order using Only Customer Phone and Amount and update rest of the order information After pickup. More details ',
      meta: 'Description',
      color: 'yellow',
    },
  ];

  const buttonElementAccordionId = useGeneratedHtmlId({
    prefix: 'buttonElementAccordion',
  });

  const phase = (status) => {
    const checked = status?.value === 'DELIVERED';
    const cancelled = status?.value === 'CANCELLED';

    return (
      <EuiTimelineItem
        verticalAlign='top'
        className='border-0 p-0'
        icon={
          checked ? (
            <EuiAvatar
              name='Checked'
              iconType='check'
              iconColor='#198754'
              color='#dcf5de'
              className='fw-bold'
            />
          ) : cancelled ? (
            <EuiAvatar
              name='Unchecked'
              iconType='dot'
              iconColor='#aa0000'
              color='#ffeeee'
              className='fw-bold'
            />
          ) : (
            <EuiAvatar name='Unchecked' iconType='dot' color='subdued' />
          )
        }
      >
        <EuiSplitPanel.Outer color='transparent' hasBorder={false}>
          <EuiSplitPanel.Inner
            paddingSize='none'
            color={checked ? 'transparent' : 'transparent'}
          >
            <h2 className='fs-5 lh-sm mb-1'>
              {status?.value?.[0] + status?.value?.slice(1)?.toLowerCase()} {status?.note ? `(${status?.note})` : ''}
            </h2>
          </EuiSplitPanel.Inner>
          <EuiHorizontalRule margin='none' />
          <EuiSplitPanel.Inner paddingSize='s' >
            <EuiText size='xs' grow={false}>
              <p className='d-flex align-items-center'>
                <EuiToken iconType='tokenDate' className='me-2' />
                {moment(status?.datetime).format('LLL')}
              </p>
            </EuiText>
            
            
            
              {status?.fieldChangeLogs?.map((changeLog)=>(
                <EuiFlexGroup wrap responsive={false} gutterSize="xs" style={{marginBottom:"5px",marginTop:"5px"}}>      
                  
                  <EuiFlexItem grow={false}>
                    <EuiText size='xs' grow={false}><EuiBadge color="success">{`${changeLog.name}`} : </EuiBadge></EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiText size='xs' grow={false}>Old value : <EuiBadge color="warning">{`${changeLog.oldValue}`} </EuiBadge></EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiText size='xs' grow={false}>New Value : <EuiBadge color="danger">{`${changeLog.newValue}`} </EuiBadge></EuiText>
                  </EuiFlexItem>{<br/>}
                  
                
                  </EuiFlexGroup>   
              ))}
              
              
            
          </EuiSplitPanel.Inner>
        </EuiSplitPanel.Outer>
      </EuiTimelineItem>
    );
  };

  let statuses = [
    {
      status: 'Initiated',
      value: 'INITIATED',
      img: acceptedImg,
    },
    {
      status: 'Accepted',
      value: 'ACCEPTED',
      img: acceptedImg,
    },
    {
      status: 'Picked',
      value: 'PICKED',
      img: pickedImg,
    },
    {
      status: 'In Transit',
      value: 'IN_TRANSIT',
      img: inTransitImg,
    },
    {
      status: 'Out For Delivery',
      value: 'OUT_FOR_DELIVERY',
      img: outForDeliveryImg,
    },
    {
      status: 'Delivered',
      value: 'DELIVERED',
      img: deliveredImg,
    },
    {
      status: 'Cancelled',
      value: 'CANCELLED',
      img: cancelledImg,
    },
  ];

  const logsMap = {};
  order?.statusLogs?.forEach((status, i) => {
    logsMap[status?.value] = status;
    // if (status?.value === 'CANCELLED') {
    //   const idx = i > statuses?.length - 1 ? statuses?.length - 1 : i;
    //   const c = {
    //     status: 'Cancelled',
    //     value: 'CANCELLED',
    //     img: cancelledImg,
    //   };
    //   statuses = [...statuses?.slice(0, idx), c, ...statuses?.slice(idx)];
    // }
  });

  const [loading, setLoading] = useState(false);

  const orderCancelConfirm = async()=>{
    setLoading(true)
    await cancelledOrder(order?.orderId).then(()=>{
      getOrderDetails(orderId);
      setLoading(false)
      closeCancelModal()
    })
  }

  let isCancelled = false;

  const trackLink = `https://piickmeexpress.com/track?orderId=${order?.orderId}`;

  return (
    <>
      <Container fluid>
        <Row className='g-3'>
          <Col sm='12' className='notes'>
            <Container fluid>
              <EuiPanel hasShadow={false} hasBorder={true}>
                <EuiPageHeader>
                  <EuiPageHeaderSection>
                    <div>
                      <div className='d-flex flex-wrap mb-2'>
                        <h3 className='fs-5 fw-bold me-2'>
                          Delivery #{order?.orderId}
                        </h3>
                        <EuiBadge
                          color='#dcf5de'
                          className='my-2 my-md-0 fs-10 badge text-success'
                        >
                          {order?.status}
                        </EuiBadge>
                      </div>
                      <EuiText size='s' className='d-flex align-items-center'>
                        <FaStore className='me-2' />
                        <p>{order?.storeName || 'No Store'}</p>
                      </EuiText>
                    </div>
                  </EuiPageHeaderSection>
                  {isModalVisible && 
                    <EuiConfirmModal
                    title={"Delivery Cancel Confirmation"}
                    onCancel={closeCancelModal}
                    onConfirm={()=>{
                      orderCancelConfirm();
                    }}
                    cancelButtonText={"Cancel"}
                    confirmButtonText={'Confirm'}
                    buttonColor="danger"
                    isLoading={loading}>
                    Are you sure you want to cancel the order ?
                  </EuiConfirmModal>
                  }
                  <EuiPageHeaderSection>
                  {OrderStatuses?.slice(0, 2)
                      .concat('INITIATED')
                      .includes(order?.status) && (
                      <EuiButton
                        color='danger'
                        style={{marginRight:10}}
                        fill
                        onClick={() => {
                          showCancelModal();
                        }}
                      >
                        Cancel
                      </EuiButton>
                    )}
                    {OrderStatuses?.slice(0, 4)
                      .concat('INITIATED')
                      .includes(order?.status) && (
                      <EuiButton
                        className='outlined'
                        onClick={() => {
                          navigate(`/deliveries/update/${order?.orderId}`);
                        }}
                      >
                        Edit
                      </EuiButton>
                    )}
                  </EuiPageHeaderSection>
                </EuiPageHeader>
              </EuiPanel>
            </Container>
          </Col>
          <Col sm='12' className='mb-4'>
            <Container fluid>
              <Row className='g-3'>
                <Col sm='12' lg='7' className='order-1 order-md-1'>
                  <EuiPanel
                    hasShadow={false}
                    hasBorder={true}
                    className='h-100 bg-white'
                  >
                    <h3 className='mb-4 fw-bold'>Recepient Details</h3>
                    <Row className='g-4'>
                      <Col sm='12' lg='6'>
                        <h4 className='normal text-s mb-2'>Name</h4>
                        <p className='text-dark'>{order?.recipientName}</p>
                      </Col>
                      <Col sm='12' lg='6'>
                        <h4 className='normal text-s mb-2'>Phone</h4>
                        <p className='text-dark'>{order?.recipientPhone}</p>
                      </Col>
                      <Col sm='12' lg='12'>
                        <h4 className='normal text-s mb-2'>Address</h4>
                        <p className='text-dark'>{order?.recipientAddress}</p>
                      </Col>
                      <Col sm='12' lg='4'>
                        <h4 className='normal text-s mb-2'>City</h4>
                        <p className='text-dark'>{order?.recipientCity}</p>
                      </Col>
                      <Col sm='12' lg='4'>
                        <h4 className='normal text-s mb-2'>Zone</h4>
                        <p className='text-dark'>{order?.recipientZone}</p>
                      </Col>
                      <Col sm='12' lg='4'>
                        <h4 className='normal text-s mb-2'>Area</h4>
                        <p className='text-dark'>{order?.recipientArea}</p>
                      </Col>
                    </Row>
                  </EuiPanel>
                </Col>
                <Col lg='5' className='order-3 order-md-2'>
                  <EuiPanel
                    hasShadow={false}
                    hasBorder={true}
                    className='h-100 bg-white'
                  >
                    <div>
                      <div className=''>
                        <div className='d-flex align-items-start'>
                          <h3 className='mb-4 fw-bold me-2'>
                            Cost of Delivery
                          </h3>
                          <EuiBadge color='warning'>
                            {order?.paymentStatus}
                          </EuiBadge>
                        </div>
                        <div>
                          <Table
                            size='large'
                            padded={false}
                            className='delivery-details-table'
                          >
                            <Table.Body>
                              <Table.Row>
                                <Table.Cell>Delivery Fee</Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell textAlign='right'>
                                  ৳
                                  {order?.parcelOrder?.deliveryCharge || 0}
                                </Table.Cell>
                              </Table.Row>

                              <Table.Row>
                                <Table.Cell>COD Fee</Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell textAlign='right'>
                                  ৳
                                  {order?.parcelOrder?.codCharge}
                                </Table.Cell>
                              </Table.Row>

                              <Table.Row>
                                <Table.Cell>Discount</Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell
                                  textAlign='right'
                                  className='text-danger'
                                >
                                  ৳0
                                </Table.Cell>
                              </Table.Row>

                              <Table.Row>
                                <Table.Cell>Promo Discount</Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell
                                  textAlign='right'
                                  className='text-danger'
                                >
                                  ৳0
                                </Table.Cell>
                              </Table.Row>

                              <Table.Row>
                                <Table.Cell>Additional Charge</Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell textAlign='right'>৳0</Table.Cell>
                              </Table.Row>

                              <Table.Row>
                                <Table.Cell>Compensation Cost</Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell textAlign='right'>৳0</Table.Cell>
                              </Table.Row>
                            </Table.Body>

                            <Table.Footer>
                              <Table.Row>
                                <Table.HeaderCell>
                                  <h4 className='fw-bold fs-5'>Total</h4>
                                </Table.HeaderCell>
                                <Table.HeaderCell />
                                <Table.HeaderCell textAlign='right'>
                                  <h4 className='fw-bold fs-5'>
                                    ৳{order?.parcelOrder?.deliveryCharge + order?.parcelOrder?.codCharge}
                                  </h4>
                                </Table.HeaderCell>
                              </Table.Row>
                            </Table.Footer>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </EuiPanel>
                </Col>
                <Col sm='12' lg='7' className='order-2 order-md-3'>
                  <EuiPanel
                    hasShadow={false}
                    hasBorder={true}
                    className='h-100 bg-white'
                  >
                    <h3 className='mb-4 fs-4 fw-bold me-2'>Tracking Details</h3>
                    <div>
                      <span>Current Hub : </span><span style={{color: 'green', fontWeight: 'bold'}}>{order?.parcelOrder?.piHub?.name}</span>
                      <Step.Group
                        items
                        className='my-4 mb-5'
                        // stackable='tablet'
                      >
                        {statuses?.map((status, i) => {
                          isCancelled = !!logsMap['CANCELLED'];
                          if (!isCancelled && status.value === 'CANCELLED')
                            return;
                          return (
                            <Step key={i} active={false}>
                              <Step.Content>
                                <Step.Title className='flex flex-column justify-centent-space align-items-center'>
                                  <EuiIcon
                                    type={status?.img}
                                    size='xxl'
                                    className={`mb-2 ${
                                      isCancelled && !logsMap[status?.value]
                                        ? `gray-img`
                                        : ``
                                    }`}
                                  />
                                  <h4
                                    className={`fs-6 mb-2 text-center ${
                                      status?.value === 'CANCELLED'
                                        ? `text-danger`
                                        : ``
                                    }`}
                                    style={
                                      isCancelled && !logsMap[status?.value]
                                        ? { color: 'gray', maxWidth: 80 }
                                        : { maxWidth: 80 }
                                    }
                                    dangerouslySetInnerHTML={{
                                      __html: `${status?.status}`,
                                    }}
                                  ></h4>
                                  <EuiBetaBadge
                                    color={
                                      !!logsMap[status?.value]
                                        ? 'undefined'
                                        : 'hollow'
                                    }
                                    iconType={
                                      status.value === 'CANCELLED'
                                        ? 'cross'
                                        : 'check'
                                    }
                                    className={
                                      status?.value === 'CANCELLED'
                                        ? 'text-white bg-danger'
                                        : !!logsMap[status?.value] ||
                                          logsMap['DELIVERED']
                                        ? 'text-white bg-success'
                                        : 'text-white'
                                    }
                                    size='s'
                                  />
                                </Step.Title>
                              </Step.Content>
                            </Step>
                          );
                        })}
                      </Step.Group>

                      <EuiTimeline aria-label='Life cycle of data'>
                        {[...(order?.statusLogs || [])]
                          ?.reverse()
                          ?.map((status) => phase(status))}
                      </EuiTimeline>
                    </div>
                  </EuiPanel>
                </Col>
                <Col sm='12' lg='5' className='order-4 order-md-4'>
                  <EuiPanel
                    hasShadow={false}
                    hasBorder={true}
                    className='h-100 bg-white'
                  >
                    <div className='d-flex align-items-start'>
                      <h3 className='mb-4 fw-bold me-2'>Delivery Info</h3>
                      <EuiBadge color='rgba(0, 0, 0, .25)'>COD</EuiBadge>
                    </div>
                    <Row className='g-4'>
                      <Col sm='12' lg='6'>
                        <h4 className='normal text-s mb-2'>Product Type</h4>
                        <p className='text-dark'>{order?.itemType}</p>
                      </Col>
                      <Col sm='12' lg='6'>
                        <h4 className='normal text-s mb-2'>Delivery Type</h4>
                        <p className='text-dark'>
                          {order?.deliveryType?.replace(/_/g, ' ')}
                        </p>
                      </Col>
                      <Col sm='12' lg='6'>
                        <h4 className='normal text-s mb-2'>Weight</h4>
                        <p className='text-dark'>{order?.itemWeight} Kg</p>
                      </Col>
                      <Col sm='12' lg='6'>
                        <h4 className='normal text-s mb-2'>
                          Amount to Collect
                        </h4>
                        <p className='text-dark'>
                          {order?.amountToCollect >= 0
                            ? `৳ ${order?.amountToCollect}`
                            : '-'}
                        </p>
                      </Col>
                      <Col sm='12' lg='12'>
                        <h4 className='normal text-s mb-2'>
                          Product Description
                        </h4>
                        <p className='text-dark'>{order?.itemDesc || '-'}</p>
                      </Col>
                      <Col sm='12' lg='12'>
                        <h4 className='normal text-s mb-2'>
                          Special Instruction
                        </h4>
                        <p className='text-dark mb-4'>
                          {order?.specialInstruction || '-'}
                        </p>
                      </Col>
                    </Row>
                    <Row className='g-4'>
                      <Col sm='12' lg='12'>
                        <div className='mt-auto'>
                          <h4 className=' fs-5 fw-bold mb-2'>
                            Share Tracking Details
                          </h4>
                          <div className='d-flex items-center w-full w-100'>
                            <div
                              className='d-flex-grow mb-3 flex align-items-center text-red flex-grow-1 bg-gray p-2 px-3 me-3 hidden-scroll'
                              style={{
                                flexGrow: 1,
                                whiteSpace: 'nowrap',
                                overflowX: 'auto',
                              }}
                            >
                              {trackLink}
                            </div>
                            <EuiButton
                              type='button'
                              fill
                              className='me-3'
                              onClick={(e) => {
                                e.preventDefault();
                                navigator.clipboard.writeText(trackLink || '');
                              }}
                            >
                              Copy
                            </EuiButton>
                          </div>
                          <p className='normal text-sm fs-xsx fs-small'>
                            You can share the above public link with the
                            recipient. They can view the tracking details from
                            the link.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </EuiPanel>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}
