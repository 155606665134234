import {
  deliveryTypes,
  locations,
  productWeights,
  zoneTypes,
  productTypes,
} from 'assets';
import { useStore } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert, useAuth, useLoading } from '../contexts';
import { formatMobile } from '../utils';
import { useApi } from './use-api';
import { useData } from './use-data';
import * as d3 from 'd3';
import { useDeliveryCharge } from './use-delivery-charge';

const productBody = {
  instructionComment: '',
  productName: '',
  productPrice: 0,
  quantity: 0,
};

export function useCreateParcelBulk(formRef) {
  const [fetchedCSVData, setFetchedCSVData] = React.useState();

  const { showAlert } = useAlert();
  const { setLoading } = useLoading();
  const { createParcelBulk } = useApi();
  const navigate = useNavigate();
  const { stores, getStoreList } = useStore();
  const { token } = useAuth();
  const { getChargeCatalog } = useData();
  const { calculateDeliveryCharge } = useDeliveryCharge();

  const handlers = {};

  useEffect(() => {
    if (token) {
      getStoreList();
    }
  }, [token]);

  handlers.handleFetchedCSVData = (data) => {
    setFetchedCSVData(data);
  };

  handlers.deleteAnOrder = (user) => {
    const id = user?.id;
    if (typeof id === 'number') {
      setFetchedCSVData((prevData) => {
        let temp = [...(prevData?.orders || [])];
        temp = temp.filter((_, i) => i !== id);
        return { orders: temp };
      });
    } else {
      showAlert('PLease provide a valid id');
    }
  };

  handlers.resetInputs = () => {
    setFetchedCSVData(undefined);
  };

  const getValue = (key, val) => {
    if (key.split(':')?.length === 1) {
      return {
        [key]: getData(val),
      };
    } else {
      return {
        [key?.split(':')[0]]: {
          ...getValue(key?.split(':')?.slice(1)?.join(':'), val),
        },
      };
    }
  };

  const isBool = (data) => {
    if (typeof data === 'boolean') {
      return true;
    } else {
      const form = data?.toLowerCase();
      if (form === 'true' || form === 'false') {
        return true;
      }
    }
    return false;
  };

  const getBoolValue = (data) => {
    const form = data?.toLowerCase();
    if (form === 'true') {
      return true;
    }
    return false;
  };

  const getData = (data) => {
    const form = data?.trim();
    return form
      ? isNaN(Number(form))
        ? isBool(form)
          ? getBoolValue(form)
          : form
        : Number(form)
      : undefined;
  };

  const structurizeData = async (data) => {
    const filteredData = [];
    data?.forEach((row) => {
      const structuredData = {};
      for (let [key, val] of Object.entries(row)) {
        if (key === 'deliveryType') {
          structuredData[key] = val?.trim()?.replace(/ /gi, '_');
        } else if (key === 'itemType') {
          structuredData[key] = val?.toUpperCase();
        } else if (key === 'itemQuantity') {
          structuredData[key] = Math.abs(getData(val));
        } else if (key === 'itemWeight') {
          structuredData[key] = Math.abs(getData(val));
        } else if (key === 'amountToCollect') {
          structuredData[key] = Math.abs(getData(val));
        } else if (key === 'recipientPhone') {
          structuredData[key] = formatMobile(val);
        } else {
          structuredData[key] = getData(val);
        }
      }

      structuredData['deliveryCharge'] = calculateDeliveryCharge(
        structuredData['deliveryType'],
        structuredData['itemWeight'],
        structuredData['recipientCity'],
        structuredData['recipientZone'],
        structuredData['amountToCollect'],
        structuredData['itemQuantity']
      ).total;

      filteredData.push(structuredData);
    });

    setFetchedCSVData({
      orders: filteredData?.find((row) => {
        if (
          Object.values(row)?.find(
            (value) => typeof value !== 'object' && (value || value === 0)
          )
        ) {
          return true;
        }
      })
        ? filteredData
        : [],
    });
  };

  handlers.handleUploadFile = (val) => {
    const mainFile = val?.[0];
    if (mainFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        d3.csv(data).then((res) => {
          console.log(1234, res?.columns);
          const actualColumnsNeeded = [
            'storeName',
            'storeAddress',
            'itemType',
            'merchantOrderId',
            'amountToCollect',
            'deliveryType',
            'recipientCity',
            'recipientZone',
            'recipientArea',
            'recipientName',
            'recipientAddress',
            'recipientPhone',
            'itemDesc',
            'itemQuantity',
            'itemWeight',
            'specialInstruction',
          ];

          if (
            actualColumnsNeeded?.find((key) => !res?.columns?.includes(key))
          ) {
            showAlert(
              'Missing field name found. Please download sample file and upload again.',
              'error'
            );
          } else {
            structurizeData(res);
          }
        });
      };
      reader.readAsDataURL(mainFile);
    } else {
      setFetchedCSVData({ orders: [] });
    }
  };

  // submit the whole form
  handlers.handleBulkSubmit = async function () {
    if (fetchedCSVData?.orders) {
      setLoading(true);
      try {
        const readyData = {
          ...fetchedCSVData,
        };

        const res = await createParcelBulk(readyData);
        setLoading(false);

        if (!res) {
          showAlert('Ops! Something went wrong', 'error');
          return;
        }
        if (res?.statusCode !== 200) {
          showAlert(res.message || 'Something went wrong!', 'error');
          return;
        }
        showAlert('Orders uploaded Successfully', 'success');
        handlers.resetInputs();
        // navigate('/deliveries');
      } catch (e) {
        console.error(e);
        setLoading(false);
        return;
      }
    } else {
      showAlert('Minumum 1 order required!', 'error');
    }
  };

  return {
    fetchedCSVData,
    ...handlers,
  };
}
