import React, { useRef, useState } from 'react';
import { useAlert, useAuth } from '../contexts';
import { FaTimes, FaCheck, FaExclamationTriangle } from 'react-icons/fa';
import { useTermsModal } from 'contexts/terms.context';
import {
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiSpacer,
  EuiCodeBlock,
  EuiModalFooter,
  EuiButton,
  useGeneratedHtmlId,
  EuiFieldText,
  EuiFormRow,
  EuiRadioGroup,
  EuiForm,
} from '@elastic/eui';
import modal from 'antd/lib/modal';
import { usePaymentMethodModal } from 'contexts/payment-method.context';
import { Row, Col, Form } from 'react-bootstrap';
import { useCreatePaymentMethod } from 'hooks';
import { useEffect } from 'react';

function PaymentMethodModal() {
  const { paymentMethodModal, showPaymentMethodModal } =
    usePaymentMethodModal();
  const formRef = useRef(null);
  const hook = useCreatePaymentMethod();
  const paymentInfo = hook.getPaymentMethodFromStorage();
  

  function hide() {
    formRef?.current?.reset();
    showPaymentMethodModal(false);
    hook.emptyBankInfoFromState();
    hook.emptyMFSInfoFromState();
  }

  // for radio method input
  const radios = [
    {
      id: 'mfs',
      label: 'MFS',
    },
    {
      id: 'bank',
      label: 'Bank',
    },
  ];

  const onChange = (optionId) => {
    hook.handleMethod(optionId);
  };

  let modal;

  if (paymentMethodModal) {
    modal = (
      <EuiModal
        style={{ width: 800 }}
        onClose={hide}
        className='payment-method-modal'
      >
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Update Payment Method</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <Form action='submit' onSubmit={hook.handleSubmit} ref={formRef}>
            <span className='text-danger'>Important: </span> This payment method
            is going to be created to collect your delivery charges
            <EuiSpacer />
            <EuiCodeBlock>
              <Row className='g-4'>
                <Col sm='12' lg='12'>
                  <EuiRadioGroup
                    options={radios}
                    idSelected={hook.method}
                    onChange={onChange}
                    name='Method'
                    legend={{
                      children: <span>Select the way</span>,
                    }}
                    className='mb-4'
                  />
                </Col>
              </Row>
              <EuiSpacer size='s' />
              {hook.method === 'bank' ? (
                <div>
                  <Row className='g-4'>
                    <Col sm='12' lg='6'>
                      <EuiFormRow label='Account Name'>
                        <EuiFieldText
                          type='text'
                          name='accountName'
                          label='Account Name'
                          placeholder='Type Account Name'
                          required
                          value={hook.getAccountName()}
                          onChange={e => hook.setAccountName(e.target.value)}
                        />
                      </EuiFormRow>
                    </Col>
                    <Col sm='12' lg='6'>
                      <EuiFormRow label='Account Number'>
                        <EuiFieldText
                          type='text'
                          label='Account Number'
                          name='accountNumber'
                          placeholder='Type Account Number'
                          required
                          value={hook.getAccountNumber()}
                          onChange={e => hook.setAccountNumber(e.target.value)}
                        />
                      </EuiFormRow>
                    </Col>
                    <Col sm='12' lg='6'>
                      <EuiFormRow label='Bank Name'>
                        <EuiFieldText
                          type='text'
                          name='bankName'
                          label='Bank Name'
                          placeholder='Type Bank Name'
                          required
                          value={hook.getBankName()}
                          onChange={e => hook.setBankName(e.target.value)}
                        />
                      </EuiFormRow>
                    </Col>
                    <Col sm='12' lg='6'>
                      <EuiFormRow label='Branch Name'>
                        <EuiFieldText
                          type='text'
                          label='Branch Name'
                          name='branchName'
                          placeholder='Type Branch Name'
                          required
                          value={hook.getBranchName()}
                          onChange={e => hook.setBranchName(e.target.value)}
                        />
                      </EuiFormRow>
                    </Col>
                    <Col sm='12' lg='6'>
                      <EuiFormRow label='Routing Number'>
                        <EuiFieldText
                          type='text'
                          label='Routing Number'
                          name='routingNumber'
                          placeholder='Type Routing number'
                          required
                          value={hook.getRoutingNumber()}
                          onChange={e => hook.setRoutingNumber(e.target.value)}
                        />
                      </EuiFormRow>
                    </Col>
                  </Row>
                </div>
              ) : (
                <div>
                  <Row className='g-4'>
                    <Col sm='12' lg='6'>
                      <EuiFormRow label='MFS Name'>
                        <EuiFieldText
                          type='text'
                          name='mfsName'
                          label='MFS Name'
                          placeholder='Type MFS Name'
                          required={true ? true : false}
                          value={hook.getMfsName()}
                          onChange={e => hook.setMfsName(e.target.value)}
                        />
                      </EuiFormRow>
                    </Col>
                    <Col sm='12' lg='6'>
                      <EuiFormRow label='Mobile Number (Personal Account)'>
                        <EuiFieldText
                          type='text'
                          label='Mobile Number'
                          name='mobileNumber'
                          placeholder='Type Mobile Number'
                          required
                          value={hook.getMfsMobileNumber() }
                          onChange={e=> hook.setMfsMobileNumber(e.target.value)}
                        />
                      </EuiFormRow>
                    </Col>
                  </Row>
                </div>
              )}
              <EuiSpacer />
            </EuiCodeBlock>
            <div className='flex justify-content-end pt-4'>
              <EuiButton type='submit' fill className='me-3'>
                Submit
              </EuiButton>
              <EuiButton type='button' className='outlined' onClick={hide} fill>
                Close
              </EuiButton>
            </div>
          </Form>
        </EuiModalBody>
      </EuiModal>
    );
  }

  return <div>{modal}</div>;
}

export default PaymentMethodModal;
