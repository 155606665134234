import React from 'react';
import { Container, Row } from 'react-bootstrap';
import DeliveriesTable from './deliveries-table';

const Deliveries = () => {
  return (
    <Container fluid>
      <DeliveriesTable />
    </Container>
  );
};

export default Deliveries;
