import React, { useState } from 'react';
import { Hero, About, PriceCalculator } from '../components';
import FAQs from '../components/faqs';
import { useNavLinks, useAuth } from '../contexts';
import { Card, Button, Icon, Label, Segment, Header } from 'semantic-ui-react';
import { Container, Row, Col } from 'react-bootstrap';
import { PartialDelivery } from '../components/partial-delivery';
import {
  EuiAvatar,
  EuiButtonIcon,
  EuiDatePicker,
  EuiDatePickerRange,
} from '@elastic/eui';
import { Link } from 'react-router-dom';
import { useData } from 'hooks';

export default function Dashboard() {
  const { homeLinks, userLinks } = useNavLinks();
  const { checkForUser, token, user } = useAuth();
  const {
    dashboardData: data,
    getDashboardData,
    from,
    to,
    handleFrom,
    handleTo,
  } = useData();

  React.useEffect(() => {
    if (token && from && to) {
      getDashboardData();
    } else {
      homeLinks();
    }
  }, [token, from, to]);

  React.useEffect(() => {
    checkForUser();
  }, [token]);

  const items = [
    // {
    //   header: 'FB Group',
    //   description:
    //     'Please join in this FB group for getting regular updates about our services.',
    //   meta: 'Description',
    //   color: 'red',
    //   width: '400px',
    //   textWrap: 'truncate',
    // },
    {
      header: 'Order Placement',
      description: 'For Smooth Pickup please give entry before 4PM.',
      meta: 'Description',
      color: 'yellow',
      textWrap: 'truncate',
    },
  ];

  console.log(data);

  return (
    <>
      <Container fluid>
        <div className='notes mb-4 mx-2 mx-md-0'>
          <Container fluid>
            <Card.Group textWrap='truncate' fluid items={items} />
          </Container>
        </div>
        <div className='mb-4'>
          <Container fluid>
            <Row className='g-3 d-flex justify-content-center'>
              <Col sm='12' lg='8'>
                <div className='d-flex flex-wrap align-items-center mb-4'>
                  <h3 className='me-3 mb-2 mb-md-0 eui-textLeft fw-bold ms-2 ms-md-0'>
                    Brief States
                  </h3>
                  <div>
                    <EuiDatePickerRange
                      isInvalid={from > to}
                      startDateControl={
                        <EuiDatePicker
                          selected={from}
                          onChange={(date) => date && handleFrom(date)}
                          startDate={from}
                          endDate={to}
                          aria-label='Start date'
                          // showTimeSelect
                          maxDate={to}
                        />
                      }
                      endDateControl={
                        <EuiDatePicker
                          selected={to}
                          onChange={(date) => date && handleTo(date)}
                          startDate={from}
                          endDate={to}
                          aria-label='End date'
                          // showTimeSelect
                          minDate={from}
                        />
                      }
                    />
                  </div>
                </div>
                <Row className='g-4'>
                  <Col sm='12' lg='6'>
                    <div className='p-4 rounded-1 h-100 bg-white'>
                      <h4 className='normal text-xs'>Total Delivered</h4>
                      <h2 className='my-2'>{data?.totalDelivered}</h2>
                      <div>
                        <div className='d-flex mb-2 justify-content-between'>
                          <h3 className='normal text-xs'>Pending Delivery</h3>
                          <h3>{data?.pendingDelivery}</h3>
                        </div>
                        <div className='d-flex mb-2 justify-content-between'>
                          <h3 className='normal text-xs'>Total Returned</h3>
                          <h3>{data?.totalReturn}</h3>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg='6'>
                    <div className='h-full'>
                      <div className='p-4 rounded-1 h-100 bg-white'>
                        <h4 className='normal text-xs'>Payment Processed</h4>
                        <h2 className='my-2'>{data?.paymentProcessed}</h2>
                        <div className='mb-4'>
                          {/* <div className='d-flex mb-2 justify-content-between'>
                            <h3 className='normal text-xs'>
                              Payment in Process
                            </h3>
                            <h3>{data?.paymentInProcess}</h3>
                          </div> */}
                          <div className='d-flex mb-2 justify-content-between'>
                            <h3 className='normal text-xs'>Due Amount</h3>
                            <h3>{data?.dueAmount}</h3>
                          </div>
                          <div className='d-flex mb-2 justify-content-between'>
                            <h3 className='normal text-xs'>Pending Payment</h3>
                            <h3>{data?.pendingAmount}</h3>
                          </div>
                        </div>

                        <Button as='div' size='small' labelPosition='right'>
                          <Button size='small' color='red' disabled>
                            <Icon name='share square outline' />
                            Pay
                          </Button>
                          <Label as='a' basic color='red' pointing='left'>
                            {data?.dueAmount}
                          </Label>
                        </Button>
                      </div>
                    </div>
                  </Col>
                  <Col lg='12'>
                    <div className='bg-white'>
                      <PartialDelivery />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg='4' className='quick-links'>
                <h3 className='eui-textLeft mb-4 fw-bold ms-2 ms-md-0'>
                  Quick Links
                </h3>
                <div className='mx-2 mx-md-0'>
                  {[
                    {
                      name: 'Pricing Plan',
                      color: 'red',
                      url: '/pricing-plan',
                    },
                    {
                      name: 'New Delivery',
                      color: 'red',
                      url: '/deliveries/create',
                      disabled : user?.status !== 'ACTIVE',
                    },
                    {
                      name: 'Create Bulk Delivery',
                      color: 'orange',
                      url: '/deliveries/create/bulk',
                      disabled : user?.status !== 'ACTIVE',
                    },
                    {
                      name: 'Create Store',
                      color: 'green',
                      url: '/stores/create',
                      disabled : user?.status !== 'ACTIVE',
                    },
                    {
                      name: 'Store List',
                      color: 'olive',
                      url: '/stores',
                      disabled : user?.status !== 'ACTIVE',
                    },
                    {
                      name: 'Deliveries',
                      color: 'yellow',
                      url: '/deliveries',
                      disabled : user?.status !== 'ACTIVE',
                    },
                  ].map((item) => (
                    <Segment clearing color={item.color || 'red'}>
                      <EuiAvatar
                        name='Avatar color'
                        iconType='managementApp'
                        color={item.disabled ? '#A9A9A9' : '#ed1c25'}
                      />
                      <span className='fw-bold mx-2'>{item.name}</span>
                      {/* <Icon name='share rounded' flipped /> */}
                      <Link to={item?.disabled ? '#':item.url}>
                        <Button
                          basic
                          disabled={item.disabled}
                          floated='right'
                          icon={
                            <EuiButtonIcon
                              display='fill'
                              iconType='arrowRight'
                              aria-label='Next'
                              floated='right'
                            />
                          }
                        ></Button>
                      </Link>
                    </Segment>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}
