import React from 'react';
import { useAlert } from '../contexts';
import { FaTimes, FaCheck, FaExclamationTriangle } from 'react-icons/fa';
import { useTermsModal } from 'contexts/terms.context';
import {
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiSpacer,
  EuiCodeBlock,
  EuiModalFooter,
  EuiButton,
} from '@elastic/eui';
import modal from 'antd/lib/modal';

function TermsModal() {
  const { termsModal, showTermsModal } = useTermsModal();

  function hide() {
    showTermsModal(false);
  }

  let modal;

  if (termsModal) {
    modal = (
      <EuiModal style={{ width: 800 }} onClose={hide}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Terms & Conditions</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <span className="text-danger">Important: </span> Please read these Terms and Conditions carefully.
          <EuiSpacer />
          <EuiCodeBlock language='html'>
            {`
            These Terms and Conditions apply to Merchants/Sellers/Service Requesters (as defined below) who have registered with Piickme Express Limited as such and upon acceptance of these Terms have started to use Piickme Express Limited and Logistics Service (\`Piickme Express Limited’ or the ‘Service’ as defined below).
            
            By using the Service, you (“Service Requester”) agree that you have read, understood, accepted, and agreed unconditionally with the terms of use as stated herein (the “Piickme Express Limited Terms and Conditions”, or the “Terms and Conditions”, or the “Agreement”).
            
            The Terms and Conditions stated herein constitute a legal agreement between you and Piickme Express Limited.
            
            Piickme Express Limited is an information technology-enabled service company that provides courier and logistics support to the Merchant/Seller/Service Requester within the territory of Bangladesh with a robust infrastructure and superfast transportation system spread all over the country. It strives to ensure a flexible, less costly, and time-saving Delivery service to the last-mile consumers and thereby contribute to building a sustainable business ecosystem in Bangladesh.
            
            By using the Piickme Express Limited mobile application (the “Application”) or Piickme Express Limited website (collectively, the “Piickme Express Limited Platform” or the \`Platform’) for the purpose of offering or using Piickme Express Limited (the “Service”), you hereby expressly acknowledge and agree to be bound by the Terms and Conditions (the “Terms and Conditions”), and any future amendments and additions to the Terms and Conditions as published from time to time at https://PiickmeExpress.com/terms.
            
            Once agreed, your continued use of the Service, shall constitute your consent to and acceptance of the Terms and Conditions prospectively. You further agree to the representations made by yourself below.
            
            Piickme Express Limited reserves the right to amend or update these Terms and Conditions at any time as it thinks fit.
            Once agreed, you are bound by these Terms and Conditions while availing of the Service. If You decide not to agree to these Terms and Conditions and wish to discontinue using the service, you must notify the Company at once. Terms of separation are detailed hereinunder in Clause 3 of this Agreement.
            
            
            Piickme Express Limited and the Service Requester/Merchant/Seller are hereinafter referred to individually as a "Party" and jointly as the "Parties".
            
            1. Definition
            
            For the purpose of these Terms and Conditions, wherever the context so admits and requires, the following words shall have the following meanings:
            
            1.1.
            "Courier and Logistics Services" or the "Service(s)" shall mean collecting the Deliverables from a specified location and securely carrying, transporting, and delivering the same to another specified location within the territory of Bangladesh as per the Service Request placed by the Service Requester, in exchange of Service Fee. It also includes the collection of payment from the Recipient/Customer on behalf of the Seller against an underlying invoice issued by the Seller and transferring the collected amount to the bank/MFS account of the Seller after deduction of the Service Fee payable to Piickme Express Limited. The Service also includes the Return of the Deliverables to the Service Requester where the Delivery has failed due to the Customer not accepting delivery or could not be reached after the required attempts have been made.
            
            1.2.
            "Customer/End Customer/Recipient" shall mean the ultimate recipient of any Deliverables to be delivered by Piickme Express Limited under a Service Request from the registered Service Requester and from whom the price of Deliverables shall be collected by the Delivery Agent where applicable.
            
            1.3.
            "Delivery" shall mean the act of carrying or transporting Deliverables to the Drop-off Location specified by the Service Requester in a Service Request.
            
            1.4.
            "Deliverables" shall mean any document/product/parcel/goods specified in any Service Request to be delivered from one location to another by means of carrying and transportation and are permitted to be carried and transported under the law of Bangladesh.
            
            
            1.5.
            "Delivery Agent/Agent" shall mean the personnel designated by Piickme Express Limited for conducting the Service under this Agreement including Pick-up, Delivery, etc.
            
            1.6.
            "Drop-off Time" shall mean the timeline of delivering the Deliverables by Piickme Express Limited to the Drop-off Location.
            
            1.7.
            "Drop-off Location" shall mean the drop-off location specified in Service Request by the Service Requester whereto the Deliverables are to be delivered by Piickme Express Limited.
            
            1.8.
            "Failed/Canceled Delivery" shall mean a Delivery attempt is made by the Delivery Agent and the Customer is unwilling or declines to receive the Deliverables as it is or it is found that the contact information of the Customer given in the Service Request is wrong or the Customer remains unavailable after various attempts by the Delivery Agent or the Order is canceled by the Service Requester or Customer at a stage when the Delivery Agent is already on the way to the Drop-off Location.
            
            1.9.
            "OTP Verification" shall mean a one-time password sent by Piickme Express Limited to the Customer’s mobile phone to confirm the receipt of the product by the Customer at the time of Delivery of the product by the Delivery Agent as specified in Clause 3.19
            
            1.10.
            "Package/Packaging" shall mean any container or wrapper used for enclosing or containing any Deliverables for Delivery that is safe, proper, and adequate to withstand normal transportation and environmental hazards.
            
            1.11.
            "Pick-up" shall mean the collection of Deliverables from the Pick-up Location specified in the Service Request by the Service Requester.
            
            1.12.
            "Pick-up Location" shall mean the pick-up location specified in the Service Request by the Service Requester wherefrom the Deliverables are to be collected by Piickme Express Limited.
            
            1.13.
            "Pick-up Time" shall mean the timeframe for collecting the Deliverables by Piickme Express Limited from the Pick-up Location.
            
            1.14.
            "Return" shall mean the return of the Deliverables to the Service Requester due to Failed/Canceled Delivery as detailed in Clauses 3.29, 3.30 and 3.31 of this Agreement.
            
            1.15.
            "Service Fee Realization Model 1/SFRM 1" shall mean the collection of Service Fee by Piickme Express Limited where the Delivery Agent collects cash from the Customer upon Delivery of the Deliverables as per the invoice issued by the Service Requester and the cash collected on a specific day is transferred/deposited to the Bank or MFS account of the Service Requester by Piickme Express Limited after deducting the Service Fee of Piickme Express Limited as specified in Clauses 3.23, 3.24, 3.25 and 3.26.
            
            1.16.
            "Service Fee Realization Model 2/SFRM 2" shall mean the collection of Service Fee where the Service Fee in consideration of the Service rendered by Piickme Express Limited will be paid by the Service Requester at the end of every month as per the monthly invoice issued by Piickme Express Limited as specified in Clauses 3.27 and 3.28. Additional service level agreement will be required for the eligible Service Requester to opt for this model.
            
            1.17.
            "Service Request/Order" shall mean an order duly placed by the Service Requester requiring Service from Piickme Express Limited under this Agreement which includes the specifications of the Deliverables, contact details of the End Customer, Pick-up Location, and Drop-off Location.
            
            
            
            1.18.
            "Service Requester" shall mean an individual or entity, interchangeably referred to as “Merchant” or “Seller”, who has registered as such on Piickme Express Limited Platform, sells their products online or offline and requires delivery service from Piickme Express Limited to transport their sold products to the buyer/customer.
            
            1.19.
            "Service Request Panel/Merchant Panel/Panel" shall mean an online platform/interface provided by Piickme Express Limited through which registered Merchants can make Pick-up and Delivery Service Request for their product/parcel/document/goods efficiently.
            
            1.20.
            "Successful Delivery" shall mean successful completion of Service under any Service Request where Delivery Attempt is made by the Delivery Agent and the End Customer/Recipient receives the Deliverables and confirms successful Delivery by Piickme Express Limited through OTP Verification.
            
            1.21.
            "Terms and Conditions/Agreement" shall mean this Agreement or Terms and Conditions and include all further amendments and annexures thereto.
            
            2. Scope the Service
            
            The Courier and Logistics Services offered by Piickme Express Limited will include regular Pick-up of the Deliverables from the Service Requester/Merchant, on-time Delivery to the End Customer, Return of the Deliverables to the Merchant in case of Failed or Canceled Delivery, Cash Collection upon Delivery from the End Customer on behalf of the Merchant (COD) and next-day payment to the Merchant after deducting the Service Fee from the COD collected. The Service will cover both Business-to-Business (B2B) and Business-to-Customer (B2C) services.
            
            3. Service Steps
            Service Steps are hereby stipulated as follows:
            A. Service Requester Registration Process
            
            B. Service Request by the Service Requester
            
            C. Pick up of Deliverables from the Service Requester
            
            D. Delivery to Recipient/Customer
            
            E. Cash on Delivery (COD) Collection from the Recipient/ Customer
            
            F. Payment to the Service Requester
            
            G. Return of Deliverables to the Service Requester due to Failed/Cancelled Delivery
            
            H. Compensation for Lost or Damaged Deliverables.
            
            A. Service Requester Registration Process
            
            3.1.
            To avail of the Delivery and Logistics services of Piickme Express Limited, interested Merchants must register themselves at the following link: https://piickmeexpress.com/register
            
            3.2
            Any and all information provided by the Merchant at the time of registration is self-declaration and shall be binding on the Merchant. Piickme Express Limited shall operate as per the information given to it by the Merchant. Piickme Express Limited shall not bear any responsibility nor shall be held accountable for wrong or misinformation provided by the Merchant at the time of registration or subsequent update of relevant information. Subsequent changes to account information can be made through Piickme Express Limited call center support.
            
            3.3.
            Merchants shall provide accurate bank/MFS account details to receive fund transfers from Piickme Express Limited against cash collected from their product deliveries. Piickme Express Limited will not verify bank/ MFS account information given to it by the Merchant before disbursing payment when due. As long as Piickme Express Limited makes fund disbursements as per the information given to it by the Merchant, Piickme Express Limited shall not be held accountable for the loss of money due to wrong account information, non-payment, or delay due to bounce back.
            
            3.4
            You explicitly and unambiguously consent to the collection, storage, use, and transfer, in electronic or other forms, of your personal information as collected during the Registration Process or at any subsequent stages of the Service for the exclusive purpose of the smooth completion of the Service required by you. In connection with the collection, storage, transfer, and use of your personally identifiable information, Piickme Express Limited complies with all applicable laws relating to data privacy in Bangladesh along with its Privacy Policy available at https://Piickme Express Limited.com/privacy .Piickme Express Limited has commercially reasonable physical, technical, organizational, and administrative security measures and policies in place to protect all personal information collected by it or on its behalf from and against unauthorized access, use, and/or disclosure.
            
            3.5.
            Service Requesters/Merchants can reach out to their assigned Key Account Manager for any inquiry or call the Merchant Support helpline at 09609414141.
            B. Service Requester Registration Process
            
            3.6.
            A registered Service Requester shall have access to a Service Request Panel provided by Piickme Express Limited through which they can place a Service Request under this Agreement.
            
            3.7
            The Service Request/Order for any Deliverables must include the specifications (the type, size, number, weight, price, etc.), Recipient contact details, Pick-up Location, and Drop-off Location.
            
            3.8.
            Pick-up Location of any Deliverables under this Agreement shall be as specified by the Service Requester in the Service Request.
            
            3.9
            The Drop-off Location of any Deliverables under this Agreement will be as specified by the Service Requester in the Service Request.
            
            3.10
            The Service Requester shall ensure that the Packaging of the Deliverable(s) is safe, proper, and adequate to withstand the normal transportation and environmental hazards, and the attached invoice and documentation thereon are prepared in compliance with the applicable (Value Added Tax) VAT rates of the country.
            
            3.11.
            The Service Requester shall ensure that the Deliverables inside the Package match the description of the Deliverables on the outer Packaging/documentation and that the information given including the value/price of the Deliverables and factual declaration made thereon is true, fair, and correct. Piickme Express Limited will not be liable for any risk, expense, damage, loss, or transportation costs incurred thereon due to misinformation or disinformation provided and false declaration or misrepresentation made by the Service Requester and such actions on the part of the Service Requester shall constitute a material breach of this Agreement.
            
            3.12.
            If Service Requester is aware that any Pick-up/Drop-off Location may be unsafe for the Delivery Agent, it will be their duty to inform Piickme Express Limited beforehand. Any failure, negligence, or omission in this regard will be considered as willful negligence on the part of the Service Requester as such the Service Requester shall be liable for any physical harm or damage, or financial loss incurred thereon by Piickme Express Limited/ Delivery Agent.
            
            3.13.
            The Service Requester shall ensure that NO such deliverables will be included in the Service Request or handed over to the Delivery Agent of Piickme Express Limited that are illegal, prohibited, restricted, infringing any third-party rights, or in violation of any law of the country and in any way, detrimental to public health, safety, and morality and against the public policy.
            
            C. Pick up of Deliverables from the Service Requester
            
            3.14.
            Upon receipt of any Service Request from the Service Requester, Piickme Express Limited through its Delivery Agent will collect the Deliverables from the Pick-up Location mentioned in the Service Request.
            
            3.15.
            The Service Requester shall ensure that the Deliverables are prepared and duly packaged for Pick-up in time, failing which Piickme Express Limited may discard the Service Request/Order or charge the Service Requester an amount of Requisite/Delay Fee in addition to the regular Service Fee.
            
            D. Delivery to Recipient/Customer
            
            3.16.
            Piickme Express Limited shall deliver the Deliverables Picked-up on a specific day within the timeframe as specified at https://PiickmeExpress.com
            
            3.17.
            In the event of any unexpected delay in delivering any Deliverables due to circumstances beyond its control, Piickme Express Limited shall immediately inform the Service Requester of the delay and shall be guided by the instructions of the Service Requester as to the next course of action.
            
            3.18.
            Piickme Express Limited shall ensure 3 (three) attempts to deliver the Deliverables to the Customer. If the Customer fails to receive the Package within these three attempts, it will be considered a Failed Delivery and will be returned to the Service Requester without delay.
            
            3.19.
            Upon Delivery of the Package by the Delivery Agent, Piickme Express Limited will send an OTP to the End Customer’s mobile number and the Customer will share the OTP with the Delivery Agent. The Agent shall key in the OTP sent to the Customer in the Service Request Panel and confirm the Delivery. The Service Requester will be notified of such Successful Delivery of their Package through an update of the status in the Panel.
            
            3.20.
            Piickme Express Limited shall preserve the digital transaction log that will be available as Proof of Delivery.
            
            
            E. Cash Collection (COD) from the Recipient/Customer
            
            3.21.
            During delivery of the Product, the Delivery Agent shall collect the product price in cash from the End Customer as per the invoice issued by the Merchant/Seller and complete the delivery (COD Deliveries).
            
            3.22.
            In some cases, where the Customer pays the Merchant in advance at the time of placing the order for the product and completes the financial transaction themselves. In that cases, the Delivery Agent shall only deliver the product and the invoice to the Customer and complete the delivery (Non-COD Deliveries).
            
            F. Payment to the Service Requester
            
            3.23.
            As per SFRM 1, collected from Customers for COD Deliveries shall be subject to deduction of the Delivery Service Fee of Piickme Express Limited before it is transferred to the bank or MFS account of the Merchant.
            
            3.24.
            Service Fee payable by the Merchant for Non-COD Deliveries shall be adjusted against net cash payable to Merchant for COD Deliveries to the same Service Requester.
            
            3.25.
            Piickme Express Limited shall execute fund disbursement instructions to the Merchant Account through banking or MFS channels on every working day of the week. Fund transfer to bank accounts will be made through BEFTN and is subject to the standard processing time as per Bangladesh Bank guidelines.
            
            3.26.
            Merchant shall receive a Statement of Payment in their email account for every payment made to them by Piickme Express Limited which will be issued on the day of disbursement and instructions sent to the bank/ MFS Providers to execute fund transfers.
            3.27.
            As per SFRM 2, cash collected from Customers after product delivery shall be transferred to the bank/ MFS account of the Merchant in full without deduction of the Service Fee of Piickme Express Limited. Instead, Piickme Express Limited shall issue a periodic invoice to the Merchant to claim settlement of dues against Delivery Service Fees incurred by the Merchant. The Merchant must make payment to Piickme Express Limited within the credit period stipulated in the invoice. In case of delay or non-payment, additional fines and penalties shall be imposed and added to the outstanding amount and become payable by the Merchant. Piickme Express Limited reserves the right to withhold and adjust outstanding payables by the Merchant against future COD Deliveries once the credit period of an invoice expires and the Merchant fails to make payment to Piickme Express Limited.
            
            3.28.
            3.28. Additional Service Level Agreement between Piickme Express Limited and the Merchant will be mandatory to opt for SFRM 2 and this model will be offered only to the Merchants with a regular and high volume of Deliveries and after a thorough business case analysis.
            
            G. Return of Deliverables due to Failed/Cancelled Delivery
            
            3.29.
            When a Delivery attempt is made by the Delivery Agent and the Customer is unwilling or declines to receive the Deliverables as it is or it is found that the contact information of the Customer given in the Service Request is wrong or the Customer remains unavailable after various attempts by the Delivery Agent or the Order is canceled by the Service Requester or Customer at a stage when the Delivery Agent is already on the way to the Drop-off Location, it will be deemed to be a Failed/Canceled Delivery.
            
            3.30.
            In case of any such Failed/Canceled delivery, the Package will be returned to the Service Requester within approximately the same timeline as the initial product journey from pick-up to delivery attempt and the Service Requester shall be liable to pay Delivery as well as Return Service Fee to Piickme Express Limited.
            
            3.31.
            Since Packaging offered by the Merchant at the time of product handover to the Delivery Agent of Piickme Express Limited usually covers only one delivery attempt journey to the Customer, multiple delivery attempts due to unavailability of the Customer or a return journey as a result of Failed Delivery may create additional stress to the product Packaging in the normal course of business. Piickme Express Limited shall ensure packages are handled with care at all times but will not guarantee against nor be liable for any wear and tear as a result of poor Packaging by the Merchant or multiple delivery attempts to cater to the Customer’s needs or return of the product to the Merchant as a result of Failed Delivery.
            
            H. Compensation for Lost or Damaged Deliverables
            
            3.32.
            In case of any damage or loss of the Deliverables/Package due to negligence of the Delivery Agent and where the liabilities of Piickme Express Limited are clearly established, Piickme Express Limited will be liable to compensate the Service Requester.
            
            3.33.
            Piickme Express Limited reserves the right not to provide any compensation on incidents where the terms of this Agreement have been found violated by the Service Requester.
            
            3.34.
            The compensation amount will be determined upon mutual discussion between the Parties and will not exceed the invoice value or standard market price of the damaged or lost Deliverables or BDT 10,000 (ten thousand), whichever is lower. Piickme Express Limited's total liability to any Service Requester in respect of all losses incurred in connection with this Agreement, whether in contract, tort (including negligence, breach of statutory or other duty) or otherwise shall in no circumstances exceed the value of the Package or BDT 10,000 (ten thousand), whichever is lower.
            
            4. Service Fee
            
            4.1.
            Service fee, which includes Delivery Fee and COD Fee, in consideration of the Service provided by Piickme Express Limited will be as specified in the following link https://Piickme Express Limited.com/courier
            
            4.2.
            Piickme Express Limited reserves the right to revise the Service Fee at any time at its sole discretion. Service Requesters are requested to check the updated chart of Service Fees before placing any Service Requests on the Service Request Panel.
            
            4.3.
            Discounted pricing may apply on certain accounts up on mutual discussion and business case feasibility.
            
            5. Term and Termination
            
            5.1.
            This Agreement shall deem to be effective from the date of electronic registration by the Service Requester on Piickme Express Limited and Logistics Platform as per part A of Clause 3 of this Agreement and thereby acceptance of these Terms and Conditions of use (Effective Date) and shall remain in force for 1 (one) initially year until and unless terminated by either party. After 1 (one) year the contract will automatically be renewed for a further 1 (one) year period and shall continue to do so until and unless terminated by either party.
            
            5.2.
            Both the parties reserve the right to terminate this Agreement without assigning any reason whatsoever by serving 30 (thirty) days prior notice of termination to the other.
            
            5.3.
            Without prejudice to any other rights or remedy it may have, either Party may terminate this Agreement with immediate effect upon providing written notice of termination to the other Party, in the event of the following circumstances:
            
            (a) Failure to pay any amount due under this Agreement in full within 05 (five) working days past the due date. Piickme Express Limited may decide to transfer such due account to the third-party debt recovery service company.
            
            (b) Any Party is in breach of this Agreement and does not rectify the breach within 30 (thirty) days of notice by the non-breaching party; or
            
            (c) In the event that the other Party becomes bankrupt or insolvent, or goes into liquidation either compulsory or voluntary (save for the purpose of reconstruction or amalgamation), or if an order is made or a resolution is passed for the winding up of the other Party, or if an administrator, administrative receiver or receiver is appointed in respect of the whole or any part of the other Party’s assets or business, or if the other Party makes any composition.
            5.4.
            In the event of termination of this Agreement by either party, the Service Requester shall be liable to return any and all documents which are the property of Piickme Express Limited in its possession within 15 (fifteen) days of termination.
            
            5.5.
            In the event of termination of this Agreement by either party, financial liabilities on either side become payable immediately at once. Both parties shall commit time and resources to reconcile outstanding balances and conduct settlements without delay.
            5.6.
            Both Parties agree to mutually review the progress and status of services being rendered pursuant to this Agreement, keeping in mind the business viability, and will accordingly extend/update/modify the Agreement as required.
            
            
            Miscellaneous
            
            6. Severability
            
            If any of the provisions of this Agreement shall be held invalid or unenforceable by reason of the scope or duration thereof or for any other reason, such invalidity or unenforceability shall attach only to the particular aspect of such provision found invalid or unenforceable and shall not affect any other provision of this Agreement. To the fullest extent permitted by law, this Agreement shall be construed as if the scope or duration of such provision had been more narrowly drafted so as not to be invalid or unenforceable.
            
            7. Confidentiality
            
            7.1.
            This Agreement and related contents are confidential and neither party shall disclose the contents either in full or in part to any third party either in a hard or soft format without the prior written approval from the other party.
            
            7.2.
            The parties covenant and agree to keep confidential and secret, whether stated to be confidential or not, all verbal and written communications and all other information that the parties came to know pursuant to the relationship created by this agreement.
            
            7.3
            The parties shall not use or disclose to any person, firm, corporation or other business entity any confidential information, and shall not in any other way publicly or privately disseminate any confidential information, and shall not help anyone else to do any of the foregoing.
            
            7.4.
            In respect of any data provided to others, the receiving party of the confidential information shall return or destroy the data forthwith upon being required by the other party or immediately without request upon the expiry or termination of this agreement.
            
            7.5.
            The liabilities of the parties under this confidentiality clause shall remain valid and effective beyond the termination of this agreement.
            
            8. Representation and Warranties
            
            8.1
            Each Party represents and warrants that it has all necessary statutory and regulatory permissions, approvals, permits, and license(s) for the running and the operation of its establishment and for the conduct of its business, more particularly, for performing its obligations under this Agreement;
            
            8.2.
            Each Party represents and warrants in relation to the other Parties that:
            
            (a) The execution of this Agreement and the performance of its obligations under this Agreement and the implementations of the terms and conditions contemplated hereby do not constitute a breach of any agreement, arrangement or understanding, oral or written, entered into by it with any third party;
            
            b) The execution of this Agreement and the performance of its obligations under this Agreement and implementation of terms and conditions contemplated hereby are not a violation of any statute, regulation, rule, order, decree, injunction, or any other restriction of any government agency or court of law or of any regulations authority to which it is subject or of any of the provisions of its’ constitutional documents.
            
            9. Intellectual Property Rights
            
            9.1.
            Nothing in this Agreement shall be deemed to confer any assignment or license of the intellectual property rights of any Party to the other Party and all the intellectual property rights of the parties shall remain the property of the respective parties.
            
            9.2.
            All such intellectual property rights are the exclusive properties of the respective parties, and the other party is only permitted to use the intellectual property rights in the manner specified in this agreement or contained in the written instructions issued by the respective parties, or as agreed by the parties in writing.
            
            9.3.
            The parties shall comply with all specific instructions from the other party and procedures pertaining to the intellectual property rights prescribed by the parties from time to time, and shall obtain written approval of the other party in the manner set out herein for all advertisements, publications and communications including or referring to the intellectual property rights.
            
            10. Indemnity
            
            10.1.
            Each Party ("Breaching Party") shall at all times hereafter indemnify and keep the other Party ("Non-Breaching Party") fully indemnified against all losses, damages, costs, charges, expenses, interests, and disbursements of any nature whatsoever arising out of third party claims, demands, actions or proceedings, which the Non-Breaching Party may pay, incur, suffer or sustain or be liable to pay, incur, suffer or sustain as a result or consequence, direct or indirect, of any breach or failure to perform any of the provisions of this Agreement by the Breaching Party or by its employees and/or representatives.
            
            10.2
            In addition to all remedies available in law and equity, any Party who breaches or attempts to breach this Agreement shall be liable to the other Party for any legal fees, costs, and any kind of financial liabilities incurred in the successful enforcement of this Agreement.
            
            11. Force Majeure
            
            11.1.
            The term ‘Force Majeure as employed herein shall include but is not limited to acts of God, acts of the public enemy, wars, riots, epidemics, pandemics, civil disturbances, change of law, and any other similar events, not within the control of either party and which by the exercise of due diligence neither party is able to overcome.
            
            11.2.
            If either party is temporarily unable by reason of force majeure to meet any of its obligations under the Agreement, and if such party gives to the other party written notice of the event within fourteen (14) days after its occurrence, such obligations of the party shall be suspended as it is unable to perform by reason of the event as long as the inability continues for a maximum period of thirty (30) days.
            
            11.3.
            Neither party shall be liable to the other party for loss or damage sustained by such other party arising from any event or delays arising from such event.
            
            12. Non-Exclusivity
            
            Terms of engagement under this contract shall be deemed non-exclusive in nature for both parties. This means Piickme Express Limited can render similar services to other Merchants/Service Requesters and likewise, any Merchant registered with Piickme Express Limited can avail of services similar to other Courier and Logistic companies in the market.
            
            13. Non-Assignment of Rights and Obligations
            
            Neither Party shall assign, delegate or transfer its rights and obligations under this Agreement to any person or entity without the prior written consent of the other Party.
            14. Effect of Headings
            
            The subject headings of this Agreement are for the purpose of convenience only and shall not affect the construction or interpretation of any of its provisions.
            
            15. Governing Law
            
            This Agreement shall be governed and construed in accordance with the laws of Peoples Republic of Bangladesh.
            
            16. Arbitration
            
            The agreement shall be governed by and construed in accordance with the substantive laws of Bangladesh. All questions, disputes, or differences whatsoever which may at any time arise between the parties hereto or their respective representatives touching these presents or the subject matter hereof or arising out of or relating thereto respectively and whether as to construction or otherwise which is not settled by negotiation or other agreed mode of settlement shall be referred to arbitration as per the provisions of the Arbitration Act, 2001 shall apply.
            The parties hereto agree to be bound by any arbitration award rendered under this clause as the final adjudication. Any arbitration award may, if necessary, be enforced by any court or authority having jurisdiction. The Parties undertake and agree that all arbitral proceedings conducted under this Article shall be kept strictly confidential, and all information, documentation and materials in whatever form disclosed in the course of such arbitral proceeding shall be used solely for the purpose of those proceedings.
            
            17. Notice and Communication
            
            All notices, requests, or communications between the Parties under this Agreement shall be in writing and must be communicated via email, fax or post. Service Requesters can reach out to their assigned Key Account Manager for any inquiry or call Merchant Support helpline at 09609414141 Merchants can also send their panel.
            
            Please note that Piickme express reserve the right to cancel delivery or to refuse to accept products as its discretion. 
            
            On the other hand, Piickme express has no liability/responsibility if the customer provides any illegal or prohibited products. The liability/responsibility goes to the concerned customer in this regard.
            
            Piickme express has the right to check the products during receiving the products from the customers. It can also have the right to open the product's box /package.`}
          </EuiCodeBlock>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButton onClick={hide} fill>
            Close
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  return <div>{modal}</div>;
}

export default TermsModal;
