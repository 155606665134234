import React from 'react';
import {
  ConstantsProvider,
  AuthProvider,
  LoadingProvider,
  NavLinksProvider,
  AlertProvider,
} from './index';
import { TermsModalProvider } from './terms.context';
import { PaymentMethodModalProvider } from './payment-method.context';

export function Providers({ children }) {
  return (
    <ConstantsProvider>
      <AlertProvider>
        <TermsModalProvider>
          <PaymentMethodModalProvider>
            <LoadingProvider>
              <AuthProvider>
                <NavLinksProvider>{children}</NavLinksProvider>
              </AuthProvider>
            </LoadingProvider>
          </PaymentMethodModalProvider>
        </TermsModalProvider>
      </AlertProvider>
    </ConstantsProvider>
  );
}
