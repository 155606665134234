import { EuiHeader, EuiPanel, EuiTitle } from '@elastic/eui';
import { useCreateParcel } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { Table } from 'semantic-ui-react';
import { v4 } from 'uuid';

const CostCalculation = ({ chargeData }) => {
  const [data, setData] = useState();
  let hook = useCreateParcel();

  useEffect(() => {
    if (chargeData) {
      setData(chargeData);
    }
  }, [chargeData]);

  return (
    <div className='mb-4'>
      <h3 className='fs-4 fw-bold mb-4'>Cost Of Delivery</h3>
      <div>
        <Table size='large' padded={false} className='collapsed-in-mobile'>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Delivery Fee</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell textAlign='right'>৳{data?.deliveryCharge}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>COD Fee</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell textAlign='right'>৳{data?.codFee}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Discount</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell textAlign='right'>৳0</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Promo Discount</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell textAlign='right'>৳0</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Additional Charge</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell textAlign='right'>৳0</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Compensation Cost</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell textAlign='right'>৳0</Table.Cell>
            </Table.Row>
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell>
                <h4 className='fw-bold fs-5'>Total</h4>
              </Table.HeaderCell>
              <Table.HeaderCell />
              <Table.HeaderCell textAlign='right'>
                <h4 className='fw-bold fs-5'>৳{data?.totalCharge}</h4>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    </div>
  );
};

export default CostCalculation;
