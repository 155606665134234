import {
  EuiButton,
  EuiIcon,
  EuiLoadingSpinner,
  EuiPageTemplate,
} from '@elastic/eui';
import React from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router';

const NoContent = ({
  title = 'No Data Found',
  description,
  icon = 'searchProfilerApp',
  hasButton = false,
  btnText = 'Home',
  btnLink = '/dashboard',
}) => {
  const navigate = useNavigate();

  return (
    <EuiPageTemplate color='white' panelled={true} offset={0} grow={false}>
      <EuiPageTemplate.EmptyPrompt
        hasShadow={false}
        hasBorder
        color='white'
        className='p-0'
        footer={
          hasButton ? (
            <EuiButton
              className='outlined compressed'
              onClick={() => {
                navigate(btnLink);
              }}
            >
              {btnText}
            </EuiButton>
          ) : undefined
        }
        paddingSize='none'
      >
        <div className='text-center'>
          <EuiIcon type={icon} size='xxl' />
          <h4 className='fw-bold fs-4'>{title}</h4>
          <p className='fs-6' style={{ maxWidth: 380 }}>
            {description}
          </p>
        </div>
      </EuiPageTemplate.EmptyPrompt>
    </EuiPageTemplate>
  );
};

export default NoContent;
