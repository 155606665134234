import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Table, Container } from 'react-bootstrap';
import { deliveryTypes, parcelsData, productTypes } from '../../assets';
import { v4 } from 'uuid';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import {
  useApi,
  useCreateParcel,
  useCreateParcelBulk,
  useOrder,
  useStore,
} from '../../hooks';
import { Loader } from '../loader';
import { useAlert, useAuth, useLoading } from '../../contexts';
import {
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiTitle,
  EuiButton,
  EuiPanel,
  EuiSpacer,
  EuiTab,
  EuiText,
  EuiNotificationBadge,
  LEFT_ALIGNMENT,
  EuiBadge,
  EuiTableHeaderCell,
  EuiButtonIcon,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiLink,
  EuiPopover,
  EuiTableRow,
  EuiTableRowCell,
  EuiTableHeaderMobile,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTable,
  EuiTableBody,
  EuiTableHeader,
  EuiIcon,
  EuiAvatar,
  CENTER_ALIGNMENT,
  EuiCallOut,
  EuiPage,
  EuiLoadingSpinner,
  EuiPageTemplate,
  useGeneratedHtmlId,
  EuiFilePicker,
  RIGHT_ALIGNMENT,
  EuiBasicTable,
  EuiHealth,
} from '@elastic/eui';
import { Tag } from 'antd';
import { Dimmer, Segment, Image } from 'semantic-ui-react';
import { NoContent } from 'components/common';
// @ts-ignore
import builkcsv from '../../demo-files/deliveries.csv';
import { formatMobile, getDistricts } from 'utils';
import { usePaymentMethodModal } from 'contexts/payment-method.context';

const simpleFileDownload = () => {
  window.location.href = `${builkcsv}`;
};

const DeliveriesTableBulk = () => {
  const { user, token } = useAuth();
  const navigate = useNavigate();
  const [status, setStatus] = useState('active');
  const { loading, setLoading } = useLoading();
  const { showPaymentMethodModal } = usePaymentMethodModal();
  const { stores, getStoreList } = useStore();
  const {
    fetchedCSVData,
    handleBulkSubmit,
    handleFetchedCSVData,
    handleUploadFile,
    resetInputs,
    deleteAnOrder,
  } = useCreateParcelBulk();

  useEffect(() => {
    if (token) {
      getStoreList();
    }
  }, [token]);

  const actions = [
    {
      name: 'Delete',
      description: 'Delete this person',
      icon: 'trash',
      type: 'icon',
      color: 'danger',
      onClick: deleteAnOrder,
    },
  ];
  const columns = [
    // {
    //   field: 'checkbox',
    //   nameckbox: true,
    //   textOnly: false,
    //   width: '32px',
    //   alignment: LEFT_ALIGNMENT,
    //   mobileOptions: {
    //     show: false,
    //   },
    //   render: (title, item) => <h4 className='fw-bold'></h4>,
    // },
    {
      field: 'storeName',
      name: 'storeName',
      width: '150px',
      enlarge: true,
      render: (label) => {
        const color = stores?.find(
          (type) => type?.name?.toLowerCase() === label?.toLowerCase()
        )
          ? 'transparent'
          : 'danger';
        return <EuiHealth color={color}>{label}</EuiHealth>;
      },
    },
    {
      field: 'itemType',
      name: 'itemType',
      width: '150px',
      render: (label) => {
        const color = productTypes?.includes(label?.toUpperCase())
          ? 'transparent'
          : 'danger';
        return <EuiHealth color={color}>{label}</EuiHealth>;
      },
    },
    {
      field: 'merchantOrderId',
      name: 'merchantOrderId',
      width: '150px',
      enlarge: true,
    },
    {
      field: 'amountToCollect',
      name: 'amountToCollect',
      width: '150px',
      render: (label) => {
        const color = parseFloat(label) >= 0 ? 'transparent' : 'danger';
        return <EuiHealth color={color}>{label}</EuiHealth>;
      },
    },
    {
      field: 'deliveryType',
      name: 'deliveryType',
      width: '150px',
      render: (label) => {
        const color = deliveryTypes?.find((type) => type?.value === label)
          ? label === 'Same Day Delivery' && new Date().getUTCHours() >= 14
            ? 'danger'
            : 'transparent'
          : 'danger';
        return <EuiHealth color={color}>{label}</EuiHealth>;
      },
    },
    {
      field: 'recipientCity',
      name: 'recipientCity',
      width: '150px',
      render: (label) => {
        const color = getDistricts()?.find(
          (type) => type?.toUpperCase() === label?.toUpperCase()
        )
          ? 'transparent'
          : 'danger';
        return <EuiHealth color={color}>{label}</EuiHealth>;
      },
    },
    {
      field: 'recipientZone',
      name: 'recipientZone',
      width: '150px',
      enlarge: true,
    },
    {
      field: 'recipientName',
      name: 'recipientName',
      width: '150px',
      enlarge: true,
    },
    {
      field: 'recipientArea',
      name: 'recipientArea',
      width: '150px',
      enlarge: true,
    },
    {
      field: 'recipientAddress',
      name: 'recipientAddress',
      width: '150px',
      enlarge: true,
    },
    {
      field: 'recipientPhone',
      name: 'recipientPhone',
      width: '150px',
      enlarge: true,
    },
    {
      field: 'itemDesc',
      name: 'itemDesc',
      width: '150px',
      enlarge: true,
    },
    {
      field: 'itemQuantity',
      name: 'itemQuantity',
      width: '150px',
      enlarge: true,
      render: (label) => {
        const color = parseFloat(label || 0) >= 0 ? 'transparent' : 'danger';
        return <EuiHealth color={color}>{label}</EuiHealth>;
      },
    },
    {
      field: 'itemWeight',
      name: 'itemWeight',
      width: '150px',
      render: (label) => {
        const color = parseFloat(label || 0) >= 0 ? 'transparent' : 'danger';
        return <EuiHealth color={color}>{label}</EuiHealth>;
      },
    },
    {
      field: 'specialInstruction',
      name: 'specialInstruction',
      width: '150px',
      enlarge: true,
    },
    {
      field: 'deliveryCharge',
      name: 'deliveryCharge',
      width: '150px',
      enlarge: true,
      render: (title, item) => <h4 style={{ fontWeight: 'bold' }}>{title}</h4>,
    },
    {
      field: 'actions',
      name: 'Actions',
      isVisuallyHiddenLabel: true,
      alignment: CENTER_ALIGNMENT,
      // isActionsPopover: true,
      width: '80px',
      // enlarge: true,
      actions,
      // render: (title, item) => (
      //   <div className='fw-bold'>
      //     <EuiIcon
      //       type={item.type}
      //       size='m'
      //       style={{ verticalAlign: 'text-top' }}
      //     />
      //     {title}
      //   </div>
      // ),
    },
  ];

  const items = fetchedCSVData?.orders?.map((order, i) => {
    return {
      id: i,
      ...order,
      health: (
        <EuiBadge color={i % 2 == 0 ? 'danger' : 'warning'}>
          {order?.id?.slice(-6)}
        </EuiBadge>
      ),
    };
  });

  function renderHeaderCells() {
    const headers = [];

    columns.forEach((column, columnIndex) => {
      if (true) {
        headers.push(
          <EuiTableHeaderCell
            key={column.id}
            align={columns[columnIndex].alignment}
            width={column.width}
            mobileOptions={column.mobileOptions}
            style={{ paddingBottom: 12, paddingTop: 12 }}
          >
            {column.label}
          </EuiTableHeaderCell>
        );
      }
    });
    return headers.length ? headers : null;
  }

  function renderRows() {
    const renderRow = (item) => {
      const cells = columns.map((column) => {
        const cell = item[column.id];
        let child;
        if (column.isActionsPopover) {
          return (
            <EuiTableRowCell
              key={column.id}
              header={column.label}
              textOnly={false}
              hasActions={true}
              align='left'
            >
              <EuiButton
                href={`/deliveries/${item.orderId}`}
                key='A'
                icon='eye'
                size='s'
                color='success'
                className='text-success compressed collapsed d-none d-md-block outlined fw-normal'
              >
                View
              </EuiButton>
              <EuiIcon
                onClick={() => navigate(`/deliveries/${item.orderId}`)}
                type='eye'
                size='l'
                style={{ cursor: 'pointer' }}
                className='p-1 cursor-pointer ms-1 bg-gray rounded-1 text-danger d-block d-md-none outlined fw-normal'
              />
            </EuiTableRowCell>
          );
        }

        if (column.render) {
          // const title = item.name?.isLink ? (
          //   <EuiLink href=''>{item.name.value}</EuiLink>
          // ) : (
          //   titleText
          // );
          child = column.render(cell, item);
        } else if (column.cellProvider) {
          child = column.cellProvider(cell);
        } else if (cell?.isLink) {
          child = <EuiLink href=''>{cell.value}</EuiLink>;
        } else if (cell?.truncateText) {
          child = cell.value;
        } else {
          child = cell;
        }

        return (
          <EuiTableRowCell
            key={column.id}
            align={column.alignment}
            truncateText={(cell && cell.truncateText) || false}
            textOnly={cell ? cell.textOnly : true}
            mobileOptions={{
              header: column.label,
              ...column.mobileOptions,
            }}
            className='p-1 p-md-4'
          >
            {child}
          </EuiTableRowCell>
        );
      });

      return (
        <EuiTableRow key={item.id} isSelectable={true} hasActions={true}>
          {cells}
        </EuiTableRow>
      );
    };

    const rows = [];
    for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
      const item = items[itemIndex];
      rows.push(renderRow(item));
    }

    return rows;
  }
  const exampleId = 'example-id';

  // file upload
  const filePickerRef = useRef();
  const removeFilePickerId = useGeneratedHtmlId({ prefix: 'removeFilePicker' });

  return (
    <Container fluid>
      <EuiPageHeader>
        <EuiPageHeaderSection className='ms-3 ms-lg-0 mb-4'>
          <EuiTitle size='s'>
            <h3>Bulk Delivery</h3>
          </EuiTitle>
        </EuiPageHeaderSection>
        <EuiPageHeaderSection className='ms-3 ms-lg-0 mb-3'>
          <EuiButton
            color='text'
            className='me-3 mb-2'
            onClick={() => resetInputs()}
          >
            Clear Table
          </EuiButton>
          <EuiButton
            fill
            className='mb-2'
            onClick={() => handleBulkSubmit()}
            disabled={!fetchedCSVData?.orders?.length}
          >
            Upload{' '}
            {fetchedCSVData?.orders ? (
              <EuiNotificationBadge className='eui-alignCenter' size='m'>
                {fetchedCSVData?.orders?.length}
              </EuiNotificationBadge>
            ) : undefined}
          </EuiButton>
        </EuiPageHeaderSection>
      </EuiPageHeader>

      <EuiPanel
        hasShadow={false}
        hasBorder={true}
        paddingSize='none'
        className='mb-5'
      >
        <>
          <Fragment>
            {loading ? (
              <Loader />
            ) : fetchedCSVData?.orders?.length ? (
              <div style={{ width: '100%', overflow: 'auto' }}>
                {/* <EuiTableHeaderMobile>
                  <EuiFlexGroup
                    responsive={false}
                    justifyContent='spaceBetween'
                    alignItems='baseline'
                  >
                    <EuiFlexItem grow={false}></EuiFlexItem>
                  </EuiFlexGroup>
                </EuiTableHeaderMobile>

                <EuiTable
                  color='accent'
                  // cellPadding={100}
                  // cellSpacing={100}
                  id={exampleId}
                  className='border-top'
                >
                  <EuiTableHeader>{renderHeaderCells()}</EuiTableHeader>
                  <EuiTableBody>{renderRows()}</EuiTableBody>
                  {/* <EuiTableFooter>{renderFooterCells()}</EuiTableFooter> 
                </EuiTable> */}
                <EuiBasicTable
                  tableCaption='Upload bulk deliveries'
                  items={items}
                  isExpandable={true}
                  itemId='id'
                  columns={columns}
                  // pagination={pagination}
                  // sorting={sorting}
                  // selection={selection}
                  isSelectable={true}
                  hasActions={true}
                  responsive={true}
                  // onChange={onTableChange}
                />
              </div>
            ) : (
              <EuiPageTemplate
                color='white'
                panelled={true}
                offset={0}
                grow={false}
              >
                <EuiPageTemplate.EmptyPrompt
                  hasShadow={false}
                  hasBorder
                  color='white'
                  className='p-0'
                  footer={
                    <EuiFlexGroup alignItems='center' justifyContent='center'>
                      <EuiButton
                        className='outlined'
                        onClick={simpleFileDownload}
                      >
                        Sample CSV
                      </EuiButton>
                      <EuiFilePicker
                        ref={filePickerRef}
                        id={removeFilePickerId}
                        // multiple
                        initialPromptText='Upload CSV'
                        onChange={handleUploadFile}
                        display='default'
                        onReset={() => resetInputs()}
                        onClick={(e) => {
                          if (!user?.bankInfo && !user?.mfsInfo) {
                            e.preventDefault();
                            showPaymentMethodModal(true);
                          }
                        }}
                      />
                    </EuiFlexGroup>
                  }
                  paddingSize='none'
                >
                  <div className='text-center'></div>
                </EuiPageTemplate.EmptyPrompt>
              </EuiPageTemplate>
            )}
          </Fragment>
        </>
      </EuiPanel>
    </Container>
  );
};

export default DeliveriesTableBulk;
