import React, { useEffect, useState } from 'react';

import { FaBars, FaTimes } from 'react-icons/fa';
import {
  Navbar as BNavbar,
  Container,
  Button,
  Nav,
  Fade,
} from 'react-bootstrap';
import { useNavLinks } from '../../contexts';
import { Link, useParams } from 'react-router-dom';
import { brandLogoFull as logo, LogoLight } from '../../assets/index';
import { brandShort } from '../../assets';
import { v4 } from 'uuid';
import throttle from 'lodash.throttle';
import { useTermsModal } from 'contexts/terms.context';

export function Header({ forFooter }) {
  const [showMenu, setShowMenu] = React.useState(false);
  const [animation, setAnimation] = React.useState('');
  const [hasScrolled, setHasScrolled] = React.useState(false);

  //   const { links } = useNavLinks();
  const links = [
    {
      name: 'Partners',
      path: '#partners_section',
    },
    {
      name: 'Services',
      path: '#services_section',
    },
    {
      name: 'Pricing Plan',
      path: '#pricing_section',
    },
    {
      name: 'FAQ',
      path: '#faq_section',
    },
  ];

  function toggleMenu(e, value) {
    if (value !== undefined) {
      setShowMenu(value);
    } else {
      setShowMenu((prev) => !prev);
    }
  }

  React.useEffect(() => {
    setAnimation('on-mount 0.6s ease-out forwards');
  }, []);

  useEffect(() => {
    const handleScroll = throttle(() => {
      const offset = 0;
      const { scrollTop } = document.documentElement;
      const scrolled = scrollTop > offset;

      if (hasScrolled !== scrolled) {
        setHasScrolled(scrolled);
      }
    }, 200);

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [hasScrolled]);

  return (
    <div
      style={forFooter ? { background: '#26252D' } : { background: 'white' }}
    >
      <BNavbar
        bg={forFooter ? undefined : 'white'}
        fixed={forFooter ? undefined : hasScrolled ? 'top' : 'top'}
        expand='lg'
        style={{ minHeight: 60, maxWidth: '100vw', animation: animation }}
        className={forFooter ? 'py-4' : hasScrolled ? 'py-3' : 'px-2 py-4 pt-5'}
      >
        <Container className='d-flex align-items-center'>
          <BNavbar.Brand className='p-0'>
            <Link to='/#' className='h5 m-0 text-bolder p-0 text-primary'>
              {/* <span className='text-secondary'>Piickme</span>
        <span className='text-primary'> Express</span> */}
              {forFooter ? (
                <img src={LogoLight} className='p-2' alt='Piickme Express' />
              ) : (
                <img
                  src={logo}
                  height='48px'
                  className='p-2'
                  alt='Piickme Express'
                />
              )}
            </Link>
          </BNavbar.Brand>
          {!forFooter && (
            <Button
              variant='white'
              className={`shadow-0 p-0 icon-btn justify-self-end d-block d-lg-none`}
              onClick={toggleMenu}
            >
              {showMenu ? <FaTimes /> : <FaBars />}
            </Button>
          )}
          <NavMenu
            links={links}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            forFooter={forFooter}
          />
        </Container>
      </BNavbar>
    </div>
  );
}

const NavMenu = ({ links, showMenu, setShowMenu, forFooter }) => {
  const { termsModal, showTermsModal } = useTermsModal();

  return (
    <>
      <div
        className={`justify-content-end top-0 relative ${
          forFooter ? '' : 'px-5 bg-white'
        } ${showMenu ? 'active' : 'hide'} nav-menu`}
      >
        {showMenu && (
          <Button
            variant='white'
            className={`shadow-0 p-0 icon-btn position-absolute justify-self-end d-block d-lg-none`}
            onClick={() => setShowMenu(false)}
            style={{ right: 25, top: 25 }}
          >
            <FaTimes />
          </Button>
        )}
        {!forFooter ? (
          <Nav className='d-flex align-items-center justify-content-center'>
            {links.map((item) => (
              <button
                key={v4()}
                className={`p-2 px-3 text-dark fs-5 rounded ${
                  forFooter ? 'text-white' : ''
                }`}
                // href={item.path}
                onClick={(e) => {
                  setShowMenu(false);
                  const target_section = document.querySelector(item.path);
                  window.scrollTo({
                    top: target_section?.getBoundingClientRect().top,
                    behavior: 'smooth',
                  });
                }}
              >
                {item.name}
              </button>
            ))}
          </Nav>
        ) : (
          <button
            className={`p-2 px-3 text-dark fs-5 rounded text-white`}
            onClick={(e) => {
              showTermsModal(!termsModal);
            }}
          >
            Terms & Conditions
          </button>
        )}
      </div>
    </>
  );
};
