import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ImgService1 from "../assets/images/partners/p1.jpg";
import ImgService2 from "../assets/images/partners/p2.png";
import ImgService3 from "../assets/images/partners/p3.png";
import ImgService4 from "../assets/images/partners/p4.jpeg";
import ImgService5 from "../assets/images/partners/p5.png";
import ImgService6 from "../assets/images/partners/p6.png";
import ImgService7 from "../assets/images/partners/p7.png";
import ImgService8 from "../assets/images/partners/p8.png";
import ImgService9 from "../assets/images/partners/p9.jpg";

const Services = () => {
  return (
    <div className='services' id="partners_section">
      <Container fluid>
        <Container className=''>
          <Row className='d-flex justify-content-center'>
            <Col lg='12' className='d-flexx justify/content-center'>
              <div className='bg-white rounded shadow-lg text-center p-4 p-lg-5 mx-lg-5 mx-3'>
                <h1 className='fs-4 fw-bold mb-5 mt-3'>Our Trusted Partners</h1>
                <div className='d-flex align-items-center flex-wrap flex-lg-wrap pb-3 justify-content-center'>
                  <div className='d-flex align-items-center justify-content-center'>
                    <img
                      src={ImgService1}
                      alt=''
                      className='mx-4 mx-lg-5 mb-4 mb-lg-3'
                    />
                    <img
                      src={ImgService2}
                      alt=''
                      className='mx-4 mx-lg-5 mb-4 mb-lg-3'
                    />

                    <img
                      src={ImgService3}
                      alt=''
                      className='mx-4 mx-lg-5 mb-4 mb-lg-3'
                    />
                    <img
                      src={ImgService4}
                      alt=''
                      className='mx-4 mx-lg-5 mb-4 mb-lg-3'
                    />
                               <img
                      src={ImgService5}
                      alt=''
                      className='mx-4 mx-lg-5 mb-4 mb-lg-3'
                    />
                  </div>
                  <div className='d-flex align-items-center justify-content-center mt-5'>
                    
                    <img
                      src={ImgService6}
                      alt=''
                      className='mx-4 mx-lg-5 mb-4 mb-lg-3'
                    />
                      <img
                      src={ImgService7}
                      alt=''
                      className='mx-4 mx-lg-5 mb-4 mb-lg-3'
                    />
                      <img
                      src={ImgService8}
                      alt=''
                      className='mx-4 mx-lg-5 mb-4 mb-lg-3'
                    />
                      <img
                      src={ImgService9}
                      alt=''
                      className='mx-4 mx-lg-5 mb-4 mb-lg-3'
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default Services;
