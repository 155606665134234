import React from 'react';

const PaymentMethodModalContext = React.createContext({
  paymentMethodModal: false,
  showPaymentMethodModal: function (val) {},
});

export function usePaymentMethodModal() {
  return React.useContext(PaymentMethodModalContext);
}

export function PaymentMethodModalProvider({ children }) {
  const [paymentMethodModal, setPaymentMethodModal] = React.useState(null);

  function showPaymentMethodModal(val) {
    setPaymentMethodModal(val);
  }

  return (
    <PaymentMethodModalContext.Provider
      value={{ paymentMethodModal, showPaymentMethodModal }}
    >
      {children}
    </PaymentMethodModalContext.Provider>
  );
}
