import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { parcelsData } from '../../assets';
import { v4 } from 'uuid';
import { Link } from 'react-router-dom';
import { useApi, useOrder } from '../../hooks';
import { Loader } from '../loader';
import { useLoading } from '../../contexts';

const ParcelsTable = () => {
  const { orders, getOrderList } = useOrder();

  useEffect(async () => {
    getOrderList();
  }, []);

  return (
    <div>
      <h4 className='mb-4 fw-bold'>My Parcels</h4>
      <Table striped size='lg' style={{ minWidth: 800 }}>
        <thead>
          <tr>
            {parcelsData.fields.map((field) => (
              <th key={v4()}>{field}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {orders.length ? orders.map((order, index) => (
            <tr key={v4()}>
              <td>{index + 1}</td>
              <td>
                <Link
                  to={`/tracking?id=${order.courierCustomerInfo?.orderReference}`}
                >
                  {order.courierCustomerInfo?.orderReference}
                </Link>
              </td>
              <td>{order.customer?.name}</td>
              <td>{order.customer?.mobileNumber}</td>
              <td>{order.status}</td>
            </tr>
          )): <tr><td colSpan={5}><p className='p-4'>Nor orders found</p></td></tr> }
        </tbody>
      </Table>
    </div>
  );
};

export default ParcelsTable;
