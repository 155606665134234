import React, { useEffect } from 'react';
import {
  Page,
  Text,
  View,
  Image as Img,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';
// @ts-ignore
import logo from '../../assets/images/logo-vertical.png';
import JsBarcode from 'jsbarcode';
import moment from 'moment';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    padding: 20,
  },
  section: {
    flexGrow: 1,
    height: 842,
    backgroundColor: 'white',
  },
  headerArea: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: 15,
    width: '100%',
  },
  headerLeft: {},
  logo: {
    // flexDirection: "row",
    width: 60,
    margin: '0',
  },
  headerRight: {
    paddingTop: 15,
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  shopName: {
    fontSize: 10,
    marginBottom: 5,
    textAlign: 'right',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  shopText: {
    marginBottom: 3,
    fontSize: 10,
    fontWeight: 300,
    textAlign: 'right',
    maxWidth: 200,
  },

  titleArea: {
    // backgroundColor: "#E5E5E5",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    marginLeft: 'auto',
    marginRight: 'auto',
    minWidth: 125,
    border: '2px solid #e3e3e3',
    backgroundColor: 'white',
  },
  title: {
    fontSize: 11,
    fontWeight: 600,
    lineHeight: 1,
  },

  orderDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    // alignItems: "center",
    flexDirection: 'row',
    marginBottom: 15,
    width: '100%',
  },

  middleLeft: {
    maxWidth: 220,
    lineHeight: 1.5,
  },
  bilingTitle: {
    fontSize: 12,
    fontWeight: 900,
    marginBottom: 5,
  },
  bilingTitleValue: {
    fontSize: 14,
    fontWeight: 900,
    marginBottom: 5,
  },
  middleRight: {
    width: '45%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },

  userName: {
    fontSize: 10,
    marginBottom: 5,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  userText: {
    marginBottom: 5,
    textAlign: 'left',
    fontSize: 10,
    fontWeight: 200,
    display: 'flex',
    justifyContent: 'flex-start',
  },

  middleArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 15,
  },
  totalDue: {
    marginBottom: 0,
    color: '#bf1a0d',
    fontSize: 14,
  },

  productHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: '#efefef',
    borderRadius: 2,
    padding: '8px 15px',
    color: '#000',
    fontSize: 11,
  },
  productHeaderText: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    flex: 1,
    fontWeight: 'bold',
  },
  productList: {
    borderBottom: '1px solid #f6f6f5',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 5px',
  },

  producDetails: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
  },
  producText: {
    fontSize: 10,
    fontWeight: 400,
    textAlign: 'right',
    width: 100,
  },
  producName: {
    width: 200,
    fontSize: 10,
    textAlign: 'left',
  },
  imageArea: {
    height: 35,
    width: 35,
    display: 'flex',
    flexDirection: 'column',
    marginRight: 8,
  },
  image: {
    flexDirection: 'row',
    flex: 1,
    height: 35,
    width: 35,
    backgroundColor: '#ebebeb',
    borderRadius: 3,
  },

  acountSection: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'row',
    padding: '20px 0',
  },
  acountArea: {
    textAlign: 'right',
    marginBottom: 25,
    backgroundColor: '#f8f8f8',
    padding: 15,
  },

  acountAreaPara: {
    textAlign: 'right',
    fontSize: 10,
    marginBottom: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    flexDirection: 'row',
    width: 200,
    padding: '0px 5px',
    fontWeight: 'bold',
  },
  fromSmall: {
    fontSize: 10,
    fontWeight: 400,
    display: 'flex',
    justifyContent: 'flex-end',
  },

  footerArea: {
    textAlign: 'left',
    // maxWidth: 300,
    display: 'flex',
    alignSelf: 'baseline',
    justifyContent: 'flex-end',
    marginTop: 'auto',
  },
  footerText: {
    // display: "flex",
    flexDirection: 'row',
    fontSize: 10,
    fontWeight: 200,
    marginTop: 5,
  },
  textRed: {
    color: '#cd113b',
  },
});

const CustomerInvoice = ({ data: orderDetails }) => {
  let canvas;
  canvas = document.createElement('canvas');
  JsBarcode(canvas, orderDetails?.orderId, {
    displayValue: false,
    // width: 280,
    height: 60,
  });
  const barcode = canvas.toDataURL();
  // attempt 2
  const loadImg = (src) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = (result) => {
        console.log('result : ', result);
        resolve(src);
      };
      img.onerror = () => reject(new Error('could not load image'));
    });
  const [src, setSrc] = React.useState('preloadimg');

  // useEffect(() => {
  //   fetch(
  //     'http://localhost:3000?url=https%3A%2F%2Fstaging-qcoom-user.s3-ap-southeast-1.amazonaws.com%2Fstatic_image%2F2022-05-24T16%3A29%3A25.719_mobile.jpg&w=96&q=75'
  //   )
  //     .then((res) => {
  //       return res;
  //     })
  //     .then((res) => {
  //       setSrc(res);
  //     });
  // }, []);

  return (
    <Document title='Order Invoice'>
      <Page wrap={false} size='A4' style={styles.page}>
        <View style={styles.section}>
          <View style={styles.headerArea}>
            <View style={styles.headerLeft}>
              <Img src={logo} style={styles.logo} />
            </View>

            <View style={styles.headerRight}>
              {/* <View>
                <Text style={styles.shopName}>qfood.com</Text>
              </View>
              <View>
                <Text style={styles.shopText}>
                  {orderDetails?.shop?.address}
                </Text>
              </View>
              <View>
                <Text style={styles.shopText}>
                  {orderDetails?.shop?.contactNumber.split("+88")}
                </Text>
              </View> */}

              {/* <View style={styles.middleRight}> */}
              <View>
                <Text style={styles.bilingTitle}>Shipped from</Text>
              </View>
              <View>
                <Text
                  style={{ ...styles.bilingTitleValue, textAlign: 'right' }}
                >
                  {orderDetails?.parcelOrder?.storeName}
                </Text>
              </View>
              <View>
                <Text style={{ ...styles.userText, textAlign: 'right' }}>
                  {orderDetails?.parcelOrder?.shippingAddress}
                </Text>
              </View>
              <View>
                <Text style={{ ...styles.userText, textAlign: 'right' }}>
                  Contact:{' '}
                  {orderDetails?.parcelOrder?.storeContact ||
                    orderDetails?.customer?.mobileNumber?.replace('+88', '')}
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{
              ...styles?.orderDetails,
              backgroundColor: '#efefef',
              padding: '15px 15px',
              alignItems: 'center',
            }}
          >
            <View style={styles.middleLeft}>
              <View style={styles.titleArea}>
                <Text style={styles.title}>
                  {orderDetails?.parcelOrder?.deliveryType?.replace(/_/g, ' ')}
                </Text>
              </View>
            </View>

            <View style={styles?.middleRight}>
              <View>
                <Text style={styles.bilingTitle}>Shipped to:</Text>
              </View>
              <Text style={styles.userText}>
                Name: {orderDetails?.parcelOrder?.recipientName}
              </Text>
              <Text style={styles.userText}>
                Phone: {orderDetails?.parcelOrder?.recipientPhone}
              </Text>
              <Text style={styles.userText}>
                Address:{' '}
                {orderDetails?.parcelOrder?.recipientAddress
                  ? `${orderDetails?.parcelOrder?.recipientAddress}, `
                  : ``}
                {orderDetails?.parcelOrder?.recipientArea
                  ? `${orderDetails?.parcelOrder?.recipientArea}, `
                  : ``}
                {orderDetails?.parcelOrder?.recipientZone
                  ? `${orderDetails?.parcelOrder?.recipientZone}, `
                  : ``}
                {orderDetails?.parcelOrder?.recipientCity
                  ? `${orderDetails?.parcelOrder?.recipientCity}, `
                  : ``}
              </Text>
            </View>
          </View>

          <View style={styles?.orderDetails}>
            <View style={{ ...styles.middleLeft, marginTop: 10 }}>
              <View>
                <Text style={styles.userText}>
                  Weight:{orderDetails?.parcelOrder?.itemWeight}
                </Text>
              </View>
              <View>
                <Text style={styles.bilingTitle}>
                  Collectable Amount: BDT{' '}
                  {orderDetails?.parcelOrder?.amountToCollect}
                </Text>
              </View>
              <View>
                <Text style={styles.userText}>Special Instruction</Text>
              </View>
              <View>
                <Text style={styles.bilingTitle}>
                  {orderDetails?.parcelOrder?.specialInstruction || '-'}
                </Text>
              </View>
              <View>
                <Text style={styles.userText}>
                  Item Description{' : '}
                  {orderDetails?.itemDesc}
                </Text>
              </View>
            </View>

            <View style={{ ...styles?.middleRight, alignItems: 'flex-start' }}>
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  marginLeft: -20,
                  marginBottom: 10,
                }}
              >
                <Img src={barcode} />
              </View>
              <View style={{ marginLeft: -10 }}>
                <Text
                  style={{ fontWeight: 'bold', fontSize: 12, marginBottom: 10 }}
                >
                  {orderDetails?.orderId}
                </Text>
                <Text style={styles.userText}>
                  Order Date: {moment(orderDetails?.createdAt).format('lll')}
                </Text>
                <Text style={styles.userText}>
                  Zone: {orderDetails?.parcelOrder?.recipientZone || '-'}
                </Text>
                <Text style={styles.userText}>
                  Area: {orderDetails?.parcelOrder?.recipientArea || '-'}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CustomerInvoice;
