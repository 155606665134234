import React from 'react';
import { Header } from './header';

export function Footer() {
  return (
    <>
      <Header forFooter={true} />
    </>
  );
}
