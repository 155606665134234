import { EuiLoadingSpinner, EuiPageTemplate } from '@elastic/eui';
import React from 'react';
import { Container } from 'react-bootstrap';
import { brand } from '../assets';

export const Loader = () => {
  return (
    <div>
      {/* <img src={brand} alt='Piickme' height='40' className='logo' /> */}
      <EuiPageTemplate color='white' panelled={false} offset={0} grow={false}>
        <EuiPageTemplate.EmptyPrompt
          hasShadow={false}
          hasBorder
          // title={<span>No spice</span>}
          // footer={<EuiButton className='outlined'>BBB</EuiButton>}
        >
          <EuiLoadingSpinner size='xxl' />
        </EuiPageTemplate.EmptyPrompt>
      </EuiPageTemplate>
    </div>
  );
};
