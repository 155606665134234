import React, { Fragment, useEffect, useState } from 'react';
import { Table, Container } from 'react-bootstrap';
import { parcelsData } from '../../assets';
import { v4 } from 'uuid';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useApi, useData, useOrder } from '../../hooks';
import { Loader } from '../loader';
import { useAuth, useLoading } from '../../contexts';
import {
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiTitle,
  EuiButton,
  EuiPanel,
  EuiTabs,
  EuiSpacer,
  EuiTab,
  EuiText,
  EuiNotificationBadge,
  LEFT_ALIGNMENT,
  EuiBadge,
  EuiTableHeaderCell,
  EuiButtonIcon,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiLink,
  EuiPopover,
  EuiTableRow,
  EuiTableRowCell,
  EuiTableHeaderMobile,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTable,
  EuiTableBody,
  EuiTableHeader,
  EuiIcon,
  EuiAvatar,
  CENTER_ALIGNMENT,
  EuiCallOut,
  EuiPage,
  EuiLoadingSpinner,
  EuiPageTemplate,
} from '@elastic/eui';
import { Tag } from 'antd';
import { Dimmer, Segment, Image } from 'semantic-ui-react';
import { NoContent } from 'components/common';
import { CustomerInvoice, MerchantInvoice } from 'components/invoice-pdf';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import moment from 'moment';
import { CSVLink } from 'react-csv';

function getTagColor(key) {
  const map = {
    failed: 'red',
    canceled: 'red',
    returned: 'magenta',
    picked: 'volcano',
    processing: 'orange',
    ssl: 'gold',
    delivered: 'lime',
    success: 'green',
    paid: 'cyan',
    initiated: 'blue',
    reviewed: 'geekblue',
    outForDelivery: 'purple',
    [key]: key,
  };
  return map[key] || map['success'];
}

const OrdersByInvoiceTable = () => {
  const { token } = useAuth();
  const { orders, getOrderListByInvoice } = useOrder();
  const navigate = useNavigate();
  const [status, setStatus] = useState('PAID');
  const { loading } = useLoading();
  const { getCodCharge } = useData();

  useEffect(() => {
    if (token) {
      getOrderListByInvoice(0, 100);
    }
  }, [token, status]);

  const columns = [
    {
      id: 'checkbox',
      isCheckbox: true,
      textOnly: false,
      width: '32px',
      alignment: LEFT_ALIGNMENT,
      mobileOptions: {
        show: false,
      },
      render: (title, item) => <h4 className='fw-bold'></h4>,
    },
    {
      id: 'orderId',
      label: 'Invoice ID',
      // isVisuallyHiddenLabel: true,
      alignment: LEFT_ALIGNMENT,
      width: '100%',
      mobileOptions: {
        // show: false,
      },
      render: (title, item) => <h5 style={{ fontSize: '.95rem' }}>{title}</h5>,
    },
    {
      id: 'createdAt',
      label: 'Invoice Date',
      isVisuallyHiddenLabel: true,
      alignment: LEFT_ALIGNMENT,
      width: '100%',
      mobileOptions: {
        // show: false,
      },
    },
    {
      id: 'recipientInfo',
      label: 'Recipient Info',
      isVisuallyHiddenLabel: true,
      alignment: LEFT_ALIGNMENT,
      width: '200px',
      mobileOptions: {
        // show: false,
      },
    },
    {
      id: 'amountToCollect',
      label: 'Collectable',
      mobileOptions: {
        // only: true,
        // header: false,
        enlarge: true,
        width: '100%',
      },
      alignment: LEFT_ALIGNMENT,
      width: '150px',
      render: (title, item) => <span>৳{title}</span>,
      // cellProvider: (cell) => <EuiIcon type={cell} size='m' />,
    },
    {
      id: 'amountToCollect',
      label: 'Collected',
      mobileOptions: {
        // only: true,
        // header: false,
        enlarge: true,
        width: '100%',
      },
      alignment: LEFT_ALIGNMENT,
      width: '100%',
      render: (title, item) => <span>৳{title}</span>,
      // cellProvider: (cell) => <EuiIcon type={cell} size='m' />,
    },
    {
      id: 'cod',
      label: 'COD',
      mobileOptions: {
        // only: true,
        // header: false,
        enlarge: true,
        width: '100%',
      },
      alignment: LEFT_ALIGNMENT,
      width: '100%',
      render: (title, item) => <span>৳{title}</span>,
      // cellProvider: (cell) => <EuiIcon type={cell} size='m' />,
    },
    {
      id: 'deliveryCharge',
      label: 'Delivery Charge',
      mobileOptions: {
        // only: true,
        // header: false,
        enlarge: true,
        width: '100%',
      },
      alignment: LEFT_ALIGNMENT,
      width: '100%',
      render: (title, item) => <span>৳{title}</span>,
      // cellProvider: (cell) => <EuiIcon type={cell} size='m' />,
    },
    {
      id: 'total',
      label: 'Total',
      mobileOptions: {
        // only: true,
        // header: false,
        enlarge: true,
        width: '100%',
      },
      alignment: LEFT_ALIGNMENT,
      width: '100%',
      render: (title, item) => <span>৳{title}</span>,
      // cellProvider: (cell) => <EuiIcon type={cell} size='m' />,
    },
    {
      id: 'payout',
      label: 'Paid Out',
      mobileOptions: {
        // only: true,
        // header: false,
        enlarge: true,
        width: '100%',
      },
      alignment: LEFT_ALIGNMENT,
      width: '100%',
      render: (title, item) => <span>৳{title}</span>,
      // cellProvider: (cell) => <EuiIcon type={cell} size='m' />,
    },
  ];

  const items = orders?.map((order, i) => {
    return {
      ...order,
      paymentStatus: 'Paid',
      cod: order?.parcelOrder?.codCharge,
      deliveryCharge: order?.parcelOrder?.deliveryCharge,
      total: order?.deliveryCharge + order?.parcelOrder?.codCharge,
      payout: order?.amountToCollect - (order?.parcelOrder?.deliveryCharge + order?.parcelOrder?.codCharge),
      createdAt: moment(order?.createdAt).format('DD-MM-yyyy'),
      id: i,
      name: order.name,
      address: <p>{order?.address}</p>,
      magnitude: 1,
      orderId: order?.orderId,
      type: order?.deliveryType,
      store: order?.storeName,
      amount: order?.deliveryCharge || 0,
      deliveryStatus: order?.status?.replace(/_/g, ' '),
      payment: order?.paymentStatus,
      recipientInfo: (
        <div>
          <div className='d-flex align-items-center'>
            {order?.recipientName}
          </div>
          <div className='d-flex align-items-center'>
            {order?.recipientPhone}
          </div>
        </div>
      ),
      health: (
        <EuiBadge color={i % 2 == 0 ? 'danger' : 'warning'}>
          {order?.id?.slice(-6)}
        </EuiBadge>
      ),
    };
  });

  function renderHeaderCells() {
    const headers = [];

    columns.forEach((column, columnIndex) => {
      if (true) {
        headers.push(
          <EuiTableHeaderCell
            key={column.id}
            align={columns[columnIndex].alignment}
            width={column.width}
            mobileOptions={column.mobileOptions}
            style={{ paddingBottom: 12, paddingTop: 12 }}
          >
            {column.label}
          </EuiTableHeaderCell>
        );
      }
    });
    return headers.length ? headers : null;
  }

  function renderRows() {
    const renderRow = (item) => {
      const cells = columns.map((column) => {
        const cell = item[column.id];
        let child;
        if (column.isActionsPopover) {
          return <></>;
        }

        if (column.render) {
          // const title = item.name?.isLink ? (
          //   <EuiLink href=''>{item.name.value}</EuiLink>
          // ) : (
          //   titleText
          // );
          child = column.render(cell, item);
        } else if (column.cellProvider) {
          child = column.cellProvider(cell);
        } else if (cell?.isLink) {
          child = <EuiLink href=''>{cell.value}</EuiLink>;
        } else if (cell?.truncateText) {
          child = cell.value;
        } else {
          child = cell;
        }

        return (
          <EuiTableRowCell
            key={column.id}
            align={column.alignment}
            truncateText={cell && cell.truncateText}
            textOnly={cell ? cell.textOnly : true}
            mobileOptions={{
              header: column.label,
              ...column.mobileOptions,
            }}
            className='p-1 p-md-4'
          >
            {child}
          </EuiTableRowCell>
        );
      });

      return (
        <EuiTableRow key={item.id} isSelectable={true} hasActions={true}>
          {cells}
        </EuiTableRow>
      );
    };

    const rows = [];
    for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
      const item = items[itemIndex];
      rows.push(renderRow(item));
    }

    return rows;
  }
  const exampleId = 'example-id';

  return (
    <Container fluid>
      <EuiPageHeader>
        <EuiPageHeaderSection className='ms-3 ms-lg-0 mb-4'>
          <EuiTitle size='s'>
            <h3>Invoices</h3>
          </EuiTitle>
        </EuiPageHeaderSection>
        {/* <EuiPageHeaderSection className='ms-3 ms-lg-0 mb-3'></EuiPageHeaderSection> */}
      </EuiPageHeader>

      <EuiPanel
        hasShadow={false}
        hasBorder={true}
        paddingSize='none'
        className='mb-5'
      >
        <>
          <Fragment>
            {/* <EuiSpacer /> */}
            <EuiText>
              {loading ? (
                <Loader />
              ) : orders?.length ? (
                <div>
                  <EuiTableHeaderMobile>
                    <EuiFlexGroup
                      responsive={false}
                      justifyContent='spaceBetween'
                      alignItems='baseline'
                    >
                      <EuiFlexItem grow={false}></EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiTableHeaderMobile>

                  <EuiTable
                    color='accent'
                    // cellPadding={100}
                    // cellSpacing={100}
                    id={exampleId}
                    className='border-top'
                  >
                    <EuiTableHeader>{renderHeaderCells()}</EuiTableHeader>
                    <EuiTableBody>{renderRows()}</EuiTableBody>
                    {/* <EuiTableFooter>{renderFooterCells()}</EuiTableFooter> */}
                  </EuiTable>
                </div>
              ) : (
                <NoContent
                  title='No Deliveries Found'
                  description='There is no delivery that matches your filter or you have not
                created one yet. Let’s create one.'
                  icon='searchProfilerApp'
                  hasButton
                  btnLink='/deliveries/create'
                  btnText='New Delivery'
                />
              )}
            </EuiText>
          </Fragment>
        </>
      </EuiPanel>
    </Container>
  );
};

export default OrdersByInvoiceTable;
