import React from 'react';
import { Container, Row } from 'react-bootstrap';
import OrdersByInvoiceTable from './orders-by-invoice-table';

const DeliveriesByInvoice = () => {
  return (
    <Container fluid>
      <OrdersByInvoiceTable />
    </Container>
  );
};

export default DeliveriesByInvoice;
