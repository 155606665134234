import { EuiLink } from '@elastic/eui';
import React from 'react';
import { Card } from 'react-bootstrap';

const OrderRules = () => {
  return (
    <div>
      <div>
        <p className='mb-3'>
          Cost might vary depending upon the delivery situations and other
          circumstances.
        </p>
        <h4 className='mb-4'>
          For details:{' '}
          <EuiLink href='/pricing-plan' color='accent'>
            Check the Pricing Plan
          </EuiLink>
        </h4>
        <ol>
          <li className='mb-3'>** Pickup Last Entry Time 4 pm</li>
          <li className='mb-3'>
            ** On Demand / Express / Fragile Last Entry Time 12:30 pm, Maximum
            size of any product should not be over 2CFT (In general)
          </li>

          <li className='mb-3'>
            ** Use Fragile Type for Cosmetics, Liquide grocery, Herbals, Gift
            Items,Electronic gadgets, Lights , Glass items, Food items (dry)
          </li>

          <li className='mb-3'>
            ** Inside Dhaka Normal Delivery will take 24-48 hours, On Demand &
            Fragile take 8 hours, Out Side Dhaka it may take 3-5 days
          </li>
        </ol>
      </div>
    </div>
  );
};

export default OrderRules;
