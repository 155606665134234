import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Button, Toast } from 'react-bootstrap';
import FormGroup from '../form-group';
import { v4 } from 'uuid';
import { calculatorData } from '../../assets';
import { locations } from '../../assets/data/locations';
import { sortAreas } from '../../utils';
import {
  EuiButton,
  EuiCheckboxGroup,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiFilePicker,
  EuiLink,
  EuiRange,
  EuiSelect,
  EuiSpacer,
  EuiSwitch,
  EuiText,
  useGeneratedHtmlId,
  EuiDescribedFormGroup,
  EuiFieldNumber,
} from '@elastic/eui';

const PartialDelivery = () => {
  const [price, setPrice] = React.useState();
  const [weight, setWeight] = React.useState();
  const [district, setDistrict] = React.useState(locations[0].district);
  const [areas, setAreas] = React.useState(
    locations[0].zones?.map((zone) => zone.name)
  );
  const [selectedArea, setSelectedArea] = React.useState(
    locations[0].zones[0]?.name
  );
  const [charge, setCharge] = React.useState(undefined);

  function handleChange(e) {
    const areaList = sortAreas(e.currentTarget.value);
    setAreas(areaList);
    setSelectedArea(areaList?.[0]);

    const district = locations.find(
      (location) => location.district === e.currentTarget.value
    );
    setDistrict(district ? district.district : locations[0].district);
  }

  const valuesMap = {
    price,
    weight,
    district,
    area: selectedArea,
  };

  const actionsMap = {
    price: (e) => setPrice(e.currentTarget.value),
    weight: (e) => setWeight(e.currentTarget.value),
    district: (e) => handleChange(e),
    area: (e) => setSelectedArea(e.currentTarget.value),
  };

  useEffect(() => {
    setCharge(undefined);
  }, [price, weight, district, selectedArea]);

  function calculatePrice(e) {
    e.preventDefault();
    const loc = locations.find((location) => location.district === district);
    const charge =
      loc.charge[`${Math.ceil(weight / 1000)}kg`] ||
      district?.toLowerCase() === 'dhaka'
        ? 80
        : 120;
    setCharge(charge);
  }

  const [isSwitchChecked, setIsSwitchChecked] = useState(false);

  const formRowCheckboxItemId__1 = useGeneratedHtmlId({
    prefix: 'formRowCheckboxItem',
    suffix: 'first',
  });
  const formRowCheckboxItemId__2 = useGeneratedHtmlId({
    prefix: 'formRowCheckboxItem',
    suffix: 'second',
  });
  const formRowCheckboxItemId__3 = useGeneratedHtmlId({
    prefix: 'formRowCheckboxItem',
    suffix: 'third',
  });

  const formRowRangeId = useGeneratedHtmlId({ prefix: 'formRowRange' });
  const checkboxes = [
    {
      id: formRowCheckboxItemId__1,
      label: 'Option one',
    },
    {
      id: formRowCheckboxItemId__2,
      label: 'Option two is checked by default',
    },
    {
      id: formRowCheckboxItemId__3,
      label: 'Option three',
    },
  ];
  const [checkboxIdToSelectedMap, setCheckboxIdToSelectedMap] = useState({
    [formRowCheckboxItemId__2]: true,
  });

  const onSwitchChange = () => {
    setIsSwitchChecked(!isSwitchChecked);
  };

  const onCheckboxChange = (optionId) => {
    const newCheckboxIdToSelectedMap = {
      ...checkboxIdToSelectedMap,
      ...{
        [optionId]: !checkboxIdToSelectedMap[optionId],
      },
    };

    setCheckboxIdToSelectedMap(newCheckboxIdToSelectedMap);
  };
  return (
    <Form
      action='submit'
      className='p-4 pb-5 partial-delivery'
      onSubmit={calculatePrice}
    >
      <EuiForm component='div'>
        <EuiDescribedFormGroup
          title={<h3>Receiver's phone</h3>}
          description='This is a 11 digit phone number of the receiver who is responsible to receive the respective amount.'
        >
          <EuiFormRow label="Receiver's phone" helpText='Starts with 01...'>
            <EuiFieldText name='Mobile number' />
          </EuiFormRow>
        </EuiDescribedFormGroup>

        <EuiDescribedFormGroup
          title={<h3>Amount</h3>}
          description='Total amount to collect in number. The amount can be less of equal to the available.'
        >
          <EuiFormRow label='Amount to collect'>
            <EuiFieldNumber name='Amount' />
          </EuiFormRow>
        </EuiDescribedFormGroup>

        <EuiDescribedFormGroup
          title={<h3>Store/Shop</h3>}
          description='Choose a listed shop. Selected shop will responsible for the transaction and other shop is not affected at all.'
        >
          <EuiFormRow
            label='Store name'
            labelAppend={
              <EuiText size='xs'>
                <EuiLink>Help guide</EuiLink>
              </EuiText>
            }
          >
            <EuiSelect
              hasNoInitialSelection
              onChange={() => {}}
              options={[
                { value: 'option_one', text: 'Store one' },
                { value: 'option_two', text: 'Store two' },
                { value: 'option_three', text: 'Store three' },
              ]}
            />
          </EuiFormRow>

          <EuiSpacer />

          <EuiButton minWidth type='submit' fill disabled>
            Save form (Coming soon...)
          </EuiButton>
        </EuiDescribedFormGroup>
      </EuiForm>
    </Form>
  );
};

export default PartialDelivery;
