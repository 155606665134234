import React, { Fragment, useEffect, useState } from 'react';
// @ts-ignore
import { Table, Container } from 'react-bootstrap';
// @ts-ignore
import { parcelsData } from '../../assets';
// @ts-ignore
import { v4 } from 'uuid';
// @ts-ignore
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useApi, useData, useInvoice, useOrder, useStore } from '../../hooks';
import { Loader } from '../loader';
import { useAlert, useAuth, useLoading } from '../../contexts';
import {
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiTitle,
  EuiButton,
  EuiPanel,
  // @ts-ignore
  EuiTabs,
  // @ts-ignore
  EuiSpacer,
  // @ts-ignore
  EuiTab,
  EuiText,
  // @ts-ignore
  EuiNotificationBadge,
  LEFT_ALIGNMENT,
  // @ts-ignore
  EuiBadge,
  EuiTableHeaderCell,
  // @ts-ignore
  EuiButtonIcon,
  // @ts-ignore
  EuiContextMenuItem,
  // @ts-ignore
  EuiContextMenuPanel,
  EuiLink,
  // @ts-ignore
  EuiPopover,
  EuiTableRow,
  EuiTableRowCell,
  EuiTableHeaderMobile,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTable,
  EuiTableBody,
  EuiTableHeader,
  EuiIcon,
  // @ts-ignore
  EuiAvatar,
  // @ts-ignore
  CENTER_ALIGNMENT,
  // @ts-ignore
  EuiCallOut,
  // @ts-ignore
  EuiPage,
  // @ts-ignore
  EuiLoadingSpinner,
  // @ts-ignore
  EuiPageTemplate,
  EuiDatePicker,
  EuiDatePickerRange,
} from '@elastic/eui';
import { Tag } from 'antd';
// @ts-ignore
import { Dimmer, Segment, Image } from 'semantic-ui-react';
import { NoContent } from 'components/common';
// @ts-ignore
import { CustomerInvoice, MerchantInvoice } from 'components/invoice-pdf';
// @ts-ignore
import { pdf, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import saveAs from 'file-saver';

function getTagColor(key) {
  const map = {
    failed: 'red',
    canceled: 'red',
    returned: 'magenta',
    picked: 'volcano',
    processing: 'orange',
    ssl: 'gold',
    delivered: 'lime',
    success: 'green',
    paid: 'cyan',
    initiated: 'blue',
    reviewed: 'geekblue',
    outForDelivery: 'purple',
    [key]: key,
  };
  return map[key] || map['success'];
}

const InvoiceTable = () => {
  const [startFrom, setStartFrom] = useState();
  const [endAt, setEndAt] = useState();
  const { token } = useAuth();
  const { invoices, getInvoiceList } = useInvoice();
  const { orders, getOrderListByInvoice } = useOrder();
  const navigate = useNavigate();
  const { loading } = useLoading();
  const { showAlert } = useAlert();
  const { getCodCharge } = useData();

  const convertData = (res)=>{
    return res?.map((order) => ({
      orderId: order?.orderId,
      createdAt: moment(order?.createdAt).format('DD-MM-yyyy'),
      collectedAmount: order?.amountToCollect,
      recipientName: order?.recipientName,
      recipientPhone: order?.recipientPhone,
      collectableAmount: order?.amountToCollect,
      codFee: Math.round(order?.parcelOrder?.codCharge),
      deliveryCharge: order?.parcelOrder?.deliveryCharge,
      finalFee: order?.parcelOrder?.deliveryCharge + order?.parcelOrder?.codCharge,
      discount: order?.discount || 0,
      additionalCharge: order?.additionalCharge || 0,
      compensationCost: order?.compensationCost || 0,
      promoDiscount: order?.promoDiscount || 0,
      expectedPayoutAmount: order?.amountToCollect - order?.parcelOrder?.deliveryCharge - order?.parcelOrder?.codCharge,
      merchantOrderId: order?.merchantOrderId,
      recipientAddress:order?.recipientAddress
    }))
    
  }

  const getKeys = (obj)=>{
    // @ts-ignore
    const titleKeys = Object.keys(obj).map((key) =>getVal(key));
    return titleKeys;
  }

  function getVal(val) {
    let escaped = val;
    if (typeof val === "string") {
      escaped = ("" + val).replace(/"/g, '"');
      // @ts-ignore
      escaped = escaped?.replace(/[\t\n\"]/gi, " ");
    }
    return `"${escaped}"`; // To escape the comma in a address like string.
  }

  const refinedData = [];
  

  const generateCSVFile = (parentOrderId,response)=>{
    const convertedData = convertData(response);
    const titleKeys = getKeys(convertedData[0])
    refinedData.push(titleKeys);
    
    // @ts-ignore
    convertedData.forEach((item) => {
      refinedData.push(Object.values(item));
    });
    
    let csvContent = "";
    refinedData.forEach((row) => {
      // @ts-ignore
      csvContent += row.join(",") + "\n";
    });
    // @ts-ignore
    const blob = new Blob([csvContent], {
      type: "text/csv;charset=utf-8,",
    });

    const objUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", objUrl);
    link.setAttribute(
      "download",
      `invoice-${parentOrderId}.csv`
    );
    link?.click();

    //console.log(csvContent)
  }
  
  const { stores, getStoreList } = useStore();

  useEffect(() => {
    if (token) {
      getStoreList();
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      getInvoiceList(0, 100, startFrom, endAt);
    }
  }, [token, startFrom, endAt]);

  const columns = [
    {
      id: 'checkbox',
      isCheckbox: true,
      textOnly: false,
      width: '32px',
      alignment: LEFT_ALIGNMENT,
      mobileOptions: {
        show: false,
      },
      // @ts-ignore
      render: (title, item) => <h4 className='fw-bold'></h4>,
    },
    {
      id: 'invoiceId',
      label: 'Invoice ID',
      // isVisuallyHiddenLabel: true,
      alignment: LEFT_ALIGNMENT,
      width: '100%',
      mobileOptions: {
        // show: false,
      },
      // @ts-ignore
      render: (title, item) => <h5 style={{ fontSize: '.95rem' }}>{title}</h5>,
    },
    {
      id: 'createdAt',
      label: 'Invoice Date',
      isVisuallyHiddenLabel: true,
      alignment: LEFT_ALIGNMENT,
      width: '100%',
      mobileOptions: {
        // show: false,
      },
      render: (title) => <span> {moment(title).format('DD-MM-yyyy')}</span>,
    },
    {
      id: 'collectedAmount',
      label: 'Collected',
      mobileOptions: {
        // only: true,
        // header: false,
        enlarge: true,
        width: '100%',
      },
      alignment: LEFT_ALIGNMENT,
      width: '150px',
      // @ts-ignore
      render: (title, item) => <span>৳{title}</span>,
      // cellProvider: (cell) => <EuiIcon type={cell} size='m' />,
    },
    {
      id: 'fee',
      label: 'Fee',
      mobileOptions: {
        // only: true,
        // header: false,
        enlarge: true,
        width: '100%',
      },
      alignment: LEFT_ALIGNMENT,
      width: '150px',
      // @ts-ignore
      render: (title, item) => <span>৳{title}</span>,
      // cellProvider: (cell) => <EuiIcon type={cell} size='m' />,
    },
    {
      id: 'receivable',
      label: 'Receivable',
      mobileOptions: {
        // only: true,
        // header: false,
        enlarge: true,
        width: '100%',
      },
      alignment: LEFT_ALIGNMENT,
      width: '150px',
      // @ts-ignore
      render: (title, item) => <span>৳{title}</span>,
      // cellProvider: (cell) => <EuiIcon type={cell} size='m' />,
    },
    {
      id: 'paymentStatus',
      label: 'Status',
      alignment: LEFT_ALIGNMENT,
      isSortable: true,
      mobileOptions: {
        // show: false,
      },
      width: '150px',
      enlarge: true,
      // @ts-ignore
      render: (title, item) => (
        <div>
          <Tag
            color={getTagColor()}
            className='p-1 px-2'
            style={{ verticalAlign: 'text-top' }}
          >
            {title}
          </Tag>
        </div>
      ),
    },

    {
      id: 'actions',
      label: 'Docs',
      isVisuallyHiddenLabel: true,
      alignment: LEFT_ALIGNMENT,
      isActionsPopover: true,
      width: '200px',
      enlarge: true,
      // render: (title, item) => (
      //   <div className='fw-bold'>
      //     <EuiIcon
      //       type={item.type}
      //       size='m'
      //       style={{ verticalAlign: 'text-top' }}
      //     />
      //     {title}
      //   </div>
      // ),
    },
  ];

  const items = invoices?.map((order, i) => {
    return {
      ...order,
      id: i,
      paymentStatus: 'Paid',
    };
  });

  function renderHeaderCells() {
    const headers = [];

    columns.forEach((column, columnIndex) => {
      if (true) {
        headers.push(
          <EuiTableHeaderCell
            key={column.id}
            // @ts-ignore
            align={columns[columnIndex].alignment}
            width={column.width}
            mobileOptions={column.mobileOptions}
            style={{ paddingBottom: 12, paddingTop: 12 }}
          >
            {column.label}
          </EuiTableHeaderCell>
        );
      }
    });
    return headers.length ? headers : null;
  }

  function renderRows() {
    const renderRow = (item) => {
      const cells = columns.map((column) => {
        const cell = item[column.id];
        let child;
        if (column.isActionsPopover) {
          return (
            <EuiTableRowCell
              key={column.id}
              // @ts-ignore
              header={column.label}
              textOnly={false}
              hasActions={true}
              align='left'
            >
              <EuiButton
                key='A'
                iconType='download'
                size='s'
                iconSize='s'
                color='success'
                className='text-success outlined mb-1 me-1 compressed collapsed d-none d-md-block fw-normal'
                onClick={()=>{
                  if (item?.invoiceId) {
                    getOrderListByInvoice(0, 100, item?.invoiceId).then(
                      async (res) => {
                        if (res) {
                          generateCSVFile(item?.invoiceId,res)
                        } else {
                          showAlert('Something went wrong', 'error');
                        }
                      }
                    );
                  } else {
                    showAlert('Invoice ID missing!', 'error');
                    
                  }
                }}
              >{loading ? 'Loading...' : 'CSV'}
                {/* <CSVLink
                  data={getData()}
                  filename={`paid-invoice-${item?.invoiceId}.csv`}
                  target='_blank'
                  asyncOnClick={true}
                  onClick={(_, done) => {
                    if (item?.invoiceId) {
                      getOrderListByInvoice(0, 100, item?.invoiceId).then(
                        async (res) => {
                          if (res) {
                            console.log(res)
                            mappedData = await convertData(res)
                            console.log(mappedData)
                            // @ts-ignore
                            setCSVData((prevData)=>{return prevData = mappedData;});
                            setTimeout(()=>{done(true)},500);
                          } else {
                            showAlert('Something went wrong', 'error');
                            done(false);
                          }
                        }
                      );
                    } else {
                      showAlert('Invoice ID missing!', 'error');
                      return false;
                    }
                  }}
                  style={{ marginRight: 8 }}
                >
                  {loading ? 'Loading...' : 'CSV'}
                </CSVLink> */}

              </EuiButton>
              <EuiButton
                key='B'
                iconType='download'
                size='s'
                color='text'
                iconSize='s'
                className='compressed mb-1 text-warning collapsed d-none d-md-block'
                style={{
                  border: '1px solid rgba(80, 80, 0, .2)',
                }}
                onClick={async (e) => {
                  e.preventDefault();
                  try {
                    if (item?.invoiceId) {
                      const f = async () =>
                        await getOrderListByInvoice(
                          0,
                          300,
                          item?.invoiceId
                        ).then(async (res) => {
                          if (res) {
                            console.log("item",item )
                            const doc = (
                              <MerchantInvoice
                                data={res}
                                invoice={item}
                                store={stores?.find(
                                  (store) =>
                                    store?.name ===
                                    res[0]?.parcelOrder?.storeName
                                )}
                              />
                            );
                            const asPdf = pdf();
                            asPdf.updateContainer(doc);
                            const blob = await asPdf.toBlob();
                            saveAs(blob, `invoice-${item.invoiceId}`);
                          } else {
                            showAlert('Something went wrong', 'error');
                          }
                        });
                      f();
                    } else {
                      showAlert('Invoice ID missing!', 'error');
                      return false;
                    }
                  } catch (e) {
                    console.error(e);
                  }
                }}
              >
                {/* <PDFDownloadLink
                  document={
                    <>
                      {console.log(orders, 'inside link', 111)}
                      {orders ? (
                        <MerchantInvoice data={orders} invoice={item} />
                      ) : (
                        <></>
                      )}
                    </>
                  }
                  fileName={`invoice-${item.invoiceId}`}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? 'Loading...' : 'PDF'
                  }
                </PDFDownloadLink> */}
                {loading ? 'Loading...' : 'PDF'}
              </EuiButton>
              <EuiIcon
                onClick={() => navigate(`/invoices/${item.invoiceId}`)}
                type='eye'
                size='l'
                style={{ cursor: 'pointer' }}
                className='p-1 cursor-pointer ms-1 bg-gray rounded-1 text-danger d-block d-md-none outlined fw-normal'
              />
            </EuiTableRowCell>
          );
        }

        if (column.render) {
          // const title = item.name?.isLink ? (
          //   <EuiLink href=''>{item.name.value}</EuiLink>
          // ) : (
          //   titleText
          // );
          child = column.render(cell, item);
        } else if (column.cellProvider) {
          child = column.cellProvider(cell);
        } else if (cell?.isLink) {
          child = <EuiLink href=''>{cell.value}</EuiLink>;
        } else if (cell?.truncateText) {
          child = cell.value;
        } else {
          child = cell;
        }

        return (
          <EuiTableRowCell
            key={column.id}
            // @ts-ignore
            align={column.alignment}
            truncateText={cell && cell.truncateText}
            textOnly={cell ? cell.textOnly : true}
            mobileOptions={{
              header: column.label,
              ...column.mobileOptions,
            }}
            className='p-1 p-md-4'
            onClick={() => {
              navigate(`/invoices/${item?.invoiceId}`);
            }}
          >
            {child}
          </EuiTableRowCell>
        );
      });

      return (
        <EuiTableRow key={item.id} isSelectable={true} hasActions={true}>
          {cells}
        </EuiTableRow>
      );
    };

    const rows = [];
    for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
      const item = items[itemIndex];
      rows.push(renderRow(item));
    }

    return rows;
  }

  const exampleId = 'example-id';

  return (
    <Container fluid>
      <EuiPageHeader>
        <EuiPageHeaderSection className='ms-3 ms-lg-0 mb-4'>
          <EuiTitle size='s'>
            <h3>Invoices</h3>
          </EuiTitle>
        </EuiPageHeaderSection>
        <EuiPageHeaderSection className='ms-3 ms-lg-0 mb-3'>
          <EuiDatePickerRange
            // @ts-ignore
            isInvalid={startFrom > endAt}
            startDateControl={
              <EuiDatePicker
                selected={startFrom}
                // @ts-ignore
                onChange={(date) => date && setStartFrom(date)}
                startDate={startFrom}
                endDate={endAt}
                aria-label='Start date'
                // showTimeSelect
                maxDate={endAt}
              />
            }
            endDateControl={
              <EuiDatePicker
                selected={endAt}
                // @ts-ignore
                onChange={(date) => date && setEndAt(date)}
                startDate={startFrom}
                endDate={endAt}
                aria-label='End date'
                // showTimeSelect
                minDate={startFrom}
              />
            }
          />
        </EuiPageHeaderSection>
      </EuiPageHeader>

      <EuiPanel
        hasShadow={false}
        hasBorder={true}
        paddingSize='none'
        className='mb-5'
      >
        <>
          <Fragment>
            {/* <EuiSpacer /> */}
            <EuiText>
              {loading ? (
                <Loader />
              ) : invoices?.length ? (
                <div>
                  <EuiTableHeaderMobile>
                    <EuiFlexGroup
                      responsive={false}
                      justifyContent='spaceBetween'
                      alignItems='baseline'
                    >
                      <EuiFlexItem grow={false}></EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiTableHeaderMobile>

                  <EuiTable
                    color='accent'
                    // cellPadding={100}
                    // cellSpacing={100}
                    id={exampleId}
                    className='border-top'
                  >
                    <EuiTableHeader>{renderHeaderCells()}</EuiTableHeader>
                    <EuiTableBody>{renderRows()}</EuiTableBody>
                    {/* <EuiTableFooter>{renderFooterCells()}</EuiTableFooter> */}
                  </EuiTable>
                </div>
              ) : (
                <NoContent
                  title='No Invoices Found'
                  description='There is no invoices that matches your filter or you have not
                created one yet.'
                  icon='searchProfilerApp'
                  hasButton={false}
                />
              )}
            </EuiText>
          </Fragment>
        </>
      </EuiPanel>
    </Container>
  );
};

export default InvoiceTable;
