import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLoginForm } from '../hooks';
import { useNavLinks, useAuth, useLoading } from '../contexts';
import {
  EuiButton,
  EuiLink,
  EuiPanel,
  EuiBadge,
  EuiButtonIcon,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiHealth,
  EuiPopover,
  EuiScreenReaderOnly,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTable,
  EuiTableBody,
  EuiTableHeader,
  EuiTableHeaderMobile,
  EuiCollapsibleNav,
  EuiSpacer,
  EuiText,
  EuiCode,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiSelect,
} from '@elastic/eui';
import {
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiTitle,
  LEFT_ALIGNMENT,
} from '@elastic/eui';
import { useStore } from 'hooks';
import { Loader } from 'components';
import { NoContent } from 'components/common';
import { useEffect } from 'react';

const Stores = () => {
  const [itemIdToOpenActionsPopoverMap, setItemIdToOpenActionsPopoverMap] =
    useState({});
  const loginForm = useLoginForm();
  const { homeLinks } = useNavLinks();
  const [animation, setAnimation] = React.useState('');
  const { checkForUser, token } = useAuth();
  const { getStoreList, stores , store, getStoreByStoreId, updateStoreById, deleteStoreById} = useStore();
  const { loading } = useLoading();
  const [stateForm,setStateForm] =  useState();

  const [id,setId] =  useState('');
  const [name,setName] =  useState('');
  const [contactNumber,setContactNumber] =  useState('');
  const [address,setAddress] =  useState('');
  const [contactName,setContactName] =  useState('');
  const [storeStatus,setStoreStatus] =  useState('');
  

  React.useEffect(() => {
    if (token) {
      getStoreList();
    }
  }, [token]);

  React.useEffect(() => {
    checkForUser();
    homeLinks();
    setAnimation('on-mount 0.4s ease-out forwards');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const columns = [
    {
      id: 'checkbox',
      isCheckbox: true,
      textOnly: false,
      width: '32px',
      mobileOptions: {
        show: false,
      },
    },
    // {
    //   id: 'type',
    //   label: 'Type',
    //   isVisuallyHiddenLabel: true,
    //   alignment: LEFT_ALIGNMENT,
    //   width: '24px',
    //   cellProvider: (cell) => <EuiIcon type={cell} size='m' />,
    //   mobileOptions: {
    //     show: false,
    //   },
    // },
    {
      id: 'health',
      label: 'ID',
      footer: '',
      alignment: LEFT_ALIGNMENT,
      width: '100px',
      mobileOptions: {
        header: false,
      },
    },
    {
      id: 'title',
      label: 'Store Name',
      footer: <em>Name</em>,
      alignment: LEFT_ALIGNMENT,
      isSortable: true,
      mobileOptions: {
        show: false,
      },
      width: '300px',
      enlarge: true,
    },

    {
      id: 'status',
      label: 'Status',
      footer: 'Status',
      alignment: LEFT_ALIGNMENT,
      isSortable: true,
      width: '100%',
      enlarge: true,
    },
    {
      id: 'title_type',
      label: 'Name',
      mobileOptions: {
        only: true,
        header: false,
        enlarge: true,
        width: '100%',
      },
      render: (title, item) => (
        <h4 className='fw-bold'>
          {/* <EuiIcon
            type={item.type}
            size='m'
            style={{ verticalAlign: 'text-top' }}
          />{' '} */}
          {title}
        </h4>
      ),
    },
    {
      id: 'dateCreated',
      label: 'Address',
      footer: 'Address',
      alignment: LEFT_ALIGNMENT,
      isSortable: true,
      width: '100%',
      enlarge: true,
    },
    {
      id: 'area',
      label: 'Area',
      footer: 'Area',
      alignment: LEFT_ALIGNMENT,
      isSortable: true,
      width: '100%',
      enlarge: true,
    },
    {
      id: 'contactNumber',
      label: 'Mobile',
      footer: 'Mobile',
      alignment: LEFT_ALIGNMENT,
      isSortable: true,
      width: '100%',
      enlarge: true,
    },
    {
      id: 'contactName',
      label: 'Contact Name',
      footer: 'Contact Name',
      alignment: LEFT_ALIGNMENT,
      isSortable: true,
      width: '100%',
      enlarge: true,
    },
    // {
    //   id: 'magnitude',
    //   label: 'Orders of magnitude',
    //   footer: ({ items, pagination }) => {
    //     const { pageIndex, pageSize } = pagination;
    //     const startIndex = pageIndex * pageSize;
    //     const pageOfItems = items.slice(
    //       startIndex,
    //       Math.min(startIndex + pageSize, items.length)
    //     );
    //     return (
    //       <strong>
    //         Total: {pageOfItems.reduce((acc, cur) => acc + cur.magnitude, 0)}
    //       </strong>
    //     );
    //   },
    //   alignment: RIGHT_ALIGNMENT,
    //   isSortable: true,
    // },
    {
      id: 'actions',
      label: 'Actions',
      
      alignment: LEFT_ALIGNMENT,
      isActionsPopover: true,
      width: '100%',
      enlarge: true,
    },
  ];

  const items = stores?.map((store, i) => {
    return {
      id: i,
      title: {
        value: store.name,
        truncateText: false,
        isLink: true,
      },
      storeId : store?.id,
      status : (store?.status ? <EuiBadge color={store?.status ==='ACTIVE' ? 'success':'danger'}>{store?.status ? store?.status : ''}</EuiBadge> : ''),
      area : store?.city + '/' + store?.zone + '/' + store?.area,
      contactNumber : store?.contactNumber,
      contactName : store?.contactName,
      type: 'dot',
      dateCreated: <p>{store?.address}</p>,
      magnitude: 1,
      health: (
        <EuiBadge color={i % 2 == 0 ? 'danger' : 'warning'}>
          {store?.id?.slice(-6)}
        </EuiBadge>
      ),
    };
  });

  const togglePopover = (itemId) => {
    setItemIdToOpenActionsPopoverMap((previousState) => {
      const newItemIdToOpenActionsPopoverMap = {
        ...previousState,
        [itemId]: !previousState[itemId],
      };

      return newItemIdToOpenActionsPopoverMap;
    });
  };

  const closePopover = (itemId) => {
    // only update the state if this item's popover is open
    if (isPopoverOpen(itemId)) {
      setItemIdToOpenActionsPopoverMap((previousState) => {
        const newItemIdToOpenActionsPopoverMap = {
          ...previousState,
          [itemId]: false,
        };

        return newItemIdToOpenActionsPopoverMap;
      });
    }
  };

  const isPopoverOpen = (itemId) => {
    return itemIdToOpenActionsPopoverMap[itemId];
  };

  function renderHeaderCells() {
    const headers = [];

    columns.forEach((column, columnIndex) => {
      if (column.isVisuallyHiddenLabel) {
        headers.push(
          <EuiTableHeaderCell key={column.id} width={column.width}>
            <EuiScreenReaderOnly>
              <span>{column.label}</span>
            </EuiScreenReaderOnly>
          </EuiTableHeaderCell>
        );
      } else {
        headers.push(
          <EuiTableHeaderCell
            key={column.id}
            align={columns[columnIndex].alignment}
            width={column.width}
            mobileOptions={column.mobileOptions}
            className='px-3 fs-5'
            style={{ paddingBottom: 12, paddingTop: 12 }}
          >
            {column.label}
          </EuiTableHeaderCell>
        );
      }
    });
    return headers.length ? headers : null;
  }

  function renderRows() {
    const renderRow = (item) => {
      const cells = columns.map((column) => {
        const cell = item[column.id];
        let child;
        if (column.isActionsPopover) {
          return (
            <EuiTableRowCell
              key={column.id}
              header={column.label}
              textOnly={false}
              hasActions={true}
              align='left'
            >
              <EuiPopover
                id={`${item.id}-actions`}
                button={
                  <EuiButtonIcon
                    aria-label='Actions'
                    iconType='gear'
                    size='s'
                    color='text'
                    onClick={() => togglePopover(item.id)}
                  />
                }
                isOpen={isPopoverOpen(item.id)}
                closePopover={() => closePopover(item.id)}
                panelPaddingSize='none'
                anchorPosition='leftCenter'
              >
                <EuiContextMenuPanel
                  items={[
                    <EuiContextMenuItem
                      key='A'
                      icon='pencil'
                      onClick={() => {
                        openSideNav(item)
                        console.log(item.storeId)
                        closePopover(item.id);
                      }}
                    >
                      Edit
                    </EuiContextMenuItem>,
                    
                    // <EuiContextMenuItem
                    //   key='C'
                    //   icon='trash'
                    //   onClick={() => {
                    //     closePopover(item.id);
                    //     deleteStore(item.storeId)
                    //   }}
                    // >
                    //   Delete
                    // </EuiContextMenuItem>,
                  ]}
                />
              </EuiPopover>
            </EuiTableRowCell>
          );
        }

        if (column.render) {
          const titleText = item.title.truncateText
            ? item.title.value
            : item.title;
          const title = item.title?.isLink ? (
            <EuiLink href=''>{item.title.value}</EuiLink>
          ) : (
            titleText
          );
          child = column.render(title, item);
        } else if (column.cellProvider) {
          child = column.cellProvider(cell);
        } else if (cell?.isLink) {
          child = <EuiLink href=''>{cell.value}</EuiLink>;
        } else if (cell?.truncateText) {
          child = cell.value;
        } else {
          child = cell;
        }

        return (
          <EuiTableRowCell
            key={column.id}
            align={column.alignment}
            truncateText={cell && cell.truncateText}
            textOnly={cell ? cell.textOnly : true}
            mobileOptions={{
              header: column.label,
              ...column.mobileOptions,
            }}
            className='p-4'
          >
            {child}
          </EuiTableRowCell>
        );
      });

      return (
        <EuiTableRow key={item.id} isSelectable={true} hasActions={true}>
          {cells}
        </EuiTableRow>
      );
    };

    const rows = [];
    for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
      const item = items[itemIndex];
      rows.push(renderRow(item));
    }

    return rows;
  }
  const exampleId = 'example-id';

  const [navIsOpen, setNavIsOpen] = useState(
    JSON.parse(
      String(localStorage.getItem('euiCollapsibleNavExample--isDocked'))
    ) || false
  );
  const [navIsDocked, setNavIsDocked] = useState(
    JSON.parse(
      String(localStorage.getItem('euiCollapsibleNavExample--isDocked'))
    ) || false
  );

  const openSideNav = async(item)=>{
    setNavIsOpen((isOpen) => !isOpen)
    setStateForm(item)
    await getStoreByStoreId(item.storeId)
  }

  useEffect(()=>{
    if(store){
      setId(store[0]?.id)
      setName(store[0]?.name);
      setAddress(store[0]?.address);
      setContactNumber(store[0]?.contactNumber);
      setContactName(store[0]?.contactName);
      setStoreStatus(store[0]?.status)
    }
    
  },[store])

  const updateStore = ()=>{
    const requestBody = {
      id : id,
      name : name,
      address : address,
      contactNumber :  contactNumber,
      contactName : contactName,
      status : storeStatus,
    }
    const updateResponse = updateStoreById(requestBody);
    getStoreList();
  }

  const deleteStore = (storeId)=>{
    deleteStoreById(storeId)
    getStoreList();
  }
  const statusList = [
    { value: 'ACTIVE', text: 'ACTIVE' },
    { value: 'INACTIVE', text: 'INACTIVE' },
  ];

  return (
    <Container fluid>
      <Container fluid>
        <EuiPageHeader>
          <EuiPageHeaderSection className='ms-3 ms-lg-0 mb-4'>
            <EuiTitle size='s'>
              <h3>Stores</h3>
            </EuiTitle>
          </EuiPageHeaderSection>
          <EuiPageHeaderSection className='ms-3 ms-lg-0 mb-3'>
            <EuiButton fill href='/stores/create' className='mb-2'>
              Create Store
            </EuiButton>
          </EuiPageHeaderSection>
        </EuiPageHeader>{' '}
        <EuiPanel hasShadow={false} hasBorder={true} className='mb-4 p-0'>
          {loading ? (
            <Loader />
          ) : stores?.length ? (
            <div>
              <EuiTableHeaderMobile>
                <EuiFlexGroup
                  responsive={false}
                  justifyContent='spaceBetween'
                  alignItems='baseline'
                >
                  <EuiFlexItem grow={false}></EuiFlexItem>
                </EuiFlexGroup>
              </EuiTableHeaderMobile>

              <EuiTable
                color='accent'
                // cellPadding={100}
                // cellSpacing={100}
                id={exampleId}
              >
                <EuiTableHeader>{renderHeaderCells()}</EuiTableHeader>
                <EuiTableBody>{renderRows()}</EuiTableBody>
                {/* <EuiTableFooter>{renderFooterCells()}</EuiTableFooter> */}
              </EuiTable>
            </div>
          ) : (
            <NoContent
              title='No Shops Found'
              description='You do not have any shops now. Plesae create one if you want to continue.'
              icon='searchProfilerApp'
              hasButton
              btnLink='/stores/create'
              btnText='Create Store'
            />
          )}
        </EuiPanel>
      </Container>
      <EuiCollapsibleNav
        isOpen={navIsOpen}
        isDocked={navIsDocked}
        size={340}
        side="right"
        onClose={() => setNavIsOpen(false)}
      >
        <div style={{ padding: 16 }}>
          <EuiTitle>
            <h2>Edit Store</h2>
          </EuiTitle>
          <EuiSpacer />
          
          <EuiForm component="form">
            <EuiFormRow label="Store Name">
              <EuiFieldText name="name" value={name} placeholder='Type store name' onChange={(e)=>{
                setName(e.target.value)
              }}/>
            </EuiFormRow>
            <EuiFormRow label="Address">
              <EuiFieldText name="address" placeholder='Type Store address' value={address} onChange={(e)=>{setAddress(e.target.value)}}/>
            </EuiFormRow>
            <EuiFormRow label="Mobile">
              <EuiFieldText name="number" placeholder='Type contact number' value={contactNumber} onChange={(e)=>{setContactNumber(e.target.value)}}/>
            </EuiFormRow>
            <EuiFormRow label="Contact">
              <EuiFieldText name="contactName" placeholder='Type contact name' value={contactName} onChange={(e)=>{setContactName(e.target.value)}} />
            </EuiFormRow>

            <EuiFormRow label="Status">
              <EuiSelect options={statusList} value={storeStatus} onChange={(e)=>{setStoreStatus(e.target.value)}}>
              </EuiSelect>
            </EuiFormRow>

            <Row>
              <Col sm='12'>
                <div className='py-4 d-flex justify-content-start w-100'>
                  <EuiButton
                    type='button'
                    fill
                    style={{ marginRight: 16 }}
                    onClick={(e) => {
                      e.preventDefault()
                      updateStore();
                    }}
                  >
                    Update
                  </EuiButton>
                  
                </div>
              </Col>
            </Row>
          </EuiForm>
                
        </div>
      </EuiCollapsibleNav>
    </Container>
  );
};

export default Stores;
