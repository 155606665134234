import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useRegistrationForm } from '../../hooks';
import {
  EuiCheckbox,
  EuiButton,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiLink,
  EuiSpacer,
  useGeneratedHtmlId,
  EuiFieldPassword,
  EuiFormControlLayout,
} from '@elastic/eui';
import {
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiTitle,
  EuiFieldNumber,
} from '@elastic/eui';
import { Link } from 'react-router-dom';
import { useLoading } from 'contexts';
import { useTermsModal } from 'contexts/terms.context';
import { formatMobile } from 'utils';

export const PrimaryRegForm = () => {
  const [isSwitchChecked, setIsSwitchChecked] = useState(false);
  const [dual, setDual] = useState(true);
  const [message, setMessage] = useState('');

  const form = useRegistrationForm();
  const basicCheckboxId = useGeneratedHtmlId({ prefix: 'basicCheckbox' });
  const { loading } = useLoading();
  const { showTermsModal } = useTermsModal();

  const [businessName, setBusinessName] = useState('');
  const [ownersName, setOwnersName] = useState('');

  return (
    <Form action='submit' className='register' onSubmit={form.handleSubmit}>
      <EuiPageHeader className='mb-4'>
        <EuiPageHeaderSection>
          <EuiTitle size='m'>
            <h3>Sign Up</h3>
          </EuiTitle>
        </EuiPageHeaderSection>
      </EuiPageHeader>
      <EuiForm component='div'>
        <h4 className='text-gray mb-4' style={{ color: 'gray' }}>
          We guarantee reliable delivery of your products to your customers, at
          the right location in the right time through our efficient
          distribution channel.
        </h4>

        <EuiFormRow label='Company Name' fullWidth>
          <EuiFieldText
            name='companyName'
            placeholder='Business name'
            type='text'
            required
            aria-label='Name'
            fullWidth
            value={businessName ? businessName : ''}
            onChange={(e)=>{
              e.preventDefault();
              if(/^[\x00-\x7F]+$/i.test(e.target.value.trim())){
                //valid
                console.log(e.target.value)
                setBusinessName(e.target.value)
              }else{
                if(e.target.value == ''){
                  setBusinessName('')
                }
              }
            }}
          />
        </EuiFormRow>

        <EuiFormRow label='Owner’s Name' fullWidth>
          <EuiFieldText
            name='ownersName'
            placeholder='Type your full name'
            type='text'
            required
            aria-label='Name'
            fullWidth
            value={ownersName ? ownersName : ''}
            onChange={(e)=>{
              e.preventDefault();
              if(/^[\x00-\x7F]+$/i.test(e.target.value.trim())){
                //valid
                setOwnersName(e.target.value)
              }else{
                if(e.target.value == ''){
                  setOwnersName('')
                }
              }
            }}
          />
        </EuiFormRow>

        <EuiFormRow
          label={
            <div className='flex'>
              Mobile Number
              {message && (
                <span
                  className='text-danger font-xs mx-1 mb-2 d-block'
                  style={{ height: 10 }}
                >
                  ({message})
                </span>
              )}
            </div>
          }
          fullWidth
        >
          <EuiFormControlLayout fullWidth prepend='+88'>
            <EuiFieldNumber
              name='mobileNumber'
              placeholder='01XXXXXXXXX'
              type='number'
              required
              maxLength={11}
              className='euiFieldText--inGroup'
              // value={form.mobileNumber}
              // onChange={form.handleMobileNumber}
              onBlur={(e) => {
                if (formatMobile(e.target.value)?.length !== 14) {
                  setMessage('Invalid Mobile Number');
                } else {
                  setMessage('');
                }
              }}
              onChange={(e) => {
                if (formatMobile(e.target.value)?.length === 14) {
                  setMessage('');
                }
              }}
              controlOnly
              fullWidth
            />
          </EuiFormControlLayout>
        </EuiFormRow>

        <EuiFormRow label='Email Address' fullWidth>
          <EuiFieldText
            name='emailAddress'
            placeholder='Type name@mail.com'
            // type='email'
            required
            // value={form.address}
            // onChange={form.handleAddress}
            autoComplete='off'
            fullWidth
          />
        </EuiFormRow>

        <EuiFormRow label='Password'>
          <EuiFieldPassword
            name='password'
            placeholder='Password'
            type={dual ? 'dual' : undefined}
            required
            // value={form.password}
            // onChange={form.handlePasswordChange}
            aria-label='Password'
          />
        </EuiFormRow>

        <EuiSpacer />

        <EuiCheckbox
          id={basicCheckboxId}
          checked={isSwitchChecked}
          onChange={(e) => setIsSwitchChecked(e.target.checked)}
          required
          label={
            <div>
              I've read and agree with the{' '}
              <button
                type='button'
                onClick={() => showTermsModal(true)}
                className='text-danger'
              >
                Terms & Conditions
              </button>{' '}
              of Piickme Express
            </div>
          }
          className='mb-3'
        />

        <EuiButton
          isLoading={loading}
          type='submit'
          className='mt-3'
          fill
          disabled={!!message}
        >
          Submit
        </EuiButton>
        <div className='w-100 text-center mt-4'>
          Already have an account?{' '}
          <Link to='/login' className='text-danger'>
            Login now
          </Link>
        </div>
      </EuiForm>
    </Form>
  );
};
