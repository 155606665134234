import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useAlert, useLoading } from '../contexts';
import { useApi } from './use-api';

const initialState = {
  initiated: 0,
  processing: 0,
  delivered: 0,
  totalOrders: 0,
  amount: 0,
  paid: 0,
};

export function useOrder() {
  const [orders, setOrders] = useState([]);
  const [ordersData, setOrdersData] = useState();
  const [infos, setInfos] = useState(initialState);
  const [order, setOrder] = useState();
  const { showAlert } = useAlert();
  const { getOrders, getOrder, getOrdersByInvoice, cancelledOrderStatus } = useApi();
  const { setLoading } = useLoading();

  const { invoiceId } = useParams();

  async function getOrderList(
    page,
    limit,
    status = '',
    paymentStatus,
    orderId,
    merchantOrderId,
    storeName,
    receiver,
    recipientName,
    startFrom,
    endAt
  ) {
    try {
      setLoading(true);
      const res = await getOrders(
        page,
        limit,
        status,
        paymentStatus,
        orderId,
        merchantOrderId,
        storeName,
        receiver,
        recipientName,
        startFrom ? moment(startFrom).format('yyyy-MM-DD') : '',
        endAt ? moment(endAt).format('yyyy-MM-DD') : ''
      );
      if (!res || res.statusCode !== 200) {
        showAlert(res?.message || 'Something went wrong!', 'error');
        return;
      }
      setOrders(
        res?.orders?.map((order) => ({
          ...(order || {}),
          ...(order?.parcelOrder || {}),
        }))
      );

      setOrdersData(res);

      const state = res?.orders?.reduce((a, b) => {
        a.totalOrders++;
        if (b.status === 'RETURN') {
          a.return++;
        }
        if (b.status === 'PROCESSING') {
          a.pending++;
        }
        if (b.status === 'DELIVERED') {
          a.delivered++;
        }
        return a;
      }, initialState);

      setLoading(false);

      return state;
    } catch (e) {
      console.error(e);
      setLoading(false);
      setOrders([]);
      showAlert('Fetching orders failed!', 'error');
    }
  }

  async function getOrderListByInvoice(page = 0, limit = 100, invoice) {
    console.log(invoice,  invoiceId);
    if (invoice || invoiceId) {
      try {
        setLoading(true);
        const res = await getOrdersByInvoice(page, limit, invoice || invoiceId);
        if (!res || res.statusCode !== 200) {
          showAlert(res?.message || 'Something went wrong!', 'error');
          return;
        }
        const list =
          res?.orders?.map((order) => ({
            ...(order || {}),
            ...(order?.parcelOrder || {}),
          })) || [];

        setOrders(list);
        setLoading(false);

        return list;
      } catch (e) {
        console.error(e);
        setOrders([]);
        setLoading(false);
        showAlert('Fetching orders failed!', 'error');
      }
    } else {
      showAlert('Invoice id required to fetch orders!', 'error');
    }
  }

  async function getOrderDetails(orderId) {
    try {
      if (!orderId) {
        showAlert('Please select a order for details');
        return;
      }
      setLoading(true);
      const res = await getOrder(orderId);
      console.log(res);
      if (!res || res.statusCode !== 200) {
        showAlert(res.message || 'Something went wrong!', 'error');
        return;
      }
      setOrder({ ...(res?.order || {}), ...(res?.order?.parcelOrder || {}) });
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
      setOrder(null);
      showAlert('Fetching orders failed!', 'error');
    }
  }

  async function cancelledOrder(orderId) {
    try {
      if (!orderId) {
        showAlert('Please select a order for details');
        return;
      }
      setLoading(true);
      const res = await cancelledOrderStatus(orderId);
      console.log(res);
      if (!res || res.statusCode !== 200) {
        showAlert(res.message || 'Something went wrong!', 'error');
        return;
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
      setOrder(null);
      showAlert('Fetching orders failed!', 'error');
    }
  }

  return {
    infos,
    orders,
    order,
    ordersData,
    getOrderDetails,
    getOrderList,
    getOrderListByInvoice,
    cancelledOrder,
  };
}
